// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Box, useTheme } from '@mui/system';
import { Paper, Typography, Divider } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CreateMap from 'Features/map';
import { useSelector } from 'react-redux';
import { RootState } from 'Store';
import { getOrders } from 'Features/order/orderSelectors';
import { useAppSelector } from 'Hooks';
import { selectCRSInfo } from 'Features/project/projectSelectors';
import stylesDef from './styles';

export const DetailsColumn = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const { activeOrder } = useSelector((state: RootState) => getOrders(state));
  const { geogCS } = useSelector((state: RootState) => selectCRSInfo(state));
  const { epsgCode, project } = useAppSelector(
    (state: RootState) => state.project,
  );
  const { name: projectName } = project || {};

  return (
    <Paper sx={styles.mapColumn}>
      <Box>
        <Box sx={styles.map}>
          <CreateMap mode="sidebar" />
        </Box>
        <Typography variant="h2" sx={styles.header}>
          {projectName}
        </Typography>
        {activeOrder.middlePoint && (
          <Typography variant="body1">
            {`${activeOrder.middlePoint.place}, ${activeOrder.middlePoint.region} ${activeOrder.middlePoint.postcode}`}
            {'  '}
            {`${activeOrder.middlePoint.longitude.toFixed(5)}, ${activeOrder.middlePoint.latitude.toFixed(5)}`}
          </Typography>
        )}
        <Divider sx={styles.divider} />
        <Typography variant="h6" sx={styles.topoTitle}>CRS COORDINATE SYSTEM</Typography>
        {epsgCode ? <Typography variant="h5" sx={styles.crs}>{`${geogCS} - EPSG: ${epsgCode}`}</Typography> : 'N/A'}
        <Divider sx={styles.divider} />
        <Box sx={styles.projectInfo}>
          <Typography variant="h3" sx={styles.projectSizeInfo}>Project Size</Typography>
          <Typography variant="h3" sx={styles.projectSizeInfo}>{`${activeOrder.acreage} ac`}</Typography>
        </Box>
        <Box sx={styles.infoTextBox}>
          <InfoIcon sx={styles.icon} />
          <Typography sx={styles.infoText}>AirWorks will communicate exact cost and delivery dates via email.</Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default DetailsColumn;
