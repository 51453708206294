// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { InputBase, Popover, MenuItem, Select, TableCell } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { ACI } from 'Utils/autoCadIndexes';
import { CompactPicker } from 'react-color';
import { useAppDispatch } from 'Hooks';
import { EditLayerAttribute } from 'Features/map/mapEditorThunk';
import stylesDef from './styles';

interface ComponentProps {
  layer: any;
  siteId: string;
}

export const LinePropertiesFlyout: React.FC<ComponentProps> = ({ layer, siteId }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const [popOverOpen, setPopoverOpen] = useState(null);

  const handlePopoverClick = (event: any) => {
    setPopoverOpen(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverOpen(null);
  };

  const handleChangeWeight = async (layerId: any, e: any) => {
    const newLineWidth = e.target.value;
    dispatch(EditLayerAttribute(layerId, siteId, { lineWidth: newLineWidth }));
  };

  const handleChangeColor = async (
    layerId: number,
    color: { hex: string },
  ) => {
    const newColor = color.hex;
    dispatch(EditLayerAttribute(layerId, siteId, { color: newColor }));
  };

  const lineWeight: number[] = [];
  for (let i = -3; i <= 100; i += 1) {
    lineWeight.push(i);
  }

  // Contains line weights and corresponding heights
  const lineWeightDropdownContents = lineWeight.map((weight) => (
    <Box key={weight}>
      {
      // eslint-disable-next-line no-nested-ternary
        weight < 10 ? (
          <MenuItem value={weight}>
            <hr
              style={{ ...styles.horizontalLine, height: `0.0${weight} mm` }}
            />
            {weight}
          </MenuItem>
        ) : weight > 9 && weight < 100 ? (
          <MenuItem value={weight}>
            <hr
              style={{ ...styles.horizontalLine, height: `0.${weight}mm` }}
            />
            {weight}
          </MenuItem>
        ) : (
          <MenuItem value={weight}>
            <hr
              style={{ ...styles.horizontalLine, height: '1mm', backgroundColor: '#000000' }}
            />
            {weight}
          </MenuItem>
        )
      }
    </Box>
  ));

  const open = Boolean(popOverOpen);
  const id = open ? 'simple-popover' : undefined;

  // @ts-ignore
  return (
    <>
      <Box
        onClick={handlePopoverClick}
        sx={styles.layerColor}
        style={{ backgroundColor: layer.color }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={popOverOpen}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        sx={styles.popover}
      >
        <span style={styles.typography}>Line Color</span>
        <Box sx={styles.borderStyle}>
          <TableCell sx={styles.tableCell} component="th" scope="row">
            <Box sx={styles.colorInput}>
              <Box
                sx={{ ...styles.layerColorInput, backgroundColor: layer.color }}
              />
              <span>{ACI.getByHEX(layer.color.toUpperCase())}</span>
              <Select
                sx={styles.selectWeight}
                input={<InputBase style={{ width: '100%' }} />}
                value={layer.color}
                variant="outlined"
              >
                <CompactPicker
                  defaultValue={layer.color}
                  colors={ACI.list}
                  onChangeComplete={(color: { hex: string; }, event: any) =>
                    handleChangeColor(layer.layerId, color, event)}
                />
              </Select>
            </Box>
          </TableCell>
        </Box>

        <span style={styles.typography}>Line Weight</span>
        <Box sx={styles.borderStyle}>
          <TableCell sx={styles.tableCell} component="th" scope="row">
            <Select
              sx={styles.selectWeight}
              input={<InputBase style={{ width: '100%' }} />}
              value={layer.lineWidth}
              variant="outlined"
              onChange={(e) => handleChangeWeight(layer.layerId, e)}
            >
              {lineWeightDropdownContents}
            </Select>
          </TableCell>
        </Box>
      </Popover>
    </>
  );
};

export default LinePropertiesFlyout;
