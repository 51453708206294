// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, useTheme } from '@mui/system';
import { Typography, Button, LinearProgress, IconButton, Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch, useAppSelector } from 'Hooks';
import uploadFilesIcon from 'Assets/upload-files-icon.png';
import DropzoneWrapper from 'Components/dropzone';
import stylesDef from './styles';
import { DeleteUploadedFileThunk, FileUploadCancelThunk, FileUploadStartThunk } from 'Features/fileUpload/fileUploadThunk';
import { SetFileUploadSizeErrorAction } from 'Features/fileUpload/fileUploadActions';

const MultipleFileUploadForm = ({ onSuccess }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const { resources } = useAppSelector((state) => state.auth);
  const { projectId } = useAppSelector((state) => state.project);
  const { uploadFiles, uploadFileSizeError } = useAppSelector((state) => state.fileUpload);
  const allFilesUploaded = uploadFiles.filter((f) => f.progress < 100).length;
  const editorFunctions = !_ADMIN_ ? 'editorFunctions' in resources : true;
  const placeOrder = !_ADMIN_ ? 'placeOrder' in resources : true;

  // Allow Airworks admin to upload any files and restrict file types for platform users based on permissions
  let acceptedExtensionsForPlatformUsers;

  if (editorFunctions && placeOrder) {
    acceptedExtensionsForPlatformUsers = [
      '.tiff',
      '.tif',
      '.las',
      '.laz',
      '.kml',
      // '.dxf'
    ];
  } else if (!editorFunctions && placeOrder) {
    acceptedExtensionsForPlatformUsers = ['.tiff', '.tif', '.las', '.laz', '.kml'];
  } else if (editorFunctions && !placeOrder) {
    acceptedExtensionsForPlatformUsers = ['.tiff', '.tif'];
  }

  // Check if Airworks Admin and assign accepted extensions accordingly
  const acceptedExtensions = _ADMIN_ ? ['.tiff', '.tif', '.kml', '.laz'] : acceptedExtensionsForPlatformUsers;
  const getBackgroundByExtension = (extension: string) => {
    switch (extension) {
    case 'las': return styles.backgroundAqua;
    case 'dxf': return styles.backgroundAqua;
    case 'laz': return styles.backgroundAqua;
    case 'kml': return styles.backgroundGold;
    default: return styles.backgroundMediumBlue;
    }
  };

  const messagingWithout2dEditorPermissions = `Upload 2D data (.tif or .tiff) and/or point cloud data (.las or .laz) for one site
    to get started. Multiple .tif, .tiff, .las, and .laz are permitted. .kml files are also welcome.`;

  const messagingWith2dEditorPermissions = 'Upload 2D data (.tif or .tiff) to get started with your CAD drawing';
  const messagingForFileUpload = editorFunctions ? messagingWith2dEditorPermissions : messagingWithout2dEditorPermissions

  const cancelUploadOrDeleteFile = (progress: number, id: string, fileName: string) => {
    if (progress === 100) {
      dispatch(DeleteUploadedFileThunk(id, fileName));
    } else {
      dispatch(FileUploadCancelThunk(id));
    }
  };
  useEffect(() => {
    if (uploadFileSizeError) {
      setOpenDialog(true);
      dispatch(SetFileUploadSizeErrorAction(false));
    }
  }, [uploadFileSizeError]);

  // @ts-ignore
  return (
    <form style={styles.uploadForm}>
      <Typography variant="h1" sx={styles.uploadFormHeader}>
        Upload Data Set
      </Typography>
      <Typography sx={styles.dataTypes}>
        Upload your data (.tif, .tiff, .las, .laz, .kml) to kick off your AirWorks Order.
      </Typography>
      <Box sx={styles.outputConfidenceBorder}>
        <ErrorIcon sx={styles.errorIcon} />
        <Typography sx={styles.outputConfidence}>
          Our outputs are only as good as the data you provide us! Please take a few minutes to review our
          <a style={styles.anchorTag} target="_blank" href="http://support.airworks.io/Layer-Output-Confidence-Level.2381381633.html" onClick={() => { window?.pendo?.track('Clicked Output Confidence Link', { projectId }); }} rel="noreferrer"> Layer Output Confidence Level.</a>
        </Typography>
        <Dialog sx={styles.dialogBox} open={openDialog}>
          <IconButton edge="end" sx={styles.closeButton} onClick={() => setOpenDialog(false)}>
            <CloseIcon />
          </IconButton>
          <DialogTitle sx={styles.dialogTitle} disableTypography>
            Size Limit Reached!
          </DialogTitle>
          <DialogContent sx={styles.dialogContent}>
            <Typography>
              The maximum filesize AirWorks can currently handle is 20GB.
            </Typography>
            <Typography>
              If your dataset is larger than that, please tile it into smaller sections and upload those instead.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button color="primary" size="small" onClick={() => setOpenDialog(false)}>OK</Button>
          </DialogActions>
        </Dialog>
      </Box>
      <DropzoneWrapper acceptedExtensions={acceptedExtensions} onDropAccepted={(acceptedFilesArray) => dispatch(FileUploadStartThunk(acceptedFilesArray))}>
        {(getInputProps, open) => (
          <>
            <img src={uploadFilesIcon} alt="Upload files" style={styles.uploadFormIcon} />
            <br />
            <input {...getInputProps()} />
            <Typography variant="h5" sx={styles.uploadFormText}>
              Drag and drop to upload
            </Typography>
            <br />
            <Typography variant="caption" sx={styles.uploadFormText}>
              {'or '}
              <Button sx={styles.openFileDialogLink} onClick={open}>browse</Button>
              {' to choose a file'}
            </Typography>
            <Typography sx={styles.fileSizeText}>
              Size limit: 20GB per file
            </Typography>
          </>
        )}
      </DropzoneWrapper>
      <Typography variant="body1" sx={styles.formText}>
        {messagingForFileUpload}
      </Typography>
      <Typography variant="body1" sx={styles.formText}>
        There are some coordinate reference systems that we don’t yet support.
        <a href="https://awkb.scrollhelp.site/airworksknowledgebase/coordinate-reference-system-crs" style={styles.anchorTag} target="_blank" rel="noreferrer"> Look up your CRS here.</a>
      </Typography>
      <Typography variant="body1" sx={styles.formText}>
        Need data? Explore the new
        <a href="https://awkb.scrollhelp.site/airworksknowledgebase/the-airworks-data-marketplace" style={styles.anchorTag} target="_blank" rel="noreferrer"> AirWorks Data Marketplace!</a>
      </Typography>
      <Box sx={styles.progressSectionsWrapper}>
        {
          uploadFiles.map((file) => (
            <Box key={file.id} sx={styles.progressSection}>
              <Box
                sx={{ ...styles.fileExtension, ...getBackgroundByExtension(file.extension) }}
              >
                <Typography variant="body1" sx={styles.fileExtensionText}>
                  {`.${file.extension}`}
                </Typography>
              </Box>
              <Box sx={styles.progress}>
                <Box sx={styles.progressText}>
                  <Typography variant="body1">
                    {file.name}
                  </Typography>
                </Box>
                <Typography variant="body1">
                  {file.progress.toFixed(2)}
                  {' '}
                  %
                  {' '}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={file.progress}
                  sx={getBackgroundByExtension(file.extension)}
                />
              </Box>
              <Box sx={styles.progressButtons}>
                {
                  file.progress === 100 && (
                    <CheckIcon sx={styles.finishedIcon} />
                  )
                }
                <IconButton sx={styles.progressButton} onClick={() => cancelUploadOrDeleteFile(file.progress, file.id, file.name)}>
                  <ClearIcon sx={styles.cancelIcon} />
                </IconButton>
              </Box>
            </Box>
          ))
        }
      </Box>
      {/* Always enable the continue button, but when a file is being uploaded wait for upload to finish */}
      <Button
        onClick={async () => {
          onSuccess();
        }}
        variant="outlined"
        disabled={(!uploadFiles.length && _ADMIN_) || (uploadFiles.length && !!allFilesUploaded)}
        sx={styles.uploadFormSubmit}
      >
        Continue
      </Button>
    </form>
  );
};

MultipleFileUploadForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default MultipleFileUploadForm;
