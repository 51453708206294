// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect } from 'react';
import { Divider } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import LoadingSpinner from 'Components/LoadingSpinner';
import history from 'Utils/history';
import { clearLocalStorage } from 'Utils/localStorageService';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { GetCreditCardsThunk, GetOrgSubscriptionThunk } from '../subscriptionThunk';
import PlanInfo from './PlanInfo';
import PaymentInfo from './PaymentInfo';
import stylesDef from './styles';

const AccountBilling = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const { creditCardsLoading, subscriptionsLoading } = useAppSelector(
    (state) => state.subscription,
  );
  const { resources } = useAppSelector((state) => state.auth);
  const billing = resources?.billing;
  const loading = creditCardsLoading && subscriptionsLoading;

  useEffect(() => {
    if (!billing) {
      clearLocalStorage();
      history.push('/');
    }
    dispatch(GetCreditCardsThunk());
    dispatch(GetOrgSubscriptionThunk());
  }, []);

  return (
    <LoadingSpinner loading={loading}>
      <Box sx={styles.pageWrapper}>
        <PlanInfo />
        <Divider sx={styles.divider} />
        <PaymentInfo />
      </Box>
    </LoadingSpinner>
  );
};

export default AccountBilling;
