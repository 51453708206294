// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Typography, Divider } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import stylesDef from './styles';

interface ComponentProps {
  order: IOrder;
}

const reducer = (previousValue: number, currentValue: number): number => previousValue + currentValue;

export const OrderSummary: React.FC<ComponentProps> = ({ order }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const { addOns, creditAdjustments, adjustedAcres, imageryCreditCost, imageryDollarCost, imageryProvider, rasterizationCreditCost } = order;
  const layers = [];
  const deliverables = [];
  let addOnsInAcres = false;
  // If all add-ons include an acres field, it indicates that the user paid for these add-ons in acres
  if (addOns?.every((addOn) => addOn.hasOwnProperty('acres'))) {
    addOnsInAcres = true;
  }
  if (addOns) {
    for (let i = 0; i < addOns.length; i += 1) {
      const el = addOns[i];
      if (el.addOnType === 'layer') {
        if (el.acres) {
          layers.push(el.acres);
        } else {
          layers.push(el.cost);
        }
      } else if (el.addOnType === 'deliverable') {
        if (el.acres) {
          deliverables.push(el.acres);
        } else {
          deliverables.push(el.cost);
        }
      }
    }
  }
  const layersCost = layers.length ? layers.reduce(reducer) : 0;
  const deliverablesCost = deliverables.length ? deliverables.reduce(reducer) : 0;
  const imageryCost = addOnsInAcres ? imageryCreditCost || 0 : imageryDollarCost || 0;
  const rasterCost = rasterizationCreditCost || 0;
  const credits = order.expressAICreditAcreage ? order.expressAICreditAcreage : order.creditAcreage;
  const expressAIOrder = order.expressAICreditAcreage > 0;

  return (
    <Box sx={styles.tooltip}>
      <Box sx={styles.divGrid}>
        <Box sx={styles.titleGrid}>
          <Typography variant="subtitle2">Charged Credits</Typography>
        </Box>
        <Typography sx={styles.rightContent} variant="subtitle2">
          {`${credits.toFixed(2)} cr`}
        </Typography>
      </Box>
      <Box sx={styles.extras}>
        <Box sx={styles.divGrid}>
          <Typography variant="body1" sx={styles.extrasText}>- Project size</Typography>
          <Typography sx={styles.extrasCost} variant="body1">
            {`${order.acreage} ac`}
          </Typography>
        </Box>
        <Box sx={styles.divGrid}>
          <Typography variant="body1" sx={styles.extrasText}>- Bundle</Typography>
          <Typography sx={styles.extrasCost} variant="body1">
            {order.bundleName}
          </Typography>
        </Box>
        {!expressAIOrder && (
          <Box sx={styles.divGrid}>
            <Typography variant="body1" sx={styles.extrasText}>- Density</Typography>
            <Typography sx={styles.extrasCost} variant="body1">
              {order.density}
            </Typography>
          </Box>
        )}
        {order.largeProjectDiscount > 0 &&
          (
            <Box sx={styles.divGrid}>
              <Typography variant="body1" sx={styles.extrasText}>- Large project discount</Typography>
              <Typography sx={styles.extrasCost} variant="body1">
                {`${order.largeProjectDiscount}%`}
              </Typography>
            </Box>
          )}
        {!expressAIOrder && (
          <Box sx={styles.divGrid}>
            <Typography variant="body1" sx={styles.extrasText}>- Delivery speed</Typography>
            <Typography sx={styles.extrasCost} variant="body1">
              {order.deliverySpeed}
            </Typography>
          </Box>
        )}
      </Box>
      {!expressAIOrder && (
        <>
          <Divider sx={styles.divider} />
          <Box sx={styles.divGrid}>
            <Box sx={styles.titleGrid}>
              <Typography variant="subtitle2">Add-Ons</Typography>
            </Box>
            <Typography sx={styles.rightContent} variant="subtitle2">
              {addOns ? `${addOnsInAcres ? '' : '$'}${(rasterCost + imageryCost + layersCost + deliverablesCost).toFixed(2)} ${addOnsInAcres ? 'cr' : ''}` : 'N/A'}
            </Typography>
          </Box>
          <Box sx={styles.extras}>
            <Box sx={styles.divGrid}>
              <Typography variant="body1" sx={styles.extrasText}>{`- Deliverables ${addOns ? `(${deliverables.length}) items` : 'N/A'}`}</Typography>
              <Typography sx={styles.extrasCost} variant="body1">
                {addOns && `${addOnsInAcres ? '' : '$'}${deliverablesCost.toFixed(2)} ${addOnsInAcres ? 'cr' : ''}`}
              </Typography>
            </Box>
            <Box sx={styles.divGrid}>
              <Typography variant="body1" sx={styles.extrasText}>{`- Layers ${addOns ? `(${layers.length}) items` : 'N/A'}`}</Typography>
              <Typography sx={styles.extrasCost} variant="body1">
                {addOns && `${addOnsInAcres ? '' : '$'}${layersCost.toFixed(2)} ${addOnsInAcres ? 'cr' : ''}`}
              </Typography>
            </Box>
            {imageryCreditCost !== undefined && imageryProvider && (
              <Box sx={styles.divGrid}>
                <Typography variant="body1" sx={styles.extrasText}>{`- ${imageryProvider} Imagery `}</Typography>
                <Typography sx={styles.extrasCost} variant="body1">
                  { `${imageryCreditCost.toFixed(2)} cr `}
                </Typography>
              </Box>
            )}
            {rasterizationCreditCost !== undefined && (
              <Box sx={styles.divGrid}>
                <Typography variant="body1" sx={styles.extrasText}>- Point Cloud Rasterization</Typography>
                <Typography sx={styles.extrasCost} variant="body1">
                  { `${rasterizationCreditCost.toFixed(2)} cr `}
                </Typography>
              </Box>
            )}
          </Box>
        </>
      )}
      {creditAdjustments && !!adjustedAcres && (
        <>
          <Divider sx={styles.divider} />
          <Box sx={styles.divGrid}>
            <Box sx={styles.titleGrid}>
              <Typography variant="subtitle2">Adjusted Credits</Typography>
            </Box>
            <Typography sx={styles.rightContent} variant="subtitle2">
              { adjustedAcres >= 0 ? '+' : '' }
              { `${adjustedAcres.toFixed(2)} cr`}
            </Typography>
          </Box>
          {creditAdjustments.map((credit) => (
            <Box sx={styles.divGrid} key={credit._id}>
              <Typography variant="body1" sx={styles.reasonText}>{`- ${credit.reason}`}</Typography>
              <Typography sx={styles.extrasCost} variant="body1">
                {credit.adjustment >= 0 ? '+' : ''}
                { `${credit.adjustment.toFixed(2)} cr`}
              </Typography>
            </Box>
          ))}

        </>
      )}
      <Divider sx={styles.divider} />
      <Box sx={styles.divGrid}>
        <Box sx={styles.titleGrid}>
          <Typography variant="subtitle2">Total Credits Charged</Typography>
        </Box>
        <Typography sx={styles.rightContentTotal} variant="subtitle2">
          {`${credits.toFixed(2)} cr`}
        </Typography>
      </Box>
    </Box>
  );
};

export default OrderSummary;
