// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  dialog: {
    margin: 'auto',
    '& .MuiDialog-paperWidthMd': {
      minWidth: '600px',
    },
  },
  dialogBox: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  dialogContent: {
    maxHeight: '500px',
    padding: '0px 16px',
  },
  okButton: {
    color: '#B1A9A7',
    marginLeft: 'none',
    '&:hover': {
      backgroundColor: 'white !important',
    },
  },
  titleText: {
    fontWeight: 500,
    paddingLeft: theme.spacing(2),
    fontSize: '20px',
    marginBottom: theme.spacing(2),
  },
  planCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid #DCDCDE',
    backgroundColor: '#FAFAFC',
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
    padding: '16px 24px',
  },
  creditsInfo: {
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    alignItems: 'center',
    gridColumnGap: theme.spacing(2),
  },
  creditsInfoText: {
    textAlign: 'center',
  },
  credit: {
    fontSize: '20px',
    marginBottom: theme.spacing(1),
  },
  text: {
    fontSize: '10px',
    color: '#788898',
  },
  line: {
    width: '1px',
    height: '100%',
    backgroundColor: '#DCDCDE',
    border: 'none',
  },
  planName: {
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  planDate: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(0.5),
  },
  planDateText: {
    fontWeight: 500,
  },
  timeIcon: {
    color: '#788898',
    fontSize: '16px',
  },
});

export default styles;
