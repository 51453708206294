// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import stylesDef from './styles';
import Card from './Card';

interface BillingColumnProps {
  setCards: (arg0: boolean) => void;
  setTotalTax?: (arg0: number) => void;
  orderFormVisible: boolean;
  checkFirstTimeChangeEstimate: (arg0: Function) => void;
  additonalCharges?: number;
}

const BillingColumn = ({ orderFormVisible, setCards, additonalCharges, setTotalTax, checkFirstTimeChangeEstimate }: BillingColumnProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);

  return (
    <Box sx={styles.billingColumn}>
      {orderFormVisible ? (
        <>
          <Typography sx={styles.pageTitle} variant="h1">
            Billing and Payment
          </Typography>
          {additonalCharges > 0 ? (
            <Card orderFormVisible={orderFormVisible} additonalCharges={additonalCharges} setCards={setCards} setTotalTax={setTotalTax} checkFirstTimeChangeEstimate={checkFirstTimeChangeEstimate}/>
          ) : (
            <Typography variant="body1">
              No additional charges required
            </Typography>
          )}
        </>
      ) : (
        <Card orderFormVisible={orderFormVisible} setCards={setCards} />
      )}
    </Box>
  );
};

export default BillingColumn;
