// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dispatch } from 'redux';
import { API_URL } from 'Config';
import { getJson, patchJson } from 'Utils/http';
import { SetNotificationListAction, UpdateNotificationListAction } from './shellActions';

export const GetNotificationsThunk = () =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/notifications/me`;
    const { token } = getState().auth;

    const result = await getJson<INotification[]>(url, token);
    if (result.success) {
      dispatch(SetNotificationListAction(result.data));
    }

    return result;
  };

export const ToggleNotificationThunk = (id: string, viewed: boolean) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/notifications/${id}/${viewed}`;
    const { token } = getState().auth;

    const result = await patchJson<INotification>(url, null, token);
    if (result.success) {
      dispatch(UpdateNotificationListAction(result.data));
    }
  };
