// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  scrollWrapper: {
    overflowY: 'scroll',
    flex: 1,
    backgroundColor: '#fafafa',
    scrollbarWidth: 'thin',
    scrollbarColor: '#aaa #f1f1f1',
    '&:hover': {
      scrollbarColor: '#555 #f1f1f1',
    },
  },
  wrapper: {
    width: '1050px',
    margin: 'auto',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerAirworks: {
    width: '1050px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  ordersHeader: {
    marginBottom: theme.spacing(3),
  },
  inputControls: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridColumnGap: theme.spacing(3),
    alignItems: 'center',
  },
  files: {
    display: 'grid',
    gridTemplateColumns: '1fr auto auto',
    gridColumnGap: theme.spacing(2),
    alignItems: 'center',
  },
  cancelButton: {
    padding: theme.spacing(0.5),
    fontSize: theme.spacing(2),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-8px',
    marginLeft: '-8px',
  },
  submitButton: {
    boxShadow: 'none',
    width: '100%',
    borderRadius: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  downloadButton: {
    fontSize: theme.spacing(2),
  },
  editIcon: {
    cursor: 'pointer',
  },
  checkIcon: {
    cursor: 'pointer',
  },
  checkbox: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  flex: {
    display: 'inline-flex',
    width: '70%',
  },
  button: {
    color: '#315177',
    fontSize: '12px',
  },
  contentEditable: {
    fontSize: '22px',
    fontWeight: 400,
  },
  headerWrapper: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#fafafa',
    paddingTop: '56px',
    zIndex: 1,
    width: '100%',
    margin: 'auto',
  },
  templateSetting: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  boundaryContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  duplicateContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  borderLineStyle: {
    border: '1px solid #000000',
    width: '100px',
    textAlign: 'left',
    height: '35px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  errorBorder: {
    border: '1px solid #F95264',
  },
  error: {
    position: 'absolute',
    marginTop: '40px',
    color: '#F95264',
  },
  icon: {
    width: '20px',
  },
  boundarySelect: {
    '& .MuiInputBase-input': {
      width: '60px',
      paddingLeft: '20px',
    },
  },
});

export default styles;
