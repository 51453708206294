// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  pageWrapper: {
    width: 600,
    margin: '0 auto',
  },
  margin: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
  list: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridRowGap: theme.spacing(2),
    alignItems: 'center',
  },
  acreageList: {
    marginBottom: theme.spacing(),
  },
  progressBar: {
    marginBottom: theme.spacing(3),
  },
  bar2: {
    backgroundColor: theme.palette.secondary.main,
  },
  dashedBar: {
    animation: 'none',
    backgroundColor: theme.palette.lightGrey,
    backgroundImage: 'none',
  },
  alignRight: {
    justifySelf: 'right',
  },
  title: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    height: 2,
  },
  creditUsage: {
    gridColumn: 'span 2',
  },
  leftText: {
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.7)',
  },
  planWithDate: {
    color: 'rgba(0, 0, 0, 0.7)',
  },
});

export default styles;
