// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createSlice } from '@reduxjs/toolkit';
import {
  ClearMarkerAction,
  ClearTileJsonAction,
  ResetProjectLasInfoAction,
  ResetProjectTifInfoAction,
  SetCanvasSizeAction,
  SetLasBBoxAction,
  SetLatAction,
  SetLngAction,
  SetMapStyleAction,
  SetViewportAction,
  SetLargeTifCount,
  SetLargeTifAcknowledgedAction,
  SetNewLargeTifAction,
  GetTileJsonStartAction,
  GetTileJsonStopAction,
  GetRasterTileJsonAction,
  GetVectorTileJsonAction,
  SetLowResCreatedAtAction,
  ChangeVersionAction,
  ChangeRasterAction,
  SetAllRasterTilesLoadedAction,
  SetLasBBoxLoadingAction,
  SetDelayInRasterTileLoadAction,
  SetDelayInRasterTileLoadAcknowledgedAction,
  ZoomInAction,
  ZoomOutAction,
  ResetLatAndLngAction,
  ToggleBasemapLayerAction,
  ToggleOrthomosaicLayerAction,
  ToggleCadDrawingsLayerAction,
  AddNewLayerToVTJAction,
  UpdateLayerVTJAction,
  DeleteLayerFromVTJAction,
  ToggleProjectLayerAction,
  SetMarkerAction,
  LocationSearchStartAction,
  LocationSearchSuccessAction,
  ClearSuggestionsAction,
  ToggleNoneVectorTilesAction,
  AdminToggleCadDropdownAction,
  SetDxfExtentAction,
  SetScreenshotAction,
  SetLayerVisibleAction,
} from './mapCommonActions';

const initialState: IMapCommonState = {
  viewport: {
    center: [-100, 40],
    zoom: 4,
  },
  canvasSize: null,
  layers: {
    orthomosaic: true,
    baseMap: true,
  },
  latitude: null,
  longitude: null,
  changeVersion: false,
  changeRaster: false,
  rasterTileJson: null,
  lowResCreatedAt: null,
  allRasterTilesLoaded: false,
  delayInRasterTileLoad: false,
  delayInRasterTileLoadAcknowledged: false,
  newLargeTif: false,
  largeTifCount: 0,
  largeTifAcknowledged: false,
  vectorTileJson: null,
  marker: null,
  mapStyle: 'streets',
  rasterTileJsonLoading: false,
  vectorTileJsonLoading: false,
  screenshot: null,
  dxfExtent: null,
  lasBBox: null,
  lasBBoxLoading: false,
  geocode: {
    mapSearchLoading: false,
    mapSuggestions: [],
  },
  layerVisible: null,
};

const mapCommonSlice = createSlice({
  name: 'mapCommon',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SetCanvasSizeAction, (state, action) => {
        state.canvasSize = action.payload;
      })
      .addCase(SetViewportAction, (state, action) => {
        Object.assign(state.viewport, action.payload);
      })
      .addCase(SetScreenshotAction, (state, action) => {
        state.screenshot = action.payload;
      })
      .addCase(SetLatAction, (state, action) => {
        state.latitude = action.payload;
      })
      .addCase(SetLngAction, (state, action) => {
        state.longitude = action.payload;
      })
      .addCase(ResetLatAndLngAction, (state) => {
        state.latitude = null;
        state.longitude = null;
      })
      .addCase(ClearMarkerAction, (state) => {
        state.marker = null;
      })
      .addCase(SetMarkerAction, (state, action) => {
        state.marker = action.payload;
      })
      .addCase(ZoomInAction, (state) => {
        state.viewport.zoom += 1;
      })
      .addCase(ZoomOutAction, (state) => {
        state.viewport.zoom -= 1;
      })
      .addCase(ToggleBasemapLayerAction, (state) => {
        state.layers.baseMap = !state.layers.baseMap;
      })
      .addCase(ToggleOrthomosaicLayerAction, (state) => {
        state.layers.orthomosaic = !state.layers.orthomosaic;
      })
      .addCase(ClearTileJsonAction, (state, action) => {
        if (action.payload === 'raster') {
          state.rasterTileJson = null;
          state.allRasterTilesLoaded = false;
          state.lowResCreatedAt = null;
          state.delayInRasterTileLoad = false;
          state.delayInRasterTileLoadAcknowledged = false;
        } else if (action.payload === 'vector') {
          state.vectorTileJson = null;
        }
      })
      .addCase(SetLasBBoxAction, (state, action) => {
        state.lasBBox = action.payload;
      })
      .addCase(SetMapStyleAction, (state, action) => {
        state.mapStyle = action.payload;
      })
      .addCase(ResetProjectTifInfoAction, (state) => {
        state.rasterTileJson = null;
        state.rasterTileJsonLoading = false;
        state.allRasterTilesLoaded = false;
        state.lowResCreatedAt = null;
        state.delayInRasterTileLoad = false;
        state.delayInRasterTileLoadAcknowledged = false;
        state.newLargeTif = false;
        state.largeTifCount = 0;
        state.largeTifAcknowledged = false;
      })
      .addCase(SetLasBBoxLoadingAction, (state, action) => {
        state.lasBBoxLoading = action.payload;
      })
      .addCase(SetDelayInRasterTileLoadAction, (state, action) => {
        state.delayInRasterTileLoad = action.payload;
      })
      .addCase(SetDelayInRasterTileLoadAcknowledgedAction, (state, action) => {
        state.delayInRasterTileLoadAcknowledged = action.payload;
      })
      .addCase(SetAllRasterTilesLoadedAction, (state, action) => {
        state.allRasterTilesLoaded = action.payload;
      })
      .addCase(SetLargeTifCount, (state, action) => {
        state.largeTifCount = action.payload;
      })
      .addCase(SetLargeTifAcknowledgedAction, (state, action) => {
        state.largeTifAcknowledged = action.payload;
      })
      .addCase(SetLowResCreatedAtAction, (state, action) => {
        state.lowResCreatedAt = action.payload;
      })
      .addCase(SetNewLargeTifAction, (state) => {
        state.newLargeTif = true;
      })
      .addCase(GetTileJsonStartAction, (state, action) => {
        if (action.payload === 'raster') {
          if (state.newLargeTif && !state.largeTifAcknowledged) {
            state.rasterTileJsonLoading = false;
          } else {
            state.rasterTileJsonLoading = true;
          }
        } else {
          state.vectorTileJsonLoading = true;
        }
      })
      .addCase(GetTileJsonStopAction, (state, action) => {
        if (action.payload === 'raster') {
          state.rasterTileJsonLoading = false;
        } else {
          state.vectorTileJsonLoading = false;
        }
      })
      .addCase(GetRasterTileJsonAction, (state, action) => {
        if (!state.rasterTileJson) {
          state.rasterTileJson = action.payload;
        } else {
          Object.assign(state.rasterTileJson, action.payload);
        }
      })
      .addCase(GetVectorTileJsonAction, (state, action) => {
        if (!state.vectorTileJson) {
          state.vectorTileJson = action.payload;
        } else if (Object.keys(state.vectorTileJson).includes(Object.keys(action.payload)[0])) {
          Object.assign(state.vectorTileJson[Object.keys(action.payload)[0]], action.payload[Object.keys(action.payload)[0]]);
        } else {
          Object.assign(state.vectorTileJson, action.payload);
        }
      })
      .addCase(ChangeRasterAction, (state) => {
        state.changeRaster = !state.changeRaster;
      })
      .addCase(ChangeVersionAction, (state) => {
        state.changeVersion = !state.changeVersion;
      })
      .addCase(ToggleCadDrawingsLayerAction, (state, action) => {
        const { orderId, fileVersion } = action.payload;
        state.vectorTileJson[orderId][fileVersion].visible = !state.vectorTileJson[orderId][fileVersion].visible;
      })
      .addCase(AddNewLayerToVTJAction, (state, action) => {
        const { orderId, siteId } = action.payload;
        state.vectorTileJson[orderId][siteId].vector_layers.unshift(action.payload.layer);
      })
      .addCase(UpdateLayerVTJAction, (state, action) => {
        const { orderId, siteId } = action.payload;
        const layerObj = state.vectorTileJson[orderId][siteId].vector_layers.find((layer) => layer.layerId === action.payload.layer.layerId);
        Object.assign(layerObj, action.payload.layer);
      })
      .addCase(DeleteLayerFromVTJAction, (state, action) => {
        const { orderId, siteId, layerId } = action.payload;
        const layerIdx = state.vectorTileJson[orderId][siteId].vector_layers.findIndex((layer) => layer.layerId === layerId);
        if (layerIdx > -1) {
          state.vectorTileJson[orderId][siteId].vector_layers.splice(layerIdx, 1);
        }
      })
      .addCase(ToggleProjectLayerAction, (state, action) => {
        const { orderId, fileVersion } = action.payload;
        const layer = state.vectorTileJson[orderId][fileVersion].vector_layers.find((l) => l.id === action.payload.id);
        layer.visible = !layer.visible;
      })
      .addCase(ToggleNoneVectorTilesAction, (state) => {
        Object.keys(state.vectorTileJson || {}).forEach((orderId) => {
          Object.keys(state.vectorTileJson[orderId]).forEach((fileVersion) => {
            state.vectorTileJson[orderId][fileVersion].visible = false;
          });
        });
      })
      .addCase(AdminToggleCadDropdownAction, (state, action) => {
        const { orderId, fileVersion } = action.payload;
        Object.keys(state.vectorTileJson || {}).forEach((oid) => {
          Object.keys(state.vectorTileJson[oid]).forEach((version) => {
            state.vectorTileJson[oid][version].visible = false;
          });
        });
        if (state.vectorTileJson && state.vectorTileJson[orderId] && state.vectorTileJson[orderId][fileVersion]) {
          state.vectorTileJson[orderId][fileVersion].visible = true;
        }
      })
      .addCase(SetDxfExtentAction, (state, action) => {
        state.dxfExtent = action.payload;
      })
      .addCase(ResetProjectLasInfoAction, (state) => {
        state.lasBBox = null;
        state.lasBBoxLoading = false;
      })
      .addCase(LocationSearchStartAction, (state) => {
        state.geocode.mapSearchLoading = true;
      })
      .addCase(LocationSearchSuccessAction, (state, action) => {
        state.geocode.mapSearchLoading = false;
        state.geocode.mapSuggestions = action.payload;
      })
      .addCase(ClearSuggestionsAction, (state) => {
        state.geocode.mapSuggestions = [];
      })
      .addCase(SetLayerVisibleAction, (state, action) => {
        state.layerVisible = action.payload;
      });
  },
});

export const { reducer } = mapCommonSlice;

export default reducer;
