// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  searchField: {
    padding: '3px 10px',
    borderRadius: '20px',
    backgroundColor: '#f2f2f2',
    opacity: 0.9,
    height: '38px',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  textField: {
    flexGrow: 0,
  },
});

export default styles;
