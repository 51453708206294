// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/system';
import { Typography, Card, Tooltip } from '@mui/material';
import LoadingSpinner from 'Components/LoadingSpinner';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { useParams } from 'react-router';
import { GetImageryProvidersThunk } from 'Features/imagery/imageryThunk';
import { RootState } from 'Store';
import SubscriptionInfo from './subscriptionInfo';
import { GetOrgThunk, GetOrgSubscriptionThunk } from '../adminThunk';
import { SetOrgIdAction } from '../adminActions';
import stylesDef from './styles';

export const AccountSubscription = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const { orgId } = useParams();
  const dispatch = useAppDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const { org } = useAppSelector(
    (state: RootState) => state.admin,
  );

  useEffect(() => {
    dispatch(SetOrgIdAction(orgId));
    const loadData = async () => {
      setShowLoader(true);
      await Promise.all([
        dispatch(GetOrgThunk(orgId)),
        dispatch(GetOrgSubscriptionThunk(orgId)),
        dispatch(GetImageryProvidersThunk()),
      ]);
      setShowLoader(false);
    };

    loadData();
  }, []);

  const stripeId = org?.stripeId ? org.stripeId : 'Stripe ID Unavailable';
  return (
    <LoadingSpinner loading={showLoader}>
      <Box sx={styles.wrapper}>
        <Card sx={styles.card}>
          <Typography variant="h4">Customer Stripe ID</Typography>
          <br />
          <Tooltip title="Click to Copy" arrow>
            <Typography
              variant="h1"
              onClick={() => {
                navigator.clipboard.writeText(stripeId);
              }}
            >
              {stripeId}
            </Typography>
          </Tooltip>
        </Card>
        <br />
        <div>
          <SubscriptionInfo />
        </div>
      </Box>
    </LoadingSpinner>
  );
};

export default AccountSubscription;
