import { createAction } from '@reduxjs/toolkit';

export const GetImageryProvidersStartAction = createAction('imagery/GetImageryProvidersStartAction');
export const GetImageryProvidersSuccessAction = createAction<IImageryProvider[]>('imagery/GetImageryProvidersSuccessAction');
export const GetImageryProvidersFailedAction = createAction('imagery/GetImageryProvidersFailedAction');

export const ResetImageryCoverageAction = createAction('imagery/ResetImageryCoverageAction');

export const GetImageryCoverageStartAction = createAction('imagery/GetImageryCoverageStartAction');
export const GetImageryCoverageSuccessAction = createAction<boolean>('imagery/GetImageryCoverageSuccessAction');
export const GetImageryCoverageFailedAction = createAction('imagery/GetImageryCoverageFailedAction');

export const SetImageryVendorSelectedAction = createAction<string>('imagery/SetImageryVendorSelectedAction');

export const SetProjectionSelectedAction = createAction<string>('imagery/SetProjectionSelectedAction');

export const ToggleImageryProcessingAction = createAction('imagery/ToggleImageryProcessingAction');

export const SetAvailableProjectionsAction = createAction<string[]>('imagery/SetAvailableProjectionsAction');

export const CreateImageryRequestStartAction = createAction('imagery/CreateImageryRequestStartAction');
export const CreateImageryRequestSuccessAction = createAction('imagery/CreateImageryRequestSuccessAction');
export const CreateImageryRequestFailedAction = createAction('imagery/CreateImageryRequestFailedAction');
