// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  subscriptionDate: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
  },
  listContent: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridRowGap: theme.spacing(2),
    justifyContent: 'space-between',
    alignItems: 'center',
    '&>*:nth-child(2n)': {
      textAlign: 'right',
      fontWeight: 400,
    },
  },
  formContent: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    justifyContent: 'space-between',
    gridRowGap: theme.spacing(1),
    alignItems: 'center',
    '&>*:nth-child(2n)': {
      textAlign: 'right',
    },
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
  },
  sectionTitle: {
    marginTop: theme.spacing(1),
    color: '#979797',
    fontSize: theme.spacing(2),
  },
  buttonGroup: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  planButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  planButtonGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  historyButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingRight: '0px',
    '& .MuiButton-label': {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
    },
    '&:hover': {
      backgroundColor: '#fafafa !important',
    },
  },
  historyButtonIcon: {
    fontSize: '18px',
  },
  historyButtonText: {
    fontSize: '14px',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-12px',
    marginLeft: '-12px',
  },
  inputBase: {
    width: '150px',
  },
  billingMethod: {
    width: '200px',
    textAlign: 'end',
  },
  textFieldDiv: {
    marginRight: theme.spacing(2),
  },
  textField: {
    fontWeight: 300,
    letterSpacing: '1.3px',
    maxWidth: '125px',
    fontSize: '14px',
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1),
    },
    '& fieldset': {
      borderRadius: 0,
      borderColor: '#EAEAEA',
    },
  },
});

export default styles;
