// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import {
  Typography,
  TextField,
  CircularProgress,
  Autocomplete,
} from '@mui/material/';
import { Box, useTheme } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { airworksLayers } from 'Utils/airworksLayers';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { SetTemplateId } from '../../cadTemplateThunk';
import stylesDef from './styles';

interface ComponentProps {
  setCorrectMatching: (data: {}) => void;
}

const Matching: React.FC<ComponentProps> = ({ setCorrectMatching }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const { templateId, list } = useAppSelector((state) => state.cadTemplate);
  const currentTemplate = list?.find((t: any) => t._id === templateId);

  interface IReverseMapping {
    [key: string]: string;
  }

  const [rm, setRm] = React.useState<IReverseMapping>({});
  const [userLayers, setUserLayers] = React.useState([]);

  const _rm: IReverseMapping = {};

  React.useEffect(() => {
    if (currentTemplate) {
      dispatch(SetTemplateId(currentTemplate._id));
      const layersList: string[] = [];
      currentTemplate.layers.forEach((l: any) => {
        l.mapping.forEach((m: string) => {
          if (airworksLayers.includes(m)) {
            _rm[m] = l.name;
          }
        });
        layersList.push(l.name);
      });
      setUserLayers(layersList);
    }
    setRm(_rm);
    setCorrectMatching(_rm);
  }, [currentTemplate]);

  return (
    <Box sx={styles.matchingWrapper}>
      <Box sx={styles.matchingText}>
        <Typography variant="h3">
          Map Layers in your template to AirWorks Layers
        </Typography>
        <Typography variant="body1" sx={styles.description}>
          Each AirWorks layer should be matched to a layer in your template. All
          layers in your template that aren't mapped to an AirWorks layer will
          be exported in our output as specified by your template.
        </Typography>
      </Box>
      <Box sx={styles.layerListTitle}>
        <Typography variant="h6">AirWorks Layer</Typography>
        <Typography variant="h6">Layer name from file</Typography>
        <Typography variant="h6">Matched</Typography>
      </Box>
      {airworksLayers.map((l) => (
        <Box sx={styles.layerList} key={l}>
          <Typography sx={styles.layerListText}>{l}</Typography>
          <Autocomplete
            id="layer-select"
            sx={styles.autoComplete}
            options={['Choose a layer', ...userLayers]}
            autoHighlight
            loading={!rm[l]}
            getOptionLabel={(option) => option}
            value={rm[l] || 'Choose a layer'}
            onChange={(ev, val) => {
              let _rm = { ...rm };
              if (val === 'Choose a layer') {
                delete _rm[l];
              } else {
                _rm[l] = val;
              }
              setRm(_rm);
              setCorrectMatching(_rm);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                margin="dense"
                sx={styles.outline}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                  style: {
                    fontWeight: 300,
                    fontSize: '14px',
                  },
                }}
              />
            )}
          />
          {!currentTemplate ? (
            <CircularProgress
              sx={styles.matchingProgress}
              aria-describedby="circularProgress"
              size={20}
            />
          ) : (
            <CheckCircleIcon
              sx={rm[l] ? styles.checkIcon : styles.checkIconHide}
            />
          )}
        </Box>
      ))}
    </Box>
  );
};

export default Matching;
