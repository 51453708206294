// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dialog, Typography, Button, DialogActions } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { Box, useTheme } from '@mui/system';
import stylesDef from './styles';

export const ErrorDialog = ({ message, showDialog, onCancel }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);

  return (
    <Dialog
      open={showDialog}
      sx={styles.dialog}
    >
      <Box sx={styles.dialogBox}>
        <InfoIcon sx={styles.icon} color="error" />
        <Typography>
         {message}
        </Typography>
        <DialogActions sx={styles.dialogActions}>
          <Button onClick={onCancel} variant="text">
            Close
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ErrorDialog;
