// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import SVG from 'react-inlinesvg';
import visaIcon from 'payment-icons/min/flat/visa.svg';
import mastercardIcon from 'payment-icons/min/flat/mastercard.svg';
import amexIcon from 'payment-icons/min/flat/amex.svg';
import dinersIcon from 'payment-icons/min/flat/diners.svg';
import discoverIcon from 'payment-icons/min/flat/discover.svg';

const CreditCardIcon = ({ cardType }: { cardType: string }) => {
  switch (cardType.toLowerCase()) {
  case 'visa':
    return <SVG src={visaIcon} title="visa" />;
  case 'diners-club':
    return <SVG src={dinersIcon} title="diners-club" />;
  case 'mastercard':
    return <SVG src={mastercardIcon} title="mastercard" />;
  case 'american-express':
  case 'american express':
    return <SVG src={amexIcon} title="american-express" />;
  case 'discover':
    return <SVG src={discoverIcon} title="discover" />;
  default:
    return null;
  }
};

export default CreditCardIcon;
