/* eslint-disable react/jsx-no-useless-fragment */
// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import moment from 'moment-timezone';
import { Moment } from 'moment';
import { Box, useTheme } from '@mui/system';
import { Typography, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import stylesDef from './styles';

moment.tz.setDefault('America/New_York');

interface DatePickerComponentProps {
  openDatepicker: boolean;
  setOpenDatepicker: (openDatepicker: boolean) => void;
  selectedDate: Moment;
  setSelectedDate: (selectedDate: Moment) => void;
}

export const DatePickerComponent = ({ openDatepicker, selectedDate, setOpenDatepicker, setSelectedDate }: DatePickerComponentProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);

  return (
    <>
      {openDatepicker ? (
        <Box sx={styles.dateAndButtons}>
          <Box>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                format="MM/DD/YYYY"
                value={moment(selectedDate, "MM/DD/yyyy")}
                onChange={(e) => setSelectedDate(e.format())}
              />
            </LocalizationProvider>
          </Box>
          <Box sx={styles.buttons}>
            <IconButton
              sx={styles.iconButton}
              onClick={() => setOpenDatepicker(false)}
              aria-label="Close"
            >
              <ClearIcon fontSize="small" />
            </IconButton>
            <IconButton
              sx={styles.iconButton}
              onClick={(e) => {
                setSelectedDate(selectedDate);
                setOpenDatepicker(false);
              }}
              aria-label="Close"
            >
              <CheckIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Box sx={styles.dateAndButtons}>
          <Typography>{moment(selectedDate).format('LL')}</Typography>
          <IconButton
            sx={styles.iconButton}
            onClick={() => setOpenDatepicker(true)}
            aria-label="Close"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default DatePickerComponent;
