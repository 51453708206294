// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createAction } from '@reduxjs/toolkit';

export const SetKmlAction = createAction<{ orderId: string, kml: GeoJSON.FeatureCollection<GeoJSON.Polygon> }>('kml/SetKmlAction');
export const ResetKmlAction = createAction('kml/ResetKmlAction');

export const SetKmlErrorAction = createAction<boolean>('kml/SetKmlErrorAction');

export const SetLineStringUploadAction = createAction<boolean>('kml/SetLineStringUploadAction');

export const SetRoadNetworkClickedAction = createAction<boolean>('kml/SetRoadNetworkClickedAction');

export const LoadKmlsStartAction = createAction('kml/LoadKmlsStartAction');
export const LoadKmlsSuccessAction = createAction<{ orderId: string, kml: GeoJSON.FeatureCollection<GeoJSON.Polygon> }[]>('kml/LoadKmlsSuccessAction');
export const LoadKmlsFailureAction = createAction<string>('kml/LoadKmlsFailureAction');

export const CreateKmlBufferStartAction = createAction('kml/CreateKmlBufferStartAction');
export const CreateKmlBufferSuccessAction = createAction('kml/CreateKmlBufferSuccessAction');
export const CreateKmlBufferFailureAction = createAction('kml/CreateKmlBufferFailureAction');

export const CreateRoadNetworkKmlBufferStartAction = createAction('kml/CreateRoadNetworkKmlBufferStartAction');
export const CreateRoadNetworkKmlBufferSuccessAction = createAction('kml/CreateRoadNetworkKmlBufferSuccessAction');
export const CreateRoadNetworkKmlBufferFailureAction = createAction('kml/CreateRoadNetworkKmlBufferFailureAction');

export const SetShowBufferInfoAction = createAction<boolean>('kml/SetShowBufferInfoAction');
export const SetBufferSizeAction = createAction<number>('kml/SetBufferSizeAction');
export const ResetBufferStateAction = createAction('kml/ResetBufferStateAction');
export const AdminToggleKmlVisibilityAction = createAction<string>('kml/AdminToggleKmlVisibilityAction');

export const SetKmlBBoxAction = createAction<number[]>('kml/SetKmlBBoxAction');

export const SetShowBusinessDaysAction = createAction<boolean>('kml/SetShowBusinessDaysAction');

export const SetSelectedKmlIdAction = createAction<string>('kml/SetSelectedKmlIdAction');

export const OverwriteKmlsAction = createAction<IKmls>('kml/OverwriteKmlsAction');
