function applyForceContinuous([type, ...linestrings]) {
  if (type === "curvepolygon") {
    return [type, ...linestrings.map((l) => applyForceContinuous(l))];
  }

  let prevLinestring = linestrings[0];
  for (const linestring of linestrings.slice(1)) {
    const prevCoord = prevLinestring.slice(-1)[0];
    linestring[1] = [prevCoord[0], prevCoord[1]];
    prevLinestring = linestring;
  }
  return [type, ...linestrings];
}

function applyAutoPolygon([type, ...linestrings]) {
  const EPSILON = 1e-8;
  const close = ([x1, y1], [x2, y2]) =>
    Math.abs(x2 - x1) < EPSILON && Math.abs(y2 - y1) < EPSILON;
  const first = linestrings[0].slice(1,2)[0];
  const last = linestrings.slice(-1)[0].slice(-1)[0];
  if (close(first, last)) {
    first[0] = last[0];
    first[1] = last[1];
    return ["curvepolygon", [type, ...linestrings]];
  } else {
    return [type, ...linestrings];
  }
}

export default function curveToWkt(
  [type, ...rest],
  options = { autoPolygon: false, forceContinuous: false }
) {
  if (options.autoPolygon && type === "compoundcurve") {
    [type, ...rest] = applyAutoPolygon([type, ...rest]);
  }

  if (options.forceContinuous) {
    [type, ...rest] = applyForceContinuous([type, ...rest]);
  }

  if (options.format === "html") {
    return compoundCurveToHtmlWkt([type, ...rest]);
  }
  const casing =
    options.case === 'uppercase'
      ? (x) => String(x).toUpperCase()
      : (x) => String(x).toLowerCase();
  const coordsToWkt = (coords) =>
    `(${coords.map((coord) => `${coord.join(' ')}`).join(', ')})`;

  if (type === 'compoundcurve') {
    return `${casing(type)}(${rest.map(curveToWkt).join(', ')})`;
  }
  if (type === 'curvepolygon') {
    return `${casing(type)}(${rest.map(curveToWkt).join(', ')})`;
  }
  if (type === 'linestring') {
    // Be careful if we ever use this for more than compoundcurves
    return coordsToWkt(rest);
  }
  return `${casing(type)}${coordsToWkt(rest)}`;
}
