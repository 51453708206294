// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Box, useTheme } from '@mui/system';
import { TransitionProps } from '@mui/material/transitions/transition';
import { Dialog, Slide, IconButton, LinearProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MultipleFilesUploadForm from 'Components/FileUploadForm/MultipleFileUploadForm';
import { useAppSelector } from 'Hooks';
import stylesDef from './styles';

interface UploadFilesDialogProps {
  showDialog: boolean;
  setShowDialog: (show: boolean) => void;
  orderFormVisible?: boolean;
  setOrderFormVisible? : (show: boolean) => void;
}

const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => <Slide ref={ref} direction="up" {...props} />);

export const UploadFilesDialog = ({ showDialog, setShowDialog, orderFormVisible, setOrderFormVisible }: UploadFilesDialogProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const { loading } = useAppSelector(
    (state) => state.order,
  );

  return (
    <Dialog
      fullScreen
      open={showDialog}
      onClose={() => setShowDialog(false)}
      TransitionComponent={Transition}
      keepMounted={false}
    >
      {loading && (
        <>
          <LinearProgress sx={styles.progress} />
          <Box sx={styles.backdrop} />
        </>
      )}
      <Box sx={styles.closeButtonWrapper}>
        <IconButton color="inherit" onClick={() => setShowDialog(false)} aria-label="Close">
          <CloseIcon fontSize="large" />
        </IconButton>
      </Box>
      <Box sx={styles.contentWrapper}>
        <MultipleFilesUploadForm onSuccess={() => {
          setShowDialog(false);
          if (orderFormVisible) setOrderFormVisible(false);
        }}
        />
      </Box>
    </Dialog>
  );
};

UploadFilesDialog.defaultProps = {
  orderFormVisible: false,
  setOrderFormVisible: () => {},
};

export default UploadFilesDialog;
