// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Dialog, DialogActions, DialogContent, Button, Typography } from '@mui/material/';
import { Box, useTheme } from '@mui/system';
import ErrorIcon from '@mui/icons-material/Error';
import { useAppSelector } from 'Hooks';
import { selectProjectFiles } from 'Features/project/projectSelectors';
import stylesDef from './styles';

interface ComponentProps {
    showDialog: boolean;
    onOk: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onCancel?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const WarningDialog: React.FC<ComponentProps> = ({ showDialog, onOk, onCancel }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);

  const fileStatuses = useAppSelector((state) => state.project.fileStatusJson);
  const projectFiles = useAppSelector((state) => selectProjectFiles(state));

  const fileStatusesValid = fileStatuses && Object.keys(fileStatuses).length > 0;
  const messages: string[] = [];
  if (projectFiles.length > 0) {
    projectFiles.forEach((file: any) => {
      if (
        fileStatusesValid &&
        fileStatuses[file.name] &&
        fileStatuses[file.name].status === 'WARNING'
      ) {
        fileStatuses[file.name].messages.forEach((message) => {
          messages.push(`${file.name}: ${message}`);
        });
      }
    });
  }

  return (
    <Dialog open={showDialog} onClose={onCancel} sx={styles.dialog}>
      <Box sx={styles.dialogBox}>
        <Box sx={styles.dialogTitle}>
          <ErrorIcon sx={styles.warningIcon} />
          <Typography variant="h3" sx={styles.titleText}>
            Warning(s) found for these files
          </Typography>
          <DialogActions>
            <Button onClick={onCancel}>Close</Button>
          </DialogActions>
        </Box>
        <DialogContent>
          {messages.map((m, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Typography key={`${m}${index}`}>{m}</Typography>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={onOk} sx={styles.okButton}>
            Ok, proceed to project
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default WarningDialog;
