// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  expansionPanelHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    margin: 0,
  },
  button: {
    padding: theme.spacing(1),
  },
  downloadFileButton: {
    padding: '6px',
    marginLeft: 'auto',
    marginRight: theme.spacing(1),
  },
  sidebarList: {
    padding: 0,
  },
  sidebarButtonText: {
    paddingLeft: 0,
  },
  wrapper: {
    paddingBottom: theme.spacing(1),
  },
  dataSetRow: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
  },
  dataSetName: {
    wordWrap: 'anywhere',
  },
  cancelButton: {
    padding: theme.spacing(0.5),
  },
  cancelButtonIcon: {
    fontSize: theme.spacing(2),
  },
  uploadIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  newLine: {
    display: 'block',
  },
  filesList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  fileName: {
    overflow: 'hidden',
    maxWidth: '150px',
    textOverflow: 'ellipsis',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  fileEpsg: {
    width: '100%',
    marginBottom: theme.spacing(1.25),
  },
  fileInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    width: '100%',
  },
  settingsIcon: {
    marginLeft: theme.spacing(1),
    fontSize: '17px',
  },
  errorIcon: {
    color: 'red',
    fontSize: '20px',
  },
  warningIcon: {
    color: '#01a1dd',
    fontSize: '20px',
  },
  loadingIcon: {
    color: '#FAA738',
    fontSize: '20px',
  },
  helpIcon: {
    width: '18px',
    height: '18px',
    marginLeft: theme.spacing(0.5),
    cursor: 'pointer',
  },
  dataSetInfo: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
  },
  captureDateInfo: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  toolTipTitle: {
    fontWeight: 400,
    fontSize: '14px',
    color: '#000',
  },
  toolTipText: {
    fontSize: '14px',
  },
  captureDate: {
    marginLeft: theme.spacing(1),
  },
  toolTipLogo: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  toolTip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: '11px',
  },
  link: {
    color: theme.palette.primary.main,
  },
  toolTipFiles: {
    backgroundColor: 'rgba(64, 64, 64, 0.8)',
    color: '#FFFFFF',
    boxShadow: theme.shadows[1],
    fontSize: '11px',
    flex: 1,
  },
});

export default styles;
