// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { API_URL } from 'Config';
import { Dispatch } from 'redux';
import { getJson, patchJson, postJson, deleteRequest } from 'Utils/http';
import type { RootState } from 'Store';
import { ReadFromStorage } from '../auth/authThunk';
import {
  GetOrgProfileFailedAction,
  GetOrgProfileStartAction,
  GetOrgProfileSuccessAction,
  GetUserProfileFailedAction,
  GetUserProfileStartAction,
  GetUserProfileSuccessAction,
  UpdateUserProfileSuccessAction,
  UpdateOrgProfileSuccessAction,
  UpdatePasswordSuccessAction,
  UpdatePasswordFailureAction,
  GetOrgTeamsStartAction,
  GetOrgTeamsSuccessAction,
  GetOrgTeamsFailureAction,
  DefaultTemplateSuccessAction,
  DeleteOrgUserStartAction,
  DeleteOrgUserSuccessAction,
  DeleteOrgUserFailureAction,
  GetOrgUsersStartAction,
  GetOrgUsersSuccessAction,
  GetOrgUsersFailureAction,
  GetOrgAssignableRolesAction,
  EditOrgUserSuccessAction,
  CreateOrgUserStartAction,
  CreateOrgUserSuccessAction,
  CreateOrgUserFailureAction,
  DeleteOrgTeamStartAction,
  DeleteOrgTeamSuccessAction,
  DeleteOrgTeamFailureAction,
  UpdateOrgTeamStartAction,
  UpdateOrgTeamSuccessAction,
  UpdateOrgTeamFailureAction,
  AddOrgTeamStartAction,
  AddOrgTeamSuccessAction,
  AddOrgTeamFailureAction,
  GetOrgStatsStartAction,
  GetOrgStatsSuccessAction,
} from './accountActions';

export const GetUserProfileThunk = (polling: boolean = false) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    // if (polling) {
    //   const processingOrders = getProcessingOrders(getState());
    //   if (!processingOrders.length) return {};
    // }

    const url = `${API_URL}/users/me`;
    const { token } = getState().auth;
    const user = ReadFromStorage()(dispatch);

    dispatch(GetUserProfileStartAction());

    const result = await getJson<IUser>(url, token);
    if (result.success) {
      result.data.roles = [];
      dispatch(GetUserProfileSuccessAction(result.data));
      localStorage.setItem('user', JSON.stringify(result.data));
      ReadFromStorage()(dispatch);
    } else {
      dispatch(GetUserProfileFailedAction());
    }

    return result;
  };

export const UpdateUserProfileThunk = (data: IUser) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const url = `${API_URL}/users/me`;
    const { token } = getState().auth;

    const result = await patchJson<IUserResponse>(url, data, token);
    if (result.success) {
      dispatch(UpdateUserProfileSuccessAction(result.data));
      localStorage.setItem('user', JSON.stringify(result.data.user));
      ReadFromStorage()(dispatch);
    }

    return result;
  };

export const GetOrgProfileThunk = () =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const url = `${API_URL}/organizations`;
    const { token } = getState().auth;

    dispatch(GetOrgProfileStartAction());

    const result = await getJson<IOrgResponse>(url, token);
    if (result.success) {
      dispatch(GetOrgProfileSuccessAction(result.data.org));
    } else {
      dispatch(GetOrgProfileFailedAction());
    }

    return result;
  };

export const UpdateOrgProfileThunk = (data: IOrg) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const url = `${API_URL}/organizations/updateOrgDetails`;
    const { token } = getState().auth;

    const result = await patchJson<IOrgResponse>(url, { ownerOrganization: data._id, companyName: data.companyName, address: data.address, phoneNumber: data.phoneNumber }, token);
    if (result.success) {
      dispatch(UpdateOrgProfileSuccessAction(result.data));
    }

    return result;
  };

export const PasswordUpdateThunk = (data: { oldPassword: string, password: string }) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const url = `${API_URL}/users/passwordUpdate`;
    const { token } = getState().auth;
    const _data = {
      oldpw: data.oldPassword,
      newpw: data.password,
    };

    const result = await postJson<string>(url, _data, token);
    if (result.success) {
      dispatch(UpdatePasswordSuccessAction(result.data));
    } else {
      dispatch(UpdatePasswordFailureAction());
    }

    return result;
  };

export const GetOrgTeamsThunk = (orgId?: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(GetOrgTeamsStartAction);

    const url = orgId ? `${API_URL}/teams/${orgId}` : `${API_URL}/teams`;
    const { token } = getState().auth;
    const result = await getJson<IGetTeamsResponse>(url, token);

    if (result.success) {
      const { orgTeams } = result.data;
      dispatch(GetOrgTeamsSuccessAction(orgTeams));
    } else {
      dispatch(GetOrgTeamsFailureAction());
    }
    return result;
  };

export const DefaultTemplateThunk = (data: { templateId: string }) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const url = `${API_URL}/organizations/updateDefaultTemplate`;
    const { token } = getState().auth;

    const result = await patchJson<IOrgResponse>(url, data, token);
    if (result.success) {
      dispatch(DefaultTemplateSuccessAction(result.data.org));
    }
    return result;
  };

export const DeleteUserThunk = (userEmail: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { token } = getState().auth;
    const url = `${API_URL}/organizations/deleteOrgUser`;
    const data = { email: userEmail };

    dispatch(DeleteOrgUserStartAction(userEmail));

    const result = await deleteRequest(url, token, data);
    if (result.success) {
      dispatch(DeleteOrgUserSuccessAction(userEmail));
    } else {
      dispatch(DeleteOrgUserFailureAction(userEmail));
    }
    return result;
  };

export const GetOrgUsersThunk = (orgId?: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { token } = getState().auth;
    const url = _ADMIN_ ? `${API_URL}/organizations/getOrgUsers/${orgId}` : `${API_URL}/organizations/getOrgUsers`;

    dispatch(GetOrgUsersStartAction());

    const result = await getJson<IUser[]>(url, token);
    if (!result.success) {
      return result;
    }

    let orgUsers : {
      _id: string,
      email: string,
      fullName: string,
      admin: boolean,
      deleting: boolean,
      role: string[],
      teams: any,
  }[] = null;

    const filterTeams = (teamsArr: []) => {
      const arr:any = [];
      teamsArr.forEach((id) => {
        const team = getState().account?.orgTeams.find((p) => p._id === id);
        arr.push(team);
      });
      return arr;
    };

    if (result.success) {
      const users = result.data.data;
      orgUsers = [];
      users.forEach((user: IUser) => {
        orgUsers.push({
          ...user,
          deleting: false,
          teams: user.teams?.length > 0 ? filterTeams(user.teams) : [],
        });
      });
      dispatch(GetOrgUsersSuccessAction(orgUsers));
    } else {
      dispatch(GetOrgUsersFailureAction());
    }
    return result;
  };

export const GetOrgAssignableRolesThunk = (orgId?: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const url = orgId ? `${API_URL}/role/getAssignableRoles/${orgId}` : `${API_URL}/role/getAssignableRoles`;
    const { token } = getState().auth;
    const result = await getJson<any>(url, token);
    const roles:any = {};
    if (result.success) {
      const { orgRoles } = result.data;

      orgRoles.forEach((role: {_id: string, name: string}) => {
        if (role.name === 'editor2d') {
          role.name = 'cad';
        }
        roles[role._id] = role.name;
      });
      dispatch(GetOrgAssignableRolesAction(roles));
    }
    return result;
  };

export const CreateOrgUserThunk = (userData: IActiveUser, orgId?: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { firstName, lastName, email, teams, jobTitle, phoneNumber, rolesArray } = userData;
    const { token } = getState().auth;
    const teamIds: string[] = [];
    if (teams.length > 0) {
      teams.forEach((el: ITeam) => {
        teamIds.push(el._id);
      });
    }
    const url = `${API_URL}/users/createAdminOrUnderling`;
    // assign automate role by default(if org doesn't have an active automate subscription, it won't add roles, we have that check on the BE)
    const roleAccess = [...rolesArray, 'automate'];
    const data = { firstName, lastName, email, roleAccess, teams: teamIds, jobTitle, ...(phoneNumber && { phoneNumber }), orgId, contact_created_by: _ADMIN_ ? 'Created by AW Admin' : 'Created by Org Admin' };

    dispatch(CreateOrgUserStartAction());

    const result = await postJson<any>(url, data, token);

    const filterTeams = (teamsArr: []) => {
      const arr:any = [];
      if (teamsArr.length > 0) {
        teamsArr.forEach((id) => {
          const team = getState().account.orgTeams.find((p) => p._id === id);
          arr.push(team);
        });
      }
      return arr;
    };

    if (result.success) {
      const { newUser } = result.data;
      const orgUser = { ...newUser, deleting: false, teams: newUser.teams?.length > 0 ? filterTeams(newUser.teams) : [] };

      dispatch(CreateOrgUserSuccessAction(orgUser));
    } else {
      dispatch(CreateOrgUserFailureAction(result.message));
    }
    return result;
  };

export const EditOrgUserThunk = (updatedUser: IActiveUser, orgId?: string, setUserAsPoc?: boolean) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { _id, email, firstName, lastName, jobTitle, phoneNumber, updatedRoles, updatedTeams } = updatedUser;
    const initialUser = getState().account.orgUsers.find((user) => user._id === updatedUser._id);

    const { token } = getState().auth;
    let unique:any = [];
    const teamIds:any = [];
    if (updatedTeams && updatedTeams.length > 0) {
      updatedTeams.forEach((el: any) => {
        teamIds.push(el._id);
      });
    }
    if (updatedRoles && updatedRoles.length > 0) {
      unique = [...new Set(updatedRoles)];
    }
    const url = `${API_URL}/users/editOrgUserProfile`;
    const data = {
      orgId,
      userId: _id,
      setUserAsPoc,
      email: email === initialUser.email ? undefined : email,
      firstName: firstName === initialUser.firstName ? undefined : firstName,
      lastName: lastName === initialUser.lastName ? undefined : lastName,
      jobTitle: jobTitle === initialUser.jobTitle ? undefined : jobTitle,
      phoneNumber: phoneNumber === initialUser.phoneNumber ? undefined : (phoneNumber || ''),
      roleAccess: unique.length ? unique : undefined,
      teams: updatedTeams ? teamIds : undefined,
    };

    const result = await patchJson<{message: string, user: IUser, orgUpdated: boolean}>(url, data, token);
    if (result.success) {
      const { user } = result.data;
      dispatch(EditOrgUserSuccessAction(user));
    }
    return result;
  };

export const DeleteOrgTeamThunk = (codeId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(DeleteOrgTeamStartAction());

    const url = `${API_URL}/teams/${codeId}`;
    const { token } = getState().auth;
    const result = await deleteRequest(url, token);

    if (result.success) {
      dispatch(DeleteOrgTeamSuccessAction(codeId));
    } else {
      dispatch(DeleteOrgTeamFailureAction());
    }
    return result;
  };

export const UpdateOrgTeamThunk = (teamId: string, name: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(UpdateOrgTeamStartAction());

    const url = `${API_URL}/teams/${teamId}`;
    const { token } = getState().auth;

    const data = { name };
    const result = await patchJson<ITeamResponse>(url, data, token);

    if (result.success) {
      const { team } = result.data;

      dispatch(UpdateOrgTeamSuccessAction(team));
    } else {
      dispatch(UpdateOrgTeamFailureAction());
    }
    return result;
  };

export const AddOrgTeamThunk = (name: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(AddOrgTeamStartAction());

    const url = `${API_URL}/teams`;
    const { token } = getState().auth;
    const data = { name };
    const result = await postJson<ITeamResponse>(url, data, token);

    if (result.success) {
      const { team } = result.data;

      dispatch(AddOrgTeamSuccessAction(team));
    } else {
      dispatch(AddOrgTeamFailureAction());
    }
    return result;
  };

export const GetOrgStatsThunk = () =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const url = `${API_URL}/organizations/getOrgStats`;
    const { token } = getState().auth;

    dispatch(GetOrgStatsStartAction());

    const result = await getJson<IOrgStats>(url, token);
    if (result.success) {
      dispatch(GetOrgStatsSuccessAction(result.data));
    }
  };
