// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  dashedBar: {
    '& .MuiLinearProgress-dashed': {
      animation: 'none',
      backgroundColor: '#D8D8D8',
      backgroundImage: 'none',
    },
  },
  noAcreage: {
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#F95264',
    },
    '& .MuiLinearProgress-bar2Buffer': {
      backgroundColor: '#F95264',
    },
  },
  hasAcreage: {
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .MuiLinearProgress-bar2Buffer': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  boldtext: {
    fontWeight: 500,
  },
  noCommitText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: theme.spacing(0.5),
  },
  noCommitHeader: {
    backgroundColor: '#E1EFF4',
    borderRadius: theme.spacing(3),
    width: 'fit-content',
    padding: '8px 12px',
    margin: 'auto',
  },
  imgIcon: {
    height: '20px',
  },
  rateText: {
    fontSize: '14px',
    lineHeight: 2,
  },
});

export default styles;
