// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

// eslint-disable-next-line import/no-unresolved
import formurlencoded from 'form-urlencoded';
import Uploader from 'Utils/multipartUpload';
import { API_URL } from 'Config';
import store from 'Store';
import { SetShowTokenExpiredDialogAction } from 'Features/auth/authActions';

const DefaultErrorMessage = 'Something went wrong';
const Success = <T>(data: T): IApiResponse<T> => ({ success: true, data });
const Fail = <T>(message = DefaultErrorMessage, errors: any = undefined): IApiResponse<T> => ({ success: false, message, errors });

const makeHttpRequest = async <TResponse>(method: string, url: string, data: any, token?: string, type?: string, signal?: AbortSignal) => {
  const headers = new Headers({
    Accept: 'application/json',
  });
  const requestParams: RequestInit = {
    method,
    signal,
  };

  if (data) {
    const requestContentType = type === 'json' ? 'application/json' : 'application/x-www-form-urlencoded';
    headers.append('Content-Type', requestContentType);

    if (type === 'json') {
      requestParams.body = JSON.stringify(data);
    } else {
      requestParams.body = formurlencoded(data);
    }
  }

  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
  }

  requestParams.headers = headers;

  try {
    const response = await fetch(url, requestParams);

    if (!response.ok) {
      if (response.status === 401) {
        store.dispatch(SetShowTokenExpiredDialogAction(true));
        return Fail<TResponse>('Incorrect email or password');
      }

      try {
        const responseContentType = response.headers.get('content-type');

        if (responseContentType && responseContentType.indexOf('text/html') !== -1) {
          const text = await response.text();
          return Fail<TResponse>(text);
        }

        // Handle the case when we get an xml response from s3
        if (responseContentType && responseContentType.indexOf('application/xml') !== -1) {
          const xml = await response.text();
          const parsedXml = new DOMParser().parseFromString(xml, 'text/xml').documentElement;
          const nodes = parsedXml.querySelectorAll('*');
          if (Array.from(nodes).some((node) => node.nodeName === 'Code' && node.textContent === 'AccessDenied')) {
            return Fail<TResponse>('AccessDenied');
          }
          return Fail<TResponse>('Some xml response');
        }

        const jsonResponse = await response.json();
        const errors: any = {};
        if (jsonResponse.errors) {
          Object.keys(jsonResponse.errors).forEach((fieldName) => {
            errors[fieldName] = jsonResponse.errors[fieldName].message;
          });
        }

        const error =
          (jsonResponse.error && jsonResponse.error.message)
          || jsonResponse.error
          || DefaultErrorMessage;

        return Fail<TResponse>(error, errors);
      } catch (e) {
        if (e.name === 'AbortError') {
          // The request was cancelled
          console.log('Request was cancelled');
        } else {
          return Fail<TResponse>();
        }
      }
      return Fail<TResponse>();
    }
    if (method === 'HEAD') {
      const contentLength = response.headers.get('content-length');
      return Success<TResponse>(contentLength as unknown as TResponse);
    }
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.indexOf('text/html') !== -1) {
      const text = await response.text();
      return Success<TResponse>(text as unknown as TResponse);
    }

    const json = await response.json();
    return Success<TResponse>(json);
  } catch (e) {
    return Fail<TResponse>();
  }
};

export const getJson = async <TResponse>(url: string, token?: string) => makeHttpRequest<TResponse>('GET', url, null, token, 'json');
export const postJson = async <TResponse>(url: string, data?: any, token?: string, signal?: AbortSignal) =>
  makeHttpRequest<TResponse>('POST', url, data, token, 'json', signal);
export const patchJson = async <TResponse>(url: string, data: any, token: string) => makeHttpRequest<TResponse>('PATCH', url, data, token, 'json');
export const deleteRequest = async (url: string, token: string, data?: any) => makeHttpRequest('DELETE', url, data, token, 'json');
export const headRequest = async (url: string) => makeHttpRequest('HEAD', url, null);

export const postForm = async <TResponse>(url: string, data: any, token: string) => makeHttpRequest<TResponse>('POST', url, data, token, 'form');

export const getFile = async (url: string) => {
  const requestParams = {
    method: 'GET',
  };

  try {
    const response = await fetch(url, requestParams);
    if (!response.ok) {
      return Fail<string>();
    }

    const blob = await response.text();
    return Success(blob);
  } catch {
    return Fail<string>();
  }
};

export const getFileBlob = async (url: string) => {
  const requestParams = {
    method: 'GET',
  };

  try {
    const response = await fetch(url, requestParams);
    if (!response.ok) {
      return Fail<string>();
    }

    const blob = await response.blob();
    return Success(blob);
  } catch {
    return Fail<string>();
  }
};

export const postFile = (url: string, token: string, file: Blob, fileName: string, fieldName: string, expressAIUpload: boolean, epsg: number, imageryCaptureDate: string, onFileProgress: (id: string, percentage?: number, fileId?: string, fileKey?: string) => void, onFileLoad: (res: string) => void, id: string, fileUploadKey?: string, fileSizeInGB?: number, maxSizeInGB?: number, uploadingProcessedLAS?: boolean, skipUpdateKml?: boolean) => {
  if (fileSizeInGB && fileSizeInGB > maxSizeInGB) {
    return;
  }
  const req = new XMLHttpRequest();
  if (fieldName !== 'tiffile') {
    req.upload.addEventListener('progress', (event) => {
      if (!event.lengthComputable) return;

      const percentage = (event.loaded / event.total) * 100;
      onFileProgress(id, percentage);
    });
  }
  // req.upload.addEventListener('load', onFileLoad);
  req.onreadystatechange = () => {
    if (req.readyState === XMLHttpRequest.DONE) {
      onFileLoad(req.responseText);
    }
  };

  const formData = new FormData();
  if (fieldName === 'cadfile') {
    formData.append('expressAIUpload', JSON.stringify(expressAIUpload));
    formData.append('epsg', JSON.stringify(epsg));
  }
  if (fieldName === 'tiffile' || fieldName === 'lasfile') {
    const uploaderOptions = {
      fileName,
      file,
      uploadingProcessedLAS,
    };
    const uploaderClass = new Uploader(uploaderOptions);
    // fileUploadKey - orgid/projectid to upload a tif/las file in s3
    // fileUploadKey - orgid/projectid/orderid to upload an output processed LAS
    uploaderClass.start(uploaderOptions, fileUploadKey, token);
    let percentage = 0;
    uploaderClass.onProgress((progress: { percentage: number }) => {
      if (progress.percentage !== percentage) {
        percentage = progress.percentage;
        onFileProgress(id, percentage, uploaderClass.fileId, uploaderClass.fileKey);
        if (percentage === 100) {
          const checkStatus = () => {
            if (uploaderClass.status === 'completed') {
              const headers = new Headers({
                Accept: 'application/json',
              });
              headers.append('Content-Type', 'application/json');
              headers.append('Authorization', `Bearer ${token}`);
              const data: any = {};
              if (uploaderClass.fileKey && uploaderClass.bucket) {
                data.key = uploaderClass.fileKey;
                data.bucket = uploaderClass.bucket;
              }
              if (imageryCaptureDate) {
                data.imageryCaptureDate = imageryCaptureDate;
              }
              const onLoad = async () => {
                const resp = await fetch(`${url}`, {
                  method: 'POST',
                  body: JSON.stringify(data),
                  headers,
                });
                const dataResp = await resp.json();
                const jsonString = JSON.stringify(dataResp);
                onFileLoad(jsonString);
              };
              onLoad();
              clearInterval(intervalId);
            }
          };
          const intervalId = setInterval(checkStatus, 1000);
        }
      }
    });
  }
  if (fieldName !== 'tiffile' && fieldName !== 'lasfile') {
    formData.append(fieldName, file, fileName);
    formData.append('skipUpdateKml', JSON.stringify(skipUpdateKml));
    req.open('POST', url);
    req.setRequestHeader('Authorization', `Bearer ${token}`);
    req.send(formData);
  }
  // eslint-disable-next-line consistent-return
  return req;
};

export const postOpsFile = (url: string, notes: string, token: string, file: Blob, fileName: string, fieldName: string) => {
  const req = new XMLHttpRequest();
  const formData = new FormData();
  formData.append(fieldName, file, fileName);
  formData.append('notes', notes);

  req.open('POST', url);
  req.setRequestHeader('Authorization', `Bearer ${token}`);
  req.send(formData);

  return req;
};
