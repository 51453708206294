// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import moment from 'moment-timezone';
import { Box, useTheme } from '@mui/system';
import { Typography, Select, MenuItem, InputBase, Divider, Button, Fade, CircularProgress, TextField } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { billingMethods, pricingDescription } from 'Utils/constants';
import TextEditor from 'Components/TextEditor';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { AssignPricingPlanThunk, GetOrgPastPlansThunk, UpdateCostPerAcreThunk, UpdateOrgThunk } from 'Features/admin/adminThunk';
import { RootState } from 'Store';
import DatePicker from './datePicker';
import PlanHistory from './planHistory';
import stylesDef from './styles';

moment.tz.setDefault('America/New_York');

export const SubscriptionInfo = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const { imageryProviders } = useAppSelector(
    (state: RootState) => state.imagery,
  );
  const { user } = useAppSelector(
    (state: RootState) => state.auth,
  );
  const { org, updatingOrgPricingPlan, automateSubscription, expressAISubscription } = useAppSelector(
    (state: RootState) => state.admin,
  );
  const { costPerAcre, createdAt, contractUrl, billingMethod } = org || {};
  const pricingPlanExists = !!(org.currentPricingPlanInfo);
  const [openStartDatepicker, setOpenStartDatepicker] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(org.currentPricingPlanInfo?.startDate || moment(new Date()).format());
  const [selectedEndDate, setSelectedEndDate] = useState(org.currentPricingPlanInfo?.endDate || moment(new Date()).format());
  const [pricingPlanName, setPricingPlanName] = useState(org.currentPricingPlanInfo?.name || '');
  const [creditsPurchased, setCreditsPurchased] = useState(org.currentPricingPlanInfo?.creditsPurchased || 0);
  const [startingCredits, setStartingCredits] = useState(org.currentPricingPlanInfo?.startingCredits || 0);
  const [openEndDatepicker, setOpenEndDatepicker] = useState(false);
  const [editPricingPlan, setEditPricingPlan] = useState(false);
  const [showOrgPlanHistory, setShowOrgPlanHistory] = useState(false);
  const [planHistory, setPlanHistory] = useState([]);

  const { name, automateCurrentPeriodEnd, automateStartingAcres, automateAcresProcessed, automateAcresRemaining } = automateSubscription || {};

  const { name: expressAIName, expressAICurrentPeriodStart, expressAICurrentPeriodEnd, expressAIStartingCredits, expressAIRemainingCredits } = expressAISubscription || {};

  const handleChangeNearmap = (value: string) => {
    dispatch(UpdateOrgThunk({ _id: org._id, imageryProvider: value }));
  };

  const updateOrgContractUrl = (url: string) => {
    dispatch(UpdateOrgThunk({ _id: org._id, contractUrl: url }));
  };

  const handleChangeBillingMethod = (method: string) => {
    dispatch(UpdateOrgThunk({ _id: org._id, billingMethod: method }));
  };

  const resetPricingPlan = () => {
    if (org?.currentPricingPlanInfo) {
      const { startDate, endDate } = org.currentPricingPlanInfo;
      const orgPricingPlanName = org.currentPricingPlanInfo.name;
      const orgStartingCredits = org.currentPricingPlanInfo.startingCredits;
      const orgCreditsPurchased = org.currentPricingPlanInfo.creditsPurchased;
      setSelectedStartDate(startDate);
      setSelectedEndDate(endDate);
      setPricingPlanName(orgPricingPlanName);
      setCreditsPurchased(orgCreditsPurchased);
      setStartingCredits(orgStartingCredits);
    } else {
      setSelectedStartDate(moment(new Date()).format());
      setSelectedEndDate(moment(new Date()).format());
      setPricingPlanName('');
      setCreditsPurchased(0);
      setStartingCredits(0);
    }
  };

  const savePricingPlan = async () => {
    const pricingData = {
      startDate: selectedStartDate,
      pricingPlanName,
      startingCredits,
      ...(pricingPlanName !== 'no_commit' && { endDate: selectedEndDate, creditsPurchased }),
    };
    const resultSuccess = await dispatch(AssignPricingPlanThunk(pricingData));
    setEditPricingPlan(false);
    if (!resultSuccess) {
      resetPricingPlan();
    }
  };

  const cancelEditPricingPlan = async () => {
    setEditPricingPlan(false);
    resetPricingPlan();
  };

  const getOrgPlanHistory = async () => {
    const pastPlanData = await dispatch(GetOrgPastPlansThunk());
    if (pastPlanData?.length) {
      setPlanHistory(pastPlanData);
    }
    setShowOrgPlanHistory(true);
  };

  const onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowOrgPlanHistory(false);
    e.stopPropagation();
  };

  const getPlanLength = () => {
    const momentStartDate = moment(selectedStartDate);
    const momentEndDate = moment(selectedEndDate);
    const diffDuration = moment.duration(momentEndDate.diff(momentStartDate));
    let planLength = '';
    const diffYears = diffDuration.years();
    if (diffYears > 0) {
      planLength += `${diffYears} year(s)`;
    }
    const diffMonths = diffDuration.months();
    if (diffMonths > 0) {
      planLength += ` ${diffMonths} month(s)`;
    }
    const diffDays = diffDuration.days();
    if (diffDays > 0) {
      planLength += ` ${diffDays} day(s)`;
    }
    return planLength;
  };

  return (
    <>
      <Typography variant="h4" sx={styles.sectionTitle}>Subscription Info</Typography>
      <Divider sx={styles.divider} />
      <Box sx={styles.subscriptionDate}>
        <Typography>Nearmap Tier</Typography>
        {imageryProviders && (
          <Select
            variant="outlined"
            input={<InputBase sx={styles.inputBase} />}
            value={org.imageryProviders?.[0]}
            onChange={(e) => handleChangeNearmap(e.target.value)}
          >
            {
              imageryProviders.map(({ name: ipName, _id }) => <MenuItem key={_id} value={_id}>{ipName}</MenuItem>)
            }
          </Select>
        )}
      </Box>
      <Box sx={styles.subscriptionDate}>
        <Typography>Contract URL</Typography>
        <TextEditor
          initialValue={contractUrl}
          updateValue={(value) => updateOrgContractUrl(value)}
          linkText
        />
      </Box>
      <Box sx={styles.subscriptionDate}>
        <Typography>Billing Method</Typography>
        <Select
          variant="outlined"
          input={<InputBase sx={styles.billingMethod} />}
          value={billingMethod || ''}
          onChange={(e) => handleChangeBillingMethod(e.target.value)}
        >
          {
            billingMethods.map((method: string) => <MenuItem key={method} value={method}>{method}</MenuItem>)
          }
        </Select>
      </Box>
      <Box>
        <Typography variant="h4" sx={styles.sectionTitle}>Plan Info</Typography>
        <Divider sx={styles.divider} />
        {(!editPricingPlan && pricingPlanExists) && (
          <Box sx={styles.listContent}>
            <Typography>Pricing Plan Start Date</Typography>
            <Typography>{moment(selectedStartDate).format('LL')}</Typography>
            {pricingPlanName !== 'no_commit' && (
              <>
                <Typography>Contract End Date</Typography>
                <Typography>{moment(selectedEndDate).format('LL')}</Typography>
                <Typography>Plan Length</Typography>
                <Typography>{getPlanLength()}</Typography>
              </>
            )}
            <Typography>Pricing Tier</Typography>
            <Typography>{pricingDescription.find((tier) => tier.name === pricingPlanName)?.displayName}</Typography>
            {startingCredits >= 0 && (
              <>
                <Typography>Starting Credits</Typography>
                <Typography>{startingCredits}</Typography>
              </>
            )}
            {pricingPlanName !== 'no_commit' && (
              <>
                <Typography>Credits Purchased</Typography>
                <Typography>{creditsPurchased}</Typography>
              </>
            )}
            {pricingPlanName === 'no_commit' && (
              <>
                <Typography>PAYG Rate</Typography>
                <Typography>{`$${org.currentPricingPlanInfo?.rate}`}</Typography>
              </>
            )}
          </Box>
        )}
        {!editPricingPlan && (
          <Box sx={styles.planButtonGroup}>
            <Button disabled={!user?.pricingAccess} sx={styles.planButton} onClick={() => setEditPricingPlan(true)} color="primary" variant="contained">
              {pricingPlanExists ? 'Edit Plan' : 'Assign New Plan'}
            </Button>
            {pricingPlanExists && (
              <Button variant="text" color="primary" disableRipple sx={styles.historyButton} onClick={getOrgPlanHistory}>
                <AccessTimeIcon sx={styles.historyButtonIcon} />
                <Typography variant="h4" color="primary" sx={styles.historyButtonText}>View Plan History</Typography>
              </Button>
            )}
          </Box>
        )}
        {editPricingPlan && (
          <>
            <Box sx={styles.formContent}>
              <Typography>Pricing Plan Start Date</Typography>
              <Box>
                <DatePicker openDatepicker={openStartDatepicker} setOpenDatepicker={setOpenStartDatepicker} selectedDate={selectedStartDate} setSelectedDate={setSelectedStartDate} />
              </Box>
              {pricingPlanName !== 'no_commit' && (
                <>
                  <Typography>Contract End Date</Typography>
                  <Box>
                    <DatePicker openDatepicker={openEndDatepicker} setOpenDatepicker={setOpenEndDatepicker} selectedDate={selectedEndDate} setSelectedDate={setSelectedEndDate} />
                  </Box>
                </>
              )}
              <Typography>Pricing Tier</Typography>
              <Box>
                <Select
                  variant="outlined"
                  input={<InputBase sx={styles.inputBase} />}
                  value={pricingPlanName}
                  onChange={(e) => setPricingPlanName(e.target.value)}
                >
                  {pricingDescription.map((plan) => (
                    <MenuItem value={plan.name} key={plan.name}>{plan.displayName}</MenuItem>
                  ))}
                </Select>
              </Box>
              <Typography>Starting Credits</Typography>
              <Box sx={styles.textFieldDiv}>
                <TextField
                  id="startingCredits"
                  variant="standard"
                  type="number"
                  inputProps={{ min: 0 }}
                  onChange={(e) => { setStartingCredits(parseFloat(e.target.value)); }}
                  value={startingCredits?.toString()}
                  sx={styles.textField}
                />
              </Box>
              {pricingPlanName !== 'no_commit' && (
                <>
                  <Typography>Credits Purchased</Typography>
                  <Box sx={styles.textFieldDiv}>
                    <TextField
                      id="creditsPurchased"
                      variant="standard"
                      type="number"
                      inputProps={{ min: 0 }}
                      onChange={(e) => { setCreditsPurchased(parseFloat(e.target.value)); }}
                      value={creditsPurchased?.toString()}
                      sx={styles.textField}
                    />
                  </Box>
                </>
              )}
            </Box>
            <Box sx={styles.buttonGroup}>
              <Button
                sx={styles.planButton}
                disabled={(!selectedStartDate || !pricingPlanName || (pricingPlanName !== 'no_commit' && (!selectedEndDate || !creditsPurchased)))}
                onClick={savePricingPlan}
                color="primary"
                variant="contained"
              >
                <Fade in={updatingOrgPricingPlan}>
                  <CircularProgress size={24} sx={styles.buttonProgress} />
                </Fade>
                <Fade in={!updatingOrgPricingPlan}>
                  <span>Save Changes</span>
                </Fade>
              </Button>
              <Button sx={styles.planButton} onClick={cancelEditPricingPlan} variant="text">Cancel</Button>
            </Box>
          </>
        )}
        <PlanHistory showDialog={showOrgPlanHistory} planHistory={planHistory} onCancel={onCancel} />
      </Box>
      {Object.keys(automateSubscription).length > 0 && (
        <>
          <Divider sx={styles.divider} />
          <Box sx={styles.listContent}>
            <Typography variant="h5">{name}</Typography>
            <Typography> </Typography>
            <Typography>
              Eligible to renew on
              {' '}
              {moment(automateCurrentPeriodEnd).format('LL')}
            </Typography>
            <Typography> </Typography>
            <Typography>Subscription Start Date</Typography>
            <Typography>{moment(org.currentContractStart || createdAt).format('LL')}</Typography>
            <Typography>Starting Credits</Typography>
            <Typography>{automateStartingAcres ? Number.parseFloat(automateStartingAcres).toFixed(2) : 'N/A'}</Typography>
            <Typography>Credits Processed</Typography>
            <Typography>{automateAcresProcessed ? automateAcresProcessed.toFixed(2) : 'N/A'}</Typography>
            <Typography>Credits Remaining</Typography>
            <Typography>{automateAcresRemaining ? automateAcresRemaining.toFixed(2) : 'N/A'}</Typography>
            <Typography>Price per Credit</Typography>
            <TextEditor initialValue={String(costPerAcre)} updateValue={(value) => dispatch(UpdateCostPerAcreThunk(value))} formatValue={(value) => `$${value}`} />
          </Box>
        </>
      )}
        {Object.keys(expressAISubscription).length > 0 && (
        <>
          <Divider sx={styles.divider} />
          <Box sx={styles.listContent}>
            <Typography variant="h5">{expressAIName}</Typography>
            <Typography> </Typography>
            <Typography>
              Eligible to renew on
              {' '}
              {moment(expressAICurrentPeriodEnd).format('LL')}
            </Typography>
            <Typography> </Typography>
            <Typography>Subscription Start Date</Typography>
            <Typography>{moment(expressAICurrentPeriodStart|| createdAt).format('LL')}</Typography>
            <Typography>Starting Credits</Typography>
            <Typography>{expressAIStartingCredits ? expressAIStartingCredits.toFixed(2) : 'N/A'}</Typography>
            <Typography>Credits Remaining</Typography>
            <Typography>{expressAIRemainingCredits ? expressAIRemainingCredits.toFixed(2) : 'N/A'}</Typography>
          </Box>
        </>
      )}
    </>
  );
};

export default SubscriptionInfo;
