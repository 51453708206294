// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  map: {
    position: 'relative',
    height: 'calc( 100% - 48px )',
    width: '100%',
  },
  infoBar: {
    width: '100%',
    height: theme.spacing(6),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '10px',
    gap: '10px',
  },
  pointsText: {
    fontWeight: 400,
    fontSize: '14px',
  },
  creditContainer: {
    '& .cesium-credit-expand-link': {
      color: '#000',
      textShadow: 'none',
      width: '80px',
    },
    '& .cesium-widget-credits': {
      position: 'relative',
      marginLeft: theme.spacing(1),
      textShadow: 'none',
      display: 'flex',
      columnGap: theme.spacing(0.5),
      alignItems: 'center',
    },
  },
  mapControls: {
    position: 'absolute',
    right: theme.spacing(6),
    bottom: theme.spacing(10),
  },
  mapButton: {
    padding: theme.spacing(1),
    minWidth: 0,
  },
  divider: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
});

export default styles;
