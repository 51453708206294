// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.darkGrey,
    boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.1)',
    fontWeight: 400,
    fontSize: '14px',
    borderRadius: '0px',
    padding: theme.spacing(2),
    maxWidth: '300px',
  },
}));

export const WideLightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.darkGrey,
    boxShadow: '0px 2px 12px 0px rgba(0,0,0,0.1)',
    fontWeight: 300,
    fontSize: '14px',
    borderRadius: '4px',
    padding: theme.spacing(2),
    maxWidth: '350px',
    margin: '1px',
  },
}));

export const TeamTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.darkGrey,
    boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.1)',
    fontWeight: 400,
    fontSize: '14px',
    borderRadius: '0px',
    maxWidth: '200px',
  },
}));

export default LightTooltip;
