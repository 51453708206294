// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { ReactNode } from 'react';
import { Paper } from '@mui/material';
import stylesDef from './styles';

interface ComponentProps {
  children: ReactNode;
}

export const Sidebar: React.FC<ComponentProps> = ({ children, ...props }) => {
  const styles = stylesDef();

  return (
    <Paper sx={styles.sidebar} elevation={1} {...props}>
      {children}
    </Paper>
  );
};

export default Sidebar;
