// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Button, CircularProgress, Collapse, Fade, SnackbarContent, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Form } from 'react-final-form';
import { Helpers, TextField, Validators } from 'Components/forms';
import { Box, useTheme } from '@mui/system';
import stylesDef from './styles';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { CreateOrgThunk } from 'Features/admin/adminThunk';

export const CreateOrgForm = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();

  const CreateOrg = (data: any) => dispatch(CreateOrgThunk(data));

  const onFormSubmit = (values: any) => Helpers.submit(values, CreateOrg);

  const { createOrgSuccess } = useAppSelector(
    (state) => state.admin,
  );

  return (
    <Box sx={styles.pageTitle}>
      <Typography variant="h1">Create New Organization</Typography>
      <Form
        onSubmit={onFormSubmit}
        render={({ pristine, submitError, hasSubmitErrors, modifiedSinceLastSubmit, submitting, submitSucceeded, handleSubmit }) => (
          <form
            css={styles.form}
            onSubmit={handleSubmit}
          >
            <TextField
              name="firstName"
              label="First Name"
              variant="outlined"
              placeholder="First Name"
              validate={Validators.required}
              inputProps={{
                styles: {
                  notchedOutline: styles.outline,
                  input: styles.input,
                },
              }}
              inputLabelProps={{
                styles: {
                  outlined: styles.inputLabel,
                  shrink: styles.inputLabelShrink,
                },
              }}
              helperTextProps={{
                styles: {
                  root: styles.helperText,
                },
              }}
            />
            <TextField
              name="lastName"
              label="Last Name"
              placeholder="Last Name"
              variant="outlined"
              validate={Validators.required}
              inputProps={{
                styles: {
                  notchedOutline: styles.outline,
                  input: styles.input,
                },
              }}
              inputLabelProps={{
                styles: {
                  outlined: styles.inputLabel,
                  shrink: styles.inputLabelShrink,
                },
              }}
              helperTextProps={{
                styles: {
                  root: styles.helperText,
                },
              }}
            />
            <TextField
              name="phoneNumber"
              label="Phone Number"
              placeholder="Phone Number"
              variant="outlined"
              validate={Validators.required}
              inputProps={{
                styles: {
                  notchedOutline: styles.outline,
                  input: styles.input,
                },
              }}
              inputLabelProps={{
                styles: {
                  outlined: styles.inputLabel,
                  shrink: styles.inputLabelShrink,
                },
              }}
              helperTextProps={{
                styles: {
                  root: styles.helperText,
                },
              }}
            />
            <TextField
              name="companyName"
              label="Company Name / Organization"
              placeholder="Company Name"
              variant="outlined"
              validate={Validators.required}
              inputProps={{
                styles: {
                  notchedOutline: styles.outline,
                  input: styles.input,
                },
              }}
              inputLabelProps={{
                styles: {
                  outlined: styles.inputLabel,
                  shrink: styles.inputLabelShrink,
                },
              }}
              helperTextProps={{
                styles: {
                  root: styles.helperText,
                },
              }}
            />
            <TextField
              name="email"
              label="Email"
              placeholder="Email"
              variant="outlined"
              validate={Validators.required}
              inputProps={{
                styles: {
                  notchedOutline: styles.outline,
                  input: styles.input,
                },
              }}
              inputLabelProps={{
                styles: {
                  outlined: styles.inputLabel,
                  shrink: styles.inputLabelShrink,
                },
              }}
              helperTextProps={{
                styles: {
                  root: styles.helperText,
                },
              }}
            />
            <Box sx={styles.buttonsWrapper}>
              <Collapse in={!submitting && submitSucceeded}>
                <SnackbarContent
                  sx={styles.successSnackbar}
                  message={(
                    <Box sx={styles.snackbarContent}>
                      <CheckCircleIcon />
                      {createOrgSuccess}
                      {' '}
                      Org Created Successfully
                    </Box>
                  )}
                />
              </Collapse>
              <Collapse in={Boolean(hasSubmitErrors && !modifiedSinceLastSubmit)}>
                <Box sx={styles.errorMessage}>
                  <ErrorIcon />
                  <Typography variant="body1" sx={styles.messageText}>
                    {submitError}
                  </Typography>
                </Box>
              </Collapse>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                sx={styles.submitButton}
                type="submit"
                disabled={pristine || (hasSubmitErrors && !modifiedSinceLastSubmit) || submitting}
              >
                <Fade in={submitting}>
                  <CircularProgress size={24} sx={styles.buttonProgress} />
                </Fade>
                <Fade in={!submitting}>
                  <span>Create Organization</span>
                </Fade>
              </Button>
            </Box>
          </form>
        )}
      />
    </Box>
  );
};

export default CreateOrgForm;
