// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  title: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  },
  buttons: {
    padding: 0,
    margin: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  cancelButton: {
    color: '#021E40',
    opacity: '50%',
    fontSize: theme.spacing(2),
  },
  okButton: {
    color: theme.palette.primary.main,
    fontSize: theme.spacing(2),
  },
});

export default styles;
