// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { CircularProgress, InputBase } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { useAppSelector } from 'Hooks';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import stylesDef from './styles';

interface componentProps {
  setSearchInputValue: (value: string) => void;
  inputProps: any;
  inputRef?: any;
}

const Input: React.FC<componentProps> = ({ setSearchInputValue, inputRef = () => { }, inputProps: { ref, ...other } }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const mapSearchLoading = useAppSelector((state) => state.map.common.geocode.mapSearchLoading);

  return (
    <Box sx={styles.autosuggestInputWrapper}>
      <SearchIcon sx={styles.searchIcon} />
      <InputBase
        inputRef={(node) => {
          ref(node);
          inputRef(node);
        }}
        sx={styles.autosuggestInput}
        {...other}
      />
      {mapSearchLoading
        && <CircularProgress sx={styles.autosuggestProgress} size={20} />}
      {other.value
        && <CloseIcon sx={styles.autosuggestClearIcon} onClick={() => setSearchInputValue('')} />}
    </Box>
  );
};

export default Input;
