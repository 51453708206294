// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createSelector } from 'reselect';
import { RootState } from 'Store';

const selectProject = (state: RootState) => state.project.project;

// Selector to return all uploaded files for a given project
const selectProjectFiles = createSelector(
  [selectProject],
  (project) => {
    if (project) {
      const tifFiles = project.tifFiles?.map((tifFile) => ({ name: tifFile.filename.split('/').pop(), _id: tifFile._id, epsg: tifFile.epsg, type: 'tif' }));
      const lasFiles = project.lasFiles?.map((lasFile) => {
        const { filename, _id, epsg, tile3DUrl, tileStatuses, lasType } = lasFile;
        return {
          name: filename.split('/').pop(),
          _id,
          epsg,
          type: 'las',
          tile3DUrl,
          tileStatuses,
          lasType,
        };
      });
      const projectFiles: IProjectFile[] = [...tifFiles, ...lasFiles];
      return projectFiles;
    }
    return [];
  },
);

const select3DTileStatus = createSelector(
  [selectProjectFiles],
  (projectFiles) => {
    const files = projectFiles.filter((file) => file.type === 'las' && file.lasType === 'unclassified');
    const tileLoading = files.some((file) => file.tile3DUrl !== undefined && file.tile3DUrl?.length === 0 && !file.tileStatuses?.some((item) => item?.status === 'ERROR'));
    const tile3DError = files.some((file) => file.tileStatuses?.some((item) => item?.status === 'ERROR'));
    return {
      tileLoading,
      tile3DError,
    };
  },
);

const getRasterRequestStatus = createSelector([selectProject], (project) => {
  if (project?.rasterRequest) {
    const { rasterRequest } = project;
    const { requested, rejected, orderPlaced } = rasterRequest;
    return {
      requested: requested && !rejected && !orderPlaced,
      rejected: !!rejected,
      orderPlaced: !!orderPlaced,
    };
  }
  return {};
});

const getProjectEPSGCode = createSelector([selectProject], (project) => {
  const tifEpsgs = project?.tifFiles?.filter((tif) => (tif.epsg > -1)).map((tif) => tif.epsg);
  const lasEpsgs = project?.lasFiles?.filter((las) => (las.epsg > -1)).map((las) => las.epsg);
  const tifEpsg = tifEpsgs?.length ? tifEpsgs[0] : null;
  const lasEpsg = lasEpsgs?.length ? lasEpsgs[0] : null;
  const epsgMatch = tifEpsgs?.some((epsg) => lasEpsgs?.includes(epsg));

  return { tifEpsg, lasEpsg, epsgMatch };
});

const selectCRSInfo = createSelector(
  [(state: RootState) => state.project.epsgProjections],
  (epsgProjections) => {
    const projCSStart = epsgProjections && epsgProjections.search('PROJCS');
    const projCS = projCSStart === 0 ? epsgProjections.match(/"([^"]+)"/)[1] : 'Unavailable';
    const geogCSStart = epsgProjections && epsgProjections.search('GEOGCS');
    const geogCS = geogCSStart && geogCSStart !== -1 ? epsgProjections.substring(geogCSStart).match(/"([^"]+)"/)[1] : 'Unavailable';
    return { geogCS, projCS };
  },
);

const selectProjectEPSGCode = createSelector(
  [selectProject],
  (project) => {
    const tifEpsgs = project?.tifFiles?.filter((tif) => (tif.epsg > -1)).map((tif) => tif.epsg);
    const lasEpsgs = project?.lasFiles?.filter((las) => (las.epsg > -1)).map((las) => las.epsg);
    const tifEpsg = tifEpsgs.length ? tifEpsgs[0] : null;
    const lasEpsg = lasEpsgs.length ? lasEpsgs[0] : null;
    const epsgMatch = tifEpsgs?.some((epsg) => lasEpsgs?.includes(epsg));

    return { tifEpsg, lasEpsg, epsgMatch };
  },
);

export {
  selectProjectFiles,
  select3DTileStatus,
  getRasterRequestStatus,
  getProjectEPSGCode,
  selectCRSInfo,
  selectProjectEPSGCode,
};
