// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  errorPaper: {
    position: 'relative',
    padding: theme.spacing(2),
    width: '900px',
  },
  errorControls: {
    display: 'grid',
    gridTemplateColumns: 'auto auto 5fr 1fr',
    gridGap: theme.spacing(1),
    alignItems: 'center',
    paddingRight: theme.spacing(2),
  },
  errorIcon: {
    color: '#F95264',
  },
  errorStacks: {
    position: 'absolute',
    top: theme.spacing(3),
    left: theme.spacing(3),
    zIndex: 1,
  },
  closeIcon: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
  },
  loadingModal: {
    zIndex: 2,
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, .4)',
    top: 0,
    left: 0,
    color: '#eee',
    textAlign: 'center',
    '& p': {
      top: '45%',
      left: '45%',
      fontSize: '1.5em',
      position: 'absolute',
    },
  },
  link: {
    color: 'inherit',
    textDecoration: 'underline',
  },
});

export default styles;
