// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Typography, List, ListItemIcon } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import CheckIcon from '@mui/icons-material/Check';
import { Box, useTheme } from '@mui/system';
import { useAppSelector } from 'Hooks';
import stylesDef from './styles';

const IncludedAddOns = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const { selectedBundle, bundleDeliverables } = useAppSelector(
    (state) => state.bundle,
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {selectedBundle?.name === 'A la carte' ? (
        <Box sx={styles.infoBox}>
          <Box sx={styles.infoTitle}>
            <HelpIcon sx={styles.icon} />
            <Typography variant="h3">A la carte Bundle</Typography>
          </Box>
          <Typography variant="body1" sx={styles.infoText}>
            If you only want a select few layers on your .dxf, select this option.
            Next, you'll be able to pick which specific layers you want. A-la-carte layer selection enables us to deliver your project faster.
          </Typography>
        </Box>
      ) : (
        <Box sx={styles.list}>
          {selectedBundle?.bundleId !== 2 && (
            <Box sx={styles.insideDiv}>
              <Typography variant="h4">Layers</Typography>
              {bundleDeliverables.map(({ type, deliverableId, name }) =>
                (type === 'layer' ? (
                  <List sx={styles.bundleListItem} key={deliverableId}>
                    <ListItemIcon sx={styles.listItemIcon}>
                      <CheckIcon sx={styles.finishedIcon} />
                    </ListItemIcon>
                    {deliverableId === 20 && selectedBundle?.name === '3D Feature Elevation'
                      ? 'Building Footprints'
                      : name}
                  </List>
                ) : null))}
            </Box>
          )}
          <Box sx={styles.insideDiv}>
            <Typography variant="h4">Deliverables</Typography>
            {bundleDeliverables.map((item) =>
              (item.type === 'deliverable' ? (
                <List sx={styles.bundleListItem} key={item.deliverableId}>
                  <ListItemIcon sx={styles.listItemIcon}>
                    <CheckIcon sx={styles.finishedIcon} />
                  </ListItemIcon>
                  {item.name}
                </List>
              ) : null))}
          </Box>
        </Box>
      )}
    </>
  );
};

export default IncludedAddOns;
