// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import history from 'Utils/history';
import { TransitionProps } from '@mui/material/transitions/transition';
import { Dialog, Slide, IconButton, LinearProgress, Button, Typography, Paper, TextField, Fade, CircularProgress, Tooltip } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { GetAdminCadTemplateRoute, GetTemplateRoute } from 'Utils/routes';
import { airworksLayers } from 'Utils/airworksLayers';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import uploadFilesIcon from 'Assets/upload-cad.png';
import { useAppDispatch, useAppSelector } from 'Hooks';
import Dropzone from 'Components/dropzone';
import ConfirmDialog from 'Components/ConfirmDialog';
import { FileUploadCancelThunk, FileUploadStartThunk } from 'Features/fileUpload/fileUploadThunk';
import { EditTemplate, SetTemplateId, AddLayers } from '../cadTemplateThunk';
import Matching from './Matching';
import stylesDef from './styles';

const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => <Slide ref={ref} direction="up" {...props} />);

interface ComponentProps {
  showDialog: boolean;
  setShowDialog: (show: boolean) => void;
}

const AddTemplateDialog: React.FC<ComponentProps> = ({ showDialog, setShowDialog }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const { uploadFiles } = useAppSelector((state) => state.fileUpload);
  const { templateId, list } = useAppSelector((state) => state.cadTemplate);
  const file = uploadFiles.find((f: IFile) => f.extension === 'dxf');
  const currentTemplate = list?.find((t: any) => t._id === templateId);

  const [loading, setLoading] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [showDropzone, setShowDropzone] = useState(true);
  const [correctMatching, setCorrectMatching] = React.useState(null);
  const [templateName, setTemplateName] = useState('');
  const [openWarning, setOpenWarning] = React.useState(false);
  const [fileName, setFileName] = useState(null);

  const unmatchedLayers = correctMatching && (Object.keys(correctMatching).length > 0 && (Object.keys(correctMatching).length < 14 || Object.values(correctMatching).includes(null)));
  const canAddTemplate = correctMatching && (Object.keys(correctMatching).length > 0);
  const orgId = _ADMIN_ && useParams().orgId;

  useEffect(() => {
    dispatch(SetTemplateId(null));
    setShowDropzone(true);
    if (showDialog) {
      setShowUpload(false);
    }

    return () => {
      setCorrectMatching(null);
      setTemplateName('');
      setFileName(null);
    };
  }, [showDialog]);

  const onOkCloseDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setOpenWarning(false);
    setShowDialog(false);
    e.stopPropagation();
    if (fileName) {
      window?.pendo?.track('Client Template Created', { template_name: fileName });
    }
  };

  const onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setOpenWarning(false);
    e.stopPropagation();
  };

  const closeDialogClick = () => {
    setOpenWarning(true);
  };

  const addTemplate = async () => {
    const editArray: any[] = [];
    if (currentTemplate) {
      currentTemplate.layers.forEach((layer) => {
        const keys = Object.keys(correctMatching).filter((k) => correctMatching[k] === layer.name);
        const l: any = { _id: '', mapping: [] };
        l._id = layer._id;
        l.mapping = keys;
        editArray.push(l);
      });
    }
    setLoading(true);
    if (unmatchedLayers) {
      let newLayers: string[] = [];
      const temp = Object.keys(correctMatching).filter((l) => !correctMatching[l]);
      newLayers = [...newLayers, ...temp];
      const difference = airworksLayers.filter((l) => !Object.keys(correctMatching).includes(l));
      newLayers = [...newLayers, ...difference];
      const newLayersArr: {name: string, mapping: string[]}[] = [];
      newLayers.forEach((l) => newLayersArr.push({ name: l, mapping: [l] }));
      const data: {templateId: string, newLayers: any[]} = { templateId, newLayers: newLayersArr };
      await dispatch(AddLayers(data));
    }
    await dispatch(EditTemplate({ _id: templateId, layers: editArray }));
    if (templateName.trim() !== '') {
      await dispatch(EditTemplate({ _id: templateId, name: templateName }));
    }
    setLoading(false);
    setTemplateName('');
    if (_ADMIN_) {
      history.push(GetAdminCadTemplateRoute(orgId, templateId));
    } else {
      history.push(GetTemplateRoute(templateId));
    }
    window?.pendo?.track('Client Template Created', { template_name: templateName.length ? templateName : fileName });
    setShowDialog(false);
  };
  return (
    <Dialog
      fullScreen
      open={showDialog}
      onClose={() => setShowDialog(false)}
      TransitionComponent={Transition}
      keepMounted={false}
    >
      <Box sx={styles.closeButtonWrapper}>
        <IconButton
          color="inherit"
          onClick={() => {
            closeDialogClick();
            if (showUpload) {
              history.push(GetTemplateRoute(templateId));
            }
          }}
          aria-label="Close"
        >
          <ConfirmDialog
            showDialog={openWarning}
            onOk={onOkCloseDialog}
            onCancel={onCancel}
            contentText="This action will forfeit all changes made to the template"
          />
          <CloseIcon fontSize="large" />
        </IconButton>
      </Box>
      <Box>
        <Box sx={styles.pageTitle}>
          <Typography variant="h1">Add New CAD Template</Typography>
        </Box>
        <Box sx={styles.uploadForm}>
          <Box sx={styles.form}>
            <TextField
              variant="outlined"
              placeholder="Template Name"
              sx={styles.outline}
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
            />
          </Box>
          {showDropzone && (
            <>
              <Dropzone
                acceptedExtensions={['.dxf']}
                onDropAccepted={async (acceptedFilesArray) => {
                  const f = acceptedFilesArray[0];
                  setShowDropzone(false);
                  await dispatch(FileUploadStartThunk([f], false, true));
                  setFileName(f.name);
                }}
              >
                {(getInputProps, open) => (
                  <Box sx={styles.dropzone}>
                    <Box>
                      <img src={uploadFilesIcon} alt="Upload files" style={styles.uploadFormIcon} />
                      <input {...getInputProps()} />
                    </Box>
                    <Box sx={styles.dropzoneText}>
                      <Typography variant="h5" sx={styles.uploadFormText}>
                        Drag and drop to upload
                      </Typography>
                      <br />
                      <Typography variant="caption" sx={styles.uploadFormText}>
                        {'or '}
                        <button type="button" style={styles.openFileDialogLink} onClick={open}>browse</button>
                        {' to choose a file'}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Dropzone>
              <Typography variant="body2" sx={styles.formText}>
                If your file is in a .dwg, .dwt or .dgn file format, please convert to .dxf to upload
              </Typography>
            </>
          )}
        </Box>
        {file && (
          <Box sx={styles.progressSection}>
            <Box sx={styles.fileExtension}>
              <Typography variant="body1" sx={styles.fileExtensionText}>.dxf</Typography>
            </Box>
            <Box sx={styles.progress}>
              <Box sx={styles.progressText}>
                <Typography variant="body2">{file.name}</Typography>
              </Box>
              <Typography variant="body2">
                {file.progress.toFixed(2)}
                {' '}
                %
                {' '}
              </Typography>
              {(file.progress < 100) && (
                <LinearProgress
                  color="primary"
                  variant="determinate"
                  value={file.progress}
                />
              )}
            </Box>
            <Box sx={styles.progressButtons}>
              <IconButton
                sx={styles.progressButton}
                onClick={() => {
                  setShowDropzone(true);
                  dispatch(FileUploadCancelThunk(file.id));
                  dispatch(SetTemplateId(null));
                  setCorrectMatching(null);
                }}
              >
                <ClearIcon sx={styles.cancelIcon} />
              </IconButton>
            </Box>
          </Box>
        )}
        {file && file.progress === 100 && <Matching setCorrectMatching={setCorrectMatching} />}
      </Box>
      <Paper elevation={1} sx={styles.bottomPaper}>
        {unmatchedLayers && (
          <Box sx={styles.warningMessage}>
            <Typography variant="body2" style={{ fontWeight: 400 }}>You have unmatched layers</Typography>
            <Typography variant="body2">Unmatched layers will export using the AirWorks default layer properties</Typography>
          </Box>
        )}
        <Tooltip title="Please wait while we update your template, this may take a minute." arrow disableHoverListener={!loading} placement="top">
          <Button
            disabled={!canAddTemplate || loading}
            variant="contained"
            color="primary"
            onClick={addTemplate}
            sx={styles.addButton}
          >
            <Fade in={loading}>
              <CircularProgress size={24} sx={styles.buttonProgress} />
            </Fade>
            <Fade in={!loading}>
              <span>Add template</span>
            </Fade>
          </Button>
        </Tooltip>
      </Paper>
    </Dialog>
  );
};

export default AddTemplateDialog;
