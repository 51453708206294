// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect } from 'react';
import { Box, useTheme } from '@mui/system';
import SvgIcon from '@mui/material/SvgIcon';
import { Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'Hooks';
import Sidebar from 'Components/Sidebar';
import { getOrders } from 'Features/order/orderSelectors';
import { ShowUploadSingleFileDialogAction, HideUploadSingleFileDialogAction } from 'Features/fileUpload/fileUploadActions';
import { SetShowSidebarAction, SetOrderSelectedAction } from 'Features/map/mapEditorActions';
import CadDrawings from './CadDrawings';
import Orthomosaic from './Orthomosaic';
import Basemap from './Basemap';
import Panel from './Panel';
import stylesDef from './styles';

export const FilesSidebar = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const ordersWithCadFiles = useAppSelector((state) => getOrders(state).ordersWithCadFiles);

  const showSidebar = useAppSelector((state) => state.map.editor.present.showSidebar);
  const currentPanel = useAppSelector((state) => state.map.editor.present.currentPanel);
  const orderSelected = useAppSelector((state) => state.map.editor.present.orderSelected);

  useEffect(() => {
    if (!orderSelected && ordersWithCadFiles.length > 0) dispatch(SetOrderSelectedAction(ordersWithCadFiles[0]._id));
  });

  return (
    <Sidebar data-test="sidebar-rendering" onClick={() => dispatch(HideUploadSingleFileDialogAction())}>
      <Box sx={styles.mainContent}>
        <Panel />
        {showSidebar && (
          <Box sx={styles.sidebarContent}>
            <Box sx={styles.filesSidebar}>
              <Box sx={styles.sidebarTitle}>
                <Typography display="inline" sx={styles.header} variant="h4">
                  {' '}
                  {currentPanel}
                  {' '}
                </Typography>
                <SvgIcon sx={styles.hideSidebar} fontSize="inherit" onClick={() => dispatch(SetShowSidebarAction(false))}>
                  <rect fill="none" width="24" height="24" />
                  <g>
                    <polygon points="23.79,3.77 22.02,2 12.02,12 22.02,22 23.79,20.23 15.56,12 " />
                    <polygon points="12.02,3.77 10.25,2 0.25,12 10.25,22 12.02,20.23 3.79,12" id="polygon1" />
                  </g>
                </SvgIcon>
              </Box>
              {ordersWithCadFiles.map((order, index) => (
                <CadDrawings key={order._id} order={order} index={ordersWithCadFiles.length - index} />
              ))}
              <Orthomosaic />
              <Basemap />
            </Box>
          </Box>
        )}
      </Box>
    </Sidebar>
  );
};

export default FilesSidebar;
