// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  popper: {
    zIndex: 1100,
  },
  notificationsPanel: {
    padding: theme.spacing(2),
    width: 350,
  },
  notificationsList: {
    marginTop: theme.spacing(2),
    display: 'grid',
    gridRowGap: theme.spacing(),
    maxHeight: 256,
    overflow: 'auto',
  },
  notificationItem: {
    backgroundColor: theme.palette.grey[100],
    display: 'grid',
    gridRowGap: theme.spacing(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    borderLeft: '2px solid transparent',
    textAlign: 'initial',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  notificationCard: {
    display: 'inline-block',
  },
  activeItem: {
    borderColor: theme.palette.primary.main,
  },
  warningItem: {
    borderColor: '#01a1dd',
  },
  errorItem: {
    borderColor: 'red',
  },
});

export default styles;
