// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import type { RootState } from 'Store';
import { getOrders as ordersSelector } from '../order/orderSelectors';

const getOrders = (state: RootState) => ordersSelector(state);
const getProject = (state: RootState) => state.project.project;
const getKmls = (state: RootState) => state.kml.present.kmls;

const getFeatureCollection = createSelector(
  [getOrders, getProject, getKmls],
  (orders, project, kmls) => {
    const activeOrderId = orders.activeOrder?._id;

    const collection: GeoJSON.FeatureCollection<GeoJSON.Polygon> = {
      type: 'FeatureCollection',
      features: [],
    };

    orders.projectOrders.forEach((order) => {
      // if active order has no kml, don't try to get it's feature
      if (!kmls[order._id]) return;
      const { features } = kmls[order._id].featureCollection;
      if (!features.length) return;

      features.forEach((feature: any) => {
        // Deep copy the feature object
        const copiedFeature = JSON.parse(JSON.stringify(feature));

        // Initialize copiedFeature.properties if it doesn't exist
        if (!copiedFeature.properties) {
          copiedFeature.properties = {};
        }

        let { properties } = copiedFeature;
        // Check if properties is extensible, if not, create a new object
        if (!Object.isExtensible(properties)) {
          const newProperties = {};
          Object.keys(properties).forEach((key) => {
            newProperties[key] = properties[key];
          });
          properties = newProperties;
        }

        // Create a disabled property if it doesn't exist
        if (!properties.disabled) {
          properties.disabled = false; // or true, based on your requirement
        }

        // Update the disabled property as needed
        if (order._id !== activeOrderId) properties.disabled = true;
        if (order._id === activeOrderId && ((order.isEstimate && (!!(project?.imageryRequest?.requested) || (project?.rasterRequest?.requested && !project.rasterRequest.orderPlaced))) || !!(project?.imageryRequest?.uploaded) || !!(project?.imageryRequest?.notificationSent))) properties.disabled = true;

        collection.features.push(copiedFeature);
      });
    });

    return collection;
  },
);

export { getFeatureCollection };
