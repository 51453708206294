// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dispatch } from 'redux';
import { getJson, postJson, deleteRequest, patchJson } from 'Utils/http';
import { API_URL } from 'Config';
import type { RootState } from 'Store';
import { GetProjectsFailedAction, GetProjectsStartAction, GetProjectsSuccessAction, SetKeywordFilterAction, SetLoadAllAction, SetLoadNewAction, SetPageAction, SetProjectsAction, SetSortFilterAction, SetThumbnailAction, UpdateProjectNameAction } from './projectListActions';

// Gets projects for a given user
export const GetProjectsThunk = (orgId: string = '', limit: number = 9) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { page, keywordFilter, sortFilter, loadNew, orderSortFilter } = getState().projectList;
    const url = `${API_URL}/projects?page=${page}&limit=${limit}&query=${keywordFilter}&sort=${sortFilter}&orgId=${orgId}&filterby=${orderSortFilter || ''}`;
    const { token } = getState().auth;

    dispatch(GetProjectsStartAction());

    const result = await getJson<IProjectResponse>(url, token);
    const keywordFilterNow = getState().projectList.keywordFilter;

    if (result.success) {
      if (keywordFilterNow !== result.data.keyword) return {};
      if (loadNew) {
        dispatch(SetProjectsAction([]));
      }
      dispatch(GetProjectsSuccessAction({
        list: result.data.list,
        totalCount: result.data.totalCount,
      }));
      if (result.data.list.length === 0) {
        dispatch(SetLoadAllAction(true));
      }
    } else {
      dispatch(GetProjectsFailedAction(result.message));
    }

    dispatch(SetLoadNewAction(false));

    return result;
  };

// Reload projects
export const ReloadProjectsThunk = () =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(SetLoadNewAction(true));
    dispatch(SetLoadAllAction(false));
    dispatch(SetPageAction(0));
    dispatch(SetProjectsAction([]));
    GetProjectsThunk()(dispatch, getState);
  };

// Reset sort filter and search
export const ResetFiltersThunk = () =>
  async (dispatch: Dispatch) => {
    dispatch(SetSortFilterAction('updatedAtD'));
    dispatch(SetKeywordFilterAction(''));
  };

// Read thumbnail with a given projectId
export const ReadThumbnailThunk = (projectId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { token } = getState().auth;
    let orgId;
    if ('opsTrainer' in getState().auth.resources) {
      const project = getState().projectList?.list?.find((p) => p._id === projectId);
      orgId = project?.ownerOrganization;
    } else {
      orgId = getState().auth.user.ownerOrganization;
    }
    const url = `${API_URL}/readThumbnail/${orgId}/${projectId}`;
    const result = await getJson<string>(url, token);

    if (result.success) {
      const obj = { projectId, thumbnail: result.data };
      dispatch(SetThumbnailAction(obj));
    }
  };
