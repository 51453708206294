// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import PropTypes from 'prop-types';
import { Typography, LinearProgress } from '@mui/material';
import sparkleIcon from 'Assets/sparkle.png';
import { formatValue } from 'Utils/deliverables';
import { Box, useTheme } from '@mui/system';
import { useSelector } from 'react-redux';
import { useAppSelector } from 'Hooks';
import stylesDef from './styles';
import { selectCurrentSubscription } from 'Features/account/subscriptionSelectors';

const ProcessedAcreage = ({ headerComponent }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);

  const { automateSubscription, expressAISubscription } = useAppSelector(
    (state) => state.subscription,
  );

  const subscriptionType = automateSubscription?.interval;

  const { currentPricingPlanInfo } = useAppSelector(
    (state) => state.account.org,
  );
  const {
    name: pricingPlanName,
    usedCredits: pricingPlanUsedCredits,
    creditsPurchased: pricingPlanCreditsPurchased,
    rate: pricingPlanRate,
    upperLimit: pricingPlanUpperLimit,
  } = currentPricingPlanInfo || {};

  const currentSubscription = useSelector((state) => selectCurrentSubscription(state));
  const {
    totalAcreage: automateTotalAcreage,
    usedCreditAcreage: automateUsedCreditAcreage,
    usedPercent: automateUsedPercent,
    noAcreage,
  } = currentSubscription || {};

  const automatePlan = !pricingPlanName && automateSubscription && Object.keys(automateSubscription).length;
  const preCommitOrPrePayPlan = (pricingPlanName && pricingPlanName !== 'no_commit');
  const expressAISubscriptionOnly = !automatePlan && expressAISubscription && Object.keys(expressAISubscription).length;

  let preCommitOrPrePayPercent = (pricingPlanUsedCredits / pricingPlanCreditsPurchased) * 100;
  const creditOverage = preCommitOrPrePayPercent > 100;
  preCommitOrPrePayPercent = preCommitOrPrePayPercent > 100 ? 100 : preCommitOrPrePayPercent;

  let usedCredits = 0;
  let totalCredits = 0;
  let creditsPercent = 0;
  if (automatePlan) {
    usedCredits = automateUsedCreditAcreage;
    totalCredits = automateTotalAcreage;
    creditsPercent = automateUsedPercent;
  } else if (preCommitOrPrePayPlan) {
    usedCredits = pricingPlanUsedCredits;
    totalCredits = pricingPlanCreditsPurchased;
    creditsPercent = preCommitOrPrePayPercent;
  } else if (expressAISubscription?.name) {
    usedCredits = expressAISubscription.expressAIUsedCredits;
    totalCredits = expressAISubscription.expressAIStartingCredits;
    creditsPercent = usedCredits / totalCredits * 100;
  }

  return (
    <>
      {
        pricingPlanName === 'no_commit' && (
          <>
            <Box sx={{ ...styles.noCommitText, ...(headerComponent && styles.noCommitHeader) }}>
              <img src={sparkleIcon} style={styles.imgIcon} alt="sparkle" />
              <Typography variant="subtitle1" component="span" sx={styles.boldtext}>
                {formatValue(pricingPlanUsedCredits)}
                {' '}
                <Typography component="span" variant="subtitle1">credits processed this year</Typography>
              </Typography>
            </Box>
            {headerComponent && (
              <Typography variant="body2" sx={styles.rateText}>
                Your current rate is
                <Typography component="span" variant="body2" sx={styles.boldtext}>
                  {` $${formatValue(pricingPlanRate)}`}
                </Typography>
                , push
                <Typography component="span" variant="body2" sx={styles.boldtext}>
                  {` ${Math.ceil(pricingPlanUpperLimit - pricingPlanUsedCredits)} `}
                </Typography>
                more credits for a lower rate
              </Typography>
            )}
          </>
        )
      }
      {(automatePlan || preCommitOrPrePayPlan || expressAISubscriptionOnly) && (
        <>
          <Typography variant="subtitle1" color="textSecondary" component="span" sx={styles.boldtext}>
            <Typography component="span" variant="subtitle1" color="primary">
              {formatValue(usedCredits)}
            </Typography>
            {` of ${totalCredits} credits `}
          </Typography>
          <Typography variant="body1" component="span">
            {!!automatePlan && (subscriptionType === 'Monthly' ? 'processed this month' : 'processed this year')}
            {(preCommitOrPrePayPlan || expressAISubscriptionOnly) && 'processed'}
          </Typography>
          <LinearProgress
            variant="buffer"
            value={creditsPercent}
            valueBuffer={creditsPercent}
            sx={{ ...styles.dashedBar, ...((noAcreage || creditOverage) ? styles.noAcreage : styles.hasAcreage) }}
          />
        </>
      )}
    </>
  );
};

ProcessedAcreage.propTypes = {
  headerComponent: PropTypes.bool,
};

export default ProcessedAcreage;
