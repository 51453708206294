// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createAction } from '@reduxjs/toolkit';

export const FileUploadStartAction = createAction<{ id: string, name: string, extension: string, request: XMLHttpRequest, progress: number, success: boolean }>('fileUpload/FileUploadStartAction');
export const FileUploadUpdateAction = createAction<{ id: string, progress?: number, success?: boolean, fileId?: string, fileKey?: string }>('fileUpload/FileUploadUpdateAction');
export const FileUploadCancelAction = createAction<string>('fileUpload/FileUploadCancelAction');

export const FileUploadInitAction = createAction('fileUpload/FileUploadInitAction');

export const SetEpsgListAction = createAction<number[]>('fileUpload/SetEpsgListAction');

export const SetFileUploadSizeErrorAction = createAction<boolean>('fileUpload/SetFileUploadSizeErrorAction');

export const SetImageryCaptureDateAction = createAction<string | null>('fileUpload/SetImageryCaptureDateAction');

export const SetExpressAIUploadAction = createAction('fileUpload/SetExpressAIUploadAction');

export const SetDxfEpsgAction = createAction<number>('fileUpload/SetDxfEpsgAction');

export const ShowUploadSingleFileDialogAction = createAction<boolean>('fileUpload/ShowUploadSingleFileDialogAction');
export const HideUploadSingleFileDialogAction = createAction<boolean>('fileUpload/HideUploadSingleFileDialogAction');

export const ShowOpsTrainingDialogAction = createAction('fileUpload/ShowOpsTrainingDialogAction');
export const HideOpsTrainingDialogAction = createAction('fileUpload/HideOpsTrainingDialogAction');

export const SendExpressAIEmailStartAction = createAction('fileUpload/SendExpressAIEmailStartAction');
export const SendExpressAIEmailSuccessAction = createAction('fileUpload/SendExpressAIEmailSuccessAction');
export const SendExpressAIEmailFailureAction = createAction<string>('fileUpload/SendExpressAIEmailFailureAction');
