// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { Box, useTheme } from '@mui/system';
import { InputBase, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import stylesDef from './styles';

interface TextEditorProps {
  initialValue: string;
  updateValue: (value: string) => void;
  formatValue?: (value: string) => string;
  linkText?: boolean;
}

export const TextEditor = ({ initialValue, linkText, updateValue, formatValue }: TextEditorProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const [input, setInput] = useState(null);
  const [editable, setEditable] = useState(false);

  const startEditing = () => {
    setInput(initialValue);
    setEditable(true);
  };

  const updateInput = () => {
    // only update value when input has changed and is exist
    if (input && input.trim() !== initialValue) {
      updateValue(input);
    }
    setInput(null);
    setEditable(false);
  };

  return (
    <Box sx={styles.container}>
      {editable ? (
        <>
          <InputBase
            sx={styles.inputField}
            autoFocus
            value={input}
            onChange={(e) => {
              setInput(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === 'Escape') {
                e.preventDefault();
                e.stopPropagation();
                updateInput();
              }
            }}
          />
          <IconButton
            sx={styles.icon}
            aria-label="check"
            onClick={updateInput}
          >
            <CheckIcon />
          </IconButton>
        </>
      ) : (
        <>
          {!linkText && <Typography sx={styles.value}>{formatValue ? formatValue(initialValue) : initialValue || 'N/A'}</Typography>}
          {(linkText && !!(initialValue)) && (
            <Typography>
              <a href={initialValue} target="_blank" rel="noopener noreferrer">
                Contract URL
              </a>
            </Typography>
          )}
          <IconButton
            sx={styles.icon}
            aria-label="edit"
            onClick={startEditing}
          >
            <EditIcon />
          </IconButton>
        </>
      )}
    </Box>
  );
};

export default TextEditor;
