// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createSlice } from '@reduxjs/toolkit';
import { SetNotificationListAction, UpdateNotificationListAction } from './shellActions';

interface shellState {
  notificationList: INotification[];
}

const initialState: shellState = {
  notificationList: [],
};

const shellSlice = createSlice({
  name: 'shell',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SetNotificationListAction, (state, action) => {
        state.notificationList = action.payload;
      })
      .addCase(UpdateNotificationListAction, (state, action) => {
        const index = state.notificationList.findIndex((n) => n._id === action.payload._id);
        state.notificationList[index] = action.payload;
      });
  },
});

export const { reducer } = shellSlice;

export default reducer;
