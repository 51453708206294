// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  cardWrapper: {
    position: 'relative',
    marginBottom: '3px',
  },
  projectItem: {
    transition: theme.transitions.create(['box-shadow', 'margin-top']),
    '&:hover': {
      boxShadow: theme.shadows[15],
      marginTop: -0.5,
      '& .label': {
        opacity: 1,
        marginTop: 0,
      },
      '& .projectItemIcons': {
        opacity: 1,
        marginTop: 0,
      },
      '& .backdrop': {
        opacity: 0.7,
      },
    },
  },
  media: {
    height: 200,
    position: 'relative',
  },
  progress: {
    zIndex: 3001,
    position: 'absolute',
    width: '100%',
  },
  loadingBackdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    zIndex: 3000,
    opacity: 0.5,
  },
  backdrop: {
    transition: theme.transitions.create('opacity'),
    backgroundColor: 'black',
    opacity: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  standardBackdrop: {
    opacity: 0.7,
  },
  successBackdrop: {
    opacity: 0.9,
    background: 'linear-gradient(134.72deg, #000000 0%, #05071A 49.93%, #18216D 100%)',
  },
  bottomBackdrop: {
    transition: theme.transitions.create('opacity'),
    opacity: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  bottomSuccessBackdrop: {
    opacity: 0.2,
    backgroundColor: '#797979',
  },
  label: {
    transition: theme.transitions.create(['opacity', 'margin-top', 'color', 'background-color']),
    marginTop: -5,
    opacity: 0,
    color: 'white',
    padding: theme.spacing(2),
    border: '1px solid',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '&:hover': {
      backgroundColor: 'white',
      color: 'black',
    },
  },
  cardActions: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridTemplateAreas: `
      "name icon"
      "date icon"
    `,
    padding: theme.spacing(2),
    position: 'relative',
    height: '80px',
  },
  nameText: {
    gridArea: 'name',
    color: theme.palette.common.black,
    paddingTop: '.25rem',
    display: 'flex',
    alignItems: 'center',
    marginLeft: 0,
  },
  notificationIcon: {
    width: theme.spacing(2),
    marginLeft: theme.spacing(0.5),
  },
  dateText: {
    gridArea: 'date',
    color: '#1C364B',
    paddingBottom: '.25rem',
    gridRow: 2,
    margin: '2px 0',
  },
  details: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  detailsWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    fontSize: '10px',
  },
  projectItemIcons: {
    color: 'white',
    opacity: 0,
    transition: theme.transitions.create(['opacity', 'margin-top', 'color', 'background-color']),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  deleteIcon: {
    color: 'white',
  },
  activeIcon: {
    color: theme.palette.secondary.main,
  },
  avatar: {
    position: 'absolute',
    top: '5%',
    left: '3%',
    background: '#ffffff',
    color: '#000000',
  },
  projectItemButton: {
    color: 'white',
    padding: theme.spacing(2),
    borderRadius: 0,
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    marginTop: theme.spacing(),
    '&:hover': {
      backgroundColor: 'white',
      color: 'black',
    },
  },
  cardText: {
    color: 'white',
  },
  cardTextWrapper: {
    textAlign: 'center',
    gridColumn: '1/3',
  },
  cardControls: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
    gridTemplateColumns: '1fr 1fr',
  },
  progressPanel: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
  },
  progressStepperRoot: {
    height: '100%',
    width: '100%',
  },
  stepper: {
    backgroundColor: '#fff0',
  },
  labelText: {
    color: '#fff',
    textAlign: 'center',
  },
});

export default styles;
