// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { useSelector } from 'react-redux';
import { Box, useTheme } from '@mui/system';
import { FormControlLabel, Typography, ListItem, Checkbox } from '@mui/material';
import { useAppSelector } from 'Hooks';
import { RootState } from 'Store';
import { getOrders } from 'Features/order/orderSelectors';
import stylesDef from './styles';

interface LayerSelectProps {
  onAddOnsClick: (item: IDeliverable, checked: boolean) => void;
}

export const LayerSelect = ({ onAddOnsClick }: LayerSelectProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const { additionalDeliverables, selectedBundle, selectedAdditionals } = useAppSelector(
    (state) => state.bundle,
  );
  const { name: bundleName, bundleId } = selectedBundle || {};
  const { activeOrder } = useSelector((state: RootState) => getOrders(state));

  const utilitiesBoolean = additionalDeliverables.some((d) => d.subtype === 'Utilities');
  const roadsAndSidewalkBoolean = additionalDeliverables.some((d) => d.subtype === 'Road & Sidewalk');
  const vegetationAndLandscapeBoolean = additionalDeliverables.some((d) => d.subtype === 'Vegetation & Landscape');
  const topographyLayerBoolean = additionalDeliverables.some((d) => d.subtype === 'Topography' && d.type === 'layer');
  const administrativeBoolean = additionalDeliverables.some((d) => d.subtype === 'Administrative & Annotations');
  const planimetricsBoolean = additionalDeliverables.some((d) => d.subtype === 'Planimetrics');
  const measurementsBoolean = additionalDeliverables.some((d) => d.subtype === 'Measurements');
  const wallsAndFencesBoolean = additionalDeliverables.some((d) => d.subtype === 'Walls & Fences');
  const additionalLayersBoolean = additionalDeliverables.some((d) => d.subtype === 'Additional Layers');

  return (
    <Box sx={bundleName === 'Topo' && styles.unavailableDeliverables}>
      <Typography sx={styles.addonsHeading} variant="h3">
        Layers
      </Typography>
      {bundleName === 'Topo' ? (
        <Typography sx={styles.noDeliverableMessage}>
          Please select the 2D + TOPO bundle to add 2D features at checkout
        </Typography>
      ) : <Typography sx={styles.description}>Please select any additional layers you’d like to be drafted in your project.</Typography> }
      {(bundleId === 4 || bundleId === 5) && (
        <Typography sx={styles.noDeliverableMessage}>
          Please select the 2D + TOPO bundle to add the disabled layers at checkout
        </Typography>
      )}
      {utilitiesBoolean && (
        <Box>
          <Typography sx={styles.addonsHeading} variant="h4">
            Utilities
          </Typography>
          {additionalDeliverables.map((item) =>
            (item.subtype === 'Utilities' ? (
              <Box sx={(bundleName === '3D Feature Elevation' && !(item.deliverableId === 44 || item.deliverableId === 45)) && styles.unavailableDeliverables}>
                <ListItem button disableRipple sx={styles.listItem}>
                  <FormControlLabel
                    label={(
                      <Box>
                        <Typography variant="body1" display="inline" sx={styles.itemName}>{item.name}</Typography>
                      </Box>
                    )}
                    control={(
                      <Checkbox
                        styles={{
                          checked: styles.checked,
                        }}
                        disabled={!item.selectable}
                        checked={selectedAdditionals.findIndex((i) => i._id === item._id) !== -1}
                        onChange={(e) => onAddOnsClick(item, e.target.checked)}
                      />
                    )}
                  />
                  <Typography sx={styles.right} component="span">
                        &nbsp;
                    {' '}
                    {`${(item.acreFactor * activeOrder.acreage).toFixed(2)} Credits`}
                  </Typography>
                </ListItem>
              </Box>
            ) : null))}
        </Box>
      ) }
      {roadsAndSidewalkBoolean && (
        <Box>
          <Typography sx={styles.addonsHeading} variant="h4">
            Road & Sidewalk
          </Typography>
          {additionalDeliverables.map((item) =>
            (item.subtype === 'Road & Sidewalk' ? (
              <ListItem button disableRipple sx={styles.listItem}>
                <FormControlLabel
                  label={(
                    <Box>
                      <Typography variant="body1" display="inline" sx={styles.itemName}>{item.name}</Typography>
                    </Box>
                  )}
                  control={(
                    <Checkbox
                      styles={{
                        checked: styles.checked,
                      }}
                      disabled={!item.selectable}
                      checked={selectedAdditionals.findIndex((i) => i._id === item._id) !== -1}
                      onChange={(e) => onAddOnsClick(item, e.target.checked)}
                    />
                  )}
                />
                <Typography sx={styles.right} component="span">
                      &nbsp;
                  {' '}
                  {`${(item.acreFactor * activeOrder.acreage).toFixed(2)} Credits`}
                </Typography>
              </ListItem>
            ) : null))}
        </Box>
      ) }
      {vegetationAndLandscapeBoolean && (
        <Box>
          <Typography sx={styles.addonsHeading} variant="h4">
            Vegetation & Landscape
          </Typography>
          {additionalDeliverables.map((item) =>
            (item.subtype === 'Vegetation & Landscape' ? (
              <Box sx={(bundleName === '3D Feature Elevation' && !(item.deliverableId === 39)) && styles.unavailableDeliverables}>
                <ListItem button disableRipple sx={styles.listItem}>
                  <FormControlLabel
                    label={(
                      <Box>
                        <Typography variant="body1" display="inline" sx={styles.itemName}>{item.name}</Typography>
                      </Box>
                    )}
                    control={(
                      <Checkbox
                        styles={{
                          checked: styles.checked,
                        }}
                        disabled={!item.selectable}
                        checked={selectedAdditionals.findIndex((i) => i._id === item._id) !== -1}
                        onChange={(e) => onAddOnsClick(item, e.target.checked)}
                      />
                    )}
                  />
                  <Typography sx={styles.right} component="span">
                        &nbsp;
                    {' '}
                    {`${(item.acreFactor * activeOrder.acreage).toFixed(2)} Credits`}
                  </Typography>
                </ListItem>
              </Box>
            ) : null))}
        </Box>
      ) }
      {topographyLayerBoolean && (
        <Box sx={bundleName === '2D' && styles.unavailableDeliverables}>
          <Typography sx={styles.addonsHeading} variant="h4">
            Topography
          </Typography>
          {bundleName === '2D' && (
            <Typography sx={styles.noDeliverableMessage}>
              Please select the 2D + TOPO bundle to add Topography features at checkout
            </Typography>
          )}
          {additionalDeliverables.map((item) =>
            ((item.type === 'layer' && item.subtype === 'Topography') ? (
              <ListItem button disableRipple sx={styles.listItem}>
                <FormControlLabel
                  label={(
                    <Box>
                      <Typography variant="body1" display="inline" sx={styles.itemName}>{item.name}</Typography>
                    </Box>
                  )}
                  control={(
                    <Checkbox
                      styles={{
                        checked: styles.checked,
                      }}
                      disabled={!item.selectable}
                      checked={selectedAdditionals.findIndex((i) => i._id === item._id) !== -1}
                      onChange={(e) => onAddOnsClick(item, e.target.checked)}
                    />
                  )}
                />
                <Typography sx={styles.right} component="span">
                      &nbsp;
                  {' '}
                  {`${(item.acreFactor * activeOrder.acreage).toFixed(2)} Credits`}
                </Typography>
              </ListItem>
            ) : null))}
        </Box>
      ) }
      { administrativeBoolean && (
        <Box sx={(bundleId === 0 || bundleId === 5) && styles.unavailableDeliverables}>
          <Typography sx={styles.addonsHeading} variant="h4">
            Administrative & Annotations
          </Typography>
          {(bundleId === 0 || bundleId === 5) && (
            <Typography sx={styles.noDeliverableMessage}>
              Please select the 2D + TOPO bundle to add Administrative & Annotations features at checkout
            </Typography>
          )}
          {additionalDeliverables.map((item) =>
            (item.subtype === 'Administrative & Annotations' ? (
              <ListItem button disableRipple sx={styles.listItem}>
                <FormControlLabel
                  label={(
                    <Box>
                      <Typography variant="body1" display="inline" sx={styles.itemName}>{item.name}</Typography>
                    </Box>
                  )}
                  control={(
                    <Checkbox
                      styles={{
                        checked: styles.checked,
                      }}
                      disabled={!item.selectable}
                      checked={selectedAdditionals.findIndex((i) => i._id === item._id) !== -1}
                      onChange={(e) => onAddOnsClick(item, e.target.checked)}
                    />
                  )}
                />
                <Typography sx={styles.right} component="span">
                      &nbsp;
                  {' '}
                  {`${(item.acreFactor * activeOrder.acreage).toFixed(2)} Credits`}
                </Typography>
              </ListItem>
            ) : null))}
        </Box>
      ) }
      { planimetricsBoolean && (
        <Box sx={bundleId === 4 && styles.unavailableDeliverables}>
          <Typography sx={styles.addonsHeading} variant="h4">
            Planimetrics
          </Typography>
          {additionalDeliverables.map((item) =>
            (item.subtype === 'Planimetrics' ? (
              <Box sx={(!item.selectable || (bundleName === '3D Feature Elevation' && !(item.deliverableId === 21))) && styles.unavailableDeliverables}>
                <ListItem button disableRipple sx={styles.listItem}>
                  <FormControlLabel
                    label={(
                      <Box>
                        <Typography variant="body1" display="inline" sx={styles.itemName}>{item.name}</Typography>
                      </Box>
                    )}
                    control={(
                      <Checkbox
                        styles={{
                          checked: styles.checked,
                        }}
                        disabled={!item.selectable}
                        checked={selectedAdditionals.findIndex((i) => i._id === item._id) !== -1}
                        onChange={(e) => onAddOnsClick(item, e.target.checked)}
                      />
                    )}
                  />
                  <Typography sx={styles.right} component="span">
                        &nbsp;
                    {' '}
                    {`${(item.acreFactor * activeOrder.acreage).toFixed(2)} Credits`}
                  </Typography>
                </ListItem>
              </Box>
            ) : null))}
        </Box>
      ) }
      { measurementsBoolean && (
        <Box>
          <Typography sx={styles.addonsHeading} variant="h4">
            Measurements
          </Typography>
          {additionalDeliverables.map((item) =>
            (item.subtype === 'Measurements' ? (
              <ListItem button disableRipple sx={styles.listItem}>
                <FormControlLabel
                  label={(
                    <Box>
                      <Typography variant="body1" display="inline" sx={styles.itemName}>{item.name}</Typography>
                    </Box>
                  )}
                  control={(
                    <Checkbox
                      styles={{
                        checked: styles.checked,
                      }}
                      disabled={!item.selectable}
                      checked={selectedAdditionals.findIndex((i) => i._id === item._id) !== -1}
                      onChange={(e) => onAddOnsClick(item, e.target.checked)}
                    />
                  )}
                />
                <Typography component="span">
                  {`${(item.acreFactor * activeOrder.acreage).toFixed(2)} Credits`}
                </Typography>
              </ListItem>
            ) : null))}
        </Box>
      ) }
      { wallsAndFencesBoolean && (
        <Box sx={bundleName === '3D Feature Elevation' && styles.unavailableDeliverables}>
          <Typography sx={styles.addonsHeading} variant="h4">
            Walls & Fences
          </Typography>
          {additionalDeliverables.map((item) =>
            (item.subtype === 'Walls & Fences' ? (
              <ListItem button disableRipple sx={styles.listItem}>
                <FormControlLabel
                  label={(
                    <Box>
                      <Typography variant="body1" display="inline" sx={styles.itemName}>{item.name}</Typography>
                    </Box>
                  )}
                  control={(
                    <Checkbox
                      styles={{
                        checked: styles.checked,
                      }}
                      disabled={!item.selectable}
                      checked={selectedAdditionals.findIndex((i) => i._id === item._id) !== -1}
                      onChange={(e) => onAddOnsClick(item, e.target.checked)}
                    />
                  )}
                />
                <Typography sx={styles.right} component="span">
                      &nbsp;
                  {' '}
                  {`${(item.acreFactor * activeOrder.acreage).toFixed(2)} Credits`}
                </Typography>
              </ListItem>
            ) : null))}
        </Box>
      ) }
      {additionalLayersBoolean && (
        <Box sx={bundleName === '2D' && styles.unavailableDeliverables}>
          <Typography sx={styles.addonsHeading} variant="h4">
            Additional Layers
          </Typography>
          {additionalDeliverables.map((item) =>
            (item.subtype === 'Additional Layers' ? (
              <Box
                key={item.name}
                sx={(!item.selectable || (bundleId === 5 && item.deliverableId === 16)) && styles.unavailableDeliverables}
              >
                <ListItem button disableRipple sx={styles.listItem}>
                  <FormControlLabel
                    label={(
                      <Box>
                        <Typography variant="body1" display="inline" sx={styles.itemName}>{item.name}</Typography>
                      </Box>
                    )}
                    control={(
                      <Checkbox
                        styles={{
                          checked: styles.checked,
                        }}
                        disabled={!item.selectable}
                        checked={selectedAdditionals.findIndex((i) => i._id === item._id) !== -1}
                        onChange={(e) => onAddOnsClick(item, e.target.checked)}
                      />
                    )}
                  />
                  <Typography sx={styles.right} component="span">
                    {`${(item.acreFactor * activeOrder.acreage).toFixed(2)} credits`}
                  </Typography>
                </ListItem>
              </Box>
            ) : null))}
        </Box>
      ) }
    </Box>
  );
};

export default LayerSelect;
