// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { ToggleOrthomosaicLayerAction } from 'Features/map/mapCommonActions';
import { useAppDispatch, useAppSelector } from 'Hooks';
import stylesDef from './styles';

const OrthomosaicCheckbox = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const { layers } = useAppSelector((state) => state.map.common);

  return (
    <FormControlLabel
      label={<Typography variant="h4" sx={styles.sidebarHeader}>Orthomosaic</Typography>}
      control={(
        <Checkbox
          color="primary"
          onChange={() => dispatch(ToggleOrthomosaicLayerAction())}
          checked={layers.orthomosaic}
          sx={styles.checkbox}
        />
      )}
    />
  );
};

export default OrthomosaicCheckbox;
