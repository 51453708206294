import { createAction } from '@reduxjs/toolkit';

export const CreateCustomerAction = createAction('subscription/CreateCustomerAction');

export const GetCreditCardsStartAction = createAction('subscription/GetCreditCardsStartAction');
export const GetCreditCardsSuccessAction = createAction<ICreditCard[]>('subscription/GetCreditCardsSuccessAction');
export const GetCreditCardsFailureAction = createAction('subscription/GetCreditCardsFailureAction');

export const CreatePaymentInstrumentSuccessAction = createAction<ICreditCard>('subscription/CreatePaymentInstrumentSuccessAction');
export const CreatePaymentInstrumentFailureAction = createAction<string>('subscription/CreatePaymentInstrumentFailureAction');

export const UpdateDefaultCardStartAction = createAction('subscription/UpdateDefaultCardStartAction');
export const UpdateDefaultCardSuccessAction = createAction<string>('subscription/UpdateDefaultCardSuccessAction');
export const UpdateDefaultCardFailureAction = createAction<string>('subscription/UpdateDefaultCardFailureAction');

export const DeleteCreditCardStartAction = createAction('subscription/DeleteCreditCardStartAction');
export const DeleteCreditCardSuccessAction = createAction<ICreditCard[]>('subscription/DeleteCreditCardSuccessAction');
export const DeleteCreditCardFailureAction = createAction<string>('subscription/DeleteCreditCardFailureAction');

export const UpdateCreditCardSuccessAction = createAction<ICreditCard>('subscription/UpdateCreditCardSuccessAction');

export const GetSubscriptionStartAction = createAction('subscription/GetSubscriptionStartAction');
export const GetSubscriptionSuccessAction = createAction<ISubscriptionsResponse>('subscription/GetSubscriptionSuccessAction');
export const GetSubscriptionFailureAction = createAction('subscription/GetSubscriptionFailureAction');
