// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import PropTypes from 'prop-types';
import { DebounceInput } from 'react-debounce-input';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/system';
import stylesDef from './styles';

const SearchField = ({ value = '', debounceTimeout = 500, onChange, placeholder = '' }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);

  return (
    <TextField
      value={value}
      placeholder={placeholder}
      onChange={(ev) => onChange(ev.target.value)}
      sx={styles.textField}
      InputProps={{
        sx: styles.searchField,
        inputComponent: DebounceInput,
        inputProps: { debounceTimeout },
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

SearchField.propTypes = {
  value: PropTypes.string,
  debounceTimeout: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

SearchField.defaultProps = {
  value: '',
  debounceTimeout: 500,
  placeholder: '',
};

export default SearchField;
