// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Divider,
  Button,
  IconButton,
  Typography,
  TableHead,
  Table,
  TableRow,
  TableBody,
  TableCell,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchField from 'Components/SearchField';
import orderListIcon from 'Assets/order_list_icon.png';
import ConfirmDialog from 'Components/ConfirmDialog';
import { GetAdminProjectRoute } from 'Utils/routes';
import LoadingSpinner from 'Components/LoadingSpinner';
import { GetOrderStatus } from 'Utils/orderStatus';
import { Box, useTheme } from '@mui/system';
import stylesDef from './styles';
import CreateProjectDialog from 'Components/CreateProjectDialog';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { DeleteProjectAdminThunk } from 'Features/admin/adminThunk';

export const ProjectsTable = ({ orgId }: { orgId: string }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const [showDialog, setShowDialog] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [showConfirmDeleteProject, setShowConfirmDeleteProject] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const list = useAppSelector((state) => state.admin?.orgProjects);
  const { loading } = useAppSelector((state) => state.admin);

  useEffect(() => {
    setProjectList(list);
  }, [list]);

  const handleSearchChange = (e: string): void => {
    if (!e) {
      setProjectList(list);
      return;
    }

    const filteredlist = list.filter(({ referenceId, name }) =>
      (referenceId && referenceId.toLowerCase().includes(e.toLowerCase())) ||
      (name && name.toLowerCase().includes(e.toLowerCase())));

    setProjectList(filteredlist);
  };

  const onOkDeleteProject = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDeleteProject(false);
    dispatch(DeleteProjectAdminThunk(selectedProjectId));
    e.stopPropagation();
  };

  const onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDeleteProject(false);
    setSelectedProjectId(null);
    e.stopPropagation();
  };

  return (
    <LoadingSpinner loading={loading}>
      <Box sx={styles.pageWrapper}>
        <Box sx={styles.headerWrapper}>
          <Box sx={styles.headerOptions}>
            <Typography variant="h2">Projects</Typography>
            <Box sx={styles.itemsWrapper}>
              <SearchField
                sx={styles.icons}
                placeholder="Search"
                debounceTimeout={500}
                onChange={(e) => handleSearchChange(e)}
              />
            </Box>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setShowDialog(true)}
            >
              New project
            </Button>
          </Box>
          <Divider sx={styles.headerDivider} />
        </Box>
        <Table sx={styles.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={styles.tableCell}>
                <Typography variant="h4">Project Name</Typography>
              </TableCell>
              <TableCell sx={styles.tableCell} align="right">
                <Typography variant="h4">Reference Id</Typography>
              </TableCell>
              <TableCell sx={styles.tableCell} align="right">
                <Typography variant="h4">Status</Typography>
              </TableCell>
              <TableCell sx={styles.tableCell} align="right">
                <Typography variant="h4">Orders</Typography>
              </TableCell>
              <TableCell sx={styles.iconTableCell} align="right" />
              {!_ADMIN_ && (
                <TableCell sx={styles.tableCell} align="right" />
              )}
            </TableRow>
          </TableHead>
          {projectList?.length ? (
            <TableBody>
              {projectList.map((project: IAdminProject) => (
                <TableRow key={project._id} sx={styles.tableRow}>
                  <TableCell
                    sx={styles.tableCell}
                    component="th"
                    scope="row"
                  >
                    {project.name}
                  </TableCell>
                  <TableCell
                    sx={styles.tableCell}
                    component="th"
                    scope="row"
                  >
                    {project.referenceId || 'N/A'}
                  </TableCell>
                  <TableCell
                    sx={styles.tableCell}
                    component="th"
                    scope="row"
                  >
                    {project.orderCount === 0 ? 'N/A' : GetOrderStatus(project.status)}
                  </TableCell>
                  <TableCell
                    sx={styles.tableCell}
                    component="th"
                    scope="row"
                  >
                    {project.orderCount}
                  </TableCell>
                  <TableCell sx={styles.tableCell} component="th" scope="row">
                    <Box sx={styles.projectOptions}>
                      <RouterLink to={GetAdminProjectRoute(orgId, project._id)} css={styles.routerLink} title="View Project"><Typography variant="h6">View Project</Typography></RouterLink>
                      {
                        project.orderCount === 1 && project.status === 0 && (
                          <IconButton
                            sx={styles.deleteIcon}
                            title="Delete Project"
                            onClick={() => {
                              setSelectedProjectId(project._id);
                              setShowConfirmDeleteProject(true);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )
                      }
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : null}
        </Table>
        {projectList?.length === 0 ? (
          <Box sx={styles.noOrderContainer}>
            <img src={orderListIcon} alt="icon" />
            <Typography variant="h5">There are no projects created yet.</Typography>
          </Box>
        ) : null}
      </Box>
      <CreateProjectDialog
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        getProjectUrl={(newProjectId) => GetAdminProjectRoute(orgId, newProjectId)}
      />
      <ConfirmDialog
        showDialog={showConfirmDeleteProject}
        onOk={onOkDeleteProject}
        onCancel={onCancel}
        contentText="Selected project will be deleted"
      />
    </LoadingSpinner>
  );
};

export default ProjectsTable;
