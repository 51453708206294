// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect, useRef } from 'react';
import { Typography, Button, Fade, CircularProgress, Divider, FormControlLabel, Checkbox, Tooltip, Select, MenuItem, InputBase, FormHelperText, Snackbar, Alert } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { useParams } from 'react-router-dom';
import ContentEditable from 'react-contenteditable';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import HelpIcon from '@mui/icons-material/Help';
import ConfirmDialog from 'Components/ConfirmDialog';
import history from 'Utils/history';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { GetOrgThunk } from 'Features/admin/adminThunk';
import { SetOrgIdAction } from 'Features/admin/adminActions';
import { WideLightTooltip } from 'Components/Tooltip';
import Layers from './layers';
import Sidebar from './sidebar';
import { GetTemplates, DeleteTemplate, EditTemplate, DuplicateTemplate, SetTemplateId, DownloadCadTemplateThunk } from './cadTemplateThunk';
import { DefaultTemplateThunk } from '../accountThunk';
import stylesDef from './styles';
import { ResetTemplateListAction } from './cadTemplateActions';

export const CadTemplates = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const [templateChange, setTemplateChange] = useState(false);
  const [editable, setEditable] = useState(false);
  const [checkIcon, setCheckIcon] = useState(false);
  const [templateTitle, setTemplateTitle] = useState(null);
  const [layersStyle, setLayersStyle] = useState([]);
  const [layersWeight, setLayersWeight] = useState([]);
  const [layersColor, setLayersColor] = useState([]);
  const [layersMapping, setLayersMapping] = useState([]);
  const [layersOrderChange, setLayersOrderChange] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [deleteTemplate, setDeleteTemplate] = useState('');
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showError, setShowError] = useState(false);

  const { templateId, orgId } = useParams();
  const template = useAppSelector((state) => state.cadTemplate.list?.find((item) => item._id === templateId));

  const defaultTemplate = useAppSelector((state) => state.account?.org?.defaultTemplate);
  const title = template?.name;
  const isDefaultTemplate = defaultTemplate === templateId;
  const [retainDuplicates, setRetainDuplicates] = useState(template?.retainDuplicates || false);
  const [retainDuplicatesChange, setRetainDuplicatesChange] = useState(false);
  const [openBoundary, setOpenBoundary] = useState(template?.openBoundary);
  const [openBoundaryChange, setOpenBoundaryChange] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setLayersOrderChange([]);
    setLayersWeight([]);
    setLayersStyle([]);
    setLayersColor([]);
    setLayersMapping([]);
    setTemplateChange(false);
    setOpenBoundary(template?.openBoundary);
    setOpenBoundaryChange(false);
    setRetainDuplicates(template?.retainDuplicates);
    setRetainDuplicatesChange(false);
  }, [templateId]);

  const ref = useRef(null);
  const scrollRef = useRef(null);

  const handleIconClick = () => {
    setEditable(true);
    setCheckIcon(true);
    ref.current.focus();
  };

  const handleOnChange = (e: any) => {
    setCheckIcon(true);
    setTemplateTitle(e);
  };

  const handleCheckIconClick = async () => {
    await dispatch(EditTemplate({ _id: templateId, name: templateTitle }));
    setCheckIcon(false);
    setEditable(false);
  };

  const deleteTemplateClick = (id: string) => {
    setShowConfirmDialog(true);
    setDeleteTemplate(id);
  };

  const onOk = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDialog(false);
    dispatch(DeleteTemplate(deleteTemplate));
    history.push(`/account/cadTemplates/${defaultTemplate}`);
    e.stopPropagation();
  };

  const onCancel = () => {
    setShowConfirmDialog(false);
  };

  // Indicating that there are no changes in the layers.
  const noLayersChanges = layersWeight.length === 0 && layersStyle.length === 0 && layersColor.length === 0 && layersMapping.length === 0 && layersOrderChange.length === 0;

  // If openBoundary is undefined and retainDuplicates is false, it means user has to select one of them to enable save button.
  const noBoundaryAndDuplicates = openBoundary === undefined && !retainDuplicates;

  useEffect(() => {
    if (_ADMIN_) {
      dispatch(ResetTemplateListAction());
      dispatch(SetOrgIdAction(orgId));
      const loadData = async () => {
        await dispatch(GetOrgThunk(orgId));
        await dispatch(GetTemplates());
      };
      loadData();
    }
  }, []);

  useEffect(() => {
    dispatch(SetTemplateId(templateId));
    setTemplateTitle(title);
    setLayersStyle([]);
    setLayersWeight([]);
    setLayersColor([]);
    scrollRef.current.scrollTop = 0;
  }, [title, orgId]);

  const EditLayer = async (id: string) => {
    setSubmitting(true);
    const map = new Map();
    layersStyle.forEach((item) => map.set(item._id, item));
    layersWeight.forEach((item) => map.set(item._id, { ...map.get(item._id), ...item }));
    layersColor.forEach((item) => map.set(item._id, { ...map.get(item._id), ...item }));
    layersMapping.forEach((item) => map.set(item._id, { ...map.get(item._id), ...item }));
    const mergedArr = Array.from(map.values());
    await dispatch(EditTemplate({
      _id: id,
      layers: mergedArr,
      layersOrderChange,
      ...(openBoundaryChange && { openBoundary }),
      ...(retainDuplicatesChange && { retainDuplicates }),
    }));
    setSubmitting(false);
    setLayersStyle([]);
    setLayersWeight([]);
    setLayersColor([]);
    setLayersMapping([]);
    setLayersOrderChange([]);
    setTemplateChange(false);
    setRetainDuplicatesChange(false);
    setOpenBoundaryChange(false);
  };

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const handleDownloadTemplate = async (cadTemplateId: string) => {
    const result = await dispatch(DownloadCadTemplateThunk(cadTemplateId));
    if (!result.success) {
      setShowError(true);
    }
  };

  return (
    <>
      <Sidebar {...{ templateId }} handleDownloadTemplate={handleDownloadTemplate} />
      <Box sx={styles.scrollWrapper} ref={scrollRef}>
        <Snackbar open={showError} autoHideDuration={10000} onClose={() => setShowError(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert severity="error">Sorry! We're not able to download your template at this time. Please contact Support for assistance.</Alert>
        </Snackbar>
        {template && (
          <Box>
            <Box sx={styles.headerWrapper}>
              <Box sx={styles.wrapper}>
                <Box sx={styles.duplicateContainer}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        color="primary"
                        checked={retainDuplicates}
                        onChange={() => {
                          setRetainDuplicates(!retainDuplicates);
                          setTemplateChange(true);
                          setRetainDuplicatesChange(true);
                        }}
                        disabled={_ADMIN_ || template.airworksTemplate}
                      />
                    )}
                    label="Retain Duplicates Without Priority"
                  />
                  <WideLightTooltip
                    placement="top"
                    title="Enable this option to retain duplicates for all existing templates without considering layer priority."
                  >
                    <HelpIcon sx={styles.icon} />
                  </WideLightTooltip>
                </Box>
                <Box sx={{ ...styles.header, ...((template.airworksTemplate || isDefaultTemplate) && styles.headerAirworks) }}>
                  <Box sx={styles.flex}>
                    <Typography variant="h1" color="textPrimary">
                      {templateTitle && (
                        <ContentEditable
                          html={templateTitle}
                          disabled={_ADMIN_ || !editable}
                          innerRef={ref}
                          css={styles.contentEditable}
                          onChange={(e) => handleOnChange(e.target.value)}
                          onKeyDown={onKeyDown}
                        />
                      )}
                    </Typography>
                    {' '}
                    &nbsp;&nbsp;
                    {!_ADMIN_ && !editable && !template.airworksTemplate && <EditIcon sx={styles.editIcon} onClick={() => handleIconClick()} />}
                    {!_ADMIN_ && checkIcon && <CheckIcon sx={styles.checkIcon} onClick={() => handleCheckIconClick()} />}
                  </Box>
                  <Button
                    sx={styles.button}
                    onClick={() => handleDownloadTemplate(template._id)}
                  >
                    DOWNLOAD
                  </Button>
                  <Button
                    sx={styles.button}
                    disabled={_ADMIN_ || template.deleting}
                    onClick={() => dispatch(DuplicateTemplate(template._id))}
                  >
                    DUPLICATE
                  </Button>
                  {!template.airworksTemplate && !isDefaultTemplate && (
                    <Button
                      sx={styles.button}
                      disabled={_ADMIN_ || template.deleting}
                      onClick={() => {
                        deleteTemplateClick(template._id);
                      }}
                    >
                      DELETE
                    </Button>
                  )}
                  <ConfirmDialog
                    showDialog={showConfirmDialog}
                    onOk={onOk}
                    onCancel={onCancel}
                    contentText="Selected template will be removed"
                  />
                  <Box>
                    {!template.airworksTemplate && (
                      <Tooltip title={!submitting ? '' : 'Please wait while we update your template, this may take a minute.'} arrow placement="top">
                        <span>
                          <Button
                            variant="contained"
                            color="primary"
                            sx={styles.submitButton}
                            type="submit"
                            disabled={_ADMIN_ || submitting || (!templateChange && noLayersChanges) || noBoundaryAndDuplicates}
                            onClick={() => EditLayer(template._id)}
                          >
                            <Fade in={submitting}>
                              <CircularProgress size={16} sx={styles.buttonProgress} />
                            </Fade>
                            <Fade in={!submitting}>
                              <span>Save</span>
                            </Fade>
                          </Button>
                        </span>
                      </Tooltip>
                    )}
                  </Box>
                </Box>
                <Divider sx={styles.divider} />
              </Box>
            </Box>
            <Box sx={styles.wrapper}>
              <Box sx={styles.checkbox}>
                <Box sx={styles.templateSetting}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        color="primary"
                        checked={isDefaultTemplate}
                        onChange={() => dispatch(DefaultTemplateThunk({ templateId: template._id }))}
                        disabled={_ADMIN_}
                      />
                    )}
                    label="Set as Default Template"
                  />
                  <Box sx={styles.boundaryContainer}>
                    <WideLightTooltip
                      placement="top"
                      title="All features extracted by AirWorks are listed here. We are working to ensure your downloaded .dxf file includes all layers listed on your client template. We’ll keep you posted!"
                    >
                      <HelpIcon sx={styles.icon} />
                    </WideLightTooltip>
                    <Typography>Boundary:</Typography>
                    <Box sx={{ ...styles.borderLineStyle, ...(!template.airworksTemplate && noBoundaryAndDuplicates && styles.errorBorder) }}>
                      <Select
                        sx={styles.boundarySelect}
                        input={<InputBase />}
                        // eslint-disable-next-line no-nested-ternary
                        value={openBoundary === true ? 'open' : (openBoundary === false ? 'closed' : '')}
                        disabled={_ADMIN_ || template.airworksTemplate}
                        disableUnderline
                        variant="outlined"
                        onChange={(e: any) => {
                          setOpenBoundary(e.target.value === 'open');
                          setTemplateChange(true);
                          setOpenBoundaryChange(true);
                        }}
                      >
                        <MenuItem value="open">Open</MenuItem>
                        <MenuItem value="closed">Closed</MenuItem>
                      </Select>
                      {!template.airworksTemplate && noBoundaryAndDuplicates && <FormHelperText sx={styles.error}>Required field</FormHelperText>}
                    </Box>
                  </Box>
                </Box>
                <Layers
                  templateId={templateId}
                  layersOrderChange={layersOrderChange}
                  setLayersStyle={setLayersStyle}
                  setLayersWeight={setLayersWeight}
                  setLayersColor={setLayersColor}
                  setLayersMapping={setLayersMapping}
                  setLayersOrderChange={setLayersOrderChange}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default CadTemplates;
