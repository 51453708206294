// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { useEffect, useState } from 'react';
import { Button, Fade, CircularProgress, SnackbarContent, Collapse, Switch, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Form } from 'react-final-form';
import { TextField, Validators, Helpers, Select } from 'Components/forms';
import LoadingSpinner from 'Components/LoadingSpinner';
import states from 'Utils/states';
import stylesDef from './styles';
import { GetOrgThunk, OrgEmailsThunk, SetOrgIdThunk, UpdateOrgThunk } from '../adminThunk';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { Box, useTheme } from '@mui/system';
import { useParams } from 'react-router';

export const AdminGeneral = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const params = useParams();
  const [showLoader, setShowLoader] = useState(false);
  const [initialState, setInitialState] = useState(undefined);
  const [checked, setChecked] = useState(false);
  const { orgId } = params;

  useEffect(() => {
    dispatch(SetOrgIdThunk(orgId));

    const loadData = async () => {
      setShowLoader(true);
      await dispatch(GetOrgThunk(orgId));
      setShowLoader(false);
    };

    loadData();
  }, []);

  const optOutOfEmails = useAppSelector((state) => state.admin?.org?.optOutOfEmails);
  const initialValues = useAppSelector((state) => state.admin.org);

  useEffect(() => {
    if (!initialState && initialValues) {
      setInitialState(initialValues);
    }
  }, [initialValues]);

  useEffect(() => {
    setChecked(optOutOfEmails);
  }, [optOutOfEmails]);
  const toggleChecked = () => {
    if (orgId) {
      dispatch(OrgEmailsThunk());
    }
  };

  const updateOrgThunk = (payload: IOrg) => dispatch(UpdateOrgThunk(payload));
  const onFormSubmit = (values: any) => Helpers.submit({ _id: orgId, ...values }, updateOrgThunk);
  return (
    <LoadingSpinner loading={showLoader}>
      <Form
        onSubmit={onFormSubmit}
        keepDirtyOnReinitialize
        initialValues={initialState}
        render={({ hasSubmitErrors, modifiedSinceLastSubmit, submitting, pristine, submitError, submitSucceeded, handleSubmit }) => (
          <form
            style={styles.form}
            onSubmit={handleSubmit}
          >
            <TextField
              label="Company Name"
              name="companyName"
              validate={Validators.required}
            />
            <TextField
              label="Address 1"
              name="address.addressLine1"
              validate={Validators.required}
            />
            <TextField
              label="Address 2"
              name="address.addressLine2"
              validate={Validators.required}
            />
            <Box sx={styles.formRow}>
              <TextField
                label="City"
                name="address.city"
                validate={Validators.required}
              />
              <Select
                label="State"
                name="address.state"
                values={states}
                validate={Validators.required}
              />
              <TextField
                label="Zip Code"
                name="address.zipCode"
                validate={Validators.required}
              />
            </Box>
            <TextField
              label="Phone Number"
              name="phoneNumber"
              validate={Validators.composeValidators(Validators.required, Validators.mobilePhone)}
            />
            <Collapse in={Boolean(hasSubmitErrors && !modifiedSinceLastSubmit)}>
              <SnackbarContent
                sx={styles.errorSnackbar}
                message={(
                  <Box sx={styles.snackbarContent}>
                    <ErrorIcon />
                    {submitError}
                  </Box>
                )}
              />
            </Collapse>
            <Collapse in={!submitting && submitSucceeded}>
              <SnackbarContent
                sx={styles.successSnackbar}
                message={(
                  <Box sx={styles.snackbarContent}>
                    <CheckCircleIcon />
                    Update Successful!
                  </Box>
                )}
              />
            </Collapse>
            <Box sx={styles.sectionWrapper}>
              <Typography variant="h2">Email Notifications</Typography>
              <Box sx={styles.divider} />
              <Box sx={styles.settingWrapper}>
                <Typography variant="h4">Disable WebApp Emails</Typography>
                <Switch checked={checked} onChange={toggleChecked} color="primary" />
              </Box>
            </Box>
            <Button
              variant="contained"
              color="primary"
              sx={styles.submitButton}
              type="submit"
              disabled={pristine || (hasSubmitErrors && !modifiedSinceLastSubmit) || submitting}
            >
              <Fade in={submitting}>
                <CircularProgress size={24} sx={styles.buttonProgress} />
              </Fade>
              <Fade in={!submitting}>
                <span>Save Changes</span>
              </Fade>
            </Button>
          </form>
        )}
      />
    </LoadingSpinner>
  );
};

export default AdminGeneral;
