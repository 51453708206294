// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import {
  Divider, Checkbox, FormControlLabel, Typography, ListItemButton,
} from '@mui/material';
import { useTheme } from '@mui/system';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { ToggleBasemapLayerAction } from 'Features/map/mapCommonActions';
import stylesDef from './styles';

export const Basemap = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const layers = useAppSelector((state) => state.map.common.layers);

  return (
    <>
      <ListItemButton sx={styles.expansionPanelHeader}>
        <FormControlLabel
          label={<Typography variant="h4" sx={styles.sidebarHeader}>Basemap</Typography>}
          control={(
            <Checkbox
              color="primary"
              onChange={() => dispatch(ToggleBasemapLayerAction())}
              checked={layers.baseMap}
              sx={styles.checkbox}
            />
          )}
        />
      </ListItemButton>
      <Divider />
    </>
  );
};

export default Basemap;
