// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
    gridColumnGap: theme.spacing(1),
  },
  inputField: {
    '& .MuiInputBase-input': {
      width: '100px',
      padding: '4px',
      background: '#E6E8ED',
      height: theme.spacing(2),
      fontSize: '1rem',
    },
  },
  value: {
    fontWeight: 400,
  },
  icon: {
    color: theme.palette.primary.main,
    padding: 0,
  },
});

export default styles;
