// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import Center2 from 'Assets/lineStylePNGs/Center_2.png';
import Center_x2 from 'Assets/lineStylePNGs/Center_x2.png';
import Center from 'Assets/lineStylePNGs/Center.png';
import Continuous from 'Assets/lineStylePNGs/Continuous.png';
import Dashdot_2 from 'Assets/lineStylePNGs/Dashdot_2.png';
import Dashdot_x2 from 'Assets/lineStylePNGs/Dashdot_x2.png';
import Dashdot from 'Assets/lineStylePNGs/Dashdot.png';
import Dashed_2 from 'Assets/lineStylePNGs/Dashed_2.png';
import Dashed_x2 from 'Assets/lineStylePNGs/Dashed_x2.png';
import Dashed from 'Assets/lineStylePNGs/Dashed.png';
import Divide_x2 from 'Assets/lineStylePNGs/Divide_x2.png';
import Divide_2 from 'Assets/lineStylePNGs/Divide_2.png';
import Divide from 'Assets/lineStylePNGs/Divide.png';
import Dot_2 from 'Assets/lineStylePNGs/Dot_2.png';
import Dot_x2 from 'Assets/lineStylePNGs/Dot_x2.png';
import Dot from 'Assets/lineStylePNGs/Dot.png';
import Phantom_2 from 'Assets/lineStylePNGs/Phantom_2.png';
import Phantom_x2 from 'Assets/lineStylePNGs/Phantom_x2.png';
import Phantom from 'Assets/lineStylePNGs/Phantom.png';

export const lineStyles = [
  {
    style: 'Custom',
    img: Phantom,
  },
  {
    style: 'Double Dashed',
    img: Dashed,
  },
  {
    style: 'Dotted',
    img: Dot,
  },
  {
    style: 'Solid',
    img: Phantom, // this needs to change
  },
  {
    style: 'Continuous',
    img: Continuous,
  },
  {
    style: 'Center',
    img: Center,
  },
  {
    style: 'Centerx2',
    img: Center_x2,
  },
  {
    style: 'Center2',
    img: Center2,
  },
  {
    style: 'Dashed',
    img: Dashed,
  },
  {
    style: 'Dashedx2',
    img: Dashed_x2,
  },
  {
    style: 'Dashed2',
    img: Dashed_2,
  },
  {
    style: 'Phantom',
    img: Phantom,
  },
  {
    style: 'Phantomx2',
    img: Phantom_x2,
  },
  {
    style: 'Phantom2',
    img: Phantom_2,
  },
  {
    style: 'Dashdot',
    img: Dashdot,
  },
  {
    style: 'Dashdotx2',
    img: Dashdot_x2,
  },
  {
    style: 'Dashdot2',
    img: Dashdot_2,
  },
  {
    style: 'Dot',
    img: Dot,
  },
  {
    style: 'Dotx2',
    img: Dot_x2,
  },
  {
    style: 'Dot2',
    img: Dot_2,
  },
  {
    style: 'Divide',
    img: Divide,
  },
  {
    style: 'Dividex2',
    img: Divide_x2,
  },
  {
    style: 'Divide2',
    img: Divide_2,
  },
  {
    style: 'CUSTOM',
    img: Phantom, // this needs to change
  },
  {
    style: 'DOUBLE DASHED',
    img: Dashed,
  },
  {
    style: 'DOTTED',
    img: Dot,
  },
  {
    style: 'SOLID', // this needs to change
    img: Phantom,
  },
  {
    style: 'CONTINUOUS',
    img: Continuous,
  },
  {
    style: 'CENTER',
    img: Center,
  },
  {
    style: 'CENTERX2',
    img: Center_x2,
  },
  {
    style: 'CENTER2',
    img: Center2,
  },
  {
    style: 'DASHED',
    img: Dashed,
  },
  {
    style: 'DASHEDX2',
    img: Dashed_x2,
  },
  {
    style: 'DASHED2',
    img: Dashdot_2,
  },
  {
    style: 'PHANTOM',
    img: Phantom,
  },
  {
    style: 'PHANTOMX2',
    img: Phantom_x2,
  },
  {
    style: 'PHANTOM2',
    img: Phantom_2,
  },
  {
    style: 'DASHDOT',
    img: Dashdot,
  },
  {
    style: 'DASHDOTX2',
    img: Phantom,
  },
  {
    style: 'DASHDOT2',
    img: Dashdot_2,
  },
  {
    style: 'DOT',
    img: Dot,
  },
  {
    style: 'DOTX2',
    img: Dot_x2,
  },
  {
    style: 'DOT2',
    img: Dot_2,
  },
  {
    style: 'DIVIDE',
    img: Divide,
  },
  {
    style: 'DIVIDEX2',
    img: Divide_x2,
  },
  {
    style: 'DIVIDE2',
    img: Divide_2,
  },
];

export const lineWeight: number[] = [];
for (let i = -3; i <= 100; i += 1) {
  lineWeight.push(i);
}

export default lineStyles;
