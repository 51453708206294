// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  sidebarContent: {
    flex: 1,
    width: 320,
    overflow: 'auto',
  },
  orderButtonWrapper: {
    bottom: 0,
    width: '100%',
    padding: theme.spacing(2),
  },
  orderButton: {
    paddingTop: '10px',
    paddingBottom: '10px',
    color: '#1c364b',
  },
  acreageWrapper: {
    paddingTop: theme.spacing(),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(),
  },
  downloadContainer: {
    paddingRight: '10px',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
  },
  pdfButton: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    height: 48,
    border: '1px solid #000',
    bottom: '10%',
    color: '#021E40',
  },
  downloadImage: {
    paddingRight: theme.spacing(2),
  },
});

export default styles;
