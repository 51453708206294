// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import { Tooltip } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import proj4 from 'proj4';
import coordinatesIcon from 'Assets/coordinates-icon.png';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { getProjectEPSGCode } from 'Features/project/projectSelectors';
import { GetEpsgProjectionsThunk } from 'Features/project/projectThunk';
import { ResetLatAndLngAction } from 'Features/map/mapCommonActions';
import stylesDef from './styles';

export const EpsgProjection = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const epsg = useAppSelector((state) => getProjectEPSGCode(state));
  const epsgAuthName = useAppSelector((state) => state.project.epsgAuthName);
  const epsgProjections = useAppSelector((state) => state.project.epsgProjections);
  const firstProjection = useAppSelector((state) => state.project.firstProjection);
  const secondProjection = useAppSelector((state) => state.project.secondProjection);

  const projectionData = epsgProjections;
  const projCSStart = projectionData && projectionData.search('PROJCS');
  const projCS = projCSStart === 0 ? projectionData.match(/"([^"]+)"/)[1] : 'Unavailable';
  const geogCSStart = projectionData && projectionData.search('GEOGCS');
  const geogCS = geogCSStart && geogCSStart !== -1 ? projectionData.substring(geogCSStart).match(/"([^"]+)"/)[1] : 'Unavailable';
  const datumStart = projectionData && projectionData.search('DATUM');
  const datum = datumStart && datumStart !== -1 ? projectionData.substring(datumStart).match(/"([^"]+)"/)[1].replace(/_/g, ' ') : 'Unavailable';
  const latitude = useAppSelector((state) => state.map.common.latitude);
  const longitude = useAppSelector((state) => state.map.common.longitude);
  const lat = Number(latitude);
  const lng = Number(longitude);
  const coordinates = firstProjection && secondProjection && lat && lng && proj4(firstProjection, secondProjection, [lng, lat]);
  const xCoordinate = coordinates && coordinates[0].toFixed(3);
  const yCoordinate = coordinates && coordinates[1].toFixed(3);
  const epsgCode = epsg?.tifEpsg || epsg?.lasEpsg;

  const [toolTipTitle, settoolTipTitle] = useState('');
  const onLoad = async () => {
    dispatch(GetEpsgProjectionsThunk(epsgCode));
    dispatch(ResetLatAndLngAction());
  };

  const openToolTip = () => {
    settoolTipTitle(`PROJCS: ${projCS},\n GEOGCS: ${geogCS},\n DATUM: ${datum}`);
  };

  useEffect(() => {
    onLoad();
  }, [epsgCode]);

  return (
    <Box>
      { ((lat && lng && !Number.isNaN(lat) && !Number.isNaN(lng)) ||
        (epsgAuthName !== 'USER' && epsgCode > 0 && geogCS) ||
        (xCoordinate && yCoordinate && !Number.isNaN(xCoordinate) && !Number.isNaN(yCoordinate))) && (
        <Box sx={styles.info}>
          {lat && lng && !Number.isNaN(lat) && !Number.isNaN(lng) ? (
            <>
              <LanguageIcon fontSize="small" style={styles.languageIcon} />
              <span>
                <span style={styles.latValue}>{`Lat: ${lat.toFixed(6)}`}</span>
                <span style={styles.lngValue}>{`Lon: ${lng.toFixed(6)}`}</span>
              </span>
            </>
          ) : null}
          {epsgAuthName !== 'USER' && epsgCode > 0 && geogCS && (
            <Tooltip title={toolTipTitle} placement="top" sx={{ tooltip: styles.tooltip }}>
              <span style={{ cursor: 'pointer' }} onMouseEnter={() => openToolTip()}>
                <img style={styles.coordinatesIcon} src={coordinatesIcon} alt="Coordinates icon" />
                <span style={styles.geogCS}>{` ${geogCS}` }</span>
                <span>&#8212;</span>
                <span>{` EPSG: ${epsgCode}`}</span>
              </span>
            </Tooltip>
          )}
          {xCoordinate && yCoordinate && !Number.isNaN(xCoordinate) && !Number.isNaN(yCoordinate) ? (
            <span style={styles.coordinates}>
              <span style={styles.latValue}>{ `X: ${xCoordinate}`}</span>
              <span style={styles.lngValue}>{` Y: ${yCoordinate}`}</span>
            </span>
          ) : null}
        </Box>
      )}
    </Box>
  );
};

export default EpsgProjection;
