// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { useAppSelector } from 'Hooks';
import { getOrders } from 'Features/order/orderSelectors';
import stylesDef from './styles';

export const SelectionInfo = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const order = useAppSelector((state) => getOrders(state).activeOrder);
  const project = useAppSelector((state) => state.project.project);

  return order && order.middlePoint && Object.entries(order.middlePoint).length > 0 && (
    <Box sx={styles.selection}>
      <Box sx={styles.selectionRow}>
        <Typography variant="h2">Your selection</Typography>
      </Box>
      <Box sx={styles.selectionRow}>
        <Typography variant="h4" sx={styles.padding}>Location</Typography>
        <Typography variant="body1">
          {`${order.middlePoint.place}, ${order.middlePoint.region} ${order.middlePoint.postcode}`}
          <br />
          {`${order.middlePoint.longitude.toFixed(5)}, ${order.middlePoint.latitude.toFixed(5)}`}
        </Typography>
      </Box>
      <Box sx={styles.selectionRow}>
        <Typography variant="h4" sx={styles.padding}>{project.type === 'utilities' ? 'Project Length' : 'Acreage'}</Typography>
        <Typography variant="body1">{project.type === 'utilities' ? `${order.kmlLength} mi  (${order.orderLinearFeet} feet)` : `${order.acreage} acres`}</Typography>
      </Box>
    </Box>
  );
};

export default SelectionInfo;
