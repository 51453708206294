// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  details: {
    marginBottom: theme.spacing(4),
    color: '#FFFFFF',
    marginTop: theme.spacing(4),
  },
  header: {
    marginTop: theme.spacing(4),
  },
  forgotText: {
    color: '#01a1dd',
  },
});

export default styles;
