// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { useEffect } from 'react';
import ProjectsTable from './ProjectsTable';
import { useAppDispatch } from 'Hooks';
import { GetOrgThunk, GetOrgProjectsThunk, SetOrgIdThunk } from '../adminThunk';
import { GetOrgTeamsThunk } from 'Features/account/accountThunk';
import { GetTemplates } from 'Features/account/cadTemplates/cadTemplateThunk';
import { useParams } from 'react-router-dom';

export const AccountProjects = () => {
  const { orgId } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(SetOrgIdThunk(orgId));
    const loadData = async () => {
      await dispatch(GetOrgProjectsThunk(orgId));
      await dispatch(GetOrgThunk(orgId));
      await dispatch(GetOrgTeamsThunk(orgId));
      await dispatch(GetTemplates());
    };
    loadData();
  }, [orgId]);

  return (
    <>
      <ProjectsTable orgId={orgId} />
    </>
  );
};

export default AccountProjects;
