// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dispatch } from 'redux';
import { getJson, postJson, patchJson, deleteRequest } from 'Utils/http';
import history from 'Utils/history';
import { API_URL } from 'Config';
import { GetTemplatesRoute, GetAdminCadTemplatesRoute } from 'Utils/routes';
import type { RootState } from 'Store';
import {
  GetTemplatesStartAction,
  GetTemplatesSuccessAction,
  GetTemplatesFailureAction,
  SetTemplateIdAction,
  DeleteTemplateStartAction,
  DeleteTemplateSuccessAction,
  DeleteTemplateFailureAction,
  EditTemplateStartAction,
  EditTemplateSuccessAction,
  EditTemplateFailureAction,
  DuplicateTemplateSuccessAction,
  SetSelectedStyleAction,
  SetSelectedWeightAction,
  SetSelectedColorAction,
  SetSelectedMappingAction,
  AddTemplateAction,
  GetDefaultTemplateAction,
} from './cadTemplateActions';

export const GetDefaultTemplate = (template: string) => (dispatch: Dispatch) => dispatch(GetDefaultTemplateAction(template));

export const GetTemplates = () =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const orgId = _ADMIN_ ? getState().admin.orgId : '';
    const url = _ADMIN_ ? `${API_URL}/admins/getCadTemplates/${orgId}` :
      `${API_URL}/cadtemplates`;
    const { token } = getState().auth;

    dispatch(GetTemplatesStartAction());

    const result = await getJson<ICadTemplate[]>(url, token);
    if (result.success) {
      dispatch(GetTemplatesSuccessAction(result.data));
    } else {
      dispatch(GetTemplatesFailureAction(result.message));
    }
    return result;
  };

// Deletes a template with a given templateId
export const DeleteTemplate = (templateId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const url = `${API_URL}/cadtemplates/${templateId}`;
    const { token } = getState().auth;
    dispatch(DeleteTemplateStartAction(templateId));

    const result = await deleteRequest(url, token);
    if (result.success) {
      history.push(GetTemplatesRoute(templateId));
      dispatch(DeleteTemplateSuccessAction(templateId));
    } else {
      dispatch(DeleteTemplateFailureAction(templateId));
    }
  };

// Duplicate template
export const DuplicateTemplate = (data: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const url = `${API_URL}/cadtemplates/copyTemplate`;
    const { token } = getState().auth;

    const result = await postJson<ICadTemplate>(url, { templateId: data }, token);
    if (result.success) {
      dispatch(DuplicateTemplateSuccessAction(result.data.newTemplate));
    }
    return result;
  };

// Update template name or template layer(s)
export const AddTemplate = (cadTemplate: ICadTemplate) => (dispatch: Dispatch) => dispatch(AddTemplateAction(cadTemplate));

export const EditTemplate = (data: {_id: string, layers?: any[], name?: string, layersOrderChange?: any[], openBoundary?: boolean, retainDuplicates?: boolean}) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const url = `${API_URL}/cadtemplates/editTemplate/${data._id}`;
    const { token } = getState().auth;
    dispatch(EditTemplateStartAction());

    const result = await patchJson<ICadTemplate>(url, data, token);
    if (result.success) {
      const cadTemplateData = result.data.cadTemplate;
      const newLayerOrder = cadTemplateData?.layers?.map((layer: any) => layer.name);
      window?.pendo?.track('CAD Template Updated', { layer_priorities: newLayerOrder, retain_duplicates: cadTemplateData.retainDuplicates, boundary_choice: !!(cadTemplateData.openBoundary) });
      dispatch(EditTemplateSuccessAction(cadTemplateData));
    } else {
      dispatch(EditTemplateFailureAction());
    }
    return result;
  };

export const SetTemplateId = (templateId: string) => (dispatch: Dispatch) => dispatch(SetTemplateIdAction(templateId));

export const SetSelectedStyle = (data : { templateId: string, layerId: string, event: string}) => (dispatch: Dispatch) => dispatch(SetSelectedStyleAction(data));

export const SetSelectedWeight = (data : { templateId: string, layerId: string, event: number}) => (dispatch: Dispatch) => dispatch(SetSelectedWeightAction(data));

export const SetSelectedColor = (data : { templateId: string, layerId: string, color: string}) => (dispatch: Dispatch) => dispatch(SetSelectedColorAction(data));

export const AddLayers = (data: {templateId: string, newLayers: any[]}) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const url = `${API_URL}/cadtemplates/addLayers`;
    const { token } = getState().auth;

    const result = await postJson<{[key: string]: string}>(url, data, token);
    return result;
  };

export const SetSelectedMapping = (data : { templateId: string, layerId: string, event: Array<string>}) => (dispatch: Dispatch) => dispatch(SetSelectedMappingAction(data));

export const DownloadCadTemplateThunk = (templateId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const url = `${API_URL}/cadtemplates/download/${templateId}`;
    const { token } = getState().auth;

    const result = await getJson<string>(url, token);

    if (result.success) {
      window.open(result.data, '_self');
    }
    return result;
  };
