// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContentText, Typography, Button, Input, TextField } from '@mui/material/';
import { Box, useTheme } from '@mui/system';
import stylesDef from './styles';
import { AdjustOrderCreditsThunk } from 'Features/order/orderThunk';
import { useAppDispatch } from 'Hooks';

interface ComponentProps {
  showDialog: boolean;
  onOk: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onCancel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  confirmAmount: number;
  orderId: string;
  orgId: string;
  projectId: string;
}

export const ConfirmAmountDialog: React.FC<ComponentProps> = ({ showDialog, onOk, onCancel, confirmAmount, orderId, orgId, projectId }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const [adjustment, setAdjustment] = useState(confirmAmount);
  const [reason, setReason] = useState(null);

  const confirm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onOk(e);
    dispatch(AdjustOrderCreditsThunk(orderId, orgId, projectId, adjustment, reason));
    setReason(null);
  };

  const confirmCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onCancel(e);
    setReason(null);
  };

  const validateAmount = (input: number) => {
    if (input < (confirmAmount * -1)) {
      setAdjustment(confirmAmount * -1);
    } else {
      setAdjustment(input);
    }
  };

  return (
    <Dialog
      open={showDialog}
      onClose={confirmCancel}
    >
      <Typography sx={styles.title} variant="h3">Adjust Order</Typography>
      <Typography sx={styles.subTitle} variant="h6">Please enter the number of credits this order should be adjusted by:</Typography>
      <Box sx={styles.infoWrapper}>
        <Box sx={styles.inputWrapper}>
          <Input
            required
            type="number"
            sx={styles.input}
            defaultValue={confirmAmount}
            inputProps={{ min: (confirmAmount * -1) }}
            onChange={(e) => validateAmount(+(e.target.value))}
          />
          <DialogContentText>Credits</DialogContentText>
        </Box>
        <TextField
          required
          id="outlined-required"
          label="Adjustment Reason"
          onChange={(e) => setReason(e.target.value)}
        />
      </Box>
      <DialogActions>
        <Button onClick={confirmCancel}>Cancel</Button>
        <Button disabled={!adjustment || !reason} onClick={confirm}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmAmountDialog;
