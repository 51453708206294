// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Typography, List, ListItem, ListItemIcon } from '@mui/material';
import { Box } from '@mui/system';
import flashIcon from 'Assets/flash-icon.png';
import checkIcon from 'Assets/circle-check-light.png';
import stylesDef from './styles';

const PayAsYouGoCard = () => {
  const styles = stylesDef();
  return (
    <Box sx={styles.planContainer}>
      <Box sx={styles.planCard}>
        <img src={flashIcon} alt="flash icon" css={styles.flashIcon} />
        <Typography sx={styles.fontColor} variant="h2">
          Pay as you go
        </Typography>
        <Typography sx={styles.planDetail} variant="h1">
          No-commitment
        </Typography>
        <List component="nav" sx={styles.list}>
          <ListItem>
            <ListItemIcon sx={styles.listItemIcon}>
              <img src={checkIcon} alt="check icon" css={styles.checkIcon} />
            </ListItemIcon>
            <Typography sx={styles.planText}>
              Built for those who want ultimate flexibility
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={styles.listItemIcon}>
              <img src={checkIcon} alt="check icon" css={styles.checkIcon} />
            </ListItemIcon>
            <Typography sx={styles.planText}>
              Instantly push your first project
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemIcon sx={styles.listItemIcon}>
              <img src={checkIcon} alt="check icon" css={styles.checkIcon} />
            </ListItemIcon>
            <Typography sx={styles.planText}>
              Benefit from lower per credit prices as usage increases
            </Typography>
          </ListItem>
        </List>
        <Box sx={styles.planFooter}>
          <Typography sx={styles.planText}>
            Want a lower, locked-in rate? contact
            {' '}
            <a href="mailto:sales@airworks.io" css={styles.fontColor}>sales@airworks.io</a>
          </Typography>
        </Box>
      </Box>
      <Box sx={styles.footerBar} />
    </Box>
  );
};

export default PayAsYouGoCard;
