// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Layer, Source, Popup } from 'react-map-gl';
import { Typography } from '@mui/material';
import { useAppSelector } from 'Hooks';
import stylesDef from './styles';

interface RasterLayerProps {
  showLabel: boolean;
}

const MapLasBBox: React.FC<RasterLayerProps> = ({ showLabel = true }) => {
  const styles = stylesDef();
  const lasBBox = useAppSelector((state) => state.map.common.lasBBox);

  let bboxGeoJSON: GeoJSON.Feature<GeoJSON.Polygon> = {
    type: 'Feature',
    geometry: undefined,
    properties: {},
  };

  if (lasBBox) {
    bboxGeoJSON = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [[
          [lasBBox[0], lasBBox[1]],
          [lasBBox[2], lasBBox[1]],
          [lasBBox[2], lasBBox[3]],
          [lasBBox[0], lasBBox[3]],
          [lasBBox[0], lasBBox[1]],
        ]],
      },
      properties: {},
    };
  }

  return (
    <>
      {lasBBox && (
        <Source
          id="las-bbox"
          type="geojson"
          data={bboxGeoJSON}
        >
          <Layer
            id="las-bbox-layer"
            source="las-bbox"
            layout={{
              visibility: 'visible',
            }}
            type="line"
            paint={{
              'line-color': '#ff0000',
              'line-width': 2,
            }}
          />
        </Source>
      )}
      {(lasBBox && showLabel) && (
        <Popup longitude={lasBBox[2]} latitude={lasBBox[3]} css={styles.popUp}>
          <Typography sx={styles.popUpText} variant="body2">
            Las Bounding Box
          </Typography>
        </Popup>
      )}
    </>
  );
};

export default MapLasBBox;
