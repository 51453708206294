// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { useAppSelector, useAppDispatch } from 'Hooks';
import { MenuItem, Button, Typography, Popper, Paper, MenuList, ClickAwayListener, Fade } from '@mui/material';
import { ACCOUNT_GENERAL_ROUTE, IsAccountPath } from 'Utils/routes';
import { Logout } from 'Features/auth/authThunk';
import { useLocation, useNavigate } from 'react-router';
import { useOktaAuth } from '@okta/okta-react';
import { useTheme } from '@mui/system';
import stylesDef from './styles';

const PopupMenu = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const accountMatch = IsAccountPath(location.pathname);
  const { oktaAuth } = _ADMIN_ && useOktaAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const email = useAppSelector((state) => !_ADMIN_ ? state.account.user?.email : state.auth?.user?.email);
  const handleMenuClose = () => setAnchorEl(null);
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setAnchorEl(event.currentTarget);
  const isMenuOpen = Boolean(anchorEl);

  const accountClick = () => {
    handleMenuClose();
    navigate(ACCOUNT_GENERAL_ROUTE);
  };

  const logoutClick = async () => {
    handleMenuClose();
    if (_ADMIN_) {
      await oktaAuth.signOut();
    }
    dispatch(Logout());
  };

  return (
    <>
      <Button
        aria-owns={isMenuOpen ? 'material-appbar' : undefined}
        onClick={handleMenuOpen}
        color="inherit"
      >
        <Typography variant="body1" sx={styles.toolbarText}>{email}</Typography>
      </Button>
      <Popper style={{ zIndex: 9999 }} open={isMenuOpen} anchorEl={anchorEl} transition>
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleMenuClose}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <MenuList sx={styles.menuList}>
                  { !_ADMIN_ ? (<MenuItem sx={styles.fontWeight} onClick={accountClick} selected={Boolean(accountMatch)}>Account</MenuItem>) : null}
                  <MenuItem sx={styles.fontWeight} onClick={logoutClick}>Log Out</MenuItem>
                </MenuList>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default PopupMenu;
