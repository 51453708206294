// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { useState } from 'react';
import { Dialog, Typography, Select, Button, InputBase, DialogActions, MenuItem, CircularProgress } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import stylesDef from './styles';
import { useAppDispatch } from 'Hooks';
import { GeneratePostgisExportFileThunk } from 'Features/order/orderThunk';

export const GenerateGDBDialog = ({
  cadFiles,
  showDialog,
  setError,
  onCancel,
}) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();

  const [dxf, setDxf] = useState(cadFiles[cadFiles.length - 1]);
  const [loading, setLoading] = useState(false);

  const generateGDB = async () => {
    setLoading(true);
    const result = await dispatch(GeneratePostgisExportFileThunk(dxf._id, '.gdb'));
    if (result?.error) {
      setError(true);
    }
    setLoading(false);
    onCancel();
  };

  return (
    <Dialog
      open={showDialog}
      sx={styles.dialog}
    >
      <Box sx={styles.dialogBox}>
        <Typography variant="h5" sx={styles.dialogText}>
          Generate GDB
        </Typography>
        <Typography>
          There are multiple dxf files in your order, please select the filename you need converted into a .gdb:
        </Typography>
        <Select
          variant="outlined"
          input={<InputBase />}
          sx={styles.select}
          value={dxf?._id}
          onChange={(ev) => { setDxf(cadFiles.find((cad) => cad._id === ev.target.value)); }}
        >
          {cadFiles.map((cad) =>
            <MenuItem sx={styles.select} value={cad._id} key={cad._id}>{cad.name}</MenuItem>)}
        </Select>
        <DialogActions sx={styles.dialogActions}>
          <Button onClick={onCancel} variant="text">
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={generateGDB}
            sx={styles.button}
          >
            {loading ? <CircularProgress size={24} sx={styles.buttonProgress} /> : 'Generate'}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default GenerateGDBDialog;
