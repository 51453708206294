// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  confirmColumn: {
    padding: theme.spacing(2),
  },
  pageTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    fontWeight: 400,
    fontSize: '28px',
  },
  confirmText: {
    fontWeight: 400,
    marginBottom: theme.spacing(4),
  },
  sectionTitle: {
    marginBottom: theme.spacing(1),
    textTransform: 'capitalize',
  },
  subSectionTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  section: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    marginTop: theme.spacing(2),
  },
  button: {
    padding: 0,
    justifyContent: 'flex-end',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  cardWrapper: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gridColumnGap: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    alignItems: 'center',
    width: '100%',
    textAlign: 'left',
    border: '1px solid transparent',
  },
  iconWrapper: {
    width: theme.spacing(4),
  },
  defaultCard: {
    borderColor: theme.palette.primary.main,
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  deliverableList: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
    justifySelf: 'left',
    '&>*:nth-child(2n)': {
      textAlign: 'right',
      justifySelf: 'right',
    },
  },
  emailTag: {
    color: '#01A1DD',
  },
});

export default styles;
