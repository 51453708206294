// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment-timezone';
import { Link as RouterLink } from 'react-router-dom';
import { Card, CardActionArea, CardActions, CardMedia, Typography, IconButton, LinearProgress, Button, Avatar, Stepper, Step, StepLabel, Link } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { styled } from '@mui/material/styles';
import ProjectPreview from 'Assets/projectPreview.png';
import { GetProjectDetailsRoute, GetProjectFilesRoute } from 'Utils/routes';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { Box, useTheme } from '@mui/system';
import { useSelector } from 'react-redux';
import { SetProjectIdAction } from 'Features/project/projectActions';
import { DeleteProjectThunk } from 'Features/project/projectThunk';
import ColorlibStepIcon from './StepIcon';
import { selectProjectInfo } from '../projectListSelector';
import { ReadThumbnailThunk } from '../projectListThunk';
import stylesDef from './styles';

moment.tz.setDefault('America/New_York');

type ProjectListItemProps = {
  project: IProject;
};

const ActiveLabels = ['Order placed', 'Processing', 'Checking for Quality', 'Done'];
const steps = ['', '', '', ''];

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 8,
    left: 'calc(-50% + 0px)',
    right: 'calc(50% + 0px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient(90deg, #9038F5 0%, #3C34B7 33.29%, #2075FE 74.06%, #01A1DD 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient(90deg, #9038F5 0%, #3C34B7 33.29%, #2075FE 74.06%, #01A1DD 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 4,
    border: 0,
    backgroundColor: '#797979',
    borderRadius: 1,
    width: '100%',
  },
}));

const ProjectListItem = ({ project }: ProjectListItemProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const projectInfo = useSelector((state) => selectProjectInfo(state, project));
  const { isInProgress, placed, autonomous, cleaning, complete, cannotDelete, orderFinalized, expressAIReady,
    orderEstimate, orderStatus, imageryRequest, rasterRequest } = projectInfo;
  const activeStep = placed + autonomous + cleaning + complete;
  const { resources } = useAppSelector(
    (state) => state.auth,
  );
  const projectDetailsTab = 'projectDetailsTab' in resources;
  const opsTrainer = 'opsTrainer' in resources;

  // check project details resource as well as orderStatus not equal to 7 then navigate user to project details tab, else cad view tab
  let projectUrl = projectDetailsTab ? GetProjectDetailsRoute(project._id) : GetProjectFilesRoute(project._id);

  if (orderFinalized || expressAIReady) {
    projectUrl = GetProjectFilesRoute(project._id);
  }

  const thumbnail = !project.thumbnail || project.thumbnail.includes('undefined');

  useEffect(() => {
    if (!thumbnail) {
      dispatch(ReadThumbnailThunk(project._id));
    }
  }, []);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(false);

  const ref = useRef<any>(null);

  const [hovered, setHovered] = useState(false);

  const enter = () => {
    dispatch(SetProjectIdAction(project._id));
    setHovered(true);
  };
  const leave = () => {
    dispatch(SetProjectIdAction(null));
    setHovered(false);
  };

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.addEventListener('onmouseenter', enter);
      ref.current.addEventListener('onmouseleave', leave);
    }
    return () => {
      if (ref && ref.current) {
        ref.current.removeEventListener('onmouseenter', enter);
        ref.current.removeEventListener('onmouseleave', leave);
      }
    };
  }, [ref]);

  const initials = project.creator.match(/\b\w/g) || [];

  const deleteProjectClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setShowDeleteConfirmation(true);
  };

  const deleteCancelClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setShowDeleteConfirmation(false);
  };

  const deleteConfirmClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setShowDeleteConfirmation(false);
    dispatch(DeleteProjectThunk(project._id));
  };

  const { requested, uploaded, rejected, notificationSent } = imageryRequest || {};

  const rasterRequested = rasterRequest?.requested;
  const imageryRequested = requested && !rejected && !uploaded && !notificationSent;

  return (
    <Box ref={ref} onMouseEnter={enter} onMouseLeave={leave} sx={styles.cardWrapper}>
      {project.deleting && (
        <>
          <LinearProgress sx={styles.progress} />
          <Box sx={styles.loadingBackdrop} />
        </>
      )}
      <Card sx={styles.projectItem}>
        <RouterLink to={projectUrl} title="View Project">
          <CardActionArea>
            <CardMedia
              sx={styles.media}
              image={thumbnail ? ProjectPreview : project.thumbnail}
            >
              <Box className="backdrop" sx={{ ...styles.backdrop, ...(showDeleteConfirmation && styles.standardBackdrop), ...(isInProgress && styles.successBackdrop) }} />
              <Box sx={styles.projectItemIcons} className="projectItemIcons">
                {hovered && !cannotDelete && !imageryRequested && !rasterRequested && (
                  <IconButton
                    sx={{ ...(styles.deleteIcon), ...(showDeleteConfirmation && styles.activeIcon) }}
                    title="Delete Project"
                    onClick={deleteProjectClick}
                  >
                    <DeleteIcon />
                  </IconButton>
                ) }
                 {!opsTrainer && <Avatar sx={styles.avatar} title={project.creator}>{initials.join('').toLowerCase()}</Avatar>}
              </Box>
              {showDeleteConfirmation && (
                <Box sx={styles.cardControls}>
                  <Box sx={styles.cardTextWrapper}>
                    <Typography variant="body1" sx={styles.cardText}>
                      <Box fontWeight="fontWeightBold">
                        {'You\'re deleting this project.'}
                      </Box>
                    </Typography>
                    <Typography variant="body1" sx={styles.cardText}>
                      {'This action can\'t be undone'}
                    </Typography>
                  </Box>
                  <Button sx={styles.projectItemButton} title="Delete Project" onClick={deleteConfirmClick}>Delete</Button>
                  <Button sx={styles.projectItemButton} title="Cancel" onClick={deleteCancelClick}>Cancel</Button>
                </Box>
              )}
              {!showDeleteConfirmation && isInProgress && (
                <Box sx={styles.progressPanel}>
                  <Typography variant="h6" sx={styles.labelText}>{ActiveLabels[activeStep]}</Typography>
                  <Box sx={styles.progressStepperRoot}>
                    <Stepper sx={styles.stepper} activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
                      {steps.map((label, index) => (
                        <Step key={ActiveLabels[index]}>
                          <StepLabel StepIconComponent={ColorlibStepIcon}>
                            {label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Box>
                </Box>
              )}
              {!showDeleteConfirmation && !isInProgress && <Typography variant="h4" sx={styles.label} className="label">View Project</Typography>}
            </CardMedia>
          </CardActionArea>
          <CardActions sx={styles.cardActions}>
            <Box sx={{ ...(styles.bottomBackdrop), ...(isInProgress && styles.bottomSuccessBackdrop) }} />
            <Box sx={styles.nameText}>
              <Typography variant="h5">{ (opsTrainer ? project?.training?.nameEditable : project.name) || 'N/A'}</Typography>
            </Box>
            <Typography sx={styles.dateText} variant="body2">
              <Box sx={styles.details}>
                {project.updatedAt && moment(project.updatedAt).format('LL')}
                {orderEstimate && (
                  <Box sx={styles.detailsWrapper}>
                    <Brightness1Icon style={styles.notificationIcon} fontSize="inherit" htmlColor="#ff9900" />
                    <Typography sx={styles.dateText} variant="subtitle2">
                      Estimate
                    </Typography>
                  </Box>
                )}
                {(!orderEstimate && orderStatus === 7) && (
                  <Box sx={styles.detailsWrapper}>
                    <Brightness1Icon sx={styles.notificationIcon} fontSize="inherit" htmlColor="#66ff66" />
                    <Typography sx={styles.dateText} variant="subtitle2">
                      Completed
                    </Typography>
                  </Box>
                )}
              </Box>
            </Typography>
          </CardActions>
        </RouterLink>
      </Card>
    </Box>
  );
};

export default ProjectListItem;
