// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import {
  Button,
  Typography,
  Fade,
  CircularProgress,
  Dialog,
} from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { Form } from 'react-final-form';
import { useAppDispatch } from 'Hooks';
import { TextField, Validators } from 'Components/forms';
import stylesDef from './styles';
import { AddOrgTeamThunk } from 'Features/account/accountThunk';

interface ComponentProps {
  setSnackMessage: (message: string) => void;
  setShowSnackbar: (show: boolean) => void;
}

export const AddTeamForm: React.FC<ComponentProps> = ({ setSnackMessage, setShowSnackbar }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const createTeam = async (teamName: string) => {
    window?.pendo?.track('Team Created', { team_name: teamName });

    const result = await dispatch(AddOrgTeamThunk(teamName));
    if (result.success) {
      setSnackMessage('Team Created');
      setShowSnackbar(true);
      handleClose();
    }
  };

  const onFormSubmit = (value: any) => createTeam(value?.name);
  return (
    <Box>
      <Box sx={styles.title}>
        <Box>
          <Typography sx={styles.headerText} variant="h2">Manage Teams</Typography>
        </Box>
        <Button
          color="primary"
          variant="contained"
          sx={styles.addTeamButton}
          onClick={handleClickOpen}
        >
          <Typography variant="h4" sx={styles.addTeamText}>ADD NEW TEAM</Typography>
        </Button>
      </Box>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <Form
          onSubmit={onFormSubmit}
          render={({ submitting, hasSubmitErrors, handleSubmit }) => (
            <form
              style={styles.form}
              onSubmit={handleSubmit}
            >
              <Typography variant="h2" sx={styles.heading}>Add Team</Typography>
              <Box sx={styles.formRow}>
                <TextField
                  label="Name"
                  name="name"
                  validate={Validators.required}
                  variant="outlined"
                  inputProps={{
                    classes: {
                      notchedOutline: styles.outline,
                      input: styles.input,
                    },
                  }}
                  inputLabelProps={{
                    classes: {
                      outlined: styles.inputLabel,
                      shrink: styles.inputLabelShrink,
                    },
                  }}
                />
              </Box>
              <Button
                variant="contained"
                color="primary"
                sx={styles.submitButton}
                type="submit"
                disabled={hasSubmitErrors || submitting}
              >
                <Fade in={submitting}>
                  <CircularProgress size={24} sx={styles.buttonProgress} />
                </Fade>
                <Fade in={!submitting}>
                  <span>Add Team</span>
                </Fade>
              </Button>
            </form>
          )}
        />
      </Dialog>
    </Box>
  );
};

export default AddTeamForm;
