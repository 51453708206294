// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import { Collapse, IconButton, Typography, ListItem, ListItemButton } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import HelpIcon from '@mui/icons-material/Help';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import moment from 'moment';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ErrorIcon from '@mui/icons-material/Error';
import ClearIcon from '@mui/icons-material/Clear';
import SVG from 'react-inlinesvg';
import nearmapLogo from 'Assets/nearmap-logo.png';
import uploadKmlIcon from 'Assets/upload-kml-icon.svg';
import downloadIcon from 'Assets/download-icon.png';
import UploadFilesDialog from 'Components/UploadFilesDialog';
import ConfirmDialog from 'Components/ConfirmDialog';
import WarningDialog from 'Components/WarningDialog';
import ErrorDialog from 'Components/ErrorDialog';
import { useAppDispatch, useAppSelector } from 'Hooks';
import Tooltip from 'Components/Tooltip';
import { RootState } from 'Store';
import { selectCRSInfo, selectProjectFiles, getProjectEPSGCode } from 'Features/project/projectSelectors';
import { getOrders } from 'Features/order/orderSelectors';
import { GetRasterTilesThunk } from 'Features/map/mapCommonThunk';
import { SetDelayInRasterTileLoadAcknowledgedAction, SetLargeTifAcknowledgedAction } from 'Features/map/mapCommonActions';
import { SetWarningAcceptedAction } from 'Features/project/projectActions';
import { DeleteTifFileThunk, DeleteLasFileThunk, GetEpsgProjectionsThunk, DownloadTifThunk } from 'Features/project/projectThunk';
import ImageryRequestInfo from './ImageryRequestInfo';
import CancelImageryRequestDialog from './CancelImageryRequestDialog';
import RasterizationRequestInfo from './RasterizationRequestInfo';
import stylesDef from './styles';

const DataSetsPanel = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.project.project);
  const { imageryRequest, rasterRequest, isDemoProject } = project || {};
  const fileStatusJson = useAppSelector((state) => state.project.fileStatusJson);

  const tifFiles = project?.tifFiles?.map((tifFile) => ({ name: tifFile.filename.split('/').pop(), _id: tifFile._id, epsg: tifFile.epsg, adminUpload: tifFile.adminUpload, autonomous: tifFile.autonomous })) || [];
  const lasFiles = project?.lasFiles?.filter((lasFile) => lasFile.lasType === 'unclassified').map((lasFile) => ({ name: lasFile.filename.split('/').pop(), _id: lasFile._id, epsg: lasFile.epsg, adminUpload: lasFile.adminUpload })) || [];

  const epsg = useAppSelector((state) => getProjectEPSGCode(state));
  const epsgCode = epsg?.tifEpsg || epsg?.lasEpsg;
  const geogCS = useAppSelector((state: RootState) => selectCRSInfo(state)?.geogCS);
  const projCS = useAppSelector((state: RootState) => selectCRSInfo(state)?.projCS);

  // add/remove warning dialog depending on whether project files have warnings
  let warnings = false;
  const files = useAppSelector((state) => selectProjectFiles(state));
  const projectOrders = useAppSelector((state) => getOrders(state).projectOrders);
  files.forEach((file) => {
    if (fileStatusJson && fileStatusJson[file.name] && fileStatusJson[file.name].status === 'WARNING') {
      warnings = warnings || true;
    }
  });
  const warningsExist = warnings;
  const userReUpFiles = (projectOrders.length === 1 && projectOrders[0].orderStatus === 0) || projectOrders.some((order) => order.orderStatus === 3);

  const projectId = useAppSelector((state) => state.project.projectId);
  const warningAccepted = useAppSelector((state) => state.project?.warningAccepted?.includes(projectId));
  const newLargeTif = useAppSelector((state) => state.map.common.newLargeTif);
  const largeTifAcknowledged = useAppSelector((state) => state.map.common.largeTifAcknowledged);
  const rasterTileJson = useAppSelector((state) => state.map.common.rasterTileJson);
  const allRasterTilesLoaded = useAppSelector((state) => state.map.common.allRasterTilesLoaded);
  const lowResCreatedAt = useAppSelector((state) => state.map.common.lowResCreatedAt);
  const delayInRasterTileLoad = useAppSelector((state) => state.map.common.delayInRasterTileLoad);
  const { resources } = useAppSelector(
    (state) => state.auth,
  );
  const delayInRasterTileLoadAcknowledged = useAppSelector((state) => state.map.common.delayInRasterTileLoadAcknowledged);

  const loadingHighResTiles = !!rasterTileJson && !!lowResCreatedAt && !allRasterTilesLoaded;

  const [dataSetsExpanded, setDataSetsExpanded] = useState(true);
  const [showConfirmDialogForTif, setShowConfirmDialogForTif] = useState(false);
  const [showConfirmDialogForLas, setShowConfirmDialogForLas] = useState(false);
  const [showCancelImageryRequest, setShowCancelImageryRequest] = useState(false);
  const [showDelayWarningDialog, setShowDelayWarningDialog] = useState(false);
  const [tifFileId, setTifFileId] = useState('');
  const [lasFileId, setLasFileId] = useState('');
  const opsTrainer = 'opsTrainer' in resources;

  const fileStatuses = fileStatusJson;
  const fileStatusesValid = fileStatuses && Object.keys(fileStatuses).length > 0;

  const toggleDataSetsExpanded = () => {
    setDataSetsExpanded(!dataSetsExpanded);
  };

  const deleteTifClick = (_id: string) => {
    setShowConfirmDialogForTif(true);
    setTifFileId(_id);
  };

  const onOk = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDialogForTif(false);
    dispatch(DeleteTifFileThunk(tifFileId));
    e.stopPropagation();
  };

  const deleteLasClick = (_id: string) => {
    setShowConfirmDialogForLas(true);
    setLasFileId(_id);
  };

  const onOkDeleteLas = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDialogForLas(false);
    dispatch(DeleteLasFileThunk(lasFileId));
    e.stopPropagation();
  };

  const onOkWarning = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    dispatch(SetWarningAcceptedAction());
    e.stopPropagation();
  };

  const onIgnoreDelayWarning = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowDelayWarningDialog(false);
    if (newLargeTif && !largeTifAcknowledged) {
      dispatch(SetLargeTifAcknowledgedAction(true));
      dispatch(GetRasterTilesThunk());
    } else {
      dispatch(SetDelayInRasterTileLoadAcknowledgedAction(true));
    }
    e.stopPropagation();
  };

  const onUploadDifferentFilesClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowDelayWarningDialog(false);
    if (newLargeTif && !largeTifAcknowledged) {
      dispatch(SetLargeTifAcknowledgedAction(true));
      dispatch(GetRasterTilesThunk());
    } else {
      dispatch(SetDelayInRasterTileLoadAcknowledgedAction(true));
    }
    setUploadFilesFormVisible(true);
    e.stopPropagation();
  };

  const onCancel = () => {
    setShowConfirmDialogForTif(false);
    setShowConfirmDialogForLas(false);
  };

  const [uploadFilesFormVisible, setUploadFilesFormVisible] = useState(false);
  const showUploadFilesForm = () => setUploadFilesFormVisible(true);

  const onLoad = async () => {
    await dispatch(GetEpsgProjectionsThunk(epsgCode));
  };

  const handleDownloadClick = async (file: { name: string, _id: string, epsg: number }) => {
    await dispatch(DownloadTifThunk(file.name));
  };

  useEffect(() => {
    onLoad();
  }, [epsgCode]);

  useEffect(() => {
    if ((loadingHighResTiles && (delayInRasterTileLoad && !delayInRasterTileLoadAcknowledged)) || (newLargeTif && !largeTifAcknowledged)) {
      setShowDelayWarningDialog(true);
    } else {
      setShowDelayWarningDialog(false);
    }
  }, [newLargeTif, largeTifAcknowledged, delayInRasterTileLoad, delayInRasterTileLoadAcknowledged, loadingHighResTiles]);

  return (
    <>
      <ListItemButton sx={styles.expansionPanelHeader}>
        <Box sx={styles.dataSetInfo}>
          <Typography variant="h5">DATA SETS</Typography>
          {(imageryRequest?.uploaded) && (
            <Tooltip
              placement="top-end"
              title={(
                <>
                  <Typography sx={styles.toolTipTitle}>CRS:</Typography>
                  <Typography sx={styles.toolTipText}>{`${projCS} - ${geogCS}`}</Typography>
                  <Box sx={styles.captureDateInfo}>
                    <img style={styles.toolTipLogo} src={nearmapLogo} alt="nearmap" />
                    <Box sx={styles.captureDate}>
                      <Typography sx={styles.toolTipTitle}>Capture Date:</Typography>
                      <Typography sx={styles.toolTipText}>{moment.utc(imageryRequest?.captured).format('MMMM D, YYYY')}</Typography>
                    </Box>
                  </Box>
                </>
              )}
            >
              <HelpIcon sx={styles.helpIcon} />
            </Tooltip>
          )}
        </Box>
        <Box>
          <IconButton aria-label="Upload" sx={styles.button} onClick={showUploadFilesForm}>
            <SVG src={uploadKmlIcon} css={styles.uploadIcon} title="Upload files" />
          </IconButton>
          <IconButton aria-label={dataSetsExpanded ? 'Collapse' : 'Expand'} sx={styles.button} onClick={toggleDataSetsExpanded}>
            {dataSetsExpanded
              ? <KeyboardArrowDownIcon fontSize="small" />
              : <KeyboardArrowRightIcon fontSize="small" />}
          </IconButton>
        </Box>
      </ListItemButton>
      {!imageryRequest?.rejected && !rasterRequest?.rejected && <ImageryRequestInfo setShowCancelDialog={setShowCancelImageryRequest} />}
      <Collapse in={dataSetsExpanded}>
        {/* Check if TIF file(s) & LAS file is available in the project and choose display accordingly */}
        <Box sx={styles.wrapper}>
          <Box sx={styles.dataSetRow}>
            {tifFiles?.map((file) => (
              <Box key={file._id} sx={styles.filesList}>
                <Box sx={styles.fileInfo}>
                  <Box sx={styles.fileName}>
                    <Tooltip title={file.name} placement="top" sx={{ tooltip: styles.toolTipFiles }}>
                      <Typography noWrap variant="body2">{file.name}</Typography>
                    </Tooltip>
                    {(file.adminUpload || file.autonomous) && (
                      <Tooltip title="Uploaded by AirWorks." placement="top" sx={{ tooltip: styles.toolTipFiles }}>
                        <SettingsOutlinedIcon sx={styles.settingsIcon} />
                      </Tooltip>
                    )}
                  </Box>
                  {((!imageryRequest || (!file.adminUpload && !file.autonomous)) && !isDemoProject && !opsTrainer ) && (
                    <IconButton
                      aria-label="Download .tif"
                      sx={styles.downloadFileButton}
                      onClick={() => handleDownloadClick(file)}
                    >
                      <img src={downloadIcon} alt="Download .tif" />
                    </IconButton>
                  )}
                  {(userReUpFiles || (fileStatusesValid && fileStatuses[file.name] && (fileStatuses[file.name].status === 'ERROR'))) && (
                    <IconButton sx={styles.cancelButton} onClick={() => { deleteTifClick(file._id); }}>
                      <ClearIcon sx={styles.cancelButtonIcon} />
                    </IconButton>
                  )}
                </Box>
                <Box sx={styles.fileEpsg}>
                  <Typography variant="body2">{` - [ EPSG: ${file.epsg === -1 ? 'invalid' : file.epsg} ]`}</Typography>
                </Box>
                {(fileStatusesValid && fileStatuses[file.name] && (fileStatuses[file.name].status === 'ERROR' || fileStatuses[file.name].status === 'WARNING')) && (
                  <Tooltip title={fileStatuses[file.name].messages.join(', ')} placement="top">
                    <ErrorIcon sx={{ ...styles.errorIcon, ...(fileStatuses[file.name].status === 'WARNING' && styles.warningIcon) }} />
                  </Tooltip>
                )}
                {loadingHighResTiles && <ErrorIcon sx={styles.loadingIcon} />}
                <ConfirmDialog
                  showDialog={showConfirmDialogForTif}
                  onOk={onOk}
                  onCancel={onCancel}
                  contentText="Selected TIF file will be removed"
                />
              </Box>
            ))}
            {lasFiles.length > 0 && lasFiles.map((file) => (
              <Box key={file._id} sx={styles.filesList}>
                <Box sx={styles.fileInfo}>
                  <Box sx={styles.fileName}>
                    <Tooltip title={file.name} placement="top" sx={{ tooltip: styles.toolTipFiles }}>
                      <Typography noWrap variant="body2">{file.name}</Typography>
                    </Tooltip>
                    {file.adminUpload && (
                      <Tooltip title="Uploaded by AirWorks." placement="top" sx={{ tooltip: styles.toolTipFiles }}>
                        <SettingsOutlinedIcon sx={styles.settingsIcon} />
                      </Tooltip>
                    )}
                  </Box>
                  {((!imageryRequest || !file.adminUpload) && !isDemoProject && !opsTrainer) && (
                    <IconButton
                      aria-label="Download pointcloud"
                      sx={styles.downloadFileButton}
                      onClick={() => handleDownloadClick(file)}
                    >
                      <img src={downloadIcon} alt="Download pointcloud" />
                    </IconButton>
                  )}
                  {(userReUpFiles || (fileStatusesValid && fileStatuses[file.name] && (fileStatuses[file.name].status === 'ERROR'))) && (
                    <IconButton sx={styles.cancelButton} onClick={() => { deleteLasClick(file._id); }}>
                      <ClearIcon sx={styles.cancelButtonIcon} />
                    </IconButton>
                  )}
                </Box>
                <Box sx={styles.fileEpsg}>
                  <Typography variant="body2">{` - [ EPSG: ${file.epsg === -1 ? 'invalid' : file.epsg} ]`}</Typography>
                </Box>
                {(fileStatusesValid && fileStatuses[file.name] && (fileStatuses[file.name].status === 'ERROR' || fileStatuses[file.name].status === 'WARNING')) && (
                  <Tooltip title={fileStatuses[file.name].messages} placement="top">
                    <ErrorIcon sx={{ ...styles.errorIcon, ...(fileStatuses[file.name].status === 'WARNING' && styles.warningIcon) }} />
                  </Tooltip>
                )}
                <ConfirmDialog
                  showDialog={showConfirmDialogForLas}
                  onOk={onOkDeleteLas}
                  onCancel={onCancel}
                  contentText="Selected LAS file will be removed"
                />
              </Box>
            ))}
            <WarningDialog
              showDialog={warningsExist && !warningAccepted}
              onOk={onOkWarning}
              onCancel={onOkWarning}
            />
          </Box>
        </Box>
      </Collapse>
      <RasterizationRequestInfo lasFiles={lasFiles} tifFiles={tifFiles} />
      <CancelImageryRequestDialog showDialog={showCancelImageryRequest} setShowDialog={setShowCancelImageryRequest} />
      <ErrorDialog
        showDialog={showDelayWarningDialog}
        onAccept={onUploadDifferentFilesClick}
        onCancel={onIgnoreDelayWarning}
        titleText="Heads Up!"
        error
        acceptText="Upload files"
        cancelText="Ignore"
      >
        <Typography variant="body1">
          This file might take a while to fully render, if you’re in a hurry, we recommend
          {' '}
          <a
            style={styles.link}
            href="https://awkb.scrollhelp.site/airworksknowledgebase/how-to-tile-larger-image-files-into-smaller-files"
            onClick={() => { window?.pendo?.track('Tiling KB article clicked', { projectId }); }}
            target="_blank"
            rel="noreferrer"
          >
            tiling your site
          </a>
          {' '}
          and uploading those smaller tiles instead.
        </Typography>
      </ErrorDialog>
      <UploadFilesDialog showDialog={uploadFilesFormVisible} setShowDialog={setUploadFilesFormVisible} />
    </>
  );
};

export default DataSetsPanel;
