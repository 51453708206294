// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { API_URL } from 'Config';
import { postJson, patchJson, getJson } from 'Utils/http';
import { clearLocalStorage, getHTML, setHTML, getLoginFailedAttempts, getPlanDetails, getToken, getUser, setToken, setUser } from 'Utils/localStorageService';
import { Dispatch } from 'redux';
import history from 'Utils/history';
import jwtDecode from 'jwt-decode';
import { clearSessionStorage } from 'Utils/sessionStorageService';
import { ADMIN_ROUTE, EXPIRED_TOKEN_ROUTE, FORGOT_PASSWORD_ROUTE, PROJECTS_ROUTE, ROOT_ROUTE, SIGN_UP_ROUTE } from 'Utils/routes';
import { sendForgotPasswordLink } from './authSlice';
import { InitAuthFailedAction, InitAuthSuccessAction, LogoutAction, OktaAdminLoginStartAction, OktaAdminLoginSuccessAction, OnAcceptTnCAction } from './authActions';

export const InitAuthThunk = () => (dispatch: Dispatch) => {
  const currentLocation = history.location.pathname;
  let user;
  if (!_ADMIN_ &&
    currentLocation !== FORGOT_PASSWORD_ROUTE &&
    currentLocation !== SIGN_UP_ROUTE &&
    currentLocation.indexOf('/verifyToken') !== 0 &&
    currentLocation !== EXPIRED_TOKEN_ROUTE) {
    user = ReadFromStorage()(dispatch);
  }

  if (!user) return;

  if (currentLocation === ROOT_ROUTE) {
    history.push(_ADMIN_ ? ADMIN_ROUTE : PROJECTS_ROUTE);
  }
};

export const ReadFromStorage = () => (dispatch: Dispatch) => {
  const failedLoginAttempts = +getLoginFailedAttempts();
  try {
    const userData = getUser();
    const user = JSON.parse(userData);
    const html = getHTML();
    const token = getToken();
    const tokenExpires = jwtDecode<{ exp: number }>(token).exp;
    const { resources } = jwtDecode<{ resources: any}>(token);
    const planDetails = getPlanDetails();
    dispatch(InitAuthSuccessAction({ user, token, tokenExpires, html, resources, planDetails, failedLoginAttempts }));
    return user;
  } catch {
    clearLocalStorage();
    history.push('/');
    dispatch(InitAuthFailedAction(failedLoginAttempts));
    return false;
  }
};

export const OktaAdminLoginThunk = (tokens: any, pricingAccess?: boolean) => async (
  dispatch: Dispatch,
) => {
  const decodedToken = jwtDecode<{ exp: number, sub: string }>(tokens.accessToken);
  dispatch(OktaAdminLoginStartAction());
  const user = { email: decodedToken.sub, roles: ['admin'], pricingAccess };

  setUser(JSON.stringify(user));
  setToken(tokens.accessToken);

  dispatch(
    OktaAdminLoginSuccessAction({
      user,
      token: tokens.accessToken,
      tokenExpires: decodedToken.exp,
      resources: ['editorFunctions'],
    }),
  );
};

export const ForgotPasswordThunk = (data: { email: string, captchaToken?: string }) => async (dispatch: Dispatch) => {
  const url = `${API_URL}/forgotPassword`;
  const result = await postJson<{ message: string }>(url, data);
  dispatch(sendForgotPasswordLink(result.success));
  return result;
};

export const UpdateUserTnCDateThunk = () =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { user } = getState().auth;
    const url = `${API_URL}/updateUserTnCDate/${user._id}`;
    const { token } = getState().auth;

    const result = await patchJson<IUserResponse>(url, user, token);
    if (result.success) {
      dispatch(OnAcceptTnCAction(result.data));
      setHTML(null);
    }

    return result;
  };

export const VerifyTokenThunk = (token: string) => async () => {
  const url = `${API_URL}/verifyToken/${token}`;
  const result = await getJson<{ email: string, jwt: string }>(url, token);
  if (result.success) {
    setToken(result.data.jwt);
    return result.data.jwt;
  }
  history.push(EXPIRED_TOKEN_ROUTE);
  return null;
};

export const ResetPasswordThunk = (payload: { password: string }) => async () => {
  const url = `${API_URL}/passwordReset`;
  const token = getToken();
  const data = { token, password: payload.password };
  const result = await postJson<{ message: string }>(url, data);
  if (result.success) {
    history.push(ROOT_ROUTE);
    clearLocalStorage();
  } else {
    return { message: 'something went wrong' };
  }
  return null;
};

export const Logout = () => (dispatch: Dispatch) => {
  clearLocalStorage();
  clearSessionStorage();
  dispatch(LogoutAction());
  history.push('/');
  window.location.reload();
};
