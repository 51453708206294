// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Layer, Source } from 'react-map-gl';
import { getOrders } from 'Features/order/orderSelectors';
import { useAppSelector } from 'Hooks';

interface KmlLayerProps {
  fileName: string;
  data: GeoJSON.FeatureCollection;
  visible: boolean;
}

const KMLLayer: React.FC<KmlLayerProps> = ({ fileName, data, visible }) => {
  const sourceId = `${fileName}-kml_source`;
  const layerId = `${fileName}-kml_layer`;

  return (
    <Source
      id={sourceId}
      type="geojson"
      data={data}
    >
      <Layer
        type="fill"
        id={layerId}
        source={sourceId}
        layout={{
          visibility: visible ? 'visible' : 'none',
        }}
        paint={{
          'fill-color': 'rgba(1, 161, 221, 0.2)',
        }}
      />
    </Source>
  );
};

const MapKMLLayer = () => {
  const { projectOrders } = useAppSelector((state) => getOrders(state));
  const kmls = useAppSelector((state) => projectOrders.filter((o) => o.boundaryFile)
    .map((o) => {
      const kml = state.kml.present.kmls[o._id];
      return {
        id: kml?.id,
        visible: kml?.visible,
        geojson: kml?.featureCollection,
        name: o.boundaryFile.split('/').pop(),
      };
    }));

  return (
    <>
      {kmls.map((kml) => <KMLLayer key={kml.id} fileName={kml.name} data={kml.geojson} visible={kml.visible} />)}
    </>
  );
};

export default MapKMLLayer;
