// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { IconButton, Badge } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import downloadIcon from 'Assets/download-icon.png';
import uploadIcon from 'Assets/upload-kml-icon.png';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { ShowUploadSingleFileDialogAction, ShowOpsTrainingDialogAction } from 'Features/fileUpload/fileUploadActions';
import { SetOrderIdAction } from 'Features/order/orderActions';
import stylesDef from './styles';

interface ComponentProps {
  expand: boolean;
  checked:boolean;
  toggleExpand: () => void;
  clickDownload: (event: React.MouseEvent<HTMLElement>) => void;
  orderId: string;
  siteId: string;
}

export const CadDrawingsButtons: React.FC<ComponentProps> = ({ checked, siteId, expand, toggleExpand, orderId, clickDownload }) => {
  const styles = stylesDef();
  const dispatch = useAppDispatch();

  const downloadFileStatuses = useAppSelector((state) => state.order?.dxfFileStatus);
  const anyChangeInFileStatuses = useAppSelector((state) => state.order.anyChangeInFileStatuses);
  const resources = useAppSelector((state) => state.auth.resources);
  const opsTrainer = 'opsTrainer' in resources;
  const isDemoProject = useAppSelector((state) => state.project?.project.isDemoProject);

  const [areDownloadsReady, setAreDownloadsReady] = useState(false);
  const index = downloadFileStatuses?.findIndex((obj: { siteId: string; }) => obj.siteId === siteId);
  useEffect(() => {
    if (index < 0) {
      setAreDownloadsReady(false);
    }
    if (index >= 0) {
      const fileStatuses = downloadFileStatuses[index].fileReadyStatus;
      let areAllFilesReady = true;
      // eslint-disable-next-line no-restricted-syntax
      for (const i in fileStatuses) {
        if (fileStatuses[i] === 0) {
          areAllFilesReady = false;
          break;
        }
      }
      setAreDownloadsReady(areAllFilesReady);
    }
  }, [downloadFileStatuses, anyChangeInFileStatuses]);

  const DownloadButton = (
    <Badge color="primary" overlap="circular" variant="dot" invisible={!areDownloadsReady}>
      <IconButton
        aria-label="Download"
        sx={styles.button}
        onClick={clickDownload}
        title="Download files"
      >
        <img src={downloadIcon} alt="Download" />
      </IconButton>
    </Badge>
  );

  const uploadDxfClick = (e: React.ChangeEvent<{}>) => {
    dispatch(SetOrderIdAction(orderId));
    // eslint-disable-next-line no-unused-expressions
    opsTrainer ? dispatch(ShowOpsTrainingDialogAction()) : dispatch(ShowUploadSingleFileDialogAction());
    e.stopPropagation();
  };

  const uploadButton = (
    <IconButton
      aria-label="Upload"
      sx={styles.button}
      onClick={(e) => uploadDxfClick(e)}
      title="Upload .dxf"
    >
      <img src={uploadIcon} alt="Upload" />
    </IconButton>
  );

  const LayersExpandButton = (
    <IconButton sx={styles.button} onClick={toggleExpand}>
      {expand
        ? <KeyboardArrowDownIcon fontSize="small" />
        : <KeyboardArrowRightIcon fontSize="small" />}
    </IconButton>
  );

  return (
    <>
      {uploadButton}
      {(!isDemoProject && !opsTrainer) && DownloadButton}
      {checked && LayersExpandButton}
    </>
  );
};

export default CadDrawingsButtons;
