// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  subSectionTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  cardWrapper: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gridColumnGap: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    alignItems: 'center',
    width: '100%',
    textAlign: 'left',
    border: '1px solid transparent',
  },
  iconWrapper: {
    width: theme.spacing(4),
  },
});

export default styles;
