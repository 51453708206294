// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createSlice } from '@reduxjs/toolkit';
import { GetGlobalStatusFailedAction, GetGlobalStatusSuccessAction, GetOpsCreditCapacityFailedAction, GetOpsCreditCapacitySuccessAction, UpdateGlobalStatusFailureAction, UpdateGlobalStatusSuccessAction } from './globalStatusActions';

const initialState: IGlobalStatusState = {
  status: {
    allowExpeditedProcess: false,
    dailyCreditCommitment: null,
  },
  error: null,
};

const globalStatusSlice = createSlice({
  name: 'globalStatus',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetGlobalStatusSuccessAction, (state, action) => {
        state.status.allowExpeditedProcess = action.payload.allowExpeditedProcess;
        state.status.dailyCreditCommitment = action.payload.dailyCreditCommitment;
        state.error = null;
      })
      .addCase(GetGlobalStatusFailedAction, (state, action) => {
        state.error = action.payload;
      })
      .addCase(GetOpsCreditCapacitySuccessAction, (state, action) => {
        state.status.dailyCreditCommitment = action.payload;
        state.error = null;
      })
      .addCase(GetOpsCreditCapacityFailedAction, (state, action) => {
        state.error = action.payload;
      })
      .addCase(UpdateGlobalStatusSuccessAction, (state, action) => {
        state.status.allowExpeditedProcess = action.payload.allowExpeditedProcess;
        state.error = null;
      })
      .addCase(UpdateGlobalStatusFailureAction, (state, action) => {
        state.error = action.payload;
      });
  },
});

export const { reducer } = globalStatusSlice;

export default reducer;
