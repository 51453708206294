// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { Typography, ListItemButton, Tooltip, IconButton, Menu, MenuItem } from '@mui/material';
import { useTheme, Box } from '@mui/system';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from 'react-router-dom';
import { GetTemplateRoute, GetAdminCadTemplateRoute } from 'Utils/routes';
import history from 'Utils/history';
import ConfirmDialog from 'Components/ConfirmDialog';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { DeleteTemplate, DuplicateTemplate } from '../cadTemplateThunk';
import stylesDef from './styles';

interface ComponentProps {
  templateId: string;
  template: ICadTemplate;
  handleDownloadTemplate: (id: string) => void;
}

export const TemplateItems: React.FC<ComponentProps> = ({ templateId, template, handleDownloadTemplate }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [deleteTemplate, setDeleteTemplate] = useState('');
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [buttonHover, setButtonHover] = useState(false);
  const dispatch = useAppDispatch();

  const org = _ADMIN_ ? useAppSelector((state) => state.admin?.org) : useAppSelector((state) => state.account?.org);

  const clickOpenPopper = (e: any) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
    e.preventDefault();
  };

  const deleteTemplateClick = (templateId: string, e: any) => {
    setShowConfirmDialog(true);
    setDeleteTemplate(templateId);
    e.preventDefault();
  };

  const onOk = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDialog(false);
    dispatch(DeleteTemplate(deleteTemplate));
    history.push(GetTemplateRoute(org.defaultTemplate));
    e.stopPropagation();
  };

  const onCancel = () => {
    setShowConfirmDialog(false);
  };

  const listItemToRoute = _ADMIN_ ? GetAdminCadTemplateRoute(org._id, template._id) : GetTemplateRoute(template._id);
  return (
    <>
      <ListItemButton
        onMouseEnter={() => { setButtonHover(true); }}
        onMouseLeave={() => { setButtonHover(false); }}
        selected={template._id === templateId}
        component={Link}
        to={listItemToRoute}
        divider
        sx={styles.templateListItem}
      >
        <Tooltip title={template.name} arrow disableHoverListener={!buttonHover} placement="top">
          <Typography variant="h5" sx={styles.templateName}>{template.name}</Typography>
        </Tooltip>
        {(org?.defaultTemplate !== template._id && (template._id === templateId || buttonHover)) && (
          <IconButton disableRipple disableFocusRipple sx={styles.vertButton} onClick={clickOpenPopper}>
            <MoreVertIcon />
          </IconButton>
        )}
        {(org?.defaultTemplate === template._id) && <Typography sx={styles.defaultItem} variant="h6">Default</Typography>}
        <Menu
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          keepMounted
          open={isMenuOpen}
        >
          <MenuItem onClick={() => handleDownloadTemplate(template._id)}> Download </MenuItem>
          <MenuItem disabled={_ADMIN_} onClick={() => dispatch(DuplicateTemplate(template._id))}> Duplicate </MenuItem>
          {!template.airworksTemplate && (
            <MenuItem
              disabled={_ADMIN_}
              onClick={(e) => {
                setAnchorEl(null);
                deleteTemplateClick(template._id, e);
              }}
            >
              {' '}
              Delete
            </MenuItem>
          )}
        </Menu>
      </ListItemButton>
      <ConfirmDialog
        showDialog={showConfirmDialog}
        onOk={onOk}
        onCancel={onCancel}
        contentText="Selected template will be removed"
      />
    </>
  );
};

export default TemplateItems;
