// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  mapColumn: {
    top: theme.spacing(10),
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
  },
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight: 400,
    wordBreak: 'break-word',
    fontSize: '22px',
    lineHeight: '28px',
    paddingRight: '20px',
  },
  map: {
    height: '220px',
    width: '100%',
  },
  paddingBottom16: {
    paddingBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  topoTitle: {
    // marginTop: theme.spacing(2),
    paddingBottom: '10px',
  },
  crs: {
    fontWeight: 300,
  },
  projectSizeInfo: {
    color: '#01A1DD',
    fontWeight: 600,
  },
  projectInfo: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    fontWeight: 700,
    fontSize: '20px',
  },
  infoTextBox: {
    marginTop: theme.spacing(2),
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
    background: '#FFF4E6',
    padding: theme.spacing(2),
    columnGap: theme.spacing(1),
  },
  icon: {
    color: '#FAA738',
    width: '20px',
    height: '20px',
  },
  infoText: {
    fontWeight: 400,
  },
});

export default styles;
