// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  dialog: {
    minWidth: '600px',
    margin: 'auto',
  },
  dialogBox: {
    padding: theme.spacing(3),
  },
  dialogActions: {
    padding: '8px 0',
  },
  dialogText: {
    fontSize: '20px',
    marginBottom: theme.spacing(2),
  },
  layerList: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-12px',
    marginLeft: '-12px',
    color: '#fff',
  },
});

export default styles;
