// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createSlice } from '@reduxjs/toolkit';

import { InitializeTrainingProjectStartAction, InitializeTrainingProjectFailureAction } from './opsActions';

const initialState: IOpsTrainingState = {
  errorMessage: null,
};

const opsSlice = createSlice({
  name: 'opsTraining',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(InitializeTrainingProjectStartAction, (state) => {
        state.errorMessage = null;
      })
      .addCase(InitializeTrainingProjectFailureAction, (state, action) => {
        state.errorMessage = action.payload;
      });
  },
});
export const { reducer } = opsSlice;

export default reducer;
