// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import HelpIcon from '@mui/icons-material/Help';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { ToggleCadDrawingsThunk } from 'Features/map/mapCommonThunk';
import { ChangeVersionAction } from 'Features/map/mapCommonActions';
import { WideLightTooltip } from '../../../../../common/components/Tooltip';
import stylesDef from './styles';

interface ComponentProps {
  order: IOrder;
  index: number;
  checked: boolean;
  orderDisplayed: boolean;
  toggleChecked: () => void;
}

export const CadDrawingsCheckbox: React.FC<ComponentProps> = ({ order, index, checked, orderDisplayed, toggleChecked }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const fileVersions = useAppSelector((state) => state.order.fileVersions);

  return (
    <FormControlLabel
      label={(
        <Box sx={styles.title}>
          <Typography variant="h4" sx={styles.sidebarHeader}>{`CAD ${index}`}</Typography>
          <WideLightTooltip
            placement="top"
            interactive
            title="All features extracted by AirWorks are listed here. We are working to ensure your downloaded .dxf file includes all layers listed on your client template. We’ll keep you posted!"
          >
            <HelpIcon sx={styles.icon} />
          </WideLightTooltip>
        </Box>
      )}
      control={(
        <Checkbox
          color="primary"
          onChange={() => {
            toggleChecked();
            dispatch(ToggleCadDrawingsThunk(order._id, fileVersions[order._id]));
            dispatch(ChangeVersionAction());
          }}
          checked={orderDisplayed && checked}
          sx={styles.checkbox}
          disabled={!orderDisplayed}
        />
      )}
    />
  );
};

export default CadDrawingsCheckbox;
