// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Paper, Grid } from '@mui/material';
import { Box, useTheme, ThemeProvider } from '@mui/system';
import { darkTheme } from 'Utils/themes';
import { useMatch } from 'react-router-dom';
import { ROOT_ROUTE, EXPIRED_TOKEN_ROUTE, SIGN_UP_ROUTE, SIGN_UP_SUCCESS } from 'Utils/routes';
import logo from 'Assets/logo.png';
import Login from './Login';
import ExpiredToken from './ExpiredToken';
import SignUp from './SignUp';
import stylesDef, { mediaStyles } from './styles';
import SignUpSuccess from './signUpSuccess';

const Auth = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const isRootMatch = useMatch(ROOT_ROUTE);
  const isExpiredTokenMatch = useMatch(EXPIRED_TOKEN_ROUTE);
  const isSignUpMatch = useMatch(SIGN_UP_ROUTE);
  const isSignUpSuccessMatch = useMatch(SIGN_UP_SUCCESS);

  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={styles.videoWrapper}>
        <video loop muted autoPlay css={mediaStyles.video}>
          <source src="https://content.airworks.io/sign-in_bg_video_11mb.mp4" type="video/mp4" />
        </video>
      </Box>
      <Box sx={styles.gridWrapper}>
        <img src={logo} alt="AirWorks logo" css={styles.logo} />
        <Grid sx={styles.grid} container justify="center" alignContent="center" alignItems="center">
          <Box sx={styles.stripeGradient}>
            <br />
          </Box>
          <Grid item md={7}>
            <Paper sx={styles.form} elevation={1} square>
              <Box sx={styles.displayChild}>
                { isRootMatch && <Login /> }
                { isExpiredTokenMatch && <ExpiredToken /> }
                { isSignUpMatch && <SignUp /> }
                { isSignUpSuccessMatch && <SignUpSuccess /> }
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default Auth;
