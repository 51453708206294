/* eslint-disable lines-between-class-members */
// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { API_URL } from 'Config';

const headers = new Headers({
  Accept: 'application/json',
});
headers.append('Content-Type', 'application/json');

export class Uploader {
  chunkSize: any;
  threadsQuantity: number;
  file: any;
  fileName: string;
  aborted: boolean;
  uploadingProcessedLAS: boolean;
  uploadedSize: number;
  progressCache: {};
  activeConnections: {};
  parts: any[];
  uploadedParts: any[];
  bucket: string;
  fileId: string;
  fileKey: string;
  fileUploadKey: string;
  status: string;
  onProgressFn: (arg: any) => void;
  onErrorFn: (arg: any) => void;
  constructor(options: { fileName: string; file: any; chunkSize?: any; threadsQuantity?: any; uploadingProcessedLAS?: boolean }) {
    // this must be bigger than or equal to 5MB,
    // otherwise AWS will respond with:
    // "Your proposed upload is smaller than the minimum allowed size"
    this.chunkSize = options?.chunkSize || 1024 * 1024 * 250;
    // number of parallel uploads
    this.threadsQuantity = Math.min(options?.threadsQuantity || 5, 15);
    this.file = options.file;
    this.fileName = options.fileName;
    this.uploadingProcessedLAS = options.uploadingProcessedLAS;
    this.aborted = false;
    this.uploadedSize = 0;
    this.progressCache = {};
    this.activeConnections = {};
    this.parts = [];
    this.uploadedParts = [];
    this.bucket = null;
    this.fileId = null;
    this.fileKey = null;
    this.status = 'pending';
    this.onProgressFn = () => {};
    this.onErrorFn = () => {};
  }

  // starting the multipart upload request
  async start(uploaderOptions: { fileName: string; file: Blob; }, fileUploadKey: string, token: string) {
    this.initialize(uploaderOptions, fileUploadKey, token);
  }

  async initialize(uploaderOptions: any, fileUploadKey: string, token: string) {
    try {
      if (!headers.has('Authorization')) {
        headers.append('Authorization', `Bearer ${token}`);
      }
      const data = { uploaderOptions, fileUploadKey };
      // initializing the multipart request
      const initializeReponse = await fetch(`${API_URL}/orders/initializeMultipartUpload`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers,
      });

      const awsFileDataOutput = await initializeReponse.json();

      this.fileId = awsFileDataOutput.fileId;
      this.fileKey = awsFileDataOutput.fileKey;
      this.bucket = awsFileDataOutput.bucket;
      // retrieving the pre-signed URLs
      const numberOfparts = Math.ceil(this.file.size / this.chunkSize);

      const AWSMultipartFileDataInput = {
        fileId: this.fileId,
        fileKey: this.fileKey,
        parts: numberOfparts,
        uploadingProcessedLAS: this.uploadingProcessedLAS,
      };

      const urlsResponse = await fetch(`${API_URL}/orders/getMultipartPreSignedUrls`, {
        method: 'POST',
        body: JSON.stringify(AWSMultipartFileDataInput),
        headers,
      });
      const jsonResult = await urlsResponse.json();
      const newParts = jsonResult.parts;
      this.parts.push(...newParts);

      this.sendNext();
    } catch (error) {
      await this.complete(error);
    }
  }

  sendNext() {
    const activeConnections = Object.keys(this.activeConnections).length;

    if (activeConnections >= this.threadsQuantity) {
      return;
    }

    if (!this.parts.length) {
      if (!activeConnections) {
        this.complete();
      }

      return;
    }

    const part = this.parts.pop();
    if (this.file && part) {
      const sentSize = (part.PartNumber - 1) * this.chunkSize;
      const chunk = this.file.slice(sentSize, sentSize + this.chunkSize);

      const sendChunkStarted = () => {
        this.sendNext();
      };

      this.sendChunk(chunk, part, sendChunkStarted)
        .then(() => {
          this.sendNext();
        })
        .catch((error) => {
          this.parts.push(part);

          this.complete(error);
        });
    }
  }

  // terminating the multipart upload request on success or failure
  async complete(err?: any) {
    if (err && !this.aborted) {
      this.onErrorFn(err);
      return;
    }

    if (err) {
      this.onErrorFn(err);
      return;
    }

    try {
      await this.sendCompleteRequest();
      this.status = 'completed';
    } catch (error) {
      this.onErrorFn(error);
    }
  }

  // finalizing the multipart upload request on success by calling
  // the finalization API
  async sendCompleteRequest() {
    try {
      if (this.fileId && this.fileKey) {
        const finalizationMultiPartInput = {
          fileId: this.fileId,
          fileKey: this.fileKey,
          parts: this.uploadedParts,
          uploadingProcessedLAS: this.uploadingProcessedLAS,
        };
        await fetch(`${API_URL}/orders/finalizeMultipartUpload`, {
          method: 'POST',
          body: JSON.stringify(finalizationMultiPartInput),
          headers,
        });
      }
    } catch (error) {
      this.onErrorFn(error);
    }
  }

  sendChunk(chunk: any, part: any, sendChunkStarted: () => void) {
    return new Promise<void>((resolve, reject) => {
      this.upload(chunk, part, sendChunkStarted)
        .then((status) => {
          if (status !== 200) {
            reject(new Error('Failed chunk upload'));
            return;
          }

          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // calculating the current progress of the multipart upload request
  handleProgress(part: string | number, event: { type: string; loaded: any; }) {
    if (this.file) {
      if (event.type === 'progress' || event.type === 'error' || event.type === 'abort') {
        this.progressCache[part] = event.loaded;
      }

      if (event.type === 'uploaded') {
        this.uploadedSize += this.progressCache[part] || 0;
        delete this.progressCache[part];
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        // eslint-disable-next-line no-return-assign, no-param-reassign
        .reduce((memo, id) => (memo += this.progressCache[id]), 0);

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size);

      const total = this.file.size;

      const percentage = Math.round((sent / total) * 100);
      this.onProgressFn({
        sent,
        total,
        percentage,
      });
    }
  }

  // uploading a part through its pre-signed URL
  upload(file: XMLHttpRequestBodyInit | Document, part: { PartNumber: number; signedUrl: string | URL; }, sendChunkStarted: () => void) {
    // uploading each part with its pre-signed URL
    return new Promise((resolve, reject) => {
      if (this.fileId && this.fileKey) {
        // - 1 because PartNumber is an index starting from 1 and not 0
        // eslint-disable-next-line no-multi-assign
        const xhr = (this.activeConnections[part.PartNumber - 1] = new XMLHttpRequest());

        sendChunkStarted();

        const progressListener = this.handleProgress.bind(this, part.PartNumber - 1);

        xhr.upload.addEventListener('progress', progressListener);

        xhr.addEventListener('error', progressListener);
        xhr.addEventListener('abort', progressListener);
        xhr.addEventListener('loadend', progressListener);

        xhr.open('PUT', part.signedUrl);

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            // retrieving the ETag parameter from the HTTP headers
            const ETag = xhr.getResponseHeader('ETag');

            if (ETag) {
              const uploadedPart = {
                PartNumber: part.PartNumber,
                // removing the " enclosing carachters from
                // the raw ETag
                ETag: ETag.replaceAll('"', ''),
              };

              this.uploadedParts.push(uploadedPart);

              resolve(xhr.status);
              delete this.activeConnections[part.PartNumber - 1];
            }
          }
        };

        xhr.onerror = (error) => {
          reject(error);
          delete this.activeConnections[part.PartNumber - 1];
        };

        xhr.onabort = () => {
          reject(new Error('Upload canceled by user'));
          delete this.activeConnections[part.PartNumber - 1];
        };

        xhr.send(file);
      }
    });
  }

  onProgress(onProgress: (arg: any) => void) {
    this.onProgressFn = onProgress;
    return this;
  }

  onError(onError: () => void) {
    this.onErrorFn = onError;
    return this;
  }

  abort() {
    Object.keys(this.activeConnections)
      .map(Number)
      .forEach((e) => {
        this.activeConnections[e].abort();
      });
    this.aborted = true;
  }
}

export default Uploader;
