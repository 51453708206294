// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

export const airworksLayers = [
  'A-OBSTRUCTION',
  'B-OVERHANG',
  'G-TOPO-MAJR',
  'G-TOPO-MINR',
  'R-CURB',
  'R-PAVEMARK-LINE',
  'R-PAVEMARK-POLY',
  'R-PAVEMARK-SYMBOL',
  'R-ROAD',
  'R-WALK',
  'U-CATCHBASIN',
  'U-MANHOLE',
  'V-VEGETATION',
  'W-WATER',
];

export const airworksLayerStandards = [
  'A-BOUNDARY',
  'A-OBSTRUCTION',
  'B-FEATURE',
  'B-FOOTPRINT',
  'B-OVERHANG',
  'C-CONC-PAD',
  // 'G-SPOT-ELEV',
  'G-TOPO-MAJR',
  'G-TOPO-MINR',
  'R-CURB',
  'R-CURB-BERM',
  'R-PAVEMARK',
  'R-PAVEMARK-LINE',
  'R-PAVEMARK-POLY',
  'R-PAVEMARK-SYMBOL',
  'R-RAILROAD',
  'R-ROAD',
  'R-ROAD-ASPH',
  'R-ROAD-CONC',
  'R-ROAD-PAVER',
  // 'R-ROAD-GRAVEL',
  // 'R-ROAD-UNPAVED',
  // 'R-ROAD-BRICK',
  // 'R-ROAD-STONE',
  // 'R-ROAD-WOOD',
  // 'R-ROAD-OTHER',
  'R-WALK',
  'R-WALK-ASPH',
  'R-WALK-CONC',
  'R-WALK-PAVER',
  // 'R-WALK-GRAVEL',
  // 'R-WALK-UNPAVED',
  // 'R-WALK-BRICK',
  // 'R-WALK-WOOD',
  // 'R-WALK-OTHER',
  // 'R-WALK-STONE',
  'S-DOCK',
  'S-FENCE',
  'S-GUARDRAIL',
  'S-RAILING',
  'S-WALL',
  'S-WALL-CONC',
  'S-WALL-STONE',
  'S-WALL-WOOD',
  // 'S-WALL-RETAINING',
  // 'S-BUILDING-FEATURE',
  'U-CATCHBASIN',
  // 'U-DRAINAGE-CHANNEL',
  // 'U-DRAINAGE-DITCH',
  // 'U-GUTTER',
  'U-HYDRANT',
  'U-MANHOLE',
  'U-OVERHEAD-WIRE',
  'U-POLE',
  'U-SOLAR',
  'U-TRANSMISSION-POLE',
  // 'U-TRANSMISSION-WIRE',
  'U-UTILITY-BOX',
  'U-UTILITY-ROOF',
  'U-UTILITY-STRUCTURE',
  'V-BUSH',
  'V-GRAVEL',
  'V-LANDSCAPE',
  'V-SHRUB',
  'V-STREE',
  'V-TREELINE',
  'V-VEGETATION',
  'W-WATER',
];
