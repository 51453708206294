// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  details: {
    marginBottom: theme.spacing(4),
    color: '#FFFFFF',
    marginTop: theme.spacing(4),
  },
  header: {
    marginTop: theme.spacing(4),
  },
  form: {
    marginTop: theme.spacing(2),
  },
  errorMessage: {
    minHeight: '3em',
    marginTop: theme.spacing(1),
    color: theme.palette.error.main,
  },
  buttonWrapper: {
    marginTop: theme.spacing(4),
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: 'auto auto',
  },
  button: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    height: '50px',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  forgotText: {
    color: '#01a1dd',
  },
  formRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.spacing(2),
  },
  dropdown: {
    paddingTop: theme.spacing(1),
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
    border: '1px solid #4f5a64',
    height: '56px',
    borderRadius: '4px',
  },
  innerDropdownText: {
    display: 'contents',
    color: '#ffffff',
  },
  menuItem: {
    justifyContent: 'space-between',
  },
  captcha: {
    margin: '16px 0',
  },
  signupForm: {
    display: 'none',
  },
  agreeTerms: {
    marginLeft: theme.spacing(3),
    color: '#FFFFFF',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    display: 'contents',
    marginLeft: '0px',
  },
});

export default styles;
