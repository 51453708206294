// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  sidebarHeader: {
    fontSize: 17,
  },
  checkbox: {
    padding: theme.spacing(0.5),
  },
  notice: {
    backgroundColor: 'black',
  },
});

export default styles;
