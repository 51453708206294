// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import { Collapse, Divider, ListItemButton, Select, MenuItem, InputBase, Typography, Tooltip } from '@mui/material';
import { useTheme } from '@mui/system';
import aiIcon from 'Assets/ai.png';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { GetVectorTilesThunk } from 'Features/map/mapCommonThunk';
import { ChangeVersionAction, SetLayerVisibleAction } from 'Features/map/mapCommonActions';
import { ResetDrawSourceAction, SetlayerSelectedAction } from 'Features/map/mapEditorActions';
import { SetFileVersionThunk } from 'Features/order/orderThunk';
import CadDrawingsCheckbox from './CadDrawingsCheckbox';
import CadDrawingsButtons from './CadDrawingsButtons';
import ProjectLayersList from './ProjectLayersList';
import DownloadPanel from './DownloadPanel';
import stylesDef from './styles';

interface ComponentProps {
  order: IOrder;
  index: number;
}

const CadDrawings: React.FC<ComponentProps> = ({ order, index }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();

  const layers = useAppSelector((state) => state.map.common.vectorTileJson &&
  state.map.common.vectorTileJson[order._id] &&
  state.map.common.vectorTileJson[order._id][state.order.fileVersions[order._id]]);

  const { resources } = useAppSelector((state) => state.auth);
  const projectAccessUS = 'projectAccessUS' in resources;
  const projectAccessIndia = 'projectAccessIndia' in resources;
  const opsTrainer = 'opsTrainer' in resources;

  const orderId = order?._id;

  const [expand, setExpand] = useState(true);
  const [checked, setChecked] = useState(true);

  const orderAllowed = (projectAccessUS || projectAccessIndia || order.orderStatus === 0 || order.orderStatus === 7);
  let orderDisplayed = orderAllowed;
  const toggleExpand = () => {
    setExpand(!expand);
  };

  const toggleChecked = () => {
    setChecked(!checked);
  };

  // Check if there are files in the cadFiles. If there's none present, display 'Original file;
  const files = order.cadFiles.map((cadFile) => {
    const { _id, s3Url, filename, expressAIUpload, expressAIEmailSent } = cadFile;
    const fileName = opsTrainer ? s3Url?.split('/').pop() : (filename?.split('/').pop() || 'Original file');
    return ({ fileName, siteId: _id, expressAI: (expressAIUpload && expressAIEmailSent), visible: (orderAllowed || (expressAIUpload && expressAIEmailSent)) });
  });

  const visibleFiles = files.filter((f) => f.visible);

  const defaultFile = visibleFiles[visibleFiles.length - 1];
  const [fileName, setFileName] = useState(defaultFile && defaultFile.fileName);
  const [siteId, setSiteId] = useState(defaultFile && defaultFile.siteId);
  const [expressAI, setExpressAI] = useState(defaultFile && defaultFile.expressAI);
  const shapeFiles = order.deliverableFiles?.filter((file) => /\.shp$/.test(file.filename));
  const demFiles = order.deliverableFiles?.filter((file) => /\.dem$/.test(file.filename));
  const dtmFiles = order.deliverableFiles?.filter((file) => /\.dtm$/.test(file.filename));
  const dwgFiles = order.deliverableFiles?.filter((file) => /\.dwg$/.test(file.filename));
  const dgnFiles = order.deliverableFiles?.filter((file) => /\.dgn$/.test(file.filename));

  const [mostRecentSurfaceFile] = useState(order.surfaceFiles?.reduce((prev, current) => ((+prev.createdAt > +current.createdAt) ? prev : current), {}));
  const [mostRecentGeoDbFile] = useState(order.geoDbFiles?.reduce((prev, current) => ((+prev.createdAt > +current.createdAt) ? prev : current), {}));
  const [mostRecentLasFile] = useState(order.outputLasFiles?.filter((file) => file.autonomous === false).reduce((prev, current) => ((+prev.createdAt > +current.createdAt) ? prev : current), {}).filename?.split('/').pop());
  const [mostRecentDeliverableFile] = useState(order.deliverableFiles?.reduce((prev, current) => ((+prev.createdAt > +current.createdAt) ? prev : current), {}));
  const [mostRecentShapeFile] = useState(shapeFiles?.reduce((prev, current) => ((+prev.createdAt > +current.createdAt) ? prev : current), {}));
  const [mostRecentDemFile] = useState(demFiles?.reduce((prev, current) => ((+prev.createdAt > +current.createdAt) ? prev : current), {}));
  const [mostRecentDtmFile] = useState(dtmFiles?.reduce((prev, current) => ((+prev.createdAt > +current.createdAt) ? prev : current), {}));
  const [mostRecentDwgFile] = useState(dwgFiles?.reduce((prev, current) => ((+prev.createdAt > +current.createdAt) ? prev : current), {}));
  const [mostRecentDgnFile] = useState(dgnFiles?.reduce((prev, current) => ((+prev.createdAt > +current.createdAt) ? prev : current), {}));

  if (files.some((f) => f.expressAI)) {
    orderDisplayed = true;
  }

  useEffect(() => {
    dispatch(SetFileVersionThunk(orderId, siteId));
  }, []);

  const onFileChange = async (e: any) => {
    const site = e.target.value;
    const file = files.find((o) => o.siteId === site);
    const { fileName, expressAI } = file;
    setFileName(fileName);
    setSiteId(site);
    setExpressAI(expressAI);
    dispatch(SetFileVersionThunk(orderId, site));
    dispatch(ResetDrawSourceAction());
    dispatch(SetlayerSelectedAction(null));
    await dispatch(GetVectorTilesThunk());
    dispatch(ChangeVersionAction());
    dispatch(SetLayerVisibleAction(null));
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const popperRef = React.createRef<HTMLDivElement>();
  const clickOpenPopper = () => {
    setAnchorEl(anchorEl ? null : popperRef.current);
  };

  return (
    <>
      <ListItemButton disableRipple sx={styles.expansionPanelHeader} ref={popperRef}>
        <CadDrawingsCheckbox index={index} order={order} {...{ checked, toggleChecked, orderDisplayed }} />
        {(expressAI && orderDisplayed) && (
          <img style={styles.expressIconImg} src={aiIcon} alt="Express AI Upload" />
        )}
        {orderDisplayed ? <CadDrawingsButtons {...{ expand, checked, toggleExpand, siteId, orderId, layers, clickDownload: clickOpenPopper }} />
          : <Typography variant="h6">Processing</Typography>}
      </ListItemButton>
      {orderDisplayed && (
        <>
          {checked && (
            <Tooltip title={fileName} placement="top" sx={{ '& .MuiTooltip-tooltip': styles.toolTipFiles }}>
              <Select
                input={<InputBase />}
                value={siteId}
                renderValue={() => <>{fileName}</>}
                onChange={(e) => onFileChange(e)}
                sx={styles.select}
              >
                {files.filter((f) => f.visible).map((file) => (
                  <MenuItem key={file.siteId} value={file.siteId}>{file.fileName}</MenuItem>
                ))}
              </Select>
            </Tooltip>
          )}
          {mostRecentLasFile && (
            <Typography sx={styles.fileName}>
              <span style={styles.dot}>&#183;</span>
              {mostRecentLasFile}
            </Typography>
          )}
          {mostRecentSurfaceFile?.filename && (
            <Typography sx={styles.fileName}>
              <span style={styles.dot}>&#183;</span>
              {mostRecentSurfaceFile?.filename}
            </Typography>
          )}
          {mostRecentShapeFile?.filename && (
            <Typography sx={styles.fileName}>
              <span style={styles.dot}>&#183;</span>
              {mostRecentShapeFile?.filename}
            </Typography>
          )}
          {mostRecentDemFile?.filename && (
            <Typography sx={styles.fileName}>
              <span style={styles.dot}>&#183;</span>
              {mostRecentDemFile?.filename}
            </Typography>
          )}
          {mostRecentDtmFile?.filename && (
            <Typography sx={styles.fileName}>
              <span style={styles.dot}>&#183;</span>
              {mostRecentDtmFile?.filename}
            </Typography>
          )}
          {mostRecentDwgFile?.filename && (
            <Typography sx={styles.fileName}>
              <span style={styles.dot}>&#183;</span>
              {mostRecentDwgFile?.filename}
            </Typography>
          )}
          {mostRecentDgnFile?.filename && (
            <Typography sx={styles.fileName}>
              <span style={styles.dot}>&#183;</span>
              {mostRecentDgnFile?.filename}
            </Typography>
          )}
          {mostRecentGeoDbFile?.filename && (
            <Typography sx={styles.fileName}>
              <span style={styles.dot}>&#183;</span>
              {mostRecentGeoDbFile?.filename}
            </Typography>
          )}
          {(layers && expand) && (
            <Collapse in={expand}>
              <ProjectLayersList order={order} layers={layers} siteId={siteId} expressAIInfo={expressAI} />
            </Collapse>
          )}
          <DownloadPanel {...{ anchorEl, open, order, fileName, siteId, setAnchorEl, surfaceFile: mostRecentSurfaceFile, geoDbFile: mostRecentGeoDbFile, lasFilename: mostRecentLasFile }} />
        </>
      )}
      <Divider />
    </>
  );
};

export default CadDrawings;
