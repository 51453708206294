// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createSlice } from '@reduxjs/toolkit';
import {
  GetCreditCardsStartAction,
  GetCreditCardsSuccessAction,
  GetCreditCardsFailureAction,
  CreatePaymentInstrumentSuccessAction,
  UpdateDefaultCardStartAction,
  UpdateDefaultCardSuccessAction,
  UpdateDefaultCardFailureAction,
  DeleteCreditCardStartAction,
  DeleteCreditCardSuccessAction,
  DeleteCreditCardFailureAction,
  UpdateCreditCardSuccessAction,
  GetSubscriptionStartAction,
  GetSubscriptionSuccessAction,
  GetSubscriptionFailureAction,
} from './subscriptionActions';

const initialState: ISubscriptionState = {
  plansLoading: false,
  plans: [],
  creditCardsLoading: false,
  creditCards: [],
  deleteCreditCard: false,
  defaultCreditCard: false,
  subscriptionsLoading: false,
  cadSubscription: null,
  automateSubscription: null,
  expressAISubscription: null,
  currentTab: 0,
  proratedChargesLoading: false,
  proratedCharges: { updatedCost: '', immediateCost: '' },
  promoCode: {
    valid: null,
    discountPercent: null,
    discountAmount: null,
    totalCostAfterDiscount: null,
    promotionCode: null,
    promotionCodeId: null,
  },
};

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetCreditCardsStartAction, (state) => {
        state.creditCardsLoading = true;
      })
      .addCase(GetCreditCardsSuccessAction, (state, action) => {
        state.creditCardsLoading = false;
        state.creditCards = action.payload;
      })
      .addCase(GetCreditCardsFailureAction, (state) => {
        state.creditCardsLoading = false;
      })
      .addCase(CreatePaymentInstrumentSuccessAction, (state, action) => {
        state.creditCards.push(action.payload);
      })
      .addCase(UpdateDefaultCardStartAction, (state) => {
        state.defaultCreditCard = true;
      })
      .addCase(UpdateDefaultCardSuccessAction, (state, action) => {
        state.defaultCreditCard = false;
        state.creditCards.forEach((c) => { c.defaultCard = (c.id === action.payload); });
      })
      .addCase(UpdateDefaultCardFailureAction, (state) => {
        state.defaultCreditCard = false;
      })
      .addCase(UpdateCreditCardSuccessAction, (state, action) => {
        const index = state.creditCards.findIndex((c) => c.id === action.payload.id);
        state.creditCards[index] = action.payload;
      })
      .addCase(DeleteCreditCardStartAction, (state) => {
        state.deleteCreditCard = true;
      })
      .addCase(DeleteCreditCardSuccessAction, (state, action) => {
        state.deleteCreditCard = false;
        state.creditCards = action.payload;
      })
      .addCase(DeleteCreditCardFailureAction, (state) => {
        state.deleteCreditCard = false;
      })
      .addCase(GetSubscriptionStartAction, (state) => {
        state.subscriptionsLoading = true;
      })
      .addCase(GetSubscriptionSuccessAction, (state, action) => {
        state.cadSubscription = action.payload.response.cad;
        state.automateSubscription = action.payload.response.automate;
        state.expressAISubscription = action.payload.response.expressAI;
        state.subscriptionsLoading = false;
      })
      .addCase(GetSubscriptionFailureAction, (state) => {
        state.subscriptionsLoading = false;
      });
  },
});

export const { reducer } = subscriptionSlice;

export default reducer;
