// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  toolbarText: {
    color: theme.palette.lightGrey,
    textTransform: 'none',
  },
  fontWeight: {
    fontWeight: 300,
  },
  cad: {
    fontWeight: 300,
    color: '#01A1DD',
  },
  menuList: {
    minWidth: '150px',
  },
});

export default styles;
