// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import DirectSelect from '@mapbox/mapbox-gl-draw/src/modes/direct_select';
import * as cfm from '@mapbox/mapbox-gl-draw/src/lib/constrain_feature_movement';
import moveFeatures from '@mapbox/mapbox-gl-draw/src/lib/move_features';
import { curveToCoords, regularizeMidpoints } from 'WktCurves';
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';
import customCreateSupplementaryPoints from './customCreateSupplementaryPoints';
import moveCurve from './moveCurve';

const Constants = require('@mapbox/mapbox-gl-draw/src/constants');
const CommonSelectors = require('@mapbox/mapbox-gl-draw/src/lib/common_selectors');

const {
  noTarget,
  isInactiveFeature,
} = require('@mapbox/mapbox-gl-draw/src/lib/common_selectors');

const constrainFeatureMovement = cfm.default;

const CustomDirectSelect = { ...DirectSelect };

function isCircle([type, arc, next] = []) {
  if (!type) {
    return false;
  }
  if (type === 'curvepolygon') {
    return arc && isCircle(arc) && !next;
  }

  return (
    type === 'compoundcurve' &&
    arc &&
    !next &&
    arc[0] === 'circularstring' &&
    arc.length === 4 &&
    arc[3][0] === arc[1][0] &&
    arc[3][1] === arc[1][1]
  );
}

// CustomDirectSelect.__onSetup = DirectSelect.onSetup;

CustomDirectSelect.onSetup = function (opts) {
  const { featureId } = opts;
  const { useMode } = opts;
  const feature = this.getFeature(featureId);

  if (!feature) {
    throw new Error('You must provide a featureId to enter direct_select mode');
  }

  if (feature.type === Constants.geojsonTypes.POINT) {
    throw new TypeError('direct_select mode doesn\'t handle point features');
  }

  // Introduce a new option in the direct select state to differentiate between polygons drawn in the editor tool
  // and polygons drawn for kml boundaries to allow creating midpoints for kml polygons
  const state = {
    featureId,
    feature,
    useMode,
    dragMoveLocation: opts.startPos || null,
    dragMoving: false,
    canDragMove: false,
    selectedCoordPaths: opts.coordPath ? [opts.coordPath] : [],
  };

  this.setSelectedCoordinates(this.pathsToCoordinates(featureId, state.selectedCoordPaths));
  this.setSelected(featureId);
  doubleClickZoom.disable(this);

  this.setActionableState({
    trash: true,
  });

  if (state.feature.properties.curve && state.feature.type === 'Polygon') {
    state.feature.setCoordinates([curveToCoords(state.feature.properties.curve)]);
  }
  if (state.feature.properties.curve) {
    state.feature.setCoordinates(curveToCoords(state.feature.properties.curve));
  }
  return state;
};

CustomDirectSelect.onClick = function (state, e) {
  // eslint-disable-line consistent-return
  if (noTarget(e)) {
    if (state.useMode === 'kmlDraw') {
      this.clickActiveFeature(state, e);
    } else {
      this.clickNoTarget(state, e);
    }
    return;
  }
  if (CommonSelectors.isActiveFeature(e)) {
    this.clickActiveFeature(state, e);
    return;
  }
  if (isInactiveFeature(e)) {
    this.clickInactive(state, e);
    return;
  }

  this.stopDragging(state);
  // Enter draw_poly_line mode when clicked on the first or the last vertex of the lineString
  if (
    state.feature.type === 'LineString' &&
    !isCircle(state.feature.properties.curve)
  ) {
    if (e.featureTarget.properties.first) {
      this.changeMode('draw_poly_line', {
        featureId: state.featureId,
        backwards: true,
        curving: true,
      });
    } else if (e.featureTarget.properties.last) {
      this.changeMode('draw_poly_line', {
        featureId: state.featureId,
        curving: true,
      });
    }
  }
};

CustomDirectSelect.__stopDragging = CustomDirectSelect.stopDragging;
CustomDirectSelect.stopDragging = function (state) {
  if (state.dragMoving && state.feature.properties.curve) {
    state.feature.properties.curve = regularizeMidpoints(
      state.feature.properties.curve,
    );
    state.feature.setCoordinates(curveToCoords(state.feature.properties.curve));
  }
  this.__stopDragging(state);
};

CustomDirectSelect.dragCurveVertex = function (state, e, delta) {
  function coordsToPointFeature(coords) {
    return {
      type: Constants.geojsonTypes.FEATURE,
      properties: {},
      geometry: {
        type: Constants.geojsonTypes.POINT,
        coordinates: coords,
      },
    };
  }

  function getCoordString(curve, elementId) {
    const element = curve[elementId + 1]; // +1 because element type
    return (element || []).slice(1);
  }

  // watch out for all the weird off-by-ones. each layer of a curve structure contains a ['type', ...], although the ids still count from
  // 0: ['compoundcurve', ['linestring', [4, 5], [5, 5]]] has two coords, 0.0 and 0.1
  function getCoordsToMove() {
    // if we do this carefully, we can adjust the array in-place
    const selectedCurveCoords = state.selectedCoordPaths.flatMap(
      (coordPath) => {
        // let [ringId, elementId, coordId] = coordPath.split(".").map(Number);
        const [coordId, elementId, ringId] = coordPath
          .split('.')
          .map(Number)
          .reverse();
        const curve =
          ringId === undefined
            ? state.feature.properties.curve
            : state.feature.properties.curve[ringId + 1];

        const coords = [getCoordString(curve, elementId)[coordId]];
        // if we move the first or last coordinate in a linestring/circularstring, we also need to move the neighbouring last/first
        if (coordId === 0 && elementId > 0) {
          // moving first coord: also move last of previous
          coords.push(...getCoordString(curve, elementId - 1).slice(-1));
        } else if (coordId === getCoordString(curve, elementId).length - 1) {
          // moving last coord: also move first of next
          coords.push(...getCoordString(curve, elementId + 1).slice(0, 1));
        }
        if (ringId !== undefined) {
          if (coordId === 0 && elementId === 0) {
            // moving first coordinate in polygon ring, also move last
            coords.push(...getCoordString(curve, curve.length - 2).slice(-1));
          } else if (
            coordId === curve[elementId + 1].length - 2 &&
            elementId === curve.length - 2
          ) {
            // moving last coordinate in polygon ring, also move first
            coords.push(...getCoordString(curve, 0).slice(0, 1));
          }
        }
        return coords;
      },
    );
    // deduplicate - otherwise risk of moving one point twice
    return [...new Set(selectedCurveCoords)];
  }
  const uniqueCoords = getCoordsToMove();
  const constrainedDelta = constrainFeatureMovement(
    uniqueCoords.map(coordsToPointFeature),
    delta,
  );
  uniqueCoords.forEach((curveCoord) => {
    curveCoord[0] += constrainedDelta.lng;
    curveCoord[1] += constrainedDelta.lat;
  });
  state.feature.setCoordinates(curveToCoords(state.feature.properties.curve));
};

CustomDirectSelect.__dragVertex = DirectSelect.dragVertex;
CustomDirectSelect.dragVertex = function (state, e, delta) {
  if (state.feature.properties.curve) {
    return this.dragCurveVertex(state, e, delta);
  }
  return this.__dragVertex(state, e, delta);
};

CustomDirectSelect.dragFeature = function (state, e, delta) {
  if (state.useMode === 'kmlDraw') {
    this.stopDragging(state);
  } else {
    moveFeatures(this.getSelected(), delta);
    if (state.feature.properties.curve) {
      state.feature.properties.curve = moveCurve(
        state.feature.properties.curve,
        delta,
      );
    }
    state.dragMoveLocation = e.lngLat;
  }
};

// we duplicate this whole function to replace the call to createSupplementaryPoints
CustomDirectSelect.toDisplayFeatures = function (state, geojson, push) {
  if (state.featureId === geojson.properties.id) {
    geojson.properties.active = Constants.activeStates.ACTIVE;
    push(geojson);
    customCreateSupplementaryPoints(geojson, {
      map: this.map,
      midpoints: true,
      useMode: state.useMode,
      selectedPaths: state.selectedCoordPaths,
    }).forEach(push);
  } else {
    geojson.properties.active = Constants.activeStates.INACTIVE;
    push(geojson);
  }
  this.fireActionable(state);
};

// existing AW version
CustomDirectSelect.onTrash = function (state) {
  // Uses number-aware sorting to make sure '9' < '10'. Comparison is reversed because we want them
  // in reverse order so that we can remove by index safely.

  let linestring;

  // if the feature is a polygon and the deletion results in only two vertices
  if (
    state.feature.type === 'Polygon' &&
    state.feature.coordinates.length > 0 &&
    state.feature.coordinates[0].length - state.selectedCoordPaths.length === 2
  ) {
    // prettier-ignore
    const ids = state.selectedCoordPaths.map((coord) => parseInt(coord.split('.')[1], 10));
    linestring = this.newFeature({
      id: state.featureId,
      type: Constants.geojsonTypes.FEATURE,
      properties: state.feature.properties,
      geometry: {
        type: Constants.geojsonTypes.LINE_STRING,
        coordinates: state.feature.coordinates[0].filter(
          (coor, idx) => ids.indexOf(idx) < 0,
        ),
      },
    });
  }

  // this block is from original direct_select onTrash code
  state.selectedCoordPaths
    .sort((a, b) => b.localeCompare(a, 'en', { numeric: true }))
    .forEach((id) => state.feature.removeCoordinate(id));
  this.fireUpdate();
  state.selectedCoordPaths = [];
  this.clearSelectedCoordinates();
  this.fireActionable(state);

  // delete the old polygon
  if (!linestring && state.feature.isValid() === false) {
    this.deleteFeature([state.featureId]);
  }

  // change the polygon to a linestring
  if (linestring) {
    this.map.fire(Constants.events.UPDATE, {
      features: [linestring.toGeoJSON()],
    });
  }

  // switch to simple_select
  this.changeMode(Constants.modes.SIMPLE_SELECT, {});
};

export default CustomDirectSelect;
