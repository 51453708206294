// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Button, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import SVG from 'react-inlinesvg';
import autodraft from 'Assets/autodraft.svg';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { SetCurrentPanel } from 'Features/map/mapEditorThunk';
import stylesDef from './styles';

export const Autodraft = () => {
  const styles = stylesDef();
  const dispatch = useAppDispatch();
  const { resources } = useAppSelector((state) => state.auth);
  const editorFunctions = 'editorFunctions' in resources;
  const { currentPanel } = useAppSelector((state) => state.map.editor.present);

  return (
    <Box sx={(currentPanel === 'autodraft') ? styles.panelCurrent : {}}>
      {editorFunctions && (
        <Tooltip title="Coming Soon">
          <Button
            style={{ opacity: 0.5 }}
            sx={styles.option}
            // onClick={() => {
            //   dispatch(SetCurrentPanel("autodraft"));
            // }}
          >
            <SVG src={autodraft} title="autodraft" css={styles.icon} />
          </Button>
        </Tooltip>
      )}
    </Box>
  );
};

export default Autodraft;
