// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, Card, CardContent, CardMedia, IconButton, Typography, Fade, CircularProgress, LinearProgress, Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SVG from 'react-inlinesvg';
import uploadIcon from 'Assets/upload-kml-icon.svg';
import Dropzone from 'Components/dropzone';
import Carousel from 'react-material-ui-carousel';
import info3DFE from 'Assets/info3DFE.png';
import noiseFix from 'Assets/noisefix.gif';
import spreadFix from 'Assets/spreadfix.gif';
import { Box, useTheme } from '@mui/system';
import { useAppSelector, useAppDispatch } from 'Hooks';
import { FileUploadStartThunk } from 'Features/fileUpload/fileUploadThunk';
import stylesDef from './styles';

interface Carousel3DFEProps {
  show3DFECarousel: boolean;
  isSubmitting: boolean;
  page: number;
  setShow3DFECarousel: (arg0: boolean) => void;
  setCarouselDismissed: (arg0: boolean) => void;
  setPage: (arg0: number) => void;
  finalSubmit: () => void;
}

export const Carousel3DFE = ({ show3DFECarousel, isSubmitting, setShow3DFECarousel, setCarouselDismissed, finalSubmit }: Carousel3DFEProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const { uploadFiles } = useAppSelector(
    (state) => state.fileUpload,
  );
  const file = uploadFiles[0];
  const { projectId } = useAppSelector(
    (state) => state.project,
  );
  const [firstPageEventSent, setFirstPageEventSent] = useState(false);
  const [lastPageEventSent, setLastPageEventSent] = useState(false);

  const onSubmit = () => {
    finalSubmit();
    if (!lastPageEventSent) window?.pendo?.track('3D Feature Elevation quality check completed', { projectId });
    setShow3DFECarousel(false);
  };

  const onDismiss = () => {
    setCarouselDismissed(true);
    setShow3DFECarousel(false);
  };

  const setPendoEvents = (slide: number) => {
    if (slide === 0 && !firstPageEventSent) {
      window?.pendo?.track('3D Feature Elevation quality check displayed', { projectId });
      setFirstPageEventSent(true);
    } else if (slide === 4 && !lastPageEventSent) {
      window?.pendo?.track('3D Feature Elevation quality check completed', { projectId });
      setLastPageEventSent(true);
    }
  };

  return (
    <Dialog maxWidth="xl" sx={styles.dialog} open={show3DFECarousel}>
      <Box sx={styles.dialogHeader}>
        <Typography variant="h1">You’re almost there!</Typography>
        <Box sx={styles.closeButtonWrapper}>
          <IconButton sx={styles.iconButton} color="inherit" onClick={onDismiss} aria-label="Close">
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Box>
      </Box>
      <Typography variant="h3" sx={styles.dialogSubTitle}>Please complete our 3D Feature Elevation project success checklist to ensure our deliverable will meet your needs.</Typography>
      <Carousel
        autoPlay={false}
        animation="slide"
        swipe={false}
        indicators={false}
        navButtonsAlwaysVisible
        cycleNavigation={false}
        height={500}
        navButtonsProps={{
          style: {
            backgroundColor: 'white',
            color: '#667085',
            border: '1px solid #EAECF0',
          },
        }}
        NextIcon={<ArrowForwardIcon />}
        PrevIcon={<ArrowBackIcon />}
        changeOnFirstRender
        onChange={(currentSlide) => setPendoEvents(currentSlide)}
      >
        <Card sx={styles.card}>
          <CardContent sx={styles.cardContent}>
            <Typography variant="h2" align="center" sx={styles.cardTitle}>Please review our quality commitments based on your point cloud density, noise and spread</Typography>
            <CardMedia
              sx={styles.cardMedia}
              component="img"
              src={info3DFE}
              alt="info3DFE"
            />
          </CardContent>
        </Card>
        <Card sx={styles.card}>
          <CardContent sx={styles.cardContent}>
            <Typography variant="h2" align="center" sx={styles.cardTitle}>Have you inspected your point cloud for Noise?</Typography>
            <CardMedia
              sx={styles.cardMedia}
              component="img"
              src={noiseFix}
              alt="noiseFix"
            />
          </CardContent>
        </Card>
        <Card sx={styles.card}>
          <CardContent sx={styles.cardContent}>
            <Typography variant="h2" align="center" sx={styles.cardTitle}>Have you inspected your point cloud for Spread?</Typography>
            <CardMedia
              sx={styles.cardMedia}
              component="img"
              src={spreadFix}
              alt="spreadFix"
            />
          </CardContent>
        </Card>
        <Card sx={styles.card}>
          <CardContent sx={styles.cardContent}>
            <Typography variant="h2" align="center" sx={styles.cardTitle}>Do you have a point cloud quality report you would like to share?</Typography>
            <Box sx={styles.dropzone}>
              <Dropzone
                acceptedExtensions={['.pdf']}
                onDropAccepted={async (acceptedFilesArray) => {
                  const f = acceptedFilesArray[0];
                  await dispatch(FileUploadStartThunk([f]));
                  window?.pendo?.track('Point Cloud Quality Report Uploaded', { projectId });
                }}
              >
                {(getInputProps, open) => (
                  <>
                    <Box sx={styles.uploadIconWrapper}>
                      <SVG src={uploadIcon} title="Upload" style={styles.uploadFormIcon} />
                    </Box>
                    <input {...getInputProps()} />
                    <Typography variant="h5" sx={styles.uploadFormText}>
                      Drag and drop to upload
                    </Typography>
                    <Typography variant="caption" sx={styles.uploadFormText}>
                      {'or '}
                      <Button type="button" sx={styles.openFileDialogLink} onClick={open}>browse</Button>
                      {' to choose a file'}
                    </Typography>
                  </>
                )}
              </Dropzone>
              {file && (
                <Box sx={styles.progressRow}>
                  <div>
                    <Typography variant="body2">{file.name}</Typography>
                  </div>
                  <Typography variant="body2">
                    {file.progress.toFixed(2)}
                    {' '}
                    %
                    {' '}
                  </Typography>
                  <Box sx={styles.progress}>
                    <LinearProgress
                      color="primary"
                      variant="determinate"
                      value={file.progress}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </CardContent>
        </Card>
        <Card sx={styles.card}>
          <CardContent sx={styles.cardContent}>
            <Typography variant="h2" align="center" sx={styles.cardTitle}>Great Job!</Typography>
            <Box sx={styles.submitSlide}>
              <Typography variant="h2" align="center" sx={styles.cardTitle}>If you’re confident in the quality of your data, feel free to submit your order.</Typography>
              <Box sx={styles.finalButtonGroup}>
                <Button onClick={onDismiss} variant="contained">Dismiss</Button>
                <Button
                  onClick={onSubmit}
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                >
                  <Fade in={isSubmitting}>
                    <CircularProgress size={24} sx={styles.buttonProgress} />
                  </Fade>
                  <Fade in={!isSubmitting}>
                    <span>Submit</span>
                  </Fade>
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Carousel>
    </Dialog>
  );
};

export default Carousel3DFE;
