import { createAction } from '@reduxjs/toolkit';

export const GetUserProfileStartAction = createAction('account/GetUserProfileStartAction');

export const GetUserProfileSuccessAction = createAction<IUser>('account/GetUserProfileSuccessAction');

export const GetUserProfileFailedAction = createAction('account/GetUserProfileFailedAction');

export const GetOrgProfileStartAction = createAction('account/GetOrgProfileStartAction');

export const GetOrgProfileSuccessAction = createAction<IOrg>('account/GetOrgProfileSuccessAction');

export const GetOrgProfileFailedAction = createAction('account/GetOrgProfileFailedAction');

export const UpdateUserProfileSuccessAction = createAction<IUserResponse>('account/UpdateUserProfileSuccessAction');

export const UpdateOrgProfileSuccessAction = createAction<IOrgResponse>('account/UpdateOrgProfileSuccessAction');

export const UpdatePasswordSuccessAction = createAction<string>('account/UpdatePasswordSuccessAction');
export const UpdatePasswordFailureAction = createAction('account/UpdatePasswordFailureAction');

export const GetOrgTeamsStartAction = createAction('account/GetOrgTeamsStartAction');
export const GetOrgTeamsSuccessAction = createAction<ITeam[]>('account/GetOrgTeamsSuccessAction');
export const GetOrgTeamsFailureAction = createAction('account/GetOrgTeamsFailureAction');

export const DefaultTemplateSuccessAction = createAction<IOrg>('account/DefaultTemplateSuccessAction');

export const DeleteOrgUserStartAction = createAction<string>('account/DeleteOrgUserStartAction');
export const DeleteOrgUserSuccessAction = createAction<string>('account/DeleteOrgUserSuccessAction');
export const DeleteOrgUserFailureAction = createAction<string>('account/DeleteOrgUserFailureAction');

export const ClearOrgAccountAction = createAction('account/ClearOrgAccountAction');

export const GetOrgUsersStartAction = createAction('account/GetOrgUsersStartAction');
export const GetOrgUsersSuccessAction = createAction<IOrgUser[]>('account/GetOrgUsersSuccessAction');
export const GetOrgUsersFailureAction = createAction('account/GetOrgUsersFailureAction');

export const GetOrgAssignableRolesAction = createAction<{[key: string]: string}>('account/GetOrgAssignableRolesAction');

export const EditOrgUserSuccessAction = createAction<IUser>('account/EditOrgUserSuccessAction');

export const CreateOrgUserStartAction = createAction('account/CreateOrgUserStartAction');
export const CreateOrgUserSuccessAction = createAction<IOrgUser>('account/CreateOrgUserSuccessAction');
export const CreateOrgUserFailureAction = createAction<string>('account/CreateOrgUserFailureAction');

export const DeleteOrgTeamStartAction = createAction('account/DeleteOrgTeamStartAction');
export const DeleteOrgTeamSuccessAction = createAction<string>('account/DeleteOrgTeamSuccessAction');
export const DeleteOrgTeamFailureAction = createAction('account/DeleteOrgTeamFailureAction');

export const UpdateOrgTeamStartAction = createAction('account/UpdateOrgTeamStartAction');
export const UpdateOrgTeamSuccessAction = createAction<ITeam>('account/UpdateOrgTeamSuccessAction');
export const UpdateOrgTeamFailureAction = createAction('account/UpdateOrgTeamFailureAction');

export const AddOrgTeamStartAction = createAction('account/AddOrgTeamStartAction');
export const AddOrgTeamSuccessAction = createAction<ITeam>('account/AddOrgTeamSuccessAction');
export const AddOrgTeamFailureAction = createAction('account/AddOrgTeamFailureAction');

export const GetOrgStatsStartAction = createAction('account/GetOrgStatsStartAction');
export const GetOrgStatsSuccessAction = createAction<IOrgStats>('account/GetOrgStatsSuccessAction');
