// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { Typography, Divider, Checkbox, FormControlLabel, Collapse, IconButton } from '@mui/material';
import { classification2Classes, classification6Classes } from 'Utils/constants';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import homeIcon from 'Assets/home.png';
import { Box, useTheme } from '@mui/system';
import { useAppSelector, useAppDispatch } from 'Hooks';
import { SetClassVisibleAction, SetLayer3DChangeAction, SetToggleClassAction, Toggle3DLayerAction, Toggle3DOrderAction, ToggleAll3DLayersAction } from 'Features/viewer3D/viewer3DActions';
import CadLayers from './CadLayers';
import stylesDef from './styles';

interface Order3DLayersProps {
  orderId: string;
  orderNumber: number;
  lasFilesWith3DTile: ILasFile[];
  showClassifiedPointCloud: {[key: string]: boolean};
  setResetZoom: (show: boolean) => void;
  setShowClassifiedPointCloud: (show: {[key: string]: boolean}) => void;
}

const Order3DLayers = ({ orderId, orderNumber, lasFilesWith3DTile, showClassifiedPointCloud, setResetZoom, setShowClassifiedPointCloud }: Order3DLayersProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const [expand, setExpand] = useState(true);
  const [allLayersSelected, setAllLayersSelected] = useState(true);
  let classificationClasses: any[] = [];
  const classes = lasFilesWith3DTile?.find((las) => las.ownerOrder === orderId)?.classes;
  if (classes === 2) {
    classificationClasses = classification2Classes;
  } else if (classes === 6) {
    classificationClasses = classification6Classes;
  }

  const { selected3DCadFiles, cad3dLayers } = useAppSelector(
    (state) => state.viewer3D,
  );
  const classVisible = useAppSelector((state) => state.viewer3D.classVisible);

  const toggleOrder = (checked: boolean, order: string, toggleAllLayers: boolean = false) => {
    if (!toggleAllLayers) {
      setAllLayersSelected(true);
      dispatch(Toggle3DOrderAction(order));
    }
    const site = selected3DCadFiles[order];
    if (site) {
      const { siteId } = site;
      const layerChange: (string|boolean)[] = [];
      cad3dLayers[siteId]?.layers?.forEach((layer) => {
        if (toggleAllLayers) {
          layerChange.push(`${siteId}-${layer.layer_id}`, checked && !allLayersSelected);
        } else if (!toggleAllLayers && allLayersSelected) {
          dispatch(ToggleAll3DLayersAction({ siteId, toggle: true }));
          layerChange.push(`${siteId}-${layer.layer_id}`, checked && allLayersSelected);
        } else {
          layerChange.push(`${siteId}-${layer.layer_id}`, checked && layer.visible);
        }
      });
      dispatch(SetLayer3DChangeAction(layerChange));
    }
  };

  const toggleAllLayers = (siteId: string) => {
    toggleOrder(!allLayersSelected, orderId, true);
    dispatch(ToggleAll3DLayersAction({ siteId, toggle: !allLayersSelected }));
    setAllLayersSelected(!allLayersSelected);
  };

  const toggleLayer = (checked: boolean, siteId: string, layerId: number) => {
    dispatch(Toggle3DLayerAction({ siteId, layerId }));
    const layerChange: (string|boolean)[] = [];
    layerChange.push(`${siteId}-${layerId}`, checked);
    dispatch(SetLayer3DChangeAction(layerChange));
  };

  const toggleClassifiedPointCloud = (order: string) => {
    const newClassified = { ...showClassifiedPointCloud };
    newClassified[order] = !showClassifiedPointCloud[order];
    setShowClassifiedPointCloud(newClassified);
  };

  const toggleExpand = () => {
    setExpand(!expand);
  };

  const toggleClass = (name: string) => {
    const orderClassVisible = classVisible[orderId];
    const newClassVisible = { ...classVisible, [orderId]: { ...orderClassVisible } };
    newClassVisible[orderId][name] = !newClassVisible[orderId][name];
    dispatch(SetClassVisibleAction(newClassVisible));
    dispatch(SetToggleClassAction(orderId));
  };

  return (
    <Box sx={styles.layerGroup} key={orderId}>
      <Box sx={styles.checkBoxTitle}>
        <FormControlLabel
          label={(
            <div>
              <Typography variant="h4">{`CAD ${orderNumber}`}</Typography>
            </div>
          )}
          control={(
            <Checkbox
              color="primary"
              onChange={(e) => toggleOrder(e.target.checked, orderId)}
              checked={selected3DCadFiles[orderId].visible}
            />
          )}
        />
        {(selected3DCadFiles[orderId].visible) && (
          <IconButton sx={styles.layersExpandButton} onClick={toggleExpand}>
            {expand
              ? <KeyboardArrowDownIcon fontSize="small" />
              : <KeyboardArrowRightIcon fontSize="small" />}
          </IconButton>
        )}
      </Box>
      {selected3DCadFiles[orderId].visible && (
        <Collapse in={expand}>
          <CadLayers orderId={orderId} toggleLayer={toggleLayer} allLayersSelected={allLayersSelected} setAllLayersSelected={setAllLayersSelected} toggleAllLayers={toggleAllLayers} />
        </Collapse>
      )}
      {showClassifiedPointCloud && showClassifiedPointCloud[orderId] !== undefined && (
        <>
          <Box sx={styles.pointCloudSelection}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={showClassifiedPointCloud[orderId]}
                  onChange={() => toggleClassifiedPointCloud(orderId)}
                  name="pointCloud"
                  color="primary"
                />
              )}
              label={<Typography variant="h4" sx={styles.layerName}>CLASSIFIED POINT CLOUD</Typography>}
            />
            <IconButton onClick={() => setResetZoom(true)}>
              <img src={homeIcon} alt="reset zoom" />
            </IconButton>
          </Box>
          <Box sx={styles.classesContainer}>
            {classificationClasses.map((item) => (
              <Box sx={styles.pointCloudSelection} key={item.name}>
                <Box sx={styles.classContainer}>
                  <span style={{ ...styles.dot, backgroundColor: item.color }} />
                  <Typography variant="h5" sx={styles.classname}>{item.name}</Typography>
                </Box>
                <Box sx={styles.flexItem}>
                  {
                    classVisible[orderId]?.[item.name] ?
                      <VisibilityIcon sx={styles.icon} fontSize="small" onClick={() => toggleClass(item.name)} /> :
                      <VisibilityOffIcon sx={styles.icon} fontSize="small" onClick={() => toggleClass(item.name)} />
                  }
                </Box>
              </Box>
            ))}
          </Box>
        </>
      )}
      <Divider sx={styles.divider} />
    </Box>
  );
};

export default Order3DLayers;
