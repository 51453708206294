// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { StepIconProps } from '@mui/material/StepIcon';
import { Box, useTheme } from '@mui/system';
import stylesDef from './styles';

const ColorlibStepIcon = ({ active, completed }: StepIconProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  return (
    <Box sx={{ ...styles.root, ...(active && styles.stepActive), ...(completed && styles.stepCompleted) }} />
  );
};

export default ColorlibStepIcon;
