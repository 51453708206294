// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createSlice } from '@reduxjs/toolkit';
import {
  DeleteProjectsFailedAction,
  DeleteProjectsStartAction,
  DeleteProjectsSuccessAction,
  GetProjectsFailedAction,
  GetProjectsStartAction,
  GetProjectsSuccessAction,
  SetKeywordFilterAction,
  SetLoadAllAction,
  SetLoadNewAction,
  SetOrderSortFilterAction,
  SetPageAction,
  SetProjectsAction,
  SetSortFilterAction,
  SetThumbnailAction,
  UpdateProjectNameAction,
  DeleteProjectListLasFileAction, DeleteProjectListTifFileAction,
  UpdateProjectListLasFileAction, UpdateProjectListTifFileAction, UpdateProjectListImageryRequestSuccessAction,
} from './projectListActions';

const initialState: IProjectListState = {
  projectsLoading: false,
  projectsCount: 0,
  loadAll: false,
  loadNew: true,
  page: 0,
  list: [],
  keywordFilter: '',
  sortFilter: 'updatedAtD',
  orderSortFilter: 'updatedAtD',
};

const projectListSlice = createSlice({
  name: 'projectList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SetSortFilterAction, (state, action) => {
        state.sortFilter = action.payload;
      })
      .addCase(SetKeywordFilterAction, (state, action) => {
        state.keywordFilter = action.payload;
      })
      .addCase(SetOrderSortFilterAction, (state, action) => {
        state.orderSortFilter = action.payload;
      })
      .addCase(SetPageAction, (state, action) => {
        state.page = action.payload;
      })
      .addCase(GetProjectsStartAction, (state) => {
        state.projectsLoading = true;
      })
      .addCase(GetProjectsSuccessAction, (state, action) => {
        state.projectsLoading = false;
        state.list = [...state.list, ...action.payload.list];
        state.projectsCount = action.payload.totalCount;
      })
      .addCase(GetProjectsFailedAction, (state) => {
        state.projectsLoading = false;
      })
      .addCase(DeleteProjectsStartAction, (state, action) => {
        const index = state.list.findIndex((p) => p._id === action.payload);
        state.list[index].deleting = true;
      })
      .addCase(DeleteProjectsSuccessAction, (state, action) => {
        state.list = state.list.filter((p) => p._id !== action.payload);
      })
      .addCase(DeleteProjectsFailedAction, (state, action) => {
        const index = state.list.findIndex((p) => p._id === action.payload);
        state.list[index].deleting = false;
      })
      .addCase(SetProjectsAction, (state, action) => {
        state.list = action.payload;
      })
      .addCase(SetLoadAllAction, (state, action) => {
        state.projectsLoading = false;
        state.loadAll = action.payload;
      })
      .addCase(SetLoadNewAction, (state, action) => {
        state.loadNew = action.payload;
      })
      .addCase(SetThumbnailAction, (state, action) => {
        const index = state.list.findIndex((p) => p._id === action.payload.projectId);
        state.list[index].thumbnail = action.payload.thumbnail;
      })
      .addCase(UpdateProjectNameAction, (state, action) => {
        const project = state.list.find((p) => p._id === action.payload._id);
        /* eslint-disable no-unused-expressions */
        action.payload.opsTrainer
          ? (project.training.nameEditable = action.payload.training.nameEditable)
          : (project.name = action.payload.name);
      })
      .addCase(UpdateProjectListTifFileAction, (state, action) => {
        const projectInList = state.list.find((p) => p._id === action.payload.ownerProject);
        if (projectInList) {
          const index = projectInList.tifFiles.findIndex((t) => t._id === action.payload._id);
          if (index < 0) {
            projectInList.tifFiles.push(action.payload);
          } else {
            projectInList.tifFiles[index] = action.payload;
          }
        }
      })
      .addCase(UpdateProjectListLasFileAction, (state, action) => {
        const projectInList = state.list.find((p) => p._id === action.payload.ownerProject);
        if (projectInList) {
          const index = projectInList.lasFiles.findIndex((t) => t._id === action.payload._id);
          if (index < 0) {
            projectInList.lasFiles.push(action.payload);
          } else {
            projectInList.lasFiles[index] = action.payload;
          }
        }
      })
      .addCase(DeleteProjectListTifFileAction, (state, action) => {
        const projectInList = state.list.find((p) => p._id === action.payload.projectId);
        if (projectInList) {
          projectInList.tifFiles = projectInList.tifFiles.filter((t) => t._id !== action.payload.tifFileId);
        }
      })
      .addCase(DeleteProjectListLasFileAction, (state, action) => {
        const projectInList = state.list.find((p) => p._id === action.payload.projectId);
        if (projectInList) {
          projectInList.lasFiles = projectInList.lasFiles.filter((t) => t._id !== action.payload.lasFileId);
        }
      })
      .addCase(UpdateProjectListImageryRequestSuccessAction, (state, action) => {
        const projectInList = state.list.find((p) => p._id === action.payload.ownerProject);
        if (projectInList) {
          projectInList.imageryRequest = action.payload;
        }
      });
  },
});

export const { reducer } = projectListSlice;

export default reducer;
