/* eslint-disable react/jsx-no-useless-fragment */
// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Typography, FormControlLabel, Checkbox } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import InfoIcon from '@mui/icons-material/Info';
import HelpIcon from '@mui/icons-material/Help';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { WideLightTooltip } from 'Components/Tooltip';
import { SetDeliverablesThunk } from 'Features/bundle/bundleThunk';
import stylesDef from './styles';

interface DeliveryDateProps {
  page: number;
  businessDays: number;
  deliverySpeed: string;
  estimatedDate: string;
  expeditedAvailability: { expedited: boolean, super_expedited: boolean };
  selectedBundleId: number;
  checkFirstTimeChangeEstimate: (arg0: Function) => void;
  setDeliverySpeed: (arg0: string) => void;
}

const DeliveryDate = ({ page, businessDays, deliverySpeed, estimatedDate, expeditedAvailability, selectedBundleId, checkFirstTimeChangeEstimate, setDeliverySpeed }: DeliveryDateProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();

  const { placeOrderSuccess } = useAppSelector(
    (state) => state.order,
  );
  const { density, list: bundles, selectedBundle } = useAppSelector(
    (state) => state.bundle,
  );
  const { status } = useAppSelector(
    (state) => state.globalStatus,
  );

  const { allowExpeditedProcess } = status || {};
  const { name: selectedBundleName } = selectedBundle || {};

  const handleChangeDeliverySpeed = (newSpeed: string) => {
    const delSpeed = deliverySpeed === newSpeed ? 'normal' : newSpeed;
    const findBundles = bundles.find((b) => b.name === selectedBundleName && b.deliverySpeed === delSpeed && b.density === density);
    dispatch(SetDeliverablesThunk(findBundles, true));
    setDeliverySpeed(delSpeed);
  };

  const expressAIBundle = selectedBundleId === 9;

  return (
    <>
      <Typography variant="h6" sx={styles.cadText}>
        Estimated Delivery Date
      </Typography>
      {expressAIBundle && (
        <Box sx={styles.dateGrid}>
          <Typography variant={placeOrderSuccess ? 'body1' : 'h6'} sx={{ ...(!placeOrderSuccess && styles.deliveryDateText) }}>
            24 hours from order submission
          </Typography>
        </Box>
      )}
      {!expressAIBundle && (
        <>
          {placeOrderSuccess ? (
            <>
              {
                businessDays > 20 ? (
                  <Box sx={styles.dateGrid}>
                    <Typography variant="body1">This project is eligible for super expedited delivery</Typography>
                    <WideLightTooltip
                      placement="bottom"
                      title={
                        (
                          <div>
                            Need this back super fast? Our team can provide a custom delivery date for this project. Reach out to
                            {' '}
                            <a href="mailto:sales@airworks.io">sales@airworks.io</a>
                            {' '}
                            to get a quote.
                          </div>
                        )
                      }
                    >
                      <HelpIcon sx={styles.icon} />
                    </WideLightTooltip>
                  </Box>
                ) : (
                  <Typography variant="body1">
                    {estimatedDate}
                    {' '}
                    by 8PM EST
                  </Typography>
                )
              }
            </>
          ) : (
            <>
              {businessDays > 20 ?
                (
                  <Box sx={styles.dateGrid}>
                    <Typography variant="h6" sx={styles.deliveryDateText}>This project is eligible for super expedited delivery</Typography>
                    <WideLightTooltip
                      placement="bottom"
                      title={
                        (
                          <div>
                            Need this back super fast? Our team can provide a custom delivery date for this project. Reach out to
                            {' '}
                            <a href="mailto:sales@airworks.io">sales@airworks.io</a>
                            {' '}
                            to get a quote.
                          </div>
                        )
                      }
                    >
                      <HelpIcon sx={styles.icon} />
                    </WideLightTooltip>
                  </Box>
                ) :
                (
                  <Box sx={styles.dateGrid}>
                    <Typography variant="h6" sx={styles.deliveryDateText}>
                      {estimatedDate}
                      {' '}
                      by 8PM EST
                      {page === 0 && ` (${businessDays} business days)`}
                    </Typography>
                    <WideLightTooltip
                      placement="bottom"
                      title={
                        (
                          <div>
                            Turn-around time varies depending on site density especially for large projects. The above is our estimated delivery date. If you have questions or need your project faster, please select expedited delivery, or reach out to us through chat or
                            {' '}
                            <a href="mailto:support@airworks.io">support@airworks.io</a>
                            .
                            <br />
                            <br />
                            Add-ons delivery date is an estimate, we'll deliver them as soon as possible.
                          </div>
                        )
                      }
                    >
                      <HelpIcon sx={styles.icon} />
                    </WideLightTooltip>
                  </Box>
                )}
              <Box sx={styles.expeditedDelivery}>
                <FormControlLabel
                  disabled={!allowExpeditedProcess || !expeditedAvailability.expedited}
                  control={(
                    <Checkbox
                      disabled={!allowExpeditedProcess || !expeditedAvailability.expedited}
                      checked={deliverySpeed === 'expedited'}
                      onChange={() => checkFirstTimeChangeEstimate(() => handleChangeDeliverySpeed('expedited'))}
                    />
                  )}
                  label={`Expedited Delivery${!expeditedAvailability.expedited ? ' Not Available' : ''}`}
                  classes={{ root: styles.checkbox, label: styles.checkboxLabel }}
                />
                {!allowExpeditedProcess && (
                  <WideLightTooltip
                    placement="bottom"
                    title={(
                      <div>
                        Expedited processing is currently not available through the order form. Please reach out to us through chat or
                        {' '}
                        <a href="mailto:support@airworks.io">support@airworks.io</a>
                        {' '}
                        if you would like to discuss faster delivery.
                      </div>
                    )}
                  >
                    <Box sx={styles.info}>
                      <InfoIcon sx={styles.icon} />
                      {' '}
                      Talk to rep
                    </Box>
                  </WideLightTooltip>
                )}
              </Box>
              {allowExpeditedProcess && expeditedAvailability.super_expedited && (
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={deliverySpeed === 'super_expedited'}
                      onChange={() => checkFirstTimeChangeEstimate(() => handleChangeDeliverySpeed('super_expedited'))}
                    />
                  )}
                  label="Super Expedited Delivery"
                  sx={{ root: styles.checkbox, label: styles.checkboxLabel }}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default DeliveryDate;
