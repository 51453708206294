// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Link, matchPath } from 'react-router-dom';
import { Tabs, Tab } from '@mui/material';
import { useTheme } from '@mui/system';
import { ADMIN_ROUTE, ADMIN_OPS_SETTINGS_ROUTE, ADMIN_PRICING_TIERS_ROUTE } from 'Utils/routes';
import stylesDef from './styles';
import { useAppSelector } from 'Hooks';

export const AdminToolbar = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const currentPath = location.pathname;

  function getActiveTabId(pathname: string) {
    if (matchPath({ path: ADMIN_ROUTE }, pathname)) return 1;
    if (matchPath({ path: ADMIN_OPS_SETTINGS_ROUTE }, pathname)) return 2;
    if (matchPath({ path: ADMIN_PRICING_TIERS_ROUTE }, pathname)) return 3;
    return null;
  }
  const activeTabId = getActiveTabId(currentPath);
  const { pricingAccess } = useAppSelector((state) => state.auth.user);

  return (
    <Tabs indicatorColor="primary" value={activeTabId}>
      <Tab
        component={Link}
        label="Customers"
        value={1}
        to={ADMIN_ROUTE}
       sx={{
          wrapper: styles.tabLabel,
        }}
      />
      <Tab
        component={Link}
        label="OPS Settings"
        value={2}
        to={ADMIN_OPS_SETTINGS_ROUTE}
       sx={{
          wrapper: styles.tabLabel,
        }}
      />
      {pricingAccess && (
        <Tab
          component={Link}
          label="Pricing Tiers"
          value={3}
          to={ADMIN_PRICING_TIERS_ROUTE}
         sx={{
            wrapper: styles.tabLabel,
          }}
        />
      )}
    </Tabs>
  );
};

export default AdminToolbar;
