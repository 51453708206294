// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createAction } from '@reduxjs/toolkit';

export const GetTemplatesStartAction = createAction('cadTemplate/GetTemplatesStartAction');
export const GetTemplatesSuccessAction = createAction<ICadTemplate[]>('cadTemplate/GetTemplatesSuccessAction');
export const GetTemplatesFailureAction = createAction<string>('cadTemplate/GetTemplatesFailureAction');

export const SetTemplateIdAction = createAction<string>('cadTemplate/SetTemplateIdAction');

export const DeleteTemplateStartAction = createAction<string>('cadTemplate/DeleteTemplateStartAction');
export const DeleteTemplateSuccessAction = createAction<string>('cadTemplate/DeleteTemplateSuccessAction');
export const DeleteTemplateFailureAction = createAction<string>('cadTemplate/DeleteTemplateFailureAction');

export const EditTemplateStartAction = createAction('cadTemplate/EditTemplateStartAction');
export const EditTemplateSuccessAction = createAction<ICadTemplate>('cadTemplate/EditTemplateSuccessAction');
export const EditTemplateFailureAction = createAction('cadTemplate/EditTemplateFailureAction');

export const DuplicateTemplateSuccessAction = createAction<ICadTemplate>('cadTemplate/DuplicateTemplateSuccessAction');

export const SetSelectedStyleAction = createAction<{ templateId: string, layerId: string, event: string }>('cadTemplate/SetSelectedStyleAction');

export const SetSelectedWeightAction = createAction<{ templateId: string, layerId: string, event: number }>('cadTemplate/SetSelectedWeightAction');

export const SetSelectedColorAction = createAction<{ templateId: string, layerId: string, color: string }>('cadTemplate/SetSelectedColorAction');

export const SetSelectedMappingAction = createAction<{ templateId: string, layerId: string, event: Array<string> }>('cadTemplate/SetSelectedMappingAction');

export const AddTemplateAction = createAction<ICadTemplate>('cadTemplate/AddTemplateAction');

export const GetDefaultTemplateAction = createAction<string>('GetDefaultTemplateAction');

export const ResetTemplateListAction = createAction('cadTemplate/ResetTemplateListAction');
