// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  pageWrapper: {
    width: 600,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '30px',
  },
  settingWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 10px',
  },
});

export default styles;
