// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  pageWrapper: {
    margin: '0 auto',
    marginTop: theme.spacing(6),
  },
  headerWrapper: {
    margin: 'auto',
    width: '95%',
  },
  options: {
    display: 'flex',
  },
  exportButton: {
    backgroundColor: '#E5EAEE',
    padding: '10px',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridColumnGap: theme.spacing(1),
    alignItems: 'center',
  },
  exportButtonText: {
    color: '#021E40',
  },
  table: {
    width: '95%',
    margin: '0 auto',
  },
  tableCell: {
    borderBottom: 'none',
    textAlign: 'left',
    'last-of-type': {
      color: 'blue',
      textAlign: 'right',
      width: '200px',
    },
    width: '300px',
    fontSize: '1em',
  },
  iconTableCell: {
    borderBottom: 'none',
    textAlign: 'left',
    width: '30px',
    fontSize: '1em',
  },
  icons: {
    flexGrow: 0,
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  itemsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  noOrderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '150px',
    gap: theme.spacing(3),
  },
  icon: {
    width: '20px',
    height: '20px',
    cursor: 'pointer',
    color: '#01A1DD',
  },
  infoIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  costHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
});

export default styles;
