// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Marker } from 'react-map-gl';
import markerIcon from '@mapbox/maki/icons/marker.svg';
import SVG from 'react-inlinesvg';
import { Box, useTheme } from '@mui/system';
import { useAppSelector } from 'Hooks';
import stylesDef from './styles';

const MapSearchMarker = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const marker = useAppSelector((state) => state.map.common.marker);
  return (
    <>
      {marker && (
       <Marker latitude={marker[1]} longitude={marker[0]}>
          <Box sx={styles.markerIconWrapper}>
            <SVG src={markerIcon} css={styles.markerIcon} />
          </Box>
        </Marker>
      )}
    </>
  );
};

export default MapSearchMarker;
