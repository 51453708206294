// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  dialog: {
    width: 500,
  },
  paperFullscreen: {
    height: 'auto',
  },
  dropzone: {
    display: 'grid',
    gridTemplateColumns: '1fr 4fr',
    alignItems: 'center',
  },
  dropzoneText: {
    marginLeft: theme.spacing(2),
  },
  uploadFormText: {
    paddingBottom: theme.spacing(1),
  },
  openFileDialogLink: {
    color: theme.palette.primary.main,
    background: 'none!important',
    border: 'none',
    padding: '0!important',
    font: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  uploadFormIcon: {
    width: theme.spacing(5),
    hieght: theme.spacing(5),
  },
});

export default styles;
