// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/system';
import { Typography, CircularProgress, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { deliveryEstimate } from 'Utils/deliverables';
import WarningDeliverableDialog from 'Components/WarningDeliverableDialog';
import TalkToSalesDialog from 'Components/TalkToSalesDialog';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { useSelector } from 'react-redux';
import { RootState } from 'Store';
import { getOrders } from 'Features/order/orderSelectors';
import { ResetBundleStateAction, SelectedAdditionalDeliverablesAndLayersAction, SetDeliveryDateAction, SetDeliveryDaysAction } from 'Features/bundle/bundleActions';
import { projectTypes } from 'Utils/constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { GetBundlesThunk } from 'Features/bundle/bundleThunk';
import BundleSelect from './BundleSelect';
import LayerSelect from './LayerSelect';
import DeliverableSelect from './DeliverableSelect';
import BundleButtons from './BundleButtons';
import stylesDef from './styles';

interface PackageProps {
  deliverySpeed: string;
  isSaved: boolean;
  checkFirstTimeChangeEstimate: (arg0: Function) => void;
  page: number;
  bundleStep: number;
  setBundleStep: (bundleStep: number) => void;
  manageAddOns: (deliverableId: number, type: string, acreFactor: number, checked: boolean) => void;
  addOnDeliverableDollars: number;
  addOnLayerDollars: number;
  zeroAddOns: () => void;
  setAllowTemplateReview: (arg0: boolean) => void;
  setCheckedForOverage: (arg0: boolean) => void;
  bundleType: string;
  setBundleType: (arg0: string) => void;
}

const Package = ({ manageAddOns, deliverySpeed, isSaved, page, bundleStep, setBundleStep, addOnLayerDollars,
  zeroAddOns, checkFirstTimeChangeEstimate, addOnDeliverableDollars, setAllowTemplateReview, setCheckedForOverage, bundleType, setBundleType }: PackageProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const { telecom } = useFlags();
  const [unselectedDependencies, setUnselectedDependencies] = useState([]);
  const [unselectedDependencyNames, setUnselectedDependencyNames] = useState([]);
  const [childDependencyCheck, setChildDependencyCheck] = useState(false);
  const [selectItem, setSelectItem] = useState(null);
  const [contactSalesInteraction, setContactSalesInteraction] = useState(false);

  const { bundleDeliverables, additionalDeliverables, selectedBundle, selectedAdditionals, density, availableBundles, deliveryDays, loading, totalCost: bundleCost } = useAppSelector(
    (state: RootState) => state.bundle,
  );
  const { org } = useAppSelector(
    (state: RootState) => state.account,
  );

  const { availableCreditAcreage, costPerAcre, currentPricingPlanInfo } = org;
  const lowDensity = density === 'low';
  const pricingPlanName = currentPricingPlanInfo?.name;
  const { bundleId: currentBundleId, acreAdjustmentFactor } = selectedBundle || {};
  const activeOrder = useSelector((state: RootState) => getOrders(state).activeOrder);
  const placedOrder = useSelector((state: RootState) => getOrders(state).placedOrder);

  const expressAIBundle = currentBundleId === 9;
  const showOverageDialog = !expressAIBundle && !pricingPlanName && (!contactSalesInteraction && (availableCreditAcreage < activeOrder.acreage || (addOnDeliverableDollars + addOnLayerDollars) > 0));

  useEffect(() => {
    if (availableBundles?.length > 0 && !contactSalesInteraction && (availableCreditAcreage < activeOrder.acreage)) {
      const costB = availableCreditAcreage * costPerAcre + bundleCost;
      let orderAdjustedAcres = activeOrder.acreage;
      let addOnCost = 0;

      if (activeOrder.isEstimate) {
        orderAdjustedAcres = activeOrder.acreage * acreAdjustmentFactor;

        if (activeOrder.addOns.length > 0 && additionalDeliverables.length > 0) {
          activeOrder.addOns.forEach((addOn) => {
            const additional = additionalDeliverables.find((deliverable: IDeliverable) => deliverable.name === addOn.name);
            const flatFee = additional.costPerFactors[0];
            const costPerAcreAddon = additional.costPerFactors[1];
            addOnCost += (flatFee + costPerAcreAddon * activeOrder.acreage);
          });
        }
      }

      const costA = orderAdjustedAcres * costPerAcre + addOnCost;
      window?.pendo?.track('Out of Credits', {
        projectId: activeOrder?.ownerProject,
        orderAcreage: activeOrder?.acreage?.toFixed(2),
        costWithRenewing: costA?.toFixed(2),
        costWithoutRenewing: costB?.toFixed(2),
      });
    }
  }, [availableBundles, contactSalesInteraction, availableCreditAcreage, activeOrder.acreage]);

  useEffect(() => {
    if ((availableBundles?.length > 0)) {
      if (showOverageDialog) {
        setAllowTemplateReview(false);
      } else {
        setAllowTemplateReview(true);
        setCheckedForOverage(true);
      }
    }
  }, [availableBundles, showOverageDialog]);

  const selectAdditionalDeliverable = (data: IDeliverable, checked: boolean) => {
    let days = deliveryDays;
    const { deliverableId, type, acreFactor, time } = data;
    if (checked) {
      days += time;
      // When we add the first add-on to A la carte bundle, add 1.5 or 2 days depending on expedited or low density vs normal
      if (currentBundleId === 5 && selectedAdditionals.length === 0) {
        if (deliverySpeed === 'expedited' || lowDensity) days += 1.5;
        else if (deliverySpeed === 'super_expedited') days += 1;
        else days += 2;
      }
    } else {
      days -= time;
      if (currentBundleId === 5 && selectedAdditionals.length === 1) {
        if (deliverySpeed === 'expedited' || lowDensity) days -= 1.5;
        else if (deliverySpeed === 'super_expedited') days -= 1;
        else days -= 2;
      }
    }
    manageAddOns(deliverableId, type, acreFactor, checked);

    const calculateDate = deliveryEstimate(days);
    dispatch(SetDeliveryDaysAction(days));
    dispatch(SetDeliveryDateAction(calculateDate));
    dispatch(SelectedAdditionalDeliverablesAndLayersAction(data));
  };

  const onCancelWarning = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setUnselectedDependencies([]);
    const deliverable = additionalDeliverables.find((del) => del._id === selectItem);
    selectAdditionalDeliverable(deliverable, false);
    setSelectItem(null);
    e.stopPropagation();
  };

  const onCancelRemoveWarning = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setUnselectedDependencies([]);
    const deliverable = additionalDeliverables.find((del) => del._id === selectItem);
    selectAdditionalDeliverable(deliverable, true);
    setSelectItem(null);
    e.stopPropagation();
  };

  const onOkWarning = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    unselectedDependencies.forEach((dep) => {
      const deliverable = additionalDeliverables.find((del) => del._id === dep);
      selectAdditionalDeliverable(deliverable, true);
    });
    setUnselectedDependencies([]);
    e.stopPropagation();
  };

  const onOkChildWarning = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    unselectedDependencies.forEach((dep) => {
      const deliverable = additionalDeliverables.find((del) => del._id === dep);
      SelectedAdditionalDeliverablesAndLayersAction(deliverable);
      manageAddOns(deliverable.deliverableId, deliverable.type, deliverable.acreFactor, false);
    });
    setUnselectedDependencies([]);
    e.stopPropagation();
  };

  const onAddOnsClick = (item: IDeliverable, checked: boolean) => {
    checkFirstTimeChangeEstimate(() => {
      checkParentDependencies(item, checked);
      selectAdditionalDeliverable(item, checked);
    });
  };

  const bundleOption = () => {
    switch (bundleStep) {
    case 0:
      return (<BundleSelect deliverySpeed={deliverySpeed} zeroAddOns={zeroAddOns} checkFirstTimeChangeEstimate={checkFirstTimeChangeEstimate} />);
    case 1:
      return (<DeliverableSelect onAddOnsClick={onAddOnsClick} />);
    case 2:
      return (<LayerSelect onAddOnsClick={onAddOnsClick} />);
    default:
      break;
    }
  };

  const checkParentDependencies = (item: any, checked: boolean) => {
    const _un: any[] = [];
    const _names: string[] = [];
    if (checked) {
      setChildDependencyCheck(false);
      item.dependencies.forEach((dep: any) => {
        const dependencySelected = bundleDeliverables.some((del) => del._id === dep) || selectedAdditionals.some((del) => del._id === dep);
        if (!dependencySelected) {
          const deliverable = additionalDeliverables.find((b) => b._id === dep);
          _un.push(dep);
          _names.push(deliverable.name);
        }
      });
    } else {
      setChildDependencyCheck(true);
      item.childDependencies.forEach((dep: any) => {
        const dependencySelected = bundleDeliverables.some((del) => del._id === dep) || selectedAdditionals.some((del) => del._id === dep);
        if (dependencySelected) {
          const deliverable = additionalDeliverables.find((b) => b._id === dep);
          _un.push(dep);
          _names.push(deliverable.name);
        }
      });
    }
    setUnselectedDependencies(_un);
    setUnselectedDependencyNames(_names);
    setSelectItem(item._id);
  };

  const contactSalesClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setContactSalesInteraction(true);
    window.open('mailto:sales@airworks.io', '_blank');
  };

  const handleBundleTypeChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newType = event.target.value;
    checkFirstTimeChangeEstimate(() => {
      zeroAddOns();
      setBundleStep(0);
      setBundleType(newType);
      dispatch(ResetBundleStateAction());
      dispatch(GetBundlesThunk(activeOrder._id, activeOrder.ownerProject, newType, false));
    });
  };

  return (
    <Box sx={styles.packageColumn}>
      {isSaved && <Typography sx={styles.alert}>Alert: Any changes to your bundle or add-ons will invalidate your saved estimate.</Typography>}
      <Typography sx={styles.pageTitle} variant="h1">Select Base Package and Add-Ons</Typography>
      {telecom && (
        <RadioGroup
          sx={styles.radioGroup}
          value={bundleType}
          onChange={handleBundleTypeChange}
        >
          {projectTypes.map((type) => <FormControlLabel sx={styles.typeText} value={type} control={<Radio />} label={type} disabled={!!placedOrder} />)}
        </RadioGroup>
      )}
      <Typography>
        {
          expressAIBundle ?
            'This base package is included in your plan and comes with every project. It generates imagery exclusively in 2D using Express AI. This feature ensures a quick and efficient creation process, allowing you to quickly visualize it in a 2D format. Please contact sales for questions or more info.'
            :
            'This is your base package that is included in your plan and comes with every project. You may choose another base package for this project; please note that any price differences will be adjusted in your usage at the end of the billing period. Please contact sales for questions or more info.'
        }
      </Typography>
      {(page === 0 && availableBundles?.length) ? (
        <Box>
          {bundleOption()}
          {!!additionalDeliverables.length && (
            <BundleButtons
              bundleStep={bundleStep}
              setBundleStep={setBundleStep}
            />
          )}
        </Box>
      ) : '' }
      {(page === 0 && loading) ? (
        <Box sx={styles.spinner}>
          <CircularProgress size={50} />
        </Box>
      ) : ''}
      <WarningDeliverableDialog
        showDialog={unselectedDependencies.length > 0 && !childDependencyCheck}
        onOk={onOkWarning}
        onCancel={onCancelWarning}
        titleText="This add-on requires the following items to be selected in order to be processed:"
        dependencyList={unselectedDependencyNames}
        questionText="Would you like to add required items to your order?"
        okText="Yes, add items"
      />
      <WarningDeliverableDialog
        showDialog={unselectedDependencies.length > 0 && childDependencyCheck}
        onOk={onOkChildWarning}
        onCancel={onCancelRemoveWarning}
        titleText="This item is required by the following add-ons in order to be processed:"
        dependencyList={unselectedDependencyNames}
        questionText="Would you like to remove these add-ons from your order?"
        okText="Yes, remove items"
      />
      {(availableBundles?.length > 0) && (
        <TalkToSalesDialog
          showDialog={showOverageDialog}
          onOk={contactSalesClick}
          onProceed={() => {
            setContactSalesInteraction(true);
          }}
          onCancel={() => {
            setContactSalesInteraction(true);
          }}
          addOnDeliverableDollars={addOnDeliverableDollars}
          addOnLayerDollars={addOnLayerDollars}
          titleText="Credit Limit Reached!"
        />
      )}
    </Box>
  );
};

export default Package;
