// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  dialog: {
    '& .MuiDialog-paperWidthXl': {
      height: '80%',
      maxHeight: '700px',
      padding: theme.spacing(4),
      margin: 'auto',
    },
  },
  dialogHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  closeButtonWrapper: {
    paddingBottom: theme.spacing(3),
    gridArea: 'close',
    justifySelf: 'end',
    zIndex: 2000,
    color: 'gray',
  },
  iconButton: {
    padding: theme.spacing(1),
  },
  dialogSubTitle: {
    fontSize: '16px',
  },
  cardContent: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingTop: theme.spacing(5),
  },
  cardMedia: {
    width: '80%',
    height: 'max-content',
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '5px',
  },
  card: {
    height: '100%',
  },
  cardTitle: {
    width: '80%',
    margin: '4px auto',
    fontWeight: 500,
    fontSize: '18px',
  },
  dropzone: {
    width: '80%',
    margin: 'auto',
    paddingTop: theme.spacing(2),
  },
  openFileDialogLink: {
    color: theme.palette.primary.main,
    background: 'none!important',
    border: 'none',
    padding: '0!important',
    font: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  uploadFormText: {
    paddingBottom: theme.spacing(1),
  },
  uploadIconWrapper: {
    width: 100,
    height: 100,
    backgroundColor: theme.palette.grey[100],
    borderRadius: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(2),
  },
  uploadFormIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  progress: {
    width: theme.spacing(8),
  },
  progressBar: {
    backgroundColor: '#FAA738',
  },
  progressRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(3),
  },
  submitSlide: {
    width: '80%',
    margin: 'auto',
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(10),
    backgroundColor: '#F5F6F9',
    borderRadius: '5px',
    minHeight: '300px',
  },
  finalButtonGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: theme.spacing(2),
    margin: 'auto',
    paddingTop: theme.spacing(3),
  },
});

export default styles;
