// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { connect } from 'react-redux';
import { Source, Layer } from 'react-map-gl';
import PropTypes from 'prop-types';
import { GetRasterTilePbfUrl } from 'Config';

interface RasterLayerProps {
  fileName: string;
  rasterTileJson: any;
  orgId: string;
  projectId: string;
  visible: string;
}

const RasterLayer: React.FC<RasterLayerProps> = ({ fileName, rasterTileJson, orgId, projectId, visible }) => {
  const sourceId = `${fileName}-raster_source`;
  const layerId = `${fileName}-raster_layer`;

  return (
    <Source
      id={sourceId}
      type="raster"
      scheme={rasterTileJson[fileName].isTms ? 'tms' : 'xyz'}
      tileSize={256}
      bounds={rasterTileJson[fileName].bounds}
      tiles={[GetRasterTilePbfUrl(orgId, projectId, fileName, rasterTileJson[fileName].isTms)]}
    >
      <Layer
        beforeId="kmlStub"
        type="raster"
        id={layerId}
        source={sourceId}
        layout={{
          visibility: visible ? 'visible' : 'none',
        }}
      />
    </Source>
  );
};

interface ComponentProps {
  showLayers: boolean;
}

const MapRasterLayer: React.FC<ComponentProps> = ({ showLayers, rasterTileJson, orgId, projectId, visible, tifFileNames }) => {
  let rasterReady = 0;
  // Check if every file has corresponding bounds
  const fileNames = tifFileNames?.map((tifName) => {
    const fileName = tifName.split('/').pop();
    const tifFileName = fileName.substring(0, fileName.lastIndexOf('.'));
    if (rasterTileJson && rasterTileJson[tifFileName] && rasterTileJson[tifFileName].bounds) {
      rasterReady += 1;
    }
    return tifFileName;
  });

  return (rasterReady === fileNames?.length) && fileNames?.map((fileName: string) => (
    <RasterLayer
      key={fileName}
      fileName={fileName}
      rasterTileJson={rasterTileJson}
      orgId={orgId}
      projectId={projectId}
      visible={visible}
    />
  ));
};

const mapStateToProps = (state: IState, ownProps: ComponentProps) => {
  const { project } = state.project;
  const tifFileNames = project?.tifFiles?.map((tifFile) => (tifFile.filename.split('/').pop()));
  // eslint-disable-next-line no-nested-ternary
  const orgId = _ADMIN_ ? state.admin.orgId : ('opsTrainer' in state.auth.resources ? project?.ownerOrganization : state.auth.user?.ownerOrganization);
  return ({
    rasterTileJson: state.map.common.rasterTileJson,
    orgId,
    projectId: state.project.projectId,
    visible: !ownProps.showLayers || state.map.common.layers.orthomosaic,
    rasterLoading: state.map.common.rasterTileJsonLoading,
    changeRaster: state.map.common.changeRaster,
    tifFileNames,
  });
};

MapRasterLayer.propTypes = {
  rasterTileJson: PropTypes.any.isRequired,
  orgId: PropTypes.string.isRequired,
  projectId: PropTypes.any.isRequired,
  visible:  PropTypes.any.isRequired,
  tifFileNames: PropTypes.any.isRequired,
};

export default connect(
  mapStateToProps,
  null,
  null,
  {
    areStatePropsEqual: (next, prev) => next.projectId === prev.projectId &&
      next.orgId === prev.orgId &&
      next.rasterLoading === prev.rasterLoading &&
      next.visible === prev.visible &&
      next.changeRaster === prev.changeRaster,
  },
)(MapRasterLayer);
