// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Link, Typography, Button, CircularProgress, Fade, Collapse } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { Form } from 'react-final-form';
import history from 'Utils/history';
import { ROOT_ROUTE } from 'Utils/routes';
import { TextField, Validators, Helpers } from 'Components/forms';
import { clearLocalStorage } from 'Utils/localStorageService';
import { clearSessionStorage } from 'Utils/sessionStorageService';
import { withOktaAuth, useOktaAuth } from '@okta/okta-react';
import ReCAPTCHA from 'react-google-recaptcha';
import { FORM_ERROR } from 'final-form';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { Login } from '../authActions';
import stylesDef from './styles';

const LoginForm = () => {
  const { failedLoginAttempts, user } = useAppSelector(
    (state) => state.auth,
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const styles = stylesDef(theme);
  const captchaRef = useRef(null);
  const { oktaAuth } = _ADMIN_ && useOktaAuth();

  const [disableSubmit, setDisableSubmit] = useState(true);

  const onLogin = () => {
    oktaAuth.signInWithRedirect({ originalUri: '/admin' });
  };

  useEffect(() => {
    if (failedLoginAttempts > 2) {
      captchaRef.current.reset();
      setDisableSubmit(true);
    }
  }, [failedLoginAttempts]);

  const onFormSubmit = async (values: { email: string; password: string; }) => {
    try {
      await dispatch(Login(values)).unwrap();
      navigate('/projects');
    } catch (error) {
      if (error.message) {
        return { [FORM_ERROR]: error.message };
      }
    }
  };

  useEffect(() => {
    const currentLocation = history.location.pathname;
    if (currentLocation === ROOT_ROUTE) {
      clearLocalStorage();
      clearSessionStorage();
    }
  }, []);

  return (
    <Box>
      <Typography sx={styles.header} variant="h1">
        {_ADMIN_ ? 'Airworks Admin Portal' : 'Log in to AirWorks'}
      </Typography>
      {!_ADMIN_ && (
        <Typography sx={styles.header} variant="h5">
          Don’t have an AirWorks account?
          {' '}
          <Link
            component={RouterLink}
            sx={styles.forgotText}
            to="/signUp"
          >
            Sign up now.
          </Link>
        </Typography>
      )}
      {_ADMIN_ ? (
        <Box sx={styles.buttonWrapper}>
          <Button
            sx={styles.buttonOkta}
            onClick={onLogin}
          >
            Click here for Okta login
          </Button>
        </Box>
      ) : (
        <Form
          onSubmit={onFormSubmit}
          render={({ hasSubmitErrors, submitting, modifiedSinceLastSubmit, handleSubmit, submitError }) => (
            <form
              style={styles.form}
              onSubmit={handleSubmit}
            >
              <TextField
                name="email"
                label="Email Address"
                autoComplete="email"
                autoFocus
                validate={Validators.composeValidators(Validators.required, Validators.email)}
              />
              <TextField
                name="password"
                label="Password"
                type="password"
                validate={Validators.composeValidators(Validators.required, Validators.minLength7)}
              />
              <Typography variant="caption">
                <Link component={RouterLink} sx={styles.forgotText} to="/forgot">Forgot Password?</Link>
              </Typography>
              <Collapse in={Boolean((submitError && !modifiedSinceLastSubmit))}>
                <Typography variant="body1" sx={styles.errorMessage}>
                  {submitError}
                </Typography>
              </Collapse>
              {failedLoginAttempts > 2 && (
                <Box sx={styles.captcha}>
                  <ReCAPTCHA
                    sitekey={_GOOGLE_CAPTCHA_SITE_KEY_}
                    onChange={() => setDisableSubmit(false)}
                    ref={captchaRef}
                  />
                </Box>
              )}
              <Box sx={styles.buttonWrapper}>
                <Button
                  type="submit"
                  variant="outlined"
                  sx={styles.button}
                  disabled={(hasSubmitErrors && !modifiedSinceLastSubmit) || submitting || (failedLoginAttempts > 2 ? disableSubmit : false)}
                >
                  <Fade in={submitting}><CircularProgress size={24} sx={styles.buttonProgress} /></Fade>
                  <Fade in={!submitting}><span>Sign in</span></Fade>
                </Button>
              </Box>
            </form>
          )}
        />
      )}
    </Box>
  );
};

export default LoginForm;
