// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Dialog, DialogActions, DialogContent, Button, Typography } from '@mui/material/';
import { useTheme } from '@mui/system';
import stylesDef from './styles';

interface ComponentProps {
  showDialog: boolean;
  onOk: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onCancel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  title: string;
  contentText: string;
  cancelButtonText: string;
  okButtonText: string;
}

export const ConfirmProceedDialog: React.FC<ComponentProps> = ({ showDialog, onOk, onCancel, title, contentText, cancelButtonText, okButtonText }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);

  return (
    <Dialog
      open={showDialog}
      onClose={onCancel}
    >
      <Typography variant="h3" sx={styles.title}>{title}</Typography>
      <DialogContent><Typography>{contentText}</Typography></DialogContent>
      <DialogActions sx={styles.buttons}>
        <Button sx={styles.cancelButton} onClick={onCancel}>{cancelButtonText}</Button>
        <Button sx={styles.okButton} onClick={onOk}>{okButtonText}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmProceedDialog;
