// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import HelpIcon from '@mui/icons-material/Help';
import { MenuItem, IconButton, Popper, Paper, ClickAwayListener, MenuList, Fade } from '@mui/material';
import { useAppSelector } from 'Hooks';

export const HelpMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuClose = () => setAnchorEl(null);
  const { expressAISubscription } = useAppSelector(
    (state) => state.subscription,
  );
  const isMenuOpen = Boolean(anchorEl);
  const clickOpenPopper = (e: any) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
    e.preventDefault();
  };
  const openTAC = () => {
    window.open('https://airworks.io/legal-tos-app/', '_tab');
  };
  const openPrivacyPolicy = () => {
    window.open('https://airworks.io/legal-privacy/', '_tab');
  };
  const openKnowledgeBase = () => {
    window.open('http://support.airworks.io/', '_tab');
  };
  const openJiraSupport = () => {
    window.open('https://airworksus.atlassian.net/servicedesk/customer/portal/3', '_tab');
  };
  const openExpressAIGuide = () => {
    window.open('https://info.airworks.io/airworks-express-faqs', '_tab');
  };
  return (
    <>
      <IconButton onClick={clickOpenPopper} aria-label="Help">
        <HelpIcon />
      </IconButton>
      <Popper style={{ zIndex: 9999 }} open={isMenuOpen} anchorEl={anchorEl} transition>
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleMenuClose}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <MenuList>
                  <MenuItem onClick={() => openKnowledgeBase()}>Knowledge Base</MenuItem>
                  { expressAISubscription && Object.keys(expressAISubscription).length > 0 && <MenuItem onClick={() => openExpressAIGuide()}>AirWorks Express Hub</MenuItem> }
                  <MenuItem onClick={() => openJiraSupport()}>Customer Support</MenuItem>
                  <MenuItem onClick={() => openTAC()}>Terms & Conditions</MenuItem>
                  <MenuItem onClick={() => openPrivacyPolicy()}>Privacy Policy</MenuItem>
                </MenuList>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default HelpMenu;
