// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import {
  MAP_LAYERS_POLLING_INTERVAL_IN_MILLISECONDS,
  MAP_HIGH_RES_TILES_POLLING_INTERVAL_IN_MILLISECONDS,
} from 'Config';
import useInterval from 'Components/hooks/useInterval';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { GetRasterTilesThunk, GetVectorTilesThunk, CheckHighResolutionRasterThunk, GetLasBBoxThunk } from '../mapCommonThunk';
import { SetDelayInRasterTileLoadAction } from '../mapCommonActions';

interface ComponentProps {
  showLayers: boolean;
}

const MapLayers: React.FC<ComponentProps> = ({ showLayers }) => {
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.project.project);
  const allRasterTilesLoaded = useAppSelector((state) => state.map.common.allRasterTilesLoaded);
  const vectorTileJsonLoading = useAppSelector((state) => state.map.common.vectorTileJsonLoading);
  const lowResCreatedAt = useAppSelector((state) => state.map.common.lowResCreatedAt);
  const delayInRasterTileLoadAcknowledged = useAppSelector((state) => state.map.common.delayInRasterTileLoadAcknowledged);
  const rasterTileJson = useAppSelector((state) => state.map.common.rasterTileJson);
  const rasterTileJsonLoading = useAppSelector((state) => state.map.common.rasterTileJsonLoading);
  const lasBBox = useAppSelector((state) => state.map.common.lasBBox);
  const lasBBoxLoading = useAppSelector((state) => state.map.common.lasBBoxLoading);

  const tif = project?.tifFiles?.length > 0;

  const vectorLoading = vectorTileJsonLoading;
  const rasterLoading = rasterTileJsonLoading;

  const loadRaster = async () => {
    if ((!rasterTileJson) && tif && rasterLoading) {
      await dispatch(GetRasterTilesThunk());
    }
  };

  const loadLasBBox = async () => {
    if (lasBBoxLoading || !lasBBox) {
      await dispatch(GetLasBBoxThunk());
    }
  };

  const checkHighResolutionRaster = async () => {
    if (!allRasterTilesLoaded) {
      if (rasterTileJson && lowResCreatedAt) {
        await dispatch(CheckHighResolutionRasterThunk());
      }
      const utcTimeNow = moment.utc();
      if (lowResCreatedAt && utcTimeNow.diff(lowResCreatedAt, 'minutes') > 20 && !delayInRasterTileLoadAcknowledged) {
        dispatch(SetDelayInRasterTileLoadAction(true));
      }
    }
  };

  const loadVector = async () => {
    if (showLayers && vectorLoading) {
      await dispatch(GetVectorTilesThunk());
    }
  };

  useInterval(() => {
    loadRaster();
    loadVector();
    loadLasBBox();
  }, MAP_LAYERS_POLLING_INTERVAL_IN_MILLISECONDS);

  useInterval(() => {
    checkHighResolutionRaster();
  }, MAP_HIGH_RES_TILES_POLLING_INTERVAL_IN_MILLISECONDS);

  return null;
};

export default MapLayers;
