// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  dialog: {
    minWidth: '600px',
    margin: 'auto',
  },
  dialogBox: {
    padding: theme.spacing(3),
  },
  dialogActions: {
    padding: '8px 0',
  },
  icon: {
    marginBottom: theme.spacing(1),
  },
});

export default styles;
