// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { useAppSelector } from 'Hooks';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateOutlet = () => {
  const { user } = useAppSelector(
    (state) => state.auth,
  );

  return user ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateOutlet;
