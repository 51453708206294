// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Fade } from '@mui/material';
import { useTheme } from '@mui/system';
import { Form, FormSpy } from 'react-final-form';
import { Helpers } from 'Components/forms';
import { useAppDispatch } from 'Hooks';
import CreditCardControls from '../../CreditCardControls';
import stylesDef from './styles';
import { EditCreditCardThunk } from 'Features/account/subscriptionThunk';
import { GetTaxThunk } from 'Features/order/orderThunk';

interface ComponentProps {
  card: ICreditCard;
  onCancel: () => void;
  setTotalTax: (arg0: number) => void;
  additonalCharges?: number;
}

export const CardForm: React.FC<ComponentProps> = ({ card, onCancel, additonalCharges, setTotalTax }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const [formState, setFormState] = useState(null);
  const initialValues = card;

  useEffect(() => {
    if (formState?.submitSucceeded) {
      onCancel();
    }
  }, [formState]);

  const dispatch = useAppDispatch();
  const editCreditCard = (payload: ICreditCard) => dispatch(EditCreditCardThunk(payload));

  const onFormSubmit = async (values: any) => {
    await Helpers.submit(values, editCreditCard);
    if(additonalCharges > 0){
      const result = await dispatch(GetTaxThunk(additonalCharges));
      if (result.success) {
        setTotalTax(result.salesTaxAmount);
      }
    }
  };

  return (
    <Form
      onSubmit={onFormSubmit}
      keepDirtyOnReinitialize
      initialValues={initialValues}
      render={({ hasSubmitErrors, modifiedSinceLastSubmit, submitting, pristine, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormSpy onChange={(state) => {
            setFormState(state);
          }}
          />
          <CreditCardControls editable initialValues={initialValues} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={pristine || (hasSubmitErrors && !modifiedSinceLastSubmit) || submitting}
            sx={styles.submitButton}
          >
            <Fade in={submitting}>
              <CircularProgress size={24} sx={styles.buttonProgress} />
            </Fade>
            <Fade in={!submitting}>
              <span>Edit credit card</span>
            </Fade>
          </Button>
          <Button variant="outlined" onClick={onCancel}>Cancel</Button>
        </form>
      )}
    />
  );
};

export default CardForm;
