// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Box, css, useTheme } from '@mui/system';
import { CSVLink } from 'react-csv';
import { Typography, Table, TableHead, TableRow, TableCell, TableBody, Button, Tooltip, Divider } from '@mui/material';
import downloadIcon from 'Assets/download-icon.png';
import { pricingDescription } from 'Utils/constants';
import history from 'Utils/history';
import { ADMIN_ROUTE } from 'Utils/routes';
import stylesDef from './styles';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { GetPricingThunk } from '../adminThunk';
import { useNavigate } from 'react-router-dom';


export const PricingTiers = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [csvData, setCsvData] = useState([]);
  const [csvFileName, setCsvFileName] = useState('pricing.csv');
  const [csvHeaders] = useState([
    { label: 'Org Name', key: 'orgName' },
    { label: 'Org ID', key: 'orgId' },
    { label: 'Plan Start Date', key: 'planStartDate' },
    { label: 'Plan End Date', key: 'planEndDate' },
    { label: 'Lower Limit', key: 'lowerLimit' },
    { label: 'Upper Limit', key: 'upperLimit' },
    { label: 'Current Usage', key: 'currentUsage' },
    { label: 'Price Per Credit', key: 'pricePerCredit' },
    { label: 'Plan Name', key: 'planName' },
    { label: '12 month usage', key: 'twelveMonthUsage' },
    { label: 'Starting Credits', key: 'startingCredits' },
  ]);
  const { pricing } = useAppSelector((state) => state.admin);
  const { pricingAccess } = useAppSelector((state) => state.auth.user);

  useEffect(() => {
    const onLoad = async () => {
      await dispatch(GetPricingThunk());
    };
    if (pricingAccess) {
      onLoad();
    } else {
      navigate(ADMIN_ROUTE);
    }
  }, []);

  const downloadCSV = (data: any) => {
    const { planName, pricePerCredit, lowerLimit, upperLimit, orgs } = data;
    const dateString = moment(new Date()).format('YYYY-MM-DD');
    if (planName) {
      setCsvFileName(`${planName}_UpperLimit${upperLimit}_${dateString}.csv`);
      setCsvData(orgs.map((org: {}) => ({
        ...org,
        planName,
        pricePerCredit,
        lowerLimit,
        upperLimit,
      })));
    } else {
      const formattedData: {}[] = [];
      pricing.forEach((tier) => {
        Object.keys(tier.plans).forEach((name) => {
          tier.plans[name].orgs?.forEach((org: {}) => {
            formattedData.push({
              ...org,
              lowerLimit: tier.lowerLimit,
              upperLimit: tier.upperLimit,
              pricePerCredit: tier.plans[name]?.rate,
              planName: name,
            });
          });
        });
      });
      setCsvFileName(`All_Org_Details_${dateString}.csv`);
      setCsvData(formattedData);
    }
  };

  return (
    <Box sx={styles.pageWrapper}>
      <Box sx={styles.header}>
        <Typography variant="h2">Pricing Tiers</Typography>
        <CSVLink
          data={csvData}
          headers={csvHeaders}
          filename={csvFileName}
          onClick={() => downloadCSV({})}
        >
          <Button variant="contained" color="primary">
            DOWNLOAD ALL ORG DETAILS
          </Button>
        </CSVLink>
      </Box>
      <Divider sx={styles.divider} />
      <Table aria-label="pricing table">
        <TableHead>
          <TableRow>
            <TableCell sx={styles.headCell}>lower limit</TableCell>
            <TableCell sx={styles.headCell}>upper limit</TableCell>
            {pricingDescription.map((plan, index) => (
               <TableCell align="right" key={plan.name}>
               <Box sx={styles.planNameContainer}>
                 <span sx={styles.planName}>{plan.name?.split('_').join(' ')}</span>
                 <span
                    css={css`
                    ${styles.planDescription};
                    ${index < 2 && styles.grey};
                    ${index > 1 && styles.green};
                    `}
                    >
                    {plan.description}
                  </span>
               </Box>
             </TableCell>
           ))}
         </TableRow>
        </TableHead>
        <TableBody>
          {pricing.map((tier) => (
            <TableRow key={tier.upperLimit}>
              <TableCell sx={styles.cellText} component="th" scope="row">
                {tier.lowerLimit}
              </TableCell>
              <TableCell sx={styles.cellText} component="th" scope="row">
                {tier.upperLimit}
              </TableCell>
              {pricingDescription.map((plan) => (
                <TableCell sx={styles.cellText} align="right" key={plan.name}>
                  <Box sx={styles.priceContainer}>
                    {tier.plans[plan.name] ? `$${tier.plans[plan.name].rate?.toFixed(2)}` : '-'}
                    {tier.plans[plan.name]?.orgs?.length > 0 && (
                       <CSVLink
                         data={csvData}
                         headers={csvHeaders}
                         filename={csvFileName}
                         onClick={() => {
                           downloadCSV({
                             planName: plan.name,
                             pricePerCredit: tier.plans[plan.name].rate,
                             lowerLimit: tier.lowerLimit,
                             upperLimit: tier.upperLimit,
                             orgs: tier.plans[plan.name]?.orgs,
                           });
                         }}
                       >
                         <img src={downloadIcon} alt="Export Customers List" css={styles.downloadIcon} title="Export Customers List"/>
                       </CSVLink>
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box sx={styles.end} />
    </Box>
  );
};

export default PricingTiers;
