// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  root: {
    backgroundColor: '#797979',
    zIndex: 1,
    color: '#fff',
    width: 20,
    height: 20,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  stepActive: {
    backgroundImage:
      'linear-gradient(90deg, #9038F5 0%, #3C34B7 33.29%, #2075FE 74.06%, #01A1DD 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  stepCompleted: {
    backgroundImage:
      'linear-gradient(90deg, #9038F5 0%, #3C34B7 33.29%, #2075FE 74.06%, #01A1DD 100%)',
  },
});

export default styles;
