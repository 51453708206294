// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  wrapper: {
    position: 'relative',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
    border: '1px transparent solid',
  },
  primary: {
    borderColor: theme.palette.primary.main,
  },
});

export default styles;
