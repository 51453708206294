// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { Box, useTheme } from '@mui/system';
import { Typography, FormControlLabel, Checkbox, Button, CircularProgress } from '@mui/material';
import Tooltip from 'Components/Tooltip';
import { useFlags } from 'launchdarkly-react-client-sdk';
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import rasterizeIcon from 'Assets/rasterize.png';
import ConfirmProceedDialog from 'Components/ConfirmProceedDialog';
import moment from 'moment-timezone';
import Countdown from 'react-countdown';
import { GetProjectThunk, RejectRasterizationThunk } from 'Features/project/projectThunk';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { PlaceOrderThunk } from 'Features/order/orderThunk';
import { SetPlaceOrderSuccessAction } from 'Features/order/orderActions';
import { SetRasterizationSelectedAction } from 'Features/project/projectActions';
import { RootState } from 'Store';
import { getOrders } from 'Features/order/orderSelectors';
import stylesDef from './styles';

moment.tz.setDefault('America/New_York');

interface RasterizationRequestInfoProps {
  tifFiles: {
    name: string;
    _id: string;
    epsg: number;
  }[],
  lasFiles: {
    name: string;
    _id: string;
    epsg: number;
  }[],
}

export const RasterizationRequestInfo = ({ tifFiles, lasFiles }: RasterizationRequestInfoProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const rasterizationFlag = useFlags().rasterization;
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { project, rasterizationSelected } = useAppSelector((state: RootState) => state.project);
  const { imageryRequest, rasterRequest } = project || {};
  const { activeOrder, projectOrders, placedOrder } = useAppSelector((state: RootState) => getOrders(state));
  const activeOrderKml = activeOrder?.boundaryFile;
  const orderNumber = projectOrders?.length;

  const onCancelOrder = async () => {
    await dispatch(RejectRasterizationThunk());
    setShowCancelDialog(false);
  };

  const submitOrder = async () => {
    setIsSubmitting(true);
    await dispatch(PlaceOrderThunk(undefined, undefined, undefined, true));
    await dispatch(GetProjectThunk());
    dispatch(SetRasterizationSelectedAction(false));
    setIsSubmitting(false);
    dispatch(SetPlaceOrderSuccessAction(false));
  };

  return (
    <>
      {!imageryRequest && !rasterRequest && rasterizationFlag && !tifFiles?.length && lasFiles?.length > 0 && (
        <Box sx={styles.rasterizeGrid}>
          <img style={styles.rasterizeIcon} src={rasterizeIcon} alt="rasterize point cloud" />
          <Box>
            <Box sx={styles.rasterizeTextContainer}>
              <Typography sx={styles.rasterizeText}>
                Need an orthomosaic generated?
              </Typography>
              <Tooltip
                placement="top"
                title={
                  (
                    <Box>
                      Point cloud rasterization has a cost of
                      <span style={styles.bold}> 5 Credits</span>
                      . Learn more in our
                      <a href="https://awkb.scrollhelp.site/airworksknowledgebase/coordinate-reference-system-crs" target="_blank" rel="noreferrer"> Knowledge Base</a>
                      .
                    </Box>
                  )
                }
              >
                <HelpIcon sx={styles.helpIconSmall} />
              </Tooltip>
            </Box>
            <FormControlLabel
              control={
                <Checkbox checked={rasterizationSelected} sx={styles.checkbox} onChange={() => dispatch(SetRasterizationSelectedAction(!rasterizationSelected))} />
              }
              label="Rasterize my point cloud"
              sx={styles.checkboxContainer}
            />
            <Typography sx={styles.rasterizeNote}>Raster will be created from all LAS/LAZ files submitted</Typography>
          </Box>
        </Box>
      )}
      {rasterRequest && !rasterRequest.notificationSent && !rasterRequest.rejected && !rasterRequest.orderPlaced && (
        <Box sx={styles.cardContainer}>
          <Typography sx={styles.cardTitle}>
            <InfoIcon sx={styles.icon} />
            Ortho generation in progress
          </Typography>
          <Typography sx={styles.cardText}>
            Your .tif will be added here soon!
          </Typography>
          <Button sx={styles.cancelButton1} onClick={() => setShowCancelDialog(true)}>
            Cancel
          </Button>
        </Box>
      )}
      {rasterRequest?.notificationSent && !rasterRequest.rejected && !rasterRequest.orderPlaced && (
        <Box sx={styles.cardContainer}>
          <Typography sx={styles.cardTitle}>
            <InfoIcon sx={styles.icon} />
            Order Placed!
          </Typography>
          <Typography sx={styles.cardText}>
            Your project’s order will auto-submit in:
          </Typography>
          <Box sx={styles.timerContainer}>
            <AccessTimeIcon sx={styles.icon} fontSize="small" />
            <Typography>
              <Countdown
                date={moment.utc(rasterRequest.notificationSent).local().add(1, 'd').format('YYYY-MM-DD HH:mm:ss')}
                renderer={(props: any) => (
                  <span style={styles.timer}>
                    {props.hours}
                    :
                    {props.minutes}
                    :
                    {props.seconds}
                  </span>
                )}
              />
            </Typography>
          </Box>
          <Box sx={styles.buttonContainer}>
            <Button sx={styles.cancelButton2} onClick={() => setShowCancelDialog(true)}>
              Cancel
            </Button>
            <Button onClick={submitOrder} sx={styles.submitButton} disabled={isSubmitting}>
              {isSubmitting ? <CircularProgress size={24} /> : <span>Submit Now</span>}
            </Button>
          </Box>
        </Box>
      )}
      {rasterRequest?.orderPlaced && orderNumber < 3 && !activeOrderKml && (
        <Box sx={styles.cardContainer}>
          <Typography sx={styles.cardTitle}>
            <InfoIcon sx={styles.icon} />
            Order Placed!
          </Typography>
          <Typography sx={styles.cardText}>
            Your order was placed on
            {' '}
            <span style={styles.bold}>{moment(rasterRequest?.orderPlaced).format('MMMM D, YYYY')}</span>
            .
          </Typography>
          <Typography sx={styles.cardText}>
            Your deliverables will be available on
            {' '}
            <span style={styles.bold}>{moment(placedOrder?.deliveryEstimate).format('MMMM D, YYYY')}</span>
            .
          </Typography>
        </Box>
      )}
      <ConfirmProceedDialog
        title="Cancel Order"
        contentText="AirWorks will not proceed with your order. Are you sure you want to cancel?"
        showDialog={showCancelDialog}
        cancelButtonText="DISMISS"
        okButtonText="CANCEL ORDER"
        onOk={onCancelOrder}
        onCancel={() => setShowCancelDialog(false)}
      />
    </>
  );
};

export default RasterizationRequestInfo;
