// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Link, useLocation, matchPath } from 'react-router-dom';
import { Tabs, Tab } from '@mui/material';
import {
  ACCOUNT_GENERAL_ROUTE, ACCOUNT_BILLING_ROUTE, ACCOUNT_PASSWORD_ROUTE, ORDER_HISTORY_ROUTE, ACCOUNT_PLAN_USAGE, USER_MANAGEMENT_ROUTE, TEAMS_ROUTE, CAD_TEMPLATES_ROUTE, GetTemplatesRoute,
} from 'Utils/routes';
import stylesDef from './styles';
import { useAppSelector } from 'Hooks';

export const AccountToolbar = () => {
  const styles = stylesDef();
  const location = useLocation();
  const currentPath = location.pathname;

  const { resources } = useAppSelector((state) => state.auth);
  const { org } = useAppSelector((state) => state.account);
  const organizationAccess = !_ADMIN_ && resources?.organizationAccess;
  const billing = !_ADMIN_ && resources?.billing;
  const processingUsage = !_ADMIN_ && resources?.processingUsage;
  const cadTemplates = !_ADMIN_ && resources?.cadTemplates;
  const placeOrder = !_ADMIN_ && resources?.placeOrder;

  function getActiveTabId(pathname: string) {
    if (matchPath({ path: ACCOUNT_GENERAL_ROUTE }, pathname)) return 1;
    if (matchPath({ path: CAD_TEMPLATES_ROUTE }, pathname)) return 2;
    if (matchPath({ path: ACCOUNT_BILLING_ROUTE }, pathname)) return 3;
    if (matchPath({ path: ACCOUNT_PASSWORD_ROUTE }, pathname)) return 4;
    if (matchPath({ path: ORDER_HISTORY_ROUTE }, pathname)) return 5;
    if (matchPath({ path: ACCOUNT_PLAN_USAGE }, pathname)) return 6;
    if (matchPath({ path: USER_MANAGEMENT_ROUTE }, pathname)) return 7;
    if (matchPath({ path: TEAMS_ROUTE }, pathname)) return 8;
    return null;
  }

  const cadTemplatesRoute = GetTemplatesRoute(org.defaultTemplate);
  const activeTabId = getActiveTabId(currentPath);

  return (
    <Tabs indicatorColor="primary" value={activeTabId}>
      <Tab
        component={Link}
        label="General"
        value={1}
        to={ACCOUNT_GENERAL_ROUTE}
        sx={styles.tabLabel}
      />
      {cadTemplates && (
        <Tab
          component={Link}
          label="CAD Templates"
          value={2}
          to={cadTemplatesRoute}
          sx={styles.tabLabel}
        />
      )}
      {billing && (
        <Tab
          component={Link}
          label="Subscription"
          value={3}
          to={ACCOUNT_BILLING_ROUTE}
          sx={styles.tabLabel}
        />
      )}
      <Tab
        component={Link}
        label="Password"
        value={4}
        to={ACCOUNT_PASSWORD_ROUTE}
        sx={styles.tabLabel}
      />
      {placeOrder && (
        <Tab
          component={Link}
          label="Order History"
          value={5}
          to={ORDER_HISTORY_ROUTE}
          sx={styles.tabLabel}
        />
      )}
      {processingUsage && (
        <Tab
          component={Link}
          label="Plan Usage"
          value={6}
          to={ACCOUNT_PLAN_USAGE}
          sx={styles.tabLabel}
        />
      )}
      {organizationAccess && (
        <Tab
          component={Link}
          label="User Management"
          value={7}
          to={USER_MANAGEMENT_ROUTE}
          sx={styles.tabLabel}
        />
      )}
      {organizationAccess && (
        <Tab
          component={Link}
          label="Teams"
          value={8}
          to={TEAMS_ROUTE}
          sx={styles.tabLabel}
        />
      )}
    </Tabs>
  );
};

export default AccountToolbar;
