// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dispatch } from 'redux';
import { getJson, patchJson } from 'Utils/http';
import { API_URL } from 'Config';
import { RootState } from 'Store';
import { GetGlobalStatusFailedAction, GetGlobalStatusSuccessAction, GetOpsCreditCapacityFailedAction, GetOpsCreditCapacitySuccessAction, UpdateGlobalStatusFailureAction, UpdateGlobalStatusStartAction, UpdateGlobalStatusSuccessAction } from './globalStatusActions';

export const GetGlobalStatusThunk = () => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const { token } = getState().auth;
  const url = `${API_URL}/globalStatus`;

  const { success, message, data } = await getJson<IGlobalStatusResponse>(url, token);
  if (!success) {
    dispatch(GetGlobalStatusFailedAction(message));
    return {};
  }

  dispatch(GetGlobalStatusSuccessAction(data.globalStatus));

  return data.globalStatus;
};

export const GetOpsCreditCapacityThunk = () => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const { token } = getState().auth;
  const url = `${API_URL}/globalStatus/dailyCreditCommitment`;

  const response = await getJson<IOpsCapacityResponse>(url, token);

  if (!response.success) {
    dispatch(GetOpsCreditCapacityFailedAction(response.message));
    return;
  }

  dispatch(GetOpsCreditCapacitySuccessAction(response.data.creditsPerDay));
};

export const UpdateGlobalStatusThunk = (allowExpeditedProcess: boolean) => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const { token } = getState().auth;
  const url = `${API_URL}/globalStatus`;

  dispatch(UpdateGlobalStatusStartAction());

  const { success, message, data } = await patchJson<IGlobalStatusResponse>(url, {
    allowExpeditedProcess,
  }, token);

  if (!success) {
    dispatch(UpdateGlobalStatusFailureAction(message));
    return;
  }

  dispatch(UpdateGlobalStatusSuccessAction(data.globalStatus));
};
