// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createSlice } from '@reduxjs/toolkit';
import {
  SetOrderStatusAction,
  GetOrdersStartAction,
  GetOrdersSuccessAction,
  GetOrdersFailureAction,
  CreateOrderStartAction,
  CreateOrderSuccessAction,
  CreateOrderFailureAction,
  SetOrderIdAction,
  SetOutputLasAction,
  UpdateOrderLasFilesAction,
  SetFileVersionAction,
  SaveFileDownloadStatusAction,
  SaveGeoJsonDownloadStatusAction,
  EditOrderSuccessAction,
  UpdateOrderAction,
  SetOrderLoadingAction,
  SetPlaceOrderSuccessAction,
  SetShowAdjustmentMessageAction,
  UpdateOrderCadFilesAction,
  UpdateOrderStatusSuccessAction,
  UpdateOrderGeoDbFilesAction,
  UpdateOrderSurfaceFilesAction,
  DownloadFileStartAction,
  DownloadFileSuccessAction,
  DeleteOutputLasFileSuccessAction,
  DeleteSurfaceFileSuccessAction,
  DeleteDeliverableFileSuccessAction,
  DeleteGeoDbFileSuccessAction,
  DeleteDxfFileSuccessAction,
  CreditAdjustmentSuccessAction,
  UpdateOrderDeliverableFilesAction,
  SetTaxDetailsAction,
} from './orderActions';

const initialState: IOrderState = {
  list: [],
  fileVersions: {},
  orderId: null,
  outputLas: false,
  deleting: false,
  loading: false,
  fileLoading: false,
  orderStatus: {},
  orderEstimate: [],
  placeOrderSuccess: false,
  dxfFileStatus: [],
  geoJsonFileStatus: [],
  anyChangeInFileStatuses: false,
  showAdjustmentError: false,
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SetOrderStatusAction, (state, action) => {
        state.orderEstimate = action.payload.orderEstimate;
        state.orderStatus = action.payload.orderStatus;
      })
      .addCase(SetOrderIdAction, (state, action) => {
        state.orderId = action.payload;
      })
      .addCase(GetOrdersStartAction, (state) => {
        state.loading = true;
      })
      .addCase(GetOrdersSuccessAction, (state, action) => {
        state.loading = false;
        state.list = action.payload;
      })
      .addCase(GetOrdersFailureAction, (state) => {
        state.loading = false;
      })
      .addCase(SetOutputLasAction, (state, action) => {
        state.outputLas = action.payload;
      })
      .addCase(CreateOrderStartAction, (state) => {
        state.loading = true;
      })
      .addCase(CreateOrderSuccessAction, (state, action) => {
        state.loading = false;
        state.list.push(action.payload);
      })
      .addCase(CreateOrderFailureAction, (state) => {
        state.loading = false;
      })
      .addCase(EditOrderSuccessAction, (state, action) => {
        const index = state.list.findIndex((o) => o._id === action.payload._id);
        const x = state.list[index];
        state.list[index] = Object.assign(x, action.payload);
      })
      .addCase(SetTaxDetailsAction, (state, action) => {
        const index = state.list.findIndex((o) => o._id === action.payload._id);
        if (index !== -1) {
          state.list[index].salesTaxAmount = action.payload.salesTaxAmount;
          state.list[index].salesTaxRate = action.payload.salesTaxRate;
        }
      })
      .addCase(UpdateOrderLasFilesAction, (state, action) => {
        const order = state.list.find((o) => o._id === action.payload.ownerOrder);
        order.outputLasFiles.push(action.payload);
      })
      .addCase(SetFileVersionAction, (state, action) => {
        state.fileVersions = action.payload;
      })
      .addCase(UpdateOrderAction, (state, action) => {
        const order = state.list.find((o) => o._id === action.payload._id);
        if (order.updatedAt <= action.payload.updatedAt) {
          const index = state.list.findIndex((o) => o._id === action.payload._id);
          const x = state.list[index];
          Object.assign(x, action.payload);
        }
      })
      .addCase(DeleteOutputLasFileSuccessAction, (state, action) => {
        const order = state.list.find((o) => o._id === action.payload.orderId);
        order.outputLasFiles = order.outputLasFiles.filter((f) => f._id !== action.payload.lasFileId);
      })
      .addCase(DeleteSurfaceFileSuccessAction, (state, action) => {
        const order = state.list.find((o) => o._id === action.payload.orderId);
        order.surfaceFiles = order.surfaceFiles.filter((f) => f._id !== action.payload.surfaceFileId);
      })
      .addCase(DeleteDeliverableFileSuccessAction, (state, action) => {
        const order = state.list.find((o) => o._id === action.payload.orderId);
        order.deliverableFiles = order.deliverableFiles.filter((f) => f._id !== action.payload.deliverableFileId);
      })
      .addCase(DeleteGeoDbFileSuccessAction, (state, action) => {
        const order = state.list.find((o) => o._id === action.payload.orderId);
        order.geoDbFiles = order.geoDbFiles.filter((f) => f._id !== action.payload.geoDbFileId);
      })
      .addCase(DeleteDxfFileSuccessAction, (state, action) => {
        const order = state.list.find((o) => o._id === action.payload.orderId);
        order.cadFiles = order.cadFiles.filter((f) => f._id !== action.payload.dxfFileId);
      })
      .addCase(DownloadFileStartAction, (state) => { state.fileLoading = true; })
      .addCase(DownloadFileSuccessAction, (state) => { state.fileLoading = false; })
      .addCase(SaveFileDownloadStatusAction, (state, action) => {
        if (action.payload.fileReady === true) {
          state.dxfFileStatus = action.payload.downloadStatus;
          state.anyChangeInFileStatuses = !state.anyChangeInFileStatuses;
        } else {
          state.dxfFileStatus.push(action.payload.downloadStatus);
          state.anyChangeInFileStatuses = true;
        }
      })
      .addCase(SaveGeoJsonDownloadStatusAction, (state, action) => {
        if (action.payload.fileReady === true) {
          state.geoJsonFileStatus = action.payload.downloadGeoJsonStatus;
          state.anyChangeInFileStatuses = !state.anyChangeInFileStatuses;
        } else {
          state.geoJsonFileStatus.push(action.payload.downloadGeoJsonStatus);
          state.anyChangeInFileStatuses = true;
        }
      })
      .addCase(UpdateOrderStatusSuccessAction, (state, action) => {
        const index = state.list.findIndex((o) => o._id === action.payload._id);
        state.list[index].orderStatus = action.payload.orderStatus;
      })
      .addCase(SetOrderLoadingAction, (state, action) => {
        state.loading = action.payload;
      })
      .addCase(SetPlaceOrderSuccessAction, (state, action) => {
        state.placeOrderSuccess = action.payload;
      })
      .addCase(SetShowAdjustmentMessageAction, (state, action) => {
        state.showAdjustmentError = action.payload;
      })
      .addCase(UpdateOrderGeoDbFilesAction, (state, action) => {
        const order = state.list.find((o) => o._id === action.payload.ownerOrder);
        order.geoDbFiles.push(action.payload);
      })
      .addCase(UpdateOrderSurfaceFilesAction, (state, action) => {
        const order = state.list.find((o) => o._id === action.payload.ownerOrder);
        order.surfaceFiles.push(action.payload);
      })
      .addCase(UpdateOrderCadFilesAction, (state, action) => {
        const index = state.list.findIndex((o) => o._id === action.payload.ownerOrder);
        const order = state.list[index];
        const cadfileIndex = order.cadFiles.findIndex((f) => f._id === action.payload._id);
        const cadFile = order.cadFiles[cadfileIndex];
        if (cadfileIndex >= 0) {
          order.cadFiles[cadfileIndex] = Object.assign(cadFile, action.payload);
        } else {
          order.cadFiles.push(action.payload);
        }
      })
      .addCase(UpdateOrderDeliverableFilesAction, (state, action) => {
        const order = state.list.find((o) => o._id === action.payload.ownerOrder);
        order.deliverableFiles.push(action.payload);
      })
      .addCase(CreditAdjustmentSuccessAction, (state, action) => {
        const orderIndex = state.list.findIndex((o) => o._id === action.payload._id);
        const x = state.list[orderIndex];
        Object.assign(x, action.payload);
      });
  },
});

export const { reducer } = orderSlice;

export default reducer;
