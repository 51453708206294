// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  header: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  pageWrapper: {
    marginTop: '30px',
    width: '100%',
    padding: '0 80px',
  },
  headCell: {
    color: '#48515b',
    textTransform: 'uppercase',
    verticalAlign: 'top',
    fontSize: '16px',
  },
  planName: {
    color: '#48515b',
    textTransform: 'uppercase',
    fontSize: '16px',
  },
  planDescription: {
    display: 'flex',
    justifyContent: 'center',
    color: '#344054',
    borderRadius: '30px',
    fontSize: '12px',
    width: 'fit-content',
    padding: '0 10px',
  },
  grey: {
    background: '#F2F4F7',
  },
  green: {
    background: '#D1FADF',
  },
  planNameContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
  },
  end: {
    height: '50px',
  },
  cellText: {
    color: '#48515b',
    fontWeight: 400,
    fontSize: '16px',
  },
  priceContainer: {
    display: 'grid',
    gridTemplateColumns: '8fr 1fr',
    justifyItems: 'end',
    alignItems: 'center',
    gap: '10px',
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.darkGrey,
    boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.1)',
    fontSize: '12px',
    borderRadius: '0px',
    padding: theme.spacing(1),
  },
  downloadIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

export default styles;
