// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import {
  Fade,
  CircularProgress,
  TextField,
  Typography,
  Button,
  Dialog, DialogActions, DialogContent,
} from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { mininumBufferSize, defaultBufferSize, minimumRoadNetworkBufferSize, defaultRoadNetworkBufferSize } from 'Utils/constants';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { GenerateKmlBufferThunk, SetLineStringUploadThunk, SetRoadNetworkThunk, GenerateRoadNetworkKmlBufferThunk } from 'Features/kml/kmlThunk';
import { useSelector } from 'react-redux';
import { getOrders } from 'Features/order/orderSelectors';
import { RootState } from 'Store';
import { SetBufferSizeAction } from 'Features/kml/kmlActions';
import stylesDef from './styles';

export const KmlBufferDialog = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();

  const { activeOrder } = useSelector((state: RootState) => getOrders(state));
  const geomType = activeOrder?.geomType;

  const lineStringKmlUpload = useAppSelector((state) => state.kml.present.lineStringKmlUpload);
  const getRoadNetwork = useAppSelector((state) => state.kml.present.getRoadNetwork);
  const bufferSize = useAppSelector((state) => state.kml.present.bufferSize);
  const creatingBuffer = useAppSelector((state) => state.kml.present.creatingBuffer);
  const creatingRoadNetworkBuffer = useAppSelector((state) => state.kml.present.creatingRoadNetworkBuffer);
  const projectId = useAppSelector((state) => state.project.projectId);

  const roadNetworkBuffer = geomType === 'Polygon';

  const defaultBuffer = roadNetworkBuffer ? defaultRoadNetworkBufferSize : defaultBufferSize;
  const minBuffer = roadNetworkBuffer ? minimumRoadNetworkBufferSize : mininumBufferSize;
  const [buffer, setBuffer] = useState(bufferSize);

  useEffect(() => {
    setBuffer(defaultBuffer);
    dispatch(SetBufferSizeAction(defaultBuffer));
  }, []);

  useEffect(() => {
    dispatch(SetBufferSizeAction(defaultBuffer));
    setBuffer(defaultBuffer);
  }, [geomType]);

  const onBufferSubmit = () => {
    let finalBufferSize = defaultBuffer;
    if (!Number.isNaN(buffer)) {
      finalBufferSize = Math.max(buffer, minBuffer);
    }

    setBuffer(finalBufferSize);
    dispatch(SetBufferSizeAction(finalBufferSize));
    if (roadNetworkBuffer) {
      window?.pendo?.track('Road Network KML buffer created', { buffer_distance: finalBufferSize?.toFixed(2), projectId });
      dispatch(GenerateRoadNetworkKmlBufferThunk());
    } else {
      window?.pendo?.track('KML buffer created', { buffer_distance: finalBufferSize?.toFixed(2), projectId });
      dispatch(GenerateKmlBufferThunk());
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBuffer(parseFloat(e.target.value));
  };

  const handleBlur = () => {
    if (buffer < minBuffer) {
      setBuffer(minBuffer);
    }
  };

  return (
    <Dialog
      open={lineStringKmlUpload || getRoadNetwork}
      onClose={() => { dispatch(SetLineStringUploadThunk(false)); }}
      sx={styles.dialog}
    >
      <Box sx={styles.dialogContent}>
        <Typography sx={styles.dialogHeader} variant="h5">
          {lineStringKmlUpload ? 'Buffer Distance' : 'Get Road Network'}
        </Typography>
        <DialogContent sx={styles.dialogText}>
          {lineStringKmlUpload && (
            <Typography>
              Please add below the buffer distance for your Right of Way. Please note AirWorks requires a minimum buffer distance of
              {` ${mininumBufferSize} feet.`}
            </Typography>
          )}
          {!lineStringKmlUpload && (
            <Box>
              <Typography>
                Now you can generate a line-string KML file representing your chosen area. Specify a buffer distance and click on Continue.
                {' '}
                Road Network data made available by
                {' '}
                <a style={styles.link} href="https://www.openstreetmap.org/copyright" target="_blank" rel="noreferrer">OpenStreetMap</a>
                {' '}
                under the Open Database License.
              </Typography>
            </Box>
          )}
          <Box sx={styles.textForm}>
            <TextField
              id="buffer"
              label="Buffer Distance"
              variant="outlined"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              inputProps={{ min: minBuffer }}
              value={buffer.toString()}
              sx={styles.textField}
            />
            <Box>
              <Typography variant="h5">ft</Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={styles.dialogActions}>
          <Button onClick={() => { lineStringKmlUpload ? dispatch(SetLineStringUploadThunk(false)) : dispatch(SetRoadNetworkThunk(false)) }}>Close</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onBufferSubmit}
          >
            <Fade in={creatingBuffer || creatingRoadNetworkBuffer}>
              <CircularProgress size={16} sx={styles.expressButtonProgress} />
            </Fade>
            <Fade in={!creatingBuffer || !creatingRoadNetworkBuffer}>
              <span>Continue</span>
            </Fade>
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default KmlBufferDialog;
