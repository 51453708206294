// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import Map, { Marker, Source, Layer } from 'react-map-gl';
import { Box, useTheme } from '@mui/system';
import PropTypes from 'prop-types';
import markerIcon from '@mapbox/maki/icons/marker.svg';
import SVG from 'react-inlinesvg';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { getOrderIds, getOrders } from 'Features/order/orderSelectors';
import { RootState } from 'Store';
import { getRasterRequestStatus } from 'Features/project/projectSelectors';
import { selectAllCoordinates, selectHoveredCoordinates } from 'Features/projectList/projectListSelector';
import { GetOrdersThunk } from 'Features/order/orderThunk';
import { LoadKmlsThunk } from 'Features/kml/kmlThunk';
import MapControls from './MapControls';
import MapRasterLayer from './MapRasterLayer';
import MapVectorLayers from './MapVectorLayers';
import MapLayersController from './MapLayersController';
import MapMeasurements from './MapMeasurements';
import MapDraw from './MapDraw';
import MapLasBBox from './MapLasBBox';
import ErrorPaper from './FileErrors';
import MapEditor from './MapEditor';
import { SetCanvasSizeAction, SetLatAction, SetLayerVisibleAction, SetLngAction, SetScreenshotAction, SetViewportAction } from './mapCommonActions';
import { InitMapThunk, ToggleNoneVectorTiles } from './mapCommonThunk';
import MapKMLLayer from './MapKMLLayer';
import MapSearchMarker from './MapSearchMarker';
import MapStyleToggle from './MapStyleToggle';
import stylesDef from './styles';
import { ResetDrawSource, ResetUpdateEntities } from './mapEditorThunk';
import { SetCurrentLayerAction, SetlayerSelectedAction } from './mapEditorActions';

// By adding this layer to the map on the admin portal, we can make sure to position the raster layer at the bottom by using the beforeId property on the raster layer.
// That way, the Kml layer and the DXF layers always appear on top of the tif
const EmptyLayer = () => (
  <Source id="kmlSource" type="geojson" data={{ type: 'FeatureCollection', features: [] }}>
    <Layer id="kmlStub" source="kmlSource" type="fill" />
  </Source>
);

interface ComponentProps {
  mode: string;
}

const CreateMap: React.FC<ComponentProps> = React.memo(({ mode }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const interactive = mode !== 'sidebar';
  const showLayers = mode === 'layers';
  const dashboard = mode === 'dashboard';
  const rasterTab = mode === 'details';
  const [basemapStyle, setBasemapStyle] = useState('mapbox://styles/mapbox/streets-v11');
  const viewportCenter = useAppSelector((state) => state.map.common.viewport?.center);
  const viewportZoom = useAppSelector((state) => state.map.common.viewport?.zoom);
  const rasterTileJsonLoading = useAppSelector((state) => state.map.common.rasterTileJsonLoading);
  const lasBBoxLoading = useAppSelector((state) => state.map.common.lasBBoxLoading);
  const baseMapLayer = useAppSelector((state) => state.map.common.layers?.baseMap);
  const mapStyle = useAppSelector((state) => state.map.common.mapStyle);
  const editor = useAppSelector((state) => state.map.editor.present.editorMode);
  const showSidebar = useAppSelector((state) => state.map.editor.present.showSidebar);
  const fileVersions = useAppSelector((state) => state.order.fileVersions);
  const vectorTileJson = useAppSelector((state) => state.map.common.vectorTileJson);
  const basemap = useAppSelector((state) => state.map.common.layers?.baseMap);
  const layerVisible = useAppSelector((state) => state.map.common.layerVisible);

  const orderIds = useSelector((state: RootState) => getOrderIds(state));
  const projectId = useAppSelector((state) => state.project.projectId);
  const imageryRequest = useAppSelector((state) => state.project.project?.imageryRequest);
  const coordinates = useSelector((state) => selectAllCoordinates(state));
  const hoveredCoords = useSelector((state) => selectHoveredCoordinates(state));
  const resources = useAppSelector((state) => state.auth.resources);
  const opsTrainer = 'opsTrainer' in resources;
  const editorFunctions = 'editorFunctions' in resources;
  const { esriBasemap } = useFlags();
  const isEstimate = useSelector((state: RootState) => getOrders(state).activeOrder?.isEstimate);

  const imageryRequested = (isEstimate && !!(imageryRequest?.requested)) || !!(imageryRequest?.uploaded) || !!(imageryRequest?.notificationSent);
  const rasterizationRequested = useSelector((state: RootState) => getRasterRequestStatus(state)).requested;

  useEffect(() => {
    dispatch(SetLayerVisibleAction(null));
  }, []);

  useEffect(() => {
    if (showLayers) {
      window.dispatchEvent(new Event('resize'));
    }
  }, [showSidebar]);

  const getLatLong = useCallback((e: any) => {
    dispatch(SetLatAction(JSON.stringify(e.lngLat.lat)));
    dispatch(SetLngAction(JSON.stringify(e.lngLat.lng)));
  }, []);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current
      || !ref.current.getBoundingClientRect().width
      || !ref.current.getBoundingClientRect().height) return;

    const { width, height } = ref.current.getBoundingClientRect();
    dispatch(SetCanvasSizeAction([width, height]));
  }, [ref.current, hoveredCoords]);

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const effect = async () => {
      setInitialized(false);
      if (opsTrainer && showLayers) {
        await dispatch(GetOrdersThunk(projectId));
        await dispatch(LoadKmlsThunk());
      }
      await dispatch(InitMapThunk(showLayers, dashboard));
      if (_ADMIN_ && showLayers) {
        dispatch(ToggleNoneVectorTiles());
      }
      setInitialized(true);
    };
    effect();
  }, [rasterTileJsonLoading, lasBBoxLoading, projectId]);

  useEffect(() => {
    if (!layerVisible && orderIds.length && fileVersions && vectorTileJson) {
      const visible: any = {};
      orderIds.forEach((orderId) => {
        const fileVersion = fileVersions[orderId];
        const tileJson = fileVersion && vectorTileJson[orderId] && vectorTileJson[orderId][fileVersion];
        tileJson?.vector_layers?.forEach((layer: any) => {
          const id = `default-${orderId}-${layer.id}`;
          visible[id] = true;
        });
      });
      dispatch(SetLayerVisibleAction(visible));
    }
  }, [orderIds, fileVersions, vectorTileJson, layerVisible]);

  useEffect(() => {
    if (!basemap) {
      // set empty basemap
      setBasemapStyle({ version: 8, sources: {}, layers: [] });
    } else {
      const showMapboxLayers = !showLayers || baseMapLayer;
      if (!showMapboxLayers) {
        setBasemapStyle('mapbox://styles/mapbox/empty-v8');
      } else {
        const mapboxStreetBasemapUrl = dashboard ? 'mapbox://styles/mapbox/light-v9' : 'mapbox://styles/mapbox/streets-v11';
        const streetBasemapUrl = esriBasemap ? `https://basemaps-api.arcgis.com/arcgis/rest/services/styles/ArcGIS:Streets?type=style&token=${_ESRI_API_Key_}` : mapboxStreetBasemapUrl;
        const imageryBasemapUrl = esriBasemap ? `https://basemaps-api.arcgis.com/arcgis/rest/services/styles/ArcGIS:Imagery:Standard?type=style&token=${_ESRI_API_Key_}` : 'mapbox://styles/mapbox/satellite-v9';

        const style = mapStyle === 'streets' ? streetBasemapUrl : imageryBasemapUrl;
        setBasemapStyle(style);
      }
    }
  }, [mapStyle, basemap]);

  const onMove = useCallback((evt) => {
    const { latitude, longitude, zoom } = evt.viewState;
    dispatch(SetViewportAction({ center: [longitude, latitude], zoom }));
  }, []);

  const onIdle = useCallback((evt) => {
    if (mode === 'sidebar' || ((imageryRequested || rasterizationRequested) && mode === 'details')) {
      const img = evt.target.getCanvas().toDataURL();
      dispatch(SetScreenshotAction(img));
    }
  }, []);

  useEffect(() => {
    if (!editor) {
      if (layerVisible) {
        const visible: any = {};
        Object.keys(layerVisible).forEach((layer: any) => {
          visible[layer] = true;
        });
        dispatch(SetLayerVisibleAction(visible));
      }
      dispatch(SetCurrentLayerAction());
      dispatch(SetlayerSelectedAction());
      dispatch(ResetDrawSource());
      dispatch(ResetUpdateEntities());
    }
  }, [editor]);

  return (
    <>
      <Box sx={styles.map} ref={ref}>
        {initialized && (
          <Map
            // reuseMaps // this prop will cause empty map when switch between Project Details and CAD View
            mapboxAccessToken={_MAPBOX_KEY_}
            mapStyle={basemapStyle}
            longitude={viewportCenter[0]}
            latitude={viewportCenter[1]}
            zoom={viewportZoom}
            onMove={onMove}
            onMouseMove={getLatLong}
            onIdle={onIdle}
          >
            {!dashboard && (
              <>
                <EmptyLayer />
                {rasterTab && <ErrorPaper />}
                <MapSearchMarker />
                <MapRasterLayer showLayers={showLayers} />
                <MapLayersController showLayers={showLayers} />
                <MapVectorLayers showLayers={showLayers} />
                {(!showLayers || _ADMIN_) && <MapLasBBox showLabel={mode !== 'sidebar'} />}
                {(showLayers && !editor && !_ADMIN_) && <MapMeasurements />}
                {(showLayers && editor && editorFunctions && !_ADMIN_) && <MapEditor />}
                {showLayers && _ADMIN_ && <MapKMLLayer />}
                {!showLayers && <MapDraw />}
              </>
            )}
            {dashboard && coordinates.map((c) => (
              <div>
                <Marker longitude={c.coords[0]} latitude={c.coords[1]}>
                  <Box sx={styles.markerIconWrapper}>
                    <SVG
                      src={markerIcon}
                      style={styles.markerIcon}
                    />
                  </Box>
                </Marker>
                {hoveredCoords && (
                  <Marker longitude={hoveredCoords[0]} latitude={hoveredCoords[1]}>
                    <Box sx={styles.markerIconWrapper}>
                      <SVG
                        src={markerIcon}
                        style={styles.markerIconHovered}
                      />
                    </Box>
                  </Marker>
                )}
              </div>
            ))}
          </Map>
        )}
        {(!dashboard && !interactive) && <Box sx={styles.transparentLayer} />}
      </Box>
      {!initialized && _ADMIN_ && showLayers && <Box sx={styles.loadingModal}><p>Loading...</p></Box>}

      {/* Checking for rasterLoading in the CAD View page */}
      {(!rasterTab && rasterTileJsonLoading && interactive) && editorFunctions && <Box sx={styles.loadingModal}><p>Loading...</p></Box>}

      {/* Checking for rasterLoading in the project details page */}
      {(rasterTab && (rasterTileJsonLoading) && interactive) && <Box sx={styles.loadingModal}><p>Loading...</p></Box>}

      {(!dashboard && interactive && !opsTrainer && !_ADMIN_) && <MapControls showLayers={showLayers} />}
      {interactive && <MapStyleToggle />}
    </>
  );
});

CreateMap.propTypes = {
  mode: PropTypes.string.isRequired,
};

export default CreateMap;
