// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { Typography, Button, CircularProgress } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import ErrorDialog from 'Components/ErrorDialog';
import { useAppDispatch, useAppSelector } from 'Hooks';
import CreditCardIcon from 'Components/CreditCardIcon';
import stylesDef from './styles';
import { DeleteCreditCardThunk, UpdateDefaultCardThunk } from 'Features/account/subscriptionThunk';
import { GetTaxThunk } from 'Features/order/orderThunk';

interface ComponentProps {
  card : ICreditCard;
  additonalCharges?: number;
  setTotalTax?: (arg0: number) => void;
  checkFirstTimeChangeEstimate: (arg0: Function) => void;
  onEditClick: () => void;
}

export const CreditCardInfo: React.FC<ComponentProps> = ({ card, onEditClick, additonalCharges, setTotalTax, checkFirstTimeChangeEstimate }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);

  const [showLoader, setShowLoader] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const { creditCards } = useAppSelector((state) => state.subscription);
  const pendingImageryOrder = useAppSelector((state) => {
    const orders = state.order.list;
    return orders.some((o) => o.isEstimate && o.paidForThisProject > 0);
  });

  const cards = creditCards;

  const dispatch = useAppDispatch();

  const onDeleteClick = async () => {
    setShowLoader(true);
    if (cards.length > 1 || (cards.length === 1 && !pendingImageryOrder)) {
      await dispatch(DeleteCreditCardThunk(card.id));
      // setting total tax to 0 to avoid showing the tax on the screen
      // only when the user deletes the default card
      card?.defaultCard && setTotalTax(0);
    } else {
      setShowDialog(true);
    }
    setShowLoader(false);
    // now find the default card and recalculate the tax
    // if there are multiple cards
    if (cards.length > 1) {
      const defaultCard = cards.find((c) => c.defaultCard);
      if (defaultCard) {
        const result = await dispatch(GetTaxThunk(additonalCharges));
        if (result.success) {
          setTotalTax(result.salesTaxAmount);
        }
      }
    }
  };
  const onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowDialog(false);
    e.stopPropagation();
  };

  const onSetDefaultClick = async (cardId: any) => {
    await dispatch(UpdateDefaultCardThunk(cardId));
    if (additonalCharges > 0) {
      const result = await dispatch(GetTaxThunk(additonalCharges));
      if (result.success) {
        setTotalTax(result.salesTaxAmount);
      }
    }
  };

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.cardIcon}>
        <CreditCardIcon cardType={card.cardType} />
      </Box>
      <Box>
        <Typography variant="body1">{card.number}</Typography>
        <Typography>{`Expires ${card.expDate}`}</Typography>
      </Box>
      <Box sx={styles.buttonsWrapper}>
        <Button sx={styles.button} onClick={onEditClick}>Edit</Button>
        <Button sx={styles.button} onClick={onDeleteClick}>Delete</Button>
      </Box>
      <Box />
      <Box>
        <Typography>{card.name}</Typography>
        <Typography>
          {card.address_line1}
          {card.address_line2}
        </Typography>
        <Typography>
          {card.address_city}
          {card.address_state}
          {card.address_zip}
        </Typography>
      </Box>
      <Box sx={styles.defaultButton}>
        {card.defaultCard ? (
          <Typography color="primary">Primary Card</Typography>
        ) : (
          <Button onClick={(e: any) => checkFirstTimeChangeEstimate(() => onSetDefaultClick(card.id))}>Make default</Button>
        )}
      </Box>
      {showLoader && (
        <>
          <Box sx={styles.backdrop} />
          <Box sx={styles.progress}>
            <CircularProgress />
          </Box>
        </>
      )}
      <ErrorDialog
        showDialog={showDialog}
        onCancel={onCancel}
        titleText="Error!"
        error
        cancelText="Cancel"
      >
        <Typography variant="body1">
          Unable to delete credit card. You have a saved order with a due balance. We need at least one credit card on file to process that order. If you still want to proceed with deleting the card, please cancel the pending order first or add a new credit card, and then delete the card you no longer need.
          If you need further assistance, please reach out to us through chat or
          {' '}
          <a href="mailto:support@airworks.io">support@airworks.io</a>
          {' '}

        </Typography>
      </ErrorDialog>
    </Box>
  );
};

export default CreditCardInfo;
