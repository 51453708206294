// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import cardValidator from 'card-validator';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import isStrongPassword from 'validator/lib/isStrongPassword';

const required = (value: string) => (value ? undefined : 'Required');
const email = (value: string) => !isEmail(value) && 'Invalid email address';
const mobilePhone = (value: string) => (value ? (!isMobilePhone(value) && 'Invalid phone number') : undefined);
const minLength = (min: number) => (value: string) =>
  (value && value.length < min ? `Must be at least ${min} characters long` : undefined);
const strongPassword = (value: string) => (value ? (!isStrongPassword(value) && 'Password is not strong enough') : undefined);
const minLength7 = minLength(7);
const maxLength = (max: number) => (value: string) =>
  (value && value.length > max ? `Maximum of ${max} characters long` : undefined);
const maxLength7 = maxLength(7);
const minValue = (min: number) => (value: string) =>
  (parseInt(value, 10) < min ? 'Required' : undefined);
const match = (matchName: string) => (value: string, allValues: any) =>
  (value !== allValues[matchName]
    ? `This field must match with ${matchName} field`
    : undefined);
const passwordsMatch = (value: string, allValues: any) =>
  (value !== allValues.password ? 'Passwords don\'t match' : undefined);

const creditCard = (number: string) => {
  const supportedCreditCards = ['visa', 'mastercard', 'american-express', 'diners-club', 'discover'];
  const result = cardValidator.number(number);

  return (result.isValid && supportedCreditCards.includes(result.card.type))
    ? undefined
    : 'Invalid card number';
};

const zipCodeValidation = (addressState: string, zipCodeValue: string) => {
  const isValidZipCode = validateZipCode(addressState, zipCodeValue);
  return (addressState && zipCodeValue && isValidZipCode) ? undefined : 'Please enter a zip code from the same state.';
};

const expireDate = (date: string) => {
  const result = cardValidator.expirationDate(date);

  return result.isValid
    ? undefined
    : 'Invalid date';
};

const composeValidators = (...validators: any[]) => (value: any, allValues: any, fieldState: any) =>
  validators.reduce((error, validator) => error || validator(value, allValues, fieldState), undefined);

const stateZipCodeRanges: { [key: string]: [number, number] } = {
  AL: [35000, 36999],
  AK: [99500, 99999],
  AZ: [85000, 86999],
  AR: [71600, 72999],
  CA: [90000, 96199],
  CO: [80000, 81999],
  CT: [6000, 6999],
  DE: [19700, 19999],
  FL: [32000, 34999],
  GA: [30000, 31999],
  HI: [96700, 96999],
  ID: [83200, 83999],
  IL: [60000, 62999],
  IN: [46000, 47999],
  IA: [50000, 52999],
  KS: [66000, 67999],
  KY: [40000, 42999],
  LA: [70000, 71599],
  ME: [3900, 4999],
  MD: [20600, 21999],
  MA: [1000, 2799],
  MI: [48000, 49999],
  MN: [55000, 56999],
  MS: [38600, 39999],
  MO: [63000, 65999],
  MT: [59000, 59999],
  NE: [68000, 69999],
  NV: [88900, 89999],
  NH: [3000, 3899],
  NJ: [7000, 8999],
  NM: [87000, 88499],
  NY: [10000, 14999],
  NC: [27000, 28999],
  ND: [58000, 58999],
  OH: [43000, 45999],
  OK: [73000, 74999],
  OR: [97000, 97999],
  PA: [15000, 19699],
  RI: [2800, 2999],
  SC: [29000, 29999],
  SD: [57000, 57999],
  TN: [37000, 38599],
  TX: [75000, 79999],
  UT: [84000, 84999],
  VT: [5000, 5999],
  VA: [20100, 24699],
  WA: [98000, 99499],
  WV: [24700, 26899],
  WI: [53000, 54999],
  WY: [82000, 83199],
  DC: [20000, 20599],
};

const validateZipCode = (state: string, zipCodeValue: string): boolean => {
  const zip = parseInt(zipCodeValue, 10);
  if (Number.isNaN(zip)) return false;

  const range = stateZipCodeRanges[state];
  if (!range) return false;

  return zip >= range[0] && zip <= range[1];
};

export default {
  required,
  email,
  mobilePhone,
  minLength,
  strongPassword,
  minLength7,
  maxLength,
  match,
  passwordsMatch,
  minValue,
  creditCard,
  expireDate,
  composeValidators,
  maxLength7,
  zipCodeValidation,
};
