// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Typography, Link } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { Link as RouterLink } from 'react-router-dom';
import PayAsYouGoCard from '../PayAsYouGoCard';
import stylesDef from './styles';

// This component renders signup success page
// page that gets render when sign up has been successful
const SignUpSuccess = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  return (
    <Box>
      <Typography sx={styles.header} variant="h1">
        Success! You’re all set to start processing orders.
      </Typography>
      <Typography sx={styles.details}>
        We’ve sent you an email with a verification link. Follow the link in your email to sign into your account and get started with your AirWorks journey. Want to commit to a lower priced plan? Contact
        {' '}
        <a href="mailto:sales@airworks.io" css={styles.link}>sales@airworks.io</a>
      </Typography>
      <Typography sx={styles.details}>
        <Link component={RouterLink} sx={styles.backLink} to="/">
          Go to Log in
        </Link>
      </Typography>
      <PayAsYouGoCard />
    </Box>
  );
};

export default SignUpSuccess;
