// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  topoControls: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr 1fr auto 1fr 1fr',
    alignItems: 'center',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    display: 'contents',
    marginLeft: '0px',
  },
  dropdown: {
    textTransform: 'lowercase',
    background: '#FAFAFC',
    paddingLeft: '10px',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
    '&.Mui-disabled': {
      cursor: 'not-allowed',
      color: theme.palette.text.disabled,
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.05)',
    },
  },
  defaultText: {
    float: 'right',
    color: '#2e4deb',
    fontSize: 'smaller',
  },
  innerDropdownText: {
    display: 'contents',
    textTransform: 'lowercase',
  },
  menuItem: {
    justifyContent: 'space-between',
  },
  topoTitle: {
    marginTop: theme.spacing(2),
    paddingBottom: '10px',
  },
  topoText: {
    textTransform: 'capitalize',
    marginRight: theme.spacing(1),
  },
  topoTextSuccess: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridColumnGap: theme.spacing(3),
  },
  ftText: {
    textTransform: 'lowercase',
    color: '#788898',
    marginLeft: theme.spacing(1),
  },
  dateGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    gridColumnGap: theme.spacing(1),
  },
  icon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    cursor: 'pointer',
  },
  density: {
    marginBottom: '16px',
  },
  densityTitle: {
    margin: '16px 0',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  densityInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  densityText: {
    textTransform: 'capitalize',
  },
  disabled: {
    color: theme.palette.text.disabled,
    cursor: 'not-allowed',
  },
});

export default styles;
