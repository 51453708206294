import { createAction } from '@reduxjs/toolkit';

export const SetOrderStatusAction = createAction<{orderStatus: { [key: string]: number }, orderEstimate: string[] }>('order/SetOrderStatusAction');

export const SetOrderIdAction = createAction<string>('order/SetOrderIdAction');

export const UpdateOrderAction = createAction<IOrder>('order/UpdateOrderAction');

export const GetOrdersStartAction = createAction('order/GetOrdersStartAction');
export const GetOrdersSuccessAction = createAction<IOrder[]>('order/GetOrdersSuccessAction');
export const GetOrdersFailureAction = createAction<string>('order/GetOrdersFailureAction');

export const CreateOrderStartAction = createAction('order/CreateOrderStartAction');
export const CreateOrderSuccessAction = createAction<IOrder>('order/CreateOrderSuccessAction');
export const CreateOrderFailureAction = createAction('order/CreateOrderFailureAction');

export const CreditAdjustmentStartAction = createAction('order/CreditAdjustmentStartAction');
export const CreditAdjustmentSuccessAction = createAction<IOrder>('order/CreditAdjustmentSuccessAction');
export const CreditAdjustmentFailureAction = createAction<string>('order/CreditAdjustmentFailureAction');

export const DeleteOutputLasFileStartAction = createAction('order/DeleteOutputLasFileStartAction');
export const DeleteOutputLasFileSuccessAction = createAction<{lasFileId: string, orderId: string}>('order/DeleteOutputLasFileSuccessAction');
export const DeleteOutputLasFileFailureAction = createAction('order/DeleteOutputLasFileFailureAction');

export const DeleteDeliverableFileStartAction = createAction('order/DeleteDeliverableFileStartAction');
export const DeleteDeliverableFileSuccessAction = createAction<{deliverableFileId: string, orderId: string}>('order/DeleteDeliverableFileSuccessAction');
export const DeleteDeliverableFileFailureAction = createAction('order/DeleteDeliverableFileFailureAction');

export const DeleteDxfFileStartAction = createAction('order/DeleteDxfFileStartAction');
export const DeleteDxfFileSuccessAction = createAction<{dxfFileId: string, orderId: string}>('order/DeleteDxfFileSuccessAction');
export const DeleteDxfFileFailureAction = createAction('order/DeleteDxfFileFailureAction');

export const DeleteGeoDbFileStartAction = createAction('order/DeleteGeoDbFileStartAction');
export const DeleteGeoDbFileSuccessAction = createAction<{geoDbFileId: string, orderId: string}>('order/DeleteGeoDbFileSuccessAction');
export const DeleteGeoDbFileFailureAction = createAction('order/DeleteGeoDbFileFailureAction');

export const DeleteSurfaceFileStartAction = createAction('order/DeleteSurfaceFileStartAction');
export const DeleteSurfaceFileSuccessAction = createAction<{surfaceFileId: string, orderId: string}>('order/DeleteSurfaceFileSuccessAction');
export const DeleteSurfaceFileFailureAction = createAction('order/DeleteSurfaceFileFailureAction');

export const DownloadFileStartAction = createAction('order/DownloadFileStartAction');
export const DownloadFileSuccessAction = createAction('order/DownloadFileSuccessAction');
export const DownloadFileFailureAction = createAction<string>('order/DownloadFileFailureAction');

export const UpdateOrderStatusStartAction = createAction('order/UpdateOrderStatusStartAction');
export const UpdateOrderStatusSuccessAction = createAction<IOrder>('order/UpdateOrderStatusSuccessAction');
export const UpdateOrderStatusFailureAction = createAction<string>('order/UpdateOrderStatusFailureAction');

export const EditOrderSuccessAction = createAction<IOrder>('order/EditOrderSuccessAction');

export const SetOutputLasAction = createAction<boolean>('order/SetOutputLasAction');

export const SetTaxDetailsAction = createAction<{_id: string, salesTaxAmount: number, salesTaxRate: number }>('order/SetTaxDetailsAction');

export const UpdateOrderLasFilesAction = createAction<ILasFile>('order/UpdateOrderLasFilesAction');

export const SetFileVersionAction = createAction<{ [key: string]: string }>('order/SetFileVersionAction');

export const UpdateOrderGeoDbFilesAction = createAction<IGeoDbFile>('order/UpdateOrderGeoDbFilesAction');

export const UpdateOrderSurfaceFilesAction = createAction<ISurfaceFile>('order/UpdateOrderSurfaceFilesAction');

export const SaveFileDownloadStatusAction = createAction<{ downloadStatus: any, fileReady: boolean }>('order/SaveFileDownloadStatusAction');

export const SaveGeoJsonDownloadStatusAction = createAction<{ downloadGeoJsonStatus: any, fileReady: boolean }>('order/SaveGeoJsonDownloadStatusAction');
export const SetOrderLoadingAction = createAction<boolean>('order/SetOrderLoadingAction');

export const SetPlaceOrderSuccessAction = createAction<boolean>('order/SetPlaceOrderSuccessAction');

export const SetShowAdjustmentMessageAction = createAction<boolean>('order/SetShowAdjustmentMessageAction');

export const UpdateOrderCadFilesAction = createAction<ICadFile>('order/UpdateOrderCadFilesAction');

export const UpdateOrderDeliverableFilesAction = createAction<IDeliverableFile>('order/UpdateOrderDeliverableFilesAction');
