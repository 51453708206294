// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const getUser = () => localStorage.getItem('user');
const getLoginFailedAttempts = () => localStorage.getItem('loginFailedAttempts');
const getToken = () => localStorage.getItem('token');
const getHTML = () => localStorage.getItem('html');
const getPlanDetails = () => localStorage.getItem('planDetails');
const getSortFilter = () => localStorage.getItem('sortFilter') as ProjectSortFilter || 'recent';

const setUser = (user: string) => localStorage.setItem('user', user);

const setLoginFailedAttempts = (attempts: string) => localStorage.setItem('loginFailedAttempts', attempts);
const removeLoginFailedAttempts = () => localStorage.removeItem('loginFailedAttempts');

const setHTML = (html: string) => localStorage.setItem('html', html);
const setToken = (token: string) => localStorage.setItem('token', token);
const setPlanDetails = (planDetails: string) => localStorage.setItem('planDetails', planDetails);
const setSortFilter = (sortFilter: ProjectSortFilter) => localStorage.setItem('sortFilter', sortFilter);

const clearLocalStorage = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  localStorage.removeItem('sortFilter');
  localStorage.removeItem('tokenExpires');
  localStorage.removeItem('html');
  localStorage.removeItem('resources');
  localStorage.removeItem('planDetails');
};

export {
  getUser,
  getToken,
  getHTML,
  getSortFilter,
  getPlanDetails,
  getLoginFailedAttempts,
  setUser,
  setLoginFailedAttempts,
  removeLoginFailedAttempts,
  setToken,
  setHTML,
  setSortFilter,
  setPlanDetails,
  clearLocalStorage,
};
