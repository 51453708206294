// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import {
  Dialog,
  Typography,
  FormGroup,
  Button,
  FormControlLabel,
  DialogActions,
  RadioGroup,
  Radio,
} from '@mui/material';
import { Box, useTheme } from '@mui/system';
import stylesDef from './styles';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { DownloadKMLThunk } from 'Features/kml/kmlThunk';

export const KmlFileFormatDialog = ({
  order,
  showDialog,
  onCancel,
}) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();

  const kmlGeojson = useAppSelector((state) => state.kml.present?.kmls[order._id]?.featureCollection);


  const [format, setFormat] = useState('kml');
  const onClose = () => {
    setFormat('kml');
    onCancel();
  };

  const downloadKml = () => {
    if (format === 'geojson') {
      const newGeojson = { ...kmlGeojson };
      const newFeatures = kmlGeojson.features.map((feature) => {
        const newFeature = { ...feature };
        delete newFeature.id;
        return newFeature;
      });
      newGeojson.features = newFeatures;
      const geojson = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(newGeojson))}`;
      const downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute('href', geojson);
      downloadAnchorNode.setAttribute('download', `${order._id}.geojson`);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    } else {
      dispatch(DownloadKMLThunk(order._id, order.boundaryFile.split('/').pop()));
    }
    onClose();
  };

  const changeFormat = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormat(event.target.value);
  };

  return (
    <Dialog
      open={showDialog}
      sx={styles.dialog}
    >
      <Box sx={styles.dialogBox}>
        <Typography variant="h5" sx={styles.dialogText}>
          Please choose your preferred file format:
        </Typography>
        <FormGroup>
          <RadioGroup aria-label="format" name="format" value={format} onChange={changeFormat}>
            <FormControlLabel value="kml" control={<Radio color="primary" />} label=".kml" />
            <FormControlLabel value="geojson" control={<Radio color="primary" />} label=".geojson" />
          </RadioGroup>
        </FormGroup>
        <DialogActions sx={styles.dialogActions}>
          <Button onClick={onClose} variant="text">
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={downloadKml}
          >
            Download
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default KmlFileFormatDialog;
