// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import { Box, useTheme } from '@mui/system';
import useInterval from 'Components/hooks/useInterval';
import { useOktaAuth } from '@okta/okta-react';
// import Header from './Header';
import { Outlet } from 'react-router';
import { GetProjectsThunk } from 'Features/projectList/projectListThunk';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { GetOrgProfileThunk, GetUserProfileThunk } from 'Features/account/accountThunk';
import { GetImageryProvidersThunk } from 'Features/imagery/imageryThunk';
import { getUser } from 'Utils/localStorageService';
import { GetOrderStatusThunk } from 'Features/order/orderThunk';
import { GetTemplates } from 'Features/account/cadTemplates/cadTemplateThunk';
import ExpiredSessionModal from 'Components/ExpiredSessionModal';
import LoadingSpinner from 'Components/LoadingSpinner';
import { GetNotificationsThunk } from './shellThunk';
import Header from './Header';
import stylesDef from './styles';
import { OktaAdminLoginThunk } from 'Features/auth/authThunk';

const Shell = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const { token, resources } = useAppSelector(
    (state) => state.auth,
  );
  const { org } = useAppSelector(
    (state) => state.account,
  );
  const user = JSON.parse(getUser());
  const opsTrainer = !_ADMIN_ && 'opsTrainer' in resources;
  const { authState, oktaAuth } = _ADMIN_ && useOktaAuth();

  useEffect(() => {
    const loadData = async () => {
      // eslint-disable-next-line no-unused-expressions
      authState && !token && await dispatch(OktaAdminLoginThunk(authState.accessToken, authState.accessToken?.claims?.pricingAccess));
    };
    // eslint-disable-next-line no-unused-expressions
    _ADMIN_ && loadData();
  }, [authState, oktaAuth]);

  const loadData = async () => {
    const promiseArray = [
      dispatch(GetProjectsThunk()),
      dispatch(GetUserProfileThunk()),
      dispatch(GetOrgProfileThunk()),
      dispatch(GetNotificationsThunk()),
      dispatch(GetTemplates()),
    ];

    const resultArray = await Promise.all(promiseArray);
    const anyFailed = resultArray.some((r) => !r.success);
    setLoading(anyFailed);
  };

  useEffect(() => {
    if (_ADMIN_) {
      setLoading(false);
    } else {
      loadData();
    }
  }, []);

  useEffect(() => {
    if (!_ADMIN_ && org?.imageryProviders?.length > 0) {
      dispatch(GetImageryProvidersThunk());
    }
  }, [org?.imageryProviders]);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = '//js.hs-scripts.com/3434755.js';
    script.async = true;

    document.body.appendChild(script);
    // Start of Survicate (www.survicate.com) code
    if (window.location.hostname === 'cloud.airworks.io' && org !== null) {
      (function (opts) {
        opts.traits = {
          email: user.email,
          first_name: user.firstName,
          last_name: user.lastName,
          organization: org.companyName,
        };
      }(window._sva = window._sva || {}));
      (function (w) {
        const s = document.createElement('script');
        s.src = 'https://survey.survicate.com/workspaces/e446d987fb1a2dc0466a010b938591dd/web_surveys.js';
        s.async = true;
        const e = document.getElementsByTagName('script')[0];
        e.parentNode.insertBefore(s, e);
      }(window));
    }
    // End of Survicate code
    return () => {
      document.body.removeChild(script);
    };
  }, [org]);

  // useInterval(() => {
  //   if (!loading && !_ADMIN_) {
  //     GetUserProfileAction(true);
  //   }
  // }, 2000);

  // Call getOrders API every 30 minutes
  useInterval(() => {
    if (!loading && !_ADMIN_ && !opsTrainer) {
      dispatch(GetOrderStatusThunk());
    }
  }, 1800000);

  useInterval(() => {
    if (!loading && !_ADMIN_) {
      dispatch(GetNotificationsThunk());
    }
  }, 10000);

  return (
    <Box sx={styles.shellWrapper}>
      <LoadingSpinner loading={loading} />
      {token && !loading && (
        <>
          <Box sx={styles.headerWrapper}>
            <Header />
          </Box>
          <Box sx={styles.contentWrapper}>
            <Outlet />
          </Box>
        </>
      )}
      <ExpiredSessionModal />
    </Box>
  );
};

export default Shell;
