// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import { Popup } from 'react-map-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import area from '@turf/area';
import centroid from '@turf/centroid';
import length from '@turf/length';
import bearing from '@turf/bearing';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAppDispatch, useAppSelector } from 'Hooks';
import DrawControl from 'Components/DrawControl';
import CustomDirectSelect from '../MapEditor/curvemode/customDirectSelectMode';
import drawAngle from './modes/draw_angle';
import { SetMode } from '../mapDrawThunk';
import { SetCurrentMeasurementOptionAction } from '../mapDrawActions';
import { drawToolStyles, stylesDef } from './styles';

MapboxDraw.modes.direct_select = CustomDirectSelect;

const MapMeasurements = () => {
  const styles = stylesDef();
  const dispatch = useAppDispatch();
  const modeName = useAppSelector((state) => state.map.draw.present.mode?.name);
  const measurementMode = useAppSelector((state) => state.map.draw.present.measurementMode);
  const currentMeasurementOption = useAppSelector((state) => state.map.draw.present.currentMeasurementOption);

  const [draw, setDraw] = useState(null);
  const [modeFeatureId, setModeFeatureId] = useState(null);
  const [featureCollection, setFeatureCollection] = useState({
    type: 'FeatureCollection',
    features: [],
  });

  const syncDraw = () => {
    if (draw === null) return;
    if (draw.getMode() === 'draw_polygon' && modeName === 'draw_polygon')
      return;
    if (
      draw.getMode() === 'draw_line_string' &&
      modeName === 'draw_line_string'
    )
      return;
    if (draw.getMode() === 'draw_angle' && modeName === 'draw_angle') return;
    if (draw.getMode() === 'draw_circle' && modeName === 'draw_circle')
      return;

    draw.set(featureCollection);
    if (modeName) {
      if (modeName === 'direct_select') {
        if (!featureCollection || featureCollection.features.length === 0)
          return;
      } else {
        draw.changeMode(modeName);
      }
    }
  };
  const switchModes = () => {
    if (
      currentMeasurementOption === 'line_string' ||
      currentMeasurementOption === 'polygon' ||
      currentMeasurementOption === 'angle' ||
      currentMeasurementOption === 'circle'
    ) {
      const _fc = featureCollection.features;
      for (let index = featureCollection.features.length - 1; index >= 0; index -= 1) {
        setDraw(draw.delete(_fc[index].id).getAll());
        setDraw(null);
        _fc.splice(index, 1);
      }
      setFeatureCollection((prevState) => ({
        type: prevState.type,
        features: _fc,
      }));
    }
  };

  useEffect(() => {
    syncDraw();
  }, [draw, modeName, modeFeatureId, featureCollection, measurementMode]);

  useEffect(() => {
    switchModes();
  }, [currentMeasurementOption]);

  const getPolyArea = (feature: any) => (area(feature) * 10.764).toFixed(2);
  const getLineDistance = (feature: any) =>
    (length(feature) * 3280.84).toFixed(2);
  const getCoordinatesCentroid = (feature: any) =>
    centroid(feature).geometry.coordinates;
  const getAngle = (feature: any) => {
    const { coordinates } = feature.geometry;
    if (coordinates.length < 3) return;
    const bearing1 = bearing(coordinates[1], coordinates[0]);
    const bearing2 = bearing(coordinates[1], coordinates[2]);
    let r = (bearing2 - bearing1) % 360.0;
    if (r >= 180.0) r -= 360.0;
    return Math.abs(r).toFixed(2);
  };

  const popupText = (feature: any) => {
    if (feature.geometry.type === 'Polygon') {
      return `${getPolyArea(feature)} sq.ft.`;
    }
    if (feature.geometry.type === 'LineString') {
      if (feature.properties.type === 'Angle') {
        return `${getAngle(feature)}°`;
      }
      return `${getLineDistance(feature)} ft.`;
    }
  };

  const updateDraw = (e: any) => {
    const action = e.action;
    const feature = e.features[0];
    if (feature) {
      let _fc = featureCollection;
      if (action && (action === 'move' || action === 'change_coordinates')) {
        _fc.features.forEach((f, i) => {
          if (f.id === feature.id) {
            _fc.features.splice(i, 1);
          }
        });
      }
      _fc.features.push(feature);
      setFeatureCollection((prevState) => ({
        type: prevState.type,
        features: _fc.features,
      }));
    }
  };

  return (
    <>
      <Box>
        <DrawControl
          userProperties
          displayControlsDefault={false}
          styles={drawToolStyles}
          ref={(drawControl) => {
            if (drawControl !== null && drawControl.draw !== null) {
              setDraw(drawControl.draw);
            }
          }}
          modes={{
            ...MapboxDraw.modes,
            direct_select: CustomDirectSelect,
            draw_angle: drawAngle,
          }}
          onCreate={updateDraw}
          onUpdate={updateDraw}
          onDrawSelectionChange={(e) => {
            if (!(e.features.length === 0 && e.points.length === 0)) {
              dispatch(SetMode('direct_select'));
              setModeFeatureId(e.features[0].id);
              dispatch(SetCurrentMeasurementOptionAction(null));
            }
          }}
        />
      </Box>
      {featureCollection.features.length > 0 &&
        featureCollection.features.map((f, index) => (
          <Popup
            key={f.id}
            anchor="bottom"
            closeOnClick={false}
            longitude={getCoordinatesCentroid(f)[0]}
            latitude={getCoordinatesCentroid(f)[1]}
          >
            <Box sx={styles.popupContainer}>
              {popupText(f)}
              <DeleteIcon
                sx={styles.popupDelete}
                onClick={() => {
                  let _fc = featureCollection;
                  _fc.features.splice(index, 1);
                  setFeatureCollection(_fc);
                  setDraw(draw.delete(f.id).getAll());
                  setDraw(null);
                }}
              />
            </Box>
          </Popup>
        ))}
    </>
  );
};

export default MapMeasurements;
