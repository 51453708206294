// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createAction } from '@reduxjs/toolkit';

export const UploadDxfLayersStartAction = createAction('ops/UploadDxfLayersStartAction');
export const UploadDxfLayersSuccessAction = createAction<string>('ops/UploadDxfLayersSuccessAction');
export const UploadDxfLayersFailureAction = createAction<string>('ops/UploadDxfLayersFailureAction');

export const InitializeTrainingProjectStartAction = createAction('ops/InitializeTrainingProjectStartAction');
export const InitializeTrainingProjectSuccessAction = createAction('ops/InitializeTrainingProjectSuccessAction');
export const InitializeTrainingProjectFailureAction = createAction<string>('ops/InitializeTrainingProjectFailureAction');
