// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  dialog: {
    minWidth: '600px',
    margin: 'auto',
  },
  dialogBox: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  dialogTitle: {
    display: 'grid',
    gridTemplateColumns: 'auto auto 1fr',
    marginBottom: theme.spacing(3),
  },
  closeButton: {
    color: theme.palette.primary.main,
    marginLeft: 'none',
    '&:hover': {
      backgroundColor: 'white !important',
    },
  },
  titleText: {
    paddingLeft: theme.spacing(1),
  },
  dialogActions: {
    marginTop: theme.spacing(1),
    justifyContent: 'flex-end',
    paddingLeft: 0,
  },
  errorIcon: {
    color: '#F95264',
  },
  warningIcon: {
    color: '#FAA738',
  },
});

export default styles;
