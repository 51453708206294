// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createSlice } from '@reduxjs/toolkit';
import { CreateImageryRequestFailedAction, CreateImageryRequestStartAction, CreateImageryRequestSuccessAction, GetImageryCoverageFailedAction, GetImageryCoverageStartAction, GetImageryCoverageSuccessAction, GetImageryProvidersFailedAction, GetImageryProvidersStartAction, GetImageryProvidersSuccessAction, ResetImageryCoverageAction, SetAvailableProjectionsAction, SetImageryVendorSelectedAction, SetProjectionSelectedAction, ToggleImageryProcessingAction } from './imageryActions';

const initialState: IImageryState = {
  loading: false,
  imageryProviders: [],
  nearmapCoverageLoading: false,
  nearmapCoverage: false,
  imageryVendorSelected: null,
  imageryProcessing: true,
  projectionSelected: null,
  availableProjections: [],
  createImageryLoading: false,
};

const imagerySlice = createSlice({
  name: 'imagery',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetImageryProvidersStartAction, (state) => {
        state.loading = true;
      })
      .addCase(GetImageryProvidersSuccessAction, (state, action) => {
        state.loading = false;
        state.imageryProviders = action.payload;
      })
      .addCase(GetImageryProvidersFailedAction, (state) => {
        state.loading = false;
      })
      .addCase(GetImageryCoverageStartAction, (state) => {
        state.nearmapCoverageLoading = true;
      })
      .addCase(GetImageryCoverageSuccessAction, (state, action) => {
        state.nearmapCoverageLoading = false;
        state.nearmapCoverage = action.payload;
      })
      .addCase(GetImageryCoverageFailedAction, (state) => {
        state.nearmapCoverageLoading = false;
        state.nearmapCoverage = false;
      })
      .addCase(ResetImageryCoverageAction, (state) => {
        state.nearmapCoverage = false;
      })
      .addCase(SetImageryVendorSelectedAction, (state, action) => {
        state.imageryVendorSelected = action.payload;
      })
      .addCase(SetProjectionSelectedAction, (state, action) => {
        state.projectionSelected = action.payload;
      })
      .addCase(SetAvailableProjectionsAction, (state, action) => {
        state.availableProjections = action.payload;
      })
      .addCase(ToggleImageryProcessingAction, (state) => {
        state.imageryProcessing = !state.imageryProcessing;
      })
      .addCase(CreateImageryRequestStartAction, (state) => {
        state.createImageryLoading = true;
      })
      .addCase(CreateImageryRequestSuccessAction, (state) => {
        state.createImageryLoading = false;
        state.projectionSelected = null;
        state.imageryProcessing = true;
      })
      .addCase(CreateImageryRequestFailedAction, (state) => {
        state.createImageryLoading = false;
        state.projectionSelected = null;
        state.imageryProcessing = true;
      });
  },
});

export const { reducer } = imagerySlice;

export default reducer;
