// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { Paper, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import { Box, useTheme } from '@mui/system';
import { useSelector } from 'react-redux';
import stylesDef from './styles';
import { select3DTileStatus } from 'Features/project/projectSelectors';

export const FileErrors = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const [showTileError, setShowTileError] = useState(true);
  const { tileLoading, tile3DError } = useSelector((state) => select3DTileStatus(state));

  return (
    <>
      {tileLoading && <Box sx={styles.loadingModal}><p>Loading...</p></Box>}
      <Box sx={styles.errorStacks}>
        {tile3DError && showTileError && (
          <Paper elevation={1} square sx={styles.errorPaper}>
            <Box sx={styles.errorControls}>
              <ErrorIcon sx={styles.errorIcon} />
              <Typography variant="h3">
                We are not able to render your point cloud. Please attempt uploading another file or contact
                {' '}
                <a href="mailto:support@airworks.io" style={styles.link}>support@airworks.io</a>
                {' '}
                for us to troubleshoot further.
              </Typography>
            </Box>
            <CloseIcon sx={styles.closeIcon} onClick={() => setShowTileError(false)} />
          </Paper>
        )}
      </Box>
    </>
  );
};

export default FileErrors;
