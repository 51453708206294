// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { Collapse, IconButton, Divider, ListItemButton, Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import downloadIcon from 'Assets/download-icon.png';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { DownloadTifThunk } from 'Features/project/projectThunk';
import OrthomosaicCheckbox from './OrthomosaicCheckbox';
import OrthomosaicButtons from './OrthomosaicButtons';
import stylesDef from './styles';

const Orthomosaic = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const resources = useAppSelector((state) => state.auth.resources);
  const opsTrainer = 'opsTrainer' in resources;
  const project = useAppSelector((state) => state.project.project);
  const { imageryRequest, isDemoProject } = !opsTrainer && project;
  const tifFiles = project?.tifFiles || null;

  const [dataSetsExpanded, setDataSetsExpanded] = useState(true);

  const toggleDataSetsExpanded = () => {
    setDataSetsExpanded(!dataSetsExpanded);
  };

  const handleDownloadClick = async (tifFile: ITifFile) => {
    await dispatch(DownloadTifThunk(tifFile.filename.split('/').pop()));
  };

  return (
    <>
      <ListItemButton sx={styles.expansionPanelHeader}>
        <OrthomosaicCheckbox />
        {(!imageryRequest && !isDemoProject) && (
          <Box>
            <OrthomosaicButtons />
          </Box>
        )}
        <IconButton aria-label={dataSetsExpanded ? 'Collapse' : 'Expand'} sx={styles.button} onClick={toggleDataSetsExpanded}>
          {dataSetsExpanded
            ? <KeyboardArrowDownIcon fontSize="small" />
            : <KeyboardArrowRightIcon fontSize="small" />}
        </IconButton>
      </ListItemButton>
      <Collapse in={dataSetsExpanded}>
        <Box sx={styles.wrapper}>
          <Box sx={styles.dataSetRow}>
            {tifFiles?.map((tif: ITifFile) => (
              <Box key={tif._id} sx={styles.filesList}>
                <Typography variant="body2" sx={styles.file}>{tif.filename.split('/').pop()}</Typography>
                {(!imageryRequest && !isDemoProject && !opsTrainer) && (
                  <IconButton
                    aria-label="Download .tif"
                    sx={styles.button}
                    onClick={() => handleDownloadClick(tif)}
                  >
                    <img src={downloadIcon} alt="Download .tif" />
                  </IconButton>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      </Collapse>
      <Divider />
    </>
  );
};

export default Orthomosaic;
