// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/system';
import { TransitionProps } from '@mui/material/transitions/transition';
import { InputLabel, Dialog, Slide, Button, IconButton, Divider, LinearProgress, Typography, MenuItem, FormControl, Select, Input, FormControlLabel, Checkbox } from '@mui/material';
import { WideLightTooltip } from 'Components/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import NearmapLogo from 'Assets/nearmap-logo-full.png';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { RootState } from 'Store';
import { CreateImageryRequestThunk, GetAvailableProjectionsThunk } from 'Features/imagery/imageryThunk';
import { SetImageryVendorSelectedAction, SetProjectionSelectedAction, ToggleImageryProcessingAction } from 'Features/imagery/imageryActions';
import stylesDef from './styles';

interface VendorSelectionDialogProps {
  showDialog: boolean;
  setShowDialog: (show: boolean) => void;
}

const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => <Slide ref={ref} direction="up" {...props} />);

export const VendorSelectionDialog = ({ showDialog, setShowDialog }: VendorSelectionDialogProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [disableContinue, setDisableContinue] = useState(true);
  const { projectId } = useAppSelector((state: RootState) => state.project);
  const { createImageryLoading, projectionSelected, availableProjections, imageryProcessing } = useAppSelector((state: RootState) => state.imagery);
  const { bbox } = useAppSelector((state: RootState) => state.kml.present);

  useEffect(() => {
    const loadProjections = async () => {
      setLoading(true);
      dispatch(SetProjectionSelectedAction(null));
      await dispatch(GetAvailableProjectionsThunk(bbox));
      setLoading(false);
    };
    if (showDialog && bbox) {
      loadProjections();
    }
  }, [bbox, showDialog]);

  useEffect(() => {
    if (!projectionSelected) {
      setDisableContinue(true);
    } else {
      setDisableContinue(false);
    }
  }, [projectionSelected]);

  const selectVendor = async () => {
    // Setting vendor selected as Nearmap for this release
    window?.pendo?.track('Projection Selected', { projectId, projection_selected: projectionSelected });
    dispatch(SetImageryVendorSelectedAction('Nearmap'));
    await dispatch(CreateImageryRequestThunk());
    setShowDialog(false);
  };

  const selectProjection = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(SetProjectionSelectedAction(event.target.value));
  };

  return (
    <Dialog
      fullScreen
      open={showDialog}
      onClose={() => setShowDialog(false)}
      TransitionComponent={Transition}
      keepMounted={false}
    >
      {(createImageryLoading || loading) && (
        <>
          <LinearProgress sx={styles.progress} />
          <Box sx={styles.backdrop} />
        </>
      )}
      <Box sx={styles.closeButtonWrapper}>
        <IconButton
          color="inherit"
          onClick={() => {
            setShowDialog(false);
          }}
          aria-label="Close"
        >
          <CloseIcon fontSize="large" />
        </IconButton>
      </Box>
      <Box sx={styles.contentWrapper}>
        <Typography variant="h1">Your Imagery Vendor</Typography>
        <Typography sx={styles.contentText}>
          <b>Nearmap</b>
          {' '}
          will be your default Imagery Vendor, choose your imagery projection and click on Continue.
        </Typography>
        <Box sx={styles.vendorOption}>
          <img style={styles.vendorLogo} src={NearmapLogo} alt="nearmap logo" />
          <Divider orientation="vertical" sx={styles.verticalDivider} flexItem />
          <Box sx={styles.vendorText}>
            <Typography sx={styles.vendorName}>Nearmap</Typography>
            <Typography sx={styles.vendorDescription}>Nearmap gives you every dimension of location intelligence in one central hub.</Typography>
          </Box>
        </Box>
        <Typography sx={styles.contentText2}>Other providers coming soon.</Typography>
        <FormControl sx={styles.formControl}>
          <InputLabel id="select-projection-label">Select Projection</InputLabel>
          <Select
            sx={styles.select}
            value={projectionSelected}
            onChange={selectProjection}
            labelId="select-projection-label"
          >
            {availableProjections?.map((item: string) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography sx={styles.contentText3}>
          Feel free to review
          {' '}
          <a href="https://www.nearmap.com/us/en/current-aerial-maps-coverage" style={styles.anchorTag} rel="noreferrer" target="_blank">Nearmap’s coverage map</a>
          {' '}
          and
          {' '}
          <a href="https://docs.nearmap.com/display/NUB/2020/02/04/Projections+and+Coordinate+Systems+and+the+Importance+of+the+Coordinates" style={styles.anchorTag} rel="noreferrer" target="_blank">projection system recommendations</a>
          {' '}
          before proceeding.
        </Typography>
        <Typography sx={styles.contentText3}>Please continue to checkout and save an estimate so we can kickoff your data upload process.</Typography>
        <Box sx={styles.processingContainer}>
          <FormControlLabel
            label={<Typography>Begin Processing Immediately</Typography>}
            control={(
              <Checkbox
                color="primary"
                onChange={() => dispatch(ToggleImageryProcessingAction())}
                checked={imageryProcessing}
              />
            )}
          />
          <WideLightTooltip
            placement="bottom"
            title={(
              <Box>
                When start processing immediately is selected, you are forfeiting your chance to review the available imagery for your area of interest before placing your order. If you want to review the imagery before kicking off your project, unselect this option.
              </Box>
            )}
          >
            <InfoIcon fontSize="small" />
          </WideLightTooltip>
        </Box>
        <Button disabled={disableContinue} sx={styles.continueButton} variant="outlined" onClick={selectVendor}>Continue</Button>
      </Box>
    </Dialog>
  );
};

export default VendorSelectionDialog;
