// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { Button, Typography, TextField, Fade, CircularProgress } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import SVG from 'react-inlinesvg';
import area from 'Assets/area-icon.svg';
import polyline from 'Assets/toolbar-polyLine.svg';
import areaActive from 'Assets/area-icon-active.svg';
import polylineActive from 'Assets/toolbar-polyLine-active.svg';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { defaultBufferSize, defaultRoadNetworkBufferSize, minimumRoadNetworkBufferSize, mininumBufferSize } from 'Utils/constants';
import { GenerateKmlBufferThunk } from 'Features/kml/kmlThunk';
import { SaveDrawnKmlLine, StartKmlDraw, SaveDrawnKmlPolygon } from 'Features/map/mapDrawThunk';
import { useSelector } from 'react-redux';
import { RootState } from 'Store';
import { getOrders } from 'Features/order/orderSelectors';
import { SetBufferSizeAction } from 'Features/kml/kmlActions';
import stylesDef from './styles';

export const KmlToolBar = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();

  const currentKmlCount = useAppSelector((state) => state.map.draw.present.currentKml?.features?.length);
  const bufferSize = useAppSelector((state) => state.kml.present.bufferSize);
  const creatingBuffer = useAppSelector((state) => state.kml.present.creatingBuffer);
  const kmlLine = useAppSelector((state) => state.map.draw.present.kmlLine);
  const showBufferInfo = useAppSelector((state) => state.kml.present.showBufferInfo);
  const { activeOrder } = useSelector((state: RootState) => getOrders(state));
  const { geomType } = activeOrder;
  const creatingRoadNetworkBuffer = useAppSelector((state) => state.kml.present.creatingRoadNetworkBuffer);
  const uploadingDrawnKml = useAppSelector((state) => state.map.draw.present.uploadingDrawnKml);

  const roadNetworkBuffer = geomType === 'Polygon';
  const [buffer, setBuffer] = useState(bufferSize);

  const defaultBuffer = roadNetworkBuffer ? defaultRoadNetworkBufferSize : defaultBufferSize;
  const minBuffer = roadNetworkBuffer ? minimumRoadNetworkBufferSize : mininumBufferSize;

  useEffect(() => {
    setBuffer(bufferSize);
  }, [bufferSize]);

  const onBufferSubmit = () => {
    let finalBufferSize = defaultBuffer;
    if (!Number.isNaN(buffer)) {
      finalBufferSize = Math.max(buffer, minBuffer);
    }

    setBuffer(finalBufferSize);
    dispatch(SetBufferSizeAction(finalBufferSize));
    dispatch(GenerateKmlBufferThunk(roadNetworkBuffer));
  };

  const saveLineKmlFeature = async () => {
    await dispatch(SaveDrawnKmlLine());
  };

  const savePolygonKmlFeature = async () => {
    await dispatch(SaveDrawnKmlPolygon());
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBuffer(parseFloat(e.target.value));
  };

  const handleBlur = () => {
    if (buffer < minBuffer) {
      setBuffer(minBuffer);
    }
  };

  return (
    <Box sx={styles.kmlToolbar}>
      {showBufferInfo ? (
        <>
          <Typography variant="h4">Buffer Distance</Typography>
          <Box sx={styles.bufferForm}>
            <Box sx={styles.bufferText}>
              <TextField
                id="buffer"
                variant="outlined"
                type="number"
                onChange={handleChange}
                inputProps={{ min: minBuffer }}
                value={buffer.toString()}
                sx={styles.textField}
                disabled={creatingBuffer || creatingRoadNetworkBuffer}
                onBlur={handleBlur}
              />
              <Box sx={styles.unitDiv}>
                <Typography variant="h5">ft</Typography>
              </Box>
            </Box>
            <Button
              variant="text"
              color="primary"
              onClick={onBufferSubmit}
            >
              <Fade in={creatingBuffer || creatingRoadNetworkBuffer}>
                <CircularProgress size={16} sx={styles.expressButtonProgress} />
              </Fade>
              <Fade in={!creatingBuffer || !creatingRoadNetworkBuffer}>
                <span>Apply</span>
              </Fade>
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Button
            sx={styles.option}
            onClick={() => {
              dispatch(StartKmlDraw('draw_polygon'));
            }}
          >
            {!kmlLine ? (
              <SVG src={areaActive} title="polygon" css={styles.icon} />
            ) : (
              <SVG src={area} title="polygon" css={styles.icon} />
            )}
          </Button>
          <Button
            sx={styles.option}
            onClick={() => {
              dispatch(StartKmlDraw('draw_line_string'));
            }}
          >
            {kmlLine ? (
              <SVG src={polylineActive} title="line" css={styles.icon} />
            ) : (
              <SVG src={polyline} title="line" css={styles.icon} />
            )}
          </Button>
          {kmlLine && (
            <Button
              variant="text"
              color="primary"
              onClick={saveLineKmlFeature}
            >
              <Fade in={creatingBuffer}>
                <CircularProgress size={16} sx={styles.expressButtonProgress} />
              </Fade>
              <Fade in={!creatingBuffer}>
                <span>Save Line</span>
              </Fade>
            </Button>
          )}
          {!kmlLine && (
            <Button
              variant="contained"
              color="primary"
              onClick={savePolygonKmlFeature}
              disabled={!currentKmlCount}
            >
              <Fade in={uploadingDrawnKml}>
                <CircularProgress size={16} sx={styles.uploadKmlProgress} />
              </Fade>
              <Fade in={!uploadingDrawnKml}>
                <span>Save Processing Area</span>
              </Fade>
            </Button>
          )}
        </>
      )}
    </Box>
  );
};

export default KmlToolBar;
