// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { useState } from 'react';
import {
  Dialog,
  Typography,
  Checkbox,
  FormGroup,
  Button,
  FormControlLabel,
  DialogActions,
  Fade,
  CircularProgress,
} from '@mui/material';
import { Box, useTheme } from '@mui/system';
import stylesDef from './styles';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { SendExpressAIEmailThunk } from 'Features/fileUpload/fileUploadThunk';

const LayerSelectionDialog = ({
  showDialog,
  orderId,
  onCancel
}) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const { emailLoading } = useAppSelector((state) => state.fileUpload);

  const expressAILayers = [
    'Roads',
    'Sidewalks',
    'Curbs',
    'Buildings',
    'Pavement Markings',
    'Manholes',
    'Catch Basins',
    'Water',
    'Vegetation',
    'Contours (Major, Minor)',
    'Obstruction (Areas Obstructing Ground Segmentation)',
  ];
  const [selectedLayers, setSelectedLayers] = useState([]);

  const toggleLayer = (layer: string) => {
    if (selectedLayers.includes(layer)) {
      setSelectedLayers((selectedLayers) =>
        selectedLayers.filter((l) => l !== layer)
      );
    } else {
      setSelectedLayers((selectedLayers) => [...selectedLayers, layer]);
    }
  };

  const onDialogClose = () => {
    setSelectedLayers([]);
    onCancel();
  };

  return (
    <Dialog
      open={showDialog}
      onClose={onDialogClose}
      sx={styles.dialog}
    >
      <Box sx={styles.dialogBox}>
        <Typography variant="h5" sx={styles.dialogText}>
          Express AI layers
        </Typography>
        <Typography>Select below the Express AI layers.</Typography>
        <FormGroup sx={styles.layerList}>
          {expressAILayers.map((layer) => (
            <FormControlLabel
              key={layer}
              value={layer}
              label={<Typography>{layer}</Typography>}
              control={(
                <Checkbox
                  color="primary"
                  onChange={(e) => toggleLayer(e.target.value)}
                  checked={selectedLayers.includes(layer)}
                />
              )}
            />
          ))}
        </FormGroup>
        <DialogActions sx={styles.dialogActions}>
          <Button onClick={onCancel} variant="text">
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch(SendExpressAIEmailThunk(orderId, selectedLayers));
              onDialogClose();
            }}
          >
            <Fade in={emailLoading}>
              <CircularProgress size={24} sx={styles.buttonProgress} />
            </Fade>
            <Fade in={!emailLoading}>
              <span>Send Email</span>
            </Fade>
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default LayerSelectionDialog;
