// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { TransitionProps } from '@mui/material/transitions/transition';
import { Dialog, Slide, IconButton, LinearProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CreateOrgForm from './CreateOrgForm';
import stylesDef from './styles';
import { Box, useTheme } from '@mui/system';

const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => <Slide ref={ref} direction="up" {...props} />);

export const CreateOrgDialog = ({ showOrgDialog, setShowOrgDialog, showLoader }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);

  return (
    <>
      <Dialog
        fullScreen
        open={showOrgDialog}
        onClose={() => setShowOrgDialog(false)}
        TransitionComponent={Transition}
        keepMounted={false}
      >
        {showLoader && (
          <>
            <LinearProgress sx={styles.progress} />
            <Box sx={styles.backdrop} />
          </>
        )}
        <Box sx={styles.closeButtonWrapper}>
          <IconButton
            color="inherit"
            onClick={() => {
              setShowOrgDialog(false);
            }}
            aria-label="Close"
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
        <Box sx={styles.contentWrapper}>
          <CreateOrgForm />
        </Box>
      </Dialog>
    </>
  );
};

export default CreateOrgDialog;
