// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  matchingWrapper: {
    width: '60%',
    margin: '0 auto',
    marginBottom: '100px',
  },
  matchingText: {
    margin: '50px auto',
  },
  description: {
    marginTop: theme.spacing(1),
  },
  layerList: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr auto',
    alignItems: 'center',
  },
  layerListTitle: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr auto',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  autoComplete: {
    width: '80%',
  },
  checkIcon: {
    color: theme.palette.primary.main,
    margin: 'auto 26px',
    fontSize: '20px',
  },
  checkIconHide: {
    color: '#fff',
    margin: 'auto 26px',
    fontSize: '20px',
  },
  matchingProgress: {
    margin: 'auto 26px',
  },
  layerListText: {
    fontSize: '14px',
  },
  outline: {
    boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.1)',
    fontWeight: 300,
    letterSpacing: '1.3px',
    fontSize: '14px',
    '& fieldset': {
      borderRadius: 0,
      borderColor: '#fff',
    },
  },
});

export default styles;
