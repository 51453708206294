/* eslint-disable react/jsx-wrap-multilines */
// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router';
import { datadogRum } from '@datadog/browser-rum';

import { BUILD_VERSION, DD_ENV } from 'Config';

import {
  ROOT_ROUTE, FORGOT_PASSWORD_ROUTE, SIGN_UP_ROUTE, SIGN_UP_SUCCESS, RESET_PASSWORD_ROUTE, PROJECTS_ROUTE,
  PROJECT_DETAILS_ROUTE, PROJECT_FILES_ROUTE, PROJECT_3DVIEWER_ROUTE, ADMIN_OPS_SETTINGS_ROUTE,
  ACCOUNT_GENERAL_ROUTE, ADMIN_PROJECTS_ROUTE, ACCOUNT_BILLING_ROUTE, ACCOUNT_PASSWORD_ROUTE, ACCOUNT_PLAN_USAGE,
  ADMIN_ROUTE, ADMIN_GENERAL_ROUTE, ADMIN_SUBSCRIPTION_ROUTE, ADMIN_USER_ROUTE, ADMIN_PROJECT_ROUTE, ORDER_HISTORY_ROUTE,
  USER_MANAGEMENT_ROUTE, TEAMS_ROUTE, CAD_TEMPLATE_ROUTE, ADMIN_ORG_PLAN_USAGE_ROUTE, EXPIRED_TOKEN_ROUTE, ADMIN_ORDER_HISTORY_ROUTE, ADMIN_PRICING_TIERS_ROUTE, ADMIN_CAD_TEMPLATE_ROUTE,
} from 'Utils/routes';

import Auth from 'Features/auth';
import ForgotPassword from 'Features/auth/ForgotPassword';
import Shell from 'Features/shell';
import ProjectList from 'Features/projectList';
import AccountGeneral from 'Features/account/general';
import AccountBilling from 'Features/account/billing';
import CadTemplates from 'Features/account/cadTemplates';
import AccountPassword from 'Features/account/password';
import OrderHistory from 'Features/account/orderHistory';
import UserManagement from 'Features/account/userManagement';
import Teams from 'Features/account/teams';
import AccountPlanUsage from 'Features/account/planUsage';
import AdminDashboard from 'Features/admin/dashboard';

import './styles/App.css';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import oktaConfig from 'Utils/oktaConfig';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { getPlanDetails } from 'Utils/localStorageService';
import { useAppSelector } from 'Hooks';
import Viewer3D from 'Features/viewer3D';
import PrivateOutlet from 'Components/PrivateOutlet';
import AdminGeneral from 'Features/admin/general';
import AccountOpsSettings from 'Features/admin/opsSettings';
import PricingTiers from 'Features/admin/pricing';
import AdminProject from 'Features/admin/projects/project';
import ResetPassword from 'Features/auth/ResetPassword';
import AdminProjects from 'Features/admin/projects';
import AccountSubscription from 'Features/admin/subscription';
import AdminPlanUsage from 'Features/admin/planUsage';

// Initialize datadog real user management, used for tracking user flows.  Only initialize
// this for non admin portal.
if (!_ADMIN_) {
  datadogRum.init({
    applicationId: 'e31bc513-cb03-44be-a642-5dc5ac649a44',
    clientToken: 'pub5b764e1da0d74719b2e86c281d5329e2',
    site: 'datadoghq.com',
    service: 'airworks-client',
    env: DD_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: BUILD_VERSION,
    sampleRate: 100,
    trackInteractions: true,
  });
}

const AsyncProject = lazy(() => import('Features/project'));
const AsyncProjectFiles = lazy(() => import('Features/files'));

const oktaClient = new OktaAuth(oktaConfig);

const App = () => {
  const ldClient = useLDClient();
  const navigate = useNavigate();
  const restoreOriginalUri = async (oktaAuth: any, originalUri: any) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };
  const location = useLocation();
  const { pathname } = location;

  const { user } = useAppSelector(
    (state) => state.auth,
  );

  let { _id, ownerOrganization, firstName, lastName, email }: any = '';
  if (!_ADMIN_) {
    ({ _id, ownerOrganization, firstName, lastName, email } = user || {});
  }

  const { org } = useAppSelector(
    (state) => state.account,
  );
  const { companyName, availableCreditAcreage, totalPurchasedCreditAcreage, usedCreditAcreage, imageryProviders: orgImageryProviders } = org || {};

  const { plans, expressAISubscription } = useAppSelector(
    (state) => state.subscription,
  );
  const { imageryProviders } = useAppSelector(
    (state) => state.imagery,
  );
  const { resources } = useAppSelector(
    (state) => state.auth,
  );
  const { list: cadTemplateList } = useAppSelector(
    (state) => state.cadTemplate,
  );

  useEffect(() => {
    if (!_ADMIN_) {
      datadogRum.setUser({
        id: _id,
        name: `${firstName} ${lastName}`,
        email,
        organization: companyName,
      });
    }
  }, [pathname]);

  useEffect(() => {
    const { userAgent } = navigator;
    let os = '';
    if (userAgent.indexOf('Win') !== -1) {
      os = 'Windows';
    } else if (userAgent.indexOf('Mac') !== -1) {
      os = 'MacOS';
    } else if (userAgent.indexOf('Linux') !== -1) {
      os = 'Linux';
    } else {
      os = 'Unknown';
    }
    // this check is to avoid sending empty user attributes to launch darkly from public pages
    if (!_ADMIN_ && pathname !== '/' && pathname !== '/forgot' && pathname !== '/signUp') {
      // send user attributes to launch darkly(we use this to target individual users and organizations)
      ldClient.identify({ key: _id, email, custom: { orgId: ownerOrganization } });
    }
    // For testing allow DD_ENV === 'dev'
    // For production DD_ENV === 'prod'
    if (DD_ENV === 'prod' && !_ADMIN_) {
      if (org && expressAISubscription) {
        const isNumber = (value: number | null) => {
          if (typeof (value) === 'number') { return value; }
          return 'N/A';
        };

        let provider;
        if (orgImageryProviders?.length) {
          // In the future, when there are more providers, this function will need to change
          provider = imageryProviders.find((iP: IImageryProvider) => iP._id === orgImageryProviders[0]);
        }

        const { automateStartingAcres } = JSON.parse(getPlanDetails());
        const cadTemplates = cadTemplateList?.map((cad: ICadTemplate) => cad?.name);
        window.pendo?.initialize({
          visitor: {
            id: _id, // User Id
            email,
            available_acreage: isNumber(availableCreditAcreage),
            used_acreage: isNumber(usedCreditAcreage),
            purchased_acreage: isNumber(totalPurchasedCreditAcreage),
            most_recent_purchase: isNumber(automateStartingAcres),
            company: companyName,
            resources: Object.keys(resources),
            plans: plans.map((plan) => plan.nickname),
            nearmapTier: provider?.name || 'no subscription',
            operating_system: os,
            cadTemplates,
            expressAI: !!expressAISubscription?.name,
          },
          account: {
            id: ownerOrganization, // Org Id
          },
        });
      }
    }
  }, [org, plans, expressAISubscription]);

  return (
    _ADMIN_ ? (
      <Security
        oktaAuth={oktaClient}
        restoreOriginalUri={restoreOriginalUri}
      >
        <Routes>
          <Route path={ROOT_ROUTE} element={<Auth />} />
          <Route path="/callback" element={<LoginCallback />} />
          <Route path={EXPIRED_TOKEN_ROUTE} element={<Auth />} />
          <Route element={<Shell />}>
            <Route element={<PrivateOutlet />}>
              <Route path={ADMIN_ROUTE} element={<AdminDashboard />} />
              <Route path={ADMIN_OPS_SETTINGS_ROUTE} element={<AccountOpsSettings />} />
              <Route path={ADMIN_PRICING_TIERS_ROUTE} element={<PricingTiers />} />
              <Route path={ADMIN_GENERAL_ROUTE} element={<AdminGeneral />} />
              <Route path={ADMIN_PROJECT_ROUTE} element={<AdminProject />} />
              <Route path={ADMIN_PROJECTS_ROUTE} element={<AdminProjects />} />
              <Route path={ADMIN_SUBSCRIPTION_ROUTE} element={<AccountSubscription />} />
              <Route path={ADMIN_USER_ROUTE} element={<UserManagement />} />
              <Route path={ADMIN_ORG_PLAN_USAGE_ROUTE} element={<AdminPlanUsage />} />
              <Route path={ADMIN_ORDER_HISTORY_ROUTE} element={<OrderHistory />} />
              <Route path={ADMIN_CAD_TEMPLATE_ROUTE} element={<CadTemplates />} />
            </Route>
          </Route>
        </Routes>
      </Security>
    ) : (
      <Routes>
        <Route path={ROOT_ROUTE} element={<Auth />} />
        <Route path={EXPIRED_TOKEN_ROUTE} element={<Auth />} />
        <Route path={SIGN_UP_ROUTE} element={<Auth />} />
        <Route path={SIGN_UP_SUCCESS} element={<Auth />} />
        <Route path={FORGOT_PASSWORD_ROUTE} element={<ForgotPassword />} />
        <Route path={RESET_PASSWORD_ROUTE} element={<ResetPassword />} />
        <Route element={<Shell />}>
          <Route element={<PrivateOutlet />}>
            <Route path={PROJECTS_ROUTE} element={<ProjectList />} />
            <Route path={ACCOUNT_GENERAL_ROUTE} element={<AccountGeneral />} />
            <Route path={ACCOUNT_BILLING_ROUTE} element={<AccountBilling />} />
            <Route path={ACCOUNT_PASSWORD_ROUTE} element={<AccountPassword />} />
            <Route path={ORDER_HISTORY_ROUTE} element={<OrderHistory />} />
            <Route path={CAD_TEMPLATE_ROUTE} element={<CadTemplates />} />
            <Route path={USER_MANAGEMENT_ROUTE} element={<UserManagement />} />
            <Route path={TEAMS_ROUTE} element={<Teams />} />
            <Route path={ACCOUNT_PLAN_USAGE} element={<AccountPlanUsage />} />
            <Route
              path={PROJECT_DETAILS_ROUTE}
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <AsyncProject />
                </Suspense>
              }
            />
            <Route
              path={PROJECT_FILES_ROUTE}
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <AsyncProjectFiles />
                </Suspense>
              }
            />
            <Route path={PROJECT_3DVIEWER_ROUTE} element={<Viewer3D />} />
          </Route>
        </Route>
      </Routes>
    )
  );
};

export default App;
