// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useMap, Source, Layer } from 'react-map-gl';
import { API_URL } from 'Config';
import { getOrderIds } from 'Features/order/orderSelectors';
import { RootState } from 'Store';
import VectorLayer from './VectorLayer';

interface OrderVectorLayersProps {
  orderId: string;
  fileVersions: any;
  vectorTileJson: any;
  map: any;
}

const OrderVectorLayers: React.FC<OrderVectorLayersProps> = ({ orderId, fileVersions, vectorTileJson, map }) => {
  const fileVersion = fileVersions[orderId];
  const tileJson = fileVersion && vectorTileJson[orderId] && vectorTileJson[orderId][fileVersion];
  const sourceId = `default-${orderId}`;

  if (!tileJson || !tileJson.visible) return null;

  return (
    <Source
      id={sourceId}
      type="vector"
      tiles={[`${API_URL}/tiles/${fileVersion}/{z}/{x}/{y}.mvt`]}
    >
      {tileJson.vector_layers.map((layer: any) => (
        <VectorLayer
          key={`${orderId}-${layer.id}`}
          id={`default-${orderId}-${layer.id}`}
          orderId={orderId}
          fileVersion={fileVersion}
          sourceId={sourceId}
          sourceLayerId={layer.id}
          color={layer.color}
          lineWidth={layer.lineWidth}
          map={map}
        />
      ))}
    </Source>
  );
};

interface ComponentProps {
  showLayers: boolean;
}

const MapLayers: React.FC<ComponentProps> = ({ showLayers, vectorTileJson, orderIds, fileVersions, layerSelected, dxfExtent }) => {
  const { current: map } = useMap();

  useEffect(() => {
    if (_ADMIN_ && dxfExtent) {
      map.once('idle', () => {
        map.fitBounds([
          [dxfExtent[2], dxfExtent[3]], // southwestern corner of the bounds
          [dxfExtent[0], dxfExtent[1]], // northeastern corner of the bounds
        ], {
          padding: 20,
        });
      });
    }
  }, [dxfExtent]);

  const vectorReady =
    showLayers &&
    vectorTileJson;

  return (
    <>
      {/* Layers order is important so we need to always add tiles layers before draw layers */}
      <Source id="vectorSource" type="geojson" data={{ type: 'FeatureCollection', features: [] }}>
        <Layer id="vectorStub" source="vectorSource" type="fill" />
      </Source>
      {vectorReady && orderIds.map((orderId) => (
        <OrderVectorLayers
          key={orderId}
          orderId={orderId}
          fileVersions={fileVersions}
          vectorTileJson={vectorTileJson}
          map={map}
        />
      ))}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  vectorTileJson: state.map.common.vectorTileJson,
  orgId: _ADMIN_ ? state.admin?.orgId : state.auth?.user?.ownerOrganization,
  projectId: state.project.projectId,
  orderIds: getOrderIds(state),
  fileVersions: state.order.fileVersions,
  changeVersion: state.map.common.changeVersion,
  currentLayer: state.map.editor.present.currentLayer,
  dxfExtent: state.map.common.dxfExtent,
});

export default connect(
  mapStateToProps,
  null,
  null,
  {
    areStatePropsEqual: (next, prev) => (
      next.changeVersion === prev.changeVersion &&
      next.projectId === prev.projectId &&
      next.dxfExtent === prev.dxfExtent
    ),
  },
)(MapLayers);
