// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  pageTitle: {
    textAlign: 'center',
    marginBottom: '30px',
  },
  form: {
    margin: '0 auto',
    width: 750,
  },
  fields: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  buttonsWrapper: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'space-around',
  },
  uploadFormSubmit: {
    paddingLeft: theme.spacing(11),
    paddingRight: theme.spacing(11),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: 'black',
  },
  datePicker: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    '& .MuiOutlinedInput-notchedOutline': {
      boxShadow: theme.lightShadow,
      borderRadius: 0,
      borderColor: 'rgba(0,0,0,0.1)',
    },
  },
  calender: {
    transform: 'translate(56px, 15px) !important',
  },
  textFiled: {
    '& .MuiOutlinedInput-notchedOutline': {
      boxShadow: theme.lightShadow,
      borderRadius: 0,
      borderColor: 'rgba(0,0,0,0.1)',
    },
  },
  projectType: {
    width: '100%',
    height: '56px',
    marginTop: '16px',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
});

export default styles;
