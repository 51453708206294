// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createSlice } from '@reduxjs/toolkit';
import { Clear3DLineworkAction, Get3DLineworkSuccessAction, SetCad3DLayersAction, SetClassVisibleAction, SetLayer3DChangeAction, SetOrder3DCadFilesListAction, SetSelectedCadFile3DLayersAction, SetToggleClassAction, Toggle3DLayerAction, Toggle3DOrderAction, ToggleAll3DLayersAction } from './viewer3DActions';

const initialState: IViewer3DState = {
  order3DCadFilesList: null,
  selected3DCadFiles: {},
  cad3dLayers: {},
  layersLoading: false,
  linework3D: [],
  layer3DChange: [],
  classVisible: {},
  toggleClass: null,
};

const viewer3DSlice = createSlice({
  name: 'viewer3D',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SetSelectedCadFile3DLayersAction, (state, action) => {
        const { orderId, cadFile3DLayers } = action.payload;
        state.selected3DCadFiles[orderId] = cadFile3DLayers;
      })
      .addCase(Get3DLineworkSuccessAction, (state, action) => {
        action.payload.forEach((site) => {
          const { site_id } = site;
          if (state.cad3dLayers[site_id]) {
            state.cad3dLayers[site_id].dataAvailable = true;
          }
        });
        state.linework3D = action.payload;
      })
      .addCase(Toggle3DLayerAction, (state, action) => {
        const { siteId, layerId } = action.payload;
        const layer = state.cad3dLayers[siteId]?.layers?.find((l: any) => l.layer_id === layerId);
        if (layer) {
          layer.visible = !layer.visible;
        }
      })
      .addCase(ToggleAll3DLayersAction, (state, action) => {
        const { siteId, toggle } = action.payload;
        state.cad3dLayers[siteId]?.layers?.forEach(
          (layer) => { layer.visible = toggle; },
        );
      })
      .addCase(Toggle3DOrderAction, (state, action) => {
        const order = state.selected3DCadFiles[action.payload];
        order.visible = !order.visible;
      })
      .addCase(SetLayer3DChangeAction, (state, action) => {
        state.layer3DChange = action.payload;
      })
      .addCase(SetCad3DLayersAction, (state, action) => {
        state.cad3dLayers = action.payload;
      })
      .addCase(SetOrder3DCadFilesListAction, (state, action) => {
        state.order3DCadFilesList = action.payload;
      })
      .addCase(Clear3DLineworkAction, (state) => {
        state.linework3D = [];
      })
      .addCase(SetClassVisibleAction, (state, action) => {
        state.classVisible = action.payload;
      })
      .addCase(SetToggleClassAction, (state, action) => {
        state.toggleClass = action.payload;
      });
  },
});

export const { reducer } = viewer3DSlice;

export default reducer;
