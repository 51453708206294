// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL
/* eslint-disable no-template-curly-in-string */

import React, { useState, useEffect, useCallback } from 'react';
import * as Cesium from 'cesium';
import { Paper, Divider, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, useTheme } from '@mui/system';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { classification2Classes, classification6Classes } from 'Utils/constants';
import stylesDef from './styles';
import FileErrors from './FileErrors';
import { SetToggleClassAction } from '../viewer3DActions';

Cesium.Ion.defaultAccessToken = _CESIUM_KEY_;
const terrain = Cesium.Terrain.fromWorldTerrain();
const mapboxImagery = new Cesium.ImageryLayer(new Cesium.MapboxImageryProvider({
  mapId: 'mapbox.satellite',
  accessToken: _MAPBOX_KEY_,
}));

interface Map3DProps {
  lasFilesWith3DTile: ILasFile[];
  showPointCloud: boolean;
  showTerrain: boolean;
  showClassifiedPointCloud: {[key: string]: boolean};
  resetZoom: boolean;
  setResetZoom: (show: boolean) => void;
  lasFilesDisplayStatuses: any;
}

const Map3D = ({ lasFilesWith3DTile, showPointCloud, showClassifiedPointCloud, showTerrain, resetZoom, lasFilesDisplayStatuses, setResetZoom }: Map3DProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const [viewer, setViewer] = useState(null);
  const [tilesets, setTilesets] = useState(null);
  const [ppsm, setPpsm] = useState(null);
  const [layerDataSource] = useState<{ [key: string]: any }>({});
  const { linework3D, layer3DChange, cad3dLayers } = useAppSelector(
    (state) => state.viewer3D,
  );
  const toggleClass = useAppSelector((state) => state.viewer3D.toggleClass);
  const classVisible = useAppSelector((state) => state.viewer3D.classVisible);

  useEffect(() => {
    tilesets?.forEach((tileset: any) => {
      if (tileset.orderId) {
        tileset.show = showClassifiedPointCloud[tileset.orderId];
      } else {
        const tilesetFileName = tileset.basePath.split('/').slice(-2, -1)[0].replace(/_3d_tile$/, '');
        tileset.show = showPointCloud && lasFilesDisplayStatuses[tilesetFileName];
      }
    });
  }, [showPointCloud, showClassifiedPointCloud, lasFilesDisplayStatuses]);

  useEffect(() => {
    for (let i = 0; i < layer3DChange.length; i += 2) {
      const name = layer3DChange[i];
      if (layerDataSource[name]) {
        layerDataSource[name].show = layer3DChange[i + 1];
      }
    }
  }, [layer3DChange]);

  useEffect(() => {
    if (toggleClass) {
      const tileset = tilesets.find((tile: any) => tile.orderId === toggleClass);
      const lasVisible = classVisible[toggleClass];
      let classArray: any = [];
      if (tileset.classNumber === 2) {
        classArray = classification2Classes;
      } else if (tileset.classNumber === 6) {
        classArray = classification6Classes;
      }

      const showConditions = Object.keys(lasVisible).map((layer) => {
        const classInfo = classArray.find((item: any) => item.name === layer);
        return [`\${Classification}===${classInfo.classId}`, lasVisible[layer] ? 'true' : 'false'];
      });

      let newStyle;
      if (tileset.classNumber === 2) {
        newStyle = new Cesium.Cesium3DTileStyle({
          color: {
            conditions: [
              ['${Classification}===1', `color('${classification2Classes[0].color}')`],
              ['${Classification}===2', `color('${classification2Classes[1].color}')`],
              ['true', "color('white')"],
            ],
          },
          show: {
            conditions: showConditions,
          },
        });
      } else if (tileset.classNumber === 6) {
        newStyle = new Cesium.Cesium3DTileStyle({
          color: {
            conditions: [
              ['${Classification}===1', `color('${classification6Classes[0].color}')`],
              ['${Classification}===2', `color('${classification6Classes[1].color}')`],
              ['${Classification}===3', `color('${classification6Classes[2].color}')`],
              ['${Classification}===4', `color('${classification6Classes[3].color}')`],
              ['${Classification}===5', `color('${classification6Classes[4].color}')`],
              ['${Classification}===6', `color('${classification6Classes[5].color}')`],
              ['true', "color('white')"],
            ],
          },
          show: {
            conditions: showConditions,
          },
        });
      }
      tileset.style = newStyle;
      dispatch(SetToggleClassAction(null));
    }
  }, [toggleClass]);

  useEffect(() => {
    if (viewer) {
      if (showTerrain) {
        viewer.scene.globe.show = true;
        viewer.terrain = terrain;
      } else {
        viewer.scene.globe.show = false;
      }
    }
  }, [showTerrain]);

  useEffect(() => {
    if (viewer && tilesets?.length && resetZoom) {
      try {
        viewer.zoomTo(
          tilesets[0],
          new Cesium.HeadingPitchRange(
            0.0,
            -0.5,
            tilesets[0].boundingSphere.radius * 2.0,
          ),
        );
      } catch (error) {
        // zoom to failed
      }
      setResetZoom(false);
    }
  }, [resetZoom]);

  useEffect(() => {
    if (viewer) {
      linework3D.forEach((site) => {
        const { site_id, data } = site;
        data.forEach((layer) => {
          const layerName = `${site_id}-${layer.layer_id}`;
          if (!layerDataSource[layerName]) {
            const lineCollection = new Cesium.PolylineCollection();
            viewer.scene.primitives.add(lineCollection);
            layer.offset.forEach((line) => {
              if (line.length % 3 === 0) {
                lineCollection.add({
                  positions: Cesium.Cartesian3.fromDegreesArrayHeights(line),
                  width: 2,
                  material: Cesium.Material.fromType('Color', {
                    color: Cesium.Color.fromCssColorString(cad3dLayers[site_id]?.layers?.find((l) => l.layer_id === layer.layer_id)?.color || '#ffffff'),
                  }),
                });
              }
            });
            layerDataSource[layerName] = lineCollection;
          }
        });
      });
    }
  }, [linework3D, viewer]);

  useEffect(() => {
    const load3dTile = async () => {
      const outputLasExist = lasFilesWith3DTile.some((las) => las.ownerOrder);
      const lasTilesets = await Promise.all(lasFilesWith3DTile.map(async (lasFile) => {
        // eslint-disable-next-line no-restricted-globals
        const url = isNaN(lasFile.tile3DUrl) ? lasFile.tile3DUrl : Cesium.IonResource.fromAssetId(+lasFile.tile3DUrl);
        const newTileset = await Cesium.Cesium3DTileset.fromUrl(url);
        if (lasFile.ownerOrder && lasFile.classes === 6) {
          newTileset.orderId = lasFile.ownerOrder;
          newTileset.classNumber = 6;
          newTileset.style = new Cesium.Cesium3DTileStyle({
            color: {
              conditions: [
                ['${Classification}===1', `color('${classification6Classes[0].color}')`],
                ['${Classification}===2', `color('${classification6Classes[1].color}')`],
                ['${Classification}===3', `color('${classification6Classes[2].color}')`],
                ['${Classification}===4', `color('${classification6Classes[3].color}')`],
                ['${Classification}===5', `color('${classification6Classes[4].color}')`],
                ['${Classification}===6', `color('${classification6Classes[5].color}')`],
                ['true', "color('white')"],
              ],
            },
          });
        } else if (lasFile.ownerOrder && lasFile.classes === 2) {
          newTileset.orderId = lasFile.ownerOrder;
          newTileset.classNumber = 2;
          newTileset.style = new Cesium.Cesium3DTileStyle({
            color: {
              conditions: [
                ['${Classification}===1', `color('${classification2Classes[0].color}')`],
                ['${Classification}===2', `color('${classification2Classes[1].color}')`],
                ['true', "color('white')"],
              ],
            },
          });
        } else if (outputLasExist && !lasFile.ownerOrder) {
          newTileset.show = false;
        }
        viewer.scene.primitives.add(newTileset);
        return newTileset;
      }));

      setTilesets(lasTilesets);

      // Zoom to the first tile
      viewer.zoomTo(
        lasTilesets[0],
        new Cesium.HeadingPitchRange(
          0.0,
          -0.5,
          lasTilesets[0].boundingSphere.radius * 2.0,
        ),
      );
    };

    if (!tilesets && viewer) {
      const { totalPoints, totalAcreage } = lasFilesWith3DTile.filter((lasFile) => !lasFile.ownerOrder && lasFile.acreage > 0).reduce((acc, curr) => {
        const acreageInSquareMeters = curr.acreage * 4046.85642;
        return {
          totalPoints: acc.totalPoints + curr.points,
          totalAcreage: acc.totalAcreage + acreageInSquareMeters,
        };
      }, { totalPoints: 0, totalAcreage: 0 });
      const pointsPerSquareMeter = totalPoints / totalAcreage;
      setPpsm(pointsPerSquareMeter.toFixed(3));

      load3dTile();
    }
  }, [lasFilesWith3DTile, viewer]);

  const mapRef = useCallback((node) => {
    if (node !== null) {
      const newViewer = new Cesium.Viewer(node, {
        terrain,
        animation: false,
        baseLayerPicker: false,
        fullscreenButton: false,
        geocoder: false,
        homeButton: false,
        infoBox: false,
        sceneModePicker: false,
        selectionIndicator: false,
        timeline: false,
        navigationInstructionsInitiallyVisible: false,
        baseLayer: mapboxImagery,
        creditContainer: 'creditContainer',
      });
      newViewer.scene.globe.show = false;
      newViewer.scene.skyAtmosphere.destroy();
      newViewer.scene.skyBox.destroy();
      newViewer.scene.sun.destroy();
      newViewer.scene.globe.baseColor = Cesium.Color.BLACK;
      newViewer.scene.screenSpaceCameraController.minimumZoomDistance = 5;
      setViewer(newViewer);
    }
  }, [terrain]);

  const zoomIn = () => {
    viewer.camera.zoomIn(30);
  };

  const zoomOut = () => {
    viewer.camera.zoomOut(30);
  };

  return (
    <>
      <FileErrors />
      <Box sx={styles.infoBar}>
        {ppsm > 0 && (
          <Typography sx={styles.pointsText} variant="body1">
            {`${ppsm} pts/m`}
            <sup>2</sup>
          </Typography>
        )}
        <Box sx={styles.creditContainer} key="creditContainer" id="creditContainer" />
      </Box>
      <Box sx={styles.map} ref={mapRef} />
      <Paper elevation={1} square sx={styles.mapControls}>
        <Button sx={styles.mapButton} onClick={zoomIn}>
          <AddIcon />
        </Button>
        <Divider sx={styles.divider} />
        <Button sx={styles.mapButton} onClick={zoomOut}>
          <RemoveIcon />
        </Button>
      </Paper>
    </>
  );
};

export default Map3D;
