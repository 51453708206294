// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';

interface ComponentProps {
  showDialog: boolean;
  onOk: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onCancel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  contentText: string;
}

export const ConfirmDialog: React.FC<ComponentProps> = ({ showDialog, onOk, onCancel, contentText }) => (
  <Dialog
    open={showDialog}
    onClose={onCancel}
  >
    <DialogTitle>Are you sure?</DialogTitle>
    <DialogContent>{contentText}</DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>Cancel</Button>
      <Button onClick={onOk}>Ok</Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmDialog;
