// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  expansionPanelHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    margin: 0,
  },
  wrapper: {
    paddingBottom: theme.spacing(1),
  },
  dataSetRow: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
  },
  filesList: {
    display: 'grid',
    gridTemplateColumns: '1fr auto auto',
    alignItems: 'center',
  },
  file: {
    marginTop: '5px',
  },
  button: {
    padding: '6px',
  },
});

export default styles;
