// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { Typography, Avatar, Divider, IconButton, Snackbar, Button } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { useParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Alert from '@mui/material/Alert';
import HelpIcon from '@mui/icons-material/Help';
import { useAppDispatch, useAppSelector } from 'Hooks';
import LoadingSpinner from 'Components/LoadingSpinner';
import { TeamTooltip } from 'Components/Tooltip';
import ConfirmDialog from 'Components/ConfirmDialog';
import { ClearOrgAccountAction } from 'Features/account/accountActions';
import { GetOrgThunk } from 'Features/admin/adminThunk';
import { SetOrgIdAction } from 'Features/admin/adminActions';
import { GetOrdersThunk } from 'Features/order/orderThunk';
import { DeleteUserThunk } from '../accountThunk';
import { GetOrgSubscriptionThunk } from '../subscriptionThunk';
import UserForm from './UserForm';
import stylesDef from './styles';

const defaultActiveUser: IActiveUser = {
  firstName: '',
  lastName: '',
  fullName: '',
  email: '',
  _id: '',
  rolesArray: [],
  teams: [],
  jobTitle: '',
  phoneNumber: '',
};

export const UserManagement = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const { orgId } = useParams();
  const orgUsers = useAppSelector((state) => state.account.orgUsers);
  const orgRoles = useAppSelector((state) => state.account.orgRoles);
  const org = useAppSelector((state) => state.admin.org);
  const orders = useAppSelector((state) => state.order.list);
  const dispatch = useAppDispatch();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [deleteUser, setDeleteUser] = useState('');
  const [showUserForm, setShowUserForm] = useState(false);
  const [userFormMode, setUserFormMode] = useState('add');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showError, setShowError] = useState(false);
  const [deletePocError, setDeletePocError] = useState(false);

  const [activeUser, setActiveUser] = useState(defaultActiveUser);
  // store the email of the current POC
  const [pocEmail, setPocEmail] = useState(org?.email);

  useEffect(() => {
    dispatch(ClearOrgAccountAction());
    if (_ADMIN_) {
      dispatch(SetOrgIdAction(orgId));
      dispatch(GetOrgThunk(orgId));
    }
  }, []);

  useEffect(() => {
    if (_ADMIN_ && org) {
      setPocEmail(org?.email);
    }
  }, [org]);

  useEffect(() => {
    const loadData = async () => {
      setShowLoader(true);
      await dispatch(GetOrdersThunk(null, orgId));
      setShowLoader(false);
    };

    loadData();
  }, []);

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbar(false);
  };

  const handleErrorSnackbarClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowError(false);
  };

  const renderColumnHeader = (columnMeta: any) => (
    <td key={columnMeta.name} style={styles.columnHeader}>
      <Typography variant="h4">{columnMeta.label}</Typography>
    </td>
  );

  const deleteUserClick = (userId: string, email: string) => {
    // remove error snackbar when a delete button is clicked
    setDeletePocError(false);
    setShowError(false);
    setShowConfirmDialog(true);
    setDeleteUser(email);
  };

  const onOk = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDialog(false);
    const result = await dispatch(DeleteUserThunk(deleteUser));
    if (result.success) {
      dispatch(GetOrgSubscriptionThunk(_ADMIN_ ? orgId : undefined));
      setShowSnackbar(true);
    } else {
      setShowError(true);
      if (_ADMIN_ && deleteUser === pocEmail) setDeletePocError(true);
    }
    e.stopPropagation();
  };

  const getErrorMessage = () => {
    if (deletePocError) {
      return 'Unable to delete user. To delete, assign another user as POC';
    }
    return 'Unable to Delete User';
  };

  const AddOrgUserDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setActiveUser(defaultActiveUser);
    setUserFormMode('add');
    setShowUserForm(true);
    e.stopPropagation();
  };

  const EditOrgUserDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, _id: string, rolesArray: [string]) => {
    const orgUser = orgUsers.find((user) => user._id === _id);
    setActiveUser({ ...orgUser, rolesArray });
    setUserFormMode('edit');
    setShowUserForm(!showUserForm);
    e.stopPropagation();
  };

  const onCancel = () => {
    setShowConfirmDialog(false);
  };

  const getAvatarText = (value: string) => {
    const split = value && value.split(' ');
    const firstName = split && split[0];
    const lastName = split && split[1];
    const char1 = (firstName && firstName[0]) || '';
    const char2 = (lastName && lastName[0]) || '';

    return `${char1}${char2}`;
  };

  const getAccesstring = (roles: any, email: string) => {
    let assignedRoles: string = '';
    if (_ADMIN_ && pocEmail) {
      if (pocEmail === email) {
        assignedRoles += 'POC | ';
      }
    }
    if (orgRoles && roles) {
      roles.forEach((role: any) => {
        let roleString = '';
        if (orgRoles[role] === 'processing') {
          roleString = 'AUTOMATE';
        }
        if (orgRoles[role] === 'cad') {
          roleString = 'CAD';
        }
        if (orgRoles[role] === 'admin') {
          roleString = 'ADMIN';
        }
        if (roleString !== '') {
          assignedRoles += `${roleString} | `;
        }
      });
      return assignedRoles.slice(0, -3);
    }
    return 'No role assigned';
  };

  const gotRolesAsStrings = (roles: [string]) => {
    const assignedRoles: any = [];
    if (orgRoles && roles) {
      roles.forEach((role: any) => {
        assignedRoles.push(orgRoles[role]);
      });
    }
    return assignedRoles;
  };

  const columns = [
    {
      name: 'fullName',
      label: '  ',
      options: {
        customHeadRender: renderColumnHeader,
        customBodyRender: (value: string) => (
          <Avatar sx={styles.avatar}>{getAvatarText(value)}</Avatar>
        ),
      },
    },
    {
      name: 'firstName',
      label: 'FIRST NAME',
      options: { customHeadRender: renderColumnHeader },
    },
    {
      name: 'lastName',
      label: 'LAST NAME',
      options: { customHeadRender: renderColumnHeader },
    },
    {
      name: 'email',
      label: 'EMAIL',
      options: { customHeadRender: renderColumnHeader },
    },
    {
      name: 'jobTitle',
      label: 'JOB TITLE',
      options: { customHeadRender: renderColumnHeader },
    },
    {
      name: 'phoneNumber',
      label: 'PHONE NUMBER',
      options: { customHeadRender: renderColumnHeader },
    },
    {
      name: 'role',
      label: 'ACCESS',
      options: {
        customHeadRender: renderColumnHeader,
        customBodyRender: (value: any, tableMeta: { rowData : any }) => (
          <Box sx={styles.nameColumn}>
            {orgRoles && getAccesstring(value, tableMeta.rowData[3])}
          </Box>
        ),
      },
    },
    {
      name: 'teams',
      label: 'TEAM',
      options: {
        customHeadRender: renderColumnHeader,
        customBodyRender: (teams: boolean, tableMeta: { rowData : any }) => (
          // eslint-disable-next-line no-nested-ternary
          tableMeta.rowData[7].length > 1 ? (
            <Box sx={styles.info}>
              <TeamTooltip
                placement="top"
                interactive
                title={(
                  tableMeta.rowData[7].length > 1 && (
                    <ul sx={styles.unOrderList}>
                      {tableMeta.rowData[7].map((team: any) => (
                        <li key={team?.name}>{team && team.name}</li>
                      ))}
                    </ul>
                  )
                )}
              >
                <HelpIcon sx={styles.icon} />
              </TeamTooltip>
              <Typography>{`${tableMeta.rowData[7].length} Teams`}</Typography>
            </Box>
          ) : tableMeta.rowData[7].length === 1 ? (
            <Box sx={styles.info}>
              <Typography sx={styles.onlyTeam}>{`${tableMeta.rowData[7][0] && tableMeta.rowData[7][0].name}`}</Typography>
            </Box>
          ) : '-'
        ),
      },
    },
    {
      name: 'admin',
      empty: true,
      options: {
        customHeadRender: () => null,
        customBodyRender: (admin: boolean, tableMeta: { rowData : any }) => (
          <Box sx={styles.iconButtons}>
            <IconButton
              onClick={(e) => EditOrgUserDialog(e, tableMeta.rowData[9], gotRolesAsStrings(tableMeta.rowData[6]))}
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton
              onClick={() => deleteUserClick(tableMeta.rowData[9], tableMeta.rowData[3])}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Box>
        ),
      },
    },
    {
      name: '_id',
      label: '_id',
      options: { display: false },
    },
  ];

  const options = {
    elevation: 0,
    responsive: 'stacked',
    serverSide: true,
    selectableRows: 'none',
    print: false,
    download: false,
    viewColumns: false,
    sort: false,
    search: false,
    filter: false,
    pagination: false,
  };

  return (
    <LoadingSpinner loading={showLoader}>
      <Box sx={styles.pageWrapper}>
        <Snackbar sx={styles.snackBar} open={showSnackbar} autoHideDuration={10000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert severity="success" sx={styles.alert} color="info">User Deleted</Alert>
        </Snackbar>
        <Snackbar sx={styles.snackBar} open={showError} autoHideDuration={10000} onClose={handleErrorSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert severity="error" sx={styles.error} color="error">{getErrorMessage()}</Alert>
        </Snackbar>
        <Box sx={styles.planOptions}>
          <Box>
            <Typography sx={styles.headerText} variant="h2">Users Management</Typography>
          </Box>
          <Button
            color="primary"
            variant="contained"
            sx={styles.addNewUser}
            onClick={AddOrgUserDialog}
          >
            <Typography variant="h4" sx={styles.addUserButton}>ADD NEW USER</Typography>
          </Button>
        </Box>
        <>
          <UserForm userFormMode={userFormMode} showUserForm={showUserForm} setShowUserForm={setShowUserForm} activeUser={activeUser} pocEmail={pocEmail} setActiveUser={setActiveUser} setPocEmail={setPocEmail} />
          <Divider sx={styles.divider} />
          <MUIDataTable
            data={orgUsers}
            columns={columns}
            options={options}
            sx={styles.table}
          />
          <ConfirmDialog
            showDialog={showConfirmDialog}
            onOk={onOk}
            onCancel={onCancel}
            contentText="Any open/completed order(s) created by this user will be assigned to the Organization Admin. Are you sure you want to delete this user? This action cannot be undone."
          />
        </>
      </Box>
    </LoadingSpinner>
  );
};

export default UserManagement;
