// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Typography, Checkbox, FormControlLabel, ListItem, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import homeIcon from 'Assets/home.png';
import { Box, useTheme } from '@mui/system';
import { useAppSelector } from 'Hooks';
import Sidebar from 'Components/Sidebar';
import Order3DLayers from './Order3DLayers';
import stylesDef from './styles';

interface Viewer3DSidebarProps {
  lasFilesWith3DTile: ILasFile[];
  showPointCloud: boolean;
  showTerrain: boolean;
  setShowPointCloud: (show: boolean) => void;
  setShowTerrain: (show: boolean) => void;
  setResetZoom: (show: boolean) => void;
  toggleLasVisibility: (filename: string, visible: boolean) => void;
  lasFilesDisplayStatuses: any;
  showClassifiedPointCloud: {[key: string]: boolean};
  setShowClassifiedPointCloud: (show: {[key: string]: boolean}) => void;
}

const Viewer3DSidebar = ({
  lasFilesWith3DTile,
  showPointCloud,
  showTerrain,
  lasFilesDisplayStatuses,
  showClassifiedPointCloud,
  setShowClassifiedPointCloud,
  setShowPointCloud,
  setShowTerrain,
  setResetZoom,
  toggleLasVisibility,
}: Viewer3DSidebarProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const { order3DCadFilesList } = useAppSelector(
    (state) => state.viewer3D,
  );
  const orders = Object.keys(order3DCadFilesList);
  const inputLas = lasFilesWith3DTile.filter((las) => !las.ownerOrder);

  return (
    <Sidebar>
      <Box sx={styles.sidebarContent}>
        <Typography variant="h5" sx={styles.title}>LAYERS</Typography>
        {orders.map((orderId, index) => (
          <Order3DLayers orderId={orderId} orderNumber={orders.length - index} lasFilesWith3DTile={lasFilesWith3DTile} showClassifiedPointCloud={showClassifiedPointCloud} setShowClassifiedPointCloud={setShowClassifiedPointCloud} setResetZoom={setResetZoom} />
        ))}
        <Box sx={styles.layers}>
          <Box sx={styles.pointCloudSelection}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={showPointCloud}
                  onChange={() => setShowPointCloud(!showPointCloud)}
                  name="pointCloud"
                  color="primary"
                />
              )}
              label={<Typography variant="h5" sx={styles.layerName}>Point Cloud</Typography>}
            />
            <IconButton onClick={() => setResetZoom(true)}>
              <img src={homeIcon} alt="reset zoom" />
            </IconButton>
          </Box>
          <Box sx={styles.lasfileContainer}>
            {inputLas.map((las) => (
              <ListItem sx={styles.lasFileListItem} key={las._id}>
                <Typography variant="h5" sx={styles.lasfile}>{las.name}</Typography>
                <Box sx={styles.flexItem}>
                  {
                    lasFilesDisplayStatuses[las.lasFilename] ?
                      <VisibilityIcon sx={styles.icon} fontSize="small" onClick={() => toggleLasVisibility(las.lasFilename, false)} /> :
                      <VisibilityOffIcon sx={styles.icon} fontSize="small" onClick={() => toggleLasVisibility(las.lasFilename, true)} />
                  }
                </Box>
              </ListItem>
            ))}
          </Box>
          <FormControlLabel
            control={(
              <Checkbox
                checked={showTerrain}
                onChange={() => setShowTerrain(!showTerrain)}
                name="terrain"
                color="primary"
              />
            )}
            label={<Typography variant="h5" sx={styles.layerName}>Terrain</Typography>}
          />
        </Box>
      </Box>
    </Sidebar>
  );
};

export default Viewer3DSidebar;
