// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import createVertex from '@mapbox/mapbox-gl-draw/src/lib/create_vertex';
import createMidpoint from '@mapbox/mapbox-gl-draw/src/lib/create_midpoint';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';

function createSupplementaryPoints(geojson, options = {}, basePath = null) {
  const { type, coordinates } = geojson.geometry;
  const featureId = geojson.properties && geojson.properties.id;

  let supplementaryPoints = [];

  if (type === Constants.geojsonTypes.POINT) {
    // For points, just create a vertex
    supplementaryPoints.push(
      createVertex(featureId, coordinates, basePath, isSelectedPath(basePath)),
    );
    // begin change
  } else if ((geojson.properties.user_curve || [])[0] === 'compoundcurve') {
    // we ignore basePath because we don't support multi-linestrings/multi-compoundcurves/multi-curvepolygons ? maybe?
    processCompoundCurve(geojson.properties.user_curve, basePath);
  } else if ((geojson.properties.user_curve || [])[0] === 'curvepolygon') {
    processCurvePolygon(geojson.properties.user_curve, basePath);
    //  end change
  } else if (type === Constants.geojsonTypes.POLYGON) {
    // Cycle through a Polygon's rings and
    // process each line
    coordinates.forEach((line, lineIndex) => {
      processLine(
        line,
        basePath !== null ? `${basePath}.${lineIndex}` : String(lineIndex),
        options.useMode,
      );
    });
  } else if (type === Constants.geojsonTypes.LINE_STRING) {
    processLine(coordinates, basePath);
  } else if (type.indexOf(Constants.geojsonTypes.MULTI_PREFIX) === 0) {
    processMultiGeometry();
  }

  // begin change
  function processCurvePolygon([curveType, ...curveElements], lineBasePath) {
    // we ignore lineBasePath because we don't support multi-linestrings/multi-compoundcurves/multi-curvepolygons
    // not certain about this strategy for coordinate ids - do we want to support polygons with holes, for instance?
    curveElements.forEach((curve, elementId) => {
      processCompoundCurve(curve, `${elementId}.`);
    });
    supplementaryPoints[0].properties.first = true;
    supplementaryPoints[supplementaryPoints.length - 1].properties.last = true;
  }
  // our vertex numbering scheme follows elements within .curve rather than .geometry.coordinates. So the second point of the third arc is 3.2
  function processCompoundCurve(
    [curveType, ...curveElements],
    curveBasePath = '',
  ) {
    curveElements.forEach(([elType, ...coords], elementId) => {
      if (elType === 'linestring') {
        processLine(coords, curveBasePath + elementId);
      } else {
        processLine(coords, curveBasePath + elementId, true);
      }
    });
    supplementaryPoints[0].properties.first = true;
    supplementaryPoints[supplementaryPoints.length - 1].properties.last = true;
  }

  function processLine(line, lineBasePath, useMode = null) {
    // end change
    let firstPointString = '';
    let lastVertex = null;
    line.forEach((point, pointIndex) => {
      const pointPath = (lineBasePath !== undefined && lineBasePath !== null) ? `${lineBasePath}.${pointIndex}` : String(pointIndex);
      const vertex = createVertex(featureId, point, pointPath, isSelectedPath(pointPath));

      // If we're creating midpoints, check if there was a
      // vertex before this one. If so, add a midpoint
      // between that vertex and this one.
      // begin change
      if (options.midpoints && lastVertex && useMode === 'kmlDraw') {
        // end change
        /*
        TODO restore
        Disabling midpoint creation for now (since moving those midpoints doesn't work). To support this, we need to:
        - add a more meaningful "path" value on the vertex
        - add support for moving vertices of curves, as onMidpoint() in customDirectSelectMode
        */
        const midpoint = createMidpoint(featureId, lastVertex, vertex);
        if (midpoint) {
          supplementaryPoints.push(midpoint);
        }
      }
      lastVertex = vertex;

      // A Polygon line's last point is the same as the first point. If we're on the last
      // point, we want to draw a midpoint before it but not another vertex on it
      // (since we already a vertex there, from the first point).
      const stringifiedPoint = JSON.stringify(point);
      if (firstPointString !== stringifiedPoint) {
        supplementaryPoints.push(vertex);
      }
      if (pointIndex === 0) {
        firstPointString = stringifiedPoint;
      }
    });
  }

  function isSelectedPath(path) {
    if (!options.selectedPaths) return false;
    return options.selectedPaths.indexOf(path) !== -1;
  }

  // Split a multi-geometry into constituent
  // geometries, and accumulate the supplementary points
  // for each of those constituents
  function processMultiGeometry() {
    const subType = type.replace(Constants.geojsonTypes.MULTI_PREFIX, '');
    coordinates.forEach((subCoordinates, index) => {
      const subFeature = {
        type: Constants.geojsonTypes.FEATURE,
        properties: geojson.properties,
        geometry: {
          type: subType,
          coordinates: subCoordinates,
        },
      };
      supplementaryPoints = supplementaryPoints.concat(
        createSupplementaryPoints(subFeature, options, index),
      );
    });
  }

  return supplementaryPoints;
}

export default createSupplementaryPoints;
