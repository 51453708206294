// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link as RouterLink } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Avatar, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Box, useTheme } from '@mui/system';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { GetProjectDetailsRoute, GetProjectFilesRoute, PROJECTS_ROUTE, ADMIN_ROUTE, IsProjectListPath, IsProjectPath, IsAccountPath, IsAdminListPath, IsAdminPath } from 'Utils/routes';
import history from 'Utils/history';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import logo from 'Assets/logo_small_dark.png';
import { clearLocalStorage } from 'Utils/localStorageService';
import { useAppDispatch, useAppSelector } from 'Hooks';
import ProcessedAcreage from 'Components/ProcessedAcreage';
import CreateProjectDialog from 'Components/CreateProjectDialog';
import { GetOrgSubscriptionThunk } from 'Features/account/subscriptionThunk';
import CreateOrgDialog from 'Components/CreateOrgDialog';
import ProjectToolbar from './projectToolbar';
import AccountToolbar from './accountToolbar';
import AdminListToolbar from './adminListToolbar';
import AdminToolbar from './adminToolbar';
import NotificationsMenu from './notificationsMenu';
import PopupMenu from './PopupMenu';
import ProjectName from './projectName';
import HelpMenu from './helpToolbar';
import stylesDef from './styles';

export const Header = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const accountMatch = IsAccountPath(currentPath);
  const projectMatch = IsProjectPath(currentPath);
  const projectListMatch = IsProjectListPath(currentPath);
  const adminListMatch = IsAdminListPath(currentPath);
  const adminMatch = IsAdminPath(currentPath);
  const user = useAppSelector((state) => state.auth.user);
  const resources = useAppSelector((state) => state.auth.resources);

  const cadSubscription = useAppSelector((state) => state.subscription.cadSubscription);
  const automateSubscription = useAppSelector((state) => state.subscription.automateSubscription);

  const orgId = useAppSelector((state) => !_ADMIN_ && state.account.org._id);
  const isAdmin = user?.roles?.includes('admin');
  const projectId = useAppSelector((state) => state.project.projectId);
  const project = useAppSelector((state) => state.project.project);

  const adminUser = useAppSelector((state) => state.admin.org);

  const processingUsage = !_ADMIN_ && 'processingUsage' in resources;
  const opsTrainer = !_ADMIN_ && 'opsTrainer' in resources;
  const createProject = !_ADMIN_ ? 'createProject' in resources : true;
  const editorFunctions = !_ADMIN_ ? 'editorFunctions' in resources : true;
  const placeOrder = !_ADMIN_ ? 'placeOrder' in resources : true;

  let avatarText = null;
  if (accountMatch) {
    const { firstName, lastName } = user;
    const char1 = (firstName && firstName[0]) || '';
    const char2 = (lastName && lastName[0]) || '';
    avatarText = `${char1}${char2}`;
  }

  const title =
    (projectListMatch && 'My Projects') ||
    (projectMatch && project && (opsTrainer ? project.training.nameEditable : project.name)) ||
    (accountMatch && user.email) ||
    (adminListMatch && 'AirWorks Admin') ||
    (adminMatch && adminUser && `${adminUser.companyName}`);

  // If a non-admin user enters '/admin' or any other admin path like '/admin/orgId/projects' log them out
  useEffect(() => {
    if (!_ADMIN_) {
      dispatch(GetOrgSubscriptionThunk(orgId));
    }
    if (!_ADMIN_ && (adminListMatch || adminMatch)) {
      clearLocalStorage();
      history.push('/');
    }
  }, []);

  const ProjectBackButton = (
    <Box sx={styles.backButton}>
      <img style={styles.logo} src={logo} alt="AirWorks small logo" />
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Button component={RouterLink} to={PROJECTS_ROUTE} sx={styles.toolbarTextButton}>
          <Typography variant="body1" sx={styles.toolbarText}>All Projects</Typography>
        </Button>
        {projectMatch && (<ProjectName initialProjectName={title} projectId={projectId} />)}
        {accountMatch && (<Typography variant="body1" color="textPrimary" style={{ paddingTop: '10px', paddingBottom: '10px', fontWeight: 500 }}>{title}</Typography>)}
      </Breadcrumbs>
    </Box>
  );

  const isAutomateSubscriptionAvailable = automateSubscription && Object.keys(automateSubscription).length !== 0;

  const ProjectListHeader = (
    <Box sx={styles.backButton}>
      <img style={styles.logo} src={logo} alt="AirWorks small logo" />
      {cadSubscription && !isAutomateSubscriptionAvailable && (
        <Box sx={styles.betaBanner}>
          BETA
        </Box>
      )}
    </Box>
  );

  const AdminListHeader = (
    <Box sx={styles.backButton}>
      <img style={styles.logo} src={logo} alt="AirWorks small logo" />
      {(accountMatch || adminMatch) && <Avatar sx={styles.avatar}>{avatarText}</Avatar>}
      <Typography variant="h3" sx={styles.titleTypography} color="textPrimary">{title}</Typography>
    </Box>
  );

  const [showProjectCreateDialog, setShowProjectCreateDialog] = useState(false);
  const [showOrgDialog, setShowOrgDialog] = useState(false);

  const readSession = sessionStorage.getItem('warningBanner');

  // Set Project route (/details or /files) based on user permissions
  let ProjectRoute;
  if (editorFunctions && !placeOrder) {
    ProjectRoute = GetProjectFilesRoute;
  } else {
    ProjectRoute = GetProjectDetailsRoute;
  }

  const createProjectClick = () => {
    // Add new layer to a site  with no layers WEB-160
    setShowProjectCreateDialog(true);
  };

  const createOrgClick = () => {
    setShowOrgDialog(true);
  };

  const AdminBackButton = () => (
    <Button
      color="inherit"
      onClick={() => navigate(ADMIN_ROUTE)}
      sx={styles.backButton}
    >
      <KeyboardArrowLeftIcon />
      <Typography variant="body1" sx={styles.toolbarText}>Back to Dashboard</Typography>
    </Button>
  );

  const toolbarButtonStyle: {} = (projectMatch || accountMatch) ? styles.toolbarButtons : styles.toolbarButtonsDashboard;
  return (
    <AppBar position="relative" sx={{ ...styles.appBar, ...(readSession === 'false' && styles.appBarSession) }}>
      <Toolbar>
        <Box sx={{ ...styles.toolbarContentWrapper, ...(!(projectMatch || accountMatch || adminMatch) && styles.toolbarNoTabs) }}>
          {(projectMatch || accountMatch) && (ProjectBackButton)}
          {projectListMatch && ProjectListHeader}
          {adminMatch && <AdminBackButton />}
          {/* Make sure the admin header info is not shown when a non admin user tries to access the admin route */}
          {(adminListMatch && isAdmin) && (AdminListHeader)}
          <Box sx={toolbarButtonStyle}>
            {!_ADMIN_ && <HelpMenu />}
            {!_ADMIN_ && <NotificationsMenu />}
            <PopupMenu />
          </Box>
          {adminMatch && (
            <Box sx={styles.adminName}>
              {(accountMatch || adminMatch) && <Avatar sx={styles.avatar}>{avatarText}</Avatar>}
              <Typography variant="h3" color="textPrimary">{title}</Typography>
            </Box>
          )}
          {projectListMatch && processingUsage
            && (
              <Box sx={styles.progressText}>
                <ProcessedAcreage headerComponent />
              </Box>
            )}
        </Box>
      </Toolbar>
      <CreateProjectDialog
        showDialog={showProjectCreateDialog}
        setShowDialog={setShowProjectCreateDialog}
        getProjectUrl={ProjectRoute}
      />
      <CreateOrgDialog
        showOrgDialog={showOrgDialog}
        setShowOrgDialog={setShowOrgDialog}
      />
      {
        // Show the create new project button when user is on the dashboard and has the 'createProject' resource
        (projectListMatch && createProject) && (
          <Fab
            color="primary"
            aria-label="Add"
            sx={styles.fabButton}
            onClick={(async () => {
              // Add Pendo Track Events
              window?.pendo?.track('Create New Project');
              await createProjectClick();
            })}
          >
            <AddIcon />
          </Fab>
        )
      }
      {
        // Make sure the create org button is not shown when a non admin user tries to access the admin route
        (adminListMatch && isAdmin) && (
          <Fab
            color="primary"
            aria-label="Add"
            sx={styles.fabButton}
            onClick={createOrgClick}
          >
            <AddIcon />
          </Fab>
        )
      }
      {projectMatch && <ProjectToolbar />}
      {accountMatch && <AccountToolbar />}
      {adminMatch && <AdminToolbar />}
      {adminListMatch && <AdminListToolbar />}
    </AppBar>
  );
};

export default Header;
