// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dispatch } from 'redux';
import { datadogRum } from '@datadog/browser-rum';
import { getJson, postJson, deleteRequest, patchJson } from 'Utils/http';
import history from 'Utils/history';
import { GetAdminProjectsRoute } from 'Utils/routes';
import { API_URL } from 'Config';
import type { RootState } from 'Store';
import { DeleteProjectListLasFileAction, DeleteProjectListTifFileAction, DeleteProjectsFailedAction, DeleteProjectsStartAction, DeleteProjectsSuccessAction, UpdateProjectListImageryRequestSuccessAction, UpdateProjectListLasFileAction, UpdateProjectListTifFileAction } from '../projectList/projectListActions';
import { CreateOrderSuccessAction, SetOrderIdAction } from '../order/orderActions';
import { SetSiteIdAction } from '../map/mapEditorActions';
import { selectProjectEPSGCode, selectProjectFiles } from './projectSelectors';
import { ResetProjectLasInfoAction, ResetProjectTifInfoAction } from '../map/mapCommonActions';
import { GetRasterTilesThunk, GetLasBBoxThunk } from '../map/mapCommonThunk';
import {
  ChangeJsonAction,
  CreateProjectFailureAction,
  CreateProjectStartAction,
  CreateProjectSuccessAction,
  DeleteProjectLasFileAction,
  DeleteProjectTifFileAction,
  GetProjectFailureAction,
  GetProjectStartAction,
  GetProjectSuccessAction,
  SetFileStatusJsonAction,
  GetEpsgProjectionsAction,
  ResetEpsgProjectionsAction,
  UpdateImageryRequestSuccessAction,
  UpdateProjectLasFilesAction,
  UpdateProjectTifFilesAction,
  SetProjectIdAction,
  UpdateRasterRequestSuccessAction,
  SetWarningAcceptedAction,
  SendImageryUploadEmailStartAction,
  SendImageryUploadEmailSuccessAction,
  SendImageryUploadEmailFailureAction,
  SendRasterUploadEmailStartAction,
  SendRasterUploadEmailSuccessAction,
  SendRasterUploadEmailFailureAction,
} from './projectActions';

/**
 * API call to create a project
 * @param editorFunctions: Resource permission for editorFunctions
 * @param projectData
 * @returns Result containing the project details
 * @updates project, order and cadFile state
 */
export const CreateProjectThunk = (projectData: { name: string, type?: string, description?: string, referenceId?: string, anticipatedKickoffDate?: string }, editorFunctions: Boolean) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const url = `${API_URL}/projects`;
    const { user, token } = getState().auth;
    const { orgId } = _ADMIN_ && getState().admin;

    const data = _ADMIN_ ?
      { ...projectData, orgId } :
      { ...projectData, editorFunctions };

    dispatch(CreateProjectStartAction());

    const result = await postJson<IProject>(url, data, token);
    if (!result.success) {
      dispatch(CreateProjectFailureAction());
      return result;
    }

    // Update state for project, order and cadFiles
    dispatch(CreateProjectSuccessAction(result.data));

    // datadog action - tracking user actions by passing custom attributes
    datadogRum.addAction('new_project', {
      'project.name': result?.data?.name,
      'usr.id': user?._id,
      'usr.email': user?.email,
      'usr.org': user?.ownerOrganization,
    });

    dispatch(CreateOrderSuccessAction(result.data.order));
    if (editorFunctions) {
      dispatch(SetSiteIdAction(result.data.order.cadFiles[0]?._id));
      dispatch(SetOrderIdAction(result.data.order._id));
    }
    return result;
  };

// Deletes a project with a given projectId
export const DeleteProjectThunk = (projectId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const url = `${API_URL}/projects/${projectId}`;
    const { user, token } = getState().auth;
    const isAdmin = Boolean(user.roles && user.roles.includes('admin'));

    dispatch(DeleteProjectsStartAction(projectId));

    const result = await deleteRequest(url, token);
    if (result.success) {
      if (isAdmin) {
        const { orgId } = getState().admin;
        history.push(GetAdminProjectsRoute(orgId));
        dispatch(DeleteProjectsSuccessAction(projectId));
      } else {
        dispatch(DeleteProjectsSuccessAction(projectId));
        // Reset filter and reload projects from database when delete a project on client portal
        // ResetFiltersThunk()(dispatch);
        // ReloadProjectsThunk()(dispatch, getState);
      }
    } else {
      dispatch(DeleteProjectsFailedAction(projectId));
    }
  };

export const GetProjectThunk = () =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { projectId } = getState().project;
    if (projectId) {
      const url = `${API_URL}/projects/${projectId}`;
      const { token } = getState().auth;

      dispatch(GetProjectStartAction());
      const result = await getJson<IProject>(url, token);
      if (result.success) {
        dispatch(GetProjectSuccessAction(result.data.data));
        SetFileStatusThunk()(dispatch, getState);
      } else {
        dispatch(GetProjectFailureAction());
      }
      return result;
    }
    return {};
  };

export const SetFileStatusThunk = (option? : string) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    if (option === 'reset') {
      dispatch(SetFileStatusJsonAction(null));
      return;
    }
    const { fileStatuses } = getState().project.project;
    let fileStatusJson = getState().project.fileStatusJson || {};
    const projectFiles = selectProjectFiles(getState());

    if (!Object.isExtensible(fileStatusJson)) {
      fileStatusJson = { ...fileStatusJson };
    }

    projectFiles.forEach((file) => {
      if (!fileStatusJson[file.name]) {
        fileStatusJson[file.name] = { status: '', messages: [] };
      }
    });

    fileStatuses.forEach((s: any) => {
      if (!fileStatusJson[s.filename]) {
        fileStatusJson[s.filename] = { status: '', messages: [] };
      }
      fileStatusJson[s.filename] = { ...fileStatusJson[s.filename] };
      fileStatusJson[s.filename].status = s.status;
      fileStatusJson[s.filename].messages = s.messages;
    });
    dispatch(SetFileStatusJsonAction(fileStatusJson));
    dispatch(ChangeJsonAction());
  };

// Deletes TIF file from a project
export const DeleteTifFileThunk = (tifFileId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const url = `${API_URL}/projects/deleteTIF`;
    const { token } = getState().auth;
    const { projectId } = getState().project;
    const { orgId } = getState().admin;

    const data = {
      projectId,
      tifFileId,
    };

    const projectData = _ADMIN_ ?
      { ...data, orgId } :
      { ...data };

    const result = await postJson<IProject>(url, projectData, token);
    if (result.success) {
      dispatch(DeleteProjectTifFileAction({ tifFileId, projectId }));
      dispatch(DeleteProjectListTifFileAction({ tifFileId, projectId }));
      dispatch(ResetProjectTifInfoAction());
      GetRasterTilesThunk()(dispatch, getState);
    }
  };

// Deletes LAS file from a project
export const DeleteLasFileThunk = (lasFileId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const url = `${API_URL}/projects/deleteLAS`;
    const { token } = getState().auth;
    const { projectId } = getState().project;
    const { orgId } = getState().admin;

    const data = {
      projectId,
      lasFileId,
    };

    const projectData = _ADMIN_ ?
      { ...data, orgId } :
      { ...data };

    const result = await postJson<IProject>(url, projectData, token);
    if (result.success) {
      dispatch(DeleteProjectLasFileAction({ lasFileId, projectId }));
      dispatch(DeleteProjectListLasFileAction({ lasFileId, projectId }));
      dispatch(ResetProjectLasInfoAction());
      GetLasBBoxThunk()(dispatch, getState);
    }
  };

export const GetEpsgProjectionsThunk = (epsgCode: number) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    if (epsgCode) {
      const url = `${API_URL}/projects/getEpsgProjections/${epsgCode}`;
      const { token } = getState().auth;

      const result = await getJson<IEPSG>(url, token);
      if (result.success) {
        dispatch(GetEpsgProjectionsAction({ ...result.data, epsgCode }));
      } else {
        dispatch(ResetEpsgProjectionsAction());
      }
      return result;
    }
    return {};
  };

export const DownloadTifThunk = (filename: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { user, token } = getState().auth;
    const { projectId } = getState().project;
    let orgId;
    const isAdmin = Boolean(user.roles && user.roles.includes('admin'));
    if (isAdmin) {
      orgId = getState().admin.orgId;
    } else if ('opsTrainer' in getState().auth.resources) {
      const project = getState().project?.list?.find((p) => p._id === projectId);
      orgId = project?.ownerOrganization;
    } else {
      orgId = getState().auth.user.ownerOrganization;
    }

    const url = `${API_URL}/downloadTIF/${orgId}/${projectId}/${filename}`;
    const result = await getJson<{preSignedUrl: string, size: number}>(url, token);
    if (result.success) {
      window.open(result.data.preSignedUrl, '_self');
      window?.pendo?.track('Download Input Data', {
        projectId,
        filetype: 'tif',
        filesize: `${(result.data.size / 1000000).toFixed(2)} MB`,
      });
    }
  };

export const ToggleImageryProcessingThunk = () =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { token } = getState().auth;

    const { project: { imageryRequest } } = getState().project;

    const url = `${API_URL}/imageryRequest/${imageryRequest._id}`;

    const data = { processImmediately: !imageryRequest.processImmediately };

    const result = await patchJson<{imageryRequest: IImageryRequest}>(url, data, token);
    if (result.success) {
      dispatch(UpdateImageryRequestSuccessAction(result.data?.imageryRequest));
      dispatch(UpdateProjectListImageryRequestSuccessAction(result.data?.imageryRequest));
    }

    return result;
  };

export const GetProjectEpsgCodeThunk = (projectId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const url = `${API_URL}/projects/epsg/${projectId}`;
    const { token } = getState().auth;

    const result = await getJson<{ tifWithEpsg: ITifFile, lasWithEpsg: ILasFile }>(url, token);
    if (result.success) {
      const { tifWithEpsg, lasWithEpsg } = result.data;
      const { tifEpsg, lasEpsg } = selectProjectEPSGCode(getState());
      if (tifWithEpsg && !tifEpsg) {
        dispatch(UpdateProjectTifFilesAction(tifWithEpsg));
        dispatch(UpdateProjectListTifFileAction(tifWithEpsg));
      }
      if (lasWithEpsg && !lasEpsg) {
        dispatch(UpdateProjectLasFilesAction(lasWithEpsg));
        dispatch(UpdateProjectListLasFileAction(lasWithEpsg));
      }
      return result.data;
    }
    return null;
  };

export const SetProjectId = (projectId: string) => (dispatch: Dispatch) => dispatch(SetProjectIdAction(projectId));

export const RejectImageryThunk = (reason: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { token } = getState().auth;

    const { project: { imageryRequest } } = getState().project;

    const url = `${API_URL}/imageryRequest/${imageryRequest._id}`;

    const data = { rejectedReason: reason };

    const result = await patchJson<{imageryRequest: IImageryRequest}>(url, data, token);
    if (result.success) {
      dispatch(UpdateImageryRequestSuccessAction(result.data?.imageryRequest));
      dispatch(UpdateProjectListImageryRequestSuccessAction(result.data?.imageryRequest));
    }

    return result;
  };

export const RejectRasterizationThunk = () =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { token } = getState().auth;
    const { rasterRequest } = getState().project.project;
    const url = `${API_URL}/rasterRequest/${rasterRequest._id}`;

    const result = await patchJson<{rasterRequest: IRasterRequest}>(url, { rejected: true }, token);

    if (result.success) {
      dispatch(UpdateRasterRequestSuccessAction(result.data?.rasterRequest));
    }
  };

// Updates name of a project with ID & New name of the project in the body
export const UpdateProjectNameThunk = (projectData: { _id: string, name: string }) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/projects/${projectData._id}`;
    const { token } = getState().auth;
    const opsTrainer = 'opsTrainer' in getState().auth.resources;

    // if the user is ops trainer update the nameEditable field else update project name
    const data = opsTrainer ? { nameEditable: projectData.name } : { name: projectData.name };

    const result = await patchJson<IProject>(url, data, token);
    if (result.success) {
      const payload = { ...result.data.task, opsTrainer };
      dispatch(UpdateProjectNameAction(payload));
    }

    return result;
  };

export const ChangeJson = () => (dispatch: Dispatch) => dispatch(ChangeJsonAction());

export const SetWarningAccepted = () => (dispatch: Dispatch) => dispatch(SetWarningAcceptedAction());

export const SendImageryUploadEmailThunk = () =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { token } = getState().auth;

    const { project: { imageryRequest } } = getState().project;

    const url = `${API_URL}/imageryRequest/${imageryRequest._id}`;

    dispatch(SendImageryUploadEmailStartAction());

    const data = { notificationSent: true };

    const result = await patchJson<{imageryRequest: IImageryRequest}>(url, data, token);
    if (result.success) {
      dispatch(SendImageryUploadEmailSuccessAction(result.data?.imageryRequest));
    } else {
      dispatch(SendImageryUploadEmailFailureAction(result.message));
    }

    return result;
  };

export const SendRasterUploadEmailThunk = () =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { token } = getState().auth;

    const { project: { rasterRequest } } = getState().project;

    const url = `${API_URL}/rasterRequest/${rasterRequest._id}`;

    dispatch(SendRasterUploadEmailStartAction());

    const data = { notificationSent: true };

    const result = await patchJson<{rasterRequest: IRasterRequest}>(url, data, token);
    if (result.success) {
      dispatch(SendRasterUploadEmailSuccessAction(result.data?.rasterRequest));
    } else {
      dispatch(SendRasterUploadEmailFailureAction(result.message));
    }

    return result;
  };

// Updates files & project data
export const UpdateProject = (project: IProject) => (dispatch: Dispatch) => dispatch(UpdateProjectAction(project));

export const SetShowKmlToolbar = (showKmlToolBar: boolean) => (dispatch: Dispatch) => dispatch(SetShowKmlToolbarAction(showKmlToolBar));

export const UpdateProjectTifFiles = (tifFile: ITifFile) => (dispatch: Dispatch) => dispatch(UpdateProjectTifFilesAction(tifFile));

export const UpdateProjectLasFiles = (lasFile: ILasFile) => (dispatch: Dispatch) => dispatch(UpdateProjectLasFilesAction(lasFile));
