// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  wrapper: {
    marginBottom: theme.spacing(3),
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr',
    gridColumnGap: theme.spacing(3),
  },
  cityRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridColumnGap: theme.spacing(3),
  },
});

export default styles;
