// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect } from 'react';
import Dropzone, { DropzoneInputProps } from 'react-dropzone';
import { Box, useTheme } from '@mui/system';
import { useAppDispatch } from 'Hooks';
import { InitFileUploadThunk } from 'Features/fileUpload/fileUploadThunk';
import createAcceptedExtensionsDict from 'Utils/mimeTypes';
import stylesDef from './styles';

interface ComponentProps {
  acceptedExtensions: string[];
  onDropAccepted: (files: File[]) => void;
  children: (getInputProps: (props?: DropzoneInputProps) => DropzoneInputProps, open: () => void) => any;
}

export const DropzoneWrapper: React.FC<ComponentProps> = ({ acceptedExtensions, children, onDropAccepted }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(InitFileUploadThunk());
  }, []);

  const acceptedExtensionsDict = createAcceptedExtensionsDict(acceptedExtensions);

  return (
    <Dropzone
      noClick
      accept={acceptedExtensionsDict}
      onDrop={(acceptedFiles) => onDropAccepted(acceptedFiles)}
    >
      {({ getRootProps, getInputProps, open, isDragActive }) => (
        <Box
          {...getRootProps({
            style: {
              ...(isDragActive ? {
                borderColor: theme.palette.primary.main,
              } : {}),
            },
          })}
          sx={styles.uploadField}
        >
          {children(getInputProps, open)}
        </Box>
      )}
    </Dropzone>
  );
};

export default DropzoneWrapper;
