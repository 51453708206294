// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { Box, useTheme } from '@mui/system';
import { Dialog, DialogActions, Typography, Button, Radio, RadioGroup, TextField, FormControlLabel, FormControl } from '@mui/material/';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { RejectImageryThunk } from 'Features/project/projectThunk';
import stylesDef from './styles';

interface StringObject { [key: string]: string; }

const reasons: StringObject = {
  quality: 'Image Quality - I need higher resolution',
  date: 'Imagery Date - I need more recent imagery',
  accident: 'I submitted this order by accident',
  bad: 'This imagery is not appropriate for my project',
  other: 'Other',
};

interface CancelImageryRequestDialogProps {
  showDialog: boolean;
  setShowDialog: (show: boolean) => void;
}

export const CancelImageryRequestDialog = ({ showDialog, setShowDialog }: CancelImageryRequestDialogProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const [reason, setReason] = useState('');
  const [selection, setSelection] = useState('');
  const [openOther, setOpenOther] = useState(false);
  const dispatch = useAppDispatch();
  const projectId = useAppSelector((state) => state.project.project?._id);

  const changeReason = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSelection(value);

    if (value !== 'other') {
      setOpenOther(false);
      setReason(reasons[value]);
    } else {
      setOpenOther(true);
    }
  };

  const onClose = () => {
    setSelection('');
    setShowDialog(false);
    setOpenOther(false);
  };

  const onOk = () => {
    onClose();
    window?.pendo?.track('Cancel Order', {
      cancelReason: reason,
      projectId,
    });
    dispatch(RejectImageryThunk(reason));
  };

  return (
    <Dialog
      open={showDialog}
      onClose={onClose}
    >
      <Typography sx={styles.dialogTitle} variant="h2">Are you sure?</Typography>
      <Typography sx={styles.dialogSubtitle} variant="h6">Please share the reason behind canceling this order:</Typography>
      <FormControl sx={styles.dialogContent}>
        <RadioGroup
          sx={styles.radioContent}
          onChange={changeReason}
          value={selection}
          defaultValue={0}
          name="cancel-imagery-request"
        >
          <FormControlLabel value="quality" control={<Radio />} label={reasons.quality} />
          <FormControlLabel value="date" control={<Radio />} label={reasons.date} />
          <FormControlLabel value="accident" control={<Radio />} label={reasons.accident} />
          <FormControlLabel value="bad" control={<Radio />} label={reasons.bad} />
          <FormControlLabel value="other" control={<Radio />} label={reasons.other} />

        </RadioGroup>
        { openOther && (
          <Box sx={styles.textContent}>
            <TextField
              onChange={(e) => setReason(e.target.value)}
              multiline
              rows={3}
              maxRows={5}
              variant="outlined"
              label="Required"
              style={{ width: 400 }}
            />
          </Box>
        )}
      </FormControl>
      <DialogActions sx={styles.dialogButtons}>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={onOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelImageryRequestDialog;
