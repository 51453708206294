// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  dialog: {
    '& .MuiDialog-paper': {
      display: 'flex',
      justifyContent: 'space-between',
      width: '60%',
      height: '70%',
      padding: theme.spacing(3),
    },
  },
});

export default styles;
