import { createAction } from '@reduxjs/toolkit';

export const SetSortFilterAction = createAction<string>('projectList/SetSortFilterAction');

export const SetKeywordFilterAction = createAction<string>('projectList/SetKeywordFilterAction');

export const SetOrderSortFilterAction = createAction<ProjectOrderSortFilter>('projectList/SetOrderSortFilterAction');

export const SetPageAction = createAction<number>('projectList/SetPage');

export const GetProjectsStartAction = createAction('projectList/GetProjectsStart');

export const GetProjectsSuccessAction = createAction<IProjectSuccess>('projectList/GetProjectsSuccess');

export const GetProjectsFailedAction = createAction<string>('projectList/GetProjectsFailed');

export const SetProjectsAction = createAction<any>('projectList/SetProjectsAction');

export const SetLoadAllAction = createAction<boolean>('projectList/SetLoadAllAction');

export const SetLoadNewAction = createAction<boolean>('projectList/SetLoadNewAction');

export const SetThumbnailAction = createAction<{ projectId: string, thumbnail: string }>('projectList/SetThumbnailAction');

export const DeleteProjectsStartAction = createAction<string>('projectList/DeleteProjectsStartAction');

export const DeleteProjectsSuccessAction = createAction<string>('projectList/DeleteProjectsSuccessAction');

export const DeleteProjectsFailedAction = createAction<string>('projectList/DeleteProjectsFailedAction');

export const UpdateProjectNameAction = createAction<IProject>('project/UpdateProjectNameAction');
export const UpdateProjectListTifFileAction = createAction<ITifFile>('projectList/UpdateProjectListTifFileAction');
export const UpdateProjectListLasFileAction = createAction<ILasFile>('projectList/UpdateProjectListLasFileAction');

export const DeleteProjectListTifFileAction = createAction<any>('projectList/DeleteProjectListTifFileAction');
export const DeleteProjectListLasFileAction = createAction<any>('projectList/DeleteProjectListLasFileAction');

export const UpdateProjectListImageryRequestSuccessAction = createAction<IImageryRequest>('projectList/UpdateProjectListImageryRequestSuccessAction');
