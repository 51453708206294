// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Typography, Link } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { Link as RouterLink } from 'react-router-dom';
import stylesDef from './styles';

// This component renders the expired token page
const ExpiredToken = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  return (
    <Box>
      <Typography sx={styles.header} variant="h1">
        Your link has expired
      </Typography>
      <Typography sx={styles.details} variant="h5">
        We're sorry the link we sent you has now expired. Sign up again to get a new link sent to your inbox.
        {' '}
        {' '}
      </Typography>
      <Typography sx={styles.details} variant="h5">
        <Link component={RouterLink} sx={styles.forgotText} to="/">
          Sign up again
        </Link>
      </Typography>
    </Box>
  );
};

export default ExpiredToken;
