// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  pageTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    fontWeight: 400,
    fontSize: '28px',
  },
  billingColumn: {
    padding: theme.spacing(2),
  },
});

export default styles;
