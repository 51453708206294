// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { useTheme } from '@mui/system';
import CreditCardIcon from 'Components/CreditCardIcon';
import stylesDef from './styles';

interface ComponentProps {
  cardType: string;
}

export const Icon: React.FC<ComponentProps> = ({ cardType }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);

  return (
    <CreditCardIcon cardType={cardType} sx={{ icon: styles.cardIcon }} />
  );
};

export default Icon;
