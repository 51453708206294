import { createAction } from '@reduxjs/toolkit';

export const GetGlobalStatusSuccessAction = createAction<IGlobalStatus>('globalStatus/GetGlobalStatusSuccessAction');
export const GetGlobalStatusFailedAction = createAction<string>('globalStatus/GetGlobalStatusFailedAction');

export const GetOpsCreditCapacitySuccessAction = createAction<number>('globalStatus/GetOpsCreditCapacitySuccessAction');
export const GetOpsCreditCapacityFailedAction = createAction<string>('globalStatus/GetOpsCreditCapacityFailedAction');

export const UpdateGlobalStatusStartAction = createAction('globalStatus/UpdateGlobalStatusStartAction');
export const UpdateGlobalStatusSuccessAction = createAction<IGlobalStatus>('globalStatus/GUpdateGlobalStatusSuccessAction');
export const UpdateGlobalStatusFailureAction = createAction<string>('globalStatus/UpdateGlobalStatusFailureAction');
