// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  cardContainer: {
    border: 'solid 1px #D9DDE1',
    borderTop: '5px solid #2E4DEB',
    borderRadius: '5px',
    background: '#fff',
    marginTop: 0,
    margin: '10px',
    padding: theme.spacing(1),
  },
  cardTitle: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    color: '#000',
  },
  cardText: {
    fontSize: 14,
    marginTop: theme.spacing(1),
  },
  helpIconSmall: {
    width: '14px',
    height: '14px',
    marginLeft: theme.spacing(0.5),
    cursor: 'pointer',
  },
  icon: {
    fontSize: '16px',
    marginRight: theme.spacing(0.5),
    color: '#2E4DEB',
  },
  bold: {
    fontWeight: 600,
  },
  rasterizeGrid: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 0,
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    columnGap: theme.spacing(1.5),
  },
  rasterizeIcon: {
    height: '36px',
    width: '36px',
  },
  rasterizeTextContainer: {
    display: 'flex',
    gao: '5px',
  },
  rasterizeText: {
    fontSize: '12px',
    fontWeight: 600,
  },
  checkboxContainer: {
    '& .MuiTypography-body1': {
      fontSize: '12px',
      fontWeight: 400,
    },
    '& .MuiCheckbox-root': {
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: '3px',
    },
  },
  checkbox: {
    '&.Mui-checked': {
      color: '#2E4DEB',

    },
  },
  timerContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '4px 0',
  },
  timer: {
    color: '#2E4DEB',
    fontWeight: 400,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cancelButton1: {
    color: '#2E4DEB',
    paddingLeft: 0,
  },
  submitButton: {
    color: '#243FDD',
    padding: 0,
  },
  cancelButton2: {
    color: '#788898',
    padding: 0,
    minWidth: '50px',
    fontSize: '12px',
  },
  rasterizeNote: {
    fontSize: '11px',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    color: '#333333',
    fontWeight: 350,
  },
});

export default styles;
