// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = () => ({
  popUpText: {
    fontWeight: 400,
    fontSize: '16px',
  },
  popUp: {
    '& .mapboxgl-popup-content': {
      paddingTop: '15px',
    },
  },
});

export default styles;
