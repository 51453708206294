// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { Box, useTheme } from '@mui/system';
import { Select, MenuItem, InputBase, Grid, IconButton } from '@mui/material';
import gridviewIcon from 'Assets/grid-view-icon.png';
import gridviewIconActive from 'Assets/grid-view-icon-ACTIVE.png';
import mapviewIcon from 'Assets/map-view-icon.png';
import mapviewIconActive from 'Assets/map-view-icon-ACTIVE.png';
import stylesDef from './styles';
import { SetKeywordFilterAction, SetLoadAllAction, SetLoadNewAction, SetOrderSortFilterAction, SetPageAction, SetProjectsAction, SetSortFilterAction } from '../projectListActions';
import { GetProjectsThunk } from '../projectListThunk';
import SearchField from 'Components/SearchField';

type PageToolbarProps = {
  showMapview: boolean;
  showMapviewClick: () => void;
  showGridviewClick: () => void;
};

const PageToolbar = ({ showMapview, showMapviewClick, showGridviewClick }: PageToolbarProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const toolbarStyle: {} = showMapview ? styles.pageToolbarMapview : styles.pageToolbar;
  const toolbarContainer: {} = showMapview ? styles.pageToolbarContainerMapview : styles.pageToolbarContainer;
  const { sortFilter, orderSortFilter, keywordFilter } = useAppSelector(
    (state) => state.projectList,
  );

  const loadNewData = () => {
    dispatch(SetLoadNewAction(true));
    dispatch(SetLoadAllAction(false));
    dispatch(SetPageAction(0));
    dispatch(SetProjectsAction([]));
    dispatch(GetProjectsThunk());
  };

  return (
    <Box sx={toolbarStyle}>
      <Grid sx={toolbarContainer}>
        <SearchField
          value={keywordFilter}
          onChange={(e) => {
            dispatch(SetKeywordFilterAction(e));
            loadNewData();
          }}
        />
        <IconButton
          aria-label="Mapview"
          onClick={showMapviewClick}
          // target="_blank"
          sx={styles.icons}
        >
          {showMapview ? (
            <img src={mapviewIconActive} alt="Gridview" />
          ) : (
            <img src={mapviewIcon} alt="Gridview" />
          )}
        </IconButton>
        <IconButton
          aria-label="Gridview"
          onClick={showGridviewClick}
          // target="_blank"
          sx={styles.icons}
        >
          {showMapview ? (
            <img src={gridviewIcon} alt="Gridview" />
          ) : (
            <img src={gridviewIconActive} alt="Gridview" />
          )}
        </IconButton>
        <Select
          inputProps={{
            name: 'sort',
            id: 'sort',
          }}
          input={<InputBase />}
          value={sortFilter}
          onChange={(ev) => {
            dispatch(SetSortFilterAction(ev.target.value));
            loadNewData();
          }}
        >
          <MenuItem sx={styles.fontWeight} value="updatedAtD">
            Recent
          </MenuItem>
          <MenuItem sx={styles.fontWeight} value="nameA">
            A-Z
          </MenuItem>
          <MenuItem sx={styles.fontWeight} value="nameD">
            Z-A
          </MenuItem>
        </Select>
        <Select
          inputProps={{
            name: 'filterby',
            id: 'filterby',
          }}
          input={<InputBase />}
          value={orderSortFilter}
          onChange={(ev) => {
            dispatch(SetOrderSortFilterAction(ev.target.value));
            loadNewData();
          }}
        >
          <MenuItem value="updatedAtD">
            All Projects
          </MenuItem>
          <MenuItem value="estimate">
            Estimate
          </MenuItem>
          <MenuItem value="completed">
            Completed
          </MenuItem>
        </Select>
      </Grid>
    </Box>
  );
};

export default PageToolbar;
