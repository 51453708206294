// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const list = [{
  aci: 0,
  hex: '#000000',
  rgb: 'rgb(0,0,0)',
}, {
  aci: 1,
  hex: '#FF0000',
  rgb: 'rgb(255,0,0)',
}, {
  aci: 2,
  hex: '#FFFF00',
  rgb: 'rgb(255,255,0)',
}, {
  aci: 3,
  hex: '#00FF00',
  rgb: 'rgb(0,255,0)',
}, {
  aci: 4,
  hex: '#00FFFF',
  rgb: 'rgb(0,255,255)',
}, {
  aci: 5,
  hex: '#0000FF',
  rgb: 'rgb(0,0,255)',
}, {
  aci: 6,
  hex: '#FF00FF',
  rgb: 'rgb(255,0,255)',
}, {
  aci: 7,
  hex: '#FFFFFF',
  rgb: 'rgb(255,255,255)',
}, {
  aci: 8,
  hex: '#414141',
  rgb: 'rgb(65,65,65)',
}, {
  aci: 9,
  hex: '#808080',
  rgb: 'rgb(128,128,128)',
}, {
  aci: 10,
  hex: '#FF0000',
  rgb: 'rgb(255,0,0)',
}, {
  aci: 11,
  hex: '#FFAAAA',
  rgb: 'rgb(255,170,170)',
}, {
  aci: 12,
  hex: '#BD0000',
  rgb: 'rgb(189,0,0)',
}, {
  aci: 13,
  hex: '#BD7E7E',
  rgb: 'rgb(189,126,126)',
}, {
  aci: 14,
  hex: '#810000',
  rgb: 'rgb(129,0,0)',
}, {
  aci: 15,
  hex: '#815656',
  rgb: 'rgb(129,86,86)',
}, {
  aci: 16,
  hex: '#680000',
  rgb: 'rgb(104,0,0)',
}, {
  aci: 17,
  hex: '#684545',
  rgb: 'rgb(104,69,69)',
}, {
  aci: 18,
  hex: '#4F0000',
  rgb: 'rgb(79,0,0)',
}, {
  aci: 19,
  hex: '#4F3535',
  rgb: 'rgb(79,53,53)',
}, {
  aci: 20,
  hex: '#FF3F00',
  rgb: 'rgb(255,63,0)',
}, {
  aci: 21,
  hex: '#FFBFAA',
  rgb: 'rgb(255,191,170)',
}, {
  aci: 22,
  hex: '#BD2E00',
  rgb: 'rgb(189,46,0)',
}, {
  aci: 23,
  hex: '#BD8D7E',
  rgb: 'rgb(189,141,126)',
}, {
  aci: 24,
  hex: '#811F00',
  rgb: 'rgb(129,31,0)',
}, {
  aci: 25,
  hex: '#816056',
  rgb: 'rgb(129,96,86)',
}, {
  aci: 26,
  hex: '#681900',
  rgb: 'rgb(104,25,0)',
}, {
  aci: 27,
  hex: '#684E45',
  rgb: 'rgb(104,78,69)',
}, {
  aci: 28,
  hex: '#4F1300',
  rgb: 'rgb(79,19,0)',
}, {
  aci: 29,
  hex: '#4F3B35',
  rgb: 'rgb(79,59,53)',
}, {
  aci: 30,
  hex: '#FF7F00',
  rgb: 'rgb(255,127,0)',
}, {
  aci: 31,
  hex: '#FFD4AA',
  rgb: 'rgb(255,212,170)',
}, {
  aci: 32,
  hex: '#BD5E00',
  rgb: 'rgb(189,94,0)',
}, {
  aci: 33,
  hex: '#BD9D7E',
  rgb: 'rgb(189,157,126)',
}, {
  aci: 34,
  hex: '#814000',
  rgb: 'rgb(129,64,0)',
}, {
  aci: 35,
  hex: '#816B56',
  rgb: 'rgb(129,107,86)',
}, {
  aci: 36,
  hex: '#683400',
  rgb: 'rgb(104,52,0)',
}, {
  aci: 37,
  hex: '#685645',
  rgb: 'rgb(104,86,69)',
}, {
  aci: 38,
  hex: '#4F2700',
  rgb: 'rgb(79,39,0)',
}, {
  aci: 39,
  hex: '#4F4235',
  rgb: 'rgb(79,66,53)',
}, {
  aci: 40,
  hex: '#FFBF00',
  rgb: 'rgb(255,191,0)',
}, {
  aci: 41,
  hex: '#FFEAAA',
  rgb: 'rgb(255,234,170)',
}, {
  aci: 42,
  hex: '#BD8D00',
  rgb: 'rgb(189,141,0)',
}, {
  aci: 43,
  hex: '#BDAD7E',
  rgb: 'rgb(189,173,126)',
}, {
  aci: 44,
  hex: '#816000',
  rgb: 'rgb(129,96,0)',
}, {
  aci: 45,
  hex: '#817656',
  rgb: 'rgb(129,118,86)',
}, {
  aci: 46,
  hex: '#684E00',
  rgb: 'rgb(104,78,0)',
}, {
  aci: 47,
  hex: '#685F45',
  rgb: 'rgb(104,95,69)',
}, {
  aci: 48,
  hex: '#4F3B00',
  rgb: 'rgb(79,59,0)',
}, {
  aci: 49,
  hex: '#4F4935',
  rgb: 'rgb(79,73,53)',
}, {
  aci: 50,
  hex: '#FFFF00',
  rgb: 'rgb(255,255,0)',
}, {
  aci: 51,
  hex: '#FFFFAA',
  rgb: 'rgb(255,255,170)',
}, {
  aci: 52,
  hex: '#BDBD00',
  rgb: 'rgb(189,189,0)',
}, {
  aci: 53,
  hex: '#BDBD7E',
  rgb: 'rgb(189,189,126)',
}, {
  aci: 54,
  hex: '#818100',
  rgb: 'rgb(129,129,0)',
}, {
  aci: 55,
  hex: '#818156',
  rgb: 'rgb(129,129,86)',
}, {
  aci: 56,
  hex: '#686800',
  rgb: 'rgb(104,104,0)',
}, {
  aci: 57,
  hex: '#686845',
  rgb: 'rgb(104,104,69)',
}, {
  aci: 58,
  hex: '#4F4F00',
  rgb: 'rgb(79,79,0)',
}, {
  aci: 59,
  hex: '#4F4F35',
  rgb: 'rgb(79,79,53)',
}, {
  aci: 60,
  hex: '#BFFF00',
  rgb: 'rgb(191,255,0)',
}, {
  aci: 61,
  hex: '#EAFFAA',
  rgb: 'rgb(234,255,170)',
}, {
  aci: 62,
  hex: '#8DBD00',
  rgb: 'rgb(141,189,0)',
}, {
  aci: 63,
  hex: '#ADBD7E',
  rgb: 'rgb(173,189,126)',
}, {
  aci: 64,
  hex: '#608100',
  rgb: 'rgb(96,129,0)',
}, {
  aci: 65,
  hex: '#768156',
  rgb: 'rgb(118,129,86)',
}, {
  aci: 66,
  hex: '#4E6800',
  rgb: 'rgb(78,104,0)',
}, {
  aci: 67,
  hex: '#5F6845',
  rgb: 'rgb(95,104,69)',
}, {
  aci: 68,
  hex: '#3B4F00',
  rgb: 'rgb(59,79,0)',
}, {
  aci: 69,
  hex: '#494F35',
  rgb: 'rgb(73,79,53)',
}, {
  aci: 70,
  hex: '#7FFF00',
  rgb: 'rgb(127,255,0)',
}, {
  aci: 71,
  hex: '#D4FFAA',
  rgb: 'rgb(212,255,170)',
}, {
  aci: 72,
  hex: '#5EBD00',
  rgb: 'rgb(94,189,0)',
}, {
  aci: 73,
  hex: '#9DBD7E',
  rgb: 'rgb(157,189,126)',
}, {
  aci: 74,
  hex: '#408100',
  rgb: 'rgb(64,129,0)',
}, {
  aci: 75,
  hex: '#6B8156',
  rgb: 'rgb(107,129,86)',
}, {
  aci: 76,
  hex: '#346800',
  rgb: 'rgb(52,104,0)',
}, {
  aci: 77,
  hex: '#566845',
  rgb: 'rgb(86,104,69)',
}, {
  aci: 78,
  hex: '#274F00',
  rgb: 'rgb(39,79,0)',
}, {
  aci: 79,
  hex: '#424F35',
  rgb: 'rgb(66,79,53)',
}, {
  aci: 80,
  hex: '#3FFF00',
  rgb: 'rgb(63,255,0)',
}, {
  aci: 81,
  hex: '#BFFFAA',
  rgb: 'rgb(191,255,170)',
}, {
  aci: 82,
  hex: '#2EBD00',
  rgb: 'rgb(46,189,0)',
}, {
  aci: 83,
  hex: '#8DBD7E',
  rgb: 'rgb(141,189,126)',
}, {
  aci: 84,
  hex: '#1F8100',
  rgb: 'rgb(31,129,0)',
}, {
  aci: 85,
  hex: '#608156',
  rgb: 'rgb(96,129,86)',
}, {
  aci: 86,
  hex: '#196800',
  rgb: 'rgb(25,104,0)',
}, {
  aci: 87,
  hex: '#4E6845',
  rgb: 'rgb(78,104,69)',
}, {
  aci: 88,
  hex: '#134F00',
  rgb: 'rgb(19,79,0)',
}, {
  aci: 89,
  hex: '#3B4F35',
  rgb: 'rgb(59,79,53)',
}, {
  aci: 90,
  hex: '#00FF00',
  rgb: 'rgb(0,255,0)',
}, {
  aci: 91,
  hex: '#AAFFAA',
  rgb: 'rgb(170,255,170)',
}, {
  aci: 92,
  hex: '#00BD00',
  rgb: 'rgb(0,189,0)',
}, {
  aci: 93,
  hex: '#7EBD7E',
  rgb: 'rgb(126,189,126)',
}, {
  aci: 94,
  hex: '#008100',
  rgb: 'rgb(0,129,0)',
}, {
  aci: 95,
  hex: '#568156',
  rgb: 'rgb(86,129,86)',
}, {
  aci: 96,
  hex: '#006800',
  rgb: 'rgb(0,104,0)',
}, {
  aci: 97,
  hex: '#456845',
  rgb: 'rgb(69,104,69)',
}, {
  aci: 98,
  hex: '#004F00',
  rgb: 'rgb(0,79,0)',
}, {
  aci: 99,
  hex: '#354F35',
  rgb: 'rgb(53,79,53)',
}, {
  aci: 100,
  hex: '#00FF3F',
  rgb: 'rgb(0,255,63)',
}, {
  aci: 101,
  hex: '#AAFFBF',
  rgb: 'rgb(170,255,191)',
}, {
  aci: 102,
  hex: '#00BD2E',
  rgb: 'rgb(0,189,46)',
}, {
  aci: 103,
  hex: '#7EBD8D',
  rgb: 'rgb(126,189,141)',
}, {
  aci: 104,
  hex: '#00811F',
  rgb: 'rgb(0,129,31)',
}, {
  aci: 105,
  hex: '#568160',
  rgb: 'rgb(86,129,96)',
}, {
  aci: 106,
  hex: '#006819',
  rgb: 'rgb(0,104,25)',
}, {
  aci: 107,
  hex: '#45684E',
  rgb: 'rgb(69,104,78)',
}, {
  aci: 108,
  hex: '#004F13',
  rgb: 'rgb(0,79,19)',
}, {
  aci: 109,
  hex: '#354F3B',
  rgb: 'rgb(53,79,59)',
}, {
  aci: 110,
  hex: '#00FF7F',
  rgb: 'rgb(0,255,127)',
}, {
  aci: 111,
  hex: '#AAFFD4',
  rgb: 'rgb(170,255,212)',
}, {
  aci: 112,
  hex: '#00BD5E',
  rgb: 'rgb(0,189,94)',
}, {
  aci: 113,
  hex: '#7EBD9D',
  rgb: 'rgb(126,189,157)',
}, {
  aci: 114,
  hex: '#008140',
  rgb: 'rgb(0,129,64)',
}, {
  aci: 115,
  hex: '#56816B',
  rgb: 'rgb(86,129,107)',
}, {
  aci: 116,
  hex: '#006834',
  rgb: 'rgb(0,104,52)',
}, {
  aci: 117,
  hex: '#456856',
  rgb: 'rgb(69,104,86)',
}, {
  aci: 118,
  hex: '#004F27',
  rgb: 'rgb(0,79,39)',
}, {
  aci: 119,
  hex: '#354F42',
  rgb: 'rgb(53,79,66)',
}, {
  aci: 120,
  hex: '#00FFBF',
  rgb: 'rgb(0,255,191)',
}, {
  aci: 121,
  hex: '#AAFFEA',
  rgb: 'rgb(170,255,234)',
}, {
  aci: 122,
  hex: '#00BD8D',
  rgb: 'rgb(0,189,141)',
}, {
  aci: 123,
  hex: '#7EBDAD',
  rgb: 'rgb(126,189,173)',
}, {
  aci: 124,
  hex: '#008160',
  rgb: 'rgb(0,129,96)',
}, {
  aci: 125,
  hex: '#568176',
  rgb: 'rgb(86,129,118)',
}, {
  aci: 126,
  hex: '#00684E',
  rgb: 'rgb(0,104,78)',
}, {
  aci: 127,
  hex: '#45685F',
  rgb: 'rgb(69,104,95)',
}, {
  aci: 128,
  hex: '#004F3B',
  rgb: 'rgb(0,79,59)',
}, {
  aci: 129,
  hex: '#354F49',
  rgb: 'rgb(53,79,73)',
}, {
  aci: 130,
  hex: '#00FFFF',
  rgb: 'rgb(0,255,255)',
}, {
  aci: 131,
  hex: '#AAFFFF',
  rgb: 'rgb(170,255,255)',
}, {
  aci: 132,
  hex: '#00BDBD',
  rgb: 'rgb(0,189,189)',
}, {
  aci: 133,
  hex: '#7EBDBD',
  rgb: 'rgb(126,189,189)',
}, {
  aci: 134,
  hex: '#008181',
  rgb: 'rgb(0,129,129)',
}, {
  aci: 135,
  hex: '#568181',
  rgb: 'rgb(86,129,129)',
}, {
  aci: 136,
  hex: '#006868',
  rgb: 'rgb(0,104,104)',
}, {
  aci: 137,
  hex: '#456868',
  rgb: 'rgb(69,104,104)',
}, {
  aci: 138,
  hex: '#004F4F',
  rgb: 'rgb(0,79,79)',
}, {
  aci: 139,
  hex: '#354F4F',
  rgb: 'rgb(53,79,79)',
}, {
  aci: 140,
  hex: '#00BFFF',
  rgb: 'rgb(0,191,255)',
}, {
  aci: 141,
  hex: '#AAEAFF',
  rgb: 'rgb(170,234,255)',
}, {
  aci: 142,
  hex: '#008DBD',
  rgb: 'rgb(0,141,189)',
}, {
  aci: 143,
  hex: '#7EADBD',
  rgb: 'rgb(126,173,189)',
}, {
  aci: 144,
  hex: '#006081',
  rgb: 'rgb(0,96,129)',
}, {
  aci: 145,
  hex: '#567681',
  rgb: 'rgb(86,118,129)',
}, {
  aci: 146,
  hex: '#004E68',
  rgb: 'rgb(0,78,104)',
}, {
  aci: 147,
  hex: '#455F68',
  rgb: 'rgb(69,95,104)',
}, {
  aci: 148,
  hex: '#003B4F',
  rgb: 'rgb(0,59,79)',
}, {
  aci: 149,
  hex: '#35494F',
  rgb: 'rgb(53,73,79)',
}, {
  aci: 150,
  hex: '#007FFF',
  rgb: 'rgb(0,127,255)',
}, {
  aci: 151,
  hex: '#AAD4FF',
  rgb: 'rgb(170,212,255)',
}, {
  aci: 152,
  hex: '#005EBD',
  rgb: 'rgb(0,94,189)',
}, {
  aci: 153,
  hex: '#7E9DBD',
  rgb: 'rgb(126,157,189)',
}, {
  aci: 154,
  hex: '#004081',
  rgb: 'rgb(0,64,129)',
}, {
  aci: 155,
  hex: '#566B81',
  rgb: 'rgb(86,107,129)',
}, {
  aci: 156,
  hex: '#003468',
  rgb: 'rgb(0,52,104)',
}, {
  aci: 157,
  hex: '#455668',
  rgb: 'rgb(69,86,104)',
}, {
  aci: 158,
  hex: '#00274F',
  rgb: 'rgb(0,39,79)',
}, {
  aci: 159,
  hex: '#35424F',
  rgb: 'rgb(53,66,79)',
}, {
  aci: 160,
  hex: '#003FFF',
  rgb: 'rgb(0,63,255)',
}, {
  aci: 161,
  hex: '#AABFFF',
  rgb: 'rgb(170,191,255)',
}, {
  aci: 162,
  hex: '#002EBD',
  rgb: 'rgb(0,46,189)',
}, {
  aci: 163,
  hex: '#7E8DBD',
  rgb: 'rgb(126,141,189)',
}, {
  aci: 164,
  hex: '#001F81',
  rgb: 'rgb(0,31,129)',
}, {
  aci: 165,
  hex: '#566081',
  rgb: 'rgb(86,96,129)',
}, {
  aci: 166,
  hex: '#001968',
  rgb: 'rgb(0,25,104)',
}, {
  aci: 167,
  hex: '#454E68',
  rgb: 'rgb(69,78,104)',
}, {
  aci: 168,
  hex: '#00134F',
  rgb: 'rgb(0,19,79)',
}, {
  aci: 169,
  hex: '#353B4F',
  rgb: 'rgb(53,59,79)',
}, {
  aci: 170,
  hex: '#0000FF',
  rgb: 'rgb(0,0,255)',
}, {
  aci: 171,
  hex: '#AAAAFF',
  rgb: 'rgb(170,170,255)',
}, {
  aci: 172,
  hex: '#0000BD',
  rgb: 'rgb(0,0,189)',
}, {
  aci: 173,
  hex: '#7E7EBD',
  rgb: 'rgb(126,126,189)',
}, {
  aci: 174,
  hex: '#000081',
  rgb: 'rgb(0,0,129)',
}, {
  aci: 175,
  hex: '#565681',
  rgb: 'rgb(86,86,129)',
}, {
  aci: 176,
  hex: '#000068',
  rgb: 'rgb(0,0,104)',
}, {
  aci: 177,
  hex: '#454568',
  rgb: 'rgb(69,69,104)',
}, {
  aci: 178,
  hex: '#00004F',
  rgb: 'rgb(0,0,79)',
}, {
  aci: 179,
  hex: '#35354F',
  rgb: 'rgb(53,53,79)',
}, {
  aci: 180,
  hex: '#3F00FF',
  rgb: 'rgb(63,0,255)',
}, {
  aci: 181,
  hex: '#BFAAFF',
  rgb: 'rgb(191,170,255)',
}, {
  aci: 182,
  hex: '#2E00BD',
  rgb: 'rgb(46,0,189)',
}, {
  aci: 183,
  hex: '#8D7EBD',
  rgb: 'rgb(141,126,189)',
}, {
  aci: 184,
  hex: '#1F0081',
  rgb: 'rgb(31,0,129)',
}, {
  aci: 185,
  hex: '#605681',
  rgb: 'rgb(96,86,129)',
}, {
  aci: 186,
  hex: '#190068',
  rgb: 'rgb(25,0,104)',
}, {
  aci: 187,
  hex: '#4E4568',
  rgb: 'rgb(78,69,104)',
}, {
  aci: 188,
  hex: '#13004F',
  rgb: 'rgb(19,0,79)',
}, {
  aci: 189,
  hex: '#3B354F',
  rgb: 'rgb(59,53,79)',
}, {
  aci: 190,
  hex: '#7F00FF',
  rgb: 'rgb(127,0,255)',
}, {
  aci: 191,
  hex: '#D4AAFF',
  rgb: 'rgb(212,170,255)',
}, {
  aci: 192,
  hex: '#5E00BD',
  rgb: 'rgb(94,0,189)',
}, {
  aci: 193,
  hex: '#9D7EBD',
  rgb: 'rgb(157,126,189)',
}, {
  aci: 194,
  hex: '#400081',
  rgb: 'rgb(64,0,129)',
}, {
  aci: 195,
  hex: '#6B5681',
  rgb: 'rgb(107,86,129)',
}, {
  aci: 196,
  hex: '#340068',
  rgb: 'rgb(52,0,104)',
}, {
  aci: 197,
  hex: '#564568',
  rgb: 'rgb(86,69,104)',
}, {
  aci: 198,
  hex: '#27004F',
  rgb: 'rgb(39,0,79)',
}, {
  aci: 199,
  hex: '#42354F',
  rgb: 'rgb(66,53,79)',
}, {
  aci: 200,
  hex: '#BF00FF',
  rgb: 'rgb(191,0,255)',
}, {
  aci: 201,
  hex: '#EAAAFF',
  rgb: 'rgb(234,170,255)',
}, {
  aci: 202,
  hex: '#8D00BD',
  rgb: 'rgb(141,0,189)',
}, {
  aci: 203,
  hex: '#AD7EBD',
  rgb: 'rgb(173,126,189)',
}, {
  aci: 204,
  hex: '#600081',
  rgb: 'rgb(96,0,129)',
}, {
  aci: 205,
  hex: '#765681',
  rgb: 'rgb(118,86,129)',
}, {
  aci: 206,
  hex: '#4E0068',
  rgb: 'rgb(78,0,104)',
}, {
  aci: 207,
  hex: '#5F4568',
  rgb: 'rgb(95,69,104)',
}, {
  aci: 208,
  hex: '#3B004F',
  rgb: 'rgb(59,0,79)',
}, {
  aci: 209,
  hex: '#49354F',
  rgb: 'rgb(73,53,79)',
}, {
  aci: 210,
  hex: '#FF00FF',
  rgb: 'rgb(255,0,255)',
}, {
  aci: 211,
  hex: '#FFAAFF',
  rgb: 'rgb(255,170,255)',
}, {
  aci: 212,
  hex: '#BD00BD',
  rgb: 'rgb(189,0,189)',
}, {
  aci: 213,
  hex: '#BD7EBD',
  rgb: 'rgb(189,126,189)',
}, {
  aci: 214,
  hex: '#810081',
  rgb: 'rgb(129,0,129)',
}, {
  aci: 215,
  hex: '#815681',
  rgb: 'rgb(129,86,129)',
}, {
  aci: 216,
  hex: '#680068',
  rgb: 'rgb(104,0,104)',
}, {
  aci: 217,
  hex: '#684568',
  rgb: 'rgb(104,69,104)',
}, {
  aci: 218,
  hex: '#4F004F',
  rgb: 'rgb(79,0,79)',
}, {
  aci: 219,
  hex: '#4F354F',
  rgb: 'rgb(79,53,79)',
}, {
  aci: 220,
  hex: '#FF00BF',
  rgb: 'rgb(255,0,191)',
}, {
  aci: 221,
  hex: '#FFAAEA',
  rgb: 'rgb(255,170,234)',
}, {
  aci: 222,
  hex: '#BD008D',
  rgb: 'rgb(189,0,141)',
}, {
  aci: 223,
  hex: '#BD7EAD',
  rgb: 'rgb(189,126,173)',
}, {
  aci: 224,
  hex: '#810060',
  rgb: 'rgb(129,0,96)',
}, {
  aci: 225,
  hex: '#815676',
  rgb: 'rgb(129,86,118)',
}, {
  aci: 226,
  hex: '#68004E',
  rgb: 'rgb(104,0,78)',
}, {
  aci: 227,
  hex: '#68455F',
  rgb: 'rgb(104,69,95)',
}, {
  aci: 228,
  hex: '#4F003B',
  rgb: 'rgb(79,0,59)',
}, {
  aci: 229,
  hex: '#4F3549',
  rgb: 'rgb(79,53,73)',
}, {
  aci: 230,
  hex: '#FF007F',
  rgb: 'rgb(255,0,127)',
}, {
  aci: 231,
  hex: '#FFAAD4',
  rgb: 'rgb(255,170,212)',
}, {
  aci: 232,
  hex: '#BD005E',
  rgb: 'rgb(189,0,94)',
}, {
  aci: 233,
  hex: '#BD7E9D',
  rgb: 'rgb(189,126,157)',
}, {
  aci: 234,
  hex: '#810040',
  rgb: 'rgb(129,0,64)',
}, {
  aci: 235,
  hex: '#81566B',
  rgb: 'rgb(129,86,107)',
}, {
  aci: 236,
  hex: '#680034',
  rgb: 'rgb(104,0,52)',
}, {
  aci: 237,
  hex: '#684556',
  rgb: 'rgb(104,69,86)',
}, {
  aci: 238,
  hex: '#4F0027',
  rgb: 'rgb(79,0,39)',
}, {
  aci: 239,
  hex: '#4F3542',
  rgb: 'rgb(79,53,66)',
}, {
  aci: 240,
  hex: '#FF003F',
  rgb: 'rgb(255,0,63)',
}, {
  aci: 241,
  hex: '#FFAABF',
  rgb: 'rgb(255,170,191)',
}, {
  aci: 242,
  hex: '#BD002E',
  rgb: 'rgb(189,0,46)',
}, {
  aci: 243,
  hex: '#BD7E8D',
  rgb: 'rgb(189,126,141)',
}, {
  aci: 244,
  hex: '#81001F',
  rgb: 'rgb(129,0,31)',
}, {
  aci: 245,
  hex: '#815660',
  rgb: 'rgb(129,86,96)',
}, {
  aci: 246,
  hex: '#680019',
  rgb: 'rgb(104,0,25)',
}, {
  aci: 247,
  hex: '#68454E',
  rgb: 'rgb(104,69,78)',
}, {
  aci: 248,
  hex: '#4F0013',
  rgb: 'rgb(79,0,19)',
}, {
  aci: 249,
  hex: '#4F353B',
  rgb: 'rgb(79,53,59)',
}, {
  aci: 250,
  hex: '#333333',
  rgb: 'rgb(51,51,51)',
}, {
  aci: 251,
  hex: '#505050',
  rgb: 'rgb(80,80,80)',
}, {
  aci: 252,
  hex: '#696969',
  rgb: 'rgb(105,105,105)',
}, {
  aci: 253,
  hex: '#828282',
  rgb: 'rgb(130,130,130)',
}, {
  aci: 254,
  hex: '#BEBEBE',
  rgb: 'rgb(190,190,190)',
}, {
  aci: 255,
  hex: '#FFFFFF',
  rgb: 'rgb(255,255,255)',
}];

function findByKey(key: any, val: any) {
  if (typeof key === 'undefined') {
    throw new Error('invalid key, must be: "aci", "hex" or "rgb"');
  }

  if (typeof val === 'undefined') {
    throw new Error(`invalid value, must provide "${key}" value`);
  }

  let found;

  list.forEach((item: any) => {
    if (item[key] && item[key] === val) {
      found = item.aci;
    }
  });

  return found;
}

export const ACI: any = {};

ACI.getByHEX = (hex: any) => findByKey('hex', hex);
ACI.list = list.map((item) => item.hex);

export default ACI;
