// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  templateName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '250px',
  },
  templateListItem: {
    minWidth: '350px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    padding: theme.spacing(3),
    paddingRight: theme.spacing(1),
    height: '30px',
  },
  templateItem: {
    display: 'flex',
    alignItems: 'center',
  },
  createTemplateButtonWrapper: {
    bottom: 0,
    width: '100%',
    padding: theme.spacing(2),
  },
  vertButton: {
    right: 0,
    padding: '0px',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
  },
  defaultItem: {
    right: theme.spacing(2),
    color: '#2E4DEB',
    fontSize: '12px',
  },
});

export default styles;
