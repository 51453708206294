import { createAction } from '@reduxjs/toolkit';

export const SetSelectedCadFile3DLayersAction = createAction<{ orderId: string, cadFile3DLayers: I3DLayer }>('viewer3D/SetSelectedCadFile3DLayersAction');

export const Get3DLineworkSuccessAction = createAction<ILinework3DState[]>('viewer3D/Get3DLineworkSuccessAction');

export const Toggle3DLayerAction = createAction<{ siteId: string, layerId: number }>('viewer3D/Toggle3DLayerAction');

export const ToggleAll3DLayersAction = createAction<{ siteId: string, toggle: boolean }>('viewer3D/ToggleAll3DLayersAction');

export const Toggle3DOrderAction = createAction<string>('viewer3D/Toggle3DOrderAction');

export const SetLayer3DChangeAction = createAction<(string|boolean)[]>('viewer3D/SetLayer3DChangeAction');

export const Clear3DLineworkAction = createAction('viewer3D/Clear3DLineworkAction');

export const SetCad3DLayersAction = createAction<{ [key: string]: ICad3DLayer }>('viewer3D/SetCad3DLayersAction');

export const SetOrder3DCadFilesListAction = createAction<{ [key: string]: string[] }>('viewer3D/SetOrder3DCadFilesListAction');

export const SetClassVisibleAction = createAction<any>('viewer3D/SetClassVisibleAction');

export const SetToggleClassAction = createAction<string>('viewer3D/SetToggleClassAction');
