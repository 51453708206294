// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Box, useTheme } from '@mui/system';
import { Dialog, DialogActions, DialogContent, Typography, Button } from '@mui/material/';
import ErrorIcon from '@mui/icons-material/Error';
import { formatValue } from 'Utils/deliverables';
import stylesDef from './styles';

interface CreditOverageDialogProps {
  showDialog: boolean;
  overageInfo: IOverageInfo;
  totalDollar: number;
  pricingPlanName: string;
  onOk: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onCancel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const CreditOverageDialog = ({ showDialog, overageInfo, totalDollar, pricingPlanName, onOk, onCancel }: CreditOverageDialogProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const priceDifference = overageInfo ? (totalDollar - overageInfo.nextTierTotalDollarCost).toFixed(2) : 0;
  const isNoCommitPlan = pricingPlanName && pricingPlanName === 'no_commit';
  return (
    <Dialog open={showDialog} onClose={onCancel} sx={styles.dialog}>
      <Box sx={styles.dialogBox}>
        <Box sx={styles.dialogTitle}>
          <ErrorIcon sx={styles.warningIcon} />
          {isNoCommitPlan ? (
            <Typography variant="h3" sx={styles.titleText}>
              You could be saving on this project.
            </Typography>
          ) : (
            <Typography variant="h3" sx={styles.titleText}>
              This project could be
              <Typography variant="h3" sx={styles.titlePriceText} component="span">{` $${priceDifference} `}</Typography>
              cheaper!
            </Typography>
          )}
        </Box>
        <DialogContent>
          {isNoCommitPlan ? (
            <Typography component="span">
              You could be saving
              <Typography sx={styles.titlePriceText} component="span">{` $${priceDifference} `}</Typography>
              on this project by committing to an annual plan. Customers that choose professional packages get a per credit cost as low as $20. If you&apos;re interested in discussing our other packages, reach out to
            </Typography>
          ) : (
            <Typography component="span">
              Your project exceeds your plan&apos;s available credits. You can still place this order but you could qualify for a better price on this project by enrolling in a new package. Reach out to
            </Typography>
          )}
          <Typography component="span">
            {' '}
            <a style={styles.linkColor} target="_blank" href="mailto:sales@airworks.io" rel="noreferrer">sales@airworks.io</a>
            {' '}
            to get started.
          </Typography>
          <Box sx={styles.compare}>
            <Box>
              <Typography sx={styles.title} variant="h5">Your current cost</Typography>
              {!isNoCommitPlan && <Typography sx={styles.subTitle} variant="h6">Cost</Typography>}
              <Typography sx={styles.cost} variant="h2">{`$${formatValue(totalDollar)}`}</Typography>
              {!isNoCommitPlan && (
                <>
                  <Typography sx={styles.subTitle} variant="h6">Available Credits</Typography>
                  <Typography sx={styles.cost} variant="h2">{`${formatValue(overageInfo?.currentTierAvailableCredits)} credits`}</Typography>
                </>
              )}
            </Box>
            <Box sx={styles.line} />
            <Box>
              <Typography sx={styles.title} variant="h5">What you could be paying</Typography>
              {!isNoCommitPlan && <Typography sx={styles.subTitle} variant="h6">Cost</Typography>}
              <Typography sx={styles.cost} variant="h2" color="primary">{`$${formatValue(overageInfo?.nextTierTotalDollarCost)}`}</Typography>
              {!isNoCommitPlan && (
                <>
                  <Typography sx={styles.subTitle} variant="h6">Available Credits</Typography>
                  <Typography sx={styles.cost} variant="h2" color="primary">{`${formatValue(overageInfo?.nextTierAvailableCredits)} credits`}</Typography>
                </>
              )}
            </Box>
          </Box>
          <Typography sx={styles.terms}>* The prices shown here are estimates. Final project costs may change at AirWorks&apos; discretion, based on project details.</Typography>
          <hr style={styles.hrLine} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} sx={styles.okButton}>Proceed Anyway</Button>
          <Button onClick={onOk} sx={styles.contactButton}>Contact Sales</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default CreditOverageDialog;
