// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  list: {
    backgroundColor: '#F8F8F8',
    padding: '32px 48px',
    display: 'flex',
    overflow: 'auto',
  },
  finishedIcon: {
    fontSize: '16px',
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
    color: '#01A1DD',
  },
  listItemIcon: {
    minWidth: 0,
  },
  bundleListItem: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    paddingTop: '4px',
    paddingBottom: '4px',
  },
  insideDiv: {
    width: '50%',
  },
  icon: {
    width: '20px',
    height: '20px',
    cursor: 'pointer',
  },
  infoTitle: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    columnGap: theme.spacing(1),
  },
  infoBox: {
    backgroundColor: '#F8F8F8',
    padding: '32px 48px',
  },
  infoText: {
    marginTop: theme.spacing(1),
  },
});

export default styles;
