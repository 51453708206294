// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { Divider, Typography, Button } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import stylesDef from './styles';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { GetGlobalStatusThunk, GetOpsCreditCapacityThunk, UpdateGlobalStatusThunk } from 'Features/globalStatus/globalStatusThunk';


export const AccountOpsSettings = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const { allowExpeditedProcess, dailyCreditCommitment } = useAppSelector((state) => state.globalStatus.status);

  const [allowExpedited, setAllowExpedited] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      await dispatch(GetOpsCreditCapacityThunk());
      await dispatch(GetGlobalStatusThunk());
    };
    loadData();
  }, []);

  useEffect(() => {
    setAllowExpedited(allowExpeditedProcess);
  }, [allowExpeditedProcess]);

  const setAllowExpeditedProcess = async (value: boolean) => {
    setAllowExpedited(value);
    await dispatch(UpdateGlobalStatusThunk(value));
  };

  return (
    <Box sx={styles.pageWrapper}>
      <Typography variant="h2">Operations Settings</Typography>
      <Divider sx={styles.divider} />
      <Box sx={styles.settingWrapper}>
        <Typography variant="h4">Allow Expedited Processing</Typography>
        <Box>          
      <Button
        sx={{
          color: allowExpedited ? 'primary' : 'inherit', 
        }}
        onClick={() => setAllowExpeditedProcess(true)}
      >
        Yes
      </Button>
      {' '}
      /
      {' '}
      <Button
        sx={{
          color: !allowExpedited ? 'primary' : 'inherit', // Set 'primary' for false, 'inherit' for true
        }}
        onClick={() => setAllowExpeditedProcess(false)}
      >
        No
      </Button>
    </Box>
      </Box>
      {dailyCreditCommitment && (
        <Box>
          <Divider sx={styles.divider} />
          <Box sx={styles.settingWrapper}>
            <Typography variant="h4">Ops Credit Capacity</Typography>
            <Box>
              <Typography variant="h6">
                {dailyCreditCommitment}
                {' '}
                credits per day
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AccountOpsSettings;
