import { createAction } from '@reduxjs/toolkit';

export const SetProjectIdAction = createAction<string>('project/SetProjectIdAction');

export const CreateProjectStartAction = createAction('project/CreateProjectStartAction');
export const CreateProjectSuccessAction = createAction<IProject>('project/CreateProjectSuccessAction');
export const CreateProjectFailureAction = createAction('project/CreateProjectFailureAction');

export const SetFileStatusJsonAction = createAction('project/SetFileStatusJsonAction');

export const GetProjectStartAction = createAction('project/GetProjectStartAction');
export const GetProjectSuccessAction = createAction<IProject>('project/GetProjectSuccessAction');
export const GetProjectFailureAction = createAction('project/GetProjectFailureAction');

export const ChangeJsonAction = createAction('project/ChangeJsonAction');

export const UpdateProjectAction = createAction<IProject>('project/UpdateProjectAction');

export const UpdateProjectTifFilesAction = createAction<ITifFile>('project/UpdateProjectTifFilesAction');
export const UpdateProjectLasFilesAction = createAction<ILasFile>('project/UpdateProjectLasFilesAction');

export const SetShowKmlToolbarAction = createAction<boolean>('project/SetShowKmlToolbarAction');

export const DeleteProjectTifFileAction = createAction<any>('project/DeleteProjectTifFileAction');
export const DeleteProjectLasFileAction = createAction<any>('project/DeleteProjectLasFileAction');

export const GetEpsgProjectionsAction = createAction<IEPSG>('project/GetEpsgProjectionsAction');

export const ResetEpsgProjectionsAction = createAction('project/ResetEpsgProjectionsAction');

export const UpdateImageryRequestSuccessAction = createAction<IImageryRequest>('project/UpdateImageryRequestSuccessAction');

export const UpdateRasterRequestSuccessAction = createAction<IRasterRequest>('project/UpdateRasterRequestSuccessAction');

export const SetRasterizationSelectedAction = createAction<boolean>('project/SetRasterizationSelectedAction');
export const SetWarningAcceptedAction = createAction('project/SetWarningAcceptedAction');

export const SendImageryUploadEmailStartAction = createAction('project/SendImageryUploadEmailStartAction');
export const SendImageryUploadEmailSuccessAction = createAction<IImageryRequest>('project/SendImageryUploadEmailSuccessAction');
export const SendImageryUploadEmailFailureAction = createAction<string>('project/SendImageryUploadEmailFailureAction');

export const SendRasterUploadEmailStartAction = createAction('project/SendRasterUploadEmailStartAction');
export const SendRasterUploadEmailSuccessAction = createAction<IRasterRequest>('project/SendRasterUploadEmailSuccessAction');
export const SendRasterUploadEmailFailureAction = createAction<string>('project/SendRasterUploadEmailFailureAction');
