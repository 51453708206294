// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import { Button, Fade, CircularProgress, SnackbarContent, Collapse, Divider, Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import ErrorIcon from '@mui/icons-material/Error';
import { TextField, Validators, Helpers } from 'Components/forms';
import { Form } from 'react-final-form';
import LoadingSpinner from 'Components/LoadingSpinner';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useAppDispatch, useAppSelector } from 'Hooks';
import stylesDef from './styles';
import AccountGeneralOrg from './orgGeneral';
import { GetUserProfileThunk, UpdateUserProfileThunk } from '../accountThunk';

export const AccountGeneral = () => {
  const user = useAppSelector((state) => state.account.user);
  const updateUserMessage = useAppSelector((state) => state.account.updateUserMessage);
  const loading = useAppSelector((state) => state.account.loading);
  const initialValues = user;
  const resources = useAppSelector((state) => state.auth.resources);
  const organizationAccess = resources?.organizationAccess;
  const theme = useTheme();
  const styles = stylesDef(theme);

  const [initialState, setInitialState] = useState(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(GetUserProfileThunk());
    if (!initialState) setInitialState(initialValues);
  }, []);

  const updateUserProfile = (payload: IUser) => dispatch(UpdateUserProfileThunk(payload));
  const onFormSubmit = (values: any) => Helpers.submit(values, updateUserProfile);

  return (
    <Box sx={styles.contentWrapper}>
      <Typography variant="h4">User Info</Typography>
      <LoadingSpinner loading={loading}>
        <Form
          onSubmit={onFormSubmit}
          keepDirtyOnReinitialize
          initialValues={initialState}
          render={({ hasSubmitErrors, modifiedSinceLastSubmit, submitting, pristine, submitError, submitSucceeded, handleSubmit }) => (
            <form
              style={styles.form}
              onSubmit={handleSubmit}
            >
              <Box sx={styles.formRow}>
                <TextField
                  label="First Name"
                  name="firstName"
                  validate={Validators.required}
                  autoFocus
                />
                <TextField
                  label="Last Name"
                  name="lastName"
                  validate={Validators.required}
                />
              </Box>
              <TextField
                label="Work Email"
                name="email"
                disabled
                validate={Validators.composeValidators(Validators.required, Validators.email)}
              />
              <TextField
                label="Job Title"
                name="jobTitle"
                validate={Validators.required}
              />
              <TextField
                label="Phone Number"
                name="phoneNumber"
                validate={Validators.composeValidators(Validators.required, Validators.mobilePhone)}
              />
              <Collapse in={(hasSubmitErrors && !modifiedSinceLastSubmit)}>
                <SnackbarContent
                  sx={styles.errorSnackbar}
                  message={(
                    <Box sx={styles.snackbarContent}>
                      <ErrorIcon />
                      {submitError}
                    </Box>
                  )}
                />
              </Collapse>
              <Collapse in={!submitting && submitSucceeded}>
                <SnackbarContent
                  sx={styles.successSnackbar}
                  message={(
                    <Box sx={styles.snackbarContent}>
                      <CheckCircleIcon />
                      {updateUserMessage}
                    </Box>
                  )}
                />
              </Collapse>
              <Button
                variant="contained"
                color="primary"
                sx={styles.submitButton}
                type="submit"
                disabled={pristine || (hasSubmitErrors && !modifiedSinceLastSubmit) || submitting}
              >
                <Fade in={submitting}>
                  <CircularProgress size={24} sx={styles.buttonProgress} />
                </Fade>
                <Fade in={!submitting}>
                  <span>Update User Info</span>
                </Fade>
              </Button>
            </form>
          )}
        />
      </LoadingSpinner>
      <Divider sx={styles.divider} />
      {organizationAccess && <AccountGeneralOrg />}
    </Box>
  );
};

export default AccountGeneral;
