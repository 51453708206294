// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { Paper, Button } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useAppSelector } from 'Hooks';
import Sidebar from 'Components/Sidebar';
import AddTemplateDialog from '../AddTemplateDialog';
import TemplateItems from '../templateItems';
import stylesDef from './styles';

interface ComponentProps {
  templateId: string;
  handleDownloadTemplate: (id: string) => void;
}

export const TemplateSidebar: React.FC<ComponentProps> = ({ templateId, handleDownloadTemplate }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const [showDialog, setShowDialog] = useState(false);
  const { list } = useAppSelector((state) => state.cadTemplate);

  return (
    <Sidebar>
      <Box sx={styles.sidebarContent}>
        {list.map((template, index) => <TemplateItems key={template._id} template={template} templateId={templateId} handleDownloadTemplate={handleDownloadTemplate} />)}
      </Box>
      <Paper sx={styles.createTemplateButtonWrapper}>
        <Button onClick={() => setShowDialog(true)} sx={styles.addTemplateButton}>
          <AddCircleIcon sx={styles.addIcon} />
          New Template
        </Button>
      </Paper>
      <AddTemplateDialog
        showDialog={showDialog}
        setShowDialog={setShowDialog}
      />
    </Sidebar>
  );
};

export default TemplateSidebar;
