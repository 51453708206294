// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { MenuItem, Select, TableRow, TableCell, InputBase, Chip, Checkbox, ClickAwayListener } from '@mui/material';
import { Box, useTheme, ThemeProvider } from '@mui/system';
import DehazeIcon from '@mui/icons-material/Dehaze';
import { useParams } from 'react-router-dom';
import { ACI } from 'Utils/autoCadIndexes';
import { useInView } from 'react-intersection-observer';
import { lineStyles, lineWeight } from 'Utils/lineStyles';
import { airworksLayerStandards } from 'Utils/airworksLayers';
import { InputBaseWidth } from 'Utils/themes';
import { CompactPicker } from 'react-color';
import { useAppDispatch, useAppSelector } from 'Hooks';
import Tooltip from 'Components/Tooltip';
import { SetSelectedStyle, SetSelectedWeight, SetSelectedColor, SetSelectedMapping } from '../../cadTemplateThunk';
import stylesDef from './styles';

const ITEM_HEIGHT = 70;
const toolTipText = _ADMIN_ ? 'Template editing has been disabled for Admin.' : 'This template is not editable. Duplicate to make edits on a copy.';

interface ComponentProps {
  layer: ILayers;
  templateId: string;
  setLayersStyle: (fun: any) => void;
  setLayersWeight: (fun: any) => void;
  setLayersColor: (fun: any) => void;
  setLayersMapping: (fun: any) => void;
}

export const LayerItem: React.FC<ComponentProps> = ({ layer, templateId, setLayersStyle, setLayersWeight, setLayersColor, setLayersMapping }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);

  const [openPicker, setOpenPicker] = useState({});

  const routeTemplateId = useParams().templateId;
  const template = useAppSelector((state) => state.cadTemplate.list?.find((item) => item._id === routeTemplateId));
  const dispatch = useAppDispatch();

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const openColorPicker = (layerId: string, airworksDefault : boolean) => {
    const obj: {[key: string]: boolean} = {};
    obj[layerId] = true;
    if (!airworksDefault) {
      setOpenPicker(obj);
    }
  };

  const closeColorPicker = () => {
    setOpenPicker({});
  };

  const handleChangeStyle = async (layerId: string, e: any) => {
    setSelectedStyle(layerId, e);
    dispatch(SetSelectedStyle({ templateId, layerId, event: e.target.value }));
  };

  const handleChangeWeight = async (layerId: string, e: any) => {
    setSelectedWeight(layerId, e);
    dispatch(SetSelectedWeight({ templateId, layerId, event: e.target.value }));
  };

  const handleChangeMapping = async (layerId: string, e: any) => {
    setSelectedMapping(layerId, e);
    dispatch(SetSelectedMapping({ templateId, layerId, event: e.target.value }));
  };

  const handleChangeComplete = async (layerId: string, color: any) => {
    setSelectedColor(layerId, color.hex.toUpperCase());
    dispatch(SetSelectedColor({ templateId, layerId, color: color.hex.toUpperCase() }));
    setOpenPicker({});
  };

  const createLayersStyleArray = (_id: string, value: string) => {
    setLayersStyle((layers: ILayers[]) => [...layers, { _id, style: value }]);
  };

  const createLayersWeightArray = (_id: string, value: string) => {
    setLayersWeight((layers: ILayers[]) => [...layers, { _id, weight: value }]);
  };

  const createLayersColorArray = (_id: string, value: string) => {
    setLayersColor((layers: ILayers[]) => [...layers, { _id, color: value }]);
  };

  const createLayersMappingArray = (_id: string, value: string) => {
    setLayersMapping((layers: ILayers[]) => [...layers, { _id, mapping: value }]);
  };

  const setSelectedStyle = (_id: string, event: any) => createLayersStyleArray(_id, event.target.value);
  const setSelectedWeight = (_id: string, event: any) => createLayersWeightArray(_id, event.target.value);
  const setSelectedColor = (_id: string, color: string) => createLayersColorArray(_id, color);
  const setSelectedMapping = (_id: string, event: any) => createLayersMappingArray(_id, event.target.value);

  const getLineHeight = (weight: number) => {
    if (weight < 0) return '0.01mm';
    if (weight < 10) return `0.0${weight}mm`;
    if (weight < 100) return `0.${weight}mm`;
    return '1mm';
  };

  return (
    <TableRow sx={styles.row} ref={ref}>
      {inView ?
        (
          <>
            <TableCell sx={{ ...styles.tableCellIcon, ...styles.tableCellStyle }}>
              <DehazeIcon sx={styles.cursor} className="cadTemplateTable-handle" />
            </TableCell>
            <Tooltip title={(_ADMIN_ || template.airworksTemplate) ? toolTipText : ''} placement="top">
              <Box sx={styles.borderStyle}>
                <TableCell sx={{ ...styles.tableCell, ...styles.tableCellName }} component="th" scope="row">
                  {layer.name.length > 17 ? (
                    <Tooltip title={layer.name} placement="top">
                      <Box sx={styles.nameText}>
                        {layer.name}
                      </Box>
                    </Tooltip>
                  ) : (
                    <Box sx={styles.nameText}>
                      {layer.name}
                    </Box>
                  )}
                </TableCell>
              </Box>
            </Tooltip>
            <Tooltip title={(_ADMIN_ || template.airworksTemplate) ? toolTipText : ''} placement="top">
              <Box sx={styles.borderLineStyle}>
                <TableCell sx={{ ...styles.tableCell, ...styles.tableCellStyle }} component="th" scope="row">
                  <Select
                    input={<InputBase sx={styles.input} />}
                    sx={styles.select}
                    value={layer.style}
                    disabled={_ADMIN_ || template.airworksTemplate}
                    disableUnderline
                    variant="outlined"
                    onChange={(e) => handleChangeStyle(layer._id, e)}
                  >
                    {lineStyles.map((line) => (
                      <MenuItem value={line.style} key={line.style}>
                        <img src={line.img} style={styles.lineStylePNG} alt="lineStyle" />
                        {' '}
                        {line.style}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </Box>
            </Tooltip>
            <Tooltip title={(_ADMIN_ || template.airworksTemplate) ? toolTipText : ''} placement="top">
              <Box sx={styles.borderStyle}>
                <TableCell sx={{ ...styles.tableCell, ...styles.tableCellWeight }} component="th" scope="row">
                  <Select
                    input={<InputBase sx={styles.input} />}
                    sx={styles.selectWeight}
                    value={layer.weight}
                    disabled={_ADMIN_ || template.airworksTemplate}
                    disableUnderline
                    onChange={(e) => handleChangeWeight(layer._id, e)}
                  >
                    {lineWeight.map((weight) => (
                      <MenuItem value={weight} key={weight}>
                        <hr
                          style={{ ...styles.horizontalLine, height: getLineHeight(weight) }}
                        />
                        {weight}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </Box>
            </Tooltip>
            <ClickAwayListener onClickAway={closeColorPicker}>
              <Tooltip title={(_ADMIN_ || template.airworksTemplate) ? toolTipText : ''} placement="top">
                <Box sx={{ ...styles.borderStyle, ...styles.colorBox }} onClick={() => openColorPicker(layer._id, template.airworksTemplate)}>
                  <TableCell sx={{ ...styles.tableCell, ...styles.tableCellName }} component="th" scope="row">
                    <Box
                      sx={styles.layerColor}
                      style={{ backgroundColor: layer.color }}
                    />
                    {ACI.getByHEX(layer.color)}
                    <Box sx={styles.compactPicker}>
                      {!_ADMIN_ && openPicker[layer._id] && (
                        <CompactPicker
                          position="below"
                          defaultValue={layer.color}
                          colors={ACI.list}
                          onChangeComplete={(color: string) => handleChangeComplete(layer._id, color)}
                        />
                      )}
                    </Box>
                  </TableCell>
                </Box>
              </Tooltip>
            </ClickAwayListener>
            <Tooltip title={(_ADMIN_ || template.airworksTemplate) ? toolTipText : ''} placement="top">
              <Box sx={{ ...styles.borderStyle, ...styles.mappingBox }}>
                <TableCell sx={styles.tableCellLayers} scope="row">
                  <ThemeProvider theme={InputBaseWidth}>
                    <Select
                      sx={styles.selectLayers}
                      multiple
                      IconComponent={null}
                      value={layer.mapping}
                      disableUnderline
                      disabled={_ADMIN_ || template.airworksTemplate}
                      onChange={(e) => handleChangeMapping(layer._id, e)}
                      renderValue={() => (
                        <Box sx={styles.chips}>
                          {layer.mapping.map((selected: string) => (
                            <Chip
                              size="medium"
                              onMouseDown={(event) => {
                                event.stopPropagation();
                              }}
                              key={selected}
                              label={selected}
                              sx={styles.chipRoot}
                            />
                          ))}
                          {layer.mapping.length > 1 && (
                            <Box sx={styles.badge}>
                              +
                              {layer.mapping.length - 1}
                            </Box>
                          )}
                        </Box>
                      )}
                      MenuProps={{
                        elevation: 0,
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'center',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'center',
                        },
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5,
                          width: 280,
                        },
                      }}
                    >
                      {airworksLayerStandards.map((layers) => (
                        <MenuItem
                          sx={{ root: styles.selectMapping, selected: styles.selected }}
                          key={layers}
                          value={layers}
                        >
                          <Checkbox sx={{ checked: styles.checked }} checked={layer.mapping.indexOf(layers) > -1} />
                          {layers}
                        </MenuItem>
                      ))}
                    </Select>
                  </ThemeProvider>
                </TableCell>
              </Box>
            </Tooltip>
          </>
        ) : null }
    </TableRow>
  );
};

export default LayerItem;
