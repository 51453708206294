// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { Typography, Divider, IconButton, Snackbar } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import history from 'Utils/history';
import { clearLocalStorage } from 'Utils/localStorageService';
import { useAppDispatch, useAppSelector } from 'Hooks';
import Alert from '@mui/material/Alert';
import ConfirmDialog from 'Components/ConfirmDialog';
import { GetOrgTeamsThunk, DeleteOrgTeamThunk, UpdateOrgTeamThunk } from '../accountThunk';
import AddTeamForm from './addTeam';
import stylesDef from './styles';

export const Teams = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);

  const { resources } = useAppSelector((state) => state.auth);
  const { orgTeams, teamError } = useAppSelector((state) => state.account);
  const organizationAccess = resources?.organizationAccess;
  const dispatch = useAppDispatch();

  const [deleteTeam, setDeleteTeam] = useState('');
  const [editTeam, setEditTeam] = useState('');
  const [editTeamId, setEditTeamId] = useState('');
  const [snackMessage, setSnackMessage] = useState('');
  const [edittingTeamName, setEdittingTeamName] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  useEffect(() => {
    if (!organizationAccess) {
      clearLocalStorage();
      history.push('/');
    }
  }, [organizationAccess]);

  useEffect(() => {
    if (teamError !== null) {
      setShowSnackbar(true);
    }
  }, [teamError]);

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbar(false);
  };

  const renderColumnHeader = (columnMeta: any) => (
    <td key={columnMeta.name} style={styles.columnHeader}>
      <Typography variant="h4">{columnMeta.label}</Typography>
    </td>
  );

  const deleteTeamClick = (team: string) => {
    setShowConfirmDialog(true);
    setDeleteTeam(team);
  };

  const onDeleteTeam = async () => {
    setShowConfirmDialog(false);
    const result = await dispatch(DeleteOrgTeamThunk(deleteTeam));
    if (result.success) {
      setSnackMessage('Team Deleted');
      setShowSnackbar(true);
    }
  };

  const editTeamClick = (id: string) => {
    setEditTeamId(id);
    setEdittingTeamName(true);
  };

  const onCancel = () => {
    setShowConfirmDialog(false);
  };

  const handleTeamEdit = (e) => {
    setEditTeam(e.target.value);
  };

  const onUpdateTeam = async () => {
    window?.pendo?.track('Team Updated', { team_name: editTeam, team_id: editTeamId });

    const result = await dispatch(UpdateOrgTeamThunk(editTeamId, editTeam));

    if (result.success) {
      setSnackMessage('Team Updated');
      setShowSnackbar(true);
    }
    setEdittingTeamName(false);
    setEditTeam('');
    setEditTeamId('');
  };

  const columns = [
    {
      name: 'name',
      label: 'NAME',
      options: {
        customHeadRender: renderColumnHeader,
        customBodyRender: (value: string, tableMeta: { rowData : any }) => (
          <Box sx={styles.nameColumn}>
            {edittingTeamName && tableMeta.rowData[2] === editTeamId ? (
              <input
                defaultValue={value}
                sx={styles.editInput}
                onChange={(e) => { handleTeamEdit(e); }}
              />
            ) : value}
          </Box>
        ),
      },
    },
    {
      name: 'admin',
      empty: true,
      options: {
        customHeadRender: (): void => null,
        customBodyRender: (admin: boolean, tableMeta: { rowData : any }) => (
          <Box sx={styles.iconButtons}>
            {edittingTeamName && tableMeta.rowData[2] === editTeamId ? (
              <>
                <IconButton
                  onClick={() => setEdittingTeamName(false)}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
                <IconButton
                  onClick={() => onUpdateTeam()}
                >
                  <CheckIcon fontSize="small" />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  onClick={() => editTeamClick(tableMeta.rowData[2])}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton
                  onClick={() => deleteTeamClick(tableMeta.rowData[2])}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </>
            )}
          </Box>
        ),
      },
    },
    {
      name: '_id',
      label: '_id',
      options: { display: false },
    },
  ];

  const options = {
    elevation: 0,
    responsive: 'stacked',
    serverSide: true,
    selectableRows: 'none',
    print: false,
    download: false,
    viewColumns: false,
    sort: false,
    search: false,
    filter: false,
    pagination: false,
    onTableInit: () => { dispatch(GetOrgTeamsThunk()); },
  };
  return (
    <Box sx={styles.pageWrapper}>
      <Snackbar sx={styles.snackBar} open={showSnackbar} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        {teamError ? <Alert severity="error" sx={styles.alert} color="error">{teamError}</Alert> : <Alert severity="success" sx={styles.alert} color="info">{snackMessage}</Alert>}
      </Snackbar>
      <Box sx={styles.pageWrapper}>
        <AddTeamForm setSnackMessage={setSnackMessage} setShowSnackbar={setShowSnackbar} />
        <Divider sx={styles.divider} />
        <MUIDataTable
          data={orgTeams}
          columns={columns}
          options={options}
          sx={styles.table}
        />
        <ConfirmDialog
          showDialog={showConfirmDialog}
          onOk={onDeleteTeam}
          onCancel={onCancel}
          contentText="Are you sure you want to delete this team? This action can't be undone and will be removed from all relevant users."
        />
      </Box>
    </Box>
  );
};

export default Teams;
