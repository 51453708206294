// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import moment from 'moment-business-days';
import { Dialog, DialogActions, Button, Typography } from '@mui/material/';
import MomentUtils from '@date-io/moment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Box, useTheme } from '@mui/system';
import { useAppDispatch } from 'Hooks';
import { EditOrderThunk } from 'Features/order/orderThunk';
import stylesDef from './styles';

interface KickoffDatePickerProps {
  orderId: string;
  showDialog: boolean;
  setShowDialog: (show: boolean) => void;
}

export const KickoffDatePicker = ({ orderId, showDialog, setShowDialog }: KickoffDatePickerProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date: Moment) => {
    setSelectedDate(date);
  };

  const onCancel = () => {
    setShowDialog(false);
  };

  const onSave = async () => {
    const anticipatedKickoffDate = validateDate();
    if (anticipatedKickoffDate === 'Invalid date') return;
    await dispatch(EditOrderThunk({ orderId, anticipatedKickoffDate: new Date(anticipatedKickoffDate) }));
    setShowDialog(false);
  };

  const validateDate = () => {
    if (selectedDate) {
      const anticipatedKickoffDate = selectedDate.format('YYYY-MM-DD');
      const today = moment(new Date()).format('YYYY-MM-DD');
      if (moment(today).isAfter(moment(anticipatedKickoffDate))) return 'Invalid date';
      return anticipatedKickoffDate;
    }
    return undefined;
  };

  return (
    <Dialog open={showDialog} onClose={onCancel}>
      <Box sx={styles.container}>
        <Typography variant="h2">Save Estimate</Typography>
        <Typography variant="body1" sx={styles.text}>We recommend adding an anticipated kickoff date for this estimate.</Typography>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            format="MM/DD/YYYY"
            value={selectedDate}
            onChange={handleDateChange}
            sx={styles.datePicker}
            label="Anticipated Kickoff"
            disablePast
          />
        </LocalizationProvider>
        <DialogActions sx={styles.buttons}>
          <Button onClick={onCancel} sx={styles.dismissButton}>DISMISS</Button>
          <Button variant="contained" color="primary" sx={styles.saveButton} onClick={onSave}>SAVE</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default KickoffDatePicker;
