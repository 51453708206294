// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { IconButton } from '@mui/material';
import { Box } from '@mui/system';
import downloadIcon from 'Assets/download-icon.png';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { DownloadTifThunk } from 'Features/project/projectThunk';
import stylesDef from './styles';

const OrthomosaicButtons = () => {
  const styles = stylesDef();
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.project?.project);

  const tifFiles = (project?.tifFiles?.length !== 0) ? project?.tifFiles : null;

  const handleDownloadAll = async () => {
    const interval = 3000;
    tifFiles.forEach((file, index) => {
      setTimeout(async () => {
        const name = file.filename.split('/').pop();
        await dispatch(DownloadTifThunk(name));
      }, index * interval);
    });
  };

  // Disable 'Download all' button when there are more than six tifs
  return (
    <Box>
      {tifFiles && (tifFiles.length > 1 && tifFiles.length <= 6) ? (
        <IconButton
          title="Download all .tif files"
          aria-label="Download all .tif files"
          sx={styles.button}
          onClick={() => handleDownloadAll()}
        >
          <img src={downloadIcon} alt="Download all .tif files" />
        </IconButton>
      ) : <Box />}
    </Box>
  );
};

export default OrthomosaicButtons;
