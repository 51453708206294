// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const useStyles = (theme: any) => ({
  sidebarContent: {
    flex: 1,
    overflow: 'auto',
    // sidebarWidth - width of the panel
    width: theme.sidebarWidth - 70,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  filesSidebar: {
    marginTop: theme.spacing(2),
    overflowY: 'auto',
  },
  mainContent: {
    display: 'flex',
    height: '100%',
  },
  header: {
    marginLeft: '10px',
    fontWeight: 600,
  },
  hideSidebar: {
    marginRight: '20px',
    cursor: 'pointer',
  },
  sidebarTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  bottom: {
    height: '36.5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px black',
  },
  bottomButton: {
    width: '100%',
    color: theme.palette.primary.main,
  },
});

export default useStyles;
