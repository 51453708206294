// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createSlice } from '@reduxjs/toolkit';
import {
  ChangeJsonAction,
  SetFileStatusJsonAction,
  CreateProjectFailureAction,
  CreateProjectStartAction,
  CreateProjectSuccessAction,
  DeleteProjectLasFileAction,
  DeleteProjectTifFileAction,
  GetProjectFailureAction,
  GetProjectStartAction,
  GetProjectSuccessAction,
  SetProjectIdAction,
  SetShowKmlToolbarAction,
  UpdateProjectLasFilesAction,
  UpdateProjectTifFilesAction,
  GetEpsgProjectionsAction,
  ResetEpsgProjectionsAction,
  UpdateImageryRequestSuccessAction,
  UpdateRasterRequestSuccessAction,
  SetRasterizationSelectedAction,
  SetWarningAcceptedAction,
  SendImageryUploadEmailStartAction,
  SendImageryUploadEmailSuccessAction,
  SendImageryUploadEmailFailureAction,
  SendRasterUploadEmailStartAction,
  SendRasterUploadEmailSuccessAction,
  SendRasterUploadEmailFailureAction,
} from './projectActions';

const initialState: IProjectState = {
  projectId: null,
  emailLoading: false,
  project: null,
  loading: false,
  changeJson: false,
  showKmlToolBar: false,
  fileStatusJson: null,
  rasterizationSelected: false,
  epsgCode: null,
  epsgProjections: null,
  epsgAuthName: null,
  firstProjection: null,
  secondProjection: null,
  warningAccepted: [],
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SetProjectIdAction, (state, action) => {
        state.projectId = action.payload;
      })
      .addCase(SetShowKmlToolbarAction, (state, action) => {
        state.showKmlToolBar = action.payload;
      })
      .addCase(GetEpsgProjectionsAction, (state, action) => {
        state.epsgCode = action.payload.epsgCode;
        state.epsgProjections = action.payload.projectionData;
        state.epsgAuthName = action.payload.epsgAuthName;
        state.firstProjection = action.payload.proj4Data.first;
        state.secondProjection = action.payload.proj4Data.second;
      })
      .addCase(ResetEpsgProjectionsAction, (state) => {
        state.epsgCode = null;
        state.epsgProjections = null;
        state.epsgAuthName = null;
        state.firstProjection = null;
        state.secondProjection = null;
      })
      .addCase(CreateProjectStartAction, (state) => {
        state.loading = true;
      })
      .addCase(CreateProjectSuccessAction, (state, action) => {
        state.loading = false;
        state.projectId = action.payload._id;
      })
      .addCase(CreateProjectFailureAction, (state) => {
        state.loading = false;
      })
      .addCase(GetProjectStartAction, (state) => {
        state.loading = true;
      })
      .addCase(GetProjectSuccessAction, (state, action) => {
        state.loading = false;
        state.project = action.payload;
      })
      .addCase(GetProjectFailureAction, (state) => {
        state.loading = false;
      })
      .addCase(SetFileStatusJsonAction, (state, action) => {
        state.fileStatusJson = action.payload;
      })
      .addCase(ChangeJsonAction, (state) => {
        state.changeJson = !state.changeJson;
      })
      .addCase(SetRasterizationSelectedAction, (state, action) => {
        state.rasterizationSelected = action.payload;
      })
      .addCase(UpdateProjectTifFilesAction, (state, action) => {
        const { project } = state;
        if (project._id === action.payload.ownerProject) {
          const index = project.tifFiles.findIndex((t) => t._id === action.payload._id);
          if (index < 0) {
            project.tifFiles.push(action.payload);
          } else {
            project.tifFiles[index] = action.payload;
          }
        }
      })
      .addCase(UpdateProjectLasFilesAction, (state, action) => {
        const { project } = state;
        if (project._id === action.payload.ownerProject) {
          const index = project.lasFiles.findIndex((t) => t._id === action.payload._id);
          if (index < 0) {
            project.lasFiles.push(action.payload);
          } else {
            project.lasFiles[index] = action.payload;
          }
        }
      })
      .addCase(DeleteProjectTifFileAction, (state, action) => {
        const { project } = state;
        if (project._id === action.payload.projectId) {
          project.tifFiles = project.tifFiles.filter((t) => t._id !== action.payload.tifFileId);
        }
      })
      .addCase(DeleteProjectLasFileAction, (state, action) => {
        const { project } = state;
        if (project._id === action.payload.projectId) {
          project.lasFiles = project.lasFiles.filter((t) => t._id !== action.payload.lasFileId);
        }
      })
      .addCase(SetWarningAcceptedAction, (state) => {
        if (!state.warningAccepted.includes(state.projectId)) {
          state.warningAccepted.push(state.projectId);
        }
      })
      .addCase(UpdateImageryRequestSuccessAction, (state, action) => {
        const { project } = state;
        if (project._id === action.payload.ownerProject) {
          project.imageryRequest = action.payload;
        }
      })
      .addCase(UpdateRasterRequestSuccessAction, (state, action) => {
        if (state.project._id === action.payload.ownerProject) {
          state.project.rasterRequest = action.payload;
        }
      })
      .addCase(SendImageryUploadEmailStartAction, (state) => { state.emailLoading = true; })
      .addCase(SendImageryUploadEmailSuccessAction, (state, action) => {
        const { project } = state;
        if (project) {
          Object.assign(project.imageryRequest, action.payload);
        }
        if (project._id === action.payload.ownerProject) {
          Object.assign(project.imageryRequest, action.payload);
        }
        state.emailLoading = false;
      })
      .addCase(SendImageryUploadEmailFailureAction, (state) => { state.emailLoading = false; })
      .addCase(SendRasterUploadEmailStartAction, (state) => { state.emailLoading = true; })
      .addCase(SendRasterUploadEmailSuccessAction, (state, action) => {
        const { project } = state;
        if (project) {
          Object.assign(project.rasterRequest, action.payload);
        }
        if (project._id === action.payload.ownerProject) {
          Object.assign(project.rasterRequest, action.payload);
        }
        state.emailLoading = false;
      })
      .addCase(SendRasterUploadEmailFailureAction, (state) => { state.emailLoading = false; });
  },
});

export const { reducer } = projectSlice;

export default reducer;
