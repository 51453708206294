// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'Hooks';
import moment from 'moment';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { Logout } from 'Features/auth/authThunk';
import { SetShowTokenExpiredDialogAction } from 'Features/auth/authActions';
import useInterval from '../hooks/useInterval';

const ExpiredSessionModal = () => {
  const dispatch = useAppDispatch();
  const { tokenExpires, showTokenExpiredDialog } = useAppSelector(
    (state) => state.auth,
  );

  const checkToken = () => {
    const currentTime = moment.utc().unix();
    if (currentTime >= tokenExpires) {
      dispatch(SetShowTokenExpiredDialogAction(true));
    }
  };

  useEffect(() => {
    if (tokenExpires) checkToken();
  }, []);

  useInterval(() => {
    checkToken();
  }, 1000);

  const onLogountClick = () => {
    dispatch(Logout());
    dispatch(SetShowTokenExpiredDialogAction(false));
  };

  return (
    <Dialog
      open={showTokenExpiredDialog}
      disableEscapeKeyDown
    >
      <DialogTitle>Your session has expired</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please sign out, then sign in again.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onLogountClick} color="primary">
          Sign Out
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExpiredSessionModal;
