// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { InView } from 'react-intersection-observer';
import {
  Typography,
  Avatar,
  CircularProgress,
  Button,
  Snackbar,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { GetAdminGeneralRoute } from 'Utils/routes';
import SearchField from 'Components/SearchField';
import { Box, useTheme } from '@mui/system';
import stylesDef from './styles';
import { DeleteOrgThunk, GetListThunk, SetListThunk, SetLoadAll, SetLoadNew, SetPageThunk, SetSearchFilterThunk } from '../adminThunk';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { useNavigate } from 'react-router-dom';

const Admin = () => {
    const theme = useTheme();
    const styles = stylesDef(theme);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { loading, loadAll, list, page, searchFilter } = useAppSelector((state) => state.admin);
    const { token } = useAppSelector((state) => state.auth);

    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [failedResultMessage, setFailedResultMessage] = useState(null);

    const renderColumnHeader = (columnMeta: any) => (
      <td key={columnMeta.name}>
        <Typography variant="caption">{columnMeta.label}</Typography>
      </td>
    );

    const getAvatarText = (value: string) => {
      const split = value && value.split(' ');
      const firstName = split && split[0];
      const lastName = split && split[1];
      const char1 = (firstName && firstName[0]) || '';
      const char2 = (lastName && lastName[0]) || '';

      return `${char1}${char2}`;
    };

    const handleDeleteOrg = async (e: any, orgId: string) => {
      e.stopPropagation();
      const result = await dispatch(DeleteOrgThunk(orgId));
      if (result.success) {
        setShowSuccess(true);
      } else if (!result.success && result.message) {
        setFailedResultMessage(result.message);
        setShowError(true);
      }
    };

    const handleSuccessSnackbarClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setShowSuccess(false);
    };

    const handleErrorSnackbarClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setShowError(false);
    };

    const pluralize = (number: number, noun: string, suffix = 's') =>
      `${number} ${noun}${number !== 1 ? suffix : ''}`;

    const columns = [
      {
        name: 'companyName',
        label: 'Company Name',
        options: {
          // customHeadRender: renderColumnHeader,
          customBodyRender: (value: string) => (
            <>
            <Box sx={styles.nameColumn}>
              <Avatar sx={styles.avatar}>{getAvatarText(value)}</Avatar>
              {value}
            </Box>
            </>
          ),
        },
      },
      {
        name: 'location',
        label: 'Location',
        options: { customHeadRender: renderColumnHeader },
      },
      {
        name: 'subscriptions',
        label: 'Subscription',
        options: { customHeadRender: renderColumnHeader },
      },
      {
        name: 'phoneNumber',
        label: 'Phone number',
        options: { customHeadRender: renderColumnHeader },
      },
      {
        name: 'projectsCount',
        label: ' ',
        options: {
          customHeadRender: renderColumnHeader,
          customBodyRender: (value: number) => pluralize(value || 0, 'project'),
        },
      },
      {
        name: 'View',
        empty: true,
        options: {
          customHeadRender: () => null,
          customBodyRender: () => <Button>View</Button>,
        },
      },
      {
        name: '_id',
        empty: true,
        options: {
          customHeadRender: () => null,
          customBodyRender: (orgId: string) => (
            <Button onClick={(e) => {
              handleDeleteOrg(e, orgId);
            }}
            >
              Delete
            </Button>
          ),
        },
      },
    ];

    const options = {
      elevation: 0,
      responsive: 'stacked',
      serverSide: true,
      selectableRows: 'none',
      print: false,
      download: false,
      viewColumns: false,
      sort: false,
      search: false,
      filter: false,
      pagination: false,
      onTableInit: () => {
        token && dispatch(GetListThunk());
      },
      setRowProps: () => ({
        styles: {
          hover: styles.rowHover,
        },
      }),
      onRowClick: (
        rowData: string[],
        rowMeta: { dataIndex: number; rowIndex: number },
      ) => {
        const org = list[rowMeta.dataIndex];
        navigate(GetAdminGeneralRoute(org._id));
      },
    };

    return (
      <Box sx={styles.pageWrapper}>
        <Box sx={styles.toolbar}>
          <SearchField
            value={searchFilter}
            debounceTimeout={500}
            disabled={loading}
            onChange={(v) => {
              dispatch(SetLoadNew(true));
              dispatch(SetListThunk([]));
              dispatch(SetSearchFilterThunk(v));
              dispatch(SetLoadAll(false));
              dispatch(SetPageThunk(0));
              dispatch(GetListThunk());
            }}
          />
          {/* sort is not handled, will fix it when requirements come in */}
          {/* <SortSelect
            onChange={(ev) => {
              SetSortFilterThunk(ev.target.value);
              GetListThunk();
            }}
          /> */}
        </Box>
        <Snackbar sx={styles.snackBar} open={showSuccess} autoHideDuration={10000} onClose={handleSuccessSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert severity="success" sx={styles.alert} color="info">Organization Deleted</Alert>
        </Snackbar>
        <Snackbar sx={styles.snackBar} open={showError} autoHideDuration={10000} onClose={handleErrorSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert severity="error" sx={styles.error} color="error">{!failedResultMessage ? 'Unable to delete Organization' : failedResultMessage}</Alert>
        </Snackbar>
        <MUIDataTable
          data={list}
          columns={columns}
          options={options}
          sx={styles.table}
        />
        <Box sx={styles.loading}>
          {loading && (<CircularProgress size={50} />)}
          {!loading && (loadAll ? (
            <Typography variant="h3">End of List</Typography>
          ) : (
            <>
              <InView onChange={(inView) => {
                if (inView) {
                  dispatch(SetPageThunk(page + 1));
                  dispatch(GetListThunk());
                }
              }}
              />
              <Typography variant="h3">Loading more...</Typography>
            </>
          ))}
        </Box>
      </Box>
    );
  };

export default Admin;
