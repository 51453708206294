// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Box, useTheme } from '@mui/system';
import { useAppSelector } from 'Hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ListItem, FormControlLabel, Typography, Checkbox } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { deliverableCost } from 'Utils/deliverables';
import { useSelector } from 'react-redux';
import { getOrders } from 'Features/order/orderSelectors';
import { RootState } from 'Store';
import stylesDef from './styles';

interface DeliverableSelectProps {
  onAddOnsClick: (item: IDeliverable, checked: boolean) => void;
}

const DeliverableSelect = ({ onAddOnsClick }: DeliverableSelectProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const { breaklinesAddon } = useFlags();

  const { additionalDeliverables, selectedBundle, selectedAdditionals } = useAppSelector(
    (state) => state.bundle,
  );
  const { name: bundleName, bundleId } = selectedBundle || {};

  const { project, rasterizationSelected } = useAppSelector(
    (state) => state.project,
  );

  const { activeOrder } = useSelector((state: RootState) => getOrders(state));

  const deliverablesBoolean = additionalDeliverables?.some((d) => d.subtype === 'Deliverables');
  const topographyDeliverableBoolean = additionalDeliverables?.some((d) => d.subtype === 'Topography' && d.type === 'deliverable');
  const tifUploaded = project.tifFiles?.length;

  let additionalDeliverablesFiltered = additionalDeliverables;

  if (breaklinesAddon && bundleId === 5) {
    additionalDeliverablesFiltered = additionalDeliverables.filter((del) => (del.deliverableId !== 10 && del.deliverableId !== 52));
  } else if (breaklinesAddon && (bundleId === 1 || bundleId === 2 || bundleId === 4)) {
    additionalDeliverablesFiltered = additionalDeliverables.filter((del) => (del.deliverableId !== 51));
  } else if (bundleId === 5) {
    additionalDeliverablesFiltered = additionalDeliverables.filter((del) => (del.deliverableId !== 10 && del.deliverableId !== 51 && del.deliverableId !== 52));
  } else {
    additionalDeliverablesFiltered = additionalDeliverables.filter((del) => (del.deliverableId !== 51 && del.deliverableId !== 52));
  }

  return (
    <Box sx={styles.addons}>
      <Typography sx={styles.addonsHeading} variant="h3">
        Additional Deliverables
      </Typography>
      <Typography>Please select any additional deliverables you’d like to receive for your project.</Typography>
      {bundleId === 4 && (
        <Typography sx={styles.noDeliverableMessage}>
          Please select the 2D + TOPO bundle to add the disabled deliverables at checkout
        </Typography>
      )}
      {deliverablesBoolean && (
        <div>
          <Typography sx={styles.addonsHeading} variant="h4">
            Deliverables
          </Typography>
          {additionalDeliverablesFiltered.map((item) =>
            ((item.type === 'deliverable' && item.subtype === 'Deliverables') ? (
              <Box sx={((bundleName === '2D' && item.requirements.includes('1'))
              || (bundleId === 4 &&
                  (item.deliverableId === 12 ||
                    item.deliverableId === 13 ||
                    item.deliverableId === 15)) || (bundleId === 5 && item.deliverableId === 11)) && styles.unavailableDeliverables}
              >
                {bundleName === '2D' && item.requirements.includes('1') && (
                  <Typography sx={styles.noDeliverableMessage}>
                    Please select the 2D + TOPO bundle to add
                    {' '}
                    {item.name}
                    {' '}
                    at checkout
                  </Typography>
                )}
                <ListItem disableRipple sx={styles.listItem}>
                  <FormControlLabel
                    label={(
                      <div>
                        <Typography variant="body1" display="inline" sx={styles.itemName}>{item.name}</Typography>
                      </div>
                    )}
                    control={(
                      <Checkbox
                        classes={{
                          checked: styles.checked,
                        }}
                        disabled={!item.selectable}
                        checked={selectedAdditionals.findIndex((i) => i._id === item._id) !== -1}
                        onChange={(e) => onAddOnsClick(item, e.target.checked)}
                      />
                    )}
                  />
                  <Typography sx={styles.right} component="span">
                    &nbsp;
                    {' '}
                    {`${deliverableCost(item.acreFactor, activeOrder.acreage).toFixed(2)} credits`}
                  </Typography>
                </ListItem>
              </Box>
            ) : ''))}
        </div>
      )}
      {topographyDeliverableBoolean && (
        <Box sx={(bundleId === 0) && styles.unavailableDeliverables}>
          <Typography sx={styles.addonsHeading} variant="h4">
            Topography
          </Typography>
          {(bundleId === 0) && (
            <Typography sx={styles.noDeliverableMessage}>
              Please select the 2D + TOPO bundle to add Topography features at checkout
            </Typography>
          )}
          {additionalDeliverablesFiltered.map((item) =>
            ((item.type === 'deliverable' && item.subtype === 'Topography') ? (
              <Box sx={(!item.selectable || !(bundleId !== 5 || (bundleId === 5 && item.deliverableId === 51)) || (bundleId === 2 && item.deliverableId === 52 && (!tifUploaded && !rasterizationSelected))) && styles.unavailableDeliverables}>
                <ListItem sx={styles.listItem}>
                  <FormControlLabel
                    sx={styles.formControl}
                    label={(
                      <div>
                        <Typography variant="body1" display="inline" sx={styles.itemName}>{item.name}</Typography>
                        {(bundleId === 2 && item.deliverableId === 52) && <Typography variant="body1" display="inline" sx={styles.itemName}> (Rasterization required)</Typography>}
                      </div>
                    )}
                    control={(
                      <Checkbox
                        classes={{
                          checked: styles.checked,
                        }}
                        checked={selectedAdditionals.findIndex((i) => i._id === item._id) !== -1}
                        onChange={(e) => onAddOnsClick(item, e.target.checked)}
                      />
                    )}
                  />
                  {item.deliverableId === 53 && <OpenInNewIcon sx={styles.icon} onClick={() => window.open('http://support.airworks.io/Breaklines-Powered-by-DataSight.2636414981.html', '_blank')} />}
                  <Typography sx={styles.right} component="span">
                      &nbsp;
                    {' '}
                    {`${deliverableCost(item.acreFactor, activeOrder.acreage).toFixed(2)} credits`}
                  </Typography>
                </ListItem>
              </Box>
            ) : null))}
        </Box>
      )}
    </Box>
  );
};

export default DeliverableSelect;
