// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useRef, useState } from 'react';
import Sortable from 'sortablejs';
import { Typography, Table, TableRow, TableBody, TableCell } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { useParams } from 'react-router-dom';
import { InView } from 'react-intersection-observer';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { SetTemplateId } from '../cadTemplateThunk';
import LayerItem from './LayerItem';
import stylesDef from './styles';

interface ComponentProps {
  templateId: string;
  layersOrderChange: any[];
  setLayersStyle: (fun: any) => void;
  setLayersWeight: (fun: any) => void;
  setLayersColor: (fun: any) => void;
  setLayersMapping: (fun: any) => void;
  setLayersOrderChange: (fun: any) => void;
}

export const Layers: React.FC<ComponentProps> = ({ templateId, layersOrderChange, setLayersStyle, setLayersWeight, setLayersColor, setLayersMapping, setLayersOrderChange }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const cadTemplateTableRef = useRef(null);

  const [loadAll, setLoadAll] = useState(false);
  const [page, setPage] = useState(0);
  const [layers, setLayers] = useState([]);
  const [layerChange, setLayerChange] = useState(null);

  const routeTemplateId = useParams().templateId;
  const template = useAppSelector((state) => state.cadTemplate.list?.find((item) => item._id === routeTemplateId));
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(SetTemplateId(templateId));
    setLoadAll(false);
    setPage(0);
  }, [templateId]);

  useEffect(() => {
    const el = cadTemplateTableRef.current;
    if (el) {
      const sortable = new Sortable(el, {
        handle: '.cadTemplateTable-handle',
        disabled: _ADMIN_,
        ghostClass: 'ghost',
        onUpdate: (evt) => {
          const { oldIndex, newIndex } = evt;
          setLayerChange({ oldIndex, newIndex });
        },
      });
    }
  }, []);

  useEffect(() => {
    if (layerChange) {
      const newChange = [...layersOrderChange];
      newChange.push(layerChange);
      setLayersOrderChange(newChange);
    }
  }, [layerChange]);

  useEffect(() => {
    if (template.layers.length / 20 <= page && !loadAll) {
      setLoadAll(true);
    } else {
      setLayers(template.layers.slice(0, 20 * (page + 1)));
    }
  }, [page, template.layers]);

  return (
    <Box sx={styles.pageWrapper}>
      <Table sx={styles.table} aria-label="simple table">
        <TableBody>
          <TableRow sx={styles.headerRow}>
            <TableCell sx={{ ...styles.tableCellEmpty, ...styles.tableCellRoot }} />
            <TableCell
              sx={{ ...styles.tableCellHeader, ...styles.tableCellRoot }}
            >
              <Typography variant="h4">Layer Name</Typography>
            </TableCell>
            <TableCell
              sx={{ ...styles.tableCellHeadeStyle, ...styles.tableCellRoot }}
              align="left"
            >
              <Typography variant="h4">Line Style</Typography>
            </TableCell>
            <TableCell
              sx={{ ...styles.tableCellHeader, ...styles.tableCellRoot }}
              align="left"
            >
              <Typography variant="h4">Line Weight</Typography>
            </TableCell>
            <TableCell
              sx={{ ...styles.tableCellHeader, ...styles.colorCellHeader, ...styles.tableCellRoot }}
              align="left"
            >
              <Typography variant="h4">Line Color</Typography>
            </TableCell>
            <TableCell
              sx={{ ...styles.tableCellHeader, ...styles.tableCellRoot }}
              align="left"
            >
              <Typography variant="h4">Airworks Layers</Typography>
            </TableCell>
          </TableRow>
          <div id="cadTemplateTable" ref={cadTemplateTableRef}>
            {layers.map((layer) => (
              <LayerItem
                key={layer.name}
                layer={layer}
                templateId={templateId}
                setLayersStyle={setLayersStyle}
                setLayersWeight={setLayersWeight}
                setLayersColor={setLayersColor}
                setLayersMapping={setLayersMapping}
              />
            ))}
          </div>
        </TableBody>
      </Table>
      <Box sx={styles.loading}>
        {loadAll ? (
          <Typography variant="h3">End of List</Typography>
        ) : (
          <>
            <InView onChange={(inView) => {
              if (inView) {
                setPage(page + 1);
              }
            }}
            />
            <Typography variant="h3">Loading more...</Typography>
          </>
        )}
      </Box>
    </Box>
  );
};
export default Layers;
