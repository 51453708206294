// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import SVG from 'react-inlinesvg';
import angle from 'Assets/angle-icon.svg';
import pointer from 'Assets/toolbar-pointer.svg';
import polyline from 'Assets/toolbar-polyLine.svg';
import arc from 'Assets/toolbar-arc.svg';
import area from 'Assets/area-icon.svg';
import distance from 'Assets/distance-icon.svg';
import angleActive from 'Assets/angle-icon-active.svg';
import pointerActive from 'Assets/toolbar-pointer-active.svg';
import areaActive from 'Assets/area-icon-active.svg';
import distanceActive from 'Assets/distance-icon-active.svg';
import polylineActive from 'Assets/toolbar-polyLine-active.svg';
import arcActive from 'Assets/toolbar-arc-active.svg';
import circle from 'Assets/circle-icon.svg';
import circleActive from 'Assets/circle-icon-active.svg';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { StartDraw } from 'Features/map/mapDrawThunk';
import { SetCurrentMeasurementOptionAction, SetMeasurementModeAction } from 'Features/map/mapDrawActions';
import { SetCurrentTool } from 'Features/map/mapEditorThunk';
import { SetEditorModeAction, SetDrawModeAction } from 'Features/map/mapEditorActions';
import stylesDef from './styles';

export const MeasurementOptions = () => {
  const styles = stylesDef();
  const dispatch = useAppDispatch();
  const currentMeasurementOption = useAppSelector((state) => state.map.draw.present.currentMeasurementOption);
  const resources = useAppSelector((state) => state.auth.resources);
  const editorFunctions = 'editorFunctions' in resources;
  const currentTool = useAppSelector((state) => state.map.editor.present.currentTool);

  useEffect(() => {
    dispatch(StartDraw('simple_select'));

    return () => {
      dispatch(SetCurrentMeasurementOptionAction(null));
      dispatch(StartDraw('direct_select'));
    };
  }, []);

  return (
    <Box sx={styles.container}>
      {editorFunctions && (
        <Box sx={styles.type}>
          <Box sx={styles.measurements}>
            <Typography sx={styles.typeName} variant="h4">Draw </Typography>
            <Box sx={styles.betaBanner}>
              BETA
            </Box>
          </Box>
          <Box sx={styles.measurements}>
            <Button
              sx={styles.option}
              onClick={() => {
                dispatch(SetEditorModeAction(true));
                dispatch(SetDrawModeAction({ name: 'simple_select' }));
                dispatch(SetCurrentTool('pointer'));
                dispatch(SetCurrentMeasurementOptionAction(null));
              }}
            >
              {currentTool === 'pointer' ? (
                <SVG
                  src={pointerActive}
                  title="pointer"
                  css={styles.icon}
                />
              ) : (
                <SVG src={pointer} title="pointer" css={styles.icon} />
              )}
            </Button>
            <Button
              sx={styles.option}
              onClick={() => {
                dispatch(SetEditorModeAction(true));
                dispatch(SetDrawModeAction({ name: 'draw_poly_line' }));
                dispatch(SetCurrentTool('polyline'));
                dispatch(SetCurrentMeasurementOptionAction(null));
              }}
            >
              {currentTool === 'polyline' ? (
                <SVG
                  src={polylineActive}
                  title="polyline"
                  css={styles.icon}
                />
              ) : (
                <SVG src={polyline} title="polyline" css={styles.icon} />
              )}
            </Button>
            <Button
              sx={styles.option}
              onClick={() => {
                dispatch(SetEditorModeAction(true));
                dispatch(SetDrawModeAction({ name: 'draw_circle' }));
                dispatch(SetCurrentTool('circle'));
                dispatch(SetCurrentMeasurementOptionAction(null));
              }}
            >
              {currentTool === 'circle' ? (
                <SVG src={circleActive} title="circle" css={styles.icon} />
              ) : (
                <SVG src={circle} title="circle" css={styles.icon} />
              )}
            </Button>
            <Button
              sx={styles.option}
              onClick={() => {
                dispatch(SetEditorModeAction(true));
                dispatch(SetDrawModeAction({ name: 'draw_poly_line' }));
                dispatch(SetCurrentTool('arc'));
                dispatch(SetCurrentMeasurementOptionAction(null));
              }}
            >
              {currentTool === 'arc' ? (
                <SVG src={arcActive} title="arc" css={styles.icon} />
              ) : (
                <SVG src={arc} title="arc" css={styles.icon} />
              )}
            </Button>
          </Box>
        </Box>
      )}
      <Box sx={styles.type}>
        <Box sx={styles.measurements}>
          <Typography sx={styles.typeName} variant="h4">Measure </Typography>
        </Box>
        <Box sx={styles.measurements}>
          <Button
            sx={styles.option}
            onClick={() => {
              dispatch(SetEditorModeAction(false));
              // TODO - do we need to dispatch SetCurrentTool(null) here? this seems to be creating a new layer record
              // dispatch(SetCurrentTool(null));
              dispatch(SetMeasurementModeAction());
              dispatch(StartDraw('draw_angle'));
              dispatch(SetCurrentMeasurementOptionAction('angle'));
            }}
          >
            {currentMeasurementOption === 'angle' ? (
              <SVG src={angleActive} title="angle" css={styles.icon} />
            ) : (
              <SVG src={angle} title="angle" css={styles.icon} />
            )}
          </Button>
          <Button
            sx={styles.option}
            onClick={() => {
              dispatch(SetEditorModeAction(false));
              // dispatch(SetCurrentTool(null));
              dispatch(SetMeasurementModeAction());
              dispatch(StartDraw('draw_polygon'));
              dispatch(SetCurrentMeasurementOptionAction('polygon'));
            }}
          >
            {currentMeasurementOption === 'polygon' ? (
              <SVG src={areaActive} title="area" css={styles.icon} />
            ) : (
              <SVG src={area} title="area" css={styles.icon} />
            )}
          </Button>
          <Button
            sx={styles.option}
            onClick={() => {
              dispatch(SetEditorModeAction(false));
              // dispatch(SetCurrentTool(null));
              dispatch(SetMeasurementModeAction());
              dispatch(StartDraw('draw_line_string'));
              dispatch(SetCurrentMeasurementOptionAction('line_string'));
            }}
          >
            {currentMeasurementOption === 'line_string' ? (
              <SVG
                src={distanceActive}
                title="distance"
                css={styles.icon}
              />
            ) : (
              <SVG src={distance} title="distance" css={styles.icon} />
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default MeasurementOptions;
