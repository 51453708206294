// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import {
  Button,
  Fade,
  CircularProgress,
  SnackbarContent,
  Collapse,
  Typography,
} from '@mui/material';
import { Box, useTheme } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { TextField, Validators, Helpers } from 'Components/forms';
import { Form } from 'react-final-form';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { PasswordUpdateThunk } from '../accountThunk';
import stylesDef from './styles';

export const AccountPassword = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);

  const dispatch = useAppDispatch();
  const { updatePasswordMessage } = useAppSelector(
    (state) => state.account,
  );

  const passwordUpdate = (payload: { oldPassword: string, password: string }) => dispatch(PasswordUpdateThunk(payload));
  const onFormSubmit = (values: any) => {
    Helpers.submit(values, passwordUpdate);
  };

  return (
    <Box sx={styles.contentWrapper}>
      <Form
        onSubmit={onFormSubmit}
        render={({ hasSubmitErrors, modifiedSinceLastSubmit, submitting, pristine, submitError, submitSucceeded, handleSubmit }) => (
          <form
            style={styles.form}
            onSubmit={handleSubmit}
          >
            <Typography variant="h4">RESET PASSWORD</Typography>
            <TextField
              name="oldPassword"
              label="Current Password"
              type="password"
              validate={Validators.composeValidators(Validators.required, Validators.minLength7)}
            />
            <TextField
              name="password"
              label="New Password"
              type="password"
              validate={Validators.composeValidators(Validators.required, Validators.strongPassword)}
            />
            <TextField
              name="passwordConfirm"
              label="Confirm Password"
              type="password"
              validate={Validators.composeValidators(Validators.required, Validators.strongPassword, Validators.passwordsMatch)}
            />
            <Collapse in={(hasSubmitErrors && !modifiedSinceLastSubmit)}>
              <SnackbarContent
                sx={styles.errorSnackbar}
                message={(
                  <Box sx={styles.snackbarContent}>
                    <ErrorIcon />
                    {submitError}
                  </Box>
                )}
              />
            </Collapse>
            <Collapse in={!submitting && submitSucceeded}>
              <SnackbarContent
                sx={styles.successSnackbar}
                message={(
                  <Box sx={styles.snackbarContent}>
                    <CheckCircleIcon />
                    {updatePasswordMessage}
                  </Box>
                )}
              />
            </Collapse>
            <Button
              variant="outlined"
              color="primary"
              sx={styles.submitButton}
              type="submit"
              disabled={pristine || (hasSubmitErrors && !modifiedSinceLastSubmit) || submitting}
            >
              <Fade in={submitting}>
                <CircularProgress size={24} sx={styles.buttonProgress} />
              </Fade>
              <Fade in={!submitting}>
                <span>Update Password</span>
              </Fade>
            </Button>
          </form>
        )}
      />
      <Box sx={styles.requirements}>
        <Typography sx={styles.rheader} variant="h5">
          Your new password must contain:
        </Typography>
        <Typography sx={styles.rdetails}>A minimum of 8 characters</Typography>
        <Typography sx={styles.rdetails}>A number</Typography>
        <Typography sx={styles.rdetails}>A lowercase letter and an uppercase letter</Typography>
        <Typography sx={styles.rdetails}>
          A special character: ~`!@#$%^&*()_-+=
          {'{'}
          [
          {'}'}
          ]|\:;
          &quot;&apos;
          {'<'}
          ,
          {'>'}
          .?/
        </Typography>
      </Box>
    </Box>
  );
};

export default AccountPassword;
