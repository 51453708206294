// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { ListItemButton, ListItemText, Paper } from '@mui/material';
import { useTheme } from '@mui/system';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { GoToSearchResultThunk, MapSearchThunk, MapClearSuggestionsThunk } from 'Features/map/mapCommonThunk';
import { ClearMarkerAction } from 'Features/map/mapCommonActions';
import Input from './Input';
import stylesDef from './styles';

const AutosuggestWrapper = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();

  const suggestions = useAppSelector((state) => state.map.common.geocode.mapSuggestions);

  const [searchInputValue, setSearchInputValue] = useState('');
  const _setSearchInputValue = (newValue: string) => {
    setSearchInputValue(newValue);
    if (!newValue) dispatch(ClearMarkerAction());
  };

  const renderSuggestion = (suggestion: IPlacesSearchFeature, { isHighlighted }: Autosuggest.RenderSuggestionParams) => (
    <ListItemButton selected={isHighlighted}>
      <ListItemText primary={suggestion.text} secondary={suggestion.description} />
    </ListItemButton>
  );

  return (
    <Paper sx={styles.autosuggestRoot} elevation={1}>
      <Autosuggest
        suggestions={suggestions}
        renderInputComponent={(e) => <Input inputProps={e} setSearchInputValue={_setSearchInputValue} />}
        renderSuggestion={renderSuggestion}
        onSuggestionsFetchRequested={(ev) => dispatch(MapSearchThunk(ev.value))}
        onSuggestionsClearRequested={() => dispatch(MapClearSuggestionsThunk())}
        getSuggestionValue={(suggestion) => suggestion.text}
        onSuggestionSelected={(ev, { suggestion }) => dispatch(GoToSearchResultThunk(suggestion))}
        renderSuggestionsContainer={(options) => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
        inputProps={{
          placeholder: 'Enter city, state, or zip code',
          value: searchInputValue,
          onChange: ((ev, { newValue }) => _setSearchInputValue(newValue)),
        }}
        theme={{
          suggestionsContainerOpen: styles.suggestionsContainerOpen,
          suggestionsList: styles.suggestionsList,
        }}
      />
    </Paper>
  );
};

export default AutosuggestWrapper;
