// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  deliveryDateText: {
    color: '#01A1DD',
    textTransform: 'none',
    fontSize: '16px',
    letter: '0.21px',
    lineHeight: '19px',
  },
  dateGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    gridColumnGap: theme.spacing(1),
  },
  checkbox: {
    '&.Mui-disabled': {
      cursor: 'not-allowed',
    },
  },
  checkboxLabel: {
    fontSize: '14px',
  },
  expeditedDelivery: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '13px',
    marginTop: theme.spacing(1),
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    color: '#2e4deb',
    cursor: 'pointer',
  },
  icon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    cursor: 'pointer',
  },
  cadText: {
    paddingBottom: '10px',
  },
});

export default styles;
