// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { green } from '@mui/material/colors';

const styles = (theme: any) => ({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    marginTop: theme.spacing(8),
  },
  form: {
    margin: '0 auto',
    width: 500,
    display: 'block',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  formRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr ',
    gridGap: theme.spacing(1),
  },
  submitButton: {
    boxShadow: 'none',
    width: '50%',
    borderRadius: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(4),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-12px',
    marginLeft: '-12px',
  },
  errorSnackbar: {
    backgroundColor: theme.palette.error.dark,
    boxShadow: 'none',
    marginTop: theme.spacing(4),
  },
  successSnackbar: {
    backgroundColor: green[600],
    boxShadow: 'none',
    marginTop: theme.spacing(4),
  },
  snackbarContent: {
    display: 'grid',
    gridGap: theme.spacing(),
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
  },
  cancelButton: {
    borderStyle: 'none',
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
  },
});

export default styles;
