// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React , { useState } from 'react';
import { TextField as MaterialTextField } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { TextField, Select, Validators } from 'Components/forms';
import states from 'Utils/states';
import stylesDef from './styles';

interface ComponentProps {
  editable?: boolean;
  initialValues?: ICreditCard;
}

export const CreateCardForm: React.FC<ComponentProps> = ({ editable, initialValues }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);

  const [addressState, setAddressState] = useState<string | undefined>(initialValues?.address_state);

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.row}>
        {!editable && (
          <>
            <TextField
              label="Credit Card Number"
              name="number"
              mask="9999 9999 9999 9999"
              placeholder="0000 0000 0000 0000"
              isCreditCard
              validate={Validators.composeValidators(Validators.required, Validators.creditCard)}
            />
            <TextField label="Exp Date" name="expDate" placeholder="mm/yy" mask="99/99" validate={Validators.composeValidators(Validators.required, Validators.expireDate)} />
            <TextField label="CVV" name="cvv" mask="999" placeholder="000" validate={Validators.maxLength7}/>
          </>
        )}
        {editable && (
          <>
            <MaterialTextField disabled value={initialValues.number} label="Credit Card Number" />
            <MaterialTextField disabled value={initialValues.expDate} label="Exp Date" />
            <MaterialTextField disabled value="***" label="CVV" />
          </>
        )}
      </Box>
      <TextField label="Name" name="name" validate={Validators.required} />
      <TextField label="Street Address" name="address_line1" validate={Validators.required} />
      <TextField label="Street Address 2" name="address_line2" />
      <Box sx={styles.row}>
        <TextField label="City" name="address_city" validate={Validators.required} />
        <Select label="State" name="address_state" values={states} validate={(value:string)=>{
          setAddressState(value);
          return Validators.required(value);
        }} />
        <TextField label="Zip Code" name="address_zip" validate={(value:string)=>{
          return Validators.zipCodeValidation(addressState, value);
        }} />
      </Box>
    </Box>
  );
};

export default CreateCardForm;
