// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import { Button, InputBase, IconButton } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { getOrders } from 'Features/order/orderSelectors';
import stylesDef from './styles';
import { UpdateProjectNameThunk } from 'Features/project/projectThunk';

// { initialProjectName, projectOrders, projectId, ordersLoaded, UpdateProjectNameAction }: PropsType
interface ComponentProps {
  initialProjectName: string;
  projectId: string;
}

export const ProjectName: React.FC<ComponentProps> = ({ initialProjectName, projectId }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();

  const [projectName, setProjectName] = useState(null);
  const [isProjectNameEditable, setIsProjectNameEditable] = useState(false);
  const [haveOrdersBeenProcessed, setHaveOrdersBeenProcessed] = useState(false);
  const { projectOrders } = useAppSelector((state) => getOrders(state));
  const ordersLoaded = projectOrders.length > 0;

  const startEditingProjectName = () => {
    if (!haveOrdersBeenProcessed) {
      setProjectName(initialProjectName);
      setIsProjectNameEditable(true);
    }
  };

  useEffect(() => {
    setHaveOrdersBeenProcessed(projectOrders.some((o) => o.orderStatus > 0));
  }, [projectOrders]);

  const updateProjectName = () => {
    dispatch(UpdateProjectNameThunk({ _id: projectId, name: projectName }));
    setIsProjectNameEditable(false);
  };

  const AllowEdit = () => {
    if (isProjectNameEditable) {
      return (
        <IconButton aria-label="check" onClick={updateProjectName}>
          <CheckIcon />
        </IconButton>
      );
    }
    return (
      <IconButton aria-label="edit" onClick={startEditingProjectName}>
        <EditIcon />
      </IconButton>
    );
  };

  return (
    <Box sx={styles.projectName}>
      {!isProjectNameEditable ? (
        <Button
          sx={styles.titleButton}
          variant="text"
          disableTouchRipple
          onDoubleClick={startEditingProjectName}
        >
          <span>{projectName || initialProjectName}</span>
        </Button>
      ) : (
        <InputBase
          sx={styles.inputField}
          autoFocus
          value={projectName}
          onChange={(e) => {
            setProjectName(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === 'Escape') {
              // check if project name has changed
              if (!projectName || projectName.trim() === initialProjectName) {
                setIsProjectNameEditable(false);
                setProjectName(null);
                return;
              }
              // else call the update project name API
              updateProjectName();
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        />
      )}
      {(ordersLoaded && !haveOrdersBeenProcessed) && AllowEdit()}
    </Box>
  );
};

export default ProjectName;
