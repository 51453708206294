// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import SimpleSelect from '@mapbox/mapbox-gl-draw/src/modes/simple_select';
import moveFeatures from '@mapbox/mapbox-gl-draw/src/lib/move_features';
import customCreateSupplementaryPoints from './customCreateSupplementaryPoints';
import moveCurve from './moveCurve';

const Constants = require('@mapbox/mapbox-gl-draw/src/constants');

const CustomSimpleSelect = { ...SimpleSelect };

// we override this function just to use customCreateSupplementaryPoints
CustomSimpleSelect.toDisplayFeatures = function toDisplayFeatures(
  state,
  geojson,
  display,
) {
  geojson.properties.active = this.isSelected(geojson.properties.id)
    ? Constants.activeStates.ACTIVE
    : Constants.activeStates.INACTIVE;
  display(geojson);
  this.fireActionable();
  if (
    geojson.properties.active !== Constants.activeStates.ACTIVE ||
    geojson.geometry.type === Constants.geojsonTypes.POINT
  ) {
    return;
  }
  if (geojson.properties.user_curve && !geojson.properties.curve) {
    geojson.properties.curve = geojson.properties.user_curve;
  }
  customCreateSupplementaryPoints(geojson).forEach(display);
};

CustomSimpleSelect.dragMove = function dragMove(state, e) {
  // Dragging when drag move is enabled
  state.dragMoving = true;
  e.originalEvent.stopPropagation();
  const delta = {
    lng: e.lngLat.lng - state.dragMoveLocation.lng,
    lat: e.lngLat.lat - state.dragMoveLocation.lat,
  };

  moveFeatures(this.getSelected(), delta);
  this.getSelected().forEach((feature) => {
    if (feature.properties.curve) {
      feature.properties.curve = moveCurve(feature.properties.curve, delta);
    }
  });
  state.dragMoveLocation = e.lngLat;
};

export default CustomSimpleSelect;
