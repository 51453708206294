// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import creditCardType from 'credit-card-type';
import cardValidator from 'card-validator';
import { TextField as MuiTextField, InputAdornment, TextFieldProps } from '@mui/material';
import { Field, FieldRenderProps } from 'react-final-form';
import { useMask } from '@react-input/mask';
import Icon from './Icon';

interface FieldProps {
  label: string;
  inputProps: any;
  inputLabelProps: any;
  helperTextProps: any;
  variant: string;
  isCreditCard: boolean;
  isCvv: boolean;
  cardNumber: string;
  mask: string;
  disabled: boolean;
}

type TextWrapperProps = FieldRenderProps<TextFieldProps & FieldProps>;

/* eslint-disable react/jsx-props-no-spreading */
const textFieldComponent = (props: TextWrapperProps) => {
  const {
    variant = 'standard', input, label, placeholder, disabled,
    inputProps = {}, inputLabelProps = {}, helperTextProps = {}, autoFocus = false,
    isCreditCard, isCvv, cardNumber, sx, mask, meta: { invalid, touched, error },
    ...rest
  } = props;
  const { type } = input;

  let hasError = false;

  if (error !== undefined) {
    hasError = true;
  }
  const shouldShowError = touched && invalid;
  hasError = shouldShowError && hasError;

  const _inputProps = { ...inputProps };
  if (isCreditCard) {
    const creditCardTypes = creditCardType(input.value);
    if (creditCardTypes.length === 1) {
      _inputProps.startAdornment = (
        <InputAdornment position="start">
          <Icon cardType={creditCardTypes[0].type} />
        </InputAdornment>
      );
    }
  }

  let _label = label;
  let _mask = mask;
  let _placeholder = placeholder;
  if (isCvv) {
    const validationResult = cardValidator.number(cardNumber);
    if (validationResult.isPotentiallyValid && validationResult.card) {
      const { card } = validationResult;
      _label = card.code.name;
      _mask = new Array(card.code.size + 1).join('9');
      _placeholder = new Array(card.code.size + 1).join('0');
    }
  }

  // Get ref using useMask hook
  const inputRef = useMask({
    mask: _mask,
    replacement: { _: /\d/ },
  });

  return (
    <MuiTextField
      {...input}
      ref={inputRef}
      label={_label}
      type={type}
      placeholder={_placeholder}
      error={hasError}
      margin="normal"
      variant={variant}
      fullWidth
      disabled={disabled}
      autoFocus={autoFocus}
      helperText={hasError ? error : ''}
      InputProps={_inputProps}
      InputLabelProps={inputLabelProps}
      FormHelperTextProps={helperTextProps}
      sx={sx}
    />
  );
};

const textField = (props: any) => (
  <Field
    component={textFieldComponent}
    {...props}
  />
);

export default textField;
