// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import undoable from 'redux-undo';
import thunkMiddleware from 'redux-thunk';
import authReducer from './features/auth/authSlice';
import projectListReducer from './features/projectList/projectListSlice';
import accountReducer from './features/account/accountSlice';
import shellReducer from './features/shell/shellSlice';
import orderReducer from './features/order/orderSlice';
import subscriptionReducer from './features/account/subscriptionSlice';
import cadTemplateReducer from './features/account/cadTemplates/cadTemplateSlice';
import bundleReducer from './features/bundle/bundleSlice';
import fileUploadReducer from './features/fileUpload/fileUploadSlice';
import mapCommonReducer from './features/map/mapCommonSlice';
import mapEditorReducer from './features/map/mapEditorSlice';
import mapDrawReducer from './features/map/mapDrawSlice';
import projectReducer from './features/project/projectSlice';
import adminReducer from './features/admin/adminSlice';
import viewer3DReducer from './features/viewer3D/viewer3DSlice';
import kmlReducer from './features/kml/kmlSlice';
import imageryReducer from './features/imagery/imagerySlice';
import globalStatusReducer from './features/globalStatus/globalStatusSlice';
import opsReducer from './features/ops/opsSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    projectList: projectListReducer,
    account: accountReducer,
    shell: shellReducer,
    globalStatus: globalStatusReducer,
    opsTraining: opsReducer,
    order: orderReducer,
    subscription: subscriptionReducer,
    cadTemplate: cadTemplateReducer,
    bundle: bundleReducer,
    project: projectReducer,
    fileUpload: fileUploadReducer,
    admin: adminReducer,
    map: combineReducers({ common: mapCommonReducer, editor: undoable(mapEditorReducer), draw: undoable(mapDrawReducer) }),
    viewer3D: viewer3DReducer,
    kml: undoable(kmlReducer),
    imagery: imageryReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(thunkMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
