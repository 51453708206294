// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createSlice } from '@reduxjs/toolkit';
import {
  SetSiteIdAction,
  SetOrderSelectedAction,
  SetShowSidebarAction,
  SetCurrentPanelAction,
  SetCurrentLayerAction,
  SetlayerSelectedAction,
  SetCurrentToolAction,
  SetEditorModeAction,
  ResetDrawSourceAction,
  ResetUpdateEntitiesAction,
  CreateFeatureAction,
  EditFeatureAction,
  DeleteAddedOrEditedFeatureAction,
  DeleteFeatureAction,
  SetDrawModeAction,
  CopyActiveFeatureAction,
  CutActiveFeatureAction,
  PasteActiveFeatureAction,
  GetGeoJsonForLayerSuccessAction,
  SetLayerUpdateAction,
  UpdateEntityStartAction,
  UpdateEntitySuccessAction,
  UpdateEntityFailureAction,
  SetAutoUpdateAction,
  SetHighlightedLayerAction,
} from './mapEditorActions';

const initialState: IEditorState = {
  featureCollection: {
    type: 'FeatureCollection',
    features: [],
  },
  clickedPoint: null,
  currentLayer: null,
  siteId: null,
  update: false,
  editEntities: [],
  addEntities: [],
  deleteEntities: [],
  currentTool: 'pointer',
  currentPanel: 'layers',
  editorMode: true,
  drawMode: null,
  cloneEntity: null,
  layerSelected: null,
  orderSelected: null,
  autoUpdate: false,
  highlightedLayer: null,
  showSidebar: true,
  updateEntityStart: false,
};

const mapEditorSlice = createSlice({
  name: 'mapEditor',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetGeoJsonForLayerSuccessAction, (state, action) => {
        state.featureCollection.features = action.payload.features;
        if (state.cloneEntity) {
          // assign newly selected layer properties to clone entity properties
          Object.assign(state.cloneEntity.properties, state.featureCollection.features[0].properties);
        }
      })
      .addCase(SetLayerUpdateAction, (state, action) => {
        state.update = action.payload;
      })
      .addCase(SetSiteIdAction, (state, action) => {
        state.siteId = action.payload;
      })
      .addCase(SetOrderSelectedAction, (state, action) => {
        state.orderSelected = action.payload;
      })
      .addCase(SetCurrentPanelAction, (state, action) => {
        state.currentPanel = action.payload;
      })
      .addCase(SetShowSidebarAction, (state, action) => {
        state.showSidebar = action.payload;
      })
      .addCase(SetlayerSelectedAction, (state, action) => {
        state.layerSelected = action.payload;
      })
      .addCase(SetAutoUpdateAction, (state, action) => {
        state.autoUpdate = action.payload;
      })
      .addCase(SetEditorModeAction, (state, action) => {
        state.editorMode = action.payload;
      })
      .addCase(SetCurrentToolAction, (state, action) => {
        state.currentTool = action.payload;
      })
      .addCase(SetCurrentLayerAction, (state, action) => {
        state.currentLayer = action.payload;
      })
      .addCase(ResetDrawSourceAction, (state) => {
        state.featureCollection = initialState.featureCollection;
      })
      .addCase(SetDrawModeAction, (state, action) => {
        state.drawMode = action.payload;
      })
      .addCase(UpdateEntityStartAction, (state) => {
        state.updateEntityStart = true;
      })
      .addCase(UpdateEntitySuccessAction, (state) => {
        state.updateEntityStart = false;
      })
      .addCase(UpdateEntityFailureAction, (state) => {
        state.updateEntityStart = false;
      })
      .addCase(SetHighlightedLayerAction, (state, action) => {
        state.highlightedLayer = action.payload;
      })
      .addCase(ResetUpdateEntitiesAction, (state) => {
        state.editEntities = initialState.editEntities;
        state.addEntities = initialState.addEntities;
        state.deleteEntities = initialState.deleteEntities;
      })
      .addCase(DeleteFeatureAction, (state, action) => {
        state.deleteEntities.push(action.payload);
        const index = state.featureCollection.features.findIndex((feature: any) => feature.id === action.payload.id);
        if (index !== -1) state.featureCollection.features.splice(index, 1);
      })
      .addCase(CopyActiveFeatureAction, (state, action) => {
        state.cloneEntity = action.payload;
      })
      .addCase(CutActiveFeatureAction, (state, action) => {
        const index = state.featureCollection.features.findIndex((feature: any) => feature.id === action.payload.id);
        if (index !== -1) state.featureCollection.features.splice(index, 1);
        state.cloneEntity = action.payload;
      })
      .addCase(PasteActiveFeatureAction, (state, action) => {
        state.featureCollection.features.push(action.payload);
        state.addEntities.push(action.payload);
      })
      .addCase(DeleteAddedOrEditedFeatureAction, (state, action) => {
        // Check if the feature exists in featureCollection (-_-)
        const index1 = state.featureCollection.features.findIndex((feature: any) => feature.id === action.payload.id);
        if (index1 !== -1) state.featureCollection.features.splice(index1, 1);
        // Check if feature exists in the addEntities array
        const index2 = state.addEntities.findIndex((feature: any) => feature.id === action.payload.id);
        if (index2 !== -1) state.addEntities.splice(index2, 1);
        // Check if feature exists in the editEntities array
        const index3 = state.editEntities.findIndex((feature: any) => feature.id === action.payload.id);
        if (index3 !== -1) state.editEntities.splice(index3, 1);
      })
      .addCase(CreateFeatureAction, (state, action) => {
        // Check if the feature exists in featureCollection
        const index1 = state.featureCollection.features.findIndex((feature: any) => feature.id === action.payload.id);
        if (index1 !== -1) {
          // if it exists, update it
          state.featureCollection.features[index1] = action.payload;
        } else {
          state.featureCollection.features.push(action.payload);
        }
        // Check if a feature exists in the addEntities array
        const index2 = state.addEntities.findIndex((feature: any) => feature.id === action.payload.id);
        if (index2 !== -1) {
          // if it exists, update it
          state.addEntities[index2] = action.payload;
        } else {
          state.addEntities.push(action.payload);
        }
      })
      .addCase(EditFeatureAction, (state, action) => {
        // Check if the feature exists in featureCollection
        const index1 = state.featureCollection.features.findIndex((feature: any) => feature.id === action.payload.id);
        if (index1 !== -1) {
          state.featureCollection.features.splice(index1, 1);
          state.featureCollection.features.push(action.payload);
        }
        const index2 = state.editEntities.findIndex((feature: any) => feature.id === action.payload.id);
        if (index2 !== -1) {
          state.editEntities[index2] = action.payload;
        } else {
          state.editEntities.push(action.payload);
        }
      });
  },
});

export const { reducer } = mapEditorSlice;

export default reducer;
