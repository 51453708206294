// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { useAppSelector, useAppDispatch } from 'Hooks';
import { IconButton, Badge, Popper, Fade, Paper, Typography, ButtonBase, ClickAwayListener } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Box, useTheme } from '@mui/system';
import stylesDef from './styles';
import { ToggleNotificationThunk } from 'Features/shell/shellThunk';

const NotificationsMenu = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { notificationList } = useAppSelector(
    (state) => state.shell,
  );
  const newNotificationsCount = notificationList.filter((n) => !n.viewed).length;

  const clickOpenPopper = (e: any) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
    e.preventDefault();
  };

  const toggleNotification = (notification) => {
    dispatch(ToggleNotificationThunk(notification._id, !notification.viewed));
  };

  return (
    <>
      <IconButton onClick={clickOpenPopper} aria-label="notifications">
        <Badge badgeContent={newNotificationsCount} color="primary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popper open={open} anchorEl={anchorEl} placement="bottom-start" transition sx={styles.popper}>
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper sx={styles.notificationsPanel}>
                <Typography variant="h2">Notifications</Typography>
                <Box sx={styles.notificationsList}>
                  { notificationList.length > 0 ? notificationList.map((notification) => {
                  // add classes for applying different styles to errors and warnings
                    const content = (
                      <Box sx={{ ...styles.notificationItem,
                        ...(!notification.viewed && styles.activeItem),
                        ...(!notification.viewed && notification.title === 'WARNING' && styles.warningItem),
                        ...(!notification.viewed && notification.title === 'ERROR' && styles.errorItem) }}
                      >
                        <Typography>{notification.message}</Typography>
                      </Box>
                    );
                    return <ButtonBase sx={styles.notificationCard} key={notification._id} onClick={() => toggleNotification(notification)}>{content}</ButtonBase>;
                  }) : <Typography>You have no notifications yet</Typography> }
                </Box>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default NotificationsMenu;
