// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createSlice } from '@reduxjs/toolkit';
import {
  GetOrgProfileFailedAction,
  GetOrgProfileStartAction,
  GetOrgProfileSuccessAction,
  GetUserProfileFailedAction,
  GetUserProfileStartAction,
  GetUserProfileSuccessAction,
  UpdateUserProfileSuccessAction,
  UpdateOrgProfileSuccessAction,
  UpdatePasswordSuccessAction,
  UpdatePasswordFailureAction,
  GetOrgTeamsStartAction,
  GetOrgTeamsSuccessAction,
  GetOrgTeamsFailureAction,
  DefaultTemplateSuccessAction,
  DeleteOrgUserStartAction,
  DeleteOrgUserSuccessAction,
  DeleteOrgUserFailureAction,
  ClearOrgAccountAction,
  GetOrgUsersStartAction,
  GetOrgUsersSuccessAction,
  GetOrgUsersFailureAction,
  GetOrgAssignableRolesAction,
  EditOrgUserSuccessAction,
  CreateOrgUserStartAction,
  CreateOrgUserSuccessAction,
  CreateOrgUserFailureAction,
  DeleteOrgTeamStartAction,
  DeleteOrgTeamSuccessAction,
  DeleteOrgTeamFailureAction,
  UpdateOrgTeamStartAction,
  UpdateOrgTeamSuccessAction,
  UpdateOrgTeamFailureAction,
  AddOrgTeamStartAction,
  AddOrgTeamSuccessAction,
  AddOrgTeamFailureAction,
  GetOrgStatsStartAction,
  GetOrgStatsSuccessAction,
} from './accountActions';

const initialState: IAccountState = {
  loading: false,
  userUpdating: false,
  user: null,
  updatePasswordMessage: null,
  updateUserMessage: null,
  updateCompanyMessage: null,
  orgStats: null,
  org: null,
  orgUsers: [],
  orgTeams: [],
  orgRoles: {},
  teamError: null,
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetUserProfileStartAction, (state) => {
        state.loading = true;
      })
      .addCase(GetUserProfileSuccessAction, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(GetUserProfileFailedAction, (state) => {
        state.loading = false;
      })
      .addCase(GetOrgProfileStartAction, (state) => {
        state.loading = true;
      })
      .addCase(GetOrgProfileSuccessAction, (state, action) => {
        state.loading = false;
        state.org = action.payload;
      })
      .addCase(GetOrgProfileFailedAction, (state) => {
        state.loading = false;
      })
      .addCase(UpdateUserProfileSuccessAction, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.updateUserMessage = action.payload.message;
      })
      .addCase(UpdateOrgProfileSuccessAction, (state, action) => {
        state.loading = false;
        state.org = action.payload.org;
        state.updateCompanyMessage = action.payload.message;
      })
      .addCase(UpdatePasswordSuccessAction, (state, action) => {
        state.updatePasswordMessage = action.payload;
      })
      .addCase(UpdatePasswordFailureAction, (state) => {
        state.updatePasswordMessage = null;
      })
      .addCase(GetOrgTeamsStartAction, (state) => {
        state.loading = true;
        state.teamError = null;
      })
      .addCase(GetOrgTeamsSuccessAction, (state, action) => {
        state.loading = false;
        state.orgTeams = action.payload;
        state.teamError = null;
      })
      .addCase(GetOrgTeamsFailureAction, (state) => {
        state.loading = false;
        state.teamError = 'Failed to get teams';
      })
      .addCase(DefaultTemplateSuccessAction, (state, action) => {
        state.org = action.payload;
      })
      .addCase(DeleteOrgUserStartAction, (state, action) => {
        const index = state.orgUsers.findIndex((u) => u.email === action.payload);
        state.orgUsers[index].deleting = true;
      })
      .addCase(DeleteOrgUserSuccessAction, (state, action) => {
        state.orgUsers = state.orgUsers.filter((u) => u.email !== action.payload);
      })
      .addCase(DeleteOrgUserFailureAction, (state, action) => {
        const index = state.orgUsers.findIndex((u) => u.email === action.payload);
        state.orgUsers[index].deleting = false;
      })
      .addCase(GetOrgUsersStartAction, (state) => {
        state.loading = true;
      })
      .addCase(GetOrgUsersSuccessAction, (state, action) => {
        state.loading = false;
        state.orgUsers = action.payload;
      })
      .addCase(GetOrgUsersFailureAction, (state) => {
        state.loading = false;
      })
      .addCase(GetOrgAssignableRolesAction, (state, action) => {
        state.orgRoles = action.payload;
      })
      .addCase(CreateOrgUserStartAction, (state) => {
        state.loading = true;
      })
      .addCase(CreateOrgUserSuccessAction, (state, action) => {
        state.loading = false;
        state.orgUsers.push(action.payload);
      })
      .addCase(CreateOrgUserFailureAction, (state) => {
        state.loading = false;
      })
      .addCase(EditOrgUserSuccessAction, (state, action) => {
        const index = state.orgUsers.findIndex((u) => u._id === action.payload._id);
        state.orgUsers[index].role = action.payload.role;
        state.orgUsers[index].teams = action.payload.teams;
      })
      .addCase(GetOrgStatsStartAction, (state) => {
        state.loading = true;
      })
      .addCase(GetOrgStatsSuccessAction, (state, action) => {
        state.loading = false;
        state.orgStats = action.payload;
      })
      .addCase(DeleteOrgTeamStartAction, (state) => {
        state.teamError = null;
      })
      .addCase(DeleteOrgTeamSuccessAction, (state, action) => {
        state.orgTeams = state.orgTeams.filter((u) => u._id !== action.payload);
        state.teamError = null;
      })
      .addCase(DeleteOrgTeamFailureAction, (state) => {
        state.teamError = 'Failed to delete team';
      })
      .addCase(UpdateOrgTeamStartAction, (state) => {
        state.teamError = null;
      })
      .addCase(UpdateOrgTeamSuccessAction, (state, action) => {
        const index = state.orgTeams.findIndex((u) => u._id === action.payload._id);
        state.orgTeams[index] = action.payload;
        state.teamError = null;
      })
      .addCase(UpdateOrgTeamFailureAction, (state) => {
        state.teamError = 'Failed to update team';
      })
      .addCase(AddOrgTeamStartAction, (state) => {
        state.teamError = null;
      })
      .addCase(AddOrgTeamSuccessAction, (state, action) => {
        state.orgTeams.push(action.payload);
        state.teamError = null;
      })
      .addCase(AddOrgTeamFailureAction, (state) => {
        state.teamError = 'Failed to add team';
      })
      .addCase(ClearOrgAccountAction, (state) => {
        state.orgUsers = [];
        state.orgTeams = [];
        state.orgRoles = {};
      });
  },
});

export const { reducer } = accountSlice;

export default reducer;
