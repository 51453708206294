// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import moment from 'moment-timezone';
import { Box, useTheme } from '@mui/system';
import { Dialog, DialogActions, DialogContent, Typography, Button } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { pricingDescription } from 'Utils/constants';
import stylesDef from './styles';

interface PlanHistoryDialogProps {
  showDialog: boolean;
  planHistory: IPricingPlanInfo[];
  onCancel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const PlanHistoryDialog = ({ showDialog, planHistory, onCancel }: PlanHistoryDialogProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);

  return (
    <Dialog open={showDialog} onClose={onCancel} sx={styles.dialog} maxWidth="md">
      <Box sx={styles.dialogBox}>
        <Typography variant="h3" sx={styles.titleText}>Plan History</Typography>
        <DialogContent sx={styles.dialogContent}>
          {planHistory?.map((plan) => (
            <Box sx={styles.planCard} key={plan.startDate.toString()}>
              <Box>
                <Typography sx={styles.planName}>{pricingDescription.find((tier) => tier.name === plan.name)?.displayName}</Typography>
                <Box sx={styles.planDate}>
                  <AccessTimeIcon sx={styles.timeIcon} />
                  <Typography sx={styles.planDateText}>From: </Typography>
                  <Typography component="span">{ moment(plan.startDate).format('LL')}</Typography>
                </Box>
                <Box sx={styles.planDate}>
                  <AccessTimeIcon sx={styles.timeIcon} />
                  <Typography sx={styles.planDateText}>Until: </Typography>
                  <Typography component="span">{ moment(plan.endDate).format('LL')}</Typography>
                </Box>
              </Box>
              <Box sx={styles.creditsInfo}>
                <Box sx={styles.creditsInfoText}>
                  <Typography variant="h4" sx={styles.credit}>{plan.creditsPurchased || 0}</Typography>
                  <Typography variant="h6" sx={styles.text}>credits</Typography>
                  <Typography variant="h6" sx={styles.text}>purchased</Typography>
                </Box>
                <Box sx={styles.line} />
                <Box sx={styles.creditsInfoText}>
                  <Typography variant="h4" sx={styles.credit}>{plan.usedCredits || 0}</Typography>
                  <Typography variant="h6" sx={styles.text}>credits</Typography>
                  <Typography variant="h6" sx={styles.text}>used</Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} sx={styles.okButton}>Close</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default PlanHistoryDialog;
