// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect } from 'react';
import { Layer } from 'react-map-gl';
import { API_URL } from 'Config';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { getOrders } from 'Features/order/orderSelectors';
import {
  GetGeoJsonForLayer as GetGeoJsonForLayerAction,
  SetLayerUpdate as SetLayerUpdateAction,
  SetlayerSelected as SetlayerSelectedAction,
  Autosave as AutosaveAction,
  SetAutoUpdate as SetAutoUpdateAction,
  SetHighlightedLayer as SetHighlightedLayerAction,
} from 'Features/map/mapEditorThunk';
import { SetCurrentLayerAction, SetSiteIdAction } from 'Features/map/mapEditorActions';
import { SetLayerVisibleAction } from 'Features/map/mapCommonActions';

interface ComponentProps {
  id: string;
  orderId: string;
  sourceId: string;
  fileVersion: string;
  sourceLayerId: string;
  color: string;
  lineWidth: number;
  map: any;
}

const MapLayers: React.FC<ComponentProps> = ({ id, orderId, sourceId, fileVersion, sourceLayerId, lineWidth, map, color }) => {
  const dispatch = useAppDispatch();
  const vectorTileJson = useAppSelector((state) => state.map.common.vectorTileJson[orderId][fileVersion]);
  const resources = useAppSelector((state) => state.auth.resources);
  const layerVisible = useAppSelector((state) => state.map.common.layerVisible);
  const projectAccessUS = !_ADMIN_ && 'projectAccessUS' in resources;
  const projectAccessIndia = !_ADMIN_ && 'projectAccessIndia' in resources;

  const orders = useAppSelector((state) => getOrders(state).projectOrders);
  const order = orders.find((o) => o._id === orderId);
  const cadFile = order?.cadFiles?.find((f) => f._id === fileVersion);
  const expressAI = cadFile?.expressAIUpload && cadFile?.expressAIEmailSent;
  const orderDisplayed = (_ADMIN_ || projectAccessUS || projectAccessIndia || order.orderStatus === 0 || order.orderStatus === 7 || expressAI);

  if (!vectorTileJson) return {};

  const orderVisible = vectorTileJson.visible;
  const layer = vectorTileJson.vector_layers.find((l) => l.id === sourceLayerId);

  const visible = Boolean(orderDisplayed && orderVisible && layer?.visible && layerVisible?.[id]);
  const currentLayer = useAppSelector((state) => state.map.editor.present.currentLayer);
  const autoSaveUpdate = useAppSelector((state) => state.map.editor.present.autoUpdate);
  const update = useAppSelector((state) => state.map.editor.present.update);
  const editorFunctions = !_ADMIN_ ? 'editorFunctions' in resources : true;
  const layerSelected = useAppSelector((state) => state.map.editor.present.layerSelected);
  const currentTool = useAppSelector((state) => state.map.editor.present.currentTool);
  const highlightedLayer = useAppSelector((state) => state.map.editor.present.highlightedLayer);
  const updateEntityStart = useAppSelector((state) => state.map.editor.present.updateEntityStart);

  const onLayerClick = () => {
    // When we are in any other tool other than 'pointer' if currentLayer and layerSelected are null or if
    // currentLayer and layerSelected are both are not equal to the sourceLayerId return from this function
    if (currentTool !== 'pointer' && ((!currentLayer && !layerSelected) || (currentLayer !== sourceLayerId && layerSelected !== sourceLayerId))) {
      return;
    }
    if (highlightedLayer && highlightedLayer !== sourceLayerId) {
      return;
    }
    // If updateLayer API is called, wait till we get a response, avoid calling autosave twice
    if (updateEntityStart) {
      return;
    }
    // Users with no editorFunctions resource and Airworks Admins should not be able to click on the lines
    if (editorFunctions && !_ADMIN_) {
      dispatch(SetHighlightedLayerAction(null));
      dispatch(AutosaveAction());
      if (sourceLayerId !== layerSelected) {
        dispatch(SetlayerSelectedAction(sourceLayerId));
      }
      if (currentLayer !== layerSelected) {
        // Clear Editor's Draw source when we move to a new layer
        dispatch(GetGeoJsonForLayerAction(sourceLayerId, fileVersion));
        dispatch(SetCurrentLayerAction(sourceLayerId));
        dispatch(SetSiteIdAction(fileVersion));
        const newlayerVisible = { ...layerVisible, [`default-${orderId}-${currentLayer}`]: true, [id]: false };
        dispatch(SetLayerVisibleAction(newlayerVisible));
      }
      // If the value of update is true, it means that the currently active dynamic layer has been edited
      // In this case, when the user clicks on a new static layer, we refresh the tiles to see the updated tiles for the previous edited layer
      // Set the update variable in the store to false after mvt refresh
      if ((update || autoSaveUpdate) && map.getSource(sourceId)) {
        const url = `${API_URL}/tiles/${fileVersion}/{z}/{x}/{y}.mvt`;
        map.getSource(sourceId).setTiles([url]);
        dispatch(SetLayerUpdateAction(false));
        dispatch(SetAutoUpdateAction(false));
      }
    }
  };

  useEffect(() => {
    if (layerSelected === sourceLayerId) {
      onLayerClick();
    }
  }, [layerSelected, autoSaveUpdate]);

  // For a given lineWidth-
  // width in mm = lineWidth * 0.01
  // width in pixels = lineWidth * 0.01 * 3.779
  //
  // lineWidth values range between -3 and 100
  // Exceptions -
  // For lineWidth value -3, we set the width as 0.25mm. This is 3.779 pixels * 0.25 = 0.9447 pixels
  // We also treat the lineWidth values -1 and -2 as -3(this is temporary)
  // For lineWidth value 0, we treat as lineWidth 13 and set the width as 0.13mm.
  const pixels = 3.779; // 1 mm = 3.779 pixels
  let widthInPixels = 0.9447; // set 0.25mm as the default value

  if (lineWidth === 0) {
    widthInPixels = 0.13 * pixels;
  } else if (lineWidth === -1 || lineWidth === -2 || lineWidth === -3) {
    widthInPixels = 0.9447;
  } else {
    widthInPixels = lineWidth * 0.01 * pixels;
  }
  // dynamic threshold for the transparent layer
  // minimum at 15, and if the widthInPixels * 2 is wider than 15, use the linewidth
  const newWidth = widthInPixels * 2 > 15 ? widthInPixels * 2 : 15;

  const onMouseEnter = () => {
    // Show the highlight layer when we hover over the current layer
    // Will allow this for all accounts in the future, but right now it is only for accounts with editorFunctions and also not Airworks Admins
    if (editorFunctions && !_ADMIN_) {
      if (highlightedLayer && highlightedLayer !== sourceLayerId) {
        return;
      }
      dispatch(SetHighlightedLayerAction(sourceLayerId));
    }
  };

  const onMouseLeave = () => {
    if (editorFunctions && !_ADMIN_) {
      // Hide the highlight layer when the mouse leaves the current layer
      dispatch(SetHighlightedLayerAction(null));
    }
  };

  return (
    <>
      <Layer
        beforeId="vectorStub"
        id={id}
        key={id}
        type="line"
        source={sourceId}
        source-layer={sourceLayerId}
        paint={{
          'line-color': color,
          'line-width': (widthInPixels * 2), // Doubling the line widths so that they look better on the app
        }}
        layout={{
          visibility: visible ? 'visible' : 'none',
        }}
      />
      {/* This dummy layer(highlight layer) is always hidden. It is shown on hovering over the actual layer and is controlled inside the actual layer */}
      {/* <Layer
        beforeId="vectorStub"
        id={`${id}-highlight`}
        key={`${id}-highlight`}
        type="line"
        source={sourceId}
        source-layer={sourceLayerId}
        paint={{
          'line-color': '#2075FE',
          'line-width': 1,
        }}
        layout={{
          visibility: highlightedLayer === sourceLayerId ? 'visible' : 'none',
        }}
      /> */}
      {/* This is a transparent layer to lower the sensitivity on hovering the layer */}
      {/* <Layer
        id={`${id}-invisible`}
        key={`${id}-invisible`}
        type="line"
        source={sourceId}
        source-layer={sourceLayerId}
        paint={{
          'line-color': '#CCCCCC',
          'line-opacity': 0,
          'line-width': newWidth,
        }}
        layout={{
          visibility: visible ? 'visible' : 'none',
        }}
        onClick={onLayerClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      /> */}
    </>
  );
};

export default MapLayers;
