// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import SVG from 'react-inlinesvg';
import layers from 'Assets/layers.svg';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { SetCurrentPanel } from 'Features/map/mapEditorThunk';
import stylesDef from './styles';

export const LayersPanel = () => {
  const styles = stylesDef();
  const dispatch = useAppDispatch();
  const { currentPanel } = useAppSelector((state) => state.map.editor.present);

  return (
    <Box sx={(currentPanel === 'layers') ? styles.panelCurrent : {}}>
      <Button sx={styles.option} onClick={() => dispatch(SetCurrentPanel('layers'))}>
        <SVG src={layers} title="layers" css={styles.icon} />
      </Button>
    </Box>
  );
};

export default LayersPanel;
