// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect } from 'react';
import { Box, useTheme } from '@mui/system';
import { Dialog, Typography, Button, Collapse } from '@mui/material';
import logo from 'Assets/logo_small.png';
import { Form } from 'react-final-form';
import { TextField, Validators, Helpers } from 'Components/forms';
import { ThemeProvider } from '@mui/material/styles';
import { darkTheme } from 'Utils/themes';
import { useAppDispatch } from 'Hooks';
import { useParams } from 'react-router';
import { ResetPasswordThunk, VerifyTokenThunk } from '../authThunk';
import stylesDef from './styles';

export const ResetPassword = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const { token } = useParams();
  const dispatch = useAppDispatch();

  const onLoad = async () => {
    await dispatch(VerifyTokenThunk(token));
  };

  useEffect(() => {
    onLoad();
  });

  const resetPassword = (payload: { password: string }) => dispatch(ResetPasswordThunk(payload));

  const onFormSubmit = (values: any) => {
    Helpers.submit(values, resetPassword);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Dialog
        fullScreen
        open
        keepMounted={false}
        sx={styles.dialogPaper}
      >
        <Box sx={styles.dialog}>
          <img src={logo} alt="AirWorks small logo" />
          <Typography sx={styles.header} variant="h1">
            Reset your Password
          </Typography>
          <Typography sx={styles.details}>
            Choose a new password that meets password requirements
          </Typography>
          <Box sx={styles.requirements}>
            <Typography sx={styles.rheader} variant="h5">
              Your password must contain:
            </Typography>
            <Typography sx={styles.rdetails}>A minimum of 8 characters</Typography>
            <Typography sx={styles.rdetails}>A number</Typography>
            <Typography sx={styles.rdetails}>A lowercase letter and an uppercase letter</Typography>
            <Typography sx={styles.rdetails}>
              A special character: ~`!@#$%^&*()_-+=
              {'{'}
              [
              {'}'}
              ]|\:;
              &quot;&apos;
              {'<'}
              ,
              {'>'}
              .?/
            </Typography>
          </Box>
          <Form
            onSubmit={onFormSubmit}
            render={({ hasSubmitErrors, modifiedSinceLastSubmit, submitting, submitError, handleSubmit }) => (
              <form
                style={styles.form}
                onSubmit={handleSubmit}
              >
                <TextField
                  name="password"
                  label="New Password"
                  type="password"
                  sx={styles.textField}
                  validate={Validators.composeValidators(Validators.required, Validators.strongPassword)}
                />
                <TextField
                  name="passwordConfirm"
                  label="Confirm Password"
                  type="password"
                  sx={styles.textField}
                  validate={Validators.composeValidators(Validators.required, Validators.strongPassword, Validators.passwordsMatch)}
                />
                <Collapse in={Boolean(hasSubmitErrors && !modifiedSinceLastSubmit)}>
                  <Typography variant="body1" sx={styles.errorMessage}>
                    {submitError}
                  </Typography>
                </Collapse>
                <Box sx={styles.buttonWrapper}>
                  <Button
                    type="submit"
                    variant="outlined"
                    sx={styles.button}
                    disabled={(hasSubmitErrors && !modifiedSinceLastSubmit) || submitting}
                  >
                    Reset Password
                  </Button>
                </Box>
              </form>
            )}
          />
        </Box>
      </Dialog>
    </ThemeProvider>
  );
};

export default ResetPassword;
