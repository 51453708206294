// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  projectName: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    gridColumnGap: theme.spacing(1),
  },
  titleButton: {
    padding: '0px',
    width: '100%',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
    fontWeight: 500,
    fontSize: '1rem',
    justifyContent: 'left',
    textTransform: 'none',
    color: '#4f5a64',
  },
  inputField: {
    '& .MuiInputBase-input': {
      width: '100%',
      padding: '4px',
      background: '#E6E8ED',
      height: '41px',
      fontSize: '1rem',
    },
  },
});

export default styles;
