/*
Normalizing a curve makes it easier to work with by breaking down everything into a small number of regular forms. Notably, after normalization:
- the top level element is either compoundcurve or curvepolygon
- every ring of a curvepolygon is a compoundcurve
- every circularstring consists of exactly 3 points (by splitting those of more than 3 points)
*/
function normalizeCurve([type, ...rest]) {
  function normalizeElement([elType, ...coords]) {
    if (elType === 'circularstring' && coords.length > 3) {
      const out = [];
      for (let i = 0; i < coords.length - 2; i += 2) {
        out.push(['circularstring', ...coords.slice(i, i + 3)]);
      }
      return out;
    }
    return [[elType, ...coords]]; // return same level of nesting
  }
  if (type === 'curvepolygon') {
    // we want each ring to be a compoundcurve. probably only 1 ring in practice.
    return [type, ...rest.map(normalizeCurve)];
  }
  if (type === 'circularstring') {
    // turn everything into compoundcurve
    return ['compoundcurve', ...normalizeElement([type, ...rest])];
  }
  if (type === 'compoundcurve') {
    return [type, ...rest.flatMap(normalizeElement)];
  }
  if (type === 'linestring') {
    // maybe a linear ring within a curvepolygon
    return ['compoundcurve', [type, ...rest]];
  }
  throw new Error(`Unsuppported type in normalizeCurve: ${type}`);
}
function isCircle([type, arc, next] = []) {
  if (!type) {
    return false;
  }
  if (type === 'curvepolygon') {
    return arc && isCircle(arc) && !next;
  }

  return (
    type === 'compoundcurve' &&
    arc &&
    !next &&
    arc[0] === 'circularstring' &&
    arc.length === 4 &&
    arc[3][0] === arc[1][0] &&
    arc[3][1] === arc[1][1]
  );
}

function circleToPolygon([type, ...elements]) {
  if (type === 'compoundcurve' && isCircle([type, ...elements])) {
    return ['curvepolygon', [type, ...elements]];
  }
  return [type, ...elements];
}

/*

Parses a WKT (well-known string) to an object structure as required by the curves functions.
Options:
- normalize: imposes requirements such as 3 points per circularstring, every ring of a curvepolygon is a compoundcurve, etc
- circlesToPolygons: automatically converts circle compoundCurves to curvePolygons
Caveats:
- no validation
- focuses on handling possible outputs from PostGIS
- inserts a fictional 'linestring' tag name within CompoundCurve etc
- converts tag names to lowercase

Output format: ['compoundcurve', ['circularstring', [0, 0], [1, 2], [2, 2]], ['linestring', [2,2], [2, 4]]

*/

export default function parseWkt(
  wktString,
  options = { normalize: true, circlesToPolygons: false },
) {
  const s = () => wktString.slice(pos);
  let pos = 0;

  // match a pattern, advance that many characters, and return what matched
  function consume(pattern, optional) {
    const m = s().match(pattern);
    if (m) {
      pos += m[0].length;
    } else if (!optional) {
      throw new Error(`Expected ${pattern} in ${s()}`);
    }
    return m && m[0];
  }

  // parse a parenthesised, comma-separated list of things into an array
  function getList() {
    const ret = [];
    consume(/^\(/);
    while (s()[0] !== ')') {
      ret.push(getElement());
      consume(/^,\s*/, true);
    }
    consume(/^\)\s*/);
    return ret;
  }

  // parse some space-separated numbers into an array of numbers
  // "43.2 -16.9" -> [43.2, -16.9]
  function parseCoord() {
    const coordString = consume(/^[^,)]+/).trim();
    return coordString.split(/\s+/).map(Number);
  }

  // parse an element. It could be one of:
  // - tagname and list:  circularstring(1 2, ...) or compoundcurve(circularstring(...), ...)
  // - implicit linestring and list: (1 2, ...)
  // - coordinate: 1 2
  // because we do this with minimal semantics, we don't really know what to expect any time we're inside parentheses.
  function getElement() {
    const tag = consume(/^([a-z]*)(?=\()/i, true);
    if (tag !== null) {
      return [(tag || 'linestring').toLowerCase(), ...getList()];
    }
    if (s().match(/^[0-9-]/)) {
      return parseCoord();
    }
    throw new Error(`Unexpected element ${s()}`);
  }
  let r = getElement();
  if (options.normalize) {
    r = normalizeCurve(r);
  }
  if (options.circlesToPolygons) {
    r = circleToPolygon(r);
  }
  return r;
}
