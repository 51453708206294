// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { FORM_ERROR } from 'final-form';

// eslint-disable-next-line consistent-return
const submit = async <TPayload, TResponse>(ev: TPayload, action: (payload: TPayload) => Promise<IApiResponse<TResponse>>) => {
  const result = await action(ev);
  if (result && (result.message || result.errors)) {
    const errorObject = { [FORM_ERROR]: result.message, ...result.errors };
    return errorObject;
  }
};

export default { submit };
