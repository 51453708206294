import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL, GetTermsAndConditionsUrl } from 'Config';
import { getFile, postJson } from 'Utils/http';
import history from 'Utils/history';
import { getLoginFailedAttempts, removeLoginFailedAttempts, setHTML, setLoginFailedAttempts, setUser, setToken, setPlanDetails } from 'Utils/localStorageService';
import jwtDecode from 'jwt-decode';
import moment from 'moment';

export const LogoutAction = createAction('auth/LogoutAction');

export const SetShowTokenExpiredDialogAction = createAction<boolean>('auth/SetShowTokenExpiredDialogAction');

export const InitAuthSuccessAction = createAction<{ user: IUser, token: string, tokenExpires: number, html: string, resources: any, planDetails: any, failedLoginAttempts: number }>('auth/InitAuthSuccessAction');

export const InitAuthFailedAction = createAction<number>('auth/InitAuthFailedAction');

export const OnAcceptTnCAction = createAction('auth/OnAcceptTnCAction');

export const OktaAdminLoginStartAction = createAction('auth/OktaAdminLoginStartAction');
export const OktaAdminLoginSuccessAction = createAction<{ user: { email: string }, token: string, tokenExpires: number, resources: any[] }>('auth/OktaAdminLoginSuccessAction');

export const Login = createAsyncThunk<
    {
        user: any;
        token: string;
        planDetails: any;
        tokenExpires: number;
        html: string;
        resources: any;
    },
    {
        email: string;
        password: string;
    },
    {
        rejectValue: {
            failedLoginAttempts?: number;
            message: string;
        }
    }
>(
  'LOGIN',
  async (data: { email: string; password: string }, { rejectWithValue }) => {
    try {
      const url = `${API_URL}/users/login`;
      const result = await postJson<{ user: IUser; token: string, html: string, planDetails: any }>(url, data);

      if (!result.success) {
        const numAttempts = +getLoginFailedAttempts();
        setLoginFailedAttempts(JSON.stringify(numAttempts + 1));
        return rejectWithValue({ failedLoginAttempts: numAttempts + 1, message: result.message });
      }

      if (!result?.data) {
        return rejectWithValue({ message: 'Failed to login' });
      }

      const { user, token, planDetails } = result.data;

      let difference;
      if (user?.lastPasswordUpdate) {
        const today = moment(new Date());
        const previousReset = moment(user?.lastPasswordUpdate);
        difference = today.diff(previousReset, 'days');
      }

      if (difference > 364 || difference === undefined) {
        const forceResetUrl = `${API_URL}/users/forceResetPassword`;
        const resetResult = await postJson<{encodedURLToken: string}>(forceResetUrl, { email: user.email });
        if (resetResult.success) {
          history.push(`/verifyToken/${resetResult.data.encodedURLToken}`);
        }
        return rejectWithValue({ message: 'Please reset password' });
      }

      const decodedToken = jwtDecode<{ exp: number, resources: any }>(token);

      removeLoginFailedAttempts();

      // adding if condition here to not make a call to s3 if the html is 'undefined'
      // it means user have already accepted the latest T&C
      let htmlData;
      if (result.data.html !== undefined) {
        const fileBlobResult = await getFile(GetTermsAndConditionsUrl(result.data.html));
        htmlData = fileBlobResult.data;
        setHTML(htmlData);
      }

      setUser(JSON.stringify(user));
      setToken(token);
      setPlanDetails(JSON.stringify(result.data.planDetails));
      removeLoginFailedAttempts();

      return {
        user,
        token,
        planDetails,
        tokenExpires: decodedToken.exp,
        html: htmlData,
        resources: decodedToken.resources,
      };
    } catch (error) {
      return rejectWithValue({ message: 'Failed to login' });
    }
  },
);
