// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  wrapper: {
    margin: '0 auto',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    width: 500,
  },
  card: {
    minWidth: 275,
    height: '100px',
    width: '500px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 12px 0 rgba(0,0,0,0.1)',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(12),
    paddingLeft: theme.spacing(2),
  },
});

export default styles;
