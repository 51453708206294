// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useRef, useEffect, useState } from 'react';
import { Typography, Link } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { Link as RouterLink } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import HubspotForm  from 'react-hubspot-form';
import history from 'Utils/history';
import PayAsYouGoCard from '../PayAsYouGoCard';
import stylesDef from './styles';
import { CreateOrgThunk } from 'Features/admin/adminThunk';
import { useAppDispatch } from '../../../hooks';

// This component renders signup form
// It renders on "/signUp" route, parent component is auth(index.tsx)
const SignUp = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const captchaRef = useRef(null);
  const [disableSubmit, setDisableSubmit] = useState(true);

  useEffect(() => {
    // this is to use hubspot callbacks without jQuery
    window.addEventListener('message', handler);
    return () => {
      window.removeEventListener('message', handler);
    };
  }, []);

  // hubspot handler on event listener
  const handler = async (event: any) => {
    // Callback that executes after form is validated, just before the data is actually sent.
    if (
      event.data.type === 'hsFormCallback' &&
      event.data.eventName === 'onFormSubmit'
    ) {
      if (event.data.id === 'd9f1edf9-5ff0-43be-bfa8-a48f1112a337') {
        const obj = {};
        // loop through the actual data that was sent to hubspot
        for (let i = 0; i < event.data.data.length; i += 1) {
          const newData = Object.values(event.data.data[i]);
          const entries = new Map([newData]);
          Object.assign(obj, Object.fromEntries(entries));
        }
        // rename hubspot object keys to our keys for eg: rename firstname --> firstName...etc
        const renameKey = (ob: any, old_key: string, new_key: string) => {
          // check if old key = new key
          if (old_key !== new_key) {
            Object.defineProperty(
              ob,
              new_key,
              Object.getOwnPropertyDescriptor(ob, old_key),
            );
            delete ob[old_key];
          }
        };

        const keys = Object.keys(obj);
        keys.forEach((key) => {
          if (key === 'firstname') renameKey(obj, 'firstname', 'firstName');
          if (key === 'lastname') renameKey(obj, 'lastname', 'lastName');
          if (key === 'company') renameKey(obj, 'company', 'companyName');
          if (key === 'phone') renameKey(obj, 'phone', 'phoneNumber');
          if (key === 'jobtitle') renameKey(obj, 'jobtitle', 'jobTitle');
          if (key === 'n2023_industry') renameKey(obj, 'n2023_industry', 'industry');
        });

        obj.captchaToken = await captchaRef.current.getValue();
        // call create org admin on submit button click
        await dispatch(CreateOrgThunk(obj));
        captchaRef.current.reset();
      }
    }
    // Callback the data is actually sent.
    if (
      event.data.type === 'hsFormCallback' &&
      event.data.eventName === 'onFormSubmitted'
    ) {
      // once form submitted redirect the user to signupSuccess page
      history.push('/signUpSuccess');
    }
  };

  const handleCaptchaChange = () => {
    if (captchaRef.current.getValue()) {
      // User has successfully verified, so show the form
      setDisableSubmit(false);
    } else {
      // ReCAPTCHA expired or verification failed, so hide the form
      setDisableSubmit(true);
    }
  };
  return (
    <>
      <Box>
        <Typography variant="h5" sx={{ ...styles.details, ...(!disableSubmit && styles.signupForm) }}>
          Verify to Sign up
        </Typography>
        <Box sx={styles.captcha}>
          <ReCAPTCHA
            sitekey={_GOOGLE_CAPTCHA_SITE_KEY_}
            onChange={handleCaptchaChange}
            ref={captchaRef}
          />
        </Box>
        <Box sx={disableSubmit && styles.signupForm} >
            <HubspotForm
              portalId= '3434755'
              formId='d9f1edf9-5ff0-43be-bfa8-a48f1112a337'
           />
        </Box>
        <Typography sx={styles.details} variant="h5">
          Already have an account?
          {' '}
          <Link component={RouterLink} sx={styles.forgotText} to="/">
            Login
          </Link>
        </Typography>
      </Box>
      <PayAsYouGoCard />
    </>
  );
};

export default SignUp;
