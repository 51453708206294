// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr auto auto',
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  dialogWrapper: {
    margin: theme.spacing(2),
  },
  dialog: {
    '& .MuiDialog-paper': {
      maxWidth: '40%',
      height: '60%',
    },
    // maxWidth: '40%',
    // height: '60%',
  },
  errorMessage: {
    minHeight: '3em',
    marginTop: theme.spacing(1),
    color: theme.palette.error.main,
  },
  dialogContent: {
    padding: '8px 12px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '12px',
      height: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#aaa',
      borderRadius: '5px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#555',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      borderRadius: '2px',
    },
  },
});

export default styles;
