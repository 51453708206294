// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Paper, Button, Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector } from 'Hooks';
import { selectProjectFiles } from 'Features/project/projectSelectors';
import stylesDef from './styles';

export const ErrorPaper = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const { viewer3D } = useFlags();

  const fileStatus = useAppSelector((state) => state.project.fileStatusJson);
  const { kmlError } = useAppSelector((state) => state.kml.present);
  const { rasterTileJson, allRasterTilesLoaded, lowResCreatedAt, lasBBoxLoading } = useAppSelector((state) => state.map.common);
  const loadingHighResTiles = !!rasterTileJson && !!lowResCreatedAt && !allRasterTilesLoaded;

  let errors = false;
  let tile3DError = false;
  let tileExist = false;
  let lasTileOnProcess = false;
  const files = useAppSelector((state) => selectProjectFiles(state));
  files.forEach((file) => {
    if (fileStatus && fileStatus[file.name] && fileStatus[file.name].status === 'ERROR') {
      errors = errors || true;
    }
    if (file.type === 'las' && file.lasType === 'unclassified') {
      if (!tile3DError) {
        tile3DError = file.tileStatuses?.some((statusObj) => statusObj.status === 'ERROR');
      }
      if (!tileExist && !lasTileOnProcess) {
        tileExist = file.tile3DUrl?.length > 0;
        lasTileOnProcess = file.tile3DUrl !== undefined && file.tile3DUrl?.length === 0;
      }
    }
  });

  const errorsExist = errors || kmlError;
  const lasTileLoading = !lasBBoxLoading && !tileExist && lasTileOnProcess;

  const [expandList, setExpandList] = useState(false);
  const [showFileError, setShowFileError] = useState(true);
  const [showTileWarning, setShowTileWarning] = useState(true);
  const [showTileError, setShowTileError] = useState(true);

  return (
    <Box sx={styles.errorStacks}>
      {loadingHighResTiles && (
        <Paper elevation={1} square sx={styles.errorPaper}>
          <Box sx={styles.tileLoadingInfo}>
            <ErrorIcon sx={styles.loadingIcon} />
            <Box>
              <Typography sx={styles.loadingInfoText} variant="body1">
                We’re still working on rendering the full details of your orthomosaic.
              </Typography>
              <Typography sx={styles.loadingInfoText} variant="body1">
                Please draw or upload your KML to continue.
              </Typography>
            </Box>
          </Box>
        </Paper>
      )}
      {errorsExist && showFileError && (
        <Paper elevation={1} square sx={styles.errorPaper}>
          <CloseIcon sx={styles.closeIcon} onClick={() => setShowFileError(false)} />
          <Box sx={styles.errorControls}>
            <ErrorIcon sx={styles.errorIcon} />
            <Typography variant="h3">
              An error has occured
            </Typography>
            <Box />
            <Button onClick={() => setExpandList(!expandList)}>
              {expandList ? 'HIDE' : 'SHOW'}
            </Button>
          </Box>
          {expandList && (
            <Box sx={styles.errorList}>
              {
                kmlError ? (
                  <Typography variant="body1">
                    We're unable to properly render your KML. Please try uploading a single Polygon or LineString KML.
                  </Typography>
                ) : (
                  <>
                    {(fileStatus && Object.keys(fileStatus).length > 0) && (
                      Object.keys(fileStatus).map((file) => {
                        if (fileStatus[file].status === 'ERROR') {
                          return (
                            <>
                              {fileStatus[file].messages.map((message) => (
                                <Typography variant="body1">
                                  {`Error found in file ${file}:  ${message}`}
                                  <br />
                                </Typography>
                              ))}
                            </>
                          );
                        }
                      })
                    )}
                  </>
                )
              }
            </Box>
          )}
        </Paper>
      )}
      {viewer3D && tile3DError && showTileError && (
        <Paper elevation={1} square sx={styles.errorPaper}>
          <Box sx={styles.errorControls}>
            <ErrorIcon sx={styles.errorIcon} />
            <Typography variant="h3">
              We are not able to render your point cloud. Please attempt uploading another file or contact
              {' '}
              <a href="mailto:support@airworks.io" style={styles.link}>support@airworks.io</a>
              {' '}
              for us to troubleshoot further.
            </Typography>
          </Box>
          <CloseIcon sx={styles.closeIcon} onClick={() => setShowTileError(false)} />
        </Paper>
      )}
      {viewer3D && !errorsExist && !tile3DError && lasTileLoading && showTileWarning && (
        <Paper elevation={1} square sx={styles.errorPaper}>
          <Box sx={styles.errorControls}>
            <ErrorIcon sx={styles.warningIcon} />
            <Typography variant="h3">
              We’re still working on rendering the full details of your point cloud.
              Please check back in a couple of minutes.
            </Typography>
          </Box>
          <CloseIcon sx={styles.closeIcon} onClick={() => setShowTileWarning(false)} />
        </Paper>
      )}
    </Box>
  );
};

export default ErrorPaper;
