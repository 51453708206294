// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { Button, Fade, CircularProgress, SnackbarContent, Collapse, Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import ErrorIcon from '@mui/icons-material/Error';
import { Form } from 'react-final-form';
import { TextField, Validators, Helpers, Select } from 'Components/forms';
import states from 'Utils/states';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LoadingSpinner from 'Components/LoadingSpinner';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { GetOrgProfileThunk, UpdateOrgProfileThunk } from 'Features/account/accountThunk';
import stylesDef from './styles';

export const AccountGeneralOrg = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const org = useAppSelector((state) => state.account.org);
  const updateCompanyMessage = useAppSelector((state) => state.account.updateCompanyMessage);
  const loading = useAppSelector((state) => state.account.loading);

  const [initialState, setInitialState] = useState(null);
  const dispatch = useAppDispatch();
  const initialValues = org;

  useEffect(() => {
    dispatch(GetOrgProfileThunk());
    if (!initialState) setInitialState(initialValues);
  }, []);

  const updateOrgProfile = (payload: IOrg) => dispatch(UpdateOrgProfileThunk(payload));
  const onFormSubmit = (values: any) => Helpers.submit(values, updateOrgProfile);
  return (
    <Box>
      <Typography variant="h4">Company Info</Typography>
      <LoadingSpinner loading={loading}>
        <Form
          onSubmit={onFormSubmit}
          keepDirtyOnReinitialize
          initialValues={initialState}
          render={({ hasSubmitErrors, modifiedSinceLastSubmit, submitting, pristine, submitError, submitSucceeded, handleSubmit }) => (
            <form
              style={styles.form}
              onSubmit={handleSubmit}
            >
              <TextField
                label="Company Name"
                name="companyName"
                validate={Validators.required}
              />
              <TextField
                label="Phone Number"
                name="phoneNumber"
                validate={Validators.required}
              />
              <TextField
                label="Address 1"
                name="address.addressLine1"
                validate={Validators.required}
              />
              <TextField
                label="Address 2"
                name="address.addressLine2"
              />
              <Box sx={styles.formRow2}>
                <TextField
                  label="City"
                  name="address.city"
                  validate={Validators.required}
                />
                <Select
                  label="State"
                  name="address.state"
                  values={states}
                  validate={Validators.required}
                />
                <TextField
                  label="Zip Code"
                  name="address.zipCode"
                  validate={Validators.required}
                />
              </Box>
              <Collapse in={Boolean(hasSubmitErrors && !modifiedSinceLastSubmit)}>
                <SnackbarContent
                  sx={styles.errorSnackbar}
                  message={(
                    <Box sx={styles.snackbarContent}>
                      <ErrorIcon />
                      {submitError}
                    </Box>
                  )}
                />
              </Collapse>
              <Collapse in={!submitting && submitSucceeded}>
                <SnackbarContent
                  sx={styles.successSnackbar}
                  message={(
                    <Box sx={styles.snackbarContent}>
                      <CheckCircleIcon />
                      {updateCompanyMessage}
                    </Box>
                  )}
                />
              </Collapse>
              <Button
                variant="contained"
                color="primary"
                sx={styles.submitButton}
                type="submit"
                disabled={pristine || (hasSubmitErrors && !modifiedSinceLastSubmit) || submitting}
              >
                <Fade in={submitting}>
                  <CircularProgress size={24} sx={styles.buttonProgress} />
                </Fade>
                <Fade in={!submitting}>
                  <span>Update Company Info</span>
                </Fade>
              </Button>
            </form>
          )}
        />
      </LoadingSpinner>
    </Box>
  );
};

export default AccountGeneralOrg;
