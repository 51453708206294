// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  addIcon: {
    marginRight: theme.spacing(1),
    height: '1.5em',
    fontSize: '32px',
    color: 'rgba(0, 0, 0, 0.35)',
  },
  buttonText: {
    textTransform: 'capitalize',
    letterSpacing: '0.75px',
  },
  button: {
    padding: '0px',
    height: '50px',
    '&:hover': {
      backgroundColor: 'white !important',
    },
  },
});

export default styles;
