// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  info: {
    position: 'absolute',
    margin: '0px auto',
    width: '100%',
    padding: '8px',
    bottom: '0%',
    height: '35px',
    textAlign: 'center',
    background: '#fff',
    zIndex: 5,
  },
  tooltip: {
    fontSize: '14px',
    whiteSpace: 'pre-line',
  },
  languageIcon: {
    verticalAlign: 'middle',
    marginRight: '5px',
  },
  latLng: {
    marginRight: '25px',
    display: 'inline-block',
  },
  latValue: {
    marginRight: '25px',
  },
  lngValue: {
    marginRight: '25px',
  },
  geogCS: {
    marginRight: '5px',
  },
  coordinates: {
    marginLeft: '25px',
  },
  coordinatesIcon: {
    width: '20px',
    verticalAlign: 'middle',
  },
});

export default styles;
