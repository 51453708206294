// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { TransitionProps } from '@mui/material/transitions/transition';
import { Dialog, Slide } from '@mui/material';
import OrderForm from './OrderForm';

const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => <Slide ref={ref} direction="up" {...props} />);

interface OrderDialogProps {
  orderFormVisible: boolean;
  setOrderFormVisible: (visible: boolean) => void;
}

const OrderDialog = ({ orderFormVisible, setOrderFormVisible }: OrderDialogProps) => (
  <Dialog
    fullScreen
    open={orderFormVisible}
    TransitionComponent={Transition}
  >
    <OrderForm {...{ orderFormVisible, setOrderFormVisible }} />
  </Dialog>
);

export default OrderDialog;
