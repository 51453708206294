// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  columnHeader: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(),
    paddingTop: theme.spacing(),
  },
  headerText: {
    display: 'inline',
    fontSize: '22px',
    lineHeight: '25px',
    color: '#1C364B',
  },
  headerRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.spacing(2),
  },
  planDetails: {
    fontSize: '16px',
    lineHeight: '19px',
    marginTop: theme.spacing(2),
    color: '#484D52',
    marginRight: theme.spacing(1),
  },
  planOptions: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
  },
  addNewUser: {
    boxShadow: 'none',
    borderRadius: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(4),
  },
  addUserButton: {
    color: 'white',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(1),
    width: 32,
    height: 32,
    fontSize: '16px',
  },
  nameColumn: {
    display: 'flex',
    alignItems: 'center',
  },
  table: {
    backgroundColor: 'inherit',
    marginBottom: theme.spacing(3),
    cursor: 'pointer',
  },
  pageWrapper: {
    width: '90%',
    margin: '0 auto',
    marginTop: theme.spacing(8),
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  iconButtons: {
    textAlign: 'right',
  },
  snackBar: {
    top: '140px',
  },
  alert: {
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
  },
  error: {
    display: 'flex',
    alignItems: 'center',
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    cursor: 'pointer',
  },
  icon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    cursor: 'pointer',
  },
  onlyTeam: {
    color: '#2E4DEB',
  },
  unOrderList: {
    paddingRight: theme.spacing(3),
  },
});

export default styles;
