// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const getBanner = () => localStorage.getItem('warningBanner');

const setBanner = (banner: string) => sessionStorage.setItem('warningBanner', banner);

const clearSessionStorage = () => {
  sessionStorage.removeItem('warningBanner');
};

export {
  getBanner,
  setBanner,
  clearSessionStorage,
};
