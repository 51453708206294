// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const useStyles = (theme: any) => ({
  dialog: {
    maxWidth: '540px',
    margin: 'auto',
  },
  dialogContent: {
    padding: '24px 32px',
  },
  dialogHeader: {
    fontSize: '20px',
  },
  dialogText: {
    marginTop: '12px',
    padding: '0px',
  },
  textField: {
    fontWeight: 300,
    letterSpacing: '1.3px',
    maxWidth: '150px',
    fontSize: '14px',
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(2),
    },
    '& fieldset': {
      borderRadius: 0,
      borderColor: '#EAEAEA',
    },
  },
  deliveryDate: {
    color: '#01a1dd',
  },
  expressButtonProgress: {
    color: '#fff',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-8px',
    marginLeft: '-8px',
  },
  dialogActions: {
    marginTop: theme.spacing(2),
  },
  textForm: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '20px',
    marginTop: theme.spacing(3),
  },
  link: {
    color: '#01A1DD',
  },
});

export default useStyles;
