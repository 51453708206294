// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { ReactElement } from 'react';
import { Box, useTheme } from '@mui/system';
import { Dialog, DialogActions, Button, Typography } from '@mui/material/';
import ErrorIcon from '@mui/icons-material/Error';
import stylesDef from './styles';

interface ErrorDialogProps {
  showDialog: boolean;
  onAccept?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onCancel?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  error: boolean;
  titleText: string;
  acceptText?: string;
  cancelText: string;
  children?: ReactElement;
}

export const ErrorDialog = ({ showDialog, error, onAccept, onCancel, titleText, acceptText, cancelText, children }: ErrorDialogProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);

  return (
    <Dialog open={showDialog} sx={styles.dialog}>
      <Box sx={styles.dialogBox}>
        <Box sx={styles.dialogTitle}>
          <ErrorIcon sx={error ? styles.errorIcon : styles.warningIcon} />
          <Typography variant="h3" sx={styles.titleText}>{titleText}</Typography>
        </Box>
        {children}
        <DialogActions sx={styles.dialogActions}>
          {acceptText && <Button onClick={onAccept} sx={styles.closeButton}>{acceptText}</Button>}
          <Button onClick={onCancel} sx={styles.closeButton}>{cancelText}</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

ErrorDialog.defaultProps = {
  onAccept: () => {},
  onCancel: () => {},
  acceptText: '',
  children: null,
};

export default ErrorDialog;
