// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  errorPaper: {
    position: 'relative',
    padding: theme.spacing(2),
    width: '900px',
  },
  errorControls: {
    display: 'grid',
    gridTemplateColumns: 'auto auto 5fr 1fr',
    gridGap: theme.spacing(1),
    alignItems: 'center',
    paddingRight: theme.spacing(2),
  },
  errorIcon: {
    color: '#F95264',
  },
  warningIcon: {
    color: '#FAA738',
  },
  tileLoadingInfo: {
    display: 'flex',
    columnGap: theme.spacing(2),
    alignItems: 'center',
  },
  loadingIcon: {
    color: '#FAA738',
  },
  loadingInfoText: {
    fontWeight: 400,
  },
  errorList: {
    padding: theme.spacing(2),
  },
  errorStacks: {
    position: 'absolute',
    top: theme.spacing(3),
    left: theme.spacing(3),
    gap: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  closeIcon: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
  },
  link: {
    color: 'inherit',
    textDecoration: 'underline',
  },
});

export default styles;
