// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';
import * as CommonSelectors from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';
import createVertex from '@mapbox/mapbox-gl-draw/src/lib/create_vertex';
import { curveToCoords, regularizeMidpoints } from 'WktCurves';

const {
  addPointTovertices,
  createSnapList,
  snap,
} = require('mapbox-gl-draw-snap-mode/src/utils/index');

const DrawCompoundCurve = {};

DrawCompoundCurve.useExistingFeature = function ({
  backwards,
  featureId,
} = {}) {
  let line;
  const state = {};
  if (featureId) {
    line = this.getFeature(featureId);
    if (!line) {
      throw new Error('Could not find a feature with the provided featureId');
    }
    if (!line.properties.curve) {
      throw new Error(
        'Cannot continue drawing a feature which is not a compoundCurve',
      );
    }
    state.line = line;
    state.curve = JSON.parse(JSON.stringify(line.properties.curve));
    if (backwards) {
      state.backwards = true;
      state.arcPoints = line.coordinates.slice(0, 1);
    } else {
      state.arcPoints = line.coordinates.slice(-1);
    }
    // ?s - what is the purpose of this deleteFeature
    // this.deleteFeature([featureId]);
  }
  return state;
};

/*
Supported options:
  - featureId: id of existing line (with curve property) to extend
  - backwards: true if the existing feature should be continued backwards from the start
  - curving: true if user wants to draw an arc, false if they are drawing a polyline
*/
DrawCompoundCurve.onSetup = function (opts) {
  const blankLine = () =>
    this.newFeature({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: [],
      },
    });

  // Create new empty 'arc' feature and 'line' features
  const arcFeature = blankLine();
  const line = blankLine();
  // Add line feature to the map
  this.addFeature(line);

  // Get existing selected features and clear/unselect them(so that the feature we are currently drawing is the only selected feature?)
  const selectedFeatures = this.getSelected();
  this.clearSelectedFeatures();
  doubleClickZoom.disable(this);
  this.activateUIButton(Constants.types.LINE);

  // Indicate that trash is possible with current mode
  // draw.actionable is fired as the state of Draw changes to enable and disable different actions. Following this event will enable you know if draw.trash() will have an effect.
  this.setActionableState({
    trash: true,
  });

  // Snapping functionality
  // snapList - list of features the current drawing can snap to
  // vertices - vertices of the features in the snapList
  const [snapList, vertices] = createSnapList(this.map, this._ctx.api, line);

  // Add arc feature to the map
  this.addFeature(arcFeature);

  const state = {
    map: this.map,
    curve: ['compoundcurve'], // short representation of whole path, for serialising to WKT
    line, // geojson version of curve except for current arc
    curving: opts.curving, // is the user wanting to draw straight or curved element
    arcFeature, // visual representation of the current arc or line segment (tied to mouse)
    arcPoints: [], // control points for the current arc (3 points = complete arc)
    backwards: false, // if true, we are adding new elements at the start of the curve
    snapList,
    vertices,
    selectedFeatures,
    ...this.useExistingFeature(opts),
  };

  // Why is this done here?
  this.updateLineFromCompoundCurve(state);

  state.options = this._ctx.options;
  this.updateUIClasses({ mouse: Constants.cursors.ADD });
  this._ctx.ui.queueMapClasses({ mouse: Constants.cursors.ADD });

  const moveendCallback = () => {
    const [snapListCB, verticesCB] = createSnapList(this.map, this._ctx.api, line);
    state.vertices = verticesCB;
    state.snapList = snapListCB;
  };
  // for removing listener later on close
  state.moveendCallback = moveendCallback;

  const optionsChangedCallBAck = (options) => {
    state.options = options;
  };
  // for removing listener later on close
  state.optionsChangedCallBAck = optionsChangedCallBAck;

  this.map.on('moveend', moveendCallback);
  this.map.on('draw.snap.options_changed', optionsChangedCallBAck);
  return state;
};

DrawCompoundCurve.onMouseMove = function (state, e) {
  // Currently can look bad as we draw a weird "curve" down to the south pole and back, like two parallel lines
  const { lng, lat } = snap(state, e);
  const coord = [lng, lat];
  state.snappedLng = lng;
  state.snappedLat = lat;
  if (state.arcPoints.length === 1) {
    // straight line from fixed point to mouse point, whether in curve mode or not
    state.arcFeature.setCoordinates([...state.arcPoints, coord]);
  } else if (state.arcPoints.length === 2) {
    // curved line through 2 fixed points and one mouse point
    state.arcFeature.setCoordinates(
      curveToCoords(['circularstring', ...state.arcPoints, coord], {
        steps: 64,
      }),
    );
  }
  if (
    e.featureTarget &&
    (e.featureTarget.properties.first || e.featureTarget.properties.last)
  ) {
    this.updateUIClasses({ mouse: Constants.cursors.POINTER });
  }
};

// recompute the primary line feature from the curve
DrawCompoundCurve.updateLineFromCompoundCurve = function (state) {
  // Line geometry coordinates generated for the curve representation of the line
  state.line.setCoordinates(curveToCoords(state.curve, { steps: 64 }));
  // Curve representation saved in the curve property of the line
  state.line.setProperty('curve', state.curve);
};

DrawCompoundCurve.onStop = function (state) {
  // mostly copied from draw_line_string
  doubleClickZoom.enable(this);
  this.activateUIButton();

  // get rid of half-drawn arcs
  this.deleteFeature([state.arcFeature.id]);
  // check to see if we've deleted this feature
  if (this.getFeature(state.line.id) === undefined) return;

  state.curve = regularizeMidpoints(state.curve);
  this.updateLineFromCompoundCurve(state);

  if (state.line.isValid()) {
    this.map.fire(Constants.events.CREATE, {
      features: [state.line.toGeoJSON()],
    });
  } else {
    this.deleteFeature([state.line.id], {
      silent: true,
    });
    // remove moveemd callback
    this.map.off('moveend', state.moveendCallback);

    // This relies on the the state of PolyLineMode being similar to draw_line_string
    DrawCompoundCurve.onStop.call(this, state);
    this.changeMode(Constants.modes.SIMPLE_SELECT, {}, { silent: true });
  }
};

DrawCompoundCurve.onTrash = function (state) {
  this.deleteFeature([state.line.id, state.arcFeature.id], {
    silent: true,
  });
  this.map.off('moveend', state.moveendCallback);
  this.changeMode(Constants.modes.SIMPLE_SELECT);
};

DrawCompoundCurve.finish = function (state) {
  this.changeMode(Constants.modes.SIMPLE_SELECT, {
    featureIds: [state.line.id],
  });
};

DrawCompoundCurve.addCoord = function (state, coord) {
  if (state.curving) {
    state.arcPoints.push(coord);
    if (state.arcPoints.length === 3) {
      // add arc to stored curve and redraw
      if (!state.backwards) {
        state.curve.push(['circularstring', ...state.arcPoints.slice(-3)]);
      } else {
        state.curve.splice(1, 0, [
          'circularstring',
          ...state.arcPoints.slice(-3).reverse(),
        ]);
      }

      this.updateLineFromCompoundCurve(state);
      // remove control points
      state.arcPoints = [coord];
      state.arcFeature.setCoordinates([]);
    }
  } else {
    if (state.curve.length === 1 && state.arcPoints.length) {
      // we haven't stored our initial anchor point yet
      this.addLineCoord(state, state.arcPoints[0]);
    }
    this.addLineCoord(state, coord);
  }
};

/* Adds a coordinate of a straight line segment to our curve, creating a new `linestring` element
   and duplicating a neighbouring curve coordinate as necessary. */
DrawCompoundCurve.addLineCoord = function (state, coord) {
  const insert = (array, item) => {
    if (state.backwards) {
      array.splice(1, 0, item);
    } else {
      array.push(item);
    }
  };

  const currentElement = state.backwards
    ? state.curve[1]
    : state.curve[state.curve.length - 1];
  let lineStringElement = ['linestring'];

  if (currentElement[0] === 'linestring') {
    // extend current straight linestring
    lineStringElement = currentElement;
  } else {
    insert(state.curve, lineStringElement);
    if (currentElement[0] === 'circularstring') {
      const currentCoord = state.backwards
        ? currentElement.slice(1, 2)[0]
        : currentElement.slice(-1)[0];
      // start a new linestring, repeating the most recent coordinate of the most recent arc
      insert(lineStringElement, [...currentCoord]); // clone the coordinate, don't hotlink
    }
  }
  insert(lineStringElement, coord);
  state.line.setProperty('curve', state.curve);
  this.updateLineFromCompoundCurve(state);
  // start next arc where this line finishes
  state.arcPoints = [coord];
};

DrawCompoundCurve.clickOnVertex = function (state, e) {
  if (
    (e.featureTarget.properties.first && state.backwards) ||
    (e.featureTarget.properties.last && !state.backwards)
  ) {
    // double clicked on point to stop drawing
    this.updateLineFromCompoundCurve(state);
  } else if (e.featureTarget.properties.first) {
    // closed loop back to start
    DrawCompoundCurve.addCoord(state, state.line.coordinates[0]);
  } else if (e.featureTarget.properties.last) {
    // closed loop, backwards to end
    DrawCompoundCurve.addCoord(
      state,
      state.line.coordinates[state.line.coordinates.length - 1],
    );
  }
  this.finish(state);
};

DrawCompoundCurve.clickAnywhere = function (state, e) {
  const lng = state.snappedLng;
  const lat = state.snappedLat;
  const coord = [lng, lat];
  DrawCompoundCurve.addCoord(state, coord);
  this.updateUIClasses({ mouse: Constants.cursors.ADD });
};

// from DrawLineString
DrawCompoundCurve.onClick = function (state, e) {
  const lng = state.snappedLng;
  const lat = state.snappedLat;

  if (CommonSelectors.isVertex(e)) {
    this.clickOnVertex(state, e);
  } else {
    this.clickAnywhere(state, e);
  }

  addPointTovertices(state.map, state.vertices, { lng, lat });
};
DrawCompoundCurve.onTap = DrawCompoundCurve.onClick;

DrawCompoundCurve.toDisplayFeatures = function (state, geojson, display) {
  const isActive = [state.line.id, state.arcFeature.id].includes(
    geojson.properties.id,
  );
  const isActiveLine =
    geojson.properties.id === state.line.id ||
    geojson.properties.id === state.arcFeature.id;

  geojson.properties.active = isActive ? Constants.activeStates.ACTIVE : Constants.activeStates.INACTIVE;

  if (!isActiveLine) {
    display(geojson);
    return;
  }

  if (geojson.geometry.coordinates.length < 2) {
    return;
  }
  geojson.properties.meta = Constants.meta.FEATURE;
  if (state.arcPoints.length > 0) {
    state.arcPoints.forEach((coord, i) => {
      const vertex = createVertex(state.arcFeature.id, coord, String(i), false);
      display(vertex);
    });
  }

  if (state.line.coordinates.length > 1) {
    // create a vertex at the opposite end from where we started so the user can close the polygon
    let vertex;
    if (!state.backwards) {
      vertex = createVertex(
        state.line.id,
        state.line.coordinates[0],
        '', // does it matter?
        false,
      );
      vertex.properties.first = true;
    } else {
      vertex = createVertex(
        state.line.id,
        state.line.coordinates[state.line.coordinates.length - 1],
        '',
        false,
      );
      vertex.properties.last = true;
    }

    display(vertex);
  }

  display(geojson);
};

DrawCompoundCurve.onKeyUp = function (state, e) {
  if (e.key === 'c') {
    state.curving = !state.curving;
    if (!state.curving) {
      if (state.arcPoints.length === 2) {
        this.addLineCoord(state, state.arcPoints[1]);
      }
      state.arcFeature.setCoordinates([]);
    }
  } else if (CommonSelectors.isEnterKey(e)) {
    // cleanup happens in onStop
    this.changeMode(Constants.modes.SIMPLE_SELECT, {
      featureIds: [state.line.id],
    });
  } else if (CommonSelectors.isEscapeKey(e)) {
    this.deleteFeature([state.line.id], { silent: true });
    this.changeMode(Constants.modes.SIMPLE_SELECT);
  }
};

// window.ccm = DrawCompoundCurve;

export default DrawCompoundCurve;
