// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useRef, useState } from 'react';
import { Dialog, Typography, Button, Collapse, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions/transition';
import { Box, useTheme, ThemeProvider } from '@mui/system';
import { darkTheme } from 'Utils/themes';
import { Link } from 'react-router-dom';
import logo from 'Assets/logo_small.png';
import { TextField, Validators, Helpers } from 'Components/forms';
import { Form } from 'react-final-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { ForgotPasswordThunk } from '../authThunk';
import { sendForgotPasswordLink } from '../authSlice';
import stylesDef from './styles';

const Transition = React.forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) =>
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Slide direction="up" ref={ref} {...props} />);

const ForgotPassword = () => {
  const { forgotPasswordLinkSend } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const styles = stylesDef(theme);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const captchaRef = useRef(null);
  const onFormSubmit = async (values: any) => {
    values.captchaToken = await captchaRef.current.getValue();
    const forgotPassword = (payload: any) => dispatch(ForgotPasswordThunk(payload));
    Helpers.submit(values, forgotPassword);
    captchaRef.current.reset();
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Dialog
        fullScreen
        open
        TransitionComponent={Transition}
        sx={{ '& .MuiDialog-paper': styles.dialogPaper }}
      >
        <Box sx={styles.dialog}>
          <img src={logo} alt="AirWorks small logo" />
          {forgotPasswordLinkSend ? (
            <>
              <Typography sx={styles.header} variant="h1">
                Check your Email
              </Typography>
              <Typography sx={styles.successMessage}>
                A password reset link has been emailed to the email address you entered. If you don't receive
                an email, be sure to check your spam and that you entered the email associated with your account.
              </Typography>
              <Button variant="outlined" sx={styles.button} onClick={() => sendForgotPasswordLink(false)}>
                RESEND LINK
              </Button>
            </>
          ) : (
            <>
              <Typography sx={styles.header} variant="h1">
                Forgot Password
              </Typography>
              <Typography sx={styles.details}>
                Enter the email address associated with this account and
                we'll send you a link to reset your password.
              </Typography>
              <Form
                onSubmit={onFormSubmit}
                render={({ hasSubmitErrors, modifiedSinceLastSubmit, submitting, handleSubmit }) => (
                  <form
                    style={styles.form}
                    onSubmit={handleSubmit}
                  >
                    <TextField
                      name="email"
                      label="Email Address"
                      autoComplete="email"
                      autoFocus
                      validate={Validators.composeValidators(Validators.required, Validators.email)}
                      variant="standard"
                      sx={styles.textField}
                    />

                    <Collapse in={Boolean(hasSubmitErrors && !modifiedSinceLastSubmit)}>
                      <Typography variant="body1" sx={styles.errorMessage}>
                        Oops! We weren’t able to send you a reset link, please enter your email and try again.
                      </Typography>
                    </Collapse>
                    <Box sx={styles.captcha}>
                      <ReCAPTCHA
                        sitekey={_GOOGLE_CAPTCHA_SITE_KEY_}
                        onChange={() => setDisableSubmit(false)}
                        ref={captchaRef}
                      />
                    </Box>
                    <Box sx={styles.buttonWrapper}>
                      <Button
                        type="submit"
                        variant="outlined"
                        sx={styles.button}
                        disabled={(hasSubmitErrors && !modifiedSinceLastSubmit) || submitting || disableSubmit}
                      >
                        Reset Password
                      </Button>
                      <Button component={Link} to="/" sx={styles.button2}>BACK TO SIGN IN</Button>
                    </Box>
                  </form>
                )}
              />
            </>
          )}
        </Box>
      </Dialog>
    </ThemeProvider>
  );
};

export default ForgotPassword;
