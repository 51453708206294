/* eslint-disable import/prefer-default-export */
// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createSelector } from 'reselect';
import { RootState } from 'Store';

const selectSelectedAdditionals = (state: RootState) => state.bundle.selectedAdditionals;

const selectSelectedBundle = createSelector(
  [selectSelectedAdditionals],
  (selectedAdditionals) => {
    const extraDeliverablesLength = selectedAdditionals.filter((u) => u.type === 'deliverable').length;
    const extraLayersLength = selectedAdditionals.filter((u) => u.type === 'layer').length;
    const addOnCount = extraDeliverablesLength + extraLayersLength;
    return {
      extraDeliverablesLength,
      extraLayersLength,
      addOnCount,
    };
  },
);

export {
  selectSelectedBundle,
};
