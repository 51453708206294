// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dispatch } from 'redux';
import { API_URL } from 'Config';
import { postOpsFile, postJson } from 'Utils/http';
import { RootState } from 'Store';
import { UpdateOrderCadFiles } from 'Features/order/orderThunk';
import { UploadDxfLayersStartAction, InitializeTrainingProjectStartAction, InitializeTrainingProjectFailureAction } from './opsActions';

export const UploadDxfLayersThunk = (notes: string, file: any) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { orderId } = getState().order;
    const { _id, ownerOrganization } = getState().project.project;
    const url = `${API_URL}/opsusers/uploadLabeledFileFromClientOpsTrain/${ownerOrganization}/${_id}/${orderId}`;

    const { token } = getState().auth;

    dispatch(UploadDxfLayersStartAction());
    const _file = file;
    const { name } = _file;
    const fieldName = 'labeledFile';
    const result = postOpsFile(url, notes, token, _file, name, fieldName);

    result.onreadystatechange = () => {
      if (result.readyState === XMLHttpRequest.DONE) {
        const resParse = JSON.parse(result.responseText);
        UpdateOrderCadFiles(resParse.newCadFile)(dispatch);
      }
    };
  };

export const InitializeTrainingProjectThunk = (orderId: string, newProjectName: string, dxfDropboxLink: string, tifDropboxLink: string, kmlDropboxLink: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { _id, ownerOrganization } = getState().project.project;
    const url = `${API_URL}/opsusers/initializeTrainingProject/${ownerOrganization}/${_id}/${orderId}`;

    const { token } = getState().auth;

    dispatch(InitializeTrainingProjectStartAction());

    const data = {
      newProjectName,
      dxfDropboxLink,
      tifDropboxLink,
      kmlDropboxLink,
    };

    const result = await postJson<{imageryRequest: IImageryRequest}>(url, data, token);
    if (!result.success) {
      dispatch(InitializeTrainingProjectFailureAction(result.message));
    }
  };
