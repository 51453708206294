// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createSlice, configureStore } from '@reduxjs/toolkit';
import { InitAuthFailedAction, InitAuthSuccessAction, Login, LogoutAction, OktaAdminLoginSuccessAction, OnAcceptTnCAction, SetShowTokenExpiredDialogAction } from './authActions';

interface authState {
    token: string | null,
    tokenExpires: number | null,
    user: any,
    html: string | null,
    resources: any,
    planDetails: any,
    failedLoginAttempts: number,
    forgotPasswordLinkSend: boolean,
    showTokenExpiredDialog: boolean,
}

const initialState: authState = {
  token: null,
  tokenExpires: null,
  user: null,
  html: null,
  resources: null,
  planDetails: null,
  failedLoginAttempts: 0,
  forgotPasswordLinkSend: false,
  showTokenExpiredDialog: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLogin: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.tokenExpires = action.payload.tokenExpires;
      state.resources = action.payload.resources;
      state.planDetails = action.payload.planDetails;
      state.failedLoginAttempts = 0;
    },
    sendForgotPasswordLink: (state, action) => {
      state.forgotPasswordLinkSend = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(Login.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.html = action.payload.html;
        state.token = action.payload.token;
        state.tokenExpires = action.payload.tokenExpires;
        state.resources = action.payload.resources;
        state.planDetails = action.payload.planDetails;
        state.failedLoginAttempts = 0;
      })
      .addCase(Login.rejected, (state, action) => {
        if (action.payload && action.payload.failedLoginAttempts) {
          state.failedLoginAttempts = action.payload.failedLoginAttempts;
        }
      })
      .addCase(LogoutAction, (state) => {
        state.user = null;
        state.token = null;
      })
      .addCase(OnAcceptTnCAction, (state, action) => {
        state.user = action.payload.user;
        state.html = null;
      })
      .addCase(InitAuthSuccessAction, (state, action) => {
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.tokenExpires = action.payload.tokenExpires;
        state.html = action.payload.html;
        state.resources = action.payload.resources;
        state.failedLoginAttempts = action.payload.failedLoginAttempts;
      })
      .addCase(InitAuthFailedAction, (state, action) => {
        state.failedLoginAttempts = action.payload;
      })
      .addCase(OktaAdminLoginSuccessAction, (state, action) => {
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.tokenExpires = action.payload.tokenExpires;
        state.resources = action.payload.resources;
      })
      .addCase(SetShowTokenExpiredDialogAction, (state, action) => {
        state.showTokenExpiredDialog = action.payload;
      });
  },
});

export const { actions, reducer } = authSlice;

export const { setLogin, sendForgotPasswordLink } = actions;

export default reducer;
