// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import type { RootState } from 'Store';

const getOrg = (state: RootState) => state.account.org;
const getSelectedBundle = (state: RootState) => state.bundle.selectedBundle;

const selectCurrentSubscription = createSelector(
  [getOrg, getSelectedBundle],
  (org, selectedBundle) => {
    const totalAcreage = +(org.availableCreditAcreage + org.usedCreditAcreage).toFixed(2);
    const { usedCreditAcreage } = org;
    const adjustedAcres = selectedBundle?.adjustedOrderAcres || 0;

    const remainingCreditAcreage = +(org.availableCreditAcreage - adjustedAcres).toFixed(2);
    const usedPercent = (usedCreditAcreage / totalAcreage) * 100;
    const noAcreage = usedPercent > 100;

    const result = {
      totalAcreage,
      usedCreditAcreage,
      remainingCreditAcreage: remainingCreditAcreage > 0 ? remainingCreditAcreage : 0,
      usedPercent: usedPercent > 100 ? 100 : usedPercent,
      noAcreage,
    };

    return result;
  },
);

export { selectCurrentSubscription };
