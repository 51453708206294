// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  pageControls: {
    width: '100%',
    position: 'fixed',
    minHeight: '80px',
    maxHeight: '80px',
    background: 'white',
    zIndex: 2,
  },
  pageControlsSub: {
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gridColumnGap: theme.spacing(4),
    gridTemplateAreas: '"backButton breadcrumbs close"',
  },
  pageWrapper: {
    height: '100%',
    marginTop: '80px',
    width: '1300px',
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'grid',
    justifyContent: 'center',
    gridTemplateColumns: '8fr 6fr',
    gridColumnGap: '100px',
  },
  closeButtonWrapper: {
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(4),
    gridArea: 'close',
    justifySelf: 'end',
    zIndex: 2000,
    color: 'gray',
  },
  backButton: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    gridArea: 'backButton',
    justifySelf: 'start',
    zIndex: 2000,
    color: 'gray',
    width: theme.spacing(8),
  },
  tabs: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto auto auto',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(3),
    gridArea: 'breadcrumbs',
    justifySelf: 'start',
  },
  chevron: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  activeTabName: {
    fontWeight: 400,
  },
  summaryColumn: {
    marginTop: theme.spacing(3),
  },
  subtitleText: {
    marginTop: theme.spacing(2),
  },
  textField: {
    border: 'solid 1px #EAEAEA',
    height: '200px',
    margin: '16px 48px 16px 0px',
    padding: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  editOrderDivider: {
    marginBottom: theme.spacing(1),
  },
  subHeader: {
    marginTop: theme.spacing(2),
  },
  summaryText: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  checkBox: {
    marginBottom: theme.spacing(3),
  },
  checkBoxText: {
    fontWeight: 400,
  },
  confirmHeader: {
    marginBottom: theme.spacing(4),
  },
  orderInfo: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
    justifySelf: 'left',
    '&>*:nth-child(2n)': {
      textAlign: 'right',
      justifySelf: 'right',
    },
    marginBottom: theme.spacing(3),
  },
  inputText: {
    maxHeight: '168px',
    overflow: 'scroll',
  },
  button: {
    position: 'relative',
    marginLeft: '83%',
    marginRight: theme.spacing(3),
  },
  submitButton: {
    position: 'relative',
    marginLeft: '80%',
    marginTop: theme.spacing(2),
    width: '140px',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-12px',
    marginLeft: '-12px',
  },
  errorMessage: {
    paddingLeft: theme.spacing(1),
  },
  bold: {
    fontWeight: 400,
  },
  filesList: {
    paddingLeft: theme.spacing(1),
    fontWeight: 400,
  },
  epsgErrorMessage: {
    paddingLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  errorText: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: 'black',
  },
  errorFile: {
    fontWeight: 400,
    display: 'inline',
  },
  errorItem: {
    backgroundColor: 'rgba(171,182,193,0.14)',
    padding: '8px 16px',
    borderLeft: '5px solid #ABB6C1',
    marginBottom: theme.spacing(1),
  },
  link: {
    color: '#01A1DD',
    textDecoration: 'underline',
  },
});

export default styles;
