// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'Hooks';
import LoadingSpinner from 'Components/LoadingSpinner';
import CreateMap from 'Features/map';
import UploadDialog from 'Components/FileUploadForm/SingleFileUploadForm';
import OpsTrainingDialogForm from 'Components/FileUploadForm/OpsTrainerFileUpload';
import EpsgProjection from 'Components/EpsgProjection';
import { SetOrderSelectedAction } from '../map/mapEditorActions';
import { SetProjectIdAction } from '../project/projectActions';
import { GetProjectThunk } from '../project/projectThunk';
import { GetOrdersThunk } from '../order/orderThunk';
import Sidebar from './Sidebar';
import MeasurementOptions from './Sidebar/MeasurementOptions';

import stylesDef from './styles';

const Files = () => {
  const styles = stylesDef();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  const onLoad = async () => {
    const projectId = id;
    dispatch(SetProjectIdAction(projectId));
    await dispatch(GetOrdersThunk(projectId));
    await dispatch(GetProjectThunk());
    setLoading(false);
  };
  useEffect(() => {
    onLoad();
    return () => {
      dispatch(SetProjectIdAction(null));
      dispatch(SetOrderSelectedAction(null));
    };
  }, []);

  return (
    <LoadingSpinner loading={loading}>
      <Sidebar />
      <Box sx={styles.map} id="uploadDxfFormContainer">
        <MeasurementOptions />
        <CreateMap mode="layers" />
        <EpsgProjection />
      </Box>
      <OpsTrainingDialogForm />
      <UploadDialog adminOutputUpload={false} fileExtension={['.dxf']} containerId="uploadDxfFormContainer" />
    </LoadingSpinner>
  );
};

export default Files;
