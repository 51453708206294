// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = () => ({
  fontColor: {
    color: '#01a1dd',
  },
  planContainer: {
    position: 'absolute',
    top: 0,
    left: '500px',
    width: 'calc( 100vw - 500px )',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  planCard: {
    background: 'white',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px',
    position: 'relative',
    height: '450px',
    width: '450px',
    color: '#021E40',
  },
  planDetail: {
    fontWeight: 600,
    margin: '15px 0',
  },
  list: {
    margin: '10px 0',
  },
  listItemIcon: {
    minWidth: '30px',
  },
  flashIcon: {
    margin: '20px 0',
  },
  checkIcon: {
    width: '20px',
  },
  planFooter: {
    position: 'absolute',
    bottom: 0,
    height: '60px',
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    backgroundColor: '#F8F8F8',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  planText: {
    fontSize: '15px',
    fontWeight: 400,
  },
  footerBar: {
    height: '15px',
    width: '400px',
    background: 'linear-gradient(90deg, #9038F5 0%, #3C34B7 33.29%, #2075FE 74.06%, #01A1DD 100%)',
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
  },
});

export default styles;
