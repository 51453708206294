// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { InView } from 'react-intersection-observer';
import { Typography, CircularProgress } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { useAppDispatch, useAppSelector } from 'Hooks';
import CreateMap from 'Features/map';
import PageToolbar from './PageToolbar';
import ProjectListItem from './ProjectListItem';
import TermsAndConditionsDialog from './Terms&ConditionsDialog';
import stylesDef from './styles';
import { SetPageAction } from './projectListActions';
import { GetProjectsThunk } from './projectListThunk';

const ProjectList = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const [showMapview, setShowMapview] = useState(false);
  const { projectsLoading, projectsCount, loadAll, page, list } = useAppSelector(
    (state) => state.projectList,
  );
  const { html } = useAppSelector(
    (state) => state.auth,
  );

  const showMapviewClick = () => {
    setShowMapview(true);
  };

  const showGridviewClick = () => {
    setShowMapview(false);
  };

  const showNoProjects = projectsCount === 0 && list.length === 0;
  const showEmptyList = !projectsLoading && projectsCount > 0 && list.length === 0;
  const wrapperStyle : {} = showMapview ? styles.contentWrapperMap : styles.contentWrapperGrid;
  const listStyle : {} = showMapview ? styles.mapList : styles.gridList;
  const listWrapperStyle : {} = showMapview ? styles.mapListWrapper : styles.gridListWrapper;

  return (
    <Box sx={wrapperStyle}>
      <Box sx={styles.dashboardContent}>
        {html !== undefined && html !== 'undefined' && html !== 'null' && html !== null && (<TermsAndConditionsDialog />)}
        <Box sx={styles.mapviewLayoutLeft}>
          <PageToolbar
            showMapview={showMapview}
            showMapviewClick={showMapviewClick}
            showGridviewClick={showGridviewClick}
          />
          <Box sx={listWrapperStyle}>
            {list.length > 0 && (
              <>
                <Box sx={listStyle}>
                  {list.map((project) => (
                    <ProjectListItem
                      showMapview={showMapview}
                      project={project}
                      key={project._id}
                    />
                  ))}
                </Box>
                <Box sx={styles.loading}>
                  {!projectsLoading && (loadAll ? (
                    <Typography variant="h3">End of List</Typography>
                  ) : (
                    <>
                      <InView onChange={(inView) => {
                        if (inView) {
                          dispatch(SetPageAction(page + 1));
                          dispatch(GetProjectsThunk());
                        }
                      }}
                      />
                      <Typography variant="h3">Loading more...</Typography>
                    </>
                  ))}
                </Box>
              </>
            )}
            <Box sx={styles.loading}>
              {projectsLoading && (<CircularProgress size={50} />)}
            </Box>
          </Box>
        </Box>
        {showMapview && (
          <Box sx={styles.mapviewLayoutRight}>
            <CreateMap mode="dashboard" />
          </Box>
        )}
        {showNoProjects && (
          <Typography variant="h1" sx={styles.noProjects}>
            You don&apos;t currently have any projects. Create a
            <br />
            project to get started!
          </Typography>
        )}
        {showEmptyList && (
          <Typography variant="h1" sx={styles.noProjects}>
            Project not found
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ProjectList;
