// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL
import React from 'react';
import WebFont from 'webfontloader';
import * as Sentry from '@sentry/browser';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import ErrorScreen from 'Components/ErrorScreen';
import { lightTheme } from 'Utils/themes';
// import BrowserHistory from 'Utils/history';
import history from 'Utils/history';
// import HubspotForm from 'react-hubspot-form'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import { InitAuthThunk } from 'Features/auth/authThunk';

import App from './App';
import store from './store';

if (_MODE_ === 'production') {
  Sentry.init({
    release: _VERSION_,
    dsn: 'https://47fa9213f6364dfe8a71e1cc423e8164@sentry.io/1525762',
    environment: _ENV_,
  });
}

WebFont.load({
  google: {
    families: ['Work+Sans:300,400,500,600'],
  },
});

InitAuthThunk()(store.dispatch);

const Wrapper = () => (
  <CssBaseline>
    <ErrorScreen>
    <Provider store={store}>
      <ThemeProvider theme={lightTheme}>
        <HistoryRouter history={history}>
            <App />
        </HistoryRouter>
      </ThemeProvider>
    </Provider>
    </ErrorScreen>
  </CssBaseline>
);

(async () => {
  const LDProvider = await asyncWithLDProvider({ clientSideID: _LAUNCHDARKLY_CLIENT_SIDE_ID });
  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(<LDProvider><Wrapper /></LDProvider>);
})();
