// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Box, useTheme } from '@mui/system';
import LayersPanel from './LayersPanel';
import ObjectProperties from './ObjectProperties';
import Autodraft from './Autodraft';
import Settings from './Settings';
import stylesDef from './styles';

export const Panel = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);

  return (
    <Box sx={styles.panelContent}>
      <LayersPanel />
      <ObjectProperties />
      <Autodraft />
      <Settings />
    </Box>
  );
};

export default Panel;
