// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Font, StyleSheet } from '@react-pdf/renderer';

Font.register({
  family: 'Work Sans',
  fonts: [
    { src: 'https://fonts.gstatic.com/s/worksans/v16/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32KxfXNig.ttf', fontWeight: 300 },
    { src: 'https://fonts.gstatic.com/s/worksans/v16/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nXNig.ttf', fontWeight: 400 },
    { src: 'https://fonts.gstatic.com/s/worksans/v16/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K3vXNig.ttf', fontWeight: 500 },
  ],
});

const styles = StyleSheet.create({
  container: {
    margin: '20px',
  },
  header: {
    height: '30px',
    borderBottom: '1px solid #DCDCDC',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  headerLeft: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerRight: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  logo: {
    width: '40px',
    height: '10px',
  },
  name: {
    fontFamily: 'Work Sans',
    marginLeft: '10px',
    fontSize: '10px',
    fontWeight: 400,
    letterSpacing: '4px',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
  },
  package: {
    width: '50%',
    paddingRight: '4px',
  },
  dates: {
    display: 'flex',
    flexDirection: 'row',
    margin: '6px 0',
  },
  bundle: {
    border: '1px solid #01A1DD',
    height: '50px',
    margin: '8px 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  bundleInfo: {
    display: 'flex',
    justifyContent: 'center',
  },
  bundleListItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '3px 0',
  },
  bundleItems: {
    backgroundColor: '#F8F8F8',
    height: '250px',
    display: 'flex',
    flexDirection: 'row',
    padding: '10px',
    margin: '6px 0',
  },
  bundleItemsContainer: {
    width: '50%',
    paddingRight: '10px',
  },
  addOnListItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '3px 0',
    flexGrow: 1,
    flexBasis: '85%',
    paddingRight: '10px',
  },
  addOnCost: {
    fontFamily: 'Work Sans',
    fontSize: '8px',
    padding: '3px 0',
    flexBasis: '10%',
    flexGrow: 1,
    marginLeft: '2px',
    textAlign: 'right',
  },
  mapColumn: {
    border: '1px solid #DCDCDC',
    width: '50%',
    padding: '4px 8px',
    marginLeft: '6px',
    maxHeight: '700px',
  },
  projectInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  projectInfoLeft: {
    width: '60%',
  },
  projectInfoRight: {
    width: '40%',
  },
  screenshot: {
    marginTop: '8px',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  orderInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: '8px',
  },
  line: {
    borderBottom: '0.6px solid #DCDCDC',
    margin: '8px 0',
  },
  icon: {
    width: '8px',
    height: '6px',
    marginRight: '5px',
    padding: '1px',
  },
  icon2: {
    width: '16px',
    height: '16px',
    margin: '0 20px',
    padding: '1px',
  },
  title1: {
    fontFamily: 'Work Sans',
    fontSize: '14px',
    fontWeight: 500,
    padding: '8px 0',
  },
  title2: {
    fontFamily: 'Work Sans',
    fontSize: '12px',
    fontWeight: 400,
    padding: '3px 0',
  },
  title3: {
    fontFamily: 'Work Sans',
    fontSize: '12px',
    fontWeight: 500,
    padding: '3px 0',
  },
  title4: {
    fontFamily: 'Work Sans',
    fontSize: '12px',
    fontWeight: 500,
    padding: '3px 0',
    color: '#01A1DD',
  },
  text1: {
    fontFamily: 'Work Sans',
    fontSize: '10px',
    padding: '1px 0',
    fontWeight: 300,
  },
  text1Cap: {
    fontFamily: 'Work Sans',
    fontSize: '10px',
    fontWeight: 300,
    textTransform: 'capitalize',
  },
  text2: {
    fontFamily: 'Work Sans',
    fontSize: '10px',
    padding: '3px 0',
    fontWeight: 400,
  },
  text3: {
    fontFamily: 'Work Sans',
    fontSize: '10px',
    padding: '3px 0',
    color: '#01A1DD',
  },
  text4: {
    fontFamily: 'Work Sans',
    fontSize: '8px',
    padding: '3px 0',
  },
  text4Cap: {
    fontFamily: 'Work Sans',
    fontSize: '8px',
    padding: '3px 0',
    textTransform: 'capitalize',
  },
  text5: {
    fontFamily: 'Work Sans',
    fontSize: '8px',
    padding: '3px 0',
    fontWeight: 300,
  },
  text5Cap: {
    fontFamily: 'Work Sans',
    fontSize: '8px',
    padding: '3px 0',
    fontWeight: 300,
    textTransform: 'capitalize',
  },
  text6: {
    fontFamily: 'Work Sans',
    fontSize: '10px',
    marginTop: '10px',
    marginBottom: '3px',
  },
  text7: {
    fontFamily: 'Work Sans',
    fontSize: '8px',
    margin: '8px 0',
    fontWeight: 300,
    color: '#BEBEBE',
  },
});

export default styles;
