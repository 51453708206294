// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createSlice } from '@reduxjs/toolkit';
import { GetOrgStatsStartAction, GetOrgStatsSuccessAction } from 'Features/account/accountActions';
import {
  CreateOrgFailureAction,
  CreateOrgStartAction,
  CreateOrgSuccessAction,
  DeleteOrgAction,
  DeleteProjectAdminSuccessAction,
  GetLastLoginFailureAction,
  GetLastLoginStartAction,
  GetLastLoginSuccessAction,
  GetLoginStatsSuccessAction,
  GetOrgAction,
  GetOrgProjectsFailureAction,
  GetOrgProjectsStartAction,
  GetOrgProjectsSuccessAction,
  GetPricingAction,
  GetSubscriptionFailureAction,
  GetSubscriptionStartAction,
  GetSubscriptionSuccessAction,
  GetUsersSuccessAction,
  LoadListStartAction,
  LoadListSuccessAction,
  OrgEmailsSuccessAction,
  SetListAction,
  SetLoadAllAction,
  SetLoadNewAction,
  SetOrgIdAction,
  SetOrgPricingFailureAction,
  SetOrgPricingStartAction,
  SetOrgPricingSuccessAction,
  SetPageAction,
  SetRowsPerPageAction,
  SetSearchFilterAction,
  SetSortFilterAction,
  SetUserIdAction,
  UpdateCostPerAcreAction,
  UpdateOrgSuccessAction,
} from './adminActions';

const initialState: IAdminState = {
  loading: false,
  loadAll: false,
  loadNew: true,
  searchFilter: '',
  sortFilter: 'companyName',
  page: 0,
  count: 0,
  rowsPerPage: 15,
  list: [],
  orgStats: null,
  orgId: null,
  userId: null,
  org: null,
  orgProjects: [],
  loginStats: null,
  usersList: [],
  user: null,
  automateSubscription: null,
  expressAISubscription: null,
  cadSubscription: null,
  createOrgSuccess: null,
  pricing: [],
  updatingOrgPricingPlan: false,
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetOrgAction, (state, action) => {
        state.org = action.payload;
      })
      .addCase(UpdateOrgSuccessAction, (state, action) => {
        state.org = action.payload;
      })
      .addCase(GetUsersSuccessAction, (state, action) => {
        state.usersList = action.payload;
      })
      .addCase(OrgEmailsSuccessAction, (state, action) => {
        state.org.optOutOfEmails = action.payload;
      })
      .addCase(SetOrgIdAction, (state, action) => {
        state.orgId = action.payload;
      })
      .addCase(LoadListStartAction, (state) => {
        state.loading = true;
      })
      .addCase(LoadListSuccessAction, (state, action) => {
        state.loading = false;
        state.list = [...state.list, ...action.payload.list];
        state.count = action.payload.count;
      })
      .addCase(SetLoadNewAction, (state, action) => {
        state.loadNew = action.payload;
      })
      .addCase(SetLoadAllAction, (state, action) => {
        state.loading = false;
        state.loadAll = action.payload;
      })
      .addCase(SetListAction, (state, action) => {
        state.list = action.payload;
      })
      .addCase(SetSearchFilterAction, (state, action) => {
        state.searchFilter = action.payload;
      })
      .addCase(SetSortFilterAction, (state, action) => {
        state.sortFilter = action.payload;
      })
      .addCase(SetPageAction, (state, action) => {
        state.page = action.payload;
      })
      .addCase(SetRowsPerPageAction, (state, action) => {
        state.rowsPerPage = action.payload;
      })
      .addCase(SetUserIdAction, (state, action) => {
        state.userId = action.payload;
      })
      .addCase(CreateOrgStartAction, (state) => {
        state.loading = true;
      })
      .addCase(CreateOrgSuccessAction, (state, action) => {
        state.loading = false;
        state.list.push(action.payload);
        state.createOrgSuccess = action.payload.message;
      })
      .addCase(CreateOrgFailureAction, (state) => { state.loading = false; })
      .addCase(GetLastLoginStartAction, (state) => { state.loginStats = null; })
      .addCase(GetLastLoginSuccessAction, (state, action) => {
        state.loginStats = Object.assign(action.payload, state.loginStats);
      })
      .addCase(GetLastLoginFailureAction, (state) => { state.loginStats.lastLogin = 'N/A'; })
      .addCase(GetLoginStatsSuccessAction, (state, action) => {
        state.loginStats = Object.assign(action.payload, state.loginStats);
      })
      .addCase(GetOrgStatsStartAction, (state) => { state.loading = true; })
      .addCase(GetOrgStatsSuccessAction, (state, action) => {
        state.loading = false;
        state.orgStats = action.payload;
      })
      .addCase(GetSubscriptionStartAction, (state) => { state.loading = true; })
      .addCase(GetSubscriptionSuccessAction, (state, action) => {
        state.cadSubscription = action.payload.response.cad;
        state.automateSubscription = action.payload.response.automate;
        state.expressAISubscription = action.payload.response.expressAI;
        state.loading = false;
      })
      .addCase(GetSubscriptionFailureAction, (state) => { state.loading = false; })
      .addCase(UpdateCostPerAcreAction, (state, action) => {
        state.org.costPerAcre = action.payload;
      })
      .addCase(DeleteOrgAction, (state, action) => {
        state.list = state.list.filter((p) => p._id !== action.payload);
      })
      .addCase(GetPricingAction, (state, action) => {
        state.pricing = action.payload;
      })
      .addCase(SetOrgPricingStartAction, (state) => {
        state.updatingOrgPricingPlan = true;
      })
      .addCase(SetOrgPricingSuccessAction, (state, action) => {
        state.org.currentPricingPlanInfo = action.payload;
        state.updatingOrgPricingPlan = false;
      })
      .addCase(SetOrgPricingFailureAction, (state) => {
        state.updatingOrgPricingPlan = false;
      })
      .addCase(GetOrgProjectsStartAction, (state) => {
        state.loading = true;
      })
      .addCase(GetOrgProjectsSuccessAction, (state, action) => {
        state.orgProjects = action.payload;
        state.loading = false;
      })
      .addCase(DeleteProjectAdminSuccessAction, (state, action) => {
        state.orgProjects = state.orgProjects.filter((p) => p._id !== action.payload);
      })
      .addCase(GetOrgProjectsFailureAction, (state) => {
        state.loading = false;
      });
  },
});

export const { reducer } = adminSlice;

export default reducer;
