// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import {
  Fade,
  CircularProgress,
  Popper,
  Paper,
  Typography,
  Button,
  ClickAwayListener,
  Checkbox,
  FormControlLabel,
  Snackbar,
  IconButton,
  Dialog, DialogActions, DialogContent,
} from '@mui/material';
import { Box, useTheme } from '@mui/system';
import moment from 'moment-timezone';
import HelpIcon from '@mui/icons-material/Help';
import Alert from '@mui/material/Alert';
import { useAppDispatch, useAppSelector } from 'Hooks';
import downloadIcon from 'Assets/download-icon.png';
import ErrorIcon from '@mui/icons-material/Error';
import {
  DownloadLASThunk as DownloadLASAction,
  DownloadSurfaceFileThunk as DownloadSurfaceFileAction,
  DownloadDeliverableFileThunk as DownloadDeliverableFileAction,
  DownloadPostgisDxfThunk as DownloadPostgisDxfAction,
  SetDownloadFilesState as SetDownloadFilesStateAction,
  PrepareLAS as PrepareLASAction,
  PrepareSurfaceFile as PrepareSurfaceFileAction,
  PrepareDeliverableFile as PrepareDeliverableFileAction,
  ClearDownloadFilesThunk as ClearDownloadFilesAction,
  SetGeojsonFilesState as SetGeoJsonDownloadStatusAction,
  PrepareGeoDbFile as PrepareGeoDbFileAction,
  DownloadGeoDbFileThunk as DownloadGeoDbFileAction,
} from 'Features/order/orderThunk';
import stylesDef from './styles';
import { WideLightTooltip } from 'Components/Tooltip';

moment.tz.setDefault('America/New_York');

interface ComponentProps {
  anchorEl: HTMLElement;
  setAnchorEl: any;
  open: boolean;
  order: IOrder;
  fileName: string;
  siteId: string;
  surfaceFile: ISurfaceFile;
  geoDbFile: IGeoDbFile;
  lasFilename: string;
}

export const DownloadPanel: React.FC<ComponentProps> = ({ open, anchorEl, fileName, siteId, order, setAnchorEl, surfaceFile, geoDbFile, lasFilename }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();

  const vectorTileJson = useAppSelector((state) => state.map.common.vectorTileJson &&
  state.map.common.vectorTileJson[order._id] &&
  state.map.common.vectorTileJson[order._id][state.order.fileVersions[order._id]]);

  const noLayersSelected = vectorTileJson?.vector_layers && vectorTileJson.vector_layers.every((l) => !l.visible);
  const id = open ? 'simple-popper' : null;
  const { deliveryEstimate, expressAICreditAcreage } = order;
  const orderFile = fileName;
  const orderId = order._id;
  const orderedGeoJson = order?.addOns?.some((el) => el.name === 'Geojson File Format (.geojson)');
  const downloadFileStatuses = useAppSelector((state) => state.order?.dxfFileStatus);
  const downloadGeoJsonFileStatuses = useAppSelector((state) => state.order?.geoJsonFileStatus);
  const anyChangeInFileStatuses = useAppSelector((state) => state.order?.anyChangeInFileStatuses);

  const [includeEmptyLayers, setIncludeEmptyLayers] = useState(false);
  const [areDownloadsReady, setAreDownloadsReady] = useState(false);
  const [isCheckboxEnabled, setIsCheckboxEnabled] = useState(false);
  const [isGeoJsonCheckboxEnabled, setIsGeoJsonCheckboxEnabled] = useState(false);
  const [isDxfCheckboxEnabled, setIsDxfCheckboxEnabled] = useState(false);
  const [showExpressAIWarning, setShowExpressAIWarning] = useState(false);
  const [dialogSpinner, setDialogSpinner] = useState(false);
  const [showFileDownloadState, setShowFileDownloadState] = useState('');

  const index = downloadFileStatuses?.findIndex((obj: any) => obj.siteId === siteId);
  const downloadFileStatusesIndex = index >= 0 && downloadFileStatuses[index];
  const geoJsonIndex = downloadGeoJsonFileStatuses.findIndex((obj: any) => obj.siteId === siteId);
  const downloadGeoJsonFileStatusesIndex = geoJsonIndex >= 0 && downloadGeoJsonFileStatuses[geoJsonIndex];
  const [fileDownloadCheckboxState, setFileDownloadCheckboxState] = useState({
    dxfFile: false,
    lasFile: false,
    surfaceFile: false,
    geoDbFile: false,
    geoJson: false,
    shapeFile: false,
    demFile: false,
    dtmFile: false,
    dwgFile: false,
    dgnFile: false,
  });
  const shapeFiles = order.deliverableFiles?.filter(file => /\.shp$/.test(file.filename));
  const demFiles = order.deliverableFiles?.filter(file => /\.dem$/.test(file.filename));
  const dtmFiles = order.deliverableFiles?.filter(file => /\.dtm$/.test(file.filename));
  const dwgFiles = order.deliverableFiles?.filter(file => /\.dwg$/.test(file.filename));
  const dgnFiles = order.deliverableFiles?.filter(file => /\.dgn$/.test(file.filename));

  const [mostRecentShapeFile] = useState(shapeFiles?.reduce((prev, current) => ((+prev.createdAt > +current.createdAt) ? prev : current), {}));
  const [mostRecentDemFile] = useState(demFiles?.reduce((prev, current) => ((+prev.createdAt > +current.createdAt) ? prev : current), {}));
  const [mostRecentDtmFile] = useState(dtmFiles?.reduce((prev, current) => ((+prev.createdAt > +current.createdAt) ? prev : current), {}));
  const [mostRecentDwgFile] = useState(dwgFiles?.reduce((prev, current) => ((+prev.createdAt > +current.createdAt) ? prev : current), {}));
  const [mostRecentDgnFile] = useState(dgnFiles?.reduce((prev, current) => ((+prev.createdAt > +current.createdAt) ? prev : current), {}));

  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    let areAllFilesReady = true;
    if (index < 0 && geoJsonIndex < 0) {
      setShowFileDownloadState('Prepare Download');
      return;
    }
    // dxf and geojson both requested
    if (index >= 0 && geoJsonIndex >= 0) {
      const fileStatuses = downloadFileStatusesIndex.fileReadyStatus;
      const checkboxes: {
        [key: string]: boolean;
      } = {};

      Object.keys(fileStatuses).forEach((fileType) => {
        if (fileType in fileDownloadCheckboxState) {
          checkboxes[fileType] = true;
        }
      });

      const geoJsonFileStatuses = downloadGeoJsonFileStatusesIndex.fileReadyStatus;

      Object.keys(geoJsonFileStatuses).forEach((fileType) => {
        if (fileType in fileDownloadCheckboxState) {
          checkboxes[fileType] = true;
        }
      });

      setFileDownloadCheckboxState({ ...fileDownloadCheckboxState, ...checkboxes });
      areAllFilesReady = Object.values(fileStatuses).every((value) => value !== 0);
      areAllFilesReady = Object.values(geoJsonFileStatuses).every((value) => value !== 0);
    }
    // only dxf download requested
    if (index >= 0 && geoJsonIndex < 0) {
      const fileStatuses = downloadFileStatusesIndex.fileReadyStatus;
      const checkboxes: {
        [key: string]: boolean;
      } = {};

      Object.keys(fileStatuses).forEach((fileType) => {
        if (fileType in fileDownloadCheckboxState) {
          checkboxes[fileType] = true;
        }
      });

      setFileDownloadCheckboxState({ ...fileDownloadCheckboxState, ...checkboxes });
      areAllFilesReady = Object.values(fileStatuses).every((value) => value !== 0);
    }
    // only geojson download requested
    if (geoJsonIndex >= 0 && index < 0) {
      setShowFileDownloadState('Preparing Download');
      const geoJsonFileStatuses = downloadGeoJsonFileStatusesIndex.fileReadyStatus;
      const checkboxes: {
        [key: string]: boolean;
      } = {};

      Object.keys(geoJsonFileStatuses).forEach((fileType) => {
        if (fileType in fileDownloadCheckboxState) {
          checkboxes[fileType] = true;
        }
      });

      setFileDownloadCheckboxState({ ...fileDownloadCheckboxState, ...checkboxes });
      areAllFilesReady = Object.values(geoJsonFileStatuses).every((value) => value !== 0);
    }

    if (!areAllFilesReady) {
      setAreDownloadsReady(false);
      setIsCheckboxEnabled(true);
      // The checkbox for selecting dxf on the download panel is enabled only when atleast one layer is selected
      if (!noLayersSelected) {
        setIsDxfCheckboxEnabled(true);
      }
      setShowFileDownloadState('Preparing Download');
    } else {
      setAreDownloadsReady(true);
      setShowFileDownloadState('Download');
      setShowSnackbar(true);
    }
  }, [downloadFileStatuses, downloadGeoJsonFileStatuses, anyChangeInFileStatuses]);

  useEffect(() => {
    if (noLayersSelected) {
      // If no layers are selected and the download panel is opened, uncheck the dxf checkbox and disable it
      setFileDownloadCheckboxState({ ...fileDownloadCheckboxState, dxfFile: false });
      setIsDxfCheckboxEnabled(true);
    } else {
      // Checkbox enabled when atleast one layer is selected
      setIsDxfCheckboxEnabled(false);
    }
  }, [noLayersSelected]);

  const cadPresent = !!(order.cadFiles[0]);
  const cadFile = order.cadFiles.find((file) => file._id === siteId);

  const PrepareOutputFiles = async () => {
    // check what are the files requested for dxf and las
    setIsCheckboxEnabled(true);
    setIsDxfCheckboxEnabled(true);
    setIsGeoJsonCheckboxEnabled(true);
    if (fileDownloadCheckboxState.dxfFile && fileDownloadCheckboxState.geoJson) {
      await dispatch(SetDownloadFilesStateAction(siteId, fileDownloadCheckboxState));
      await dispatch(SetGeoJsonDownloadStatusAction(siteId, fileDownloadCheckboxState));
      await dispatch(DownloadPostgisDxfAction(orderId, siteId, includeEmptyLayers, fileDownloadCheckboxState));
    }
    if (fileDownloadCheckboxState.dxfFile && !fileDownloadCheckboxState.geoJson) {
      // eslint-disable-next-line no-unused-expressions
      if (cadFile.expressAIUpload && cadFile.expressAIEmailSent) {
        setShowExpressAIWarning(true);
      } else {
        await dispatch(SetDownloadFilesStateAction(siteId, fileDownloadCheckboxState));
        await dispatch(DownloadPostgisDxfAction(orderId, siteId, includeEmptyLayers, fileDownloadCheckboxState));
      }
    }

    if (fileDownloadCheckboxState.geoJson && !fileDownloadCheckboxState.dxfFile) {
      await dispatch(SetGeoJsonDownloadStatusAction(siteId, fileDownloadCheckboxState));
      await dispatch(DownloadPostgisDxfAction(orderId, siteId, includeEmptyLayers, fileDownloadCheckboxState));
    }

    if (fileDownloadCheckboxState.lasFile) {
      await dispatch(SetDownloadFilesStateAction(siteId, fileDownloadCheckboxState));
      await dispatch(PrepareLASAction(siteId));
    }
    if (fileDownloadCheckboxState.surfaceFile) {
      await dispatch(SetDownloadFilesStateAction(siteId, fileDownloadCheckboxState));
      await dispatch(PrepareSurfaceFileAction(siteId));
    }
    if (fileDownloadCheckboxState.shapeFile) {
      await dispatch(SetDownloadFilesStateAction(siteId, fileDownloadCheckboxState));
      await dispatch(PrepareDeliverableFileAction(siteId));
    }
    if (fileDownloadCheckboxState.demFile) {
      await dispatch(SetDownloadFilesStateAction(siteId, fileDownloadCheckboxState));
      await dispatch(PrepareDeliverableFileAction(siteId));
    }
    if (fileDownloadCheckboxState.dtmFile) {
      await dispatch(SetDownloadFilesStateAction(siteId, fileDownloadCheckboxState));
      await dispatch(PrepareDeliverableFileAction(siteId));
    }
    if (fileDownloadCheckboxState.dwgFile) {
      await dispatch(SetDownloadFilesStateAction(siteId, fileDownloadCheckboxState));
      await dispatch(PrepareDeliverableFileAction(siteId));
    }
    if (fileDownloadCheckboxState.dgnFile) {
      await dispatch(SetDownloadFilesStateAction(siteId, fileDownloadCheckboxState));
      await dispatch(PrepareDeliverableFileAction(siteId));
    }
    if (fileDownloadCheckboxState.geoDbFile) {
      await dispatch(SetDownloadFilesStateAction(siteId, fileDownloadCheckboxState));
      await dispatch(PrepareGeoDbFileAction(siteId));
    }
  };

  const confirmDxfExport = async () => {
    setDialogSpinner(true);
    await dispatch(SetDownloadFilesStateAction(siteId, fileDownloadCheckboxState));
    await dispatch(DownloadPostgisDxfAction(orderId, siteId, includeEmptyLayers));
    setDialogSpinner(false);
    setShowExpressAIWarning(false);
  };

  const DownloadOutputFiles = async () => {
    const status = [];
    if (fileDownloadCheckboxState.dxfFile && fileDownloadCheckboxState.geoJson) {
      const filesReadyToDownload = [];
      if (index >= 0 && downloadFileStatusesIndex.url) {
        filesReadyToDownload.push(downloadFileStatusesIndex.url);
      }

      if (geoJsonIndex >= 0 && downloadGeoJsonFileStatusesIndex.url) {
        filesReadyToDownload.push(downloadGeoJsonFileStatusesIndex.url);
      }

      const interval = 3000;
      filesReadyToDownload.forEach((file, i) => {
        setTimeout(async () => {
          window.open(file, '_self');
        }, i * interval);
      });

      const pendoEventName = 'Download DXF and GeoJson';
      status.push('Y');
      window?.pendo?.track(pendoEventName, {
        filename: orderFile,
        filesize: `${(downloadFileStatusesIndex.size / 1000000).toFixed(2)} MB`,
        projectId: order.ownerProject,
      });
    }
    if (fileDownloadCheckboxState.dxfFile && !fileDownloadCheckboxState.geoJson) {
      if (index >= 0 && downloadFileStatusesIndex.url) {
        window.open(downloadFileStatusesIndex.url, '_self');
      }

      status.push('Y');
      window?.pendo?.track('Download DXF', {
        filename: orderFile,
        filesize: `${(downloadFileStatusesIndex.size / 1000000).toFixed(2)} MB`,
        projectId: order.ownerProject,
        dxfType: expressAICreditAcreage > 0 ? 'express_ai' : 'QC',
      });
    }
    if (fileDownloadCheckboxState.geoJson && !fileDownloadCheckboxState.dxfFile) {
      if (geoJsonIndex >= 0 && downloadGeoJsonFileStatusesIndex.url) {
        window.open(downloadGeoJsonFileStatusesIndex.url, '_self');
      }
      const pendoEventName = 'Download GeoJson';
      status.push('Y');
      window?.pendo?.track(pendoEventName, {
        filename: orderFile,
        filesize: `${(downloadGeoJsonFileStatusesIndex.size / 1000000).toFixed(2)} MB`,
        projectId: order.ownerProject,
      });
    }
    if (fileDownloadCheckboxState.lasFile) {
      const res = await dispatch(DownloadLASAction(orderId, lasFilename));
      if (res.success) {
        status.push('Y');
      }
    }
    if (fileDownloadCheckboxState.surfaceFile) {
      const res = await dispatch(DownloadSurfaceFileAction(surfaceFile._id, orderId, siteId));
      if (res.success === true) {
        status.push('Y');
      }
    }
    if (fileDownloadCheckboxState.shapeFile) {
      const res = await dispatch(DownloadDeliverableFileAction(mostRecentShapeFile._id, orderId, siteId));
      if (res.success === true) {
        status.push('Y');
      }
    }
    if (fileDownloadCheckboxState.demFile) {
      const res = await dispatch(DownloadDeliverableFileAction(mostRecentDemFile._id, orderId, siteId));
      if (res.success === true) {
        status.push('Y');
      }
    }
    if (fileDownloadCheckboxState.dtmFile) {
      const res = await dispatch(DownloadDeliverableFileAction(mostRecentDtmFile._id, orderId, siteId));
      if (res.success === true) {
        status.push('Y');
      }
    }
    if (fileDownloadCheckboxState.dwgFile) {
      const res = await dispatch(DownloadDeliverableFileAction(mostRecentDwgFile._id, orderId, siteId));
      if (res.success === true) {
        status.push('Y');
      }
    }
    if (fileDownloadCheckboxState.dgnFile) {
      const res = await dispatch(DownloadDeliverableFileAction(mostRecentDgnFile._id, orderId, siteId));
      if (res.success === true) {
        status.push('Y');
      }
    }
    if (fileDownloadCheckboxState.geoDbFile) {
      const res = await dispatch(DownloadGeoDbFileAction(geoDbFile._id, orderId));
      if (res.success === true) {
        status.push('Y');
      }
    }
    if (status.every((val, i, arr) => val === arr[0])) {
      await dispatch(ClearDownloadFilesAction(siteId));
      setIsCheckboxEnabled(false);
      setIsDxfCheckboxEnabled(true);
      setIsGeoJsonCheckboxEnabled(false);
      setAreDownloadsReady(false);
      setShowFileDownloadState('Prepare Download');
    }
  };

  const ButtonFunction = () => {
    // eslint-disable-next-line no-unused-expressions
    areDownloadsReady ? DownloadOutputFiles() : PrepareOutputFiles();
  };
  // Download files based on which of the files are ready to be downloaded
  const handleClickAway = () => {
    setAnchorEl(false);
  };

  const handleCheckboxChange = (event: any) => {
    setFileDownloadCheckboxState({ ...fileDownloadCheckboxState, [event.target.name]: event.target.checked });
  };

  const handleIncludeEmptyLayersCheckboxChange = () => {
    setIncludeEmptyLayers(!includeEmptyLayers);
  };

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbar(false);
  };

  const prepareDownloadButton = Object.values(fileDownloadCheckboxState).every((value) => !value);
  return (
    <>
      <Snackbar sx={styles.snackBar} open={showSnackbar} autoHideDuration={10000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert severity="success" sx={styles.alert} color="info"> Your file(s) are ready to download</Alert>
      </Snackbar>
      <Popper id={id} open={open} anchorEl={anchorEl} transition placement="right-start" sx={styles.popper}>
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper sx={styles.downloadPanel}>
                <Box sx={styles.header}>
                  <Typography variant="h4">Export</Typography>
                  <WideLightTooltip
                    placement="top"
                    interactive
                    title={(
                      <Box>
                        Please review
                        {' '}
                        <a style={styles.link} href="http://support.airworks.io/Workaround-for-exported-DXFs-in-AutoCAD.2406023169.html" target="_blank" rel="noreferrer">these steps</a>
                        {' '}
                        on importing an AirWorks dxf into AutoCAD.
                        {orderedGeoJson && (
                          <Box>
                            {' '}
                            <br />
                            {' '}
                            Your .geojson file will download in the WGS84 coordinate system.
                            {' '}
                          </Box>
                        )}
                      </Box>
                    )}
                  >
                    <HelpIcon sx={styles.icon} />
                  </WideLightTooltip>
                </Box>
                {cadPresent && (
                  <Box>
                    <FormControlLabel
                      control={(
                        <Checkbox color="primary" checked={fileDownloadCheckboxState.dxfFile} name="dxfFile" onChange={handleCheckboxChange} disabled={isDxfCheckboxEnabled} />
                      )}
                      label={orderFile}
                    />
                    <FormControlLabel
                      control={(
                        <Checkbox sx={styles.smallCheckbox} color="primary" checked={includeEmptyLayers} name="emptyLayers" onChange={handleIncludeEmptyLayersCheckboxChange} disabled={isDxfCheckboxEnabled} />
                      )}
                      label="Empty Layers"
                      sx={{
                        '& .MuiFormControlLabel-root': styles.emptyLayers,
                        '& .MuiFormControlLabel-label': styles.emptyLayersLabel,
                      }}
                    />
                    {/* check if the user ordered geojson for this order and this is not an express AI dxf */}
                    { orderedGeoJson && !cadFile.expressAIUpload && (
                      <FormControlLabel
                        control={(
                          <Checkbox color="primary" checked={fileDownloadCheckboxState.geoJson} name="geoJson" onChange={handleCheckboxChange} disabled={isGeoJsonCheckboxEnabled} />
                        )}
                        label={`${orderFile.replace('.dxf', '.geojson')}`}
                      />
                    )}
                  </Box>
                )}
                <br />
                {lasFilename && (
                  <FormControlLabel
                    control={(
                      <Checkbox color="primary" checked={fileDownloadCheckboxState.lasFile} name="lasFile" onChange={handleCheckboxChange} disabled={isCheckboxEnabled} />
                    )}
                    label={lasFilename}
                  />
                )}
                <br />
                {surfaceFile.filename && (
                  <FormControlLabel
                    control={(
                      <Checkbox color="primary" checked={fileDownloadCheckboxState.surfaceFile} name="surfaceFile" onChange={handleCheckboxChange} disabled={isCheckboxEnabled} />
                    )}
                    label={surfaceFile.filename}
                  />
                )}
                {mostRecentShapeFile.filename && (
                  <FormControlLabel
                    control={(
                      <Checkbox color="primary" checked={fileDownloadCheckboxState.shapeFile} name="shapeFile" onChange={handleCheckboxChange} disabled={isCheckboxEnabled} />
                    )}
                    label={mostRecentShapeFile.filename}
                  />
                )}
                {mostRecentDemFile.filename && (
                  <FormControlLabel
                    control={(
                      <Checkbox color="primary" checked={fileDownloadCheckboxState.demFile} name="demFile" onChange={handleCheckboxChange} disabled={isCheckboxEnabled} />
                    )}
                    label={mostRecentDemFile.filename}
                  />
                )}
                {mostRecentDtmFile.filename && (
                  <FormControlLabel
                    control={(
                      <Checkbox color="primary" checked={fileDownloadCheckboxState.dtmFile} name="dtmFile" onChange={handleCheckboxChange} disabled={isCheckboxEnabled} />
                    )}
                    label={mostRecentDtmFile.filename}
                  />
                )}
                {mostRecentDwgFile.filename && (
                  <FormControlLabel
                    control={(
                      <Checkbox color="primary" checked={fileDownloadCheckboxState.dwgFile} name="dwgFile" onChange={handleCheckboxChange} disabled={isCheckboxEnabled} />
                    )}
                    label={mostRecentDwgFile.filename}
                  />
                )}
                {mostRecentDgnFile.filename && (
                  <FormControlLabel
                    control={(
                      <Checkbox color="primary" checked={fileDownloadCheckboxState.dgnFile} name="dgnFile" onChange={handleCheckboxChange} disabled={isCheckboxEnabled} />
                    )}
                    label={mostRecentDgnFile.filename}
                  />
                )}
                {geoDbFile.filename && (
                  <FormControlLabel
                    control={(
                      <Checkbox color="primary" checked={fileDownloadCheckboxState.geoDbFile} name="geoDbFile" onChange={handleCheckboxChange} disabled={isCheckboxEnabled} />
                    )}
                    label={geoDbFile.filename}
                  />
                )}
                <Button
                  disabled={prepareDownloadButton}
                  onClick={ButtonFunction}
                  sx={styles.downloadButton}
                  color="primary"
                >
                  {
                    (showFileDownloadState === 'Preparing Download') &&
                  (
                    <CircularProgress size={16} sx={styles.buttonProgress} color="primary" />
                  )
                  }
                  {(showFileDownloadState === 'Prepare Download' || showFileDownloadState === 'Download') &&
                (
                  <IconButton
                    aria-label="Download"
                    title="Download"
                    color="primary"
                  >
                    <img src={downloadIcon} alt="Download" />
                  </IconButton>
                )}
                  {showFileDownloadState}
                </Button>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
      <Dialog
        open={showExpressAIWarning}
        onClose={() => { setShowExpressAIWarning(false); }}
        sx={styles.dialog}
      >
        <Box sx={styles.dialogContent}>
          <Box sx={styles.warningTitle}>
            <ErrorIcon sx={styles.warningIcon} />
            <Typography variant="h3">Export Layer</Typography>
          </Box>
          <DialogContent>
            <Typography display="inline">
              This layer was generated by the
              {' '}
              <strong>AirWorks Autonomous Pipeline</strong>
              {' '}
              and has not yet had its quality assessed. Your final output will be ready by
              {' '}
            </Typography>
            <Typography sx={styles.deliveryDate} display="inline">
              {moment(deliveryEstimate).format('LL')}
              .
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { setShowExpressAIWarning(false); }}>Close</Button>
            <Button
              variant="text"
              color="primary"
              onClick={confirmDxfExport}
            >
              <Fade in={dialogSpinner}>
                <CircularProgress size={16} sx={styles.expressButtonProgress} />
              </Fade>
              <Fade in={!dialogSpinner}>
                <span>Export Anyway</span>
              </Fade>
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default DownloadPanel;
