// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import moment from 'moment-timezone';
import { Typography, Button, Card, Divider } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { Link as RouterLink } from 'react-router-dom';
import { ACCOUNT_PLAN_USAGE } from 'Utils/routes';
import { formatValue } from 'Utils/deliverables';
import { pricingDescription } from 'Utils/constants';
import { useAppSelector } from 'Hooks';
import stylesDef from './styles';

moment.tz.setDefault('America/New_York');

export const PlanInfo = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const { automateSubscription, subscriptionsLoading, expressAISubscription } = useAppSelector((state) => state.subscription);
  const pricingPlanName = useAppSelector((state) => state.account.org?.currentPricingPlanInfo?.name);
  const pricingPlanRate = useAppSelector((state) => state.account.org?.currentPricingPlanInfo?.rate);

  const automateSubscriptionExists = automateSubscription && Object.keys(automateSubscription).length > 0;

  const expressAISubscriptionExists = expressAISubscription && Object.keys(expressAISubscription).length > 0;

  return (
    <Box>
      <Card sx={styles.subscriptionContent}>
        <Typography sx={styles.header} variant="h4">Accounts and Subscriptions</Typography>
        <Box>
          <>
            {pricingPlanName && (
              <>
                <Divider sx={styles.divider} />
                <Typography sx={styles.planName} variant="h2">
                  {pricingDescription.find((pricing) => pricing.name === pricingPlanName)?.displayName}
                </Typography>
                <Typography sx={styles.planRate} variant="body1">
                  You are currently paying $
                  {formatValue(pricingPlanRate)}
                  {' '}
                  per credit.
                </Typography>
                <Box>
                  <RouterLink to={ACCOUNT_PLAN_USAGE} title="View Usage and Limits">
                    <Typography variant="h5" sx={styles.optionsButton}>
                      View Usage and Limits
                    </Typography>
                  </RouterLink>
                </Box>
              </>
            )}
            {!pricingPlanName && automateSubscriptionExists && (
              <>
                <Divider sx={styles.divider} />
                <Box sx={styles.planOptions}>
                  <Box>
                    <Typography sx={styles.planName} variant="h2">{automateSubscription.name}</Typography>
                    <Typography sx={styles.planName} variant="h2">{` - ${automateSubscription.interval}`}</Typography>
                    {automateSubscription.trial && <Typography sx={styles.planName} variant="h2"> - Trial</Typography>}
                  </Box>
                </Box>
                <Box>
                  <RouterLink to={ACCOUNT_PLAN_USAGE} title="View Usage and Limits">
                    <Typography variant="h5" sx={styles.optionsButton}>
                      View Usage and Limits
                    </Typography>
                  </RouterLink>
                </Box>
              </>
            )}
             {!pricingPlanName && expressAISubscriptionExists && (
              <>
                <Divider sx={styles.divider} />
                <Box sx={styles.planOptions}>
                  <Box>
                    <Typography sx={styles.planName} variant="h2">{expressAISubscription.name}</Typography>
                    <Typography sx={styles.planName} variant="h2">{` - ${expressAISubscription.interval}`}</Typography>
                    {expressAISubscription.trial && <Typography sx={styles.planName} variant="h2"> - Trial</Typography>}
                  </Box>
                </Box>
              </>
            )}
          </>
          {!subscriptionsLoading && !automateSubscriptionExists && !expressAISubscriptionExists && !pricingPlanName && (
            <Box sx={styles.planOptions}>
              <Box>
                <Typography sx={styles.planName} variant="h2">No active subscriptions</Typography>
              </Box>
              <Button variant="outlined" onClick={() => { window.open('mailto:sales@airworks.io', '_blank'); }}>Upgrade plan</Button>
            </Box>
          )}
        </Box>
      </Card>
    </Box>
  );
};

export default PlanInfo;
