export const oktaConfig = {
  clientId: _OKTA_CLIENT_ID_,
  issuer: `https://${_OKTA_DOMAIN_}/oauth2/default`,
  redirectUri: `${window.location.origin}/callback`,
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
  disableHttpsCheck: true,
};

export default oktaConfig;
