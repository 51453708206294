// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, useTheme } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import usePrevious from '../hooks/usePrevious';
import stylesDef from './styles';

const LoadingSpinner = ({ loading, children }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const [showSpinner, setShowSpinner] = useState(false);
  const [timer, setTimer] = useState(null);
  const [show, setShow] = useState(true);

  const prevLoading = usePrevious(loading);

  useEffect(() => {
    if (show && prevLoading && !loading) {
      setShow(false);
    }
  }, [loading]);

  useLayoutEffect(() => {
    const t = setTimeout(() => setShowSpinner(true), 10);
    setTimer(t);

    return function cleanup() {
      clearTimeout(timer);
    };
  }, []);

  if (show) {
    return showSpinner ? (
      <Box sx={styles.wrapper}>
        <CircularProgress />
      </Box>
    ) : null;
  }

  // return null;
  // return loading ? (
  //   <Box sx={styles.wrapper}>
  //     <CircularProgress />
  //   </Box>
  // ) : null;
  return (
    <>
      {children}
    </>
  );
};

LoadingSpinner.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default LoadingSpinner;
