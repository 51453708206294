// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { Box, useTheme } from '@mui/system';
import CreditCardInfo from './CreditCardInfo';
import EditCardForm from './EditCardForm';
import stylesDef from './styles';

interface ComponentProps {
  card : ICreditCard;
  setTotalTax?: (arg0: number) => void;
  checkFirstTimeChangeEstimate?: (arg0: Function) => void;
  additonalCharges?: number;
}

export const CreditCard: React.FC<ComponentProps> = ({ card, additonalCharges, setTotalTax, checkFirstTimeChangeEstimate}) => {
  const theme = useTheme();
  const styles = stylesDef(theme);

  const [showEditCard, setShowEditCard] = useState(false);
  const { defaultCard } = card;
  return (
    <Box sx={{ ...styles.wrapper, ...(defaultCard && styles.primary) }}>
      {!showEditCard && <CreditCardInfo additonalCharges= {additonalCharges} setTotalTax={setTotalTax} card={card} onEditClick={() => setShowEditCard(true)} checkFirstTimeChangeEstimate={checkFirstTimeChangeEstimate}/>}
      {showEditCard && <EditCardForm card={card} additonalCharges= {additonalCharges} setTotalTax={setTotalTax} onCancel={() => setShowEditCard(false)} />}
    </Box>
  );
};

export default CreditCard;
