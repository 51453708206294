// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Typography, Card } from '@mui/material';
import { useTheme } from '@mui/system';
import { useAppSelector } from 'Hooks';
import CreditCard from './CreditCard';
import CreateCreditCard from './CreateCreditCard';
import stylesDef from './styles';

export const PaymentInfo = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const { creditCards } = useAppSelector((state) => state.subscription);
  const cards = creditCards;

  return (
    <Card sx={styles.paymentInfoContent}>
      <Typography sx={styles.header} variant="h4">Payment Info</Typography>
      {cards.map((card: ICreditCard) => <CreditCard card={card} key={card.id} />)}
      <CreateCreditCard />
    </Card>
  );
};

export default PaymentInfo;
