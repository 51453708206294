// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Tabs, Tab } from '@mui/material';
import { GetProjectDetailsRoute, GetProjectFilesRoute, GetProject3DViewerRoute } from 'Utils/routes';
import { useAppSelector } from 'Hooks';
import { cadViewPermissions } from 'Features/order/orderSelectors';
import stylesDef from './styles';

export const ProjectToolbar = () => {
  const styles = stylesDef();
  const { viewer3D } = useFlags();
  const location = useLocation();

  let isAnyOrderProcessed = false;
  const cadViewPermission = useAppSelector((state) => cadViewPermissions(state));
  if (_ENV_ === 'production' && (cadViewPermission.cadViewProd)) {
    isAnyOrderProcessed = true;
  }
  if ((_ENV_ === 'test' || _ENV_ === 'dev') && (cadViewPermission.cadViewDevStage)) {
    isAnyOrderProcessed = true;
  }

  const projectId = useAppSelector((state) => state.project.projectId);
  const project = useAppSelector((state) => state.project.project);

  const viewer3DTab = project?.lasFiles?.filter((las) => las.tile3DUrl).length;

  const resources = useAppSelector((state) => state.auth.resources);
  const editorFunctions = !_ADMIN_ && resources?.editorFunctions;
  const projectDetailsTab = !_ADMIN_ && resources?.projectDetailsTab;

  const isTabDisabled = editorFunctions ? false : !isAnyOrderProcessed;

  return (
    <>
      {projectId && (
        <Tabs indicatorColor="primary" value={location.pathname}>
          {projectDetailsTab && (
            <Tab
              component={Link}
              label="Project Details"
              value={GetProjectDetailsRoute(projectId)}
              to={GetProjectDetailsRoute(projectId)}
              sx={styles.tabLabel}
            />
          )}
          <Tab
            component={Link}
            label="CAD View"
            value={GetProjectFilesRoute(projectId)}
            to={GetProjectFilesRoute(projectId)}
            sx={styles.tabLabel}
            // disable CAD View if the customer doesn't have a processed order or doesn't have 2d editor permissions
            disabled={isTabDisabled}
          />
          {viewer3DTab && viewer3D && (
            <Tab
              component={Link}
              label="3D Viewer"
              value={GetProject3DViewerRoute(projectId)}
              to={GetProject3DViewerRoute(projectId)}
              sx={styles.tabLabel}
            />
          )}
        </Tabs>
      )}
    </>
  );
};

export default ProjectToolbar;
