// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import Dropzone from 'Components/dropzone';
import { Dialog, Button, Typography } from '@mui/material/';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import uploadFilesIcon from 'Assets/upload-files-icon.png';
import { Box, useTheme } from '@mui/system';
import DialogContent from '@mui/material/DialogContent';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { UploadDxfLayersThunk } from 'Features/ops/opsThunk';
import stylesDef from './styles';
import { HideOpsTrainingDialogAction } from 'Features/fileUpload/fileUploadActions';

const OpsTrainingDialogForm = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();

  const showOpsTrainingDialog = useAppSelector((state) => state.fileUpload.showOpsTrainingDialog);
  const [filename, setFilename] = useState(null);

  useEffect(() => {
    dispatch(HideOpsTrainingDialogAction());
  }, []);

  const [notes, setNotes] = useState('');
  const [fileUpload, setFileUpload] = useState({});

  const handleTextBoxChange = (e: any) => {
    setNotes(e.target.value);
  };

  const handleDxfLogsUpload = async () => {
    await dispatch(UploadDxfLayersThunk(notes, fileUpload));
    setNotes('');
    setFilename('');
    dispatch(HideOpsTrainingDialogAction());
  };

  const handleDxfClose = async () => {
    setNotes('');
    setFilename('');
    dispatch(HideOpsTrainingDialogAction());
  };

  return (
    <Dialog open={showOpsTrainingDialog} onClose={handleDxfClose}   sx={{
      '& .MuiDialog-paper': styles.dialog,
    }}>
     <DialogContent>
        <Dropzone
                acceptedExtensions={['.dxf']}
                onDropAccepted={async (acceptedFilesArray) => {
                  setFileUpload(acceptedFilesArray[0])
                  setFilename(acceptedFilesArray[0].name)
                }}
              >
                {(getInputProps, open) => (
                  <Box sx={styles.dropzone}>
                    <Box>
                      <img src={uploadFilesIcon} alt="Upload files" style={styles.uploadFormIcon} />
                      <input {...getInputProps()} />
                    </Box>
                    <Box sx={styles.dropzoneText}>
                      <Typography variant="h5" sx={styles.uploadFormText}>
                        Drag and drop to upload
                      </Typography>
                      <br />
                      <Typography variant="caption" sx={styles.uploadFormText}>
                        {'or '}
                        <button type="button" style={styles.openFileDialogLink} onClick={open}>browse</button>
                        {' to choose a file'}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Dropzone>
        <TextField
          margin="dense"
          id="log-notes"
          label="Log Notes"
          type="log-notes"
          variant="outlined"
          fullWidth
          onChange={(e) => handleTextBoxChange(e)}
          value={notes}
        />
        </DialogContent>
        {filename && (
              <Box>
                <Typography variant="body2">{filename}</Typography>
              </Box>
            )}
      <DialogActions>
        <Button onClick={handleDxfClose} color="primary">
          CANCEL
        </Button>
        <Button onClick={handleDxfLogsUpload} color="primary">
          CONFIRM AND UPLOAD
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OpsTrainingDialogForm;
