/* eslint-disable import/no-unresolved */
// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

// TODO: should fix the destructing lint issues in this file (garrett, 2021-08-16)
/* eslint-disable react/destructuring-assignment */

import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';
import draw_line_string from '@mapbox/mapbox-gl-draw/src/modes/draw_line_string';

const MapboxDraw = require('@mapbox/mapbox-gl-draw');
const turfLength = require('@turf/length').default;
const turfCircle = require('@turf/circle').default;
const turfMidpoint = require('@turf/midpoint').default;

const CircleMode = { ...draw_line_string };

function createVertex(parentId, coordinates, path, selected) {
  return {
    type: 'Feature',
    properties: {
      meta: 'vertex',
      parent: parentId,
      coord_path: path,
      active: (selected) ? 'true' : 'false',
    },
    geometry: {
      type: 'Point',
      coordinates,
    },
    active: 'true',
  };
}

CircleMode.onSetup = function (opts) {
  const props = MapboxDraw.modes.draw_line_string.onSetup.call(this, opts);
  const circle = this.newFeature({
    type: Constants.geojsonTypes.FEATURE,
    properties: {
      meta: 'radius',
    },
    geometry: {
      type: Constants.geojsonTypes.POLYGON,
      coordinates: [[]],
    },
  });
  this.addFeature(circle);

  return {
    ...props,
    circle,
  };
};

CircleMode.clickAnywhere = function (state, e) {
  if (state.currentVertexPosition === 1) {
    state.line.updateCoordinate(state.currentVertexPosition, e.lngLat.lng, e.lngLat.lat);
    const { coordinates } = state.line;
    if (coordinates[0][0] === coordinates[1][0] && coordinates[0][1] === coordinates[1][1]) {
      this.deleteFeature([state.line.id], { silent: true }); // eslint-disable-line react/no-this-in-sfc
      return this.changeMode(Constants.modes.SIMPLE_SELECT, {}); // eslint-disable-line react/no-this-in-sfc
    }
    return this.changeMode(Constants.modes.SIMPLE_SELECT, { featureIds: [state.line.id] }); // eslint-disable-line react/no-this-in-sfc
  }
  this.updateUIClasses({ mouse: 'add' }); // eslint-disable-line react/no-this-in-sfc

  if (state.direction === 'forward') {
    state.currentVertexPosition += 1;
    state.line.updateCoordinate(state.currentVertexPosition, e.lngLat.lng, e.lngLat.lat);
  } else {
    state.line.addCoordinate(0, e.lngLat.lng, e.lngLat.lat);
  }
  return null;
};

CircleMode.onStop = function (state) {
  doubleClickZoom.enable(this);
  this.activateUIButton();

  // check to see if we've deleted this feature
  if (this.getFeature(state.line.id) === undefined) return;

  if (state.line.isValid()) {
    this.map.fire('draw.create', {
      features: [state.circle.toGeoJSON()],
    });

    // remove the diameter after finalizing circle
    this.deleteFeature([state.line.id], { silent: true });
    this.changeMode(Constants.modes.SIMPLE_SELECT, { featureIds: [state.circle.id] });
  } else {
    this.deleteFeature([state.line.id], { silent: true });
    this.changeMode(Constants.modes.SIMPLE_SELECT, {}, { silent: true });
  }
};

CircleMode.onMouseMove = function (state, e) {
  MapboxDraw.modes.draw_line_string.onMouseMove.call(this, state, e);
  const center = turfMidpoint(state.line.coordinates[0], [e.lngLat.lng, e.lngLat.lat]);
  const radius = turfLength(state.line) * 0.5;
  if (radius) {
    const circleFeature = turfCircle(center, radius);
    state.circle.setCoordinates(circleFeature.geometry.coordinates);
  }
};

CircleMode.toDisplayFeatures = function (state, geojson, display) {
  const isActiveLine = geojson.properties.id === state.line.id;
  geojson.properties.active = (isActiveLine) ? 'true' : 'false';
  if (!isActiveLine) return display(geojson);

  // Only render the line if it has at least one real coordinate
  if (geojson.geometry.coordinates.length < 2) return null;
  geojson.properties.meta = 'feature';

  // displays first vertex as a point feature
  display(createVertex(
    state.line.id,
    geojson.geometry.coordinates[state.direction === 'forward' ? geojson.geometry.coordinates.length - 2 : 1],
    `${state.direction === 'forward' ? geojson.geometry.coordinates.length - 2 : 1}`,
    false,
  ));

  // displays the line as it is drawn
  display(geojson);

  // create custom feature for the current pointer position
  const currentVertex = {
    type: 'Feature',
    properties: {
      meta: 'currentPosition',
      radiusMetric: turfLength(geojson),
      // radiusStandard: displayMeasurements.standard,
      parent: state.line.id,
    },
    geometry: {
      type: 'Point',
      coordinates: geojson.geometry.coordinates[1],
    },
  };
  display(currentVertex);

  // display circle feature
  const circleFeature = state.circle.toGeoJSON();
  display(circleFeature);

  return null;
};

export default CircleMode;
