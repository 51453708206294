// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import { Button, Collapse, Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import CreateCreditCardForm from './CreateCreditCardForm';
import stylesDef from './styles';

interface ComponentProps {
  setTotalTax?: (arg0: number) => void;
  additonalCharges?: number;
}

export const CreateCreditCard: React.FC<ComponentProps> = ({ additonalCharges, setTotalTax }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const [expand, setExpand] = useState(false);

  const handleCancel = () => {
    setExpand(false);
  };

  return (
    <Box>
      <Button sx={styles.button} onClick={() => setExpand(!expand)}>
        <AddCircleOutline sx={styles.addIcon} />
        <Typography sx={styles.buttonText} variant="h4">Add New Payment Method</Typography>
      </Button>
      <Collapse in={expand} mountOnEnter unmountOnExit>
        <CreateCreditCardForm  additonalCharges={additonalCharges} setTotalTax={setTotalTax} onCancel={handleCancel} />
      </Collapse>
    </Box>
  );
};

export default CreateCreditCard;
