// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Dialog, DialogActions, DialogContent, Button, Typography } from '@mui/material/';
import { Box, useTheme } from '@mui/system';
import stylesDef from './styles';

interface WarningDeliverableDialogProps {
  showDialog: boolean;
  onOk: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onCancel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  titleText: string;
  questionText: string;
  okText: string;
  dependencyList?: any[];
  tifDependencyList?: any[];
  lasDependencyList?: any[];
}

const WarningDeliverableDialog = ({ showDialog, onOk, onCancel, titleText, questionText, okText, dependencyList,
  tifDependencyList, lasDependencyList }: WarningDeliverableDialogProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);

  return (
    <Dialog open={showDialog} onClose={onCancel} sx={styles.dialog}>
      <Box sx={styles.dialogBox}>
        <Box sx={styles.dialogTitle}>
          <Typography variant="h3" sx={styles.titleText}>{titleText}</Typography>
        </Box>
        <DialogContent sx={styles.dialogContent}>
          {dependencyList && dependencyList.map((dep) => (
            <Typography variant="body1" sx={styles.dependency}>{dep}</Typography>
          ))}
          {tifDependencyList && tifDependencyList.length > 0 && (
            <>
              <Typography variant="body1" sx={styles.dependencyTitle}>
                An orthomosaic(.tif) is required to process the following
              </Typography>
              {tifDependencyList.map((dep) => (
                <Typography variant="body1" sx={styles.dependency}>{dep}</Typography>
              ))}
            </>
          )}
          {lasDependencyList && lasDependencyList.length > 0 && (
            <>
              <Typography variant="body1" sx={styles.dependencyTitle}>
                A point cloud(.las or.laz) is required to process the following
              </Typography>
              {lasDependencyList.map((dep) => (
                <Typography variant="body1" sx={styles.dependency}>{dep}</Typography>
              ))}
            </>
          )}
        </DialogContent>
        <Typography variant="h3" sx={styles.titleText}>{questionText}</Typography>
        <DialogActions sx={styles.dialogActions}>
          <Button onClick={onOk} sx={styles.okButton}>{okText}</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

WarningDeliverableDialog.defaultProps = {
  dependencyList: [],
  tifDependencyList: [],
  lasDependencyList: [],
};

export default WarningDeliverableDialog;
