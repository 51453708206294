// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const useStyles = (theme: any) => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  icon: {
    fontSize: theme.spacing(2),
    cursor: 'pointer',
  },
  sidebarHeader: {
    fontSize: 17,
  },
  checkbox: {
    padding: theme.spacing(0.5),
  },
});

export default useStyles;
