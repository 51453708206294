// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  appBar: {
    position: 'relative',
    backgroundColor: 'white',
    color: theme.palette.darkGrey,
    minHeight: 'min-content',
    maxHeight: 250,
    zIndex: 100,
    boxShadow: '0px 2px 12px 0px rgba(0,0,0,0.1)',
  },
  appBarSession: {
    position: 'relative',
    backgroundColor: 'white',
    color: theme.palette.darkGrey,
    minHeight: 'min-content',
    maxHeight: 190,
  },
  toolbarContentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    width: '100%',
    minHeight: 72,
    maxHeight: 190,
  },
  toolbarNoTabs: {
    minHeight: 112,
  },
  backButton: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    alignItems: 'center',
    top: theme.spacing(3),
    left: 20,
  },
  adminName: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleTypography: {
    padding: '10px 0',
  },
  toolbarButtons: {
    top: theme.spacing(2),
    position: 'absolute',
    right: 10,
  },
  toolbarButtonsDashboard: {
    top: theme.spacing(2),
    position: 'absolute',
    right: 10,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 10,
    top: '80%',
    right: 100,
    margin: '0 auto',
  },
  toolbarTextButton: {
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  toolbarText: {
    textTransform: 'none',
  },
  logo: {
    height: '16px',
    width: '64px',
    marginRight: '20px',
  },
  tabLabel: {
    textTransform: 'none',
  },
  projectNameDashboard: {
    gridArea: 'projectName',
    display: 'flex',
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(2),
  },
  projectName: {
    gridArea: 'projectName',
    display: 'flex',
    paddingBottom: theme.spacing(4),
    marginLeft: theme.spacing(2),
  },
  avatar: {
    marginRight: theme.spacing(2),
    fontWeight: 300,
  },
  progressText: {
    gridArea: 'processProgress',
    justifySelf: 'center',
    alignSelf: 'center',
    width: 450,
  },
  betaBanner: {
    marginTop: '10px',
    marginLeft: '10px',
    background: 'linear-gradient(90deg, #9038F5 0%, #3C34B7 33.29%, #2075FE 74.06%, #01A1DD 100%)',
    color: 'white',
    fontSize: '14px',
    height: '25px',
    paddingLeft: '10px',
    paddingTop: '3px',
    width: '60px',
  },
});

export default styles;
