// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  dateAndButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  buttons: {
    zIndex: 2000,
  },
  iconButton: {
    padding: '8px 8px 8px 12px',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: '#fafafa',
    },
  },
});

export default styles;
