// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

/* eslint-disable */
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import React, { useEffect, useImperativeHandle, useRef } from "react";
import { useControl } from "react-map-gl";

import type { MapRef, ControlPosition } from "react-map-gl";

type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  position?: ControlPosition;
  onCreate?: (evt: { features: object[] }) => void;
  onUpdate?: (evt: { features: object[]; action: string }) => void;
  onDelete?: (evt: { features: object[] }) => void;
  onDrawSelectionChange?: (evt: { features: object[] }) => void;
  onDrawModeChange?: (evt: { mode: string }) => void;
};

const DrawControl = React.forwardRef((props: DrawControlProps, ref) => {
  const mapDraw = useRef<MapboxDraw | null>(null);
  useImperativeHandle(ref, () => ({
    get draw() {
      return mapDraw.current;
    },
  }));

  useControl<MapboxDraw>(
    () => {
      const draw = new MapboxDraw(props);
      mapDraw.current = draw;
      return draw;
    },
    ({ map }: { map: MapRef }) => {
      map.on("draw.create", props.onCreate);
      map.on("draw.update", props.onUpdate);
      map.on("draw.delete", props.onDelete);
      map.on("draw.selectionchange", props.onDrawSelectionChange);
      map.on("draw.modechange", props.onDrawModeChange);
    },
    ({ map }: { map: MapRef }) => {
      map.off("draw.create", props.onCreate);
      map.off("draw.update", props.onUpdate);
      map.off("draw.delete", props.onDelete);
      map.off("draw.selectionchange", props.onDrawSelectionChange);
      map.off("draw.modechange", props.onDrawModeChange);
    },
    {
      position: props.position,
    }
  );

  useEffect(() => {
    if (mapDraw.current && props.initialFeatures) {
      // Delete all existing features
      mapDraw.current.deleteAll();

      // Add new features
      props.initialFeatures.forEach((feature) => {
        mapDraw.current.add(feature);
      });
    }
  }, [props.initialFeatures]);

  useEffect(() => {
    if (mapDraw.current && props.mode) {
      // If we're entering direct_select mode, get the featureId from props
      const options =
        props.mode === "direct_select" && props.featureId
          ? { featureId: props.featureId }
          : undefined;

      // Change to the specified mode
      mapDraw.current.changeMode(props.mode, options);
    }
  }, [props.mode, props.featureId]);

  return null;
});

DrawControl.defaultProps = {
  onCreate: () => {},
  onUpdate: () => {},
  onDelete: () => {},
  onDrawSelectionChange: () => {},
  onDrawModeChange: () => {},
};

export default DrawControl;