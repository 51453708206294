// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import {
  Dialog,
  Typography,
  Button,
  DialogActions,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import { Box, useTheme } from '@mui/system';
import stylesDef from './styles';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { SetImageryCaptureDateAction } from 'Features/fileUpload/fileUploadActions';

export const ImageryCaptureDialog = ({
  showDialog,
  provider,
  onCancel,
  onNext,
}) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const { imageryCaptureDate } = useAppSelector((state) => state.fileUpload);

  const [disableNext, setDisableNext] = useState(true);

  const onClose = () => {
    dispatch(SetImageryCaptureDateAction(null));
    setDisableNext(true);
    onCancel();
  };

  const handleDateChange = (date: Moment) => {
    // eslint-disable-next-line no-unused-expressions
    date && dispatch(SetImageryCaptureDateAction(date.format('MM/DD/yyyy')));
    setDisableNext(!date?.isValid() || date?.isAfter());
  };

  return (
    <Dialog
      open={showDialog}
    >
      <Box sx={styles.dialogBox}>
        <Typography variant="h5" sx={styles.dialogText}>
          Imagery Capture Date
        </Typography>
        <Typography>
          Please add the capture date from
          {' '}
          {provider}
          {' '}
          for this file.
        </Typography>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            allowKeyboardControl={false}
            autoOk
            variant="inline"
            value={moment(imageryCaptureDate, "MM/DD/yyyy")}
            onChange={handleDateChange}
            sx={styles.datePicker}
            label="Imagery Capture"
            placeholder="Imagery Capture"
            disableToolbar
            disableFuture
            PopoverProps={{
              styles: {
                root: styles.calender,
              },
            }}
          />
        </LocalizationProvider>
        <DialogActions sx={styles.dialogActions}>
          <Button onClick={onClose} variant="text">
            Dismiss
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              onNext();
              onCancel();
            }}
            disabled={!imageryCaptureDate || disableNext}
          >
            Next
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ImageryCaptureDialog;
