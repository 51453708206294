// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, Button, Typography } from '@mui/material/';
import { formatValue, deliverableCost } from 'Utils/deliverables';
import ErrorIcon from '@mui/icons-material/Error';
import { Box, useTheme } from '@mui/system';
import { useSelector } from 'react-redux';
import { RootState } from 'Store';
import { getOrders } from 'Features/order/orderSelectors';
import { useAppSelector } from 'Hooks';
import stylesDef from './styles';

interface TalkToSalesDialogProps {
  showDialog: boolean;
  onOk: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onCancel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onProceed:(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  titleText: string;
  addOnLayerDollars: number;
  addOnDeliverableDollars: number;
}

const TalkToSalesDialog = ({ showDialog, titleText, onOk, onCancel, addOnDeliverableDollars, addOnLayerDollars, onProceed }: TalkToSalesDialogProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);

  const { additionalDeliverables, selectedBundle, totalCost: bundleCost } = useAppSelector(
    (state) => state.bundle,
  );
  const { org } = useAppSelector(
    (state) => state.account,
  );
  const { acreAdjustmentFactor } = selectedBundle || {};
  const { availableCreditAcreage, costPerAcre } = org || {};
  const { activeOrder } = useSelector((state: RootState) => getOrders(state));

  const costB = availableCreditAcreage * costPerAcre + bundleCost + addOnLayerDollars + addOnDeliverableDollars;
  const [costA, setCostA] = useState(0);

  useEffect(() => {
    let orderAdjustedAcres = activeOrder.acreage;
    let addOnCost = 0;
    if (activeOrder.isEstimate) {
      orderAdjustedAcres = activeOrder.acreage * acreAdjustmentFactor;
      if (activeOrder.addOns.length > 0 && additionalDeliverables.length > 0) {
        activeOrder.addOns.forEach((addOn) => {
          const additional = additionalDeliverables.find((deliverable: IDeliverable) => deliverable.name === addOn.name);
          const additionalAcreFactor = additional ? additional.acreFactor : 0;
          const acreCost = deliverableCost(additionalAcreFactor, activeOrder.acreage);
          addOnCost += (acreCost * costPerAcre);
        });
      }
    }
    setCostA(orderAdjustedAcres * costPerAcre + addOnCost);
  }, [acreAdjustmentFactor, additionalDeliverables]);

  return (
    <Dialog open={showDialog} onClose={onCancel} sx={styles.dialog}>
      <Box sx={styles.dialogBox}>
        <Box sx={styles.dialogTitle}>
          <ErrorIcon sx={styles.warningIcon} />
          <Typography variant="h3" sx={styles.titleText}>{titleText}</Typography>
          <DialogActions>
            <Button onClick={onCancel}>Close</Button>
          </DialogActions>
        </Box>
        <DialogContent>
          <Typography>
            Your project size exceeds your plan's available credits. You could save
            {' '}
            <strong>{`$${formatValue(costB - costA)}`}</strong>
            {' '}
            on this project by renewing your plan. Reach out to sales at
            {' '}
            <a style={styles.linkColor} target="_blank" href="mailto:sales@airworks.io" rel="noreferrer">sales@airworks.io</a>
            {' '}
            to get started.
            <Typography sx={styles.title} variant="body1">Not renewing will result in an increased cost of $85 per acre</Typography>
          </Typography>
          <Box sx={styles.compare}>
            <Box>
              <Typography sx={styles.title} variant="h5">If you renew</Typography>
              <Typography sx={styles.subTitle} variant="h6">Cost</Typography>

              <Typography sx={styles.cost} variant="h2" color="primary">{`$${formatValue(costA)}`}</Typography>

              <Typography sx={styles.subTitle} variant="h6">
                {activeOrder.acreage}
                {' '}
                credits
              </Typography>
              <Typography sx={styles.cost} variant="h2" color="primary">{`($${costPerAcre.toFixed(2)}/cr)`}</Typography>
            </Box>
            <Box
              sx={styles.line}
            />
            <Box>
              <Typography sx={styles.title} variant="h5">If you don't renew</Typography>
              <Typography sx={styles.subTitle} variant="h6">Cost</Typography>

              <Typography sx={styles.cost} variant="h2">{`$${formatValue(costB)}`}</Typography>

              <Typography sx={styles.subTitle} variant="h6">
                {activeOrder.acreage}
                {' '}
                credits
              </Typography>
              <Typography sx={styles.cost} variant="h2">($85.00/ac)</Typography>
            </Box>
          </Box>
          <Typography sx={styles.terms}>* The prices shown here are estimates. Final project costs may change at AirWorks' discretion, based on project details.</Typography>
          <hr style={styles.hrLine} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onOk} sx={styles.contactButton}>Renew My Plan</Button>
          <Button onClick={onProceed} color="primary" sx={styles.okButton}>Proceed Anyway</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default TalkToSalesDialog;
