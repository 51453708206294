// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dispatch } from 'redux';
import * as geoViewport from '@mapbox/geo-viewport';
import mapboxgl from 'mapbox-gl';
import moment from 'moment';
import { getJson, headRequest } from 'Utils/http';
import { GetRasterMetadataUrl, GetTifFileSizeUrl, GetRasterLogFileUrl, API_URL, MAPBOX_URL } from 'Config';
import { getOrders } from 'Features/order/orderSelectors';
import type { RootState } from 'Store';
import { defaultExpressAILayers } from 'Utils/constants';
import { selectAllCoordinates } from '../projectList/projectListSelector';
import {
  SetMarkerAction,
  ClearMarkerAction,
  ClearTileJsonAction,
  SetLasBBoxAction,
  SetMapStyleAction,
  SetViewportAction,
  SetLargeTifCount,
  SetLargeTifAcknowledgedAction,
  SetNewLargeTifAction,
  GetTileJsonStartAction,
  GetTileJsonStopAction,
  GetRasterTileJsonAction,
  GetVectorTileJsonAction,
  SetLowResCreatedAtAction,
  ChangeVersionAction,
  ChangeRasterAction,
  SetAllRasterTilesLoadedAction,
  SetLasBBoxLoadingAction,
  ToggleCadDrawingsLayerAction,
  AddNewLayerToVTJAction,
  UpdateLayerVTJAction,
  DeleteLayerFromVTJAction,
  ToggleProjectLayerAction,
  LocationSearchStartAction,
  LocationSearchSuccessAction,
  ClearSuggestionsAction,
  ToggleNoneVectorTilesAction,
  AdminToggleCadDropdownAction,
  SetDxfExtentAction,
} from './mapCommonActions';

export const SetMarker = (center: [number, number]) => (dispatch: Dispatch) => dispatch(SetMarkerAction(center));

export const ToggleMapStyle = () =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const currentMapStyle = getState().map.common.mapStyle;
    const newStyle = currentMapStyle === 'streets' ? 'satellite' : 'streets';
    dispatch(SetMapStyleAction(newStyle));
  };

export const GoToSearchResultThunk = (searchResult: IPlacesSearchFeature) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    let center;
    let zoom;

    if (searchResult.bbox) {
      const { canvasSize } = getState().map.common;
      const v = geoViewport.viewport(searchResult.bbox, canvasSize, 0, 24, 512, true);
      ({ center, zoom } = v);
    } else if (searchResult.center) {
      ({ center } = searchResult);
      zoom = 14;
    }

    SetMarker(searchResult.center)(dispatch);
    dispatch(SetViewportAction({ center, zoom }));
  };

export const InitMapThunk = (showLayers: boolean, dashboard: boolean) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(ClearMarkerAction());
    dispatch(ClearTileJsonAction('raster'));
    dispatch(ClearTileJsonAction('vector'));
    dispatch(SetLasBBoxAction(null));
    dispatch(SetMapStyleAction('streets'));

    if (!dashboard) {
      await Promise.all([
        GetRasterTilesThunk()(dispatch, getState),
        GetLasBBoxThunk()(dispatch, getState),
        showLayers && GetVectorTilesThunk()(dispatch, getState),
      ]);
    }

    const usBbox: [number, number, number, number] = [
      -135.595703125002, 25.47023784007213,
      -64.40429687499537, 51.98973904401322,
    ];

    const { canvasSize } = getState().map.common;
    let viewportBounds: [number, number, number, number];

    const { lasBBox } = getState().map.common;
    const filesObject = getState().map.common.rasterTileJson;

    let coordinates: number[][] = [];
    if (!dashboard) {
      const { kmls } = getState().kml.present;
      coordinates = Object
        .keys(kmls)
        .map((id) => {
          const kml = kmls[id];
          if (!kml || !kml.featureCollection.features[0]) return null;

          return kml.featureCollection.features[0].geometry.coordinates[0];
        })
        .reduce((prev, current) => prev.concat(current), [])
        .filter((kml) => kml);
    } else {
      coordinates = selectAllCoordinates(getState()).map((c) => c.coords);
    }

    if (coordinates.length) {
      const bounds = coordinates.reduce(
        (b, coord) => b.extend(new mapboxgl.LngLat(coord[0], coord[1])),
        new mapboxgl.LngLatBounds(coordinates[0] as [number, number], coordinates[0] as [number, number]),
      );

      const boundsResult: [number, number, number, number] = [
        bounds.getSouthWest().lng, bounds.getSouthWest().lat,
        bounds.getNorthEast().lng, bounds.getNorthEast().lat,
      ];

      viewportBounds = boundsResult;
    } else if (filesObject && Object.keys(filesObject).length > 0) {
      // Get new bounds based on bounds for all TIF Files
      const x1 = [];
      const y1 = [];
      const x2 = [];
      const y2 = [];
      for (let i = 0; i < Object.keys(filesObject).length; i += 1) {
        x1.push(filesObject[Object.keys(filesObject)[i]].bounds[0]);
        y1.push(filesObject[Object.keys(filesObject)[i]].bounds[1]);
        x2.push(filesObject[Object.keys(filesObject)[i]].bounds[2]);
        y2.push(filesObject[Object.keys(filesObject)[i]].bounds[3]);
      }
      viewportBounds = [Math.min.apply(null, x1), Math.min.apply(null, y1), Math.max.apply(null, x2), Math.max.apply(null, y2)];
    } else if (lasBBox?.length === 4) {
      viewportBounds = lasBBox;
    } else if (navigator.geolocation) {
      return new Promise((resolve) => {
        navigator
          .geolocation
          .getCurrentPosition((position) => {
            const center: [number, number] = [position.coords.longitude, position.coords.latitude];
            dispatch(SetViewportAction({ center, zoom: 14 }));
            resolve();
          }, () => resolve());
      });
    } else {
      viewportBounds = usBbox;
    }
    const v = geoViewport?.viewport(viewportBounds, canvasSize, 0, 24, 512, true);
    dispatch(SetViewportAction(v));

    return Promise.resolve();
  };

export const ClearTileJson = (type: TileJsonType) => (dispatch: Dispatch) => dispatch(ClearTileJsonAction(type));

export const ToggleNoneVectorTiles = () => (dispatch: Dispatch) => dispatch(ToggleNoneVectorTilesAction());

export const ChangeVersion = () => (dispatch: Dispatch) => dispatch(ChangeVersionAction());

export const GetDxfExtentThunk = (siteId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { token } = getState().auth;
    const result = await getJson<string>(`${API_URL}/tiles/${siteId}/extent`, token);
    if (result.success) {
      const extent = result.data.split(/\(|\)/)[1].split((/,| /)).map((coor) => +coor);
      dispatch(SetDxfExtentAction(extent));
    }
  };

export const StopLoading = () => (dispatch: Dispatch) => {
  dispatch(GetTileJsonStopAction('raster'));
  dispatch(GetTileJsonStopAction('vector'));
  dispatch(ClearTileJsonAction('raster'));
  dispatch(ClearTileJsonAction('vector'));
};

export const AdminToggleCadDropdown = (orderId: string, fileVersion: string) => (dispatch: Dispatch) => dispatch(AdminToggleCadDropdownAction({ orderId, fileVersion }));

export const GetRasterTilesThunk = () =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const type = 'raster';
    const { user } = _ADMIN_ ? getState().admin : getState().auth;
    const { project } = getState().project;
    const { allRasterTilesLoaded, largeTifCount } = getState().map.common;
    if (!project.tifFiles || project.tifFiles?.length === 0) {
      ClearTileJson(type)(dispatch);
      return;
    }

    // Extracts name of file from the filepath string, makes sure to remove the last '.' from the filename
    const tifFileNames = project.tifFiles?.map((tifFile) => {
      const fileName = tifFile.filename.split('/').pop();
      const tifFileName = fileName.substring(0, fileName.lastIndexOf('.'));
      return tifFileName;
    });

    const organization = _ADMIN_ || 'opsTrainer' in getState().auth.resources ? project.ownerOrganization : user.ownerOrganization;

    const promiseArray = tifFileNames.map(async (file) => {
      const url = GetRasterMetadataUrl(organization, project._id, file);
      const response = await getJson<ITileMetadata>(url);
      return response;
    });

    const results: {
      [key: string]: IRasterTileJson,
    } = {};

    const lowResCreatedAt: moment.Moment[] = [];

    const requestResult = await Promise.all(promiseArray);
    const anyFailed = requestResult.some((r) => !r.success);
    if (anyFailed) {
      const tifFileCheck = tifFileNames.map(async (file) => {
        const checkBucketUrl = GetTifFileSizeUrl(organization, project._id, file);
        const response = await headRequest(checkBucketUrl);
        return response;
      });
      const tifFileResults = await Promise.all(tifFileCheck);
      const noneFailed = tifFileResults.every((r) => r.success);
      let tifSizes: number[] = [];
      if (noneFailed) {
        tifSizes = tifFileResults.map((file) => parseInt(String(file.data), 10));
      }
      // Files larger than 4 GB are large files
      const newLargeTifCount = tifSizes.filter((size) => (size / 1000000000) > 4).length;

      if (newLargeTifCount > largeTifCount) {
        dispatch(SetLargeTifCount(largeTifCount + 1));
        dispatch(SetLargeTifAcknowledgedAction(false));
        dispatch(SetNewLargeTifAction());
      }
      // Setting rasterLoading to true from here
      dispatch(GetTileJsonStartAction(type));
      ClearTileJson(type)(dispatch);
    } else {
      requestResult.forEach((fileObject) => {
        let bounds: [number, number, number, number] = null;
        let isTms = false;

        const fileInfo = fileObject.data;
        if (fileInfo.bounds) {
          const parsedBounds = fileInfo.bounds.split(',').map((s) => Number(s));
          if (parsedBounds.length === 4) {
            bounds = parsedBounds as [number, number, number, number];
          }
        }
        if (fileInfo.createdAt) {
          const fileDate = moment.utc(fileInfo.createdAt, 'X');
          lowResCreatedAt.push(fileDate);
        }
        if (fileInfo.tiletype === 'tms') {
          isTms = true;
        }
        results[fileInfo.name.substr(9)] = { bounds, isTms };
      });
    }

    if (Object.keys(results).length) {
      dispatch(GetRasterTileJsonAction(results));
      CheckHighResolutionRasterThunk()(dispatch, getState);
    }
    // metadata.json is available in the bucket(that is how we have the lowResCreatedAt dates)
    // log.txt not in the folder yet, after checking
    if (!allRasterTilesLoaded && lowResCreatedAt.length >= 1) {
      const mostRecentLowResCreatedAt = moment.max(lowResCreatedAt);
      dispatch(SetLowResCreatedAtAction(moment.utc(mostRecentLowResCreatedAt).format()));
    }
    dispatch(ChangeRasterAction());
    if (tifFileNames.length === Object.entries(results).length) {
      dispatch(GetTileJsonStopAction(type));
    }
  };

export const CheckHighResolutionRasterThunk = () =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { rasterTileJson } = getState().map.common;
    const { user } = _ADMIN_ ? getState().admin : getState().auth;
    const { project } = getState().project;
    const organization = _ADMIN_ || 'opsTrainer' in getState().auth.resources ? project.ownerOrganization : user.ownerOrganization;

    const logFilePromiseArray = Object.keys(rasterTileJson).map(async (file) => {
      const logFileUrl = GetRasterLogFileUrl(organization, project._id, file);
      const logFileResponse = await headRequest(logFileUrl);
      return logFileResponse;
    });
    const logFileRequestResult = await Promise.all(logFilePromiseArray);
    const logFileAnyFailed = logFileRequestResult.some((r) => !r.success);
    if (!logFileAnyFailed) {
      dispatch(SetAllRasterTilesLoadedAction(true));
    } else {
      dispatch(SetAllRasterTilesLoadedAction(false));
    }
  };

export const GetVectorTilesThunk = (mode?: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { user } = getState().auth;
    const orders = getOrders(getState());
    const vectorTileJson = getState().map.common.vectorTileJson || {};
    const fileVersions = getState().order.fileVersions || {};
    const type = 'vector';
    const organization = _ADMIN_ ? getState().admin.orgId : user.ownerOrganization;
    const { token } = getState().auth;

    if (Object.keys(fileVersions).length === 0) {
      return;
    }
    const processedOrders = orders
      .projectOrders
      .sort((a, b) => (a.updatedAt > b.updatedAt ? 0 : 1))
      .filter((o) => o.cadFiles && o.cadFiles.length > 0)
      .filter((o) => !vectorTileJson[o._id] || (vectorTileJson[o._id] && !vectorTileJson[o._id][fileVersions[o._id]]));

    const promiseArray =
      processedOrders.filter((o) => fileVersions[o._id])
        .map((o) => {
          const url = `${API_URL}/tiles/${fileVersions[o._id]}/uniqueLayersList`;
          return (async () => ({
            orderId: o._id,
            version: fileVersions[o._id],
            result: await getJson<ILayerAttributes[]>(url, token),
          }))();
        });

    const requestResult = await Promise.all(promiseArray);

    const result: {
      [key: string]: {
        [key: string]: IVectorTileJson
      },
    } = {};

    processedOrders.forEach((order) => {
      order.cadFiles.forEach((file) => {
        const version = file._id;
        const tileJson = requestResult.find((d) => d.orderId === order._id && d.version === version);
        const expressAIOrder = order.bundleName === '2D Only - Express AI';

        if (!tileJson || !tileJson.result.success) {
          dispatch(GetTileJsonStartAction(type));
          return;
        }

        const { data } = tileJson.result;

        const vectorTileJsonObj: any = {};

        if (data) {
          vectorTileJsonObj.vector_layers = [];
          data.forEach((l: any) => {
            const visible = _ADMIN_ || (expressAIOrder ? defaultExpressAILayers.includes(l.name) : true);
            vectorTileJsonObj.vector_layers.push({
              id: l.name,
              visible,
              color: l.color,
              lineType: l.line_type,
              lineWidth: l.line_width,
              layerId: l.layer_id,
              originEpsg: l.origin_epsg,
              srid: l.srid,
            });
          });
          vectorTileJsonObj.visible = true;
        }

        result[order._id] = result[order._id] || {};
        result[order._id][version] = vectorTileJsonObj;
        dispatch(GetTileJsonStopAction(type));
      });
    });

    dispatch(GetVectorTileJsonAction(result));
    dispatch(ChangeVersionAction());

    if (_ADMIN_) {
      dispatch(GetTileJsonStopAction(type));
    } else if (Object.entries(result).length === 0 && processedOrders.length === 0) {
      dispatch(GetTileJsonStopAction(type));
    }

    // eslint-disable-next-line no-unused-expressions
    // dispatch(GetTileJsonStopAction(type));
  };

export const GetLasBBoxThunk = () =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { project } = getState().project;
    let bboxArray: [number, number, number, number][] = [];
    let lasBounds: [number, number, number, number] = null;

    // Exclude output las files
    const inputLasFiles = project.lasFiles?.filter((lasFile) => !lasFile.ownerOrder);
    if (inputLasFiles?.length) {
      const lasFilesWithBbox = inputLasFiles.filter((lasFile) => lasFile.bbox?.length && !!(lasFile.bbox[0]));
      // If none of the las files uploaded for the project have a bbox field, load till the bbox is found
      if (lasFilesWithBbox?.length) {
        bboxArray = lasFilesWithBbox.map((lasFile) => lasFile.bbox);
      } else {
        dispatch(SetLasBBoxLoadingAction(true));
      }
    }

    if (!bboxArray.length) return;
    if (bboxArray.length === 1) {
      [lasBounds] = bboxArray;
    } else {
      lasBounds = bboxArray.reduce((combined, current) => ([
        Math.min(combined[0], current[0]),
        Math.min(combined[1], current[1]),
        Math.max(combined[2], current[2]),
        Math.max(combined[3], current[3]),
      ]));
    }
    if (lasBounds?.length) {
      dispatch(SetLasBBoxAction(lasBounds));
    }
    if (inputLasFiles?.length === bboxArray.length) {
      dispatch(SetLasBBoxLoadingAction(false));
    } else {
      dispatch(SetLasBBoxLoadingAction(true));
    }
  };

export const ToggleCadDrawingsThunk = (orderId?: string, fileVersion?: string) => (dispatch: Dispatch) => dispatch(ToggleCadDrawingsLayerAction({ orderId, fileVersion }));

export const AddNewLayerToVTJ = (orderId: string, siteId: string, layer: IVectorLayer) => (dispatch: Dispatch, getState: () => RootState) => {
  dispatch(AddNewLayerToVTJAction({ orderId, siteId, layer }));
};

// Function to update the layer attributes in Vector Tile Json when editLayer API returns the updated layer
export const UpdateLayerVTJ = (orderId: string, siteId: string, layer: IVectorLayer) => (dispatch: Dispatch, getState: () => RootState) => {
  dispatch(UpdateLayerVTJAction({ orderId, siteId, layer }));
};

export const DeleteLayerVTJ = (orderId: string, siteId: string, layerId: Number) => (dispatch: Dispatch, getState: () => RootState) => {
  dispatch(DeleteLayerFromVTJAction({ orderId, siteId, layerId }));
};

export const ToggleProjectLayer = (orderId?: string, id?: string, fileVersion?: string) => (dispatch: Dispatch) => {
  dispatch(ToggleProjectLayerAction({ orderId, id, fileVersion }));
};

export const MapSearchThunk = (query: string) =>
  async (dispatch: Dispatch) => {
    const url = `${MAPBOX_URL}/mapbox.places/${query}.json?access_token=${_MAPBOX_KEY_}`;
    dispatch(LocationSearchStartAction());
    const result = await getJson<ISearchResponse>(url);

    const suggestions = result.data.features.map((feature) => ({
      text: feature.text,
      description: feature.place_name,
      center: feature.center,
      bbox: feature.bbox,
    }));

    dispatch(LocationSearchSuccessAction(suggestions));
  };

export const MapClearSuggestionsThunk = () =>
  async (dispatch: Dispatch) => {
    dispatch(ClearSuggestionsAction());
  };
