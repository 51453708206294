// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Button, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import SVG from 'react-inlinesvg';
import properties from 'Assets/properties.svg';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { SetCurrentPanel } from 'Features/map/mapEditorThunk';
import stylesDef from './styles';

export const ObjectProperties = () => {
  const styles = stylesDef();
  const dispatch = useAppDispatch();
  const { resources } = useAppSelector((state) => state.auth);
  const editorFunctions = 'editorFunctions' in resources;
  const propertiesPanel = 'propertiesPanel' in resources;
  const { currentPanel } = useAppSelector((state) => state.map.editor.present);

  return (
    <Box sx={(currentPanel === 'objectProperties') ? styles.panelCurrent : {}}>
      {(editorFunctions && propertiesPanel) && (
        <Tooltip title="Coming Soon">
          <Button
            style={{ opacity: 0.5 }}
            sx={styles.option}
            // onClick={() => {
            //   dispatch(SetCurrentPanel("objectProperties"));
            // }}
          >
            <SVG src={properties} title="properties" css={styles.icon} />
          </Button>
        </Tooltip>
      )}
    </Box>
  );
};

export default ObjectProperties;
