// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { TransitionProps } from '@mui/material/transitions/transition';
import { Dialog, Slide, IconButton, LinearProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Box, useTheme } from '@mui/system';
import { useAppDispatch, useAppSelector } from 'Hooks';
import CreateProjectForm from './CreateProjectForm';
import stylesDef from './styles';
import { SetProjectIdAction } from 'Features/project/projectActions';
import MultipleFileUploadForm from '../FileUploadForm/MultipleFileUploadForm';

const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => <Slide ref={ref} direction="up" {...props} />);

const CreateProjectDialog = ({ showDialog, setShowDialog, getProjectUrl }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loading, projectId } = useAppSelector(
    (state) => state.project,
  );

  const [showFileUpload, setShowFileUpload] = useState(false);
  useEffect(() => {
    if (showDialog) {
      setShowFileUpload(false);
    }
  }, [showDialog]);

  return (
    <Dialog
      fullScreen
      open={showDialog}
      onClose={() => setShowDialog(false)}
      TransitionComponent={Transition}
      keepMounted={false}
    >
      {loading && (
        <>
          <LinearProgress sx={styles.progress} />
          <Box sx={styles.backdrop} />
        </>
      )}
      <Box sx={styles.closeButtonWrapper}>
        <IconButton
          color="inherit"
          onClick={() => {
            setShowDialog(false);
          }}
          aria-label="Close"
        >
          <CloseIcon fontSize="large" />
        </IconButton>
      </Box>
      <Box sx={styles.contentWrapper}>
        {
          !showFileUpload ? (
            <CreateProjectForm
              onCreate={(project) => {
                navigate(getProjectUrl(project._id));
                setShowDialog(false);
              }}
              onUpload={(project) => {
                dispatch(SetProjectIdAction(project._id));
                setShowFileUpload(true);
              }}
              onCreateEstimate={(project) => {
                dispatch(SetProjectIdAction(project._id));
                navigate(getProjectUrl(project._id));
                setShowDialog(false);
              }}
            />
          ) : (
            <MultipleFileUploadForm
              onSuccess={() => {
                navigate(getProjectUrl(projectId));
                setShowDialog(false);
              }}
            />
          )
        }
      </Box>
    </Dialog>
  );
};

CreateProjectDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  setShowDialog: PropTypes.func.isRequired,
  getProjectUrl: PropTypes.func.isRequired,
};

export default CreateProjectDialog;
