// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { Box, useTheme } from '@mui/system';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { Logout, UpdateUserTnCDateThunk } from 'Features/auth/authThunk';
import stylesDef from './styles';

const TermsAndConditionsDialog = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const [showDialog, setshowDialog] = useState(true);
  const { html } = useAppSelector(
    (state) => state.auth,
  );

  const onAcceptClick = () => {
    dispatch(UpdateUserTnCDateThunk());
    setshowDialog(false);
  };

  const onRejectClick = () => {
    dispatch(Logout());
  };

  return (
    <Dialog
      open={showDialog}
      sx={styles.dialog}
      disableEscapeKeyDown
    >
      <DialogContent
        sx={styles.dialogContent}
      >
        <Box sx={styles.dialogWrapper} dangerouslySetInnerHTML={{ __html: html }} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onAcceptClick}
          color="primary"
        >
          Accept
        </Button>
        <Button onClick={onRejectClick} color="primary">
          Reject
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TermsAndConditionsDialog;
