// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

export default function moveCurve([type, ...rest], delta) {
  if (type === 'circularstring' || type === 'linestring') {
    return [
      type,
      ...rest.map(([lng, lat]) => [lng + delta.lng, lat + delta.lat]),
    ];
  }
  return [type, ...rest.map((el) => moveCurve(el, delta))];
}
