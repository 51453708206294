// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import { Box, useTheme } from '@mui/system';
import ErrorIcon from '@mui/icons-material/Error';
import {
  Button,
  Collapse,
  Typography,
  Fade,
  CircularProgress,
  Dialog,
} from '@mui/material';
import { Form } from 'react-final-form';
import { useAppDispatch } from 'Hooks';
import { useSelector } from 'react-redux';
import { getOrders } from 'Features/order/orderSelectors';
import { RootState } from 'Store';
import { selectAvailableTeams } from 'Features/account/accountSelectors';
import { EditOrderThunk } from 'Features/order/orderThunk';
import { GetOrgTeamsThunk } from 'Features/account/accountThunk';
import { Select, TextField } from 'Components/forms';
import stylesDef from './styles';

const EditOrder = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [initialFormState, setInitialFormState] = useState(undefined);

  const { activeOrder } = useSelector((state: RootState) => getOrders(state));
  const { _id: orderId, referenceId, team } = activeOrder;

  const { availableTeams, availableTeamNames } = useSelector((state: RootState) => selectAvailableTeams(state));
  const currentTeam = availableTeams.find((t) => t._id === team);

  const handleClickOpen = () => {
    setInitialFormState({ referenceId, team: currentTeam?.name });
    setOpen(true);
  };

  useEffect(() => {
    dispatch(GetOrgTeamsThunk());
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const onFormSubmit = async (values: any) => {
    const teamInput = values.team || '';
    const referenceIdInput = values.referenceId || '';
    const selectedTeam = availableTeams.find((t) => t.name === teamInput);
    const teamChange = teamInput !== currentTeam?.name ? teamInput : 'N/A';
    const referenceIdChange = referenceIdInput !== activeOrder.referenceId ? referenceIdInput : 'N/A';

    window?.pendo?.track('Order Checkout', { team_name: teamChange, ref_id: referenceIdChange, projectId: activeOrder?.ownerProject });

    await dispatch(EditOrderThunk({ orderId, referenceId: referenceIdInput, team: selectedTeam?._id }));

    handleClose();
  };

  return (
    <div>
      <Box sx={styles.title}>
        <div>
          <Typography sx={styles.sectionTitle} variant="h3">Order Details</Typography>
        </div>
        <Button
          sx={styles.addTeamButton}
          onClick={handleClickOpen}
        >
          EDIT
        </Button>
      </Box>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <Form
          onSubmit={onFormSubmit}
          keepDirtyOnReinitialize
          initialValues={initialFormState}
          render={({ hasSubmitErrors, modifiedSinceLastSubmit, submitting, submitError, handleSubmit }) => (
            <form
              style={styles.form}
              onSubmit={handleSubmit}
            >
              <Typography variant="h2" sx={styles.heading}>Edit Order</Typography>
              <Box sx={styles.formRow}>
                <TextField
                  label="Reference Id"
                  name="referenceId"
                  variant="outlined"
                  sx={styles.textFiled}
                  // inputProps={{
                  //   name: referenceId,
                  //   classes: {
                  //     notchedOutline: classes.outline,
                  //     input: classes.input,
                  //   },
                  // }}
                  // inputLabelProps={{
                  //   classes: {
                  //     outlined: classes.inputLabel,
                  //     shrink: classes.inputLabelShrink,
                  //   },
                  // }}
                />
              </Box>
              {availableTeamNames.length ? (
                <Box sx={styles.formRow}>
                  <Select
                    label="Team"
                    name="team"
                    values={availableTeamNames}
                    disabled
                    sx={styles.textFiled}
                    // inputProps={{
                    //   name: team,
                    //   classes: {
                    //     notchedOutline: classes.outline,
                    //     input: classes.input,
                    //   },
                    // }}
                    // inputLabelProps={{
                    //   classes: {
                    //     outlined: classes.inputLabel,
                    //     shrink: classes.inputLabelShrink,
                    //   },
                    // }}
                  />
                </Box>
              ) : ''}
              <Button
                variant="contained"
                color="primary"
                sx={styles.submitButton}
                type="submit"
                disabled={(hasSubmitErrors && !modifiedSinceLastSubmit) || submitting}
              >
                <Fade in={submitting}>
                  <CircularProgress size={24} sx={styles.buttonProgress} />
                </Fade>
                <Fade in={!submitting}>
                  <span>UPDATE</span>
                </Fade>
              </Button>
              <Collapse in={Boolean(hasSubmitErrors && !modifiedSinceLastSubmit)}>
                <Box sx={styles.errorMessage}>
                  <ErrorIcon />
                  <Typography variant="body1" sx={styles.messageText}>
                    {submitError}
                  </Typography>
                </Box>
              </Collapse>
            </form>
          )}
        />
      </Dialog>
    </div>
  );
};

export default EditOrder;
