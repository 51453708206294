// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  tableCellIcon: {
    borderBottom: 'none',
    width: '40px',
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
  },
  tableCell: {
    borderBottom: 'none',
    textAlign: 'left',
    width: '22%',
    fontSize: '1em',
  },
  tableCellName: {
    padding: '14px 10px',
  },
  nameText: {
    width: '160px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tableCellStyle: {
    padding: '8px 10px',
  },
  tableCellWeight: {
    padding: '12px 10px',
  },
  routerLink: {
    color: '#2E4DEB',
  },
  borderStyle: {
    border: '1px solid #000000',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    width: '180px',
    textAlign: 'left',
    height: '50px',
    cursor: 'pointer',
  },
  row: {
    display: 'flex',
    height: '66px',
  },
  input: {
    width: '100%',
    padding: '0px 21px 0px 0px',
  },
  select: {
    padding: '0px 21px 0px 0px',
    '& .MuiSelect-select': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  selectWeight: {
    display: 'flex',
    '& .MuiSelect-select': {
      padding: '0px 21px 0px 0px',
      display: 'flex',
      alignItems: 'center',
    },
  },
  lineStylePNG: {
    height: '1px',
    marginRight: '8px',
  },
  borderLineStyle: {
    border: '1px solid #000000',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    width: '235px',
    textAlign: 'left',
    height: '50px',
    cursor: 'pointer',
  },
  chips: {
    flexWrap: 'wrap',
  },
  tableCellLayers: {
    borderBottom: 'none',
    padding: '2px 4px',
    width: '235px',
  },
  badge: {
    height: '30px',
    width: '40px',
    backgroundColor: '#01A1DD',
    position: 'absolute',
    right: 0,
    top: '8px',
    color: '#FFFFFF',
    borderRadius: '15px',
    textAlign: 'center',
    verticalAlign: 'middle',
    lineHeight: '29px',
  },
  chip: {
    margin: 2,
  },
  chipRoot: {
    '&:not(:first-of-type)': {
      display: 'none',
    },
    borderRadius: '0',
    color: '#01A1DD',
  },
  selectLayers: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiSelect-select': {
      padding: '5px',
    },
  },
  selected: {
    color: '#01A1DD',
  },
  checked: {
    color: '#01A1DD !important',
  },
  compactPicker: {
    '& div': {
      zIndex: 1,
    },
  },
  horizontalLine: {
    backgroundColor: '#000000',
    width: '50%',
    marginRight: '10px',
    marginLeft: '0px',
  },
  layerColor: {
    float: 'left',
    height: '20px',
    width: '30px',
    border: '1px solid #000000',
    clear: 'both',
    marginRight: '20px',
  },
  selectMapping: {
    fontFamily: 'Work Sans',
    fontWeight: 300,
  },
  colorBox: {
    width: '115px',
  },
  mappingBox: {
    width: '235px',
  },
  cursor: {
    cursor: 'grab',
  },
});

export default styles;
