// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL
/* eslint-disable no-nested-ternary */

import React from 'react';
import { ButtonBase } from '@mui/material';
import SatelliteView from 'Assets/satelliteView.png';
import { useTheme } from '@mui/system';
import StreetsView from 'Assets/streetsView.png';
import ESRIImageryView from 'Assets/esri-imagery-view.png';
import ESRIStreetsView from 'Assets/esri-street-view.png';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { ToggleMapStyle } from '../mapCommonThunk';
import stylesDef from './styles';

export const MapStyleToggle = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const mapStyle = useAppSelector((state) => state.map.common.mapStyle);
  const { esriBasemap } = useFlags();

  return (
    <ButtonBase
      sx={styles.styleToggle}
      onClick={() => dispatch(ToggleMapStyle())}
    >
      <img src={mapStyle === 'streets' ? (esriBasemap ? ESRIImageryView : SatelliteView) : (esriBasemap ? ESRIStreetsView : StreetsView)} alt="Toggle Map Style" />
    </ButtonBase>
  );
};

export default MapStyleToggle;
