// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Typography, Divider } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { useAppSelector } from 'Hooks';
import CreditCardIcon from 'Components/CreditCardIcon';
import CreateCreditCardForm from 'Features/account/billing/PaymentInfo/CreateCreditCard/CreateCreditCardForm';
import CreateCreditCard from 'Features/account/billing/PaymentInfo/CreateCreditCard';
import CreditCard from 'Features/account/billing/PaymentInfo/CreditCard';
import stylesDef from './styles';

interface CardProps {
  setCards: (arg0: boolean) => void;
  setTotalTax?: (arg0: number) => void;
  checkFirstTimeChangeEstimate: (arg0: Function) => void;
  orderFormVisible: boolean;
  additonalCharges?: number;
}

const Card = ({ orderFormVisible, setCards, additonalCharges, setTotalTax, checkFirstTimeChangeEstimate }: CardProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const { creditCards } = useAppSelector(
    (state) => state.subscription,
  );
  const hasCards = creditCards.length > 0;
  if (!hasCards) {
    setCards(false);
  } else {
    setCards(true);
  }

  return (
    <>
      {!hasCards && (
        <>
          <Typography sx={styles.subSectionTitle} variant="h6">Billing Address and Card Details</Typography>
          <Box sx={styles.icons}>
            <Box sx={styles.iconWrapper}><CreditCardIcon cardType="visa" /></Box>
            <Box sx={styles.iconWrapper}><CreditCardIcon cardType="mastercard" /></Box>
            <Box sx={styles.iconWrapper}><CreditCardIcon cardType="diners-club" /></Box>
            <Box sx={styles.iconWrapper}><CreditCardIcon cardType="american-express" /></Box>
            <Box sx={styles.iconWrapper}><CreditCardIcon cardType="discover" /></Box>
          </Box>
          <CreateCreditCardForm additonalCharges= {additonalCharges} setTotalTax={setTotalTax} onCancel={() => {}} />
        </>
      )}
      {hasCards && (
        <>
          {orderFormVisible && (
            <>
              <Typography sx={styles.sectionTitle} variant="h3">Payment Information</Typography>
              <Divider sx={styles.divider} />
            </>
          )}
          {creditCards.map((card) => <CreditCard additonalCharges= {additonalCharges} setTotalTax={setTotalTax} card={card} checkFirstTimeChangeEstimate={checkFirstTimeChangeEstimate}/>)}
          <CreateCreditCard additonalCharges= {additonalCharges} setTotalTax={setTotalTax} />
        </>
      )}
    </>
  );
};

export default Card;
