// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  packageColumn: {
    padding: theme.spacing(2),
  },
  pageTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontWeight: 400,
    fontSize: '28px',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '100px',
  },
  alert: {
    color: theme.palette.error.main,
  },
  radioGroup: {
    flexDirection: 'row',
    gap: '80px',
    margin: '10px 0',
  },
  typeText: {
    textTransform: 'capitalize',
  },
});

export default styles;
