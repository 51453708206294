// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createSelector } from 'reselect';
import type { RootState } from 'Store';

const selectOrgTeams = (state: RootState) => state.account.orgTeams;

const selectTeams = (state: RootState) => state.auth.user.teams;

const selectCadTemplates = (state: RootState) => state.cadTemplate.list;

const selectDefaultTemplateId = (state: RootState) => state.account.org?.defaultTemplate;

const selectAvailableTeams = createSelector(
  [selectOrgTeams, selectTeams],
  (orgTeams, teams) => {
    const availableTeamNames: string[] = [];
    const availableTeams = orgTeams.filter((t) => {
      if (teams?.includes(t._id)) {
        availableTeamNames.push(t.name);
        return t._id;
      }
      return false;
    });

    return {
      availableTeams,
      availableTeamNames,
    };
  },
);

const selectDefaultTemplate = createSelector(
  [selectCadTemplates, selectDefaultTemplateId],
  (cadTemplates, defaultTemplateId) => {
    const defaultTemplate = cadTemplates?.find((u) => u._id === defaultTemplateId);

    return defaultTemplate;
  },
);

export {
  selectAvailableTeams,
  selectDefaultTemplate,
};
