// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import { Button, Fade, CircularProgress, Typography, Collapse } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { Form, FormSpy } from 'react-final-form';
import { useAppDispatch } from 'Hooks';
import { Helpers } from 'Components/forms';
import CreditCardControls from '../../CreditCardControls';
import stylesDef from './styles';
import { CreatePaymentInstrumentThunk } from 'Features/account/subscriptionThunk';
import { GetTaxThunk } from 'Features/order/orderThunk';

interface ComponentProps {
  onCancel: () => void;
  setTotalTax?: (arg0: number) => void;
  additonalCharges?: number;
}

export const CreateCreditCardForm: React.FC<ComponentProps> = ({ onCancel, additonalCharges, setTotalTax }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();

  const [formState, setFormState] = useState<{submitSucceeded? : any}>({});
  const createPaymentInstrument = (payload: ICreditCard) => dispatch(CreatePaymentInstrumentThunk(payload));
  const onFormSubmit = async (values: any) => {
    await Helpers.submit(values, createPaymentInstrument);
    if(additonalCharges > 0){
      const result = await dispatch(GetTaxThunk(additonalCharges));
      if (result.success) {
        setTotalTax(result.salesTaxAmount);
      }
    }
  };

  const handleFormStateChange = (state: any) => {
    setFormState(state);
  };

  useEffect(() => {
    if (formState?.submitSucceeded) {
      onCancel();
    }
  }, [formState, onCancel]);

  return (
    <Box>
      <Form
        onSubmit={onFormSubmit}
        render={({ submitError, hasSubmitErrors, modifiedSinceLastSubmit, submitting, pristine, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormSpy onChange={handleFormStateChange} />
            <CreditCardControls />
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={pristine || (hasSubmitErrors && !modifiedSinceLastSubmit) || submitting}
              sx={styles.submitButton}
            >
              <Fade in={submitting}>
                <CircularProgress size={24} sx={styles.buttonProgress} />
              </Fade>
              <Fade in={!submitting}>
                <span> Add new card</span>
              </Fade>
            </Button>
            <Button variant="outlined" onClick={onCancel}>Cancel</Button>
            <Collapse in={Boolean(hasSubmitErrors && !modifiedSinceLastSubmit)}>
              {submitError && (
                <Typography variant="body1" sx={styles.errorMessage}>
                  {submitError}
                </Typography>
              ) }
            </Collapse>
          </form>
        )}
      />
    </Box>
  );
};

export default CreateCreditCardForm;
