// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import equal from '@turf/boolean-equal';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { RootState } from 'Store';
import ConfirmProceedDialog from 'Components/ConfirmProceedDialog';
import { getOrders } from 'Features/order/orderSelectors';
import { getFeatureCollection } from 'Features/kml/kmlSelectors';
import { SetSelectedKmlIdAction, SetShowBusinessDaysAction } from 'Features/kml/kmlActions';
import { getRasterRequestStatus } from 'Features/project/projectSelectors';
import CustomDirectSelect from 'Features/map/MapEditor/curvemode/customDirectSelectMode';
import CustomKmlSimpleSelect from 'Features/map/MapDraw/modes/customKmlSimpleSelect';
import DrawControl from 'Components/DrawControl';
import { InitDraw, Draw, SetSelection, SelectPolygon, Undo } from '../mapDrawThunk';
import { SetFirstTimeChangeEstimateAction, UpdateKmlFeatureAction } from '../mapDrawActions';
import drawToolStyles from './styles';

const MapDraw = ({ collection, modeName, modeFeatureId, kmlFile, imageryRequested, rasterRequest }) => {
  const dispatch = useAppDispatch();
  const [draw, setDraw] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  useEffect(() => {
    dispatch(SetFirstTimeChangeEstimateAction(true));
  }, []);

  const syncDraw = () => {
    if (draw === null) return;
    draw.set(collection);
    if (imageryRequested || rasterRequest.requested || rasterRequest.rejected) {
      draw.changeMode('simple_select', { useMode: 'kmlDraw' });
      return;
    }
    if (modeName) {
      if (modeName === 'direct_select') {
        if (!collection || collection.features.length === 0) return;
        const feature = draw.get(modeFeatureId);
        if (feature) {
          dispatch(SetSelectedKmlIdAction(modeFeatureId));
        } else {
          return;
        }
        draw.changeMode(modeName, { featureId: modeFeatureId, useMode: 'kmlDraw' });
      } else if (modeName === 'simple_select') {
        draw.changeMode(modeName, { useMode: 'kmlDraw' });
      } else {
        draw.changeMode(modeName);
      }
    } else {
      dispatch(SetSelectedKmlIdAction(null));
    }
  };

  useEffect(() => {
    syncDraw();
  }, [draw, collection, modeName, modeFeatureId, imageryRequested, rasterRequest]);

  useEffect(() => {
    if (!imageryRequested && !rasterRequest.requested && !rasterRequest.rejected) {
      dispatch(InitDraw());
    }
  }, [kmlFile]);

  const updateDraw = async (e: any) => {
    if (e.features[0].geometry.type === 'LineString') {
      dispatch(UpdateKmlFeatureAction(e.features[0]));
      return;
    }
    const { isEstimate, firstTimeChangeEstimate } = await dispatch(Draw(e.features[0]));
    if (isEstimate && firstTimeChangeEstimate) {
      setShowConfirmDialog(true);
    }
  };

  const cancelKmlChange = () => {
    dispatch(Undo());
    setShowConfirmDialog(false);
    dispatch(SetFirstTimeChangeEstimateAction(true));
  };
  const proceedKmlChange = () => {
    setShowConfirmDialog(false);
    dispatch(SetShowBusinessDaysAction(false));
  };
  return (
    <>
      <DrawControl
        userProperties
        displayControlsDefault={false}
        styles={drawToolStyles}
        modes={{
          ...MapboxDraw.modes,
          direct_select: CustomDirectSelect,
          simple_select: CustomKmlSimpleSelect,
        }}
        featureId={modeFeatureId}
        initialFeatures={collection.features || []}
        ref={(drawControl) => {
          if (drawControl !== null && drawControl.draw !== null && drawControl.draw !== draw) {
            setDraw(drawControl.draw);
          }
        }}
        onCreate={updateDraw}
        onUpdate={updateDraw}
        onDrawSelectionChange={(e) => {
          dispatch(SetSelection(e));
        }}
        onDrawModeChange={(e) => {
          if (e.mode === 'simple_select') {
            dispatch(SelectPolygon());
          }
        }}
      />
      <ConfirmProceedDialog
        title="You have changed the project size. This modifies the saved estimate for this project."
        contentText="Please let us know if you'd like to proceed"
        showDialog={showConfirmDialog}
        cancelButtonText="CANCEL"
        okButtonText="PROCEED"
        onOk={proceedKmlChange}
        onCancel={cancelKmlChange}
      />
    </>
  );
};

MapDraw.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  collection: PropTypes.object.isRequired,
  // eslint-disable-next-line react/require-default-props
  modeName: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  modeFeatureId: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  kmlFile: PropTypes.string,
  imageryRequested: PropTypes.bool.isRequired,
  rasterRequest: PropTypes.shape({
    requested: PropTypes.bool.isRequired,
    rejected: PropTypes.bool.isRequired,
    orderPlaced: PropTypes.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = (state: RootState) => {
  const orders = getOrders(state);
  const { createKml, currentKml } = state.map.draw.present;
  const currentProject = state.project.project;
  const imageryRequested = (orders?.activeOrder?.isEstimate && !!(currentProject?.imageryRequest?.requested)) || !!(currentProject?.imageryRequest?.uploaded) || !!(currentProject?.imageryRequest?.notificationSent);
  const rasterRequest = getRasterRequestStatus(state);
  return {
    collection: createKml ? currentKml : getFeatureCollection(state),
    modeName: state.map.draw.present.mode?.name,
    modeFeatureId: state.map.draw.present.mode?.params?.featureId,
    kmlFile: orders.activeOrder && orders.activeOrder.boundaryFile,
    activeOrder: orders.activeOrder,
    imageryRequested,
    rasterRequest,
  };
};

const areCollectionsEqual = (next: GeoJSON.FeatureCollection, prev: GeoJSON.FeatureCollection) => {
  if (next === prev) return true;
  if (next.features.length !== prev.features.length) return false;

  const nextFeatures = next.features;
  const prevFeatures = prev.features;

  const nextIds = nextFeatures.map((f) => f.id.toString());
  const prevIds = prevFeatures.map((f) => f.id.toString());

  if (!arraysEqual(nextIds, prevIds)) return false;

  return areFeaturesEqual(nextFeatures, prevFeatures);
};

const areFeaturesEqual = (arr1: GeoJSON.Feature[], arr2: GeoJSON.Feature[]) => {
  for (let i = 0; i < arr1.length; i += 1) {
    if (!equal(arr1[i], arr2[i])) return false;
  }
  return true;
};

const arraysEqual = (arr1: string[], arr2: string[]) => {
  if (arr1 === arr2) return true;
  if (!arr1 || !arr2) return false;

  if (arr1.length !== arr2.length) return false;

  return arr1.every((item) => arr2.includes(item));
};

export default connect(
  mapStateToProps,
  null,
  null,
  {
    areStatePropsEqual: (next: any, prev: any) => next.modeName === prev.modeName &&
        next.modeFeatureId === prev.modeFeatureId &&
        next.kmlFile === prev.kmlFile &&
        areCollectionsEqual(next.collection, prev.collection)
        && next.activeOrder.isEstimate === prev.activeOrder.isEstimate
        && next.rasterRequest?.requested === prev.rasterRequest?.requested,
  },
)(MapDraw);
