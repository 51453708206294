// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Box, useTheme } from '@mui/system';
import { Typography } from '@mui/material';
import CreditCardIcon from 'Components/CreditCardIcon';
import { useAppSelector } from 'Hooks';
import { RootState } from 'Store';
import stylesDef from './styles';

export const PaymentConfirmation = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const { creditCards } = useAppSelector(
    (state: RootState) => state.subscription,
  );
  const card = creditCards.find((card) => card.defaultCard);

  return (
    <>
      <Typography sx={styles.subSectionTitle} variant="h6">
        Billing Address
      </Typography>
      <Typography variant="body1">{card.name}</Typography>
      <Typography variant="body1">{card.address_line1}</Typography>
      <Typography variant="body1">{card.address_line2}</Typography>
      <Typography variant="body1">{`${card.address_city}, ${card.address_state} ${card.address_zip}`}</Typography>
      <Typography sx={styles.subSectionTitle} variant="h6">
        Payment Information
      </Typography>
      <Box sx={styles.cardWrapper}>
        <Box sx={styles.iconWrapper}>
          <CreditCardIcon cardType={card.cardType} />
        </Box>
        <Box>
          <Typography variant="body1">{card.number}</Typography>
          <Typography variant="body1">{`Expires ${card.expDate}`}</Typography>
        </Box>
      </Box>
    </>
  );
};

export default PaymentConfirmation;
