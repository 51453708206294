// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createSlice } from '@reduxjs/toolkit';
import {
  GetTemplatesStartAction,
  GetTemplatesSuccessAction,
  GetTemplatesFailureAction,
  SetTemplateIdAction,
  DeleteTemplateStartAction,
  DeleteTemplateSuccessAction,
  DeleteTemplateFailureAction,
  EditTemplateStartAction,
  EditTemplateSuccessAction,
  EditTemplateFailureAction,
  DuplicateTemplateSuccessAction,
  SetSelectedStyleAction,
  SetSelectedWeightAction,
  SetSelectedColorAction,
  SetSelectedMappingAction,
  AddTemplateAction,
  ResetTemplateListAction,
} from './cadTemplateActions';

const initialState: ICadTemplateState = {
  loading: false,
  templateId: null,
  list: [],
};

const cadTemplateSlice = createSlice({
  name: 'cadTemplate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetTemplatesStartAction, (state) => {
        state.loading = true;
      })
      .addCase(GetTemplatesSuccessAction, (state, action) => {
        state.loading = false;
        state.list = action.payload;
      })
      .addCase(GetTemplatesFailureAction, (state) => {
        state.loading = false;
      })
      .addCase(SetTemplateIdAction, (state, action) => {
        state.templateId = action.payload;
      })
      .addCase(DeleteTemplateStartAction, (state, action) => {
        const index = state.list.findIndex((p) => p._id === action.payload);
        state.list[index].deleting = true;
      })
      .addCase(DeleteTemplateSuccessAction, (state, action) => {
        state.list = state.list.filter((p) => p._id !== action.payload);
      })
      .addCase(DeleteTemplateFailureAction, (state, action) => {
        const index = state.list.findIndex((p) => p._id === action.payload);
        state.list[index].deleting = false;
      })
      .addCase(EditTemplateStartAction, (state) => {
        state.loading = true;
      })
      .addCase(EditTemplateSuccessAction, (state, action) => {
        const index = state.list.findIndex((p) => p._id === action.payload._id);
        state.list[index] = action.payload;
      })
      .addCase(EditTemplateFailureAction, (state) => {
        state.loading = false;
      })
      .addCase(DuplicateTemplateSuccessAction, (state, action) => {
        state.loading = false;
        state.list.push(action.payload);
      })
      .addCase(AddTemplateAction, (state, action) => {
        state.list.push(action.payload);
      })
      .addCase(SetSelectedStyleAction, (state, action) => {
        const index = state.list.findIndex((p) => p._id === action.payload.templateId);
        const indexLayer = state.list[index].layers.findIndex((p) => p._id === action.payload.layerId);
        state.list[index].layers[indexLayer].style = action.payload.event;
      })
      .addCase(SetSelectedWeightAction, (state, action) => {
        const index = state.list.findIndex((p) => p._id === action.payload.templateId);
        const indexLayer = state.list[index].layers.findIndex((p) => p._id === action.payload.layerId);
        state.list[index].layers[indexLayer].weight = action.payload.event;
      })
      .addCase(SetSelectedColorAction, (state, action) => {
        const index = state.list.findIndex((p) => p._id === action.payload.templateId);
        const indexLayer = state.list[index].layers.findIndex((p) => p._id === action.payload.layerId);
        state.list[index].layers[indexLayer].color = action.payload.color;
      })
      .addCase(SetSelectedMappingAction, (state, action) => {
        const index = state.list.findIndex((p) => p._id === action.payload.templateId);
        const indexLayer = state.list[index].layers.findIndex((p) => p._id === action.payload.layerId);
        state.list[index].layers[indexLayer].mapping = action.payload.event;
      })
      .addCase(ResetTemplateListAction, (state) => {
        state.list = [];
      });
  },
});

export const { reducer } = cadTemplateSlice;

export default reducer;
