// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  markerIconWrapper: {
    '&:after': {
      top: 9,
      left: '50%',
      width: 10,
      height: 10,
      content: '""',
      position: 'absolute',
      borderRadius: '50%',
      backgroundColor: 'white',
      transform: 'translateX(-50%)',
    },
  },
  markerIcon: {
    width: 40,
    height: 40,
    fill: theme.palette.secondary.main,
  },
});

export default styles;
