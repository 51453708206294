// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  tooltip: {
    width: '250px',
    minHeight: '100px',
  },
  divGrid: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between',
  },
  extras: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
  },
  rightContent: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  titleGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    gridColumnGap: theme.spacing(1),
  },
  extrasText: {
    marginLeft: '10px',
    fontSize: '12px',
    fontWeight: 400,
  },
  reasonText: {
    marginLeft: '10px',
    width: '180px',
    paddingBottom: '5px',
    wordWrap: 'break-word',
    fontSize: '12px',
    fontWeight: 400,
  },
  extrasCost: {
    position: 'absolute',
    right: 0,
    top: 0,
    fontSize: '12px',
    fontWeight: 400,
  },
  divider: {
    height: '0px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  rightContentTotal: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: '#01A1DD',
  },
});

export default styles;
