/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Typography,
  Fade,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  InputBase,
  Divider,
  Checkbox,
  FormControlLabel,
  Paper,
} from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';
import downloadIcon from 'Assets/download-dark-icon.png';
import CreateMap from 'Features/map';
import { deliveryEstimate, deliveryBusinessDays, formatValue, projByProjDollarPerAcre } from 'Utils/deliverables';
import { WideLightTooltip } from 'Components/Tooltip';
import { opsDailyCreditCapacity, rasterizationCredits } from 'Utils/constants';
import { PDFDownloadLink } from '@react-pdf/renderer';
import TalkToSalesDialog from 'Components/TalkToSalesDialog';
import { useAppSelector, useAppDispatch } from 'Hooks';
import { useSelector } from 'react-redux';
import { RootState } from 'Store';
import { selectSelectedBundle } from 'Features/bundle/bundleSelectors';
import { GetOrderDollarCostThunk, GetTaxThunk } from 'Features/order/orderThunk';
import { getOrders } from 'Features/order/orderSelectors';
import { selectAvailableTeams, selectDefaultTemplate } from 'Features/account/accountSelectors';
import { GetTemplates } from 'Features/account/cadTemplates/cadTemplateThunk';
import { selectCRSInfo } from 'Features/project/projectSelectors';
import { ToggleImageryProcessingThunk } from 'Features/project/projectThunk';
import PDF from 'Features/order/PDF';
import KickoffDatePicker from '../KickoffDatePicker';
import TopoSelect from './TopoSelect';
import DeliveryDate from './DeliveryDate';
import stylesDef from './styles';

interface MapColumnProps {
  goToNextPage: () => void;
  setPage: (arg0: number) => void;
  cadTemplate: string;
  setCadTemplate: (arg0: string) => void;
  setShowTemplateReviewDialog: (arg0: boolean) => void;
  setDeliverySpeed: (arg0: string) => void;
  deliverySpeed: string;
  isSubmitting: boolean;
  isSaved: boolean;
  isSaving: boolean,
  firstTimeChangeEstimate: boolean,
  saveEstimate: () => void;
  currentOrderAcreage: number,
  currentOrderLength: number,
  middlePoint: string,
  referenceId: string | null,
  team: string | null,
  coordinates: string,
  page: number,
  checkFirstTimeChangeEstimate: (arg0: Function) => void;
  bundleStep: number;
  addOnLayerAcres: number;
  addOnDeliverableAcres: number;
  addOnDeliverableDollars: number;
  addOnLayerDollars: number;
  cards: boolean;
  totalCredits: number;
  setTotalCredits: (arg0: number) => void;
  totalDollar: number;
  setTotalDollar: (arg0: number) => void;
  totalTax: number;
  setTotalTax: (arg0: number) => void;
  creditsToMin: number;
  setCreditsToMin: (arg0: number) => void;
  inLicenseAddonAcres: number;
  setinLicenseAddonAcres: (arg0: number) => void;
  setPricingInfo: (arg0: {}) => void;
  pricingInfo: IPricingPlanInfo;
  additonalCharges: number;
  setAdditonalCharges: (arg0: number) => void;
  allowTemplateReview: boolean;
  setAllowTemplateReview: (arg0: boolean) => void;
  setCheckedForOverage: (arg0: boolean) => void;
  bundleType: string;
}

const MapColumn =
  ({
    cadTemplate,
    goToNextPage,
    setDeliverySpeed,
    deliverySpeed,
    setCadTemplate,
    setShowTemplateReviewDialog,
    isSubmitting,
    isSaved,
    isSaving,
    bundleStep,
    addOnLayerAcres,
    addOnDeliverableAcres,
    addOnLayerDollars,
    addOnDeliverableDollars,
    firstTimeChangeEstimate,
    saveEstimate,
    currentOrderAcreage,
    currentOrderLength,
    middlePoint,
    referenceId,
    team,
    coordinates,
    page,
    setPage,
    checkFirstTimeChangeEstimate,
    cards,
    totalCredits,
    setTotalCredits,
    totalDollar,
    setTotalDollar,
    totalTax,
    setTotalTax,
    creditsToMin,
    setCreditsToMin,
    inLicenseAddonAcres,
    setinLicenseAddonAcres,
    setPricingInfo,
    additonalCharges,
    setAdditonalCharges,
    pricingInfo,
    allowTemplateReview,
    setAllowTemplateReview,
    setCheckedForOverage,
    bundleType,
  }: MapColumnProps) => {
    const theme = useTheme();
    const styles = stylesDef(theme);
    const dispatch = useAppDispatch();
    const { minOrderCredits, superExpeditedAvailable, reviewCadTemplate } = useFlags();
    const minCredits: number = minOrderCredits || 5;

    const { extraDeliverablesLength, extraLayersLength, addOnCount } = useSelector((state: RootState) => selectSelectedBundle(state));
    const defaultTemplate = useSelector((state: RootState) => selectDefaultTemplate(state));
    const { availableTeams } = useSelector((state: RootState) => selectAvailableTeams(state));
    const { geogCS } = useSelector((state: RootState) => selectCRSInfo(state));

    const { resources } = useAppSelector(
      (state) => state.auth,
    );
    const { placeOrderSuccess } = useAppSelector(
      (state) => state.order,
    );
    const { creditCards, automateSubscription } = useAppSelector(
      (state: RootState) => state.subscription,
    );
    const { org } = useAppSelector(
      (state: RootState) => state.account,
    );
    const { list: cadTemplateList } = useAppSelector(
      (state: RootState) => state.cadTemplate,
    );
    const { dailyCreditCommitment } = useAppSelector(
      (state: RootState) => state.globalStatus.status,
    );

    const { selectedBundle, totalCost, deliveryDays, availableBundles, topoMajr, topoMinr, bundleDeliverables, selectedAdditionals, density } = useAppSelector(
      (state: RootState) => state.bundle,
    );
    const { epsgCode, rasterizationSelected, project } = useAppSelector(
      (state: RootState) => state.project,
    );
    const { screenshot } = useAppSelector(
      (state: RootState) => state.map.common,
    );
    const { activeOrder } = useSelector((state: RootState) => getOrders(state));

    const cadTemplatesResource = 'cadTemplates' in resources;
    const { priceName: automatePlanType } = automateSubscription || {};
    const { name: projectName, imageryRequest } = project || {};
    const rasterizationCreditCost = rasterizationSelected ? rasterizationCredits : 0;
    const hasCards = creditCards.length > 0;
    const { costPerAcre, availableCreditAcreage } = org || {};
    const pricingPlanName = org?.currentPricingPlanInfo?.name;
    const noCostString = pricingPlanName ? '0 credits' : '0 credits ($0.00)';
    const pricingRate = pricingInfo?.rate;
    const { name: selectedBundleName, imageryCreditCost, imageryDollarCost, imageryExtraDollarCost,
      adjustedOrderAcres, bundleCost, bundleId: selectedBundleId } = selectedBundle || {};
    const expressAIBundle = selectedBundleId === 9;

    const defaultCostPerAcre = selectedBundle?.costPerFactors[1];
    const [estimatedDate, setEstimatedDate] = useState('');
    const [businessDays, setBusinessDays] = useState(null);
    const [showKickoffDatePicker, setShowKickoffDatePicker] = useState(false);
    const [firstTimeClickSaveEstimate, setFirstTimeClickSaveEstimate] = useState(true);
    const [contactSalesInteraction, setContactSalesInteraction] = useState(false);

    const [showSalesDialog, setShowSalesDialog] = useState(false);

    const [disableDensity, setDisableDensity] = useState(false);

    const [chargedAcresInSubscription, setChargedAcresInSubscription] = useState(null);
    const [chargedAdditionalAcres, setChargedAdditionalAcres] = useState(null);
    const [expeditedAvailability, setExpeditedAvailability] = useState({ expedited: true, super_expedited: false });

    const [rasterizationDollarCost, setRasterizationDollarCost] = useState(0);
    const [rasterizationExtraDollarCost, setRasterizationExtraDollarCost] = useState(0);
    const [dollarsToMin, setDollarsToMin] = useState(0);
    const [extraDollarsToMin, setExtraDollarsToMin] = useState(0);
    const [projByProjAddonAcres, setProjByProjAddonAcres] = useState(0);
    const [totalAddOnDollars, setTotalAddOnDollars] = useState(0);
    const [totalAddOnString, setTotalAddOnString] = useState(noCostString);
    const [layerAddOnString, setLayerAddOnString] = useState(noCostString);
    const [deliverableAddOnString, setDeliverableAddOnString] = useState(noCostString);

    useEffect(() => {
      // handle minimum order credits for a la carte bundle
      setCreditsToMin(0);
      setDollarsToMin(0);
      setExtraDollarsToMin(0);
      if (selectedBundleName === 'A la carte') {
        setTotalTax(0);
        const imageryAndAddOn = imageryCreditCost + inLicenseAddonAcres + projByProjAddonAcres;
        if (imageryAndAddOn > 0 && imageryAndAddOn < minCredits) {
          const extraCredits = minCredits - imageryAndAddOn;
          // to avoid setting TotalCredits again due to the dependency in useEffect
          // we set the credits to min directly in another useEffect
          // setCreditsToMin(extraCredits);
          if (projByProjAddonAcres === 0) {
            const existingCreditCost = inLicenseAddonAcres + imageryCreditCost;
            const remainingAvailableCreditAcreage = availableCreditAcreage - existingCreditCost > 0 ? availableCreditAcreage - existingCreditCost : 0;
            if (remainingAvailableCreditAcreage >= extraCredits) {
              setDollarsToMin(extraCredits * costPerAcre);
            } else {
              setDollarsToMin(remainingAvailableCreditAcreage * costPerAcre + (extraCredits - remainingAvailableCreditAcreage) * projByProjDollarPerAcre);
              setExtraDollarsToMin((extraCredits - remainingAvailableCreditAcreage) * projByProjDollarPerAcre);
            }
          } else {
            setDollarsToMin(extraCredits * projByProjDollarPerAcre);
            setExtraDollarsToMin(extraCredits * projByProjDollarPerAcre);
          }
        }
      }
    }, [selectedBundleName, inLicenseAddonAcres, projByProjAddonAcres]);

    useEffect(() => {
      const overageLayerAcres = addOnLayerDollars / projByProjDollarPerAcre;
      const overageDeliverableAcres = addOnDeliverableDollars / projByProjDollarPerAcre;
      setTotalAddOnDollars(addOnDeliverableDollars + addOnLayerDollars);
      setinLicenseAddonAcres(addOnDeliverableAcres + addOnLayerAcres);
      setProjByProjAddonAcres(overageDeliverableAcres + overageLayerAcres);
    }, [addOnLayerAcres, addOnDeliverableAcres, addOnLayerDollars, addOnDeliverableDollars]);

    useEffect(() => {
      const calculateDollarCost = async () => {
        let pricingInfoResult: any = pricingInfo;
        if (!pricingInfo) {
          pricingInfoResult = await dispatch(GetOrderDollarCostThunk(totalCredits));
          setPricingInfo(pricingInfoResult);
        }
        const { lowerLimit, upperLimit, usedCredits, startingCredits, creditsPurchased } = pricingInfoResult;

        let { rate } = pricingInfoResult;
        let additionalCost = 0;
        const totalUsedCredits = totalCredits + startingCredits + usedCredits;
        if (pricingPlanName === 'no_commit' && (totalUsedCredits < lowerLimit || totalUsedCredits > upperLimit)) {
          pricingInfoResult = await dispatch(GetOrderDollarCostThunk(totalCredits));
          rate = pricingInfoResult.rate;
          setPricingInfo(pricingInfoResult);
        }
        if (pricingPlanName === 'no_commit') {
          additionalCost = totalCredits * rate;
        } else if (pricingPlanName !== 'enterprise_pre_commit') {
          // pre-pay
          const usedCreditInPlan = usedCredits + startingCredits > creditsPurchased ? creditsPurchased : usedCredits + startingCredits;
          const overageCredits = totalCredits + usedCreditInPlan - creditsPurchased;
          if (overageCredits > 0) {
            additionalCost = overageCredits * rate;
          }
        }
        setAdditonalCharges(additionalCost);
        setTotalDollar(totalCredits * rate);
      };

      if (!expressAIBundle && pricingPlanName) {
        calculateDollarCost();
      }
    }, [totalCredits]);

    useEffect(() => {
      const totalAddOnOverageDollars = addOnDeliverableDollars + addOnLayerDollars;

      if (pricingPlanName) {
        // For new pricing plan, we count all add on credits as in license credits
        const credits = rasterizationCreditCost + imageryCreditCost + inLicenseAddonAcres;
        setTotalAddOnString(`${credits.toFixed(2)} credits ($${formatValue(credits * pricingRate)})`);
        return;
      }

      if (totalAddOnOverageDollars === 0) {
        setTotalAddOnString(`${(rasterizationCreditCost + imageryCreditCost + inLicenseAddonAcres).toFixed(2)} credits ($${formatValue(rasterizationDollarCost + imageryDollarCost + inLicenseAddonAcres * costPerAcre)})`);
        return;
      }

      if (inLicenseAddonAcres === 0 && totalAddOnOverageDollars !== 0) {
        const acres = totalAddOnOverageDollars / projByProjDollarPerAcre + imageryCreditCost + rasterizationCreditCost;
        setTotalAddOnString(`${acres.toFixed(2)} credits ($${formatValue(imageryDollarCost + rasterizationDollarCost + totalAddOnOverageDollars)})`);
        return;
      }

      setTotalAddOnString(`${(inLicenseAddonAcres + projByProjAddonAcres + imageryCreditCost + rasterizationCreditCost).toFixed(2)} credits ($${formatValue(rasterizationDollarCost + imageryDollarCost + totalAddOnOverageDollars + (inLicenseAddonAcres * costPerAcre))})`);
    }, [inLicenseAddonAcres, totalAddOnDollars, imageryCreditCost, imageryDollarCost, rasterizationCreditCost, rasterizationDollarCost, pricingRate]);

    useEffect(() => {
      const overageLayerAcres = addOnLayerDollars / projByProjDollarPerAcre;

      if (pricingPlanName) {
        setLayerAddOnString(`${addOnLayerAcres.toFixed(2)} credits ($${formatValue(addOnLayerAcres * pricingRate)})`);
        return;
      }

      if (addOnLayerAcres === 0 && addOnLayerDollars === 0) {
        setLayerAddOnString(noCostString);
        return;
      }
      if (addOnLayerDollars === 0 && addOnLayerAcres !== 0) {
        setLayerAddOnString(`${addOnLayerAcres} credits ($${formatValue(addOnLayerAcres * costPerAcre)})`);
        return;
      }
      if (addOnLayerAcres === 0 && addOnLayerDollars !== 0) {
        setLayerAddOnString(`${overageLayerAcres.toFixed(2)} credits ($${formatValue(addOnLayerDollars)})`);
        return;
      }

      setLayerAddOnString(`${(addOnLayerAcres + overageLayerAcres).toFixed(2)} credits ($${formatValue(addOnLayerDollars + (addOnLayerAcres * costPerAcre))})`);
    }, [addOnLayerDollars, addOnLayerAcres, pricingRate]);

    useEffect(() => {
      const overageDeliverableAcres = addOnDeliverableDollars / projByProjDollarPerAcre;

      if (pricingPlanName) {
        setDeliverableAddOnString(`${addOnDeliverableAcres.toFixed(2)} credits ($${formatValue(addOnDeliverableAcres * pricingRate)})`);
        return;
      }

      if (addOnDeliverableDollars === 0 && addOnDeliverableAcres === 0) {
        setDeliverableAddOnString(noCostString);
        return;
      }
      if (addOnDeliverableDollars === 0 && addOnDeliverableAcres !== 0) {
        setDeliverableAddOnString(`${addOnDeliverableAcres} credits ($${formatValue(addOnDeliverableAcres * costPerAcre)})`);
        return;
      }
      if (addOnDeliverableAcres === 0 && addOnDeliverableDollars !== 0) {
        setDeliverableAddOnString(`${overageDeliverableAcres.toFixed(2)} credits ($${formatValue(addOnDeliverableDollars)})`);
        return;
      }

      setDeliverableAddOnString(`${(addOnDeliverableAcres + overageDeliverableAcres).toFixed(2)} credits ($${formatValue(addOnDeliverableDollars + (addOnDeliverableAcres * costPerAcre))})`);
    }, [addOnDeliverableAcres, addOnDeliverableDollars, pricingRate]);

    useEffect(() => {
      const { isEstimate, cadTemplate: activeOrderCadTemplate } = activeOrder;
      if (isEstimate) {
        setCadTemplate(activeOrderCadTemplate);
      } else {
        setCadTemplate(defaultTemplate?._id);
      }
    }, []);

    useEffect(() => {
      if (availableBundles.length > 0 && cadTemplate && allowTemplateReview) {
        const selectedCadTemplate = cadTemplateList.find((temp) => temp._id === cadTemplate);
        if (!selectedCadTemplate.isReviewed && reviewCadTemplate) {
          setShowTemplateReviewDialog(true);
          window?.pendo?.track('CAD Template Review Reminder', { project_name: project?.name, project_id: activeOrder.ownerProject });
        }
      }
    }, [availableBundles, cadTemplate, allowTemplateReview]);

    useEffect(() => {
      if (bundleCost > 0) {
        setChargedAcresInSubscription(availableCreditAcreage);
        setChargedAdditionalAcres(adjustedOrderAcres - availableCreditAcreage);
      } else {
        setChargedAcresInSubscription(adjustedOrderAcres);
        setChargedAdditionalAcres(0);
      }
      if (rasterizationCreditCost > 0) {
        const overage = adjustedOrderAcres + rasterizationCreditCost - availableCreditAcreage;
        if (overage < 0) {
          setRasterizationDollarCost(rasterizationCreditCost * costPerAcre);
        } else if (overage >= rasterizationCreditCost) {
          const extraCost = rasterizationCreditCost * projByProjDollarPerAcre;
          setRasterizationDollarCost(extraCost);
          setRasterizationExtraDollarCost(extraCost);
        } else {
          const extraCost = overage * projByProjDollarPerAcre;
          setRasterizationDollarCost(extraCost + (rasterizationCreditCost - overage) * costPerAcre);
          setRasterizationExtraDollarCost(extraCost);
        }
      }
    }, [adjustedOrderAcres, availableCreditAcreage, bundleCost]);

    useEffect(() => {
      // set Total credits to 5 if the sum is less than 5
      if (
        creditsToMin + rasterizationCreditCost + imageryCreditCost + adjustedOrderAcres + inLicenseAddonAcres + projByProjAddonAcres > 0 &&
        creditsToMin + rasterizationCreditCost + imageryCreditCost + adjustedOrderAcres + inLicenseAddonAcres + projByProjAddonAcres < 5
      ) {
        setTotalCredits(minOrderCredits || 5);
      } else {
        setTotalCredits(creditsToMin + rasterizationCreditCost + imageryCreditCost + adjustedOrderAcres + inLicenseAddonAcres + projByProjAddonAcres);
      }
      if (!expressAIBundle && !pricingPlanName) {
        setTotalDollar(dollarsToMin + rasterizationDollarCost + imageryDollarCost + totalCost + (projByProjAddonAcres * projByProjDollarPerAcre) + ((inLicenseAddonAcres + chargedAcresInSubscription) * costPerAcre));
        setAdditonalCharges(extraDollarsToMin + rasterizationExtraDollarCost + imageryExtraDollarCost + totalCost + addOnLayerDollars + addOnDeliverableDollars);
      }
    }, [creditsToMin, dollarsToMin, extraDollarsToMin, rasterizationCreditCost, rasterizationDollarCost, rasterizationExtraDollarCost, imageryCreditCost,
      imageryDollarCost, imageryExtraDollarCost, adjustedOrderAcres, totalCost, inLicenseAddonAcres, projByProjAddonAcres, chargedAcresInSubscription,
      addOnLayerDollars, addOnDeliverableDollars, projByProjDollarPerAcre, costPerAcre]);

    useEffect(() => {
      setDisableDensity(selectedBundle?.bundleId === 2);
      if (expressAIBundle) {
        setTotalDollar(0);
        setAdditonalCharges(0);
      }
    }, [selectedBundle?.bundleId]);

    useEffect(() => {
      const totalDays = deliveryDays + (rasterizationCreditCost && 3);
      const dueDate = moment(deliveryEstimate(totalDays));
      setEstimatedDate(dueDate.format('LL'));
      setBusinessDays(deliveryBusinessDays(totalDays));
    }, [deliveryDays]);

    useEffect(() => {
      const loadData = async () => {
        await dispatch(GetTemplates());
        // await GetDefaultTemplateAction(org.defaultTemplate);
      };
      loadData();
    }, []);
    const currentTeam = availableTeams?.find((t: any) => t._id === activeOrder.team);

    const onProceed = () => {
      setContactSalesInteraction(true);
      if (!cards) setPage(1);
    };

    useEffect(() => {
      const newExpeditedAvailability: any = { ...expeditedAvailability };
      const speedFactors = availableBundles.find((b) => selectedBundleName === b.name)?.deliverySpeedAdjustmentFactor;
      speedFactors?.forEach((speedObj) => {
        const { speed, creditFactor } = speedObj;
        if (speed !== 'normal' && !(speed === 'super_expedited' && !superExpeditedAvailable)) {
          const dailyCommitment = selectedBundle ? ((creditFactor * adjustedOrderAcres) / selectedBundle.bundleDeliveryTime) : 0;
          const availability = dailyCommitment + dailyCreditCommitment < opsDailyCreditCapacity;
          if (speed === 'expedited' && !availability) {
            window?.pendo?.track('Expedited Processing Unavailable', { project_name: projectName, order_size: activeOrder.acreage?.toFixed(2), projectId: activeOrder.ownerProject });
          }
          newExpeditedAvailability[speed] = availability;
          setExpeditedAvailability(newExpeditedAvailability);
        }
      });
    }, [selectedBundleName]);

    const pdfData = useMemo(() => ({
      type: 'Estimate',
      submitter: activeOrder.ownerEmail || 'N/A',
      automatePlanType,
      projectName,
      referenceId: activeOrder.referenceId,
      team: currentTeam?.name,
      acreage: activeOrder.acreage,
      kmlLength: activeOrder.kmlLength,
      middlePoint: `${activeOrder.middlePoint?.place}, ${activeOrder.middlePoint?.region} ${activeOrder.middlePoint?.postcode}`,
      coordinates: `${activeOrder.middlePoint?.longitude.toFixed(5)}, ${activeOrder.middlePoint?.latitude.toFixed(5)}`,
      cadTemplate: cadTemplateList.find((temp) => temp._id === cadTemplate)?.name,
      crs: epsgCode && `${geogCS} - EPSG: ${epsgCode}`,
      topoMajr,
      topoMinr,
      selectedBundle,
      businessDays,
      bundleDeliverables,
      selectedAdditionals,
      screenshot,
      salesTaxAmount: totalTax || activeOrder.salesTaxAmount,
      chargedCredits: adjustedOrderAcres,
      chargedCreditsInDollars: pricingPlanName ? +(adjustedOrderAcres * pricingRate).toFixed(2) : chargedAcresInSubscription * costPerAcre + chargedAdditionalAcres * selectedBundle?.costPerFactors[1],
      totalCreditsCharged: automatePlanType !== 'plan_free' ? +(totalCredits).toFixed(2) : 0,
      totalCreditsChargedInDollars: automatePlanType !== 'plan_free' ? totalDollar : 0,
      addOnsCreditsString: totalAddOnString,
      deliverablesCreditsString: deliverableAddOnString,
      layersCreditsString: layerAddOnString,
      totalAdditionalCharged: additonalCharges,
      totalCostForProject: activeOrder.costToCustomer,
      anticipatedKickoffDate: activeOrder.anticipatedKickoffDate,
      imageryProvider: imageryRequest?.provider,
      imageryCreditCost,
      imageryDollarCost: pricingPlanName ? +(imageryCreditCost * pricingRate).toFixed(2) : imageryDollarCost,
      rasterizationCreditCost,
      rasterizationDollarCost: pricingPlanName ? +(rasterizationCreditCost * pricingRate).toFixed(2) : rasterizationDollarCost,
      pricingPlanName,
      bundleType,
    }), [automatePlanType, projectName, activeOrder, cadTemplateList, cadTemplate, epsgCode, geogCS, topoMajr, topoMinr,
      selectedBundle, businessDays, costPerAcre, chargedAcresInSubscription, chargedAdditionalAcres, totalAddOnString, layerAddOnString,
      deliverableAddOnString, bundleDeliverables, selectedAdditionals, screenshot,
      imageryRequest, imageryCreditCost, imageryDollarCost, totalCredits, totalDollar, additonalCharges, bundleType, totalTax]);

    const extraLayers: any[] = [];
    const extraDeliverables: any[] = [];

    selectedAdditionals.forEach((del) => {
      if (del.type === 'layer') {
        extraLayers.push(del);
      } else {
        extraDeliverables.push(del);
      }
    });

    const onClickSaveEstimate = async () => {
      const { anticipatedKickoffDate } = activeOrder;
      // fetch the final tax amount again just in case the user has changed something in the existing estimate
      // and/or the user address has changed since the last time the tax amount was fetched
      if(additonalCharges > 0){
        const result = await dispatch(GetTaxThunk(additonalCharges));
        if (result.success) {
          setTotalTax(result.salesTaxAmount);
        }
      }
      if (firstTimeClickSaveEstimate && !anticipatedKickoffDate && !imageryRequest && !rasterizationCreditCost) {
        setShowKickoffDatePicker(true);
        setFirstTimeClickSaveEstimate(false);
      // Redirect PAYG (Pay As You Go) customers to the credit card page
      // if they attempt to request imagery or raster but have not added a credit card yet.
      // This applies when the pricing plan is 'no_commit'.
      } else if (pricingPlanName ===
      'no_commit' && (imageryRequest || rasterizationCreditCost > 0) && !hasCards) {
        setPage(1);
      } else if (imageryRequest && additonalCharges > 0 && !hasCards) {
        setShowSalesDialog(true);
        setContactSalesInteraction(false);
      } else {
        saveEstimate();
      }
    };

    const showTalkToSalesDialog = !pricingPlanName && (!contactSalesInteraction && showSalesDialog);

    const contactSalesClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      setContactSalesInteraction(true);
      window.open('mailto:sales@airworks.io', '_blank');
    };

    useEffect(() => {
      if (availableBundles?.length > 0 && imageryRequest) {
        if (showTalkToSalesDialog) {
          setAllowTemplateReview(false);
        } else {
          setAllowTemplateReview(true);
          setCheckedForOverage(true);
        }
      }
    }, [availableBundles, imageryRequest, showTalkToSalesDialog]);

    const toggleImageryProcessing = async () => {
      await dispatch(ToggleImageryProcessingThunk());
    };

    const [loading, setLoading] = useState(false);

    // Fetch sales tax amount
    // every time additonalCharges changes(the amount user needs to pay for the order using the payment method) we fetch the sales tax amount
    // we do this for both PAYG and pre-pay(automate subscription) customers

    useEffect(() => {
      setTotalTax(0);
      const abortController = new AbortController();
      const fetchData = async () => {
        if (additonalCharges > 0) {
          setLoading(true);
          const result = await dispatch(GetTaxThunk(additonalCharges, abortController.signal));
          if (!abortController.signal.aborted && result.success) {
            setTotalTax(result.salesTaxAmount);
          }
          setLoading(false);
        }
      };
      fetchData();
      return () => {
        abortController.abort();
      };
    }, [additonalCharges]);

    useEffect(() => {
      if (selectedBundleId === 9 && bundleCost > 0) {
        window?.pendo?.track('Express AI Out of Credits');
      }
    }, [selectedBundleId, bundleCost]);

    return (
      <>
        <Paper sx={styles.mapColumn}>
          <Box sx={styles.orderInfo}>
            <Box>
              <Typography variant="h1" sx={styles.header}>
                {projectName}
              </Typography>
              {placeOrderSuccess ? <Typography variant="body1">{`Reference ID: ${referenceId || 'N/A'}`}</Typography> : ''}
              {placeOrderSuccess ? <Typography variant="body1">{`Team: ${team || 'N/A'}`}</Typography> : ''}
              {bundleType === 'utilities' ? (
                <Typography variant="body1">{`${placeOrderSuccess ? currentOrderLength : activeOrder.kmlLength} mi`}</Typography>
              ) : (
                <Typography variant="body1">{`${placeOrderSuccess ? currentOrderAcreage : activeOrder.acreage} acres`}</Typography>
              )}
              {placeOrderSuccess ? (
                <Typography variant="body1" sx={styles.paddingBottom16}>
                  {middlePoint}
                  <br />
                  {coordinates}
                </Typography>
              )
                : (activeOrder.middlePoint && (
                  <Typography variant="body1" sx={styles.paddingBottom16}>
                    {`${activeOrder.middlePoint.place}, ${activeOrder.middlePoint.region} ${activeOrder.middlePoint.postcode}`}
                    <br />
                    {`${activeOrder.middlePoint.longitude.toFixed(5)}, ${activeOrder.middlePoint.latitude.toFixed(5)}`}
                  </Typography>
                )
                )}
            </Box>
            <Box sx={styles.map}>
              <CreateMap mode="sidebar" />
            </Box>
          </Box>
          <Divider sx={styles.divider} />
          {cadTemplatesResource && (
            <>
              <Typography variant="h6" sx={styles.cadText}>
                CAD TEMPLATE
              </Typography>
              {placeOrderSuccess ? (
                <Typography variant="body1">{cadTemplateList.find((temp) => temp._id === cadTemplate)?.name}</Typography>
              ) : (
                <FormControl sx={styles.formControl}>
                  <Select
                    value={cadTemplate}
                    input={<InputBase style={{ width: '100%' }} />}
                    onChange={(e: any) => checkFirstTimeChangeEstimate(() => setCadTemplate(e.target.value))}
                    variant="outlined"
                    sx={styles.dropdown}
                  >
                    {cadTemplateList.map((template: any) => (
                      <MenuItem key={template._id} value={template._id} sx={styles.menuItem}>
                        <Typography variant="h4" sx={styles.innerDropdownText}>
                          {template.name}
                        </Typography>
                        {org.defaultTemplate === template._id ? (
                          <Typography variant="h4" sx={styles.defaultText}>
                            Default
                          </Typography>
                        ) : (
                          ''
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </>
          )}
          <Typography variant="h6" sx={styles.topoTitle}>CRS COORDINATE SYSTEM</Typography>
          {epsgCode ? <Typography variant="h5" sx={{ ...(placeOrderSuccess && styles.crs) }}>{`${geogCS} - EPSG: ${epsgCode}`}</Typography> : 'N/A'}
          {!expressAIBundle && (
            <TopoSelect
              disableDensity={disableDensity}
              deliverySpeed={deliverySpeed}
              checkFirstTimeChangeEstimate={checkFirstTimeChangeEstimate}
            />
          )}
          <Divider sx={styles.divider} />
          <DeliveryDate
            page={page}
            businessDays={businessDays}
            deliverySpeed={deliverySpeed}
            estimatedDate={estimatedDate}
            expeditedAvailability={expeditedAvailability}
            checkFirstTimeChangeEstimate={checkFirstTimeChangeEstimate}
            setDeliverySpeed={setDeliverySpeed}
            selectedBundleId={selectedBundleId}
          />
          <Divider sx={styles.divider} />

          <Box sx={styles.divGrid}>
            <Box sx={styles.dateGrid}>
              <Typography variant="h3">
                {expressAIBundle ? 'Express AI' : selectedBundleName}
                {' '}
                credits
              </Typography>
              {!expressAIBundle && (
                <WideLightTooltip
                  placement="bottom"
                  disableHoverListener={disableDensity}
                  title={(
                    <div>
                      The credits listed here reflect the number of credits that will be deducted from your plan for this project based on the project size, bundle, project density and delivery date selected. This number is tentative based on AirWorks approval of any density change.
                      {(placeOrderSuccess ? currentOrderAcreage : activeOrder.acreage) < minCredits ? (
                        <>
                          <br />
                          <br />
                          As a reminder, AirWorks minimum site size is
                          {' '}
                          {minCredits}
                          {' '}
                          credits.
                        </>
                      ) : ''}
                    </div>
                  )}
                >
                  <Box sx={styles.densityInfo}>
                    <InfoIcon sx={styles.icon} />
                  </Box>
                </WideLightTooltip>
              )}
            </Box>
            <Typography sx={styles.rightContent} variant="h3">
              {`${adjustedOrderAcres} credits`}
              {
                !expressAIBundle && (pricingPlanName ?
                  ` ($${formatValue(adjustedOrderAcres * pricingRate)})` :
                  ` ($${formatValue(chargedAcresInSubscription * costPerAcre + chargedAdditionalAcres * defaultCostPerAcre)})`)
              }
            </Typography>
          </Box>
          <Box sx={styles.orderDetail}>
            <Box sx={styles.divGrid}>
              <Typography variant="body1" sx={styles.orderDetailTitle}>- Project Size</Typography>
              <Typography variant="body1" sx={styles.orderDetailValue}>
                {bundleType === 'utilities' ?
                  `${placeOrderSuccess ? currentOrderLength : activeOrder.kmlLength} mi` :
                  `${placeOrderSuccess ? currentOrderAcreage : activeOrder.acreage} ac`}
              </Typography>
            </Box>
            <Box sx={styles.divGrid}>
              <Typography variant="body1" sx={styles.orderDetailTitle}>- Bundle</Typography>
              <Typography variant="body1" sx={styles.orderDetailValue}>
                {selectedBundleName}
              </Typography>
            </Box>
            {!expressAIBundle && (
              <Box sx={styles.divGrid}>
                <Typography variant="body1" sx={styles.orderDetailTitle}>- Density</Typography>
                <Typography variant="body1" sx={{ ...styles.orderDetailValue, ...styles.capitalize }}>
                  {density}
                </Typography>
              </Box>
            )}
            {!expressAIBundle && (
              <Box sx={styles.divGrid}>
                <Typography variant="body1" sx={styles.orderDetailTitle}>- Delivery Speed</Typography>
                <Typography variant="body1" sx={{ ...styles.orderDetailValue, ...styles.capitalize }}>
                  {deliverySpeed.split('_').join(' ')}
                </Typography>
              </Box>
            )}
          </Box>

          {!expressAIBundle && (
            <>
              <Box sx={styles.divGrid}>
                <Typography variant="h3">Add-Ons</Typography>
                <Typography sx={styles.rightContent} variant="h3">
                  {totalAddOnString}
                </Typography>
              </Box>
              <Box sx={styles.orderDetail}>
                <Box sx={styles.divGrid}>
                  <Typography variant="body1" sx={styles.orderDetailTitle}>{`- Deliverables (${extraDeliverablesLength})`}</Typography>
                  <Typography variant="body1" sx={styles.orderDetailValue}>
                    {deliverableAddOnString}
                  </Typography>
                </Box>
                <Box sx={styles.divGrid}>
                  <Typography variant="body1" sx={styles.orderDetailTitle}>{`- Layers (${extraLayersLength})`}</Typography>
                  <Typography variant="body1" sx={styles.orderDetailValue}>
                    {layerAddOnString}
                  </Typography>
                </Box>
                {imageryRequest && (
                  <Box sx={styles.divGrid}>
                    <Typography variant="body1" sx={styles.orderDetailTitle}>{`- ${imageryRequest.provider} Imagery`}</Typography>
                    <Typography variant="body1" sx={styles.orderDetailValue}>
                      {`${imageryCreditCost} credits`}
                      {pricingPlanName ? ` ($${formatValue(imageryCreditCost * pricingRate)})` : ` ($${formatValue(imageryDollarCost)})`}
                    </Typography>
                  </Box>
                )}
                {!!rasterizationCreditCost && (
                  <Box sx={styles.divGrid}>
                    <Typography variant="body1" sx={styles.orderDetailTitle}>- Point Cloud Rasterization</Typography>
                    <Typography variant="body1" sx={styles.orderDetailValue}>
                      {`${rasterizationCreditCost} credits`}
                      {pricingPlanName ? ` ($${formatValue(rasterizationCreditCost * pricingRate)})` : ` ($${formatValue(rasterizationDollarCost)})`}
                    </Typography>
                  </Box>
                )}
              </Box>
            </>
          )}

          <Divider sx={styles.divider} />

          <Box sx={{ ...styles.divGrid, ...styles.paddingTop8 }}>
            <Box sx={styles.dateGrid}>
              <Typography variant="h3">Total Credits Charged</Typography>
              {!expressAIBundle && (
                <WideLightTooltip
                  placement="bottom"
                  title={(
                    <div>
                      {`Your total credits are the sum of ${selectedBundleName} credits & add-ons.`}
                      {creditsToMin > 0 && (
                        <>
                          <br />
                          <br />
                          As a reminder, AirWorks minimum order size is
                          {' '}
                          {minCredits}
                          {' '}
                          credits.
                        </>
                      )}
                    </div>
                  )}
                >
                  <Box sx={styles.densityInfo}>
                    <InfoIcon sx={styles.icon} />
                  </Box>
                </WideLightTooltip>
              )}
            </Box>
            <Typography variant="h3" sx={{ ...styles.rightContent, ...styles.paddingTop8 }}>
              {`${automatePlanType !== 'plan_free' ? +(totalCredits).toFixed(2) : 0}`}
              {!expressAIBundle && ` ($${formatValue(automatePlanType !== 'plan_free' ? totalDollar : 0)})`}
            </Typography>
          </Box>
          {!expressAIBundle && totalTax > 0 && (
            <Box sx={{ ...styles.divGrid, ...styles.paddingTop16 }}>
              <Box sx={styles.dateGrid}>
                <Typography variant="h3">Sales Tax</Typography>
              </Box>
              <Typography variant="h3" sx={{ ...styles.rightContent, ...styles.paddingTop16 }}>
                {loading ? 'loading...' : `$${formatValue(totalTax)}`}
              </Typography>
            </Box>
          )}
          {!expressAIBundle && additonalCharges > 0 && (
            <Box sx={{ ...styles.divGrid, ...styles.paddingTop16 }}>
              <Box sx={styles.dateGrid}>
                <Typography variant="h3">Total Additional Charges</Typography>
              </Box>
              <Typography variant="h3" sx={{ ...styles.rightContent, ...styles.paddingTop16 }}>
                {loading ? 'loading...' : `$${formatValue(totalDollar + totalTax)}`}
              </Typography>
            </Box>
          )}
          <Box sx={{ ...styles.divGrid, ...styles.paddingTop16, ...styles.blueFont }}>
            <Box sx={styles.dateGrid}>
              <Typography variant="h2">Total cost for project</Typography>
            </Box>
            <Typography variant="h2" sx={{ ...styles.rightContent, ...styles.paddingTop16 }}>
              {expressAIBundle ? (`${adjustedOrderAcres} credits`) : (loading ? 'loading...' : `$${formatValue(totalDollar + totalTax)}`)}
            </Typography>
          </Box>
          {!expressAIBundle && additonalCharges === 0 && (
            <Box sx={styles.notes}>
              &#42; This project incurs no new charges. Cost is for estimating purposes and will come out of your current balance.
            </Box>
          )}

          {!expressAIBundle && !pricingPlanName && automatePlanType !== 'plan_free' && bundleCost > 0 && (
            <Box sx={styles.notes}>
              &#42; Total cost is for estimating purposes only, this project includes purchases that exceed your license contact your sales rep to upgrade and reduce your project cost.
            </Box>
          )}

          {expressAIBundle && (
            <Box sx={styles.notes}>
              &#42; Your Express AI deliverables will be available in 24 hours.
            </Box>
          )}
        </Paper>
        {!placeOrderSuccess && (
          <Box>
            {imageryRequest && (
              <Box sx={styles.processingContainer}>
                <FormControlLabel
                  label={<Typography>Begin Processing Immediately</Typography>}
                  control={(
                    <Checkbox
                      disabled={activeOrder.isEstimate}
                      color="primary"
                      onChange={toggleImageryProcessing}
                      checked={imageryRequest.processImmediately}
                      sx={styles.checkbox}
                    />
                  )}
                />
                <WideLightTooltip
                  placement="bottom"
                  disableHoverListener={activeOrder.isEstimate}
                  title={(
                    <div>
                      When start processing immediately is selected, you are forfeiting your chance to review the available imagery for your area of interest before placing your order. If you want to review the imagery before kicking off your project, unselect this option.
                    </div>
                  )}
                >
                  <InfoIcon fontSize="small" />
                </WideLightTooltip>
              </Box>
            )}
            <Box sx={styles.buttonsContainer}>
              <Box sx={styles.downloadContainer}>
                {selectedBundle && screenshot && (isSaved || firstTimeChangeEstimate) ? (
                  <PDFDownloadLink document={<PDF data={pdfData} />} fileName={`${projectName}_estimate.pdf`} style={styles.pdfButton}>
                    {({ blob, url, loading }) =>
                      (loading ? <CircularProgress size={24} sx={styles.buttonProgress} /> : (
                        <Box
                          sx={styles.downloadText}
                          onClick={() => {
                            window?.pendo?.track('Download Estimate', { projectId: activeOrder.ownerProject });
                          }}
                        >
                          <img src={downloadIcon} alt="download" />
                          DOWNLOAD ESTIMATE
                        </Box>
                      ))}
                  </PDFDownloadLink>
                ) : (
                  <>
                    {(isSaving || (!isSaving && !isSaved && !firstTimeChangeEstimate)) && (
                      <Button variant="outlined" sx={styles.saveButton} onClick={onClickSaveEstimate} disabled={(page === 1 && !cards) || (selectedBundle?.bundleId === 5 && addOnCount === 0)}>
                        <Fade in={isSaving}>
                          <CircularProgress size={24} sx={styles.buttonProgress} />
                        </Fade>
                        <Fade in={!isSaving && !isSaved && !firstTimeChangeEstimate}>
                          <span style={styles.buttonText}>{ imageryRequest ? 'Request Imagery' : (!!rasterizationCreditCost ? 'Generate Ortho from point cloud(s)' : 'Save Estimate')}</span>
                        </Fade>
                      </Button>
                    )}
                  </>
                )}
              </Box>
              {((bundleStep === 2 && page === 0) || (isSaved || firstTimeChangeEstimate)) || page !== 0 || expressAIBundle ? (
                <Button
                  variant="contained"
                  color="primary"
                  sx={styles.continueButton}
                  onClick={goToNextPage}
                  disabled={(isSubmitting) || (page === 1 && !cards) || (selectedBundle?.bundleId === 5 && addOnCount === 0) ||
                    (imageryRequest && (!imageryRequest.uploaded || !activeOrder.isEstimate)) || !!rasterizationCreditCost ||
                    (selectedBundleId === 9 && bundleCost > 0)}
                >
                  <Fade in={isSubmitting}>
                    <CircularProgress size={24} sx={styles.buttonProgress} />
                  </Fade>
                  <Fade in={!isSubmitting}>
                    {page && page === 2 ? <span>Submit Order</span> : <span>Continue</span>}
                  </Fade>
                </Button>
              ) : ''}
            </Box>
          </Box>
        )}
        <KickoffDatePicker orderId={activeOrder._id} showDialog={showKickoffDatePicker} setShowDialog={setShowKickoffDatePicker} />
        {(availableBundles?.length > 0 && imageryRequest) && (
          <TalkToSalesDialog
            showDialog={showTalkToSalesDialog}
            onOk={contactSalesClick}
            onProceed={onProceed}
            onCancel={() => {
              setContactSalesInteraction(true);
            }}
            addOnDeliverableDollars={addOnDeliverableDollars}
            addOnLayerDollars={addOnLayerDollars}
            titleText="Credit Limit Reached!"
          />
        )}
      </>
    );
  };

export default MapColumn;
