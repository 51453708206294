// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/system';
import { BarChart, Bar, XAxis, YAxis } from 'recharts';
import { Typography, LinearProgress, Divider, Container, Paper, Select, InputBase, MenuItem } from '@mui/material';
import moment from 'moment-timezone';
import LoadingSpinner from 'Components/LoadingSpinner';
import { monthNames, longMonthNames, dayNames, pricingDescription } from 'Utils/constants';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { RootState } from 'Store';
import { SetOrgIdAction } from '../adminActions';
import { GetLastLoginThunk, GetLoginStatsThunk, GetOrgThunk, GetOrgStatsThunk, GetOrgSubscriptionThunk } from '../adminThunk';
import stylesDef from './styles';

const hexToRGBA = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const formatNumber = (number: number) => +Number(number).toFixed(2);

const timeOptions = ['Monthly', 'Quarterly', 'Yearly'];

const BarLabel = (params: any) => {
  const { value: barLabelValue } = params;
  if (barLabelValue === 0) return <text />;

  const { x, y, value, width, height } = params;

  return (
    <text
      x={x}
      y={y}
      dx={7}
      dy={-8}
      fontSize={12}
      fill="grey"
      className="recharts-bar-label"
      width={width}
      height={height}
      textAnchor="middle"
    >
      {value}
    </text>
  );
};

export const PlanUsage = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const { orgId } = useParams();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [timeOption, setTimeOption] = useState('');
  const [orgYears, setOrgYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');

  const { orgStats, loginStats, automateSubscription } = useAppSelector(
    (state: RootState) => state.admin,
  );
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  const monthLength = new Date(year, month, 0).getDate();
  const daysToPrepend = new Date(year, month, 1).getDay();
  const daysToAppend = 6 - new Date(year, month, monthLength).getDay();

  useEffect(() => {
    dispatch(SetOrgIdAction(orgId));
    setTimeOption(timeOptions[0]);
    const loadData = async () => {
      await Promise.all([
        dispatch(GetOrgThunk(orgId)),
        dispatch(GetLoginStatsThunk(orgId)),
        dispatch(GetLastLoginThunk(orgId)),
        dispatch(GetOrgSubscriptionThunk(orgId)),
        dispatch(GetOrgStatsThunk(orgId)),
      ]);
      setLoading(false);
    };
    loadData();
  }, []);

  useEffect(() => {
    if (timeOption === timeOptions[0] || timeOption === timeOptions[1]) {
      if (orgStats?.creditAcres?.processedPerMonth) {
        const years = orgStats?.creditAcres.processedPerMonth.map((stat: { year: any; }) => stat.year);
        const uniqueYears = [...new Set(years)].map((y: string) => parseInt(y, 10));
        setOrgYears(uniqueYears);
      }
    }
  }, [timeOption, orgStats]);

  useEffect(() => {
    if (orgYears.length > 0) {
      const mostRecentYear = Math.max(...orgYears);
      setSelectedYear(mostRecentYear.toString());
    }
  }, [orgYears]);

  const loginsData =
    loginStats?.perMonth?.map((v, index) => ({
      monthName: monthNames[v.month - 1],
      year: index === 0 || v.month === 1 ? v.year : '',
      logins: v.value,
    }));

  const creditAcresMonthlyData =
    orgStats?.creditAcres?.processedPerMonth
      .filter((stat) => stat.year === parseInt(selectedYear, 10))
      .map((v) => ({
        monthName: monthNames[v.month - 1],
        creditAcres: +(v.value).toFixed(2),
      }));

  const creditAcresYearlyData =
    orgStats?.creditAcres?.processedPerYear.map((v) => ({
      year: v.year,
      creditAcres: +(v.value).toFixed(2),
    }));

  const creditAcresQuarterlyData =
    orgStats?.creditAcres?.processedPerQuarter
      .filter((stat) => stat.year === parseInt(selectedYear, 10))
      .map((v) => ({
        quarter: `Q${v.quarter}`,
        creditAcres: +(v.value).toFixed(2),
      }));

  const projectsData =
    orgStats?.projects?.perMonth.map((v, index) => ({
      monthName: monthNames[v.month - 1],
      year: index === 0 || v.month === 1 ? v.year : '',
      projects: v.value,
    }));

  const pricingPlanInfo = orgStats?.creditAcres?.pricingPlanInfo;
  const getPricingPlanName = (planName: string) => pricingDescription.find((plan) => plan.name === planName)?.displayName;

  const linearProgressValue = pricingPlanInfo ? (pricingPlanInfo.usedCredits / pricingPlanInfo.purchasedCredits) : (orgStats?.creditAcres?.totalProcessed / orgStats?.creditAcres?.limit);
  const planRemainingCredits = formatNumber(pricingPlanInfo?.purchasedCredits - pricingPlanInfo?.usedCredits);
  const remainingCredits = planRemainingCredits > 0 ? planRemainingCredits : 0;

  const renderBarChart = (data: any[], xAxisKey: string) => (
    <BarChart height={200} width={600} data={data}>
      <XAxis dataKey={xAxisKey} tickLine={false} />
      <YAxis padding={{ bottom: 8, top: 16 }} hide />
      <Bar dataKey="creditAcres" fill="blue" label={<BarLabel />} barSize={16} radius={[2, 2, 2, 2]} />
    </BarChart>
  );

  const renderChart = () => {
    switch (timeOption) {
    case timeOptions[0]:
      return renderBarChart(creditAcresMonthlyData, 'monthName');
    case timeOptions[1]:
      return renderBarChart(creditAcresQuarterlyData, 'quarter');
    case timeOptions[2]:
      return renderBarChart(creditAcresYearlyData, 'year');
    default:
      return null;
    }
  };

  return (
    <LoadingSpinner loading={loading}>
      <Box sx={styles.pageWrapper}>
        <Box sx={styles.margin}>
          {orgStats && (
            <Box>
              <Box sx={styles.title}>
                <Typography variant="h4">Credit Usage</Typography>
                <Box>
                  <Select
                    variant="outlined"
                    input={<InputBase style={{ width: '100px' }} />}
                    value={timeOption}
                    onChange={(e) => setTimeOption(e.target.value)}
                  >
                    {
                      timeOptions.map((option) => <MenuItem key={option} value={option}>{option}</MenuItem>)
                    }
                  </Select>
                  {(timeOption === timeOptions[0] || timeOption === timeOptions[1]) && (
                    <Select
                      variant="outlined"
                      input={<InputBase style={{ width: '100px' }} />}
                      value={selectedYear}
                      onChange={(e) => setSelectedYear(e.target.value)}
                    >
                      {
                        orgYears.map((orgYear) => <MenuItem key={orgYear} value={orgYear.toString()}>{orgYear}</MenuItem>)
                      }
                    </Select>
                  )}
                </Box>
              </Box>
              <Box sx={{ ...styles.list, ...styles.acreageList }}>
                {pricingPlanInfo && pricingPlanInfo.planName !== 'no_commit' && (
                  <>
                    <Typography variant="subtitle1" color="textSecondary">
                      <Typography component="span" variant="subtitle1" color="primary">{formatNumber(pricingPlanInfo?.usedCredits)}</Typography>
                      {` of ${formatNumber(pricingPlanInfo?.purchasedCredits)} credits processed`}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary" sx={styles.alignRight}>{`${remainingCredits} credits remaining`}</Typography>
                  </>
                )}
                {!pricingPlanInfo && (
                  <>
                    <Box>
                      <Typography variant="subtitle1" color="textSecondary">
                        <Typography component="span" variant="subtitle1" color="primary">{formatNumber(orgStats.creditAcres.totalProcessed)}</Typography>
                        {` of ${formatNumber(orgStats.creditAcres.limit)} credits`}
                      </Typography>
                      <Typography variant="body2">{automateSubscription?.interval === 'Monthly' ? 'processed this month' : 'processed this year'}</Typography>
                    </Box>
                    <Typography variant="subtitle1" color="textSecondary" sx={styles.alignRight}>{`${formatNumber(orgStats.creditAcres.available)} credits remaining`}</Typography>
                  </>
                )}
              </Box>
              {(!pricingPlanInfo || (pricingPlanInfo && pricingPlanInfo.planName !== 'no_commit')) && (
                <LinearProgress
                  variant="determinate"
                  value={linearProgressValue * 100}
                  valueBuffer={linearProgressValue * 100}
                  sx={styles.progressBar}
                />
              )}
              <Typography variant="subtitle1">{`Credits Per ${timeOption?.slice(0, -2)}`}</Typography>
              {renderChart()}
              <Box sx={styles.list}>
                <Typography variant="subtitle1" sx={styles.leftText}>Credits Processed to Date</Typography>
                <Typography variant="h1" color="textSecondary" sx={styles.alignRight}>
                  {formatNumber(orgStats.creditAcres.totalProcessed)}
                </Typography>
                {pricingPlanInfo && (
                  <>
                    <Typography variant="subtitle1" sx={styles.leftText}>Credits Used in Current Pricing Plan</Typography>
                    <Typography variant="h1" color="textSecondary" sx={styles.alignRight}>
                      {formatNumber(pricingPlanInfo?.usedCredits)}
                    </Typography>
                  </>
                )}
                <Typography variant="subtitle1" sx={styles.leftText}>Avg Credits Per Month</Typography>
                <Typography variant="h1" color="textSecondary" sx={styles.alignRight}>
                  {formatNumber(orgStats.creditAcres.averagePerMonth)}
                </Typography>
                <Typography variant="subtitle1" sx={styles.leftText}>Avg Credits Per Project</Typography>
                <Typography variant="h1" color="textSecondary" sx={styles.alignRight}>
                  {formatNumber(orgStats.creditAcres.averagePerProject)}
                </Typography>
                {pricingPlanInfo && pricingPlanInfo.planName !== 'no_commit' && (
                  <>
                    <Box sx={styles.planWithDate}>
                      <Typography variant="subtitle1" sx={styles.leftText}>{getPricingPlanName(pricingPlanInfo?.planName)}</Typography>
                      <Typography variant="body2">{`Contract Ends On: ${moment(pricingPlanInfo?.planEndDate).format('MM/DD/YYYY')}`}</Typography>
                    </Box>
                    <Typography variant="h1" color="textSecondary" sx={styles.alignRight}>
                      {`$${formatNumber(pricingPlanInfo?.purchasedCredits)}`}
                    </Typography>
                  </>
                )}
                {pricingPlanInfo && pricingPlanInfo.planName === 'no_commit' && (
                  <>
                    <Typography variant="subtitle1" sx={styles.leftText}>{getPricingPlanName(pricingPlanInfo?.planName)}</Typography>
                    <Typography variant="h1" color="textSecondary" sx={styles.alignRight}>
                      {`$${formatNumber(pricingPlanInfo?.pricePerCredit)}`}
                    </Typography>
                  </>
                )}
              </Box>
              <Divider sx={styles.divider} />
              <Typography sx={styles.title} variant="h4">Project usage</Typography>
              <Typography variant="subtitle1">Projects Per Month</Typography>
              <BarChart height={200} width={600} data={projectsData}>
                <XAxis dataKey="monthName" tickLine={false} />
                <XAxis dataKey="year" axisLine={false} tickLine={false} xAxisId="year" />
                <YAxis padding={{ bottom: 8, top: 16 }} hide />
                <Bar dataKey="projects" fill="blue" label={<BarLabel />} barSize={16} radius={[2, 2, 2, 2]} />
              </BarChart>
              <Box sx={styles.list}>
                <Typography variant="subtitle1">Projects Processed to Date</Typography>
                <Typography variant="h1" color="textSecondary" sx={styles.alignRight}>
                  {orgStats.projects.total}
                </Typography>
                <Typography variant="subtitle1">Avg Projects Per Month</Typography>
                <Typography variant="h1" color="textSecondary" sx={styles.alignRight}>
                  {formatNumber(orgStats.projects.averagePerMonth)}
                </Typography>
              </Box>
            </Box>
          )}
          <Divider sx={styles.divider} />
          {loginStats && (
            <Box>
              <Typography sx={styles.title} variant="h4">Login Analytics</Typography>
              <Box sx={styles.list}>
                <Typography variant="subtitle1">Last Login Date</Typography>
                <Typography variant="h4" color="textSecondary" sx={styles.alignRight}>
                  {loginStats.lastLogin}
                </Typography>
              </Box>
              <Typography variant="subtitle1" sx={styles.topMargin}>Logins Per Month</Typography>
              <BarChart height={200} width={600} data={loginsData}>
                <XAxis dataKey="monthName" tickLine={false} />
                <XAxis
                  dataKey="year"
                  axisLine={false}
                  tickLine={false}
                  xAxisId="year"
                />
                <YAxis padding={{ bottom: 8, top: 16 }} hide />
                <Bar
                  dataKey="logins"
                  fill="blue"
                  label={<BarLabel />}
                  barSize={16}
                  radius={[2, 2, 2, 2]}
                />
              </BarChart>

              <Typography variant="subtitle1">Logins This Month</Typography>
              <Typography color="textSecondary" variant="subtitle1">{`${longMonthNames[new Date().getMonth()]} ${new Date().getFullYear()}`}</Typography>
              {loginStats.thisMonth && (
                <Box sx={styles.calendarContainer}>
                  {dayNames.map((day) => (
                    <Container sx={styles.calendarHeaders} key={day}>
                      <Typography align="left">{day}</Typography>
                    </Container>
                  ))}
                  { Array.from({ length: daysToPrepend }, (_) => <Paper elevation={0} sx={styles.emptyDay} />)}
                  {
                    loginStats.thisMonth.map((stat: any) => (
                      <Paper elevation={0} sx={styles.calendarDay} key={stat.day}>
                        <Typography gutterBottom style={{ position: 'absolute', paddingLeft: '5px' }}>{stat.day}</Typography>
                        <Container sx={styles.loginsCircle} style={{ backgroundColor: hexToRGBA('blue', stat.value > 10 ? 1 : stat.value / 10) }}>
                          <Typography align="center" variant="h3" style={{ color: stat.value > 0 ? 'white' : 'black', lineHeight: '37px' }}>{stat.value}</Typography>
                        </Container>
                      </Paper>
                    ))
                  }
                  { Array.from({ length: daysToAppend }, (_) => <Paper elevation={0} sx={styles.emptyDay} />)}
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </LoadingSpinner>
  );
};

export default PlanUsage;
